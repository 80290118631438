import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface LicensesTransfersSendEmailProps {
  id: string,
  sourceCustomerId: string,
  targetCustomerId: string,
  showAs: string,
}

const LicensesTransfersSendEmail: React.FC<LicensesTransfersSendEmailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  let transfer = rootStore.getLicensesTransferById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootStore.fetchTemplateById(props.id)
    transfer = rootStore.getLicensesTransferById(props.id)

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleSendEmails = (values:any) => {
    setError(null);
    //setLoading(true);
    //console.log(JSON.stringify(values, null, 4));
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      let dataToSend={}

      if (values.ownEmails) {
        dataToSend = { id: props.id, sourceEmails: [values.sourceEmail], targetEmails: [values.targetEmail] }
      } else {
        dataToSend = { id: props.id, sourceCustomerId: props.sourceCustomerId, targetCustomerId: props.targetCustomerId }
      }

      await axios.post(getUrl('URL_TRANSFER_LICENSE')+'/'+props.id+'/email', dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  const schemaSendTest = Yup.object().shape({
    sourceEmail: Yup.string().when('ownEmails', {
      is: true,
      then: Yup.string()
      .required('E-mail musí být vyplněn!')
      .email('Vyplňte platný e-mail!'),
   
      }),
    targetEmail: Yup.string().when('ownEmails', {
      is: true,
      then: Yup.string()
      .required('E-mail musí být vyplněn!')
      .email('Vyplňte platný e-mail!'),
    
      }),  

    })



   
  return (
    <>
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Odeslat pokyny k převodu licence</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Odeslat pokyny k převodu licence</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Odeslání pokynů k převodu licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>E-maily s pokyny k převodu licence byly úspěšně odeslány!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <h4>{'#'+transfer?.sourceCustomerAccountNumber+' -> #'+transfer?.targetCustomerAccountNumber+' ('+transfer?.sourceProgram+' '+transfer?.sourceProgramVersion+')'}</h4>
            <br />
            <Formik
              validationSchema={schemaSendTest}
              initialValues={{ 
                ownEmails: false,
                sourceEmail: '', 
                targetEmail: '', 
                
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSendEmails(values)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="ownEmails">
                    <Form.Row>
                      
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="ownEmails"
                          size="sm"
                          className="checkbox-small"
                          style={{marginTop: '10px'}}
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.ownEmails}
                          
                          isValid={touched.ownEmails && !errors.ownEmails} 
                          isInvalid={!!errors.ownEmails}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ownEmails}
                        </Form.Control.Feedback>
                      </Col>
                      <FormLabel column>Odeslat na konkrétní e-maily</FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  
                  {values.ownEmails && <>
                  <FormGroup controlId="sourceEmail">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail - původní zákazník</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="sourceEmail"
                          value={values.sourceEmail}
                          onChange={handleChange}
                          isValid={touched.sourceEmail && !errors.sourceEmail} 
                          isInvalid={!!errors.sourceEmail}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.sourceEmail}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="targetEmail">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail - nový zákazník</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="targetEmail"
                          value={values.targetEmail}
                          onChange={handleChange}
                          isValid={touched.targetEmail && !errors.targetEmail} 
                          isInvalid={!!errors.targetEmail}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.sourceEmail}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  </>}

                  {error}
                  <br />
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám e-maily...' : 'Odeslat e-maily'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(LicensesTransfersSendEmail))

import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../parts/TopMenu/SideMenu.parts'

import NewLicensesTransfer from './components/NewLicensesTransfer.part'
import LicensesTransfersDetail from './components/LicensesTransfers.Detail.part'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

//import * as Constants from '../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { Container, Card, Table, Spinner, ButtonGroup, DropdownButton, Dropdown, Modal } from 'react-bootstrap'

import { useMount } from '../../helpers/lifecycle-hooks'
import { useStore } from '../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion, getUrl } from '../../Utils/Common'

import { confirm } from "../../Utils/Confirmation"

import moment from 'moment'

import Pagination from 'react-js-pagination'

import SupportContactsCustomerInfo from '../support/components/Support.Contacts.CustomerInfo.part'
import LicensesTransfersSendEmail from './components/LicensesTrasfers.SendEmail.part'

import FilterPanel from '../../sharedComponents/filterPanel/FilterPanel.part'

function LicensesTransfers(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);

  const [loading, setLoading] = useState(false)
  const [stateNewFilter, setStateNewFilter] = useState(false)

  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  
  // Nastavení práv
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Načtení Store
  const rootStore = useStore()

  const handleClose = () => { 
    
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'licenses-transfer')

    setStateNewFilter(true)
    await checkVersion()
    //if (rights === false) {
    //  props.history.push('/customers-dashboard')
    //}

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    

      await rootStore.fetchLicensesTransfersListNew(Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')))

      //await rootStore.fetchLicensesTransfersList(sessionStorage.getItem('transfersSearchValue')!, sessionStorage.getItem('transfersSearchTypeValue')!,Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')))  

      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
          }
          await rootStore.fetchCustomerList('','',1)
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')

          
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      }  
      if (Object.keys(rootStore.customerListAll).length < 100) await rootStore.fetchCustomerListAll('', '', 1)
      //await rootStore.fetchLicensesTransfersList('','',1)
    }  
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })


  // Dotaz před smazáním záznamu
  const handleOnClickDeleteTransfer = (id:string, text:any) => {
    (async () => {
      if (await confirm(<>Tato operace slouží výhradně ke smazání záznamu o převodu licence, nikoliv však k uvedení obou zúčastněných licencí do stavu před převodem. Zpětnou úpravu licencí u obou zákazníků je v případě potřeby na stránce Správa licencí nutné zajistit ručně!<br /><br />Opravdu chcete záznam o převodu licence {text}<br />smazat?</>,'Ano, smazat', 'Ne', {title: 'Smazání záznamu o převodu licence'})) {
        deleteTransfer(id)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání záznamu
  const deleteTransfer = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_TRANSFER_LICENSE')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchLicensesTransfersListNew(Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

  
  const transfers = rootStore.licensesTransfers
  const customerListAllF = rootStore.customerListAll
  
  //Počet záznamů
  const countData:number = Object.keys(transfers).length

  // Seznam pozvánek do řádků tabulky
  const linesTransfers = transfers.slice().map((transfer) => (
    <tr key={transfer.id}>
      <td>{moment(transfer.transferDate).format("D.M.YYYY H:mm")}</td>
      <td><strong><SupportContactsCustomerInfo customerId={transfer.sourceCustomerId!} isActiveCustomer={customerListAllF.find(cla => cla.id === transfer.sourceCustomerId)?.isActive!} customerName={transfer.sourceCustomerAccountNumber!} customerNameFull={transfer.sourceCustomerBusinessName!}  /></strong>&nbsp;/&nbsp;<SupportContactsCustomerInfo customerId={transfer.sourceCustomerId!} isActiveCustomer={customerListAllF.find(cla => cla.id === transfer.sourceCustomerId)?.isActive!} customerName={transfer.sourceCustomerBusinessName!} customerNameFull={transfer.sourceCustomerBusinessName!}  /></td>
      <td><strong><SupportContactsCustomerInfo customerId={transfer.targetCustomerId!} isActiveCustomer={customerListAllF.find(cla => cla.id === transfer.targetCustomerId)?.isActive!} customerName={transfer.targetCustomerAccountNumber!} customerNameFull={transfer.targetCustomerBusinessName!}  /></strong>&nbsp;/&nbsp;<SupportContactsCustomerInfo customerId={transfer.targetCustomerId!} isActiveCustomer={customerListAllF.find(cla => cla.id === transfer.targetCustomerId)?.isActive!} customerName={transfer.targetCustomerBusinessName!} customerNameFull={transfer.targetCustomerBusinessName!}  /></td>
      <td>{transfer.sourceProgram}&nbsp;{transfer.sourceProgramVersion}</td>
      <td>
        <ButtonGroup>
          <LicensesTransfersDetail id={transfer.id} showAs="button" />
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <LicensesTransfersSendEmail id={transfer.id} sourceCustomerId={transfer.sourceCustomerId!} targetCustomerId={transfer.targetCustomerId!} showAs='drop' />
            <Dropdown.Divider />
            <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteTransfer(transfer.id,<>{transfer.sourceProgram}&nbsp;{transfer.sourceProgramVersion}<br/>ze zákazníka <strong>{transfer.sourceCustomerAccountNumber!}</strong> / {transfer.sourceCustomerBusinessName!}<br />na zákazníka <strong>{transfer.targetCustomerAccountNumber!}</strong> / {transfer.targetCustomerBusinessName!}</>) } }>Smazat</Dropdown.Item>

          </DropdownButton>

        </ButtonGroup>
      </td>
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesTransfersCard = transfers.slice().map((transfer) => (
    <div key={transfer.id}>
    <Card>
      <Card.Body>
        <div style={{minWidth: '150px', float: 'left'}}>
        
          <strong>Datum a čas:</strong> {moment(transfer.transferDate).format("D.M.YYYY H:mm")}<br />
          <strong>Původní zákazník:</strong> {transfer.sourceCustomerAccountNumber} / {transfer.sourceCustomerBusinessName}<br />
          <strong>Cílový zákazník:</strong> {transfer.targetCustomerAccountNumber} / {transfer.targetCustomerBusinessName}<br />
          <strong>Program:</strong> {transfer.sourceProgram}&nbsp;{transfer.sourceProgramVersion}<br />
          

        </div>
        <div style={{float: 'right'}}>
          <ButtonGroup>
            <LicensesTransfersDetail id={transfer.id} showAs="button" />
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
              <LicensesTransfersSendEmail id={transfer.id} sourceCustomerId={transfer.sourceCustomerId!} targetCustomerId={transfer.targetCustomerId!} showAs='drop' />
              <Dropdown.Divider />
              <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteTransfer(transfer.id,<>{transfer.sourceProgram}&nbsp;{transfer.sourceProgramVersion}<br/>ze zákazníka <strong>{transfer.sourceCustomerAccountNumber!}</strong> / {transfer.sourceCustomerBusinessName!}<br />na zákazníka <strong>{transfer.targetCustomerAccountNumber!}</strong> / {transfer.targetCustomerBusinessName!}</>) } }>Smazat</Dropdown.Item>

            </DropdownButton>

          </ButtonGroup>
        </div>
        
        
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  const handlePageChange = (pageNumber:number) => {
    (async () => { 
      setLoaded(false);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      sessionStorage.setItem('transfersSearchPage', String(pageNumber))
      // Předat hledanou hodnotu action pro načtení zákazníků
      await rootStore.fetchLicensesTransfersListNew(pageNumber)
      setLoaded(true);
      // Hodnoty hledání uložit do sessionStorage
      
    })()
  }

  
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu loadingVersion={!loaded} />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <Container fluid>
              <br />
              <FilterPanel 
                //isCustomer={props.isCustomer} 
                filterPage={'licensesTransfers'} 
                filterSearchArray='licensesTransfersSearchArray' 
                pageVariable='templatesSearchPage' 
                setPageLoading={setLoading} 
                filterItems={['CustomerFulltext','AccountNumber','CompanyRegistrationNumber','Email']}  
                title="Převody licencí"
                titleCount = {Number(localStorage.getItem('licensesTransfersTotalCount')!)}
                stateNewFilter={stateNewFilter}
                setStateNewFilter={setStateNewFilter}
                secondaryLeftButtons={<NewLicensesTransfer />}
                />

                <br />

                {(loading === true) 
                ? 
                <><Spinner animation='border'  />&nbsp;<br /></>
                :
                <>
                <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam převodů licencí neobsahuje žádné záznamy.<br /><br /></div>
                <div className={countData === 0 ? 'nodisplay' : ''}>
                  <div className="showMobile">
                    {linesTransfersCard}<br />
                  </div>
                  <div className="showWeb">
                  <Table hover>
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head" title='Datum a čas převodu licence'>Datum a čas</th>
                          <th className="jsw-table-head" title='Zákazník, od kterého se licence převádí'>Původní zákazník</th>
                          <th className="jsw-table-head" title='Zákazník, na kterého se licence převádí'>Cílový zákazník</th>
                          <th className="jsw-table-head">Program</th>
                          <th className="jsw-table-head">Akce</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {linesTransfers}
                      </tbody>
                    </Table>
                  </div> 
                  
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')) }
                    itemsCountPerPage={10}
                    totalItemsCount={Number(localStorage.getItem('licensesTransfersTotalCount'))}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(1)}
                    /> 
                </div>  
                </>} 
                
              </Container>




              
            </Loader>  
            </main>
          </div>
        </div>      
        <Modal 
          show={showE} 
          onHide={handleClose} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Smazání záznamu o převodu licence</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error}
          </Modal.Body>
        </Modal>
    </div> 
  );
}

export default observer(LicensesTransfers)

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import CustomersSendInvites from './Overviews.Customers.SendInvites.part'
import CustomersOtherActionsSendEmail from '../../../parts/customersOtherActions/Customers.OtherActions.SendEmail.part'
import CustomersOtherActionsReminder from '../../../parts/customersOtherActions/Customers.OtherActions.Reminder.part'
import CustomersOtherActionsGenerateOffer from '../../../parts/customersOtherActions/Customers.OtherActions.GenerateOffer.part'
import CustomersOtherActionsPublishOffer from '../../../parts/customersOtherActions/Customers.OtherActions.PublishOffer.part'
import CustomersOtherActionsUpgradeFreeCreateLicenses from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeCreateLicenses.part'
import CustomersOtherActionsUpgradeFreePublishLicenses from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreePublishLicenses.part'
import CustomersOtherActionsUpgradeElectronicCreateProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicCreateProformas.part'
import CustomersOtherActionsUpgradeElectronicDeleteProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeleteProformas.part'
import CustomersOtherActionsUpgradeElectronicPublishProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPublishProformas.part'
import CustomersOtherActionsUpgradeElectronicUnpublishProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishProformas.part'
import CustomersOtherActionsUpgradeElectronicDeletePublishedProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeletePublishedProformas.part'
//import CustomersOtherActionsUpgradeElectronicPaidProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPaidProformas.part'
import CustomersOtherActionsUpgradeElectronicCreateFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicCreateFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicPublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPublishFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeleteFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishFinalDocuments.part'
import CustomersOtherActionsUpgradeFreeUnpublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeUnpublishFinalDocuments.part'
import CustomersOtherActionsUpgradeFreeDeleteFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeDeleteFinalDocuments.part'

import CustomersOtherActionsUpgradeCashCreateFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashCreateFinalDocuments.part'
import CustomersOtherActionsCashCreateSubmissions from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashCreateSubmissions.part'

import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'


//import { useStore } from '../../../store'
import { observer } from 'mobx-react'


interface CustomersMassActionsProps {
  id: string,
  searchValue?:string|undefined|null,
  searchType?:string|undefined|null, 
  searchScenario?:string|undefined|null,
  filtered?:boolean|undefined|null,
  multiselect?:boolean|undefined|null,
  multiselectCount?:number|undefined|null,
  style?:any
}

const CustomersMassActions: React.FC<CustomersMassActionsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  const modeToSend = props.multiselect === true ? 'selected' : 'all'
  const filteredToSend = props.multiselect === true ? false : props.filtered

    
  return (
    <>    
    {rights &&<DropdownButton as={ButtonGroup} id="dropdown-basic-button" style={props.style} title={props.multiselect === true ? "Hromadné akce (vybráno: "+ props.multiselectCount + ")" : props.filtered === true ? "Hromadné akce (filtr)":"Hromadné akce (vše)"} size="sm">
          {props.id === '2' &&<CustomersSendInvites mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType}  id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          {props.id === '1' &&<CustomersOtherActionsReminder mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} info='' count={props.multiselectCount} id='' filterId={props.id} reminderType='invite' showAs='drop' />}
          {props.id === '4' &&<CustomersOtherActionsReminder mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} info='' count={props.multiselectCount} id='' filterId={props.id} reminderType='incompleteRegistration' showAs='drop' />}
          {props.id === '6' &&<CustomersOtherActionsGenerateOffer mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          
          {props.id === '7' &&<CustomersOtherActionsPublishOffer mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          
          {props.id === '8' &&<CustomersOtherActionsReminder mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} info='' count={props.multiselectCount} id='' filterId={props.id} reminderType='offer' showAs='drop' />}

          {(props.id === '1001' || props.id === '1007' || props.id === '1013') &&<CustomersOtherActionsUpgradeCashCreateFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<CustomersOtherActionsCashCreateSubmissions mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<Dropdown.Divider />}
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          
          {(props.id === '1006' || props.id === '1012' || props.id === '1018') &&<CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}

          {(props.id === '1019' || props.id === '1025' || props.id === '1031') &&<CustomersOtherActionsUpgradeElectronicCreateProformas mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}

          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeElectronicPublishProformas mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeElectronicDeleteProformas mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}

          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicUnpublishProformas mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicDeletePublishedProformas mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}


          {(props.id === '1022' || props.id === '1028' || props.id === '1034') &&<CustomersOtherActionsUpgradeElectronicCreateFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeElectronicPublishFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<Dropdown.Divider />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          

          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}

          {(props.id === '1037' || props.id === '1040' || props.id === '1043') &&<CustomersOtherActionsUpgradeFreeCreateLicenses mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          
          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<CustomersOtherActionsUpgradeFreePublishLicenses mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<CustomersOtherActionsUpgradeFreeDeleteFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          
          {(props.id === '1039' || props.id === '1042' || props.id === '1042') &&<CustomersOtherActionsUpgradeFreeUnpublishFinalDocuments mode={modeToSend} multiselect={props.multiselect} filtered={filteredToSend} searchValue={props.searchValue} searchType={props.searchType} searchScenario={props.searchScenario} id='' id2='' id3='' showAs='drop' filterId={props.id} info='' count={props.multiselectCount} />}
          

          
          {Number(props.id) < 7 &&<CustomersOtherActionsSendEmail mode='all' id='' filterId={props.id} showAs='drop' />}
        </DropdownButton>}
    </>    
  )  
  
}

export default withRouter(observer(CustomersMassActions))

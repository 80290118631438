import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form,  Alert, Modal, Dropdown } from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
//import FormGroup from 'react-bootstrap/FormGroup'
//import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
//import { useStore, useUpgradeStore } from '../../../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

//import moment from 'moment'



interface CustomersDocumentsGenerateVerificationProps {
  showAs: string,
  upgradeScenarioId: string,
  program: string,
  programCode:string
}

const CustomersDocumentsGenerateVerification: React.FC<CustomersDocumentsGenerateVerificationProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showInfoCopy, setShowInfoCopy] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [verifyCode, setVerifyCode] = useState(<></>)

  const [verifyCodeToClip, setVerifyCodeToClip] = useState('')
 
  // Připojení store
  //const rootStore = useStore()
  //const rootUpgradeStore = useUpgradeStore()

  let tempRegistration:string
  let url:string = ''

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  const copyToClip = (textToCopy:string) => {
    
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = textToCopy;
    //dummy.value = verifyCode ;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    setShowInfoCopy(true)

    setTimeout(() => {
        
        setShowInfoCopy(false)
        //setShowInfo(false)
        //setShowForm('')
      
    }, 2800); 


  }

  // Funkce pro odeslání e-mailu
  const handleGenerate = () => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      
      url = props.programCode === 'D' ? 'duel/':'stereo/'

      // Zavolání API
      await axios.get(getUrl('URL_ADMIN_VERIFY_CODES')+url+props.upgradeScenarioId+'/'+localStorage.getItem('openedCustomerId')!, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          //sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)
          
          if (props.programCode === 'D') {
            tempRegistration = 
            props.program

            +'\r\nČíslo zákazníka: '
            +response.data.accountNumber+' (licence)'
            +'\r\nZákazník       : '
            +response.data.businessName
            +'\r\nVerifikační kód: '
            +response.data.verifyCode

            setVerifyCode(<>
            <span style={{fontFamily: 'consolas'}}>
              <strong>Licenční lístek - {props.program}</strong><br />
              Číslo zákazníka: <strong>{response.data.accountNumber}</strong> (licence)<br />
              Zákazník&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <strong>{response.data.businessName}</strong><br />
              Verifikační kód: <strong>{response.data.verifyCode}</strong>
            </span>
            </>)
            setVerifyCodeToClip(tempRegistration)

          }
          else {
            tempRegistration = 
            props.program

            +'\r\nČíslo zákazníka: '
            +response.data.accountNumber+' (licence)'
            +'\r\nZákazník       : '
            +response.data.customerName
            +'\r\nLicence        : '
            +response.data.licenseType
            +'\r\nProgram        : '
            +response.data.programType
            +'\r\nPočet PC       : '
            +response.data.count
            +'\r\nLicenční kód   : '
            +response.data.code

            setVerifyCode(<>
            <span style={{fontFamily: 'consolas'}}>
              <strong>Licenční lístek - {props.program}</strong><br />
              Číslo&nbsp;zákazníka: <strong>{response.data.accountNumber}</strong> (licence)<br />
              Zákazník&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <strong>{response.data.customerName}</strong><br />
              Licence&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <strong>{response.data.licenseType}</strong><br />
              Program&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <strong>{response.data.programType}</strong><br />
              Počet PC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <strong>{response.data.count}</strong><br />
              Licenční&nbsp;kód&nbsp;&nbsp;&nbsp;: <strong>{response.data.code}</strong>
            </span>
            </>)

            setVerifyCodeToClip(tempRegistration)

          }
          

          
          

          // Po 2800 ms celé modální okno zavřeme
          /*
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800); */

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      

    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    
  })   
  return (
    <span>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Generování {props.programCode === 'D' ? 'verifikačního kódu':'registračních údajů'}</Dropdown.Item>}
          {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Generování {props.programCode === 'D' ? 'verifikačního kódu':'registračních údajů'}</Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Generování {props.programCode === 'D' ? 'verifikačního kódu':'registračních údajů'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.mode === 'selected' &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}
          
          <Alert variant="success" show={showInfo}>
            
            {verifyCode}
            
          </Alert> 
          <Alert variant="info" show={showInfoCopy}>
            
            <i className="fas fa-info-circle"></i>&nbsp;Údaje byly zkopírovány do schránky!
            
          </Alert>
          {showInfo && <><Button variant="secondary" onClick={() => {copyToClip(verifyCodeToClip)}}><i className="fas fa-copy"></i>&nbsp;Zkopírovat do schránky</Button></>}

          <div className={showForm} >  
          <div style={{fontStyle: 'italic'}}>
              <i className="fas fa-info-circle"></i>&nbsp;
              {props.programCode === 'D' ? 
              'Tato akce slouží pro vygenerování verifikačného kódu v situacích, kdy pro zákazníka zatím není dostupný licenční lístek ale verifikace je již umožněna. Jedná se například o urychlenou verifikaci na základě pouhého potvrzení o provedené platbě.'
              :
              'Tato akce slouží pro vygenerování registračních udajů v situacích, kdy pro zákazníka zatím není dostupný licenční lístek ale registrace je již umožněna. Jedná se například o urychlenou registraci na základě pouhého potvrzení o provedené platbě.'
              }<br /><br />
            </div>

            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                email: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleGenerate()              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Generuji...' : props.programCode === 'D' ? 'Vygenerovat verifikační kód':'Vygenerovat registrační údaje' }</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersDocumentsGenerateVerification))

import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

interface OrdinaryRouteProps extends RouteProps {
  component: any;
}

const OrdinaryRoute = (props: OrdinaryRouteProps) => {
  const { component: Component, ...rest } = props;

  //const [logged] = useAuth()
  return (
      <Route
          {...rest}
          render={(routeProps) =>
            <Component {...routeProps} />
             
          }
      />
  )
}

export default OrdinaryRoute
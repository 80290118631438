import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal } from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

//import { confirm } from "../../../Utils/Confirmation"

import { useStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface CustomersDashboardLicensesEditModeOfUseProps {
  id: string,
  label: string,
  showIcon: boolean,
  block: boolean,
  programCode: string,
  programId: string

}

const CustomersDashboardLicensesEditModeOfUse: React.FC<CustomersDashboardLicensesEditModeOfUseProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)
  // eslint-disable-next-line
  const [showProg, setShowProg] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const usageMode = rootStore.customerOpenedUsageMode.find(um => um.programId === props.programId)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setShowProg(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  
  // Funkce pro změnu kontaktu
  // eslint-disable-next-line
  const handleUpdate = (stateData:string) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_USAGE_MODE')+localStorage.getItem('openedCustomerId')!+'/profile/program/'+props.programId, {customerId: localStorage.getItem('openedCustomerId')!, programId: props.programId, usageMode: stateData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          (async () => {
            if (response.status === 200) {
            //await rootUpgradeStore.getUpgradeOfferById(props.id)?.setData(response.data)
            //const dataToSave = rootUpgradeStore.upgradeOffers.filter(data => data.id !== props.id).concat(response.data)
            //await rootUpgradeStore.fetchUpgradeOffersFromData(dataToSave)
            }

          await rootStore.fetchUsageMode(localStorage.getItem('openedCustomerId')!,rootDialsStore.dialsPrograms[0].id,rootDialsStore.dialsPrograms[1].id)

          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          await setLoading(false)
          await setShowForm('nodisplay')
          await setShowInfo(true)
          await sessionStorage.setItem('autoCloseModal','1');

          //rootStore.fetchCustomerOpened()

          // Po 2800 ms celé modální okno zavřeme

          await setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);
          })()

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()
  }

  // Schéma validace formuláře
  const schemaState = Yup.object().shape({
    state: Yup.string().required('Hodnota musí být vyplněna!').notOneOf(['x'],'Hodnota musí být vyplněna!'),
  })  
 
  return (
    <span>
      {rights &&<Button block={props.block} variant="primary" size="sm" onClick={handleShow}>{props.showIcon && <span><i className="fas fa-flag"></i>&nbsp;</span>}{props.label}</Button>}

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"

        centered>
        <Modal.Header closeButton>
          <Modal.Title>Změna režimu používání programu {props.programCode === 'D' ? 'DUEL':'STEREO'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
        centered
        
        >
        <Modal.Header closeButton>
          <Modal.Title>Změna režimu používání programu {props.programCode === 'D' ? 'DUEL':'STEREO'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Změna režimu používání byla úspěšně provedena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaState}
              initialValues={{ 
                state: usageMode?.usageMode
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.state!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
               
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    
                  <FormGroup controlId="item">
                    <Form.Row>
                      <FormLabel column lg={3}>Režim používání</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="state" 
                          onChange={handleChange}
                          value={values.state!}
                          isInvalid={!!errors.state}
                        >
                            <option value="x">Vyberte možnost...</option>
                            <option value="v">Pouze pro vlastní potřebu</option>
                            <option value="k">Pouze pro jiné klienty (účetní / mzdová kancelář)</option>
                            <option value="i">Pro jiné klienty i pro vlastní potřebu</option>
                            <option value="n">Nechci odpovědět</option>
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.state}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                            
                  
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
            
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersDashboardLicensesEditModeOfUse))

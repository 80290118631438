import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
import { useStore, useUpgradeStore } from '../../../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

//import moment from 'moment'



interface CustomersDocumentsSendTestMailFinalDocumentsProps {
  showAs: string,
  upgradeScenarioId: string,
  programId: string
}

const CustomersDocumentsSendTestMailFinalDocuments: React.FC<CustomersDocumentsSendTestMailFinalDocumentsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    if (props.programId === '2b158389-2f71-4fa1-8778-5a4d179cd777') {
    await rootUpgradeStore.fetchUpgradeOffersListToCustomer(localStorage.getItem('openedCustomerId')!)
    await rootUpgradeStore.fetchUpgradeOffersDetail(rootUpgradeStore.upgradeOffersList.find(od => od.upgradeScenario.id === props.upgradeScenarioId)?.id!)
  }
    
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Funkce pro odeslání e-mailu
  const handleSendTest = (emailData: string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend={}
      let mode:string = 'electronic/'

      let isStereoLan:boolean = props.programId !== '2b158389-2f71-4fa1-8778-5a4d179cd777' ? false : rootUpgradeStore.upgradeOffersDetail[0].targetLicense.licenseType.code === 'D' ? true:false

      if (
        (Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'L')).length > 0 
          &&
          Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'Z')).length === 0
          &&
          Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'K')).length === 0)
        
        //rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'L')?.totalPrice === 0
        
        ) {
        mode='free/'
        dataToSend = {
          customerId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'Z')?.customerId,
          //upgradeScenarioId: props.upgradeScenarioId,
          programId: props.programId,
          //proformaDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'Z')?.id, 
          //taxDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'K')?.id, 
          //paymentDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'P')?.id,
          licenseDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'L')?.id,
          
          toEmailAddresses: [emailData] 
          //isStereoLan: props.programId !== '2b158389-2f71-4fa1-8778-5a4d179cd777' ? false : rootUpgradeStore.upgradeOffersDetail[0].targetLicense.licenseType.code === 'D' ? true:false
          }

      }
      else {

      dataToSend = {
        customerId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'Z')?.customerId,
        //upgradeScenarioId: props.upgradeScenarioId,
        programId: props.programId,
        //proformaDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'Z')?.id, 
        
        taxDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'K')?.id, 
        paymentDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'P')?.id,
        licenseDocumentId: rootStore.customerOpenedDocuments.find(documents => documents.upgradeScenarioId === props.upgradeScenarioId && documents.documentType === 'L')?.id,
        
        toEmailAddresses: [emailData] 
        //isStereoLan: props.programId !== '2b158389-2f71-4fa1-8778-5a4d179cd777' ? false : rootUpgradeStore.upgradeOffersDetail[0].targetLicense.licenseType.code === 'D' ? true:false
        }
      }
      // Zavolání API
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+mode+props.upgradeScenarioId+'/final/email?isStereoLan='+isStereoLan, dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)

          
          var dummy = document.createElement("textarea");
          document.body.appendChild(dummy);
          dummy.value = response.data.id ;
          dummy.select();
          document.execCommand("copy");
          document.body.removeChild(dummy);
          

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      

    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    email: Yup.string()
      .email('E-mailová adresa je ve špatném formátu!')
      .required('E-mail musí být vyplněn!')
  })   
  return (
    <span>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Odeslat finální dokumenty na e-mail</Dropdown.Item>}
          {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Odeslat finální dokumenty na e-mail</Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.mode === 'all' ? 'Hromadné zaslání finálních dokumentů e-mailu' : 'Odeslání finálních dokumentů na e-mail'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.mode === 'selected' &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}
          
          <Alert variant="success" show={showInfo}>
            <strong>E-mail byl odeslán!​</strong>
            
          </Alert>  
          <div className={showForm} >  
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                email: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSendTest(values.email)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="value">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mailová adresa</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email!}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám e-mail...' : 'Odeslat e-mail'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersDocumentsSendTestMailFinalDocuments))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl, getRoleName } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

// eslint-disable-next-line
import {Container, ButtonGroup, Badge, Dropdown, DropdownButton, Table, Card, Spinner} from 'react-bootstrap'


// eslint-disable-next-line
import axios from 'axios';

// eslint-disable-next-line
import { confirm } from "../../../../Utils/Confirmation";

import AccountsDetail from './Accounts.Accounts.Detail.part'
import AcountUpdateDisable from '../../../customers/userManagement/components/Customers.UserManagement.Disable.part'
import CustomersOtherActionsEmailHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.EmailHistory.part'

//import DialsDeliveryCzechPostTemplateUpdate from './Dials.Delivery.CzechPost.Templates.Update.part'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../../sharedComponents/filterPanel/FilterPanel.part'

const AccountsList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  //const [error, setError] = useState(null)
  // eslint-disable-next-line
  //const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  //const [showForm, setShowForm] = useState('')
  //const [show, setShow] = useState(false)
  //const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  
  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  
  
  const items = rootStore.accounts.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{item.fullNameBySurName}</td>
      <td>{item.email}</td>
      <td>{item.isDisabled === true ? <Badge title='Přiznak, že daný uživatel má zakázaný přístup do celého zákaznického portálu' variant="danger">Zablokovaný účet</Badge>:<Badge variant="success">Aktivní</Badge>}{item.isEmployee &&<>&nbsp;<Badge title='Příznak, že daný uživatel je zaměstnancem Ježek software' variant='secondary'>Zaměstnanec</Badge></> }</td>
      <td>{item.roles.map((role,index) => <span key={index}>{index > 0 && <>&nbsp;</>}<Badge variant='info'>{getRoleName(role)}</Badge></span>)}</td>
      {rights &&<td>
        <ButtonGroup>
          <AccountsDetail accountId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsEmailHistory showAs='drop' email={item.email} />
            {rightsBH &&<Dropdown.Divider />}
            {rightsBH &&<AcountUpdateDisable id={item.id} email={item.email} action={item.isDisabled ? 'E':'D'} showAs='drop' fromAccounts={true} />}
          </DropdownButton>
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{item.fullNameBySurName}</strong><br />
          {item.email}<br />
          {item.isDisabled === true ? <Badge title='Přiznak, že daný uživatel má zakázaný přístup do celého zákaznického portálu' variant="danger">Zablokovaný účet</Badge>:<Badge variant="success">Aktivní</Badge>}
          <br />
          {item.isEmployee &&<><Badge title='Příznak, že daný uživatel je zaměstnancem Ježek software' variant='secondary'>Zaměstnanec</Badge></>}
          <br />
          {item.roles.map((role,index) => <span key={index}>{index > 0 && <>&nbsp;</>}<Badge variant='info'>{getRoleName(role)}</Badge></span>)}<br />

        </div>
        <div style={{float: 'right'}}>
        {rights && <>
          <ButtonGroup>
            <AccountsDetail accountId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
              <CustomersOtherActionsEmailHistory showAs='drop' email={item.email} />
              {rightsBH &&<Dropdown.Divider />}
              {rightsBH &&<AcountUpdateDisable id={item.id} email={item.email} action={item.isDisabled ? 'E':'D'} showAs='drop' fromAccounts={true} />}
          </DropdownButton>
            
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   



    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('accountsSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootStore.fetchAccounts(pageNumber)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    // eslint-disable-next-line
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('accountsOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('accountsSearchPage', String(1))
        await rootStore.fetchAccounts(1)
        setLoading(false);  
      })()
    }
   

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'accounts'} 
        filterSearchArray='accountsSearchArray' 
        pageVariable='accountsSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['Email','SurName','FirstName','Phone','IsEmailConfirmed','IsEmployee','IsDisabled','Roles']}  
        title="Uživatské účty"
        titleCount = {Number(localStorage.getItem('lastAccountsSearchTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        
        
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam uživatelských účtů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head" style={{cursor: 'pointer'}} title='Jméno uživatele zadané při registraci na portál' onClick={() =>  handleOrderChange((localStorage.getItem('accountsOrderBy') !== 'FullName' ) ? 'FullName' : 'FullNameDesc'  )}>
                  Jméno
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('accountsOrderBy') === 'FullName' ) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('accountsOrderBy') === 'FullNameDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" style={{cursor: 'pointer'}} title='E-mail slouží jako přihlašovací jméno do zákaznického portálu' onClick={() =>  handleOrderChange((localStorage.getItem('accountsOrderBy') === 'Email' || localStorage.getItem('accountsOrderBy') === null || localStorage.getItem('accountsOrderBy') === undefined) ? 'EmailDesc' : 'Email'  )}>
                  Email
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('accountsOrderBy') === 'Email' || localStorage.getItem('accountsOrderBy') === null || localStorage.getItem('accountsOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('accountsOrderBy') === 'EmailDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" title='Aktuální stav uživatelského účtu'>Stav</th>
                <th className="jsw-table-head" title='Role, které má uživatelský účet přiřazeny'>Role</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('accountsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('accountsSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastAccountsSearchTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


 
      
      
      
    </Container>
  )  
  
}

export default withRouter(observer(AccountsList))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation";

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface CustomersDeliveryAddressUpdateProps {
  label: string,
  showIcon: boolean,
  block: boolean,
  validateOnMount : boolean
}

const CustomersDeliveryAddressUpdate: React.FC<CustomersDeliveryAddressUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)  
  
  // Připojení store
  const rootStore = useStore()
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním dodací adresy
  const handleOnClickDeleteDeliveryAddress = () => {
    (async () => {
      if (await confirm("Opravdu chcete nastavit dodací adresu stejnou jako fakturační?",'Ano, nastavit', 'Ne', {title: 'Změna dodací adresy'})) {
        deleteDeliveryAddress()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání dodací adresy
  const deleteDeliveryAddress = () => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 
      // Zavolání API     
      await axios.delete(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id+'/address', { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání smazat i ze store
          customer?.deleteDeliveryAddress()
  
        }).catch(error => {
          
        });
   
      })()    
  }

  // Funkce pro změnu adresy
  const handleUpdate = (nameData:string, titleData:string, firstNameData:string, surNameData:string, titleAfterData:string, streetData:string, houseNumberData:string, identificationNumberData:string, postalCodeData:string, cityData:string, phoneNumberData:string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      await axios.patch(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id+'/address', { addressType: 'delivery', name: nameData, title: titleData, firstName: firstNameData, surName: surNameData, titleAfter: titleAfterData, street: streetData, houseNumber: houseNumberData, identificationNumber: identificationNumberData, postalCode: postalCodeData, city: cityData, phoneNumber: phoneNumberData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Změna dat ve Store
          customer?.setDeliveryAddress(nameData, titleData, firstNameData, surNameData, titleAfterData, streetData, houseNumberData, identificationNumberData, postalCodeData, cityData, phoneNumberData)
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    name: Yup.string().when(['surName'], {
      is: (surName) => {
        return !(surName)
      },
      then: Yup.string().required('Pokud není vyplněno příjmení, musíte zadat obchodní název.')
    }),
    street: Yup.string()
    .required('Ulice musí být vyplněna!'),
    houseNumber: Yup.string()
    .required('Číslo popisné musí být vyplněno!'),
    postalCode: Yup.string()
    .required('PSČ musí být vyplněno!'),
    city: Yup.string()
    .required('Město musí být vyplněno!'),
    phoneNumber: Yup.string()
    .required('Hodnota musí být vyplněna!')
    .matches(/^([0-9+ ]{1,100})$/, 'Telefonní číslo může obsahovat pouze číslice, mezeru a znak +')
    .max(17, 'Telefon může obsahovat maximálně 17 znaků.'),
      
    
  })   
  return (
    <span className="button-box">
      <div className={rights && customer?.isActive ? '' : 'nodisplay'}>
        <Button variant="primary" size="sm" onClick={handleShow}>
          {props.showIcon && <span><i className="fas fa-map-marked"></i>&nbsp;</span>}
          {customer?.deliveryAddress === null ? props.label : props.label}
        </Button>&nbsp;
        <span className={customer?.deliveryAddress === null ? 'nodisplay' : ''}>
        <span className="nodisplay" >
        <Button variant="outline-danger" size="sm" onClick={() => { handleOnClickDeleteDeliveryAddress() } } >
          Smazat
        </Button>
        </span>
        &nbsp;&nbsp;<span className="as-link" onClick={() => { handleOnClickDeleteDeliveryAddress() } }>Nastavit stejné jako fakturační?</span>
        
        </span>
      </div>
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava dodacích údajů</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Dodací údaje byly úspěšně upraveny!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                name: customer?.deliveryAddress?.name !== null ? customer?.deliveryAddress?.name: '', 
                title: customer?.deliveryAddress?.title !== null ? customer?.deliveryAddress?.title : '',
                firstName: customer?.deliveryAddress?.firstName !== null ? customer?.deliveryAddress?.firstName : '',
                surName: customer?.deliveryAddress?.surName !== null ? customer?.deliveryAddress?.surName : '',
                titleAfter: customer?.deliveryAddress?.titleAfter !== null ? customer?.deliveryAddress?.titleAfter : '',
                street: customer?.deliveryAddress?.street !== null ? customer?.deliveryAddress?.street : '', 
                houseNumber: customer?.deliveryAddress?.houseNumber !== null ? customer?.deliveryAddress?.houseNumber : '', 
                identificationNumber: customer?.deliveryAddress?.identificationNumber !== null ? customer?.deliveryAddress?.identificationNumber : '',
                postalCode: customer?.deliveryAddress?.postalCode !== null ? customer?.deliveryAddress?.postalCode : '',
                city: customer?.deliveryAddress?.city !== null ? customer?.deliveryAddress?.city : '' ,
                phoneNumber: customer?.deliveryAddress?.phoneNumber !== null ? customer?.deliveryAddress?.phoneNumber : '' ,
              
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.name!, values.title!, values.firstName!, values.surName!, values.titleAfter!, values.street!, values.houseNumber!, values.identificationNumber!, values.postalCode!, values.city!, values.phoneNumber!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <FormGroup as={Col} controlId="name">
                      <FormLabel >Název</FormLabel>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name!}
                          onChange={handleChange}
                          
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="title">
                      <FormLabel >Titul</FormLabel>
                        <FormControl 
                          type="text"
                          name="title"
                          value={values.title!}
                          onChange={handleChange}
                          
                          isValid={touched.title && !errors.title} 
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} sm={4}  controlId="name">
                      <FormLabel>Jméno</FormLabel>
                        <FormControl 
                          type="text"
                          name="firstName"
                          value={values.firstName!}
                          onChange={handleChange}
                          
                          isValid={touched.firstName && !errors.firstName} 
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                        </FormGroup> 
                        <FormGroup as={Col} sm={4} md={4}  controlId="surName">
                        <FormLabel>Příjmení</FormLabel>
                        <FormControl 
                          type="text"
                          name="surName"
                          value={values.surName!}
                          onChange={handleChange}
                          
                          isValid={touched.surName && !errors.surName} 
                          isInvalid={!!errors.surName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.surName}
                        </Form.Control.Feedback>
                    </FormGroup> 
                    <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="titleAfter">
                      <FormLabel >Titul za</FormLabel>
                        <FormControl 
                          type="text"
                          name="titleAfter"
                          value={values.titleAfter!}
                          onChange={handleChange}
                          
                          isValid={touched.titleAfter && !errors.titleAfter} 
                          isInvalid={!!errors.titleAfter}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.titleAfter}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup as={Col}  controlId="street">
                      <FormLabel>Ulice</FormLabel>
                      <FormControl 
                        type="text"
                        name="street"
                        value={values.street!}
                        onChange={handleChange}
                        
                        isValid={touched.street && !errors.street} 
                        isInvalid={!!errors.street}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} lg={2} controlId="houseNumber">
                      <FormLabel>Číslo popisné</FormLabel>
                        <FormControl 
                          type="text"
                          name="houseNumber"
                          value={values.houseNumber!}
                          onChange={handleChange}
                          
                          isValid={touched.houseNumber && !errors.houseNumber} 
                          isInvalid={!!errors.houseNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.houseNumber}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} lg={2} controlId="identificationNumber">
                        <FormLabel>Číslo orientační</FormLabel>
                        <FormControl 
                          type="text"
                          name="identificationNumber"
                          value={values.identificationNumber!}
                          onChange={handleChange}
                            
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.identificationNumber}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>

                  <Form.Row>  
                    <FormGroup as={Col} lg={3} controlId="postalCode">
                      <FormLabel>PSČ</FormLabel>
                      <FormControl 
                        type="text"
                        name="postalCode"
                        value={values.postalCode!}
                        onChange={handleChange}
                        isValid={touched.postalCode && !errors.postalCode} 
                        isInvalid={!!errors.postalCode}
                      />
                        
                      <Form.Control.Feedback type="invalid">
                        {errors.postalCode}
                      </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} controlId="city">
                        <FormLabel>Město</FormLabel>
                        <FormControl 
                          type="text"
                          name="city"
                          value={values.city!}
                          onChange={handleChange}
                          
                          isValid={touched.city && !errors.city} 
                          isInvalid={!!errors.city}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </FormGroup> 
                    </Form.Row>
                    
                    <Form.Row>
                      <FormGroup as={Col} controlId="phoneNumber">
                        <FormLabel >Telefon pro dopravce</FormLabel>
                        <FormControl 
                          type="text"
                          name="phoneNumber"
                          value={values.phoneNumber!}
                          onChange={handleChange}
                          isValid={touched.phoneNumber && !errors.phoneNumber} 
                          isInvalid={!!errors.phoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>  
                     
                             
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersDeliveryAddressUpdate))

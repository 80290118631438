import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'
import CustomerHeader from '../../../parts/customerHeader/CustomerHeader.parts'

import CustomersDashboardUsersWithInvites from './components/Customers.UserManagement.UsersWithInvites.part'

import { Container } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'

function UserManagement(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
  if (!localStorage.getItem('openedCustomerId')) {
    props.history.push('/customers-open')
  }

  // Načtení Store
  const rootStore = useStore()

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'customers-user-management')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {   
        }
      }  
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { await rootStore.fetchCustomerOpened() }
      } 
      //Pokud je otevřený zákazník otevřený, tak načítáme pozvánky a uživatele
      if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive === true) {
        
        await (() => {
          const promise1 = rootStore.fetchInvite()
          const promise2 = rootStore.fetchCustomerUsers()
          return Promise.all([promise1,promise2])
        })()
        
      }
      
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }     
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <CustomerHeader />
              <Container fluid>
                <h4>Správa přístupů</h4>
                              
              </Container>

              <CustomersDashboardUsersWithInvites />
              <br /><br />
            </Loader>  
            </main>
          </div>
        </div>      
      
    </div> 
  );
}

export default observer(UserManagement)

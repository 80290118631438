import { types } from 'mobx-state-tree'

export const EmailingWhiteList = types
  .model({
    id: types.optional(types.string, ''),
    email: types.optional(types.string,''),
    note: types.optional(types.string,''),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import { Button,  Modal, Dropdown, Card, Table, Spinner, Badge } from 'react-bootstrap'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios'
import ReactDiffViewer from 'react-diff-viewer'

import moment from 'moment'

interface CustomersOtherActionsHistoryDetailProps {
  showAs: string,
  title:string,
  historyType: string,
  historyParam: string,
  historyDate: string,
  scope: string,
  operation: string,
  block: boolean,
  indexNumber: number 
}

const CustomersOtherActionsHistoryDetail: React.FC<CustomersOtherActionsHistoryDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  //const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  //const [before, setBefore] = useState('')
  //const [after, setAfter] = useState('')

  const [beforeA, setBeforeA] = useState({})
  const [afterA, setAfterA] = useState({})

  const [userFullName, setUserFullName] = useState('')
  
  //const deviceWidth:boolean = (window.innerWidth > 992 ? true:false)
  // Připojení store
  const rootStore = useStore()

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    //rootStore.deleteCustomerOpenedHistory()
    
    //sessionStorage.removeItem('historySearchPage')
    //localStorage.removeItem('customerHistoryTotalCount')

    //sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    //sessionStorage.removeItem('autoCloseModal')
    setLoaded(false);
    (async () => { 
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout') 
      }   
      
      let url = ''
      let urlByContext = ''
      let toDate:string =  moment(props.historyDate).add(-1, 'ms').toISOString();

      if (props.historyType === 'customer') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/detail' }
      if (props.historyType === 'customerContact') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/contacts/'+props.historyParam+'/detail' }
      if (props.historyType === 'customerNote') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/notes/'+props.historyParam+'/detail' }
      //if (historyType === 'offerByScenario') { url = 'offers/upgradeScenarios/'+historyParam+'/customers/'+localStorage.getItem('openedCustomerId')! }
      
      
      if (props.historyType === 'offerByScenario' || props.historyType === 'Offers') { 
        url = 'offers/'+props.historyParam+'/detail' 
        if (props.indexNumber === 9) {
          if (rootStore.customerOpenedHistoryNextSite[0] !== undefined) {
            urlByContext = 'offers/'+rootStore.customerOpenedHistoryNextSite[0].id+'/detail'
            toDate = rootStore.customerOpenedHistoryNextSite[0].startDate! 
          }
          else {
            //urlByContext = 'offers/'+props.historyParam+'/detail'
            toDate =  moment(props.historyDate).add(-1, 'ms').toISOString();
          }
        }
        else {
          if (rootStore.customerOpenedHistory[props.indexNumber+1] !== undefined) {
            urlByContext = 'offers/'+rootStore.customerOpenedHistory[props.indexNumber+1].id+'/detail' 
            toDate = rootStore.customerOpenedHistory[props.indexNumber+1].startDate! 
          }
          else {
            toDate =  moment(props.historyDate).add(-1, 'ms').toISOString();
          }
        }
      }

      
      
      if (props.historyType === 'services') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/services/detail' }
      if (props.historyType === 'categories') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/categories/detail' }
      //if (props.historyType === 'customer_licenses') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/categories' }
      //if (props.historyType === 'billing_address') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/categories' }
      //if (props.historyType === 'delivery_address') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/categories' }
      if (props.historyType === 'licenses' || props.historyType === 'license') { url = 'licenses/'+props.historyParam+'/detail' }
      if (props.historyType === 'template') { url = 'templates/'+props.historyParam+'/detail' }      
      
      
      
      
      
      //console.log(moment(props.historyDate).add(-1, 'ms').utc())
      //toDate =  moment(props.historyDate).add(-1, 'ms').toISOString();
      //console.log(toDate)
      

      let include:boolean = true


      //if (props.historyType === 'contact') {
        let urlBefore:string = urlByContext !== '' ? urlByContext : url

        await axios.get(getUrl('URL_CHANGES')+urlBefore,{
          headers: {
            'Authorization': 'Bearer ' + aToken
          },params: {
            toDate,include
          }  }).then(response => {
            //console.log(response.data.detail)
            //setBefore(JSON.stringify(response.data.detail))
            if ((props.historyType === 'categories') || (props.historyType === 'services')) {
              setBeforeA(response.data.items)
            }
            else {
              setBeforeA(response.data.detail)
            }
            
            //console.log(JSON.stringify(beforeA))
            //setBefore(response.data.detail)
            //beforeTxt = response.data.detail
            
          //return response.data
          
          
        }).catch(error => {
          
        });

        toDate = props.historyDate;
        //console.log(toDate)

        await axios.get(getUrl('URL_CHANGES')+url,{
          headers: {
            'Authorization': 'Bearer ' + aToken
          },params: {
            toDate,include
          }  }).then(response => {
            //console.log(response.data.detail)

            if (response.data.userFirstName !== null) {
              if (response.data.userSurName !== null) {
                setUserFullName(response.data.userFirstName + ' ' + response.data.userSurName)
              }
              else {
                setUserFullName(response.data.userSurName)
              }
            }
            else {
              if (response.data.userSurName !== null) {
                setUserFullName(response.data.userSurName)
              }
              else {
                
              }
            }

            //setAfter(JSON.stringify(response.data.detail))
            if ((props.historyType === 'categories') || (props.historyType === 'services')) {
              setAfterA(response.data.items)
            }
            else {
              setAfterA(response.data.detail)
            }
            //console.log(JSON.stringify(afterA))
        }).catch(error => {
          
        });
      //}
      await console.log(JSON.stringify(afterA))
      //console.log(JSON.stringify(beforeJSON))
      //setBefore(JSON.stringify(beforeJSON))
      //await console.log(before)
      //await rootStore.fetchCustomerOpenedHistory(props.historyType, props.historyParam, Number(sessionStorage.getItem('HistorySearchPage')) === 0 ? 1:Number(sessionStorage.getItem('HistorySearchPage'))) 
      setLoaded(true)
    })()
    setError(null)
    //setShowInfo(false)
    //setShowForm('')
    setShow(true)

  }

    //Počet záznamů
    //const countData:number = Object.keys(rootStore.customerOpenedHistory).length
    //before = JSON.stringify(beforeJSON)


  return (
    <span>
      {rights &&<>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Detail</Dropdown.Item>}
        {props.showAs === 'button' &&<Button block={props.block} size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Detail</Button>}
      </>}
      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90w"
        style={{maxWidth: 'none !important'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Historie změn - detail<br /><Badge variant="secondary" style={{fontSize: '20px'}}>{props.title}</Badge></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '80vh'}}>
      
                {loaded === false &&<div style={{textAlign: 'center', minHeight: '100px', paddingTop: '30px'}}><Spinner animation="border"  /></div>}
                
                {loaded === true &&<>

                  <div className="showMobile">
                    <Card>
                      <Card.Body>
                          <div className="float-right">
                          </div>
                          <strong>Datum změny:</strong>&nbsp;{moment(props.historyDate).format("DD.MM.YYYY HH:mm")}<br />
                          <strong>Operace:</strong>&nbsp;{props.operation}<br />
                          <strong>Oblast:</strong>&nbsp;{props.scope}<br />
                          <strong>Změnil:</strong>&nbsp;<i>{userFullName}</i>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="showWeb">
                  <Table responsive hover>
                    <thead>
                      <tr className="jsw-table-head">
                        <th className="jsw-table-head">Datum a čas</th>
                        <th className="jsw-table-head">Operace</th>
                        <th className="jsw-table-head">Oblast</th>
                        <th className="jsw-table-head">Změnil</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{moment(props.historyDate).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{props.operation}</td>
                        <td>{props.scope}</td>
                        <td>{userFullName}</td>
                      </tr>                     
                    </tbody>
                  </Table> 
                  </div>
                  <hr />
                  <div className="showWeb">
                    <ReactDiffViewer leftTitle='Stav před změnou' rightTitle='Stav po změně' oldValue={JSON.stringify(beforeA, null, ' ')} newValue={JSON.stringify(afterA, null, ' ')} splitView={true} />
                  </div>
                  <div className="showMobile">
                    <ReactDiffViewer leftTitle='Seznam změn' rightTitle='Stav po změně' oldValue={JSON.stringify(beforeA, null, ' ')} newValue={JSON.stringify(afterA, null, ' ')} splitView={false} />
                  </div>
                  
                  
                  </>}
                
                {error}
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsHistoryDetail))

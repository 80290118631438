import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'

interface DeliverySubmissionsProps {
      
}

const SelectOptionsSubmissions: React.FC<DeliverySubmissionsProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootStore = useStore()

  // Seznam typů kontaktu pro rozevírací seznam
  const items = rootStore.submissionsAll
  .filter(s => s.isExported === false && s.isSubmitted === false)
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.distributionDate; 
    var sort1B = b.distributionDate; 

     
    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }          
    return 0;
  })
  .map(item => (
    <option key={item.id} value={item.id}>{moment(item.distributionDate).format('DD.MM.YYYY HH:mm')} - {item.note}</option>
  ))   

  return (
    <>
      {items}
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsSubmissions))
import { types } from 'mobx-state-tree'

export const DialsOperationsPreferences = types
  .model({
    id: types.optional(types.string, ''),
    key: types.optional(types.string, ''),
    value: types.maybeNull(types.string),
  })
  .views(self => ({
    get keyName() {
      let name:string = ''
      
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SolverUserId') name = 'Uživatel odpovědný za vyřešení požadavku'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportThemeIdForDuel') name = 'Téma označující vztah k programu DUEL'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_AuthorId') name = 'Uživatel vystupující jako autor požadavku'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportTypeId') name = 'Typ kontaktu, se kterým je požadavek založen'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportThemeIdForStereo') name = 'Téma označující vztah k programu STEREO'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_ServiceId') name = 'Služba Instalace upgrade technikem JSW'

      return name
    },

    get order() {
      let order:number = 999
      
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SolverUserId') order = 1
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportThemeIdForDuel') order = 5
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_AuthorId') order = 2 
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportTypeId') order = 3
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportThemeIdForStereo') order = 6
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_ServiceId') order = 4

      return order
    },

    get valueType() {
      let type:string = ''
      
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SolverUserId') type = 'user'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportThemeIdForDuel') type = 'supportTheme'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_AuthorId') type = 'user'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportTypeId') type = 'supportType'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_SupportThemeIdForStereo') type = 'supportTheme'
      if (self.key === 'AutoGeneratedSupport_UpgradeInstallation_ServiceId') type = 'service'

      return type
    },

    get valueName() {
      let name:string = ''
      
      if (self.key === 'startPage' && self.value === 'O') name = 'Přepnout zákazníka'
      if (self.key === 'startPage' && self.value === 'S') name = 'Kontakty'
      if (self.key === 'startPage' && self.value === 'L') name = 'Poslední navštívená stránka'

      
      if (self.key === 'supportDefaultFilter' && self.value === 'MyNotFinish') name = 'Moje nevyřešené'
      if (self.key === 'supportDefaultFilter' && self.value === 'MyNotFinishAfterTerm') name = 'Moje nevyřešené - po termínuv'
      if (self.key === 'supportDefaultFilter' && self.value === 'AllNotFinishAfterTerm') name = 'Všechny nevyřešené - po termínu'

      if (self.key === 'supportDefaultTheme') name = self.value!
      


      return name
    }
  }))
  .actions(self => ({
    

  }))

import { types } from 'mobx-state-tree'

export const LoggedUserPreferences = types
  .model({
    key: types.optional(types.string, ''),
    value: types.maybeNull(types.string),
  })
  .views(self => ({
    get keyName() {
      let name:string = ''
      
      if (self.key === 'startPage') name = 'Startovací stránka po otevření portálu'
      if (self.key === 'supportDefaultFilter') name = 'Výchozí filtr při otevření kontaktů'
      if (self.key === 'supportDefaultTheme') name = 'Automatický výběr tématu u nového kontaktu'
      if (self.key === 'meetingsDefaultFilter') name = 'Výchozí filtr při otevření porad'
      if (self.key === 'tasksDefaultFilter') name = 'Výchozí filtr při otevření úkolů'

      return name
    },
    get valueName() {
      let name:string = ''
      
      if (self.key === 'startPage' && self.value === 'O') name = 'Přepnout zákazníka'
      if (self.key === 'startPage' && self.value === 'S') name = 'Kontakty'
      if (self.key === 'startPage' && self.value === 'L') name = 'Poslední navštívená stránka'
      if (self.key === 'startPage' && self.value === 'M') name = 'Porady'
      if (self.key === 'startPage' && self.value === 'T') name = 'Úkoly'

      
      if (self.key === 'supportDefaultFilter' && self.value === 'MyNotFinish') name = 'Moje nevyřešené'
      if (self.key === 'supportDefaultFilter' && self.value === 'MyNotFinishAfterTerm') name = 'Moje nevyřešené - po termínu'
      if (self.key === 'supportDefaultFilter' && self.value === 'AllNotFinishAfterTerm') name = 'Všechny nevyřešené - po termínu'

      if (self.key === 'supportDefaultTheme') name = self.value!

      if (self.key === 'tasksDefaultFilter' && self.value === 'MyNotFinishTasks') name = 'Moje nesplněné'
      if (self.key === 'tasksDefaultFilter' && self.value === 'MyNotFinishTasksPrivate') name = 'Moje soukromé nesplněné'
      if (self.key === 'tasksDefaultFilter' && self.value === 'MyNotFinishTasksAfterTerm') name = 'Moje nesplněné - po termínu'
      if (self.key === 'tasksDefaultFilter' && self.value === 'AllNotFinishTasksAfterTerm') name = 'Všechny nesplněné - po termínu'
      if (self.key === 'tasksDefaultFilter' && self.value === 'AuthorNotFinishTasks') name = 'Mnou zadané - nesplněné'
      if (self.key === 'tasksDefaultFilter' && self.value === 'AuthorNotFinishTasksAfterTerm') name = 'Mnou zadané - nesplněné po termínu'

      if (self.key === 'meetingsDefaultFilter' && self.value === 'UnreadMeetings') name = 'Nepřečtené (kterých se účastním)'

      return name
    }
  }))
  .actions(self => ({
    

  }))

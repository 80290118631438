import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getDataFiltersNewFirstUrlPart, getDataFiltersNewProgramId, getDataFiltersNewIsStereoLan, getDataFiltersNewOperationNameForControl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, Badge, Spinner } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
import { useStore, useUpgradeStore } from '../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
import NumberFormat from 'react-number-format'

import OthersOperationsDetail from '../../pages/others/operations/components/Others.Operations.Detail.part'

interface CustomersOtherActionsUpgradeCashCreateFinalDocumentsProps {
  mode: string,
  id: string,
  //filterId:number
  showAs: string,
  filterId: string,
  info: string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined|null,
  searchValue?:string|undefined|null,
  searchType?:string|undefined|null, 
  searchScenario?:string|undefined|null,
  count?: number|undefined
}

const CustomersOtherActionsUpgradeCashCreateFinalDocuments: React.FC<CustomersOtherActionsUpgradeCashCreateFinalDocumentsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  const rootStore = useStore()
  let operations = rootStore.getRuningOperationsByName(getDataFiltersNewOperationNameForControl(props.filterId))
  //Počet záznamů
  const countData:number = Object.keys(operations).length

  // Připojení store
  const rootUpgradeStore = useUpgradeStore()
  //const upgradeScenarioId:string = rootUpgradeStore.upgradeOffersScenarios.find(uos => uos.program.id === getDataFiltersNewProgramId(props.filterId))?.id!

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    sessionStorage.removeItem('first-useful-number')
  }
  const handleShow = async () => {
    await rootStore.fetchOperations()
    rootStore.fetchStatesForOperations()
    //operations = await rootStore.getOperationsByName(getDataFiltersNewOperationNameForControl(props.filterId))
    
    if (props.filtered === true) {
      await rootStore.fetchDatafiltersOffersNewForFilteredActions(Number(props.filterId),props.searchValue,props.searchType,props.searchScenario)
    }

    sessionStorage.removeItem('first-useful-number')
    await rootUpgradeStore.getLastNumberForInvoice('', 'T')

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Funkce pro odeslání e-mailu
  const handleCreateFinalDocuments = (dateOfIssueData: string, numberingData:boolean, numberFromData:number, newInvoiceNumbersData:boolean) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend={}

      let dOI:string = moment(dateOfIssueData + ' ' + moment().format('HH:mm:ss.SSS')).toJSON()

      if (numberingData === true) {
      dataToSend = {
        mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', 
        selectedIds: props.mode === 'all' ? props.filtered === true ? rootStore.datafiltersOffersNewForFilteredActions.map(dfon => dfon.offerId) : [] : props.multiselect === true ? rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true).map(dfon => dfon.offerId) : [props.id], 
        dateOfIssue: dOI, 
        isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), 
        timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')),
        invoiceNumberFrom: numberFromData,
        newInvoiceNumbers: newInvoiceNumbersData
        }

      }
      else {
        dataToSend = {
          mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', 
          selectedIds: props.mode === 'all' ? props.filtered === true ? rootStore.datafiltersOffersNewForFilteredActions.map(dfon => dfon.offerId) : [] : props.multiselect === true ? rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true).map(dfon => dfon.offerId) : [props.id],  
          dateOfIssue: dOI, 
          isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), 
          timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')),
          newInvoiceNumbers: newInvoiceNumbersData
          }
  
        }


      // Zavolání API
      if (props.mode === "all") {
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/final', dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response)
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API
      
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/final', dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)

          
          
          

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

    })()
  }

  let d = new Date();
  let year:number = d.getFullYear();
  let month:number = d.getMonth();
  let monthTxt:string = month<9 ? '0':''
  let day:number = d.getDate();
  let dayTxt:string = day<10 ? '0':''
  let todayTxt:string = String(year) + '-' + monthTxt + String(month+1) + '-' + dayTxt + String(day)

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    confirmation: Yup.boolean()
    .oneOf([true], 'Není zatrhnuto potvrzení!'),
    dateOfIssue: Yup.date()
    .min(new Date(year-1, month, day), 'Datum nesmí být starší než jeden rok!')
    .max(new Date(year+10, month, day), 'Datum je v příliš vzdálené budoucnosti!')
    .required('Datum vystavení musí být vyplněn!'),
  })   
  return (
    <span>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Vytvoření finálních dokumentů'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Vytvoření finálních dokumentů'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvoření finálních dokumentů'}
          </Dropdown.Item>}
          {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Vytvoření finálních dokumentů'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Vytvoření finálních dokumentů'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvoření finálních dokumentů'}
          </Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mode === 'all' && <>Hromadné vytvoření finálních dokumentů (<NumberFormat displayType={'text'} thousandSeparator={' '} value={localStorage.getItem('lastDatafilterOfferTotalCount')!} />)</>}
            {(props.mode === 'selected' && props.multiselect === true) && <>Vytvoření finálních dokumentů ({props.count})</>}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvoření finálních dokumentů'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(props.mode === 'selected' && props.multiselect !== true) &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}
          
          <Alert variant="success" show={showInfo}>
            <strong>Požadavek na vytvoření finálních dokumentů byl odeslán!​</strong>
            
          </Alert>  
          <div className={showForm} >
            {props.mode === 'alll' &&<div style={{fontStyle: 'italic'}}>
              <i className="fas fa-info-circle"></i>&nbsp;Hromadné operace probíhájí za všechny záznamy. Případně použitý filtr nebude zohledněn.<br /><br />
            </div>}
            
            <div className={countData === 0 ? 'nodisplay' : ''}>
              <br /><strong>Právě probíhají operace, které mohou mít vliv na tuto akci:</strong><hr />
              {operations.map((operation) => (
                <div key={operation.id}>
                  {(operation.isFinished !== true && operation.stateLoaded !== true) 
                    ? 
                    <Spinner animation='border' size="sm" /> 
                    : 
                    <>
                      {operation.state === 1 && <Badge variant="primary">Probíhá</Badge>}
                      {operation.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
                      {operation.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
                      {operation.state === 4 && <Badge variant="success">Dokončeno</Badge>}
                    </>}
                  &nbsp;
                  {operation.nameForMortal} 
                  &nbsp;&nbsp;&nbsp;
                  <div className="float-right">
                  <OthersOperationsDetail id={operation.id} showAs='button' />
                  </div>
                  <hr />
                </div>
               ))}
              <br />
            </div>
            
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                scenarioId: '',
                dateOfIssue: todayTxt,
                confirmation: false,
                numbering: true,
                numberFrom: 0,
                extension:false,
                newInvoiceNumbers: false
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleCreateFinalDocuments(values.dateOfIssue, values.numbering, values.numberFrom, values.newInvoiceNumbers)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="value">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum vystavení</FormLabel>
                      <Col>
                        <FormControl 
                          type="date"
                          name="dateOfIssue"
                          value={values.dateOfIssue!}
                          onChange={handleChange}
                          isValid={touched.dateOfIssue && !errors.dateOfIssue} 
                          isInvalid={!!errors.dateOfIssue}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.dateOfIssue}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Číslování dokladů</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 
                  <div style={{display: 'none'}}>
                    <FormGroup controlId="numbering">
                      <Form.Row>
                        
                        <Col md="1">
                          <FormControl 
                            type="checkbox"
                            name="numbering"
                            size="sm"
                            className="checkbox-small"
                            style={{marginTop: '10px'}}
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.numbering}
                            
                            isValid={touched.numbering && !errors.numbering} 
                            isInvalid={!!errors.numbering}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.numbering}
                          </Form.Control.Feedback>
                        </Col>
                        <FormLabel column>Použít vlastní číslování?</FormLabel>
                      </Form.Row>  
                    </FormGroup>
                  </div>
                  {values.numbering &&
                  <FormGroup controlId="numberFrom">
                    <Form.Row>
                      <FormLabel column lg={3}>Číslovat od</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          min={0}
                          name="numberFrom"
                          value={values.numberFrom!}
                          onChange={handleChange}
                          isValid={touched.numberFrom && !errors.numberFrom} 
                          isInvalid={!!errors.numberFrom}
                        />
                        <span><i className="fas fa-info-circle"></i>&nbsp;<i>Doporučené číslo: {sessionStorage.getItem('first-useful-number')}</i></span>
                        <Form.Control.Feedback type="invalid">
                          {errors.numberFrom}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  }

                    <FormGroup controlId="extension">
                      <Form.Row>      
                        <Col md="1">
                          <FormControl 
                            type="checkbox"
                            name="extension"
                            size="sm"
                            className="checkbox-small"
                            style={{marginTop: '10px'}}
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.extension}
                            
                            isValid={touched.extension && !errors.extension} 
                            isInvalid={!!errors.extension}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.extension}
                          </Form.Control.Feedback>
                        </Col>
                        <FormLabel column>Zobrazit rozšířené možnosti</FormLabel>
                      </Form.Row>  
                    </FormGroup>
                  {values.extension &&
                  <>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Rozšířené možnosti</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="newInvoiceNumbers">
                    <Form.Row>
                      
                      <Col md="1">
                      <FormControl 
                            type="checkbox"
                            name="newInvoiceNumbers"
                            size="sm"
                            className="checkbox-small"
                            style={{marginTop: '10px'}}
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.newInvoiceNumbers}
                            
                            isValid={touched.newInvoiceNumbers && !errors.newInvoiceNumbers} 
                            isInvalid={!!errors.newInvoiceNumbers}
                          />
                        
                        
                      </Col>
                      <FormLabel column>Pro všechny doklady použít nová čísla</FormLabel>
                    </Form.Row>  
                  </FormGroup> 
                  <hr />
                  </>
                  }

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Potvrzení</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="confirmation">
                    <Form.Row>
                      
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="confirmation"
                          size="sm"
                          className="checkbox-small"
                          style={{marginTop: '10px'}}
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.confirmation}
                          
                          isValid={touched.confirmation && !errors.confirmation} 
                          isInvalid={!!errors.confirmation}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmation}
                        </Form.Control.Feedback>
                      </Col>
                      <FormLabel column>Pro pokračování v operaci je nezbytné potvrdit, že ji chcete spustit.</FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám požadavek...' : 'Odeslat požadavek'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsUpgradeCashCreateFinalDocuments))

import React from 'react'

function HomeInfo(props:any) {
   
  return( 
    <div className="nodisplay">
      <h3><i className="fas fa-address-book"></i>&nbsp;&nbsp;Správa údajů</h3>
      <p>Spravujte pohodlně vaše fakturační a kontaktní údaje, dodací adresy apod.</p>
      <br />
      <h3><i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;Elektronické objednání upgrade</h3>
      <p>Objednávku nových verzí našich programů provedete snadno bez nutnosti tisku</p>
    </div>
  )
}
  
export default HomeInfo

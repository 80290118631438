import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getModuleOrder, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Card, CardDeck, Modal, Alert, Dropdown, DropdownButton, ButtonGroup} from 'react-bootstrap'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation"

import { useStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'
import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'
import CustomersLicensesEdit from './Customers.Licenses.Edit.part'
import CustomersLicensesAdd from './Customers.Licenses.Add.part'


const CustomersLicensesActive: React.FC = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [showD, setShowD] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showE, setShowE] = useState(false)
  const [showED, setShowED] = useState(false)

  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setShowD(false)
    setShowED(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    //setShowForm('')
    setShow(true)
  }  
  const handleShowD = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    //setShowForm('')
    setShowD(true)
  }  


  // Dotaz před akcí
  const handleOnClickDeactivate = (licenseId: string, licenseName: string) => {
    (async () => {
      if (await confirm("Opravdu chcete licenci "+licenseName+" zneaktivnit?",'Ano, zneaktivnit', 'Ne', {title: 'Potvrzení zneaktivnění licence'})) {
        handleDeactivateLicense(licenseId,false)
      } else {
      
      }  
    })()
  }

  // Dotaz před akcí
  const handleOnClickDelete = (licenseId: string, licenseName: string) => {
    (async () => {
      if (await confirm("Opravdu chcete licenci "+licenseName+" smazat?",'Ano, smazat', 'Ne', {title: 'Potvrzení smazání licence'})) {
        handleDeleteLicense(licenseId,false)
      } else {
      
      }  
    })()
  }

  // Funkce pro deaktivaci licence
  const handleDeactivateLicense = (licenseIdData: string, isActiveData: boolean) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_LICENSES_STATE')+licenseIdData+"/state", { licenseId: licenseIdData, customerId: localStorage.getItem('openedCustomerId')!, isActive: isActiveData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
          
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
            handleShow()
            setShowInfo(true)
            sessionStorage.setItem('autoCloseModal','1');

            // Po 2800 ms celé modální okno zavřeme
            setTimeout(() => {
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                setShow(false)
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                //setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)
        });
    })()
  }

// Funkce pro deaktivaci licence
const handleDeleteLicense = (licenseIdData: string, isActiveData: boolean) => {
  setError(null)
  setLoading(true);

  // Zpracování požadavku
  (async () => {

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    // Zavolání API
    
    await axios.delete(getUrl('URL_LICENSES_CHANGE')+licenseIdData, { headers: { 'Authorization': 'Bearer ' + aToken}, data: { licenseId: licenseIdData, customerId: localStorage.getItem('openedCustomerId')!}}
      ).then(response => {
        rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
        
        // Nastavovačky, co se má v případě, že je to OK, zobrazit
        setLoading(false)
          handleShowD()
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShowD(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

      }).catch(error => {
        setLoading(false)
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setShowED(true)
      });
  })()
}  

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  // eslint-disable-next-line
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  //Počet záznamů
  const countData:number = Object.keys(rootStore.getCustomerOpenedLicensesActiveActual()).length + Object.keys(rootStore.getCustomerOpenedLicensesActiveDefault()).length
  const countDataStereo:number = Object.keys(rootStore.getCustomerOpenedLicensesActiveStereo()).length
  const countDataDuel:number = Object.keys(rootStore.getCustomerOpenedLicensesActiveDuel()).length

  const countDataStereoAct:number = Object.keys(rootStore.getCustomerOpenedLicensesStereoActual()).length
  const countDataDuelAct:number = Object.keys(rootStore.getCustomerOpenedLicensesDuelActual()).length

  const countDataStereoDef:number = Object.keys(rootStore.getCustomerOpenedLicensesActiveStereo().filter(l => l.license.status === 'V')).length
  const countDataDuelDef:number = Object.keys(rootStore.getCustomerOpenedLicensesActiveDuel().filter(l => l.license.status === 'V')).length

  const licensesActiveActualStereo = rootStore.getCustomerOpenedLicensesActiveStereo().map(customerOpenedLicenses => (
    <Card
      bg='light'
      text='dark'
      key={customerOpenedLicenses.license.id}
      className={customerOpenedLicenses.license.status === 'A' ? 'border-left-info':'border-left-info border-left-dotted'}
    >
    <div >
      <Card.Header as="h5">{customerOpenedLicenses.license.program.name} {customerOpenedLicenses.license.programVersion.version}{customerOpenedLicenses.license.status === 'V' ? ' (výchozí licence)':''}
      {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version+' ('+customerOpenedLicenses.license.status+')'} historyType='license' historyParam={customerOpenedLicenses.license.id!} />
            {rightsBH && customerOpenedLicenses.license.status === 'A' && <Dropdown.Item onClick={() => { handleOnClickDeactivate(customerOpenedLicenses.license.id!, customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version) } }>Zneaktivnit</Dropdown.Item>}
            {rightsBH && customerOpenedLicenses.license.program.code === 'S' && countDataStereoAct !== 0 && countDataStereoDef === 0 && <CustomersLicensesAdd showIcon={false} showAs='drop' label='Přidat výchozí licenci' programId={rootDialsStore.dialsPrograms.find(dp => dp.code === 'S')?.id!} licenseStatus='V' block={false} /> }
            {rightsBH && <Dropdown.Divider />}
            {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDelete(customerOpenedLicenses.license.id!, customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version+' ('+customerOpenedLicenses.license.status+')') } }>Smazat</Dropdown.Item>}
          </DropdownButton>
        </div>}      
      </Card.Header>
      <Card.Body >
         Typ licence: {customerOpenedLicenses.license.licenseType.code}, Počet PC: {customerOpenedLicenses.license.count}
        <br /><br />
        <strong>Moduly:</strong><br />
        <table>
          <tbody>
          {customerOpenedLicenses.license.modules!.slice().sort(function(a:any, b:any) {
                var nameA = getModuleOrder(a.module.code); 
                var nameB = getModuleOrder(b.module.code); 
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              }).map(modules => (<tr key={modules.module.id}><td className="licenseModuleName">{modules.module.name}</td><td>{modules.count}</td></tr>))}   
          </tbody>
        </table>     
        {rightsBH && <div className="">
        <br />
        <CustomersLicensesEdit id={customerOpenedLicenses.license.id!} label="Upravit" showIcon={false} block={false} />
        </div>}
      </Card.Body>
    </div>
    </Card>
  ))

  const licensesActiveActualDuel = rootStore.getCustomerOpenedLicensesActiveDuel().map(customerOpenedLicenses => (
    <Card
      bg='light'
      text='dark'
      key={customerOpenedLicenses.license.id}
      className={customerOpenedLicenses.license.status === 'A' ? 'border-left-danger':'border-left-danger border-left-dotted'}
    >
    <div >
      <Card.Header as="h5">{customerOpenedLicenses.license.program.name} {customerOpenedLicenses.license.programVersion.version}{customerOpenedLicenses.license.status === 'V' ? ' (výchozí licence)':''}
      {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version+' ('+customerOpenedLicenses.license.status+')'} historyType='license' historyParam={customerOpenedLicenses.license.id!} />
            {rightsBH && customerOpenedLicenses.license.status === 'A' && <Dropdown.Item onClick={() => { handleOnClickDeactivate(customerOpenedLicenses.license.id!, customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version) } }>Zneaktivnit</Dropdown.Item>}
            
            {rightsBH && customerOpenedLicenses.license.program.code === 'D' && countDataDuelAct !== 0 && countDataDuelDef === 0 && <CustomersLicensesAdd showIcon={false} showAs='drop' label='Přidat výchozí licenci' programId={rootDialsStore.dialsPrograms.find(dp => dp.code === 'D')?.id!} licenseStatus='V' block={false} /> }
            {rightsBH && <Dropdown.Divider />}
            {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDelete(customerOpenedLicenses.license.id!, customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version+' ('+customerOpenedLicenses.license.status+')') } }>Smazat</Dropdown.Item>}
          </DropdownButton>
        </div>}       
      </Card.Header>
      <Card.Body >
         Typ licence: {customerOpenedLicenses.license.licenseType.code}, Počet PC: {customerOpenedLicenses.license.count}
        <br /><br />
        <strong>Moduly:</strong><br />
        <table>
          <tbody>
          {customerOpenedLicenses.license.modules!.slice().sort(function(a:any, b:any) {
                var nameA = getModuleOrder(a.module.code); 
                var nameB = getModuleOrder(b.module.code); 
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              }).map(modules => (<tr key={modules.module.id}><td className="licenseModuleName">{modules.module.name}</td><td>{modules.count}</td></tr>))}   
          </tbody>
        </table>     
        {rightsBH && <div className="">
        <br />
        <CustomersLicensesEdit id={customerOpenedLicenses.license.id!} label="Upravit" showIcon={false} block={false} />
        </div>}
      </Card.Body>
    </div>
    </Card>
  ))  
 
  return (
    <div>
      <h4>Aktivní licence</h4>
      <br />
      {countData === 0 ? <div>Seznam aktivních licencí neobsahuje žádné záznamy.<br /><br /></div> : ''}
      
      {countDataStereo !== 0 && <CardDeck>
        {licensesActiveActualStereo}
      </CardDeck>}
      {countDataStereo !== 0 && <br />}
      {countDataStereo !== 0 && <br />}

      {countDataDuel!== 0 && <CardDeck>
        {licensesActiveActualDuel}
      </CardDeck>}
      <br />
      <ButtonGroup>
        {countDataStereoAct === 0 && <CustomersLicensesAdd showIcon={true} label='Přidat licenci Stereo' programId={rootDialsStore.dialsPrograms.find(dp => dp.code === 'S')?.id!} licenseStatus='A' block={false} /> }
        

        {countDataDuelAct === 0 && <CustomersLicensesAdd showIcon={true} label='Přidat licenci Duel' programId={rootDialsStore.dialsPrograms.find(dp => dp.code === 'D')?.id!} licenseStatus='A' block={false} /> }
        
        

      </ButtonGroup>

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Zneaktivnění licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Zneaktivnění licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Operace byla úspěšně provedena!​</strong>
            
          </Alert>  
          
        </Modal.Body>

      </Modal>

      <Modal 
        show={showED} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={showD} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Operace byla úspěšně provedena!​</strong>
            
          </Alert>  
          
        </Modal.Body>

      </Modal>


    </div>

  )  
  
}

export default withRouter(observer(CustomersLicensesActive))

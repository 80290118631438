import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUser, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button,  Form, Col, Alert, Modal, Table, Card, Badge, DropdownButton, Dropdown, ButtonGroup} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation";

import AcountsMyAcountUpdate from '../../../accounts/myAccount/components/Accounts.MyAccount.Update.part'
import CustomersOtherActionsEmailHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.EmailHistory.part'
import CustomersOtherActionsSendEmail from '../../../../parts/customersOtherActions/Customers.OtherActions.SendEmail.part'

import AcountUpdateDisable from './Customers.UserManagement.Disable.part'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'


const CustomersDashboardUsersWithInvites: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showInfo204, setShowInfo204] = useState(false)
  const [showConfirmationInfo, setShowConfirmationInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [showE, setShowE] = useState(false)
  const [showEC, setShowEC] = useState(false)

  const [showEConfirmation, setShowEConfirmation] = useState(false)
  
  // Nastavení práv pro editaci
  const invitesRights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false
  const invitesRightsSHBU:boolean = ((localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false
  const invitesRightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') ) ? true : false
  //const invitesRightsSH:boolean = ((localStorage.getItem('SmallHedgehog')! === '1') && (localStorage.getItem('BigHedgehog')! !== '1') && (localStorage.getItem('BigUser')! !== '1')) ? true : false
  const invitesRightsSHBH:boolean = ((localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigHedgehog')! === '1') ) ? true : false
  //const invitesRightsBU:boolean = ((localStorage.getItem('BigUser')! === '1')) ? true : false
  const invitesRightsDrop:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false
  
  
  const [repeatEmail, setRepeatEmail] = useState('')
  const [repeatType, setRepeatType] = useState(invitesRightsBH === true ? '1':'0')
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setShowEC(false)
    setShowEConfirmation(false)
    setShowConfirmationInfo(false)
    setError(null)
    setRepeatEmail('')
    setRepeatType(invitesRightsBH === true ? '1':'0')
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowInfo204(false)
    setShowConfirmationInfo(false)
    setShowForm('')    
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteInvite = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete pozvánku "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání pozvánky'})) {
        deleteInvite(id)
      } else {
      
      }  
    })()
  }

  // Dotaz před smazáním uživatele
  const handleOnClickDeleteUser = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete uživatele "+text+" odebrat ze správy firmy "+rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.businessName+"?",'Ano, odebrat', 'Ne', {title: 'Odebrání uživatele'})) {
        deleteCustomer(id)
      } else {
      
      }  
    })()
  } 


  // Dotaz před znovuzasláním aktivace
  const handleOnClickResendConfirmationEmail = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete uživateli "+text+" znovu zaslat aktivační e-mail?",'Ano, zaslat', 'Ne', {title: 'Opakované zaslání aktivačního emailu'})) {
        resendConfirmationEmail(id)
      } else {
      
      }  
    })()
  }

  // Načtení Store
  const rootStore = useStore()
  
  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

  const custUsers = rootStore.customerUsers
  const registeredUsersTable:any  =  custUsers.map(cu => ({ type: 'U', id: cu.id, email: cu.email, surName: cu.surName ,firstName: cu.firstName, phone: cu.phone, isEmailConfirmed: cu.isEmailConfirmed, isAdmin: cu.isAdmin, createdAt: cu.createDate, expiredAt: null, expiredDate: null, isDisabled: cu.isDisabled })) 
  //console.log(registeredUsersTable)


  const nonUsedInvites = rootStore.getInvitesUnused()
  const nonUsedInvitesTable:any = nonUsedInvites.map(cu => ({ type: 'I', id: cu.id, email: cu.email, surName: "" ,firstName: "", phone: "", isEmailConfirmed: false , isAdmin: cu.isCustomerAdmin, createdAt: cu.createdAt,  expiredAt: new Date(cu.expiredAt!).getDate()+'.'+new Date(cu.expiredAt!).getMonth()+'.'+new Date(cu.expiredAt!).getFullYear()+' '+new Date(cu.expiredAt!).getHours()+':'+new Date(cu.expiredAt!).getMinutes(), expiredDate: cu.expiredAt, isDisabled: false  })) 


  const lostInvites = rootStore.invite.filter(invite => invite.usedAt === null && new Date(invite.expiredAt!.replace(' ', 'T')) <= new Date() && registeredUsersTable.map((u: { email: any }) => u.email).includes(invite.email) === false && nonUsedInvitesTable.map((u: { email: any }) => u.email).includes(invite.email) === false)
  const lostInvitesWithoutDuplicates = lostInvites.sort(function(a:any, b:any) {
    var nameA = a.expiredAt; // ignore upper and lowercase
    var nameB = b.expiredAt; // ignore upper and lowercase
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  }).filter((invite, index, self) => index === self.findIndex((t) => (t.email === invite.email))) 
  
  const lostInvitesTable:any = lostInvitesWithoutDuplicates.map(cu => ({ type: 'I', id: cu.id, email: cu.email, surName: "" ,firstName: "", phone: "", isEmailConfirmed: false , isAdmin: cu.isCustomerAdmin, createdAt: cu.createdAt,  expiredAt: new Date(cu.expiredAt!).getDate()+'.'+new Date(cu.expiredAt!).getMonth()+'.'+new Date(cu.expiredAt!).getFullYear()+' '+new Date(cu.expiredAt!).getHours()+':'+new Date(cu.expiredAt!).getMinutes(), expiredDate: cu.expiredAt, isDisabled: false  })) 





  const usersWithInvites:any = registeredUsersTable.concat(nonUsedInvitesTable).concat(lostInvitesTable) 

  // Nastavení třídění u spojené tabulky
  usersWithInvites.sort(function(a:any, b:any) {
    var nameA = a.email.toUpperCase(); // ignore upper and lowercase
    var nameB = b.email.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  })
  //Počet záznamů
  const countData:number = Object.keys(usersWithInvites).length

  // Seznam pozvánek do řádků tabulky
  const linesUsersWithInvites = usersWithInvites.map((userInvite: { id: string ; type: string; firstName: React.ReactNode; surName: React.ReactNode; phone: React.ReactNode; email: string; isAdmin: boolean; expiredAt: string; expiredDate: string; createdAt: string; isEmailConfirmed: boolean, isDisabled: boolean }) => (
    <tr key={userInvite.id}>
      <td>{userInvite.email}</td>
      <td>{userInvite.firstName} {userInvite.surName}</td>
      <td>{userInvite.phone}</td> 
      <td>{userInvite.isAdmin !== true ? 'Uživatel' : 'Správce'}</td>
      <td>
        {userInvite.type !== 'I' 
          ? 
          <>
          <Badge variant={userInvite.isEmailConfirmed ? 'info':'warning'}>Registrovaný{userInvite.isEmailConfirmed ? '':' bez aktivace'} {!(userInvite.createdAt === null || userInvite.createdAt === '') && moment(userInvite.createdAt).locale('cs').format("D.M.YYYY H:mm")}</Badge> 
          {userInvite.isDisabled && <><br /><Badge variant="danger">Zablokovaný účet</Badge></>}
          </>
          : 
          <>
            <Badge variant="warning">Pozvaný {moment(userInvite.createdAt).locale('cs').format("D.M.YYYY H:mm")}</Badge>
            {userInvite.expiredDate !== null && <>
            <br />
            <Badge variant={new Date(userInvite.expiredDate) < new Date() ? 'danger':'success'}>Platnost pozvánky do {moment(userInvite.expiredDate).locale('cs').format("D.M.YYYY H:mm")}</Badge>
            </>}
          </>
        }
      
      </td>
      
      <td className={invitesRightsDrop ? '' : 'nodisplay'} style={{minWidth: invitesRightsSHBH ? '50px' : '50px'}}>
        <div className="table-buttons">
          {userInvite.type === 'U' &&
            <ButtonGroup>
              {userInvite.id === getUser().id &&
                <>
                  <AcountsMyAcountUpdate />
                  
                  {invitesRightsSHBH &&
                    <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                      {invitesRightsBH && <CustomersOtherActionsSendEmail mode='selected' id={userInvite.id} filterId={0} showAs='drop' toConcreteEmail={true} concreteEmail={userInvite.email} />}
                      {invitesRightsBH && <Dropdown.Divider />}
                      <CustomersOtherActionsEmailHistory showAs='drop' email={userInvite.email} />
                    </DropdownButton>
                  }
                </>
              }
              {userInvite.id !== getUser().id &&
                <>    
                  {customer?.isActive &&
                    <>
                      {invitesRightsSHBU && userInvite.isEmailConfirmed === false &&<><Button variant="primary" size="sm" onClick={() => { handleOnClickResendConfirmationEmail(userInvite.id,userInvite.email) } }  >Zaslat aktivační e-mail</Button></>} 
                      {invitesRightsDrop &&
                        <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                          {invitesRightsBH && <CustomersOtherActionsSendEmail mode='selected' id={userInvite.id} filterId={0} showAs='drop' toConcreteEmail={true} concreteEmail={userInvite.email} />}
                          {invitesRightsBH && <Dropdown.Divider />}
                          {invitesRightsSHBH &&<CustomersOtherActionsEmailHistory showAs='drop' email={userInvite.email} />}
                          {invitesRightsBH &&<AcountUpdateDisable id={userInvite.id} email={userInvite.email} action={userInvite.isDisabled ? 'E':'D'} showAs='drop' />}
                          {invitesRightsSHBH &&<Dropdown.Divider />}
                          {invitesRights &&<Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteUser(userInvite.id,userInvite.email) } } >Odebrat</Dropdown.Item>}
                        </DropdownButton>
                      }

                    </>
                  }
                  {!customer?.isActive &&
                    <>
                      {invitesRightsSHBH &&<CustomersOtherActionsEmailHistory showAs='button' email={userInvite.email} />}

                    </>
                  }
                </>
              }
              
            </ButtonGroup>
          }
          {userInvite.type === 'I' &&
            <ButtonGroup>

              {invitesRights && customer?.isActive && <Button variant="primary" size="sm" onClick={() => { setRepeatEmail(userInvite.email); setRepeatType(userInvite.isAdmin ? '1':'0'); handleShow(); }} >Opakovat pozvání</Button>}
              {invitesRightsDrop && 
                <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                  {invitesRightsBH && <CustomersOtherActionsSendEmail mode='selected' id={userInvite.id} filterId={0} showAs='drop' toConcreteEmail={true} concreteEmail={userInvite.email} />}
                  {invitesRightsBH && <Dropdown.Divider />}
                  {invitesRightsSHBH &&<CustomersOtherActionsEmailHistory showAs='drop' email={userInvite.email} />}
                  {invitesRightsSHBH && customer?.isActive &&<Dropdown.Divider />}
                  {invitesRights && customer?.isActive &&<Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteInvite(userInvite.id,userInvite.email) } } >Smazat pozvánku</Dropdown.Item>}
                </DropdownButton>
              }
            </ButtonGroup>
          }
        </div> 
      </td>
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesUsersWithInvitesCard = usersWithInvites.map((userInvite: { id: string ; type: string; firstName: React.ReactNode; surName: React.ReactNode; phone: React.ReactNode; email: string; isAdmin: boolean; expiredAt: string; expiredDate: string; createdAt: string; isEmailConfirmed: boolean, isDisabled: boolean }) => (
    <div key={userInvite.id}>
    <Card>
      <Card.Body>
        <div className="float-right">
          <div className="table-buttons">
            {userInvite.type === 'U' &&
              <ButtonGroup>
                {userInvite.id === getUser().id &&
                  <>
                    <AcountsMyAcountUpdate />
                    
                    {invitesRightsSHBH &&
                      <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                        {invitesRightsBH && <CustomersOtherActionsSendEmail mode='selected' id={userInvite.id} filterId={0} showAs='drop' toConcreteEmail={true} concreteEmail={userInvite.email} />}
                        {invitesRightsBH && <Dropdown.Divider />}
                        <CustomersOtherActionsEmailHistory showAs='drop' email={userInvite.email} />
                      </DropdownButton>
                    }
                  </>
                }
                {userInvite.id !== getUser().id &&
                  <>    
                    {customer?.isActive &&
                      <>
                        {invitesRightsSHBU && userInvite.isEmailConfirmed === false &&<><Button variant="primary" size="sm" onClick={() => { handleOnClickResendConfirmationEmail(userInvite.id,userInvite.email) } }  >Zaslat aktivační e-mail</Button></>} 
                        {invitesRightsDrop &&
                          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                            {invitesRightsBH && <CustomersOtherActionsSendEmail mode='selected' id={userInvite.id} filterId={0} showAs='drop' toConcreteEmail={true} concreteEmail={userInvite.email} />}
                            {invitesRightsBH && <Dropdown.Divider />}
                            {invitesRightsSHBH &&<CustomersOtherActionsEmailHistory showAs='drop' email={userInvite.email} />}
                            {invitesRightsBH &&<AcountUpdateDisable id={userInvite.id} email={userInvite.email} action={userInvite.isDisabled ? 'E':'D'} showAs='drop' />}
                            {invitesRightsSHBH &&<Dropdown.Divider />}
                            {invitesRights &&<Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteUser(userInvite.id,userInvite.email) } } >Odebrat</Dropdown.Item>}
                          </DropdownButton>
                        }

                      </>
                    }
                    {!customer?.isActive &&
                      <>
                        {invitesRightsSHBH &&<CustomersOtherActionsEmailHistory showAs='button' email={userInvite.email} />}

                      </>
                    }
                  </>
                }
                
              </ButtonGroup>
            }
            {userInvite.type === 'I' &&
              <ButtonGroup>

                {invitesRights && customer?.isActive && <Button variant="primary" size="sm" onClick={() => { setRepeatEmail(userInvite.email); setRepeatType(userInvite.isAdmin ? '1':'0'); handleShow(); }} >Opakovat pozvání</Button>}
                {invitesRightsDrop && 
                  <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                    {invitesRightsBH && <CustomersOtherActionsSendEmail mode='selected' id={userInvite.id} filterId={0} showAs='drop' toConcreteEmail={true} concreteEmail={userInvite.email} />}
                    {invitesRightsBH && <Dropdown.Divider />}
                    {invitesRightsSHBH &&<CustomersOtherActionsEmailHistory showAs='drop' email={userInvite.email} />}
                    {invitesRightsSHBH && customer?.isActive &&<Dropdown.Divider />}
                    {invitesRights && customer?.isActive &&<Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteInvite(userInvite.id,userInvite.email) } } >Smazat pozvánku</Dropdown.Item>}
                  </DropdownButton>
                }
              </ButtonGroup>
            }
          </div> 
        </div>

        <div style={{minWidth:'200px', float:'left'}}>
          {userInvite.type === 'U' &&
            <>
              <strong>{userInvite.firstName} {userInvite.surName}</strong><br />(<i>
              {userInvite.email}
              {userInvite.phone !== '' ? ', ':''}{userInvite.phone}, {userInvite.isAdmin !== true ? 'Uživatel' : 'Správce'}  
              </i>)<br /><Badge variant={userInvite.isEmailConfirmed ? 'info':'warning'}>Registrovaný{userInvite.isEmailConfirmed ? '':' bez aktivace'} {userInvite.createdAt !== null && moment(userInvite.createdAt).locale('cs').format("D.M.YYYY H:mm")}</Badge>
              {userInvite.isDisabled && <><br /><Badge variant="danger">Zablokovaný účet</Badge></>}
            </>
          }
          {userInvite.type === 'I' &&
            <>
              <strong>{userInvite.email}</strong><br />(<i>
              {userInvite.isAdmin !== true ? 'Uživatel' : 'Správce'}     
              </i>)<br /><Badge variant="warning">Pozvaný {moment(userInvite.createdAt).format("D.M.YYYY H:mm")}</Badge> 
              {userInvite.expiredDate !== null && <>
                <br />
                <Badge variant={new Date(userInvite.expiredDate) < new Date() ? 'danger':'success'}>Platnost do {moment(userInvite.expiredDate).locale('cs').format("D.M.YYYY H:mm")}</Badge>
                </>}
            </>
          }
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteInvite = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      await axios.delete(getUrl('URL_INVITES')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchInvite()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }
  // Funkce pro odebrání uživatele
  const deleteCustomer = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }  
      // Zavolání API
      await axios.delete(getUrl('URL_CUSTOMER_USERS')+localStorage.getItem('openedCustomerId')+'/users/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam uživatelů
          rootStore.fetchCustomerUsers()
  
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowEC(true)           
        });
   
      })()    
  }

 // Funkce pro znovuzaslání potvrzení
 const resendConfirmationEmail = (id:string) => {
  
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }  
    // Zavolání API
    await axios.post(getUrl('URL_RESEND_CONFIRMATION_EMAIL'), {userId: id}, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        setShowConfirmationInfo(true)

        // Po 2800 ms celé modální okno zavřeme
        setTimeout(() => {
          if (sessionStorage.getItem('autoCloseModal') === '1') {  
            setShowConfirmationInfo(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
          }
        }, 2800);

      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setShowEConfirmation(true)           
      });
 
    })()    
}


  // Funkce pro založení nové pozvánky
  const handleNewUser = (emailData:string, roleData:string) => {
    setError(null)
    setLoading(true)

    // Nastavení oprávnění do const, aby to mělo správný typ. Select ve formuláři posílá string 0 nebo 1
    const role:boolean = roleData === '0' ? false : true ;

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_INVITES'), { email: emailData, customerId: localStorage.getItem('openedCustomerId'), isCustomerAdmin: role }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          // Pokud se vrátí status 201
          if (response.status === 201) {
            setShowInfo(true)
          }
          else {
            setShowInfo204(true)
            rootStore.fetchCustomerUsers()
          }
          
          rootStore.fetchInvite()
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              //setShowInfo(false)
              //setShowInfo204(false)
              //setShowForm('')
              sessionStorage.removeItem('autoCloseModal')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewUser = Yup.object().shape({
    email: Yup.string()
      .email('E-mailová adresa je ve špatném formátu!')
      .required('E-mail musí být vyplněn!')
  })   

  //const datum = new Date().toISOString()
  return (
    <Container fluid>
      <br />
      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam uživatelů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesUsersWithInvitesCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Email</th>
                <th className="jsw-table-head">Jméno</th>
                <th className="jsw-table-head">Telefon</th>
                <th className="jsw-table-head">Typ účtu</th>
                <th className="jsw-table-head">Stav</th>
                <th className={invitesRightsDrop ? 'jsw-table-head' : 'nodisplay'}>Akce</th>
              </tr>
            </thead>
            <tbody>
              {linesUsersWithInvites}
            </tbody>
          </Table>
        </div>  
      </div>  
      {invitesRights && customer?.isActive &&
      <Button variant="success" onClick={handleShow}>
        + Pozvat uživatele ke správě firmy
      </Button>
      }

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání pozvánky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      <Modal 
        show={showEC} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Odebrání uživatele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={showEConfirmation} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Opakované zaslání aktivačního emailu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      <Modal 
        show={showConfirmationInfo} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Opakované zaslání aktivačního emailu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">
              <strong>Aktivační e-mail byl úspěšně odeslán!​</strong>
          </Alert>
        </Modal.Body>
      </Modal>


      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Pozvánka ke správě firmy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Pozvánka byla úspěšně odeslána!​</strong><br />
            Na Vámi zadaný e-mail byly odeslány další informace.
          </Alert> 
          <Alert variant="success" show={showInfo204}>
            <strong>Uživatel byl přiřazen ke správě firmy!​</strong>
          </Alert> 
          <div className={showForm} >
            <Formik
              validationSchema={schemaNewUser}
              initialValues={{ email: repeatEmail, role: repeatType }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewUser(values.email, values.role)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <FormGroup controlId="formEmail">
                    <Form.Row>
                      <FormLabel column lg={3}>Email</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                          autoFocus={true}
                        />
                         
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="formRole">
                    <Form.Row>
                      <FormLabel column lg={3}>Typ účtu</FormLabel>
                      <Col>
                      <Form.Control as="select" 
                        name="role" 
                        onChange={handleChange}
                        onBlur={handleBlur}>
                          <option value="0" selected={repeatType === '0'}>Uživatel (pouze prohlížení)</option>
                          <option value="1" selected={repeatType === '1'}>Správce (plný přístup)</option>
                        </Form.Control>
                        
                        
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                    
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={loading} block>{loading ? 'Odesílám pozvánku...' : 'Odeslat pozvánku'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
      
    </Container>
  )  
  
}

export default withRouter(observer(CustomersDashboardUsersWithInvites))

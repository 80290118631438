import { types } from 'mobx-state-tree'

export const DialsDeliveryCzechPostProductsAll = types
  .model({
    id: types.optional(types.string, ''),
    prefix: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    isActive: types.optional(types.boolean, false),
    userId: types.maybeNull(types.string),
    checked: types.optional(types.boolean, false)
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    setChecked(checked:boolean) {
      self.checked = checked
    },


  }))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../../Utils/Confirmation";

import DialsFreeTextUpdate from './Dials.FreeText.Update.part'

import { useDialsStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { TextEditor } from "../../../../../Utils/draft-js/TextEditor";


const DialsFreeTextList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)


  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteText = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete volný text "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání volného textu'})) {
        deleteDialsFreeText(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  const rootDialsStore = useDialsStore()
  
  const freeTexts = rootDialsStore.dialsFreeText.slice().sort(function(a:any, b:any) {
    var sort1A = a.name; 
    var sort1B = b.name; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  
  //Počet záznamů
  const countData:number = Object.keys(freeTexts).length

  // Seznam pozvánek do řádků tabulky
  const linesFreeText = freeTexts.map((freeText) => (
    <tr key={freeText.id}>
      <td>{freeText.name}</td>
      
      
      {rights &&<td>
      
      {rightsBH &&<>
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">
          <ButtonGroup>
            <DialsFreeTextUpdate id={freeText.id} showAs='button' />
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
              <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteText(freeText.id,freeText.name!) } }>Smazat</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
          
        </div>
      </>}
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesSupportThemesCard = freeTexts.map((freeText) => (
    <div key={freeText.id}>
    <Card>
      <Card.Body>
        {rights && <>
          {rightsBH &&<>  
            <div className="float-right">
              <ButtonGroup>
                <DialsFreeTextUpdate id={freeText.id} showAs='button' />
                <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                  <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteText(freeText.id,freeText.name!) } }>Smazat</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>  
            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{freeText.name}</strong><br />
          
          <br />
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteDialsFreeText = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_DIALS_FREETEXTS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootDialsStore.fetchDialsFreeText()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }



    
  
  // Funkce pro založení nové pozvánky
  const handleNewDialsFreeText = (nameData:string, textData:string, dataData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_DIALS_FREETEXTS'), { name: nameData, text: textData, data: dataData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootDialsStore.fetchDialsFreeText()
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewTheme = Yup.object().shape({
    name: Yup.string()
    .required('Název volného textu musí být vyplněn!'),
    
  }) 
  
  return (
    <Container fluid>



      <br />


      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam volných textů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesSupportThemesCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Název</th>
                
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesFreeText}
            </tbody>
          </Table>
        </div> 
        <br />  
        
      </div>  
      {rightsBH &&
      <Button variant="success" onClick={handleShow}>
        + Přidat volný text
      </Button>
      }

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání volného textu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Volný text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Volný text byl úspěšně přidán!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewTheme}
              initialValues={{ 
                name: '',
                text: '',
                data: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewDialsFreeText(values.name!, values.text!, values.data)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <Form.Row>
                    <FormGroup as={Col}  controlId="text">
                      <FormLabel>Text</FormLabel>
                        
                        <TextEditor
                          setFieldValue={(val) => setFieldValue("text", val)}
                          value={values.text!}
                          
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>
                  
                                    
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám volný text...' : 'Uložit volný text'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(DialsFreeTextList))

import { getToken, errorEvaluation, getUrl } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { EmailingWhiteList } from './emailingWhiteList.store'
import { EmailingBlackList } from './emailingBlackList.store'
import { DatafiltersCustomersCustomList } from './datafiltersCustomersCustomList.store'
import { DatafiltersCustomersCustomVariables } from './datafiltersCustomersCustomVariables.store'
import { EmailingConditions } from './emailingConditions.store'

import { DatafiltersCustomers } from './datafiltersCustomers.store'


import { DialsPrograms } from './dialsPrograms.store'
import { DialsProgramVersions } from './dialsProgramVersions.store'
import { DialsLicenseTypes } from './dialsLicenseTypes.store'
import { DialsServices } from './dialsServices.store'
import { DialsModules } from './dialsModules.store'
import { DialsPriceListsModules } from './dialsPriceListsModules.store'
import { DialsPriceListsServices } from './dialsPriceListsServices.store'

import { DialsSupportTypes } from './dialsSupportTypes.store'
import { DialsSupportThemes } from './dialsSupportThemes.store'

import { DialsFreeText } from './dialsFreeText.store'

import { DialsOperationsPreferences } from './dialsOperationsPreferences.store'

import { DialsDeliveryCzechPostServices } from './dialsDeliveryCzechPostServices.store'
import { DialsDeliveryCzechPostServicesAll } from './dialsDeliveryCzechPostServicesAll.store'
import { DialsDeliveryCzechPostProducts } from './dialsDeliveryCzechPostProducts.store'
import { DialsDeliveryCzechPostProductsAll } from './dialsDeliveryCzechPostProductsAll.store'
import { DialsDeliveryCzechPostTemplates } from './dialsDeliveryCzechPostTemplates.store'

import { Helpers } from './helpers.store'

import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

import { getFilterStringToBE } from '../sharedComponents/filterPanel/components/FilterPanelFunctions'

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootEmailingStore = types
  .model({
    //dialsCategories: types.array(DialsCategories),
    dialsPrograms: types.array(DialsPrograms),
    dialsProgramVersions: types.array(DialsProgramVersions),
    dialsLicenseTypes: types.array(DialsLicenseTypes),
    dialsServices: types.array(DialsServices),
    dialsModules: types.array(DialsModules),
    dialsPriceListsModules: types.array(DialsPriceListsModules),
    dialsPriceListsServices: types.array(DialsPriceListsServices),
    dialsSupportTypes: types.array(DialsSupportTypes),
    dialsSupportThemes: types.array(DialsSupportThemes),
    dialsFreeText: types.array(DialsFreeText),
    dialsOperationsPreferences: types.array(DialsOperationsPreferences),

    dialsDeliveryCzechPostServices: types.array(DialsDeliveryCzechPostServices),
    dialsDeliveryCzechPostServicesAll: types.array(DialsDeliveryCzechPostServicesAll),
    dialsDeliveryCzechPostProducts: types.array(DialsDeliveryCzechPostProducts),
    dialsDeliveryCzechPostProductsAll: types.array(DialsDeliveryCzechPostProductsAll),
    dialsDeliveryCzechPostTemplates: types.array(DialsDeliveryCzechPostTemplates),
    dialsDeliveryCzechPostTemplatesAll: types.array(DialsDeliveryCzechPostTemplates),

    helpers: types.array(Helpers),


    emailingWhiteList: types.array(EmailingWhiteList),
    emailingBlackList: types.array(EmailingBlackList),
    datafiltersCustomersCustomList: types.array(DatafiltersCustomersCustomList),
    datafiltersCustomersCustomListAll: types.array(DatafiltersCustomersCustomList),
    datafiltersCustomersCustomVariables: types.array(DatafiltersCustomersCustomVariables),
    emailingConditions: types.array(EmailingConditions),
    datafiltersCustomersDesignerPreview: types.array(DatafiltersCustomers),
    
  })
  .views(self => ({
    getEmailingBlackListById(id: string) {
      return self.emailingBlackList.find(item => item.id === id)
    },
    getEmailingWhiteListById(id: string) {
      return self.emailingWhiteList.find(item => item.id === id)
    },
    getDatafiltersCustomersCustomListById(id: string) {
      return self.datafiltersCustomersCustomList.find(item => item.id === id)
    },
    getEmailingConditionsToFinalString() {
      let finalString = ''

      self.emailingConditions.forEach((item,index) => {
        
        if (index > 0) finalString += '~' + item.operatorBefore + '~'
        if (item.isSystem === true)
        {
          finalString += '('
          
          //OBSAH PRVNÍ ZÁVORKY
          item.includedConditions!.forEach((itemInside,indexInside) => {
        
            if (indexInside > 0) finalString += '~' + itemInside.operatorBefore + '~'
            if (itemInside.isSystem === true)
            {
              finalString += '('
              
              //OBSAH DRUHÉ ZÁVORKY
              itemInside.includedConditions!.forEach((itemInside2,indexInside2) => {
            
                if (indexInside2 > 0) finalString += '~' + itemInside2.operatorBefore + '~'
                if (itemInside2.isSystem === true)
                {
                  finalString += '('
                  
                  //OBSAH TŘETÍ ZÁVORKY
                  itemInside2.includedConditions!.forEach((itemInside3,indexInside3) => {
                
                    if (indexInside3 > 0) finalString += '~' + itemInside3.operatorBefore + '~'
                    if (itemInside3.isSystem === true)
                    {
                      finalString += '('

                      finalString += ')'
                    }
                    else
                    {
                      finalString += '{'
                      finalString += itemInside3.id
                      finalString += '}'
                      if (itemInside3.props1 !== null) finalString += '|' + itemInside3.props1
                      if (itemInside3.props2 !== null) finalString += '|' + itemInside3.props2
                    }
                  }) 
                  // konec obsahu třetí závorky 
                  
                  finalString += ')'
                }
                else
                {
                  finalString += '{'
                  finalString += itemInside2.id
                  finalString += '}'
                  if (itemInside2.props1 !== null) finalString += '|' + itemInside2.props1
                  if (itemInside2.props2 !== null) finalString += '|' + itemInside2.props2
                }
              }) 
              // konec obsahu druhé závorky 
              
              finalString += ')'
            }
            else
            {
              finalString += '{'
              finalString += itemInside.id
              finalString += '}'
              if (itemInside.props1 !== null) finalString += '|' + itemInside.props1
              if (itemInside.props2 !== null) finalString += '|' + itemInside.props2
            }
          }) 
          // konec obsahu první závorky 
          
          finalString += ')'
        }
        else
        {
          finalString += '{'
          finalString += item.id
          finalString += '}'
          if (item.props1 !== null) finalString += '|' + item.props1
          if (item.props2 !== null) finalString += '|' + item.props2
        }
      })  

      return finalString
    }
  }))
  .actions(self => ({
    fetchDatafiltersCustomersDesignerPreview: flow(function* fetchProjects(pageNumber:number, filterSet:string, id:string|null|undefined) {
      try {
        let isSetId:boolean = (id === null || id === undefined || id === '') ? false : true
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomPreviewSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('datafiltersCustomersCustomPreviewOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('datafiltersCustomersCustomPreviewOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        let paramsToSend:any = {
            filterSet,
            filter,
            order,
            page,
            pagesize
        }

        if (isSetId === true) paramsToSend = {
          filter,
          //order,
          page,
          pagesize
        }

        let url:string = getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_DESIGNER_PREVIEW')

        if (isSetId === true) url = getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_FILTER_PREVIEW') + id + '/execute'


        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: paramsToSend  }).then(response => {

            localStorage.setItem('lastDatafiltersCustomersCustomPreviewSearchTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDatafiltersCustomersCustomPreviewSearchTotalCount') === '0') {
          self.datafiltersCustomersDesignerPreview.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.datafiltersCustomersDesignerPreview,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst datafiltersCustomersCustomPreview. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteDatafiltersCustomersDesignerPreview: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomList.clear()
                
      } catch (error) {
        console.error('Failed to clear DatafiltersCustomersCustomList', error)
      }
    }),

    pushEmailingConditions: flow(function* fetchProjects(id:string,isSystem:boolean,props1:number|null,props2:number|null,operatorBefore:string) {
      try {
        yield sleep(100)
        self.emailingConditions.push({
          id: id, 
          isSystem: isSystem,
          props1: props1,
          props2: props2,
          operatorBefore: operatorBefore,
          includedConditions: [],
        })
                
      } catch (error) {
        console.error('Failed to push EmailingConditions', error)
      }
    }),

    fetchEmailingConditionsFromData: flow(function* fetchProjects(data:any) {
      try {
        yield sleep(100)
        applySnapshot(self.emailingConditions,data)
        
        
                
      } catch (error) {
        console.error('Failed to push EmailingConditions', error)
      }
    }),
    
    deleteEmailingConditions: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.emailingConditions.clear()
                
      } catch (error) {
        console.error('Failed to clear EmailingConditions', error)
      }
    }),
    deleteEmailingCondition: flow(function* fetchProjects(i:any) {
      try {
        yield sleep(100)
        //self.emailingConditions = self.emailingConditions.filter((item,index) => index !== i)
        self.emailingConditions.remove(i)
                
      } catch (error) {
        console.error('Failed to clear EmailingConditions', error)
      }
    }),

    unselectDatafiltersCustomersCustomVariables: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomVariables.forEach((item) => {
          item.selectItem(false)
        })
    } catch (error) {
      console.error('Nepodařilo se unselect DatafiltersCustomersCustomVariables. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDatafiltersCustomersCustomVariables: flow(function* fetchProjects() {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        //let filter:string = ''
        //let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomListSearchArray'))
        //console.log(filter)

        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name') order = 'Name~asc'
        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'NameDesc') order = 'Name~desc'

        //const page = pageNumber
        //const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_VARIABLES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            //filter,
            //order,
            //page,
            //pagesize
          }  }).then(response => {

            //localStorage.setItem('lastDatafiltersCustomersCustomVariablesTotalCount',response.data.totalCount)
            return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        applySnapshot(self.datafiltersCustomersCustomVariables,cl) 
        
        /*
        if (localStorage.getItem('lastDatafiltersCustomersCustomVariablesTotalCount') === '0') {
          //self.emailingBlackList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.datafiltersCustomersCustomList,cl) 
        } 
        */
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst DatafiltersCustomersCustomVariables. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteDatafiltersCustomersCustomVariables: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomVariables.clear()
                
      } catch (error) {
        console.error('Failed to clear DatafiltersCustomersCustomVariables', error)
      }
    }),
    
    
    fetchDatafiltersCustomersCustomList: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomListSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastDatafiltersCustomersCustomListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDatafiltersCustomersCustomListTotalCount') === '0') {
          self.datafiltersCustomersCustomList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.datafiltersCustomersCustomList,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst DatafiltersCustomersCustomList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteDatafiltersCustomersCustomList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomList.clear()
                
      } catch (error) {
        console.error('Failed to clear DatafiltersCustomersCustomList', error)
      }
    }),

    fetchDatafiltersCustomersCustomListAll: flow(function* fetchProjects() {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomListSearchArray'))
        //console.log(filter)

        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name') order = 'Name~asc'
        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = 1
        const pagesize = 9999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //localStorage.setItem('lastDatafiltersCustomersCustomListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          self.datafiltersCustomersCustomListAll.clear()
          applySnapshot(self.datafiltersCustomersCustomListAll,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst DatafiltersCustomersCustomList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchEmailingBlackList: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('emailingBlackListSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('emailingBlackListOrderBy') === 'Email') order = 'Email~asc'
        if (localStorage.getItem('emailingBlackListOrderBy') === 'EmailDesc') order = 'Email~desc'
        if (localStorage.getItem('emailingBlackListOrderBy') === 'CreateDate') order = 'CreateDate~asc'
        if (localStorage.getItem('emailingBlackListOrderBy') === 'CreateDateDesc') order = 'CreateDate~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_EMAILING_BLACKLIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastEmailingBlackListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastEmailingBlackListTotalCount') === '0') {
          self.emailingBlackList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.emailingBlackList,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst BlackList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteEmailingBlackList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.emailingBlackList.clear()
                
      } catch (error) {
        console.error('Failed to clear Blacklist', error)
      }
    }),
    
    
    fetchEmailingWhiteList: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('emailingWhiteListSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('emailingWhiteListOrderBy') === 'Email') order = 'Email~asc'
        if (localStorage.getItem('emailingWhiteListOrderBy') === 'EmailDesc') order = 'Email~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_EMAILING_WHITELIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastEmailingWhiteListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastEmailingWhiteListTotalCount') === '0') {
          self.emailingWhiteList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.emailingWhiteList,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst WhiteList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteEmailingWhiteList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.emailingWhiteList.clear()
                
      } catch (error) {
        console.error('Failed to clear whitelist', error)
      }
    }),

    

  }))

export type RootEmailingStoreType = Instance<typeof RootEmailingStore>
export type EmailingWhiteList = Instance<typeof EmailingWhiteList>
export type EmailingBlackList = Instance<typeof EmailingBlackList>

export type DialsProgramsStoreType = Instance<typeof DialsPrograms>
export type DialsProgramVersionsStoreType = Instance<typeof DialsProgramVersions>
export type DialsLicenseTypesStoreType = Instance<typeof DialsLicenseTypes>
export type DialsServicesStoreType = Instance<typeof DialsServices>
export type DialsModulesStoreType = Instance<typeof DialsModules>
export type DialsPriceListsModulesStoreType = Instance<typeof DialsPriceListsModules>
export type DialsSupportTypesStoreType = Instance<typeof DialsSupportTypes>
export type DialsSupportThemesStoreType = Instance<typeof DialsSupportThemes>
import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useEmailingStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface EmailingWhiteListUpdateProps {
  id: string,
  showAs: string,
}

const EmailingWhiteListUpdate: React.FC<EmailingWhiteListUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()

  let item = rootEmailingStore.getEmailingWhiteListById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    item = rootEmailingStore.getEmailingWhiteListById(props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (emailData:string, noteData:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_EMAILING_WHITELIST')+'/'+props.id, { id: props.id, email: emailData, note: noteData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootEmailingStore.fetchEmailingWhiteList( Number(sessionStorage.getItem('emailingWhiteListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('emailingWhiteListSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    email: Yup.string()
    .email('E-mailová adresa je ve špatném formátu!')
    .required('E-mail musí být vyplněn!')
    
  })   
  return (
    <span >
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava e-mailu v interním Whitelistu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>E-mail v interním Whitelistu byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                email: item?.email, 
                note: item?.note, 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.email!, values.note!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="email">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email!}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                                   
                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(EmailingWhiteListUpdate))

import React, { useState, useEffect }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import axios from 'axios';

import { useAppStore } from '../../store'
import { observer } from 'mobx-react'

import { confirm } from "../../Utils/Confirmation";

import { Formik } from 'formik'
import * as Yup from 'yup'

import AppProDuelReceipts from './App.ProDuel.Receipts.part'


interface AppProDuelCompanyAddUpdateProps {
  editing?:boolean,
  customerId:string,
  companyId?:string,
  showAs?:string,
} 

const AppProDuelCompanyAddUpdate: React.FC<AppProDuelCompanyAddUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [insertNewContact, setInsertNewContact] = useState(false)
  
  // Připojení store
  //const rootStore = useStore()
  const rootAppStore = useAppStore()

  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false

  // Nastavení editivaného bodu úkolu
  const customer = rootAppStore.getAppProduelCustomerByPortalId(localStorage.getItem('openedCustomerId')!)
  const editedCompany = rootAppStore.appProduelCompanies.find(c => c.id === props.companyId)

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    //clearInterval(interval)
  } 
   
  useEffect(() => {
    return () => {
      deleteInterval()
    }
  }, [])

  const deleteInterval = async () => {
    for (var i = 0; i < 99999; i++)
	    clearInterval(i);
  }
  
  // Dotaz před smazáním bodu úkolu
  const handleOnClickDeleteCompany = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete zpracovávanou firmu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání zpracovávané firmy'})) {
        deleteCompany(id)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání bodu úkolu
  const deleteCompany = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      


      // Zavolání API
      await axios.delete(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+customer?.id+'/companies/'+props.companyId, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          (async () => {
          sessionStorage.setItem('autoCloseModal','1')

          await rootAppStore.fetchAppProduelCompanies(1, customer?.id!)
          await rootAppStore.fetchAppProduelTokens(customer?.id!)
          //sessionStorage.setItem('taskDetailEdited', 'Y')

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
            }
          }, 2800);
          }
          )()

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
   
      })()    
  }


  // Funkce po odeslání formuláře
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend:any
      let urlForEditing:string = ''

      if (props.editing === true) {
        dataToSend = {
          id: props.companyId,
          name: values.name,  
              
        }
        urlForEditing = '/'+props.companyId
        }
        else {
          dataToSend = {
            customerId: customer?.id,
            name: values.name  
            }
        }

      // Zavolání API
      await axios.post(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+customer?.id+'/companies'+urlForEditing, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          (async () => {
          
          if (props.editing !== true) {
            await axios.post(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+customer?.id+'/companies/'+response.data.id+'/tokens', { 
              companyId: response.data.id,
              name: 'API klíč pro mobilní aplikaci proDUEL'
            }, { headers: { 'Authorization': 'Bearer ' + aToken}}
              ).then(responseToken => {
                
                (async () => {
                await rootAppStore.fetchAppProduelCompanies(1, customer?.id!)
                await rootAppStore.fetchAppProduelTokens(customer?.id!)  
                })()
                
              }).catch(error => {
                const errorMessage:any = (<ErrorAlert errorData={error} />)
                setError(errorMessage)
              });

          }  

          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          setInsertNewContact(false)
          sessionStorage.setItem('autoCloseModal','1')
          if (props.editing === true) {
          await rootAppStore.fetchAppProduelCompanies(1, customer?.id!)
          await rootAppStore.fetchAppProduelTokens(customer?.id!)
          }
          
          //sessionStorage.setItem('taskDetailEdited', 'Y')


          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShowForm('')
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);
          }
          )()

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    name: Yup.string()
      .required('Název zpracovávané firmy musí být vyplněn!'),
    
  })  
  

// Startovací sequence při otevření formuláře
const startSequence = () => {
  setInsertNewContact(true)
  //setStartDate(new Date()) 
  setShowInfo(false) 
  setShowForm('')
   
}



// VYKRESLENÍ FORMULÁŘE S BODEM ÚKOLU 
const contactForm = 
      <>
          <Alert variant="success" show={showInfo}>
            <strong>Zpracovávaná firma {props.editing === true ? 'upravena' : 'přidána'}!​</strong>
            
          </Alert>  
          {insertNewContact &&<>
          <div className={showForm} >
          <Alert variant="info" > 
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                name: props.editing === true ?  editedCompany?.name : '',                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values!)              
                  setSubmitting(false);
                }, 1);
              }}

            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                    <FormGroup  controlId="name">
                      <Form.Row>
                        <FormLabel column >Název</FormLabel>
                      </Form.Row>  
                      <Form.Row>
                        <Col>
                          <FormControl 
                            type="text"
                            name="name"
                            value={values.name!}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name} 
                            isInvalid={!!errors.name}
                          />
                        
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>

                  
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám...' : 'Uložit změny'}</Button>
                  
                </Form>

              )}
            </Formik>  
            
            </Alert>
          </div>
          </>}
          </>



return ( 
  <>
  {rights === true &&
  <>
  {props.editing===true?
  <>
  
  <ButtonGroup style={{}}>
    <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Přejmenovat</>:'+ Přidat zpracovávanou firmu'}</Button>
    <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
          {!(editedCompany?.tokens === null || editedCompany?.tokens === undefined) && <>
            <AppProDuelReceipts masterView={true} fromSubMenu={true}  customerId={props.customerId} companyId={props.companyId} tokenId={editedCompany?.tokens[0]?.id}   />
            <Dropdown.Divider />
          </>}
          <Dropdown.Item onClick={() => { handleOnClickDeleteCompany(props.companyId, editedCompany?.name!) } } style={{color: 'red'}} >Odstranit firmu</Dropdown.Item>

    </DropdownButton>
  </ButtonGroup>
  </>
  :
  props.showAs !== 'drop' ?
  <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Přajmenovat</>:'+ Přidat zpracovávanou firmu'}</Button>
  :
  <><Dropdown.Item onClick={() => {startSequence()}}>Přidat zpracovávanou firmu</Dropdown.Item></>
  
  }
  </>
  }
  
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editing === true ? 'Úprava' : 'Přidání'}&nbsp;zpracovávané firmy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(AppProDuelCompanyAddUpdate))

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios'
import moment from 'moment'

axios.get('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS')).then(response => {
    sessionStorage.setItem('URL', response.data.apiendpoint)
    sessionStorage.setItem('DOKLADY_URL', response.data.apidokladyendpoint)
    sessionStorage.setItem('isTestRelease', response.data.testrelease)
    sessionStorage.setItem('version', response.data.version)
    ReactDOM.render(<App />, document.getElementById('root'));
  })
/*
(async () => {
  await axios.get('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS')).then(response => {
    sessionStorage.setItem('URL', response.data.apiendpoint)
    sessionStorage.setItem('isTestRelease', response.data.testrelease)
    sessionStorage.setItem('version', response.data.version)
    
  })

  await ReactDOM.render(<App />, document.getElementById('root'))
  await serviceWorker.unregister()

})()
*/
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
import { types } from 'mobx-state-tree'

export const DialsUserGroups = types
  .model({
    id: types.optional(types.string, ''),
    name: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    data: types.maybeNull(types.string),
    users: types.optional(types.array(types.string), []),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    setUsers(users:any) {
      self.users = users
    },

  }))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Card, Button, Modal, Alert, Form, Col, Badge} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import CustomersDashboardContactsUpdate from './Customers.Dashboard.Contacts.Update.part'

const CustomersDashboardContacts: React.FC = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false
  
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }  

  // Načtení Store
  const rootStore = useStore()

  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const isPhone = customer?.contacts.filter(fcontacts => fcontacts.type === 'P')[0] === null || customer?.contacts.filter(fcontacts => fcontacts.type === 'P')[0] === undefined? false:true
 
  
  // Funkce pro založení nového kontaktu
  const handleNewContact = (typeData:string, valueData:string, noteData:string, isBillingData:boolean) => {
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 
      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id+'/contacts/', { type: typeData, value: valueData, note: noteData, isBilling: isBillingData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          rootStore.fetchCustomerOpened()

          // Po 2800 ms celé modální okno zavřeme

          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }
 
  const handleCopy = (text:string) => {
    //console.log('A')  
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    

  }

  // Schéma validace formuláře
  const schemaNewContact = Yup.object().shape({
    value: Yup.string().when('type', {
      is: 'P',
      then: Yup.string().required('Hodnota musí být vyplněna!').matches(/^([0-9+ ]){1,100}$/, 'Telefonní číslo může obsahovat pouze číslice, mezeru a znak +').max(17, 'Telefon může obsahovat maximálně 17 znaků.'),
      otherwise: Yup.string().required('Hodnota musí být vyplněna!').email('E-mailová adresa je ve špatném formátu!')
    }),
    /*
    value: Yup.string()
    .required('Hodnota musí být vyplněna!'),*/
  })   

  return (
    <Card
      bg='light'
      text='dark'
      style={{height: '100%'}}
    >
      <Card.Header  as="h5">Kontakty</Card.Header>
      <Card.Body>
      <span className="contact-type-header"><strong>E-maily</strong></span>
        {customer?.contacts.filter(fcontacts => fcontacts.type === 'E').sort(function(a:any, b:any) {
            var nameA = a.isBilling; // ignore upper and lowercase
            var nameB = b.isBilling; // ignore upper and lowercase
            if (nameB < nameA) {
              return -1;
            }
            if (nameB > nameA) {
              return 1;
            }
            return 0;
          }).map(contacts =>
        <div key={contacts.id}>
          <div className="float-right">
            <CustomersDashboardContactsUpdate id={contacts.id} />

          </div>
                   
          <div className="float-left">
            <span onClick={() => handleCopy(contacts.value)}>{contacts.type === 'E' ?  contacts.value : contacts.value}</span>&nbsp;{contacts.isBilling === true ? <Badge variant="info" title='Příznak, zda tento e-mail slouží pro zasílání dokumentů (faktur, lic. lístků apod.).'>Fakturační</Badge>:''}
            <br /><i>{contacts.note}</i>
          </div>

          <div className="cleaner divide"></div>
        </div>  )}

        <span className={isPhone === true ? 'contact-type-header':'nodisplay'}><strong>Telefony</strong></span>
        {customer?.contacts.filter(fcontacts => fcontacts.type === 'P').map(contacts =>
        <div key={contacts.id}>
          
          <div className="float-right">
            <CustomersDashboardContactsUpdate id={contacts.id} />
          </div>          
          <div className="float-left">
            {contacts.type === 'E' ?  contacts.value : contacts.value}
            <br /><i>{contacts.note}</i>
          </div>

          <div className="cleaner divide"></div>
        </div>  )}
        <br />

        <table className="fullWidth nodisplay">
          <tbody>
            {customer?.contacts.map(contacts => 
              <tr key={contacts.id}>
                <td><strong>{contacts.type === 'E' ? 'E-mail' : 'Telefon'}</strong></td>
                <td className="rightText">{contacts.type === 'E' ?  contacts.value : contacts.value}</td>
              </tr>)}
          </tbody>
        </table>  
        {rights && customer?.isActive &&
        <Button variant="success" size="sm" onClick={handleShow}>
          + Přidat nový kontakt
        </Button>
        }
        <Modal 
          show={show} 
          onHide={handleClose} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Nový kontakt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="success" show={showInfo}>
              <strong>Kontakt byl úspěšně přidán!​</strong>
            </Alert> 
            <div className={showForm} >
              <Formik
                validationSchema={schemaNewContact}
                initialValues={{ type: 'P', value: '', note: '', isBilling: false }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    handleNewContact(values.type, values.value, values.note, values.isBilling)              
                    setSubmitting(false);
                  }, 1);
                }}
              >
                {({ 
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    
                    <FormGroup controlId="type">
                      <Form.Row>
                        <FormLabel column lg={3}>Typ</FormLabel>
                        <Col md="3">
                          <Form.Control as="select" 
                            name="type" 
                            onChange={handleChange}
                            value={values.type}
                            >
                              <option value="P">Telefon</option>
                              <option value="E">E-mail</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.type}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>
                    
                    <FormGroup controlId="value">
                      <Form.Row>
                        <FormLabel column lg={3}>{values.type === 'P' ? 'Telefon':'E-mail'}</FormLabel>
                        <Col>
                          <FormControl 
                            type="text"
                            name="value"
                            value={values.value!}
                            onChange={handleChange}
                            isValid={touched.value && !errors.value} 
                            isInvalid={!!errors.value}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.value}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup> 

                    <FormGroup controlId="note">
                      <Form.Row>
                        <FormLabel column lg={3}>Popis</FormLabel>
                        <Col>
                          <FormControl 
                            type="text"
                            name="note"
                            value={values.note!}
                            onChange={handleChange}
                            isValid={touched.note && !errors.note} 
                            isInvalid={!!errors.note}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.note}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>                      
                    
                    <FormGroup controlId="isBilling" className={values.type === 'P' ? 'nodisplay':''}>
                      <Form.Row>
                        <FormLabel column lg={3}>Fakturační e-mail:</FormLabel>
                        <Col md="1">
                          <FormControl 
                            type="checkbox"
                            name="isBilling"
                            size="sm"
                            className="checkbox-small"
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.isBilling}
                            
                            isValid={touched.isBilling && !errors.isBilling} 
                            isInvalid={!!errors.isBilling}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.isBilling}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>

                    {error}
                    <br />
                    <Button type="submit" variant="success" disabled={loading} block>{loading ? 'Přidávám kontakt...' : 'Přidat kontakt'}</Button>
                    
                  </Form>
                )}
              </Formik>  
            </div>
          </Modal.Body>

        </Modal>





      </Card.Body>
    </Card>

  )  
  
}

export default withRouter(observer(CustomersDashboardContacts))

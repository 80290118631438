import { types } from 'mobx-state-tree'

const Solutions = types.model('Solutions',{
  id: types.optional(types.string, ''),
  userId: types.maybeNull(types.string),
  authorId: types.maybeNull(types.string),
  supportTypeId: types.maybeNull(types.string),
  createDate: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isData: types.optional(types.boolean, false),
  toInvoice: types.optional(types.boolean, false),
  duration: types.maybeNull(types.number),
})

export const Supports = types
  .model({
    id: types.optional(types.string, ''),
    userId: types.optional(types.string, ''),
    authorId: types.maybeNull(types.string),
    createDate: types.optional(types.string, ''),
    supportTypeId: types.maybeNull(types.string),
    isDemo: types.optional(types.boolean, false),
    customerId: types.maybeNull(types.string),
    accountNumber: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
    subject: types.maybeNull(types.string),
    request: types.maybeNull(types.string),
    supportThemes: types.maybeNull(types.array(types.string)),
    isTrouble: types.optional(types.boolean, false),
    feeling: types.maybeNull(types.string),
    solverUserId: types.maybeNull(types.string),
    deadLineDate: types.maybeNull(types.string),
    isFinished: types.optional(types.boolean, false),
    duration: types.maybeNull(types.number),
    solutions: types.array(Solutions),
    solutionCount: types.maybeNull(types.number),
    lastEditDate: types.optional(types.string, '')
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

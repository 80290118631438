import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';
import { getToken, getUrl } from '../../Utils/Common'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./textEditor.css";

interface ITextEditorProps {
  value: string;
  setFieldValue: (val: string) => void;
  refEditor?: any;
  id?: string;
}

export const TextEditor = ({ value, setFieldValue, refEditor, id }: ITextEditorProps) => {
  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
  };

  const uploadCallback = (file:any) => {
    return new Promise(async(resolve, reject) => {
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        
      }       
      
      const data = new FormData();
       data.set('Note', '')
       //data.set('LinkId', 'null')
       data.append("File", file)

       axios.post(getUrl('URL_FILES'), data, { headers: { 'Authorization': 'Bearer ' + aToken}}).then(responseImage => {
            
        
        
          resolve({ data: { link: getUrl('URL_FILES')+'/'+responseImage.data.id } });
       })
    });
  }

  /*
  const handlePastedText = (text: string, html: string, editorState: EditorState, onChange: any) => {
    // Zde můžete implementovat vlastní logiku pro zpracování vloženého textu
    return ContentState.createFromText(text); // Toto je pouze ukázka, můžete implementovat jiný způsob zpracování
  };
  */


  return (
    <div>
      <Editor
        editorState={editorState}
        ref={refEditor}
        wrapperClassName="custom-wrapper"
        editorClassName="custom-editor"
        onEditorStateChange={onEditorStateChange}
        stripPastedStyles={true}
        //handlePastedText={handlePastedText}
        toolbar={{
          options:['inline', 'blockType', 'fontSize','textAlign','list','colorPicker','history', 'link', 'remove', 'image'  ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
          },
          image: {
            uploadEnabled: false,
            uploadCallback: uploadCallback,
            previewImage: true, 
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
                 height: 'auto',
                 width: 'auto',
            }
          }
        }}
      />
    </div>
  );
};

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getRoleName, getUser } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal, Table, ButtonGroup, Row } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
//import NumberFormat from 'react-number-format'

interface MeetingsUpdateProps {
  id: string,
  showAs: string,
}

const MeetingsUpdate: React.FC<MeetingsUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  

  //const rightsMeeting:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false
  const rightsManagement:boolean = ((localStorage.getItem('Management')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  let item = rootStore.getMeetingById(props.id)

  const rightsAuthor:boolean = (item?.authorId === getUser().id) ? true : false
  const rightsMeeting:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false
  const rightsEdit:boolean = ((rightsAuthor === true) || (rightsMeeting === true && item?.isManagement === false) || (rightsManagement === true && item?.isManagement === true)) ? true : false

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [showAddHedgehogs, setShowAddHedgehogs] = useState(false)
  const [showAddHedgehogsOut, setShowAddHedgehogsOut] = useState(false)
  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    item = rootStore.getMeetingById(props.id)
    sessionStorage.removeItem('autoCloseModal')

    
    await rootStore.hedgehogUsers.map((hu,index) => {
      //console.log(item?.employeeWithAccess)
      if (item?.employeeWithAccess?.includes(hu.id) ) {
      hu.selectItem(true)
      //console.log(hu.id)
      }
      else {
        hu.selectItem(false)
        //console.log('hu.id')
      }
      return ''
    })

    await rootStore.hedgehogUsers.map((hu,index) => {
      //console.log(item?.employeeWithAccess)
      if (item?.absentEmployees?.includes(hu.id) ) {
      hu.selectItem2(true)
      //console.log(hu.id)
      }
      else {
        hu.selectItem2(false)
        //console.log('hu.id')
      }
      return ''
    })
    

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }



  const handleOpenAddHedgehogs = async () => {    
    
    
    
    
    setShowAddHedgehogs(true)
    
  }

  const handleCloseAddHedgehogs = () => {
    setShowAddHedgehogs(false)
    //setDataPreviewSeparator('')
    //setDataPreviewArray([])
  }

  const handleAddHedgehogs = () => {    
    

    handleCloseAddHedgehogs()
    
    //setShowAddHedgehogs(true)
    
  }

  const handleOpenAddHedgehogsOut = () => {    
    setShowAddHedgehogsOut(true)
    
  }

  const handleCloseAddHedgehogsOut = () => {
    setShowAddHedgehogsOut(false)
    //setDataPreviewSeparator('')
    //setDataPreviewArray([])
  }

  const handleAddHedgehogsOut = () => {    
    

    handleCloseAddHedgehogsOut()
    
    //setShowAddHedgehogs(true)
    
  }



  // Funkce pro změnu adresy
  const handleUpdate = (dateData:string, textData:string, durationData:number, categoryIdData:string, isManagementData:boolean) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_MEETINGS')+'/'+props.id, { id: props.id, date: new Date(dateData).toISOString(), text: textData, duration:durationData, categoryId: categoryIdData, isManagement: isManagementData, employeesWithAccess: isManagementData === true ? [] : rootStore.hedgehogUsers.filter(hu => hu.checked === true).map(hu => hu.id), absentEmployees: rootStore.hedgehogUsers.filter(hu => hu.checked2 === true).map(hu => hu.id) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          (async () => {
          await rootStore.fetchMeetings( Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))
          rootStore.fetchMeetingPoints(props.id)
          //rootStore.fetchTemplates(sessionStorage.getItem('templatesSearchValue')!, sessionStorage.getItem('templatesSearchTypeValue')!, sessionStorage.getItem('templatesSearchIsActiveValue')! === '0' ? false : true, sessionStorage.getItem('templatesSearchWithSystemValue')! === '0' ? false : true, 10,  Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage'))) 

          })()
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

   // Seznam kategorií porad pro rozevírací seznam
  const dialsMeetingsCategories = rootDialsStore.dialsMeetingsCategoriesAll
  .slice()
  //.filter(dst => dst.isActive === true)
  .sort(function(a:any, b:any) {
    var sort1A = a.name; 
    var sort1B = b.name; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(item => (
    <option key={item.id} value={item.id}>{item.name}</option>
  ))  

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    date: Yup.string()
    .required('Datum porady musí být vyplněno!'),
    categoryId: Yup.string()
    .required('Kategorie porady musí být vybrána!'),
    text: Yup.string()
    .required('Cíl porady musí být vyplněn!'),
    duration: Yup.number()
    .required('Délka trvání musí být vyplněna!'),
    
    
  })   
  return (
    <span >
      {rightsEdit && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava porady</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Porada byla úspěšně upravena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                date: moment(new Date(item?.date!)).format("YYYY-MM-DDTHH:mm"), 
                text: item?.text, 
                duration: item?.duration,
                categoryId: item?.categoryId,
                isManagement: item?.isManagement,
                temp: '',
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.date!, values.text!, values.duration!, values.categoryId!, values.isManagement!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="date">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="date"
                          value={values.date}
                          onChange={handleChange}
                          isValid={touched.date && !errors.date} 
                          isInvalid={!!errors.date}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.date}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="text">
                    <Form.Row>
                      <FormLabel column lg={3}>Cíl porady</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="text"
                          value={values.text}
                          onChange={handleChange}
                          isValid={touched.text && !errors.text} 
                          isInvalid={!!errors.text}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="duration">
                    <Form.Row>
                      <FormLabel column lg={3}>Délka (min.)</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="duration"
                          value={values.duration}
                          onChange={handleChange}
                          isValid={touched.duration && !errors.duration} 
                          isInvalid={!!errors.duration}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.duration}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="categoryId">
                    <Form.Row>
                      <FormLabel column lg={3}>Kategorie porady</FormLabel>
                      <Col>
                          <Form.Control as="select" 
                            name="categoryId" 
                            //onChange={async(e) => {await handleChange(e); await controlAfterSelectType(e.target.value);  if (rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId !== '') { await setFieldValue("solutionSupportTypeId", rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId ) } }}
                            onChange={handleChange}
                            value={values.categoryId!}
                            isInvalid={!!errors.categoryId}

                            //tabIndex={4}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 3:7, handleSubmit)}
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              dialsMeetingsCategories
                              }
                          </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.categoryId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isManagement">
                    <Form.Row>
                      <FormLabel column lg={3}>Porada vedení</FormLabel>
                      <Col>
                      <FormControl 
                          type="checkbox"
                          name="isManagement"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isManagement}
                          disabled={rightsManagement === false}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isManagement && !errors.isManagement} 
                          isInvalid={!!errors.isManagement}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isManagement}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  {values.isManagement === false && <>

                  <strong>Seznam účastníků:</strong><br />
                  {rootStore.hedgehogUsers.filter(hu => hu.checked === true).length === 0 && <span >Nikdo nebyl vybrán, porada je určena pro všechny.</span>}
                  {rootStore.hedgehogUsers.filter(hu => hu.checked === true).map(hu => (
                    <div key={hu.id}>{hu.fullName}</div>
                  
                  ))
                  }
                  <Button size='sm' block style={{marginTop: '10px', marginBottom: '10px'}} variant='secondary' onClick={() => { handleOpenAddHedgehogs() } }>Upravit výběr účastníků</Button>

                  </>}  

                  {values.isManagement === false && <></>} 

                  <strong>Seznam nepřítomných:</strong><br />
                  {rootStore.hedgehogUsers.filter(hu => hu.checked2 === true).length === 0 && <span >Všichni jsou přítomni!</span>}
                  {rootStore.hedgehogUsers.filter(hu => hu.checked2 === true).map(hu => (
                    <div key={hu.id}>{hu.fullName}</div>

                  ))
                  }
                  <Button size='sm' block style={{marginTop: '10px'}} variant='secondary' onClick={() => { handleOpenAddHedgehogsOut() } }>Zadat nepřítomnosti</Button>

                   

                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>

                  <Modal 
                    show={showAddHedgehogs} 
                    onHide={handleCloseAddHedgehogs}        
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Výběr účatníků porady</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th className="jsw-table-head" style={{width: '30px'}}></th>
                            <th className="jsw-table-head">Jméno</th>
                            <th className="jsw-table-head">E-mail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true).map((item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={item.checked}
                                  className=""
                                  id={'rowcheck'+item.id}
                                  onChange={() => {if (item.checked === true) { item.selectItem2(false); } item.selectItem(!item.checked); setFieldValue('temp', new Date().toISOString) }}
                                  style={{height: '18px', width: '18px'}}
                                /> 
                              </td>
                              <td>{item.fullNameBySurName}</td>
                              <td>{item.email}</td>
                            </tr>
                          ))}
                          
                        </tbody>
                      </Table>
                      <h5>Výběry</h5>
                      <ButtonGroup className='btn-block'>
                        <Button variant="secondary" onClick={() => { rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true && !(u.email === 'obchod@jezeksw.cz' || u.email === 'uzivatel.jezeksw@seznam.cz' || u.email === 'sync@jezeksw.cz' || u.email === 'test@jezeksw.cz')).map((i, index) =>  { i.selectItem(true); return null; }); setFieldValue('temp', new Date().toISOString) }} >Vybrat všechny</Button>

                      </ButtonGroup> 
                      
                      <hr />
                      <Row>
                        {rootDialsStore.dialsRoles.filter(r => !(r === 'SmallHedgehog' || r === 'BigHedgehog' || r === 'SmallUser' || r === 'BigUser')).map((item, index) => (<Col key={index} md={6} sm={12} style={{marginBottom: '5px'}}>
                          <Button variant="secondary" block onClick={() => { rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true && u.roles.includes(item) === true).map((i, index) =>  { i.selectItem(true); return null; }); setFieldValue('temp', new Date().toISOString) }} >{getRoleName(item)}</Button>    
                        </Col>))}
                        {rootDialsStore.dialsUserGroupsAll.map((item, index) => (<Col key={index} md={6} sm={12} style={{marginBottom: '5px'}}>
                          <Button variant="secondary" block onClick={() => { rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true && item.users.includes(u.id) === true).map((i, index) =>  { i.selectItem(true); return null; }); setFieldValue('temp', new Date().toISOString) }} >{item.name}</Button>    
                        </Col>))}
                      </Row>
                      <hr />
                      <Button block variant='outline-danger' onClick={() => { rootStore.hedgehogUsers.map((i, index) =>  { i.selectItem(false); return null; }); setFieldValue('temp', new Date().toISOString) }}>Zrušit výběr</Button>
                      <hr />
                      <Button variant="success" block onClick={() => { handleAddHedgehogs() }} >Hotovo, zavřít okno</Button>

                  </Modal.Body>
                </Modal>

                <Modal 
                    show={showAddHedgehogsOut} 
                    onHide={handleCloseAddHedgehogsOut}        
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Výběr nepřítomností</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th className="jsw-table-head" style={{width: '30px'}}></th>
                            <th className="jsw-table-head">Jméno</th>
                            <th className="jsw-table-head">E-mail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true && (u.checked === (Object.keys(rootStore.hedgehogUsers.filter(uc => uc.isEmailConfirmed === true && uc.checked === true)   ).length === 0 ? false : true)) ).filter(hu => {if (values.isManagement === false) return hu; else return hu.roles.includes('Management') }).map((item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={item.checked2}
                                  className=""
                                  id={'rowcheck'+item.id}
                                  onChange={() => { item.selectItem2(!item.checked2); setFieldValue('temp', new Date().toISOString) }}
                                  style={{height: '18px', width: '18px'}}
                                />
                              </td>
                              <td>{item.fullNameBySurName}</td>
                              <td>{item.email}</td>
                            </tr>
                          ))}
                          
                        </tbody>
                      </Table>
                      
                      <Button variant="success" block onClick={() => { handleAddHedgehogsOut() }} >Hotovo, zavřít okno</Button>

                  </Modal.Body>
                </Modal>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(MeetingsUpdate))

import React, { useState } from 'react'
import Loader from "react-loader"
import axios from 'axios'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'
import CustomersMassActions from '../components/Overviews.Customers.MassActions.part'
import CustomersSingleActions from '../components/Overviews.Customers.SingleActions.part'

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import { Container, Button, ButtonGroup,  Card, Badge, Table, DropdownButton, Dropdown, Spinner} from 'react-bootstrap'

//import * as Constants from '../../../Constants/Constants'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore, useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

import { useParams } from 'react-router'
import { OverviewsDetailUrlParams } from '../../../Routes'

import { getToken, getUrl, setUserRoles, handleMenuContent, getOverviewsListNameById, checkVersion } from '../../../Utils/Common'
import { login } from '../../../authProvider'

import Pagination from 'react-js-pagination'
import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'


function OverviewsList(props:any) {
  const { id } = useParams<OverviewsDetailUrlParams>()
  //const order = useStore().getOrderById(String(id))

  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  //const [loadedChange, setLoadedChange] = useState(true)
  const [opening, setOpening] = useState(false)
  const [openingId, setOpeningId] = useState('')
  const [error, setError] = useState(null)
  //const [showCancel, setShowCancel] = useState(false)

  const [isFiltered, setIsFiltered] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(false)

  // Nastavení práv pro velkou tabulku
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()
  
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
      setLoaded(false) // Nastavení pro Loader, že není strana načtena  
      if (Number(id) !== +localStorage.getItem('lastDatafilter')!) {
        if (+localStorage.getItem('lastDatafilter')! !== 9999) {
        sessionStorage.removeItem('selectionDetailSearchValue')
        sessionStorage.removeItem('selectionDetailSearchTypeValue')
        sessionStorage.setItem('selectionDetailOfferPage', '1')
        }
      }

      sessionStorage.setItem('selectionDetailOfferPage', '1')
      await checkVersion()
      setStateNewFilter(true)
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {  
      await rootStore.fetchDatafiltersCustomers(Number(id), Number(sessionStorage.getItem('selectionDetailPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailPage')))  
      //rootStore.fetchTemplates()
      rootUpgradeStore.fetchUpgradeOffersScenariosAll()
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
            await rootStore.fetchCustomerOfferCount()
          }
          //await rootStore.fetchDatafiltersCustomers(Number(id),sessionStorage.getItem('selectionDetailSearchValue')!, sessionStorage.getItem('selectionDetailSearchTypeValue')!,Number(sessionStorage.getItem('selectionDetailPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailPage')))
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      } 
    }   
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })
    
  //Počet záznamů
  const countData:number = Object.keys(rootStore.datafiltersCustomers).length
  const countDataChecked:number = Object.keys(rootStore.datafiltersCustomers.filter(dfon => dfon.checked === true  )).length

  // Seznam výběrů do karet
  const selectionsListDataCard = rootStore.datafiltersCustomers.map(customerList => (
    <div key={customerList.id}>
    <Card>
      <Card.Body>
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{customerList.businessName}</strong><br />
          <Badge variant="info">Číslo zákazníka: {customerList.accountNumber}</Badge>
          <br />
          {customerList.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}
          <br />(<i>
          IČO: {customerList.companyRegistrationNumber}
          </i>)
        </div>
        <div className="float-right" style={{textAlign: 'right'}}>
          <ButtonGroup>
            <CustomersSingleActions id={id} recordId={customerList.id} recordId2='' recordId3='' infoText={customerList.infoText!} />
            
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
              <Dropdown.Item onClick={() => { handleOpen(customerList.id,customerList.isActive) } } >{opening && openingId === customerList.id ? 'Otevírám...' : 'Otevřít zákazníka'}</Dropdown.Item>    
            </DropdownButton>
          </ButtonGroup>  
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))

  // Seznam zákazníků do řádků tabulky
  const selectionsListDataTable = rootStore.datafiltersCustomers.map(customerList => (
    <tr key={customerList.id}>
      <td>
          <input
            type="checkbox"
            checked={customerList.checked}
            className=""
            id={'rowcheck'+customerList.id}
            onChange={() => customerList.selectItem(!customerList.checked)}
            style={{height: '18px', width: '18px'}}
          />
      </td>
      <td><strong>{customerList.accountNumber}</strong></td>
      <td>
        {!opening &&<span className="as-link-no-color" onClick={() => { handleOpen(customerList.id,customerList.isActive) } }>{customerList.businessName}</span>}
        {opening &&<span className="as-link-no-color">{customerList.businessName}</span>}
      </td>
      <td>{customerList.companyRegistrationNumber}</td>
      <td>{customerList.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}</td>
      <td>
        
        <div className="float-left">
          <ButtonGroup>
              <CustomersSingleActions id={id} recordId={customerList.id} recordId2='' recordId3='' infoText={customerList.infoText!} />
              
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                <Dropdown.Item onClick={() => { handleOpen(customerList.id,customerList.isActive) } } >{opening && openingId === customerList.id ? 'Otevírám...' : 'Otevřít zákazníka'}</Dropdown.Item>
                
                
              </DropdownButton>
            
            
            
            
            </ButtonGroup>



          
          &nbsp;&nbsp;
        </div>  
        
      </td>
    </tr>
  ))

  // Funkce po otevření firmy
  const handleOpen = async (id:string,isActive:boolean) => {
    setError(null)
    setOpening(true)
    setOpeningId(id)
    
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    
    if (isActive === true) {
      // Zavolání API
      await axios.get(getUrl('URL_CUSTOMER_OPEN')+id,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        }}
      ).then(response => {
        (async () => {
        
        // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
        setUserRoles(response.data.roles)
        login(response.data.token)

        // Nastavení id otevřené firmy do proměnné v localStore
        localStorage.setItem('openedCustomerId', id)

        // Nastavení informace, že firma byla otevřená z přehledu
        localStorage.setItem('openedFromOverviews', '1')

        // Načtení otevřené firmy do store
        await rootStore.fetchCustomerOpened()
        await rootStore.fetchCustomerOfferCount()

        setOpening(false)

        // Přesměrování na stránku s přehledem firmy
        await props.history.push('/customers-dashboard')
        })();
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setOpening(false)
      });
    }
    else {
      // Nastavení id otevřené firmy do proměnné v localStore
      localStorage.setItem('openedCustomerId', id)

      // Nastavení informace, že firma byla otevřená z přehledu
      localStorage.setItem('openedFromOverviews', '1')

      // Načtení otevřené firmy do store
      await rootStore.fetchCustomerOpened()
      //await rootStore.fetchCustomerOfferCount()

      setOpening(false)

      // Přesměrování na stránku s přehledem firmy
      await props.history.push('/customers-dashboard')
    }
  }


  const handlePageChange = (pageNumber:number) => {
    (async () => { 
      setLoaded(false);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      sessionStorage.setItem('selectionDetailPage', String(pageNumber))
      // Předat hledanou hodnotu action pro načtení zákazníků
      await rootStore.fetchDatafiltersCustomers(Number(id), pageNumber)
      setLoaded(true);  
    })()
  }


  const unselectAll = () => {
    rootStore.datafiltersCustomers.map(
      dfc => (
        dfc.selectItem(false)
      )

    )
  }

  return (
    <div className="App">

        <TopMenu />
        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
      >
              <Container fluid>
                <br />
                <FilterPanel 
                //isCustomer={props.isCustomer} 
                filterPage={'overviewsCustomer'} 
                filterSearchArray='overviewsCustomerSearchArray' 
                pageVariable='selectionDetailPage' 
                setPageLoading={setLoading} 
                filterItems={['CustomerFulltext','AccountNumber','CompanyRegistrationNumber','Email']}  
                title={getOverviewsListNameById(id)}
                titleCount = {Number(localStorage.getItem('lastDatafilterTotalCount')!)}
                stateNewFilter={stateNewFilter}
                setStateNewFilter={setStateNewFilter}
                overviewsNumber={Number(id)}
                setIsFiltered={setIsFiltered}
                secondaryRightButtons={<CustomersMassActions id={id} multiselect={countDataChecked > 0 ? true:false} style={{marginBottom: '15px', marginLeft: '5px'}} multiselectCount={countDataChecked} filtered={isFiltered} searchValue={sessionStorage.getItem('selectionDetailSearchValue')!} searchType={sessionStorage.getItem('selectionDetailSearchTypeValue')!} />}
                //secondaryLeftButtons={<NewLicensesTransfer />}
                />
                
                <br />
                
                
                {(loading === true) 
                ? 
                <><Spinner animation='border'  />&nbsp;<br /></>
                :
                <>
                  <div className="showMobile">
                    {countData === 0 ? <div>Seznam zákazníků neobsahuje žádné záznamy.<br /><br /></div> : ''}
                    {selectionsListDataCard}
                  </div>
                  <div className="showWeb">
                    <Table responsive hover>
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head" style={{width: '30px'}}></th>
                          <th className="jsw-table-head row-slim">Zákazník</th>
                          <th className="jsw-table-head">Název</th>
                          <th className="jsw-table-head">IČO</th>
                          <th className="jsw-table-head">Stav</th>
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectionsListDataTable}
                      </tbody>
                    </Table>
                    {countDataChecked > 0 && <Button variant="primary" size="sm" onClick={() => {unselectAll()}} >Zrušit výběr</Button>}
                    {countData === 0 ? <div>Seznam zákazníků neobsahuje žádné záznamy.<br /><br /></div> : ''}

                  </div>  
                  <br />  
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={Number(sessionStorage.getItem('selectionDetailPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailPage')) }
                    itemsCountPerPage={10}
                    totalItemsCount={Number(localStorage.getItem('lastDatafilterTotalCount'))}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(1)}
                    />
                </>}
                {error}
              </Container>
              </Loader>
            </main>
          </div>
        </div>

    </div>
  );
}

export default observer(OverviewsList)
import React, { useState } from 'react'
import axios from 'axios'

import { RegistrationUrlParams } from '../../../Routes'
import { useParams } from 'react-router'
//import { Link } from 'react-router-dom'

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'
import {Container,  Form, Col, Button, Alert, Image} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { getUrl, checkVersionWithoutRestart } from '../../../Utils/Common'
//import * as Constants from '../../../Constants/Constants'

import { useMount } from '../../../helpers/lifecycle-hooks'

import { Formik } from 'formik'
import * as Yup from 'yup'

function Registration(props:any) {
  // Získání tokenu z URL adresy
  const { token } = useParams<RegistrationUrlParams>()

  // Pokud nepřišel token přesměrování na Login
  if (token === '') {
    props.history.push('/login')
  }

  // Kontrola zda se načetla URL BE
  
  /*
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }
  */

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [emailFromToken, setEmailFromToken] = useState('')

  const errorMessageWrongInvite:any = (
    <Alert variant="danger">
        <strong>Registrační formulář nelze načíst.</strong><br />
        Odkaz jste do prohlížeče vkládali pravděpodobně ručně. <br />Zkuste odkaz zkopírovat ještě jednou a zkontrolujte, prosím, že je celý!
    </Alert> )


    // Při načtení stránky 
    useMount(() => {
      (async () => {  
      //setLoaded(false) // Nastavení pro Loader, že není strana načtena
      window.scrollTo(0, 0)
      checkVersionWithoutRestart()
      setShowForm('nodisplay')
      // Dotažení e-mailu z tokenu
      await axios.get(getUrl('URL_INVITES_EMAIL_FROM_TOKEN')+token
        ).then(response => {
          setEmailFromToken(response.data.email)
          if (response.data.email === null) {
        
            setError(errorMessageWrongInvite)
            setShowForm('nodisplay')  
            setShowE(true)
          }
          else {
            setShowForm('')
          }
          //setLoading(false);
          //setShowInfo(true)
          //setShowForm('nodisplay')
        }).catch(error => {
          //setLoading(false);
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      
      //setLoaded(true) // Nastavení pro Loader, že je strana načtena
      })() 
    })

  // Po odeslání registračního formuláře
  const handleRegistration = (tokenData:string,passwordData:string,confirmPasswordData:string,surNameData:string,firstNameData:string,phoneData:string) => {
    setError(null)
    setLoading(true)

    // Zavolání API
    axios.post(getUrl('URL_REGISTRATION'), { token: tokenData, password: passwordData, confirmPassword: confirmPasswordData, surName: surNameData, firstName: firstNameData, phone: phoneData }
    ).then(response => {
      setLoading(false);
      setShowInfo(true)
      setShowForm('nodisplay')
    }).catch(error => {
      setLoading(false);
      const errorMessage:any = (<ErrorAlert errorData={error} />)
      setError(errorMessage)
    });
  }

  // Schéma validace formuláře
  const schema = Yup.object().shape({
    surName: Yup.string()
      .required('Příjmení musí být vyplněno!'),
    firstName: Yup.string()
      .required('Jméno musí být vyplněno!'),  
    phone: Yup.string()
      .matches(/^([0-9+ ]{1,100})$/, 'Telefonní číslo může obsahovat pouze číslice, mezeru a znak +')
      .max(17, 'Telefon může obsahovat maximálně 17 znaků.'),  
    password: Yup.string()
      .required('Heslo musí být vyplněno!')
      .matches(
        /[A-Z]/,
        "Heslo musí obsahovat alespoň jedno velké písmeno."
      )
      .matches(
        /[a-z]/,
        "Heslo musí obsahovat alespoň jedno malé písmeno"
      )
      .matches(
        /[0-9]/,
        "Heslo musí obsahovat alespoň jednu číslici."
      )
      .matches(
        /[@$!%*#?&_+]/,
        "Heslo musí obsahovat alespoň jeden speciální znak (@, $, !, %, *, #, ?, &, _, +)."
      )
      .min(8, 'Heslo musí obsahovat mininálně 8 znaků.'),
      
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], "Hesla se neshodují!")
     .required('Potvrzení hesla musí být vyplněno!'),    
  })  

  return (
    <div className="App">
      <main>
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    <HomeInfo />
                    <div>
                      <h3>Videonávod</h3>
                      <p>Pokud si nevíte rady s registrací a přihlášením do zákaznického portálu, pusťte si následující videonávod!</p>
                    <iframe title="Videonávod" style={{height: 'calc((39vw - 20px) * 0.5625)'}} width="100%"  src="https://www.youtube.com/embed/jhCWwUIvIsE" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="jezekswSmall.png" className="login-logo" /> Zákaznický portál</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Registrace</h3>
                    
                    <div className={showForm} >
                      <p>Vyplňte prosím registrační formulář a získejte tak možnost spravovat vaše firemní údaje, a také možnost objednávat nové verze (upgrade) našich programů DUEL a STEREO.</p>
                    </div>
                    <br />
                    {showE === true ? error : ''}
                    <Alert variant="success" show={showInfo}>
                            <strong>Skvělé, zbývá už jen jeden krok!</strong><br />
                            Otevřete email, který jsme Vám právě zaslali, a klikněte v něm na aktivační odkaz.<br />Dokončíte tak registraci a budete se moci přihlásit.
                    </Alert> 
                    <div className={showForm} >
                      <Formik
                        validationSchema={schema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{ password: '', confirmPassword: '', surName: '', firstName: '', phone: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleRegistration(token, values.password,values.confirmPassword,values.surName,values.firstName,values.phone);              
                            setSubmitting(false);
                          }, 1);
                        }}
                      >
                      {({ 
                        handleSubmit,
                        handleChange,
                        validateField,
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup controlId="email">
                            <Form.Row>
                              <FormLabel column lg={4}>E-mail</FormLabel>
                              <Col>
                                <FormControl 
                                  type="text"
                                  name="email"
                                  autoComplete="username"
                                  value={emailFromToken}
                                  disabled={true}
                                />
                                <i className="fas fa-info-circle"></i>&nbsp;<i>E-mail, na který byla odeslána pozvánka, slouží pro budoucí přihlašování a není možné ho měnit.</i>
                                
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          <FormGroup controlId="firstName">
                            <Form.Row>
                              <FormLabel column lg={4}>Jméno</FormLabel>
                              <Col>
                                <FormControl 
                                  type="text"
                                  name="firstName"
                                  value={values.firstName}
                                  onChange={handleChange}
                                  onBlur={() => validateField('firstName')}
                                  //autoFocus
                                  isValid={touched.firstName && !errors.firstName} 
                                  isInvalid={!!errors.firstName}
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                  {errors.firstName}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          <FormGroup controlId="surName">
                            <Form.Row>
                              <FormLabel column lg={4}>Příjmení</FormLabel>
                              <Col>
                                <FormControl 
                                  type="text"
                                  name="surName"
                                  value={values.surName}
                                  onChange={handleChange}
                                  onBlur={() => validateField('surName')}
                                  isValid={touched.surName && !errors.surName} 
                                  isInvalid={!!errors.surName}
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                  {errors.surName}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          <FormGroup controlId="phone">
                            <Form.Row>
                              <FormLabel column lg={4}>Telefon</FormLabel>
                              <Col>
                                <FormControl 
                                  type="text"
                                  name="phone"
                                  value={values.phone}
                                  onChange={handleChange}
                                  onBlur={() => validateField('phone')}                                  
                                  isValid={touched.phone && !errors.phone} 
                                  isInvalid={!!errors.phone}
                                  
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                  {errors.phone}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          <FormGroup controlId="formPassword">
                            <Form.Row>
                              <FormLabel column lg={4}>Heslo</FormLabel>
                              <Col>
                                <FormControl 
                                  type="password"
                                  name="password"
                                  autoComplete="current-password"
                                  value={values.password}
                                  onChange={async (e) => { await handleChange(e); validateField('password')}}
                                  onBlur={() => validateField('password')}
                                  isValid={touched.password && !errors.password} 
                                  isInvalid={!!errors.password}
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                  {errors.password}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          <FormGroup controlId="formConfirmPassword">
                            <Form.Row>
                              <FormLabel column lg={4}>Ověření hesla</FormLabel>
                              <Col>
                                <FormControl 
                                  type="password"
                                  name="confirmPassword"
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={() => validateField('confirmPassword')}
                                  isValid={touched.confirmPassword && !errors.confirmPassword}
                                  isInvalid={!!errors.confirmPassword} 
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.confirmPassword}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          
                          {error}
                          <br />
                          <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Probíhá registrace...' : 'Registrovat'}</Button>
                          
                        </Form>
                      )}
                      </Formik> 
                    </div>    
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>   
      </main>
    </div>
  )
}

export default Registration
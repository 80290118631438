import React, { useState, useEffect }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { confirm } from "../../../Utils/Confirmation";

import { Formik } from 'formik'
import * as Yup from 'yup'

import { TextEditor } from "../../../Utils/draft-js/TextEditor";

//import moment from 'moment'

import FilesAdd from '../../../parts/files/Files.Add.part'

import LoadFreeText from '../../../sharedComponents/loadFreeText/LoadFreeText.part'

interface TasksPointAddUpdateProps {
  editing?:boolean,
  taskId?:string,
  linkId?:string,
  solutionId?:string,
  showAs?:string,
  pointSolution?:boolean,    
} 

const TasksSolutionAddUpdate: React.FC<TasksPointAddUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [insertNewContact, setInsertNewContact] = useState(false)
  
  const [showSolText, setShowSolText] = useState(true);

  
  // Připojení store
  const rootStore = useStore()
  
  //const [interval, startInterval] = useState(setInterval(() => {},1000000))


  // Nastavení editivaného kontaktu
  const editedSolution = props.pointSolution !== true 
  ? rootStore.getTaskById(props.taskId)?.solutions.find(s => s.id === props.solutionId)
  : rootStore.getTaskById(props.taskId)?.points.find(p => p.id === props.linkId)?.solutions.find(s => s.id === props.solutionId)

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    //clearInterval(interval)
  } 
 
   
  useEffect(() => {
    return () => {
      deleteInterval()
    }
  }, [])

  const deleteInterval = async () => {
    for (var i = 0; i < 99999; i++)
	    clearInterval(i);
  }
  
  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteSolution = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete řešení úkolu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání řešení úkolu'})) {
        deleteSolution(id)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání pozvánky
  const deleteSolution = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      


      //Před smazáním dohledáme soubory
      await rootStore.batchDeleteFilesByLinkId(id)


      let url:string = getUrl('URL_TASKS')+'/'+props.taskId+'/solutions/'+id
      if (props.pointSolution === true) { url = getUrl('URL_TASKS')+'/'+props.taskId+'/points/'+props.linkId+'/solutions/'+id }


      // Zavolání API
      await axios.delete(url, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            

          sessionStorage.setItem('autoCloseModal','1')

          rootStore.fetchTaskSolutions(props.taskId)
          sessionStorage.setItem('taskDetailEdited', 'Y')

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
   
      })()    
  }


  // Funkce po odeslání formuláře
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      //localStorage.setItem('lastSupportType',values.supportType) // Nastavení posledního použitého typu kontaktu

      let dataToSend:any
      let url:string = getUrl('URL_TASKS')+'/'+props.taskId+'/solutions/'

      if (props.editing === true) {
        dataToSend = {
          id: props.solutionId,
          text: values.text,  
        }

        url = getUrl('URL_TASKS')+'/'+props.taskId+'/solutions/'+props.solutionId
        if (props.pointSolution === true) { url = getUrl('URL_TASKS')+'/'+props.taskId+'/points/'+props.linkId+'/solutions/'+props.solutionId }

        
      }
        else {
          dataToSend = {
            text: values.text,
             
            }
          url = getUrl('URL_TASKS')+'/'+props.taskId+'/solutions/'
          if (props.pointSolution === true) { url = getUrl('URL_TASKS')+'/'+props.taskId+'/points/'+props.linkId+'/solutions/' }
        }

      // Nastavení adresy požadavku podle typu NOVÝ/EDITACE
      
      

      // Zavolání API
      await axios.post(url, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          setInsertNewContact(false)
          sessionStorage.setItem('autoCloseModal','1')
          rootStore.fetchTaskSolutions(props.taskId)
          //clearInterval(interval)

          sessionStorage.setItem('taskDetailEdited', 'Y')


          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShowForm('')
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    text: Yup.string()
      .required('Text řešení úkolu musí být vyplněn!'),
    
  })  
  
 // Započetí odpočítávání délky řešení kontaktu
 const startCounting = async (startD:any) => {
  await deleteInterval()
  
  
}

// Startovací sequence při otevření formuláře
const startSequence = () => {
  setInsertNewContact(true)
  //setStartDate(new Date()) 
  setShowInfo(false) 
  setShowForm('')
  startCounting(new Date()) 
   
  
}



const handleKeyDownCtrlEnter = (e:any, handleSubmit:any) => {
  
  if (e.keyCode === 13 && e.ctrlKey && !loading) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
  }
 
};

// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>
          <Alert variant="success" show={showInfo}>
            <strong>Řešení úkolu úspěšně {props.editing === true ? 'upraveno' : 'přidáno'}!​</strong>
            
          </Alert>  
          {insertNewContact &&<>
          <div className={showForm} >
          <Alert variant="info" > 
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                text: props.editing === true ?  editedSolution?.text : '',                
                isDone: rootStore.getTaskById(props.taskId)?.isDone,
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values!)              
                  setSubmitting(false);
                }, 1);
              }}
              
              
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                    <strong>Řešení úkolu</strong><br />
                    </Form.Row>
                    
                    

                    <FormGroup as={Col}  controlId="text">
                      <FormLabel>Popis řešení</FormLabel>
                        {showSolText &&
                        <TextEditor
                          setFieldValue={(val) => setFieldValue("text", val)}
                          value={values.text!}
                        />}
                        <div>
                          <LoadFreeText 
                            setFieldValue={setFieldValue} 
                            showFormField={setShowSolText} 
                            pole="text" 
                            originalText={values.text!} 
                            handleKeyDown={handleKeyDownCtrlEnter}
                            handleSubmit={handleSubmit}    
                            />
                        </div>
                        <div className="invalid-feedback" style={{display: 'block'}}>{errors.text}</div>
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                    </FormGroup>

                    <hr />
                    <div style={{display: 'none'}}>
                    <FormGroup controlId="isDone">
                    <Form.Row>
                      <FormLabel column lg={6}>Nastavit {props.pointSolution === true ? 'bod úkolu':'úkol'} jako splněný</FormLabel>
                      <Col lg={1}>
                      <FormControl 
                          type="checkbox"
                          name="isDone"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isDone}
                          //disabled={getUser().id !== item?.responsibleUserId}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isDone && !errors.isDone} 
                          isInvalid={!!errors.isDone}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isDone}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                    </FormGroup>
                    </div>
                    
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám...' : 'Uložit změny'}</Button>
                  
                </Form>

              )}
            </Formik>  
            
            </Alert>
          </div>
          </>}
          </>



return ( 
  <>
  <>
  {props.editing===true?
  <ButtonGroup style={{}}>
    <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Upravit</>:'+ Přidat řešení úkolu'}</Button>
    
    <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
          <FilesAdd id={props.solutionId} type='task' masterId={props.taskId} showAs='drop' />
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => { handleOnClickDeleteSolution(props.solutionId, '') } } style={{color: 'red'}} >Smazat řešení {props.pointSolution === true && 'bodu '}úkolu</Dropdown.Item>
          
    </DropdownButton>
  </ButtonGroup>
  :
  props.showAs !== 'drop' ?
  <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Upravit</>:<>+ Přidat řešení {props.pointSolution === true && 'bodu '}úkolu</>}</Button>
  :
  <><Dropdown.Item onClick={() => {startSequence()}}>Přidat řešení {props.pointSolution === true && 'bodu '}úkolu</Dropdown.Item></>
  
  }
  </>
  
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editing === true ? 'Úprava' : 'Přidání'}&nbsp;řešení {props.pointSolution === true && 'bodu '}úkolu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(TasksSolutionAddUpdate))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getRoleName } from '../../../../Utils/Common'

import { Button, Col, Alert, Modal, Row, Badge, Spinner, Dropdown } from 'react-bootstrap'

import { useStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'

import { confirm } from "../../../../Utils/Confirmation";

import axios from 'axios';

import SupportContactsCustomerInfo from '../../../support/components/Support.Contacts.CustomerInfo.part'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

interface MeetingsDetailProps { 
  accountId?:string
  editing?:boolean,
  //supportId?:string,
  fromList?:boolean,
  fromSubMenu?:boolean,
  setPageLoading?:any    
}

const AccountsDetail: React.FC<MeetingsDetailProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rightsAdmin:boolean = ((localStorage.getItem('Admin')! === '1')) ? true : false


  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)

  const [showDetail, setShowDetail] = useState(false)
  const [error, setError] = useState(null)

  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  // Nastavení editivaného kontaktu
  const item = rootStore.getAccountById(props.accountId)

  //const customerListAllF = rootStore.customerListAll

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    
    if (sessionStorage.getItem('accountsDetailEdited') === 'Y') {
      //sessionStorage.setItem('supportSearchPage', '1')
      
      
       //rootDialsStore.addHelper('contacts')
       //console.log(Number(sessionStorage.getItem('supportSearchPage') !== null ? sessionStorage.getItem('supportSearchPage') : 0 ))
       rootStore.fetchAccounts(Number(sessionStorage.getItem('accountsSearchPage') !== 'null' ? sessionStorage.getItem('accountsSearchPage') : 0 ) === 0 ? 1 : Number(sessionStorage.getItem('accountsSearchPage')))
       //rootDialsStore.removeHelper('contacts')
       sessionStorage.setItem('accountsDetailEdited', 'N')
       
    }

    sessionStorage.removeItem('autoCloseModal')
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
  setLoading(true)
  setError(null)
  sessionStorage.setItem('accountsDetailEdited', 'N')
  await rootStore.fetchAccountsCustomers(props.accountId)
  if (rightsBH === true) await rootDialsStore.fetchDialsRoles()
  if (rightsBH === true) await rootDialsStore.fetchDialsUserGroupsAll()
  await rootStore.fetchAccountsUserGroups(props.accountId)
  //if (Object.keys(rootStore.customerListAll).length < 100) await rootStore.fetchCustomerListAll('', '', 1)
  setShowDetail(true)
  await setLoading(false)
}

  // Dotaz před smazáním úkolu
  const handleOnClickUpdateUserGroup = (groupId:string, text:string, state:boolean, account:string ) => {
    (async () => {
      let text1:string = state === true ? 'přidat do skupiny':'odebrat ze skupiny'
      let text2:string = state === true ? 'Ano, přidat':'Ano, odebrat'

      //text1 = text1 + ' ' + getRoleName(role)


      if (await confirm(<>Opravdu chcete uživatelský účet <strong>{account}</strong> {text1} <strong>{text}</strong>?</>,text2, 'Ne', {title: 'Změna skupin uživatelského účtu'})) {
        updateUserGroup(groupId, state)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání úkolu
  const updateUserGroup = (groupId:string, state:boolean) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API

      if (state === false) {
      
        await axios.delete(getUrl('URL_DIALS_USER_GROUPS')+'/'+groupId+'/users/'+props.accountId, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {

          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setTimeout(() => {

              setError(null)
            
          }, 4800);
            //setShowE(true)          
          });

      }

      if (state === true) {
      
        await axios.post(getUrl('URL_DIALS_USER_GROUPS')+'/'+groupId+'/users/'+props.accountId, {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů

            //rootStore.fetchMeetingPoints(props.meetingId)
            //rootStore.fetchTasksForMeeting(props.meetingId)
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setTimeout(() => {

                setError(null)
              
            }, 4800);
            //setShowE(true)          
          });

      }


      await axios.get(getUrl('URL_ACCOUNT')+'queries/user-group/user/'+props.accountId, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů
            item?.setUserGroups(response.data.items)

          }).catch(error => {
            //const errorMessage:any = (<ErrorAlert errorData={error} />)
            //setError(errorMessage)
            //setShowE(true)          
          });


   
      })()    
  }

  // Dotaz před smazáním úkolu
  const handleOnClickUpdateRole = (role:string, state:boolean, account:string ) => {
    (async () => {
      let text1:string = state === true ? 'přiřadit roli':'odebrat roli'
      let text2:string = state === true ? 'Ano, přiřadit':'Ano, odebrat'

      //text1 = text1 + ' ' + getRoleName(role)


      if (await confirm(<>Opravdu chcete uživatelskému účtu <strong>{account}</strong> {text1} <strong>{getRoleName(role)}</strong>?</>,text2, 'Ne', {title: 'Změna rolí uživatelského účtu'})) {
        updateRole(role, state)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání úkolu
  const updateRole = (role:string, state:boolean) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API

      if (state === false) {
      
        await axios.delete(getUrl('URL_ACCOUNT')+''+props.accountId+'/roles/'+role, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů

            //rootStore.fetchMeetingPoints(props.meetingId)
            //rootStore.fetchTasksForMeeting(props.meetingId)
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setTimeout(() => {

              setError(null)
            
          }, 4800);
            //setShowE(true)          
          });

      }

      if (state === true) {
      
        await axios.post(getUrl('URL_ACCOUNT')+''+props.accountId+'/roles/'+role, {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů

            //rootStore.fetchMeetingPoints(props.meetingId)
            //rootStore.fetchTasksForMeeting(props.meetingId)
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setTimeout(() => {

                setError(null)
              
            }, 4800);
            //setShowE(true)          
          });

      }


      await axios.get(getUrl('URL_ACCOUNT')+''+props.accountId, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů
            item?.setRoles(response.data.roles)

            //rootStore.fetchMeetingPoints(props.meetingId)
            //rootStore.fetchTasksForMeeting(props.meetingId)
          }).catch(error => {
            //const errorMessage:any = (<ErrorAlert errorData={error} />)
            //setError(errorMessage)
            //setShowE(true)          
          });


   
      })()    
  }


  // Dotaz před smazáním úkolu
  const handleOnClickUpdateEmployee = (state:boolean, account:string ) => {
    (async () => {
      let text1:string = state === true ? 'nastavit příznak':'odebrat příznak'
      let text2:string = state === true ? 'Ano, nastavit':'Ano, odebrat'

      //text1 = text1 + ' ' + getRoleName(role)


      if (await confirm(<>Opravdu chcete u uživatelského účtu <strong>{account}</strong> {text1} <strong>Zaměstnanec JSW</strong>?</>,text2, 'Ne', {title: 'Změna nastavení příznaku "Zaměstnanec JSW"'})) {
        updateEmployee(state)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání úkolu
  const updateEmployee = (state:boolean) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API

      if (state === false) {
      
        await axios.delete(getUrl('URL_ACCOUNT')+''+props.accountId+'/employee', { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů

            //rootStore.fetchMeetingPoints(props.meetingId)
            //rootStore.fetchTasksForMeeting(props.meetingId)
          }).catch(error => {
            //const errorMessage:any = (<ErrorAlert errorData={error} />)
            //setError(errorMessage)
            //setShowE(true)          
          });

      }

      if (state === true) {
      
        await axios.post(getUrl('URL_ACCOUNT')+''+props.accountId+'/employee', {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů

            //rootStore.fetchMeetingPoints(props.meetingId)
            //rootStore.fetchTasksForMeeting(props.meetingId)
          }).catch(error => {
            //const errorMessage:any = (<ErrorAlert errorData={error} />)
            //setError(errorMessage)
            //setShowE(true)          
          });

      }


      await axios.get(getUrl('URL_ACCOUNT')+''+props.accountId, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam úkolů
            item?.setIsEmployee(response.data.isEmployee)
            item?.setRoles(response.data.roles)
            //rootStore.fetchMeetingPoints(props.meetingId)
            //rootStore.fetchTasksForMeeting(props.meetingId)
          }).catch(error => {
            //const errorMessage:any = (<ErrorAlert errorData={error} />)
            //setError(errorMessage)
            //setShowE(true)          
          });


   
      })()    
  }



// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>

          {showDetail &&<>
          <div id='accountDetail'>

            <Row>
              <Col lg={{span: 9, order :1}}>
                <h4 className='noprint'>Uživatelský účet</h4>
                <Alert variant='secondary'>

                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Jméno a příjmení:</strong></Col>
                  <Col>{item?.fullName}</Col>
                </Row>
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Email:</strong></Col>
                  <Col>{item?.email}</Col>
                </Row>
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Telefon:</strong></Col>
                  <Col>{item?.phone}</Col>
                </Row>

                {item?.isDisabled === true &&
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Zablokován:</strong></Col>
                  <Col>Ano</Col>
                </Row>}

                {item?.isEmployee === true &&
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Zaměstnanec:</strong></Col>
                  <Col>Ano</Col>
                </Row>}


                </Alert>

                
                <h4>Seznam dostupných zákazníků</h4>
                  
                <Alert variant='secondary'>
                  
                  
                  {loading === true 
                    ? 
                    <><Spinner animation='border'  />&nbsp;</>
                    : 
                    <>
                      <>
                        {Object.keys(item?.customers!).length === 0 &&<>Seznam zákazníků neobsahuje žádné záznamy.</>}
                      </>

                      {item?.customers.slice()
                      //.sort((a, b) => (new Date(a.createDate) > new Date(b.createDate)) ? 1 : -1)
                      .map(customer => (
                        <div key={customer.id}>
                          <strong><SupportContactsCustomerInfo customerId={customer.id} isActiveCustomer={customer.isActive} customerName={customer.businessName} customerNameFull={customer.businessName}  /> </strong>
                          &nbsp;(#{customer.accountNumber}, IČO: {customer.companyRegistrationNumber}){customer.isActive === false &&<>&nbsp;<Badge variant='danger'>Neaktivní</Badge></>} <br />
                        </div>
                
                      ))}
              
                    </>
                  }
                  </Alert>
              

              </Col>
              <Col lg={{span: 3, order :2}}>
                <h4>Role účtu</h4>
                {(rightsBH === true ) ?   
                  <>
                  {rootDialsStore.dialsRoles.map((role,index) => <span key={index}>{index > 0 && <></>}
                    <Button 
                      onClick={() => handleOnClickUpdateRole(role, !(item?.roles.includes(role)!), item?.email!)}
                      disabled={item?.isDisabled === true ? true : false}
                      block 
                      style={{marginBottom: '5px'}} 
                      size='sm' 
                      variant={item?.roles.includes(role) ? 'info' : 'outline-secondary'}
                    >
                      {getRoleName(role)}
                    </Button>
                    </span>)}


                  </>
                  :
                  <>
                  {item?.roles.map((role,index) => <span key={index}>{index > 0 && <></>}<Button block style={{marginBottom: '5px', cursor: 'default'}} size='sm' variant='info'>{getRoleName(role)}</Button></span>)}
                  </>
                }
                <h4>Skupiny</h4>
                {(rightsBH === true ) ?   
                  <>
                  {rootDialsStore.dialsUserGroupsAll.map((userGroup,index) => <span key={index}>{index > 0 && <></>}
                    <Button 
                      onClick={() => handleOnClickUpdateUserGroup(userGroup.id, userGroup.name!, !(item?.userGroups.includes(userGroup.id)!), item?.email!)}
                      disabled={item?.isDisabled === true ? true : false}
                      block 
                      style={{marginBottom: '5px'}} 
                      size='sm' 
                      variant={item?.userGroups.includes(userGroup.id) ? 'info' : 'outline-secondary'}
                    >
                      {userGroup.name}
                    </Button>
                    </span>)}


                  </>
                  :
                  <>
                  {item?.userGroups.map((userGroup,index) => <span key={index}>{index > 0 && <></>}<Button block style={{marginBottom: '5px', cursor: 'default'}} size='sm' variant='info'>{rootDialsStore.getDialsUserGroupAllById(userGroup)?.name}</Button></span>)}
                  </>
                }
                {error}
                {rightsAdmin === true && <>
                  <h5 style={{marginTop: '20px'}}>Vlastnosti účtu</h5>
                  <Button 
                    onClick={() => handleOnClickUpdateEmployee(!(item?.isEmployee), item?.email!)}
                    disabled={item?.isDisabled === true ? true : false}
                    block 
                    style={{marginBottom: '5px'}} 
                    size='sm' 
                    variant={item?.isEmployee ? 'info' : 'outline-secondary'}
                  >
                    {!item?.isEmployee ? 'Zaměstnanec JSW' : 'Zaměstnanec JSW'}
                  </Button>
                
                
                </>}
              </Col>
              
            </Row>
              
             
                  <div style={{float: 'right'}}></div> 
                  
              
          
          </div>
          </>}

          </>



return ( 
  <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Detail účtu</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail účtu' onClick={() => {startSequence()}}>{item?.fullName}</span>
      </>
    :
    <>
      <Button variant='outline-primary' size="sm" style={{}} onClick={() => {startSequence()}} ><span style={{}} title='Detail účtu' ></span>Detail</Button>
    </>
    }
  
    <Modal 
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
        <div className="showMobile">
          Detail uživatelského účtu<br />
          {item?.fullName}
        </div>
        <div className="showWeb">
          Detail uživatelského účtu <Badge variant='secondary'>{item?.fullName}</Badge>
        </div>  
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {loading === true && <><Spinner animation='border' size='sm'  />&nbsp;</>}
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(AccountsDetail))

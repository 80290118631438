import { types } from 'mobx-state-tree'

const Tokens = types.model('Tokens',{
  id: types.optional(types.string, ''),
    companyId: types.optional(types.string, ''),
    token: types.optional(types.string,''),
    name: types.optional(types.string,''),
    createDate: types.optional(types.string, ''),
    isRevoked: types.optional(types.boolean, false),
})


export const AppProduelCompanies = types
  .model({
    id: types.optional(types.string, ''),
    customerId: types.optional(types.string, ''),
    name: types.optional(types.string,''),
    createDate: types.optional(types.string, ''),
    tokens: types.maybeNull(types.array(Tokens)),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    setTokens(newData:any) {
      self.tokens = newData
    }

  }))

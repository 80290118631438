import { types } from 'mobx-state-tree'

export const CalculatorsUpgradeCustomer = types
  .model({
    id: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
    companyRegistrationNumber: types.maybeNull(types.string)
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

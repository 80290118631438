import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal,Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';



//import { useStore } from '../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface CustomersNamesUpdateProps {
  label: string,
  customerId : string,
  showAs: string,
  oldName?: string,
  setCustomerAllNames?: any,
  setCustomerNames?: any,
}

const CustomersNamesUpdate: React.FC<CustomersNamesUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [show, setShow] = useState(false)  

  const [customerAllNames, setCustomerAllNames] = useState([""])
  const [customerNames, setCustomerNames] = useState(customerAllNames.filter(cn => cn !== ''))

  
  // Připojení store
  //const rootStore = useStore()
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }

    // Načtení jmen použitých v kontaktech
    await axios.get(getUrl('URL_SUPPORT_CONTACTS_ADD')+'/customer/'+props.customerId+'/names',{
      headers: {
        'Authorization': 'Bearer ' + aToken
      }  }).then(response => {
        
        setCustomerAllNames(response.data)
        setCustomerNames(response.data)

    }).catch(error => {
      
    });    
    
    
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm(true)
    setShow(true)






  }


  const [stateName, setStateName] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })


// Filtrování jmen v seznamu
const filterNames = (value:string) => {
  //setCustomers(rootStore.customerListAll)
  const newList = customerAllNames.filter(cn => cn !== '' && cn?.toLowerCase().includes(value!.toLowerCase()))
  setCustomerNames(newList)
}


// Odchytávání kláves po stisknutí na údaji OSOBA
const handleKeyDownNames =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = stateName;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, pošleme focus dál a ve funkci nepokračujeme
      if (!stateName.displayItems) { 
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
        return
      }  

      setStateName({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      // Nastavíme hodnotu pole formuláře
      setFieldValue('newName',customerNames[activeItem])
      
      // Pošleme focus dál
      if (e.target.nodeName === "INPUT") {
        //var formN = e.currentTarget.form;
        //formN.elements[nextInput].focus()
      }

  }
  else if (e.keyCode === 27) {    // ESC
      e.preventDefault();
      
      setStateName({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      
  }

  else if (e.keyCode === 38) {    // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }
      setStateName({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });
  }
  else if (e.keyCode === 40) {    // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customerNames && activeItem === customerNames.length - 1) || activeItem >= 9) {  // Pokud jsem na poslední pozici, již se nikam neposunu
        //console.log('T')  
        return;
      }
      setStateName({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });
  }
  

};





    // Seznam jmen pro rozevírací seznam
    const oldNames = customerAllNames
    .slice()
    .map((n,index) => (
      <option key={index} value={n}>{n}</option>
    ))   
  
  // Funkce pro změnu adresy
  const handleUpdate = (oldNameData:string, newNameData:string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {



      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      await axios.post(getUrl('URL_SUPPORT_CONTACTS_ADD')+'/customer/'+props.customerId+'/update-names', { customerId: props.customerId, oldName: oldNameData, newName: newNameData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Změna dat ve Store
        
        
        // Načtení jmen použitých v kontaktech
        axios.get(getUrl('URL_SUPPORT_CONTACTS_ADD')+'/customer/'+props.customerId+'/names',{
          headers: {
            'Authorization': 'Bearer ' + aToken
          }  }).then(response => {
            
            setCustomerAllNames(response.data)
            setCustomerNames(response.data)

        }).catch(error => {
          
        });
        

        setShowInfo(true)
        setShowForm(false)
        setLoading(false)
        sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              //setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              setShowForm(true)
            }
          }, 1000);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()

  }



  

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    
    
  })   
  return (
    <>
      <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={handleShow}>{props.label}</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={handleShow}>{props.label}</Button>}
        {props.showAs === 'link' &&<span style={{cursor: 'pointer', color: '#4e73df', textDecoration: 'underline'}}  onClick={handleShow}>{props.label}</span>}
      </>




      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Přejmenování komunikujících osob</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Jméno komunikující osoby bylo úspěšně přejmenováno!​</strong>
            
          </Alert>  
          {showForm &&
          <div className='' >
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                oldName: props.oldName !== null ? props.oldName: '', 
                newName: '',
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.oldName!, values.newName!)
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <FormGroup as={Col} controlId="oldName">
                      <FormLabel>Původní jméno</FormLabel>
                      <Form.Control as="select" 
                        name="oldName" 
                        onChange={handleChange}
                        //onChange={handleChange}
                        value={values.oldName!}
                        isInvalid={!!errors.oldName}
                        >
                          <option value="">--- Vyberte ---</option>
                          {
                          oldNames
                          }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.oldName}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup as={Col} controlId="newName">
                    <FormLabel>Nahradit za</FormLabel>
                        <FormControl 
                          type="text"
                          name="newName"
                          autoFocus={props.oldName !== null ? true:false}
                          value={values.newName!}
                          autoComplete="off"
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e);  stateName.displayItems = true; stateName.activeItem = 0;  await filterNames(e.target.value) }}
                          //tabIndex={3}
                          onKeyDown={(e:any) => handleKeyDownNames(e, setFieldValue, props.isCustomer === true ? 2:6)}
                          onBlur={(e:any) => {setTimeout(() => {setStateName({activeItem: 0, filteredItems: stateName.filteredItems, displayItems: false, inputValue: stateName.inputValue}); console.log('a')},200 ) }}
                          //onBlur={(e:any) => {stateName.displayItems = false; console.log('a')}}
                          isValid={touched.newName && !errors.newName} 
                          isInvalid={!!errors.newName}
                        />
                        {props.customerId! !== '' && stateName.displayItems ?
                            <div className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{border: '1px solid lightgrey', padding: '0px', marginTop: '3px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customerNames.map((cn,index) => {
                                        return (
                                            <li className={`${stateName.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={() => {console.log('X');console.log(cn);setFieldValue("newName",cn);stateName.displayItems = false}}
                                                key = {cn}
                                                >
                                                {cn}
                                                
                                            </li>
                                        )
                                    }).slice(0, 10)
                                }
                                </ul>
                            </div>
                            : null
                        }
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.newName}
                        </Form.Control.Feedback>



                    </FormGroup>
                  </Form.Row>  
                     
                             
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Přejmenovávám...' : 'Přejmenovat'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>}
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersNamesUpdate))

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { setUserSession, setUserRoles, checkVersionWithoutRestart } from '../../../Utils/Common'

import { Container, Form, Col, Button, Image, Alert } from 'react-bootstrap'

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlertWithoutLogout.part'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel' 

import { getUrl } from '../../../Utils/Common'
//import * as Constants from '../../../Constants/Constants'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { useStore } from '../../../store'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { login } from '../../../authProvider'
import { sleep } from '../../../helpers/sleep'

//import {appInsights} from '../../../AppInsights'
//import {SeverityLevel} from '@microsoft/applicationinsights-web';

function Login(props:any) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const rootStore = useStore()
 
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
      window.scrollTo(0, 0) // Odrolování nahoru
      await checkVersionWithoutRestart()
      if (window.performance) {
        if (performance.navigation.type > 0) {
          sessionStorage.removeItem('automaticLogout')
        } else {
          
        }
      }  
    })() 
  })  

  // Funkce po odeslání formuláře pro přihlášení
  const handleLogin = (emailData:string,passwordData:string) => {
    setError(null)
    setLoading(true)
    //appInsights.trackEvent({ name: 'Login', properties: { emailData } })
    // Pokud mám v session, že jsem byl automaticky odhlášen, vymazat tuto session
    sessionStorage.removeItem('automaticLogout')

    // Komunikace s BE
    axios.post(getUrl('URL_LOGIN'), { email: emailData, password: passwordData }
    ).then(response => {

      (async () => {
        setUserSession(response.data.token.accessToken, response.data)

        // Předání tokenů doplňku pro řízení RefreshTokenu
        login(response.data.token)
        await sleep(100)

        // Naplnění store přihlášeným uživatelem a stažení customers
        sessionStorage.setItem('isIn','1')
        await rootStore.fetchLoggedUsers()
        await rootStore.fetchLoggedUserPreferences()
        await rootStore.fetchCustomerList('','',1)
        console.log('Počet firem, do kterých má uživatel přístup:')
        console.log(localStorage.getItem('customersTotalCount'))

        localStorage.setItem('customersTotalCountInit',localStorage.getItem('customersTotalCount')!)
        localStorage.setItem('customersTooManyInit',localStorage.getItem('customersTooMany')!)
        // Pokud je jen jeden customer, tak ho otevřeme
        if (localStorage.getItem('customersTotalCount') === '1') {
        
          // Konunikace s BE pro otevření Customer
          await axios.get(getUrl('URL_CUSTOMER_OPEN')+localStorage.getItem('customersFirstId'),{
            headers: {
              'Authorization': 'Bearer ' + response.data.token.accessToken
            }}
          ).then(responseOpen => {
            // Předání nových tokenů doplňku pro řízení RefreshTokenu
            login(responseOpen.data.token)
            // Nastavení nových práv
            setUserRoles(responseOpen.data.roles)
            // Nastavení id Otevřené firmy do localStore
            localStorage.setItem('openedCustomerId', localStorage.getItem('customersFirstId')!)
          }).catch(error => {
            
          });

          // Přepnutí na Dashboard firmu
          await rootStore.fetchCustomerOpened()
          await rootStore.fetchCustomerOfferCount()
          
          if (rootStore.getCustomerOfferCountByState('P') > 0) {
            await props.history.push('/upgrade-offers')
          }
          else {
            await props.history.push('/customers-dashboard')
          }
          //await props.history.push('/customers-dashboard')
        }
        else {
          // Pro více firem přepneme na výběr firmy
          if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'S') {
            props.history.push('/support')
          }
          else if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'M') {
            props.history.push('/meetings')
          }
          else if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'T') {
            props.history.push('/tasks')
          }
          else if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'L' && localStorage.getItem('lastOpenedSite')) {
            props.history.push('/'+localStorage.getItem('lastOpenedSite'))
          }
          else {
            props.history.push('/customers-open')
          }

            
        }        

      })();

    }).catch(error => {
      setLoading(false)
      //console.log(error)
      const errorMessage:any = (<ErrorAlert errorData={error} />)
      setError(errorMessage)
      //let appInsights = null;
        //let test = 15/0
        //console.log(test)
        //appInsights.trackException({ error: new Error('some error'), severityLevel: SeverityLevel.Error });
    });
  }

  // Validační schéma přihlášení 
  const schema = Yup.object().shape({

    password: Yup.string()
      .required('Heslo musí být vyplněno!'),
    email: Yup.string()
      .email('E-mailová adresa je ve špatném formátu!')
      .required('E-mail musí být vyplněn!')
  })  

  // Vykreslení stránky
  return (
    <div className="App">
     
      <main>    
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    <HomeInfo />
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="jezekswSmall.png" className="login-logo" /> Zákaznický portál</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Přihlášení</h3>
                    <Alert variant="warning" show={sessionStorage.getItem('automaticLogout') === '1' ? true : false}>
                      <p>Již dlouho jste nezadávali své přihlašovací údaje.<br />Pro pokračování je potřeba se znovu přihlásit!</p>
                    </Alert>
                    <Alert variant="warning" show={sessionStorage.getItem('automaticLogout') === '2' ? true : false}>
                      <p>Z důvodu chyby došlo k automatickému odhlášení.<br />Pokud chcete pokračovat, přihlaste se prosím znovu.</p>
                    </Alert>
                    <Alert variant="warning" show={sessionStorage.getItem('automaticLogout') === '3' ? true : false}>
                      <p>Z důvodu chyby sítě došlo k automatickému odhlášení.<br />Pokud chcete pokračovat, přihlaste se prosím znovu.</p>
                    </Alert>
                    <Formik
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{ email: '', password: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleLogin(values.email,values.password);              
                        setSubmitting(false);
                      }, 1);
                    }}
                  >
                  {({ 
                    handleSubmit,
                    handleChange,
                    validateField,
                    values,
                    touched,
                    isValid,
                    errors,
                    isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup controlId="formEmail">
                        <Form.Row>
                          <FormLabel column lg={3}>E-mail</FormLabel>
                          <Col>
                            <FormControl 
                              type="text"
                              name="email"
                              autoComplete="username"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={() => validateField('email')}
                              isValid={touched.email && !errors.email} 
                              isInvalid={!!errors.email}
                              
                            />
                            
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Row>  
                      </FormGroup>
                      <FormGroup controlId="formPassword">
                        <Form.Row>
                          <FormLabel column lg={3}>Heslo</FormLabel>
                          <Col>
                            <FormControl 
                              type="password"
                              name="password"
                              autoComplete="current-password"
                              value={values.password}
                              onChange={handleChange}
                              
                              isValid={touched.password && !errors.password}
                              isInvalid={!!errors.password} 
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Row>  
                      </FormGroup>
                      
                      {error}
                      <br />
                      <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Přihlašuji...' : 'Přihlásit'}</Button>
                      <br /><Link to="/forgotten-password">Zapomněli jste své heslo?</Link>
                    </Form>
                  )}
                  </Formik>   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>


    </main>
  </div>
  );
}

export default Login
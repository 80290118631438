import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//import { getToken } from '../../../Utils/Common'

//import ErrorAlert from '../../../errorAlert/ErrorAlert.part'

//import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Col, Alert, Modal, Dropdown, Row } from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
//import FormGroup from 'react-bootstrap/FormGroup'
//import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
//import axios from 'axios';

import { observer } from 'mobx-react'
import { useStore, useDialsStore } from '../../../store'

//import { Formik } from 'formik'
//import * as Yup from 'yup'

//import { confirm } from "../../../Utils/Confirmation";

import moment from 'moment'
import NumberFormat from 'react-number-format'


interface SubmissionPacketDetailProps {
  submissionId: string,
  packetId?: string
  showAs: string,
  info: string
}

const SubmissionPacketDetail: React.FC<SubmissionPacketDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  //const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  //const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

  // eslint-disable-next-line
  //const [accountNumber, setAccountNumber] = useState('')
 
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  const editedPacket = rootStore.submissions.find(s => s.id === props.submissionId)?.packets?.find(p => p.id === props.packetId)
  const submission = rootStore.submissions.find(s => s.id === props.submissionId)

  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleCloseE = () => { 
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleShow = async () => {
    //await rootStore.fetchOperations()
    //await rootStore.fetchSubmissionsAll()
    //console.log(props.submissionId)
    //console.log(props.packetId)
    await rootDialsStore.fetchDialsDeliveryCzechPostProductsAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostServicesAll()

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    //setShowInfo(false)
    //setShowForm('')
    setShow(true)
  }


  const packetDetail = 
      <>

          <>
          <div>

            <Row>
              <Col>
                <Alert variant='secondary'>
                <h5>Distribuce</h5>
                <Row>
                  <Col md={4} lg={3}><strong>Datum distribuce:</strong></Col>
                  <Col>{moment(submission?.distributionDate).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>
                
                <Row style={{display: 'none'}}>
                  <Col md={4} lg={3}><strong>Upravil:</strong></Col>
                  <Col>{rootStore.hedgehogUsers.find(hu => hu.id === editedPacket?.userId)?.fullName}</Col>
                </Row>
                <br />
                <h5>Příjemce</h5>
                <Row>
                  <Col md={4} lg={3}><strong>Zákazník:</strong></Col>
                  <Col>{editedPacket?.nameWithPerson}</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Adresa:</strong></Col>
                  <Col>{editedPacket?.street} {editedPacket?.houseNumber}{(editedPacket?.sequenceNumber !== null && editedPacket?.sequenceNumber! !== '') &&<>/{editedPacket?.sequenceNumber}</>} </Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>PSČ a město:</strong></Col>
                  <Col>{editedPacket?.zipCode} {editedPacket?.city}</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Poznámka k tisku:</strong></Col>
                  <Col>{editedPacket?.notePrint}</Col>
                </Row>
                <br />
                <h5>Kontakt</h5>
                <Row>
                  <Col md={4} lg={3}><strong>Telefon:</strong></Col>
                  <Col>{editedPacket?.phoneNumber}</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Mobil:</strong></Col>
                  <Col>{editedPacket?.mobileNumber}</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>E-mail:</strong></Col>
                  <Col>{editedPacket?.emailAddress}</Col>
                </Row>
                <br />
                <h5>Zásilka</h5>
                <Row>
                  <Col md={4} lg={3}><strong>Identifikátor pod.:</strong></Col>
                  <Col>{editedPacket?.technologicalNumber}</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Číslo zásilky:</strong></Col>
                  <Col>{editedPacket?.packetNumber}</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Číslo faktury:</strong></Col>
                  <Col>{editedPacket?.invoiceNumber}</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Hmotnost:</strong></Col>
                  <Col><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={3} fixedDecimalScale={true} value={editedPacket?.weight} /> kg</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Dobírka:</strong></Col>
                  <Col><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={editedPacket?.amount} /> Kč</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Udaná cena:</strong></Col>
                  <Col><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={editedPacket?.insuredValue} /> Kč</Col>
                </Row>
                <Row>
                  <Col md={4} lg={3}><strong>Poznámka zásilky:</strong></Col>
                  <Col>{editedPacket?.note}</Col>
                </Row>

                {editedPacket?.returnDate !== null &&
                <Row>
                  <Col md={4} lg={3}><strong>Vráceno:</strong></Col>
                  <Col>{moment(editedPacket?.returnDate).format("DD.MM.YYYY HH:mm")} ({editedPacket?.returnNote})</Col>
                </Row>
                }

                <Row>
                  <Col md={4} lg={3}><strong>Produkt:</strong></Col>
                  <Col>{rootDialsStore.dialsDeliveryCzechPostProductsAll.find(p => p.id === editedPacket?.productId)?.name}</Col>
                </Row>

                <Row>
                  <Col md={4} lg={3}><strong>Služby:</strong></Col>
                  <Col>
                    {editedPacket?.services?.slice().map(se => (
                      <span key={se}>{rootDialsStore.dialsDeliveryCzechPostServicesAll.find(s => s.id === se)?.code} - {rootDialsStore.dialsDeliveryCzechPostServicesAll.find(s => s.id === se)?.name}<br /></span>
                    ))}
                  </Col>
                </Row>

                
                </Alert>
              </Col>
              
            </Row>

            
 
          
          </div>
          </>

          </>



  
  
  return (
    <>
      {props.showAs === 'drop'
      ?
      <>
        <Dropdown.Item onClick={() => {handleShow()}}>Detail zásilky</Dropdown.Item>
      </>
      :
      props.asLink === true 
        ? 
        <span className='as-link-no-color' title={'Detail distribuce '+props.linkText} onClick={() => {handleShow()}}>{props.linkText}</span> 
        : 
        <>
          <Button variant='outline-primary' size="sm" style={{lineHeight: '0.6'}} onClick={() => {handleShow()}} ><span style={{display: 'none'}} title='Detail zásilky' ><i className="fas fa-search"></i>&nbsp;</span>Detail</Button>
        </>
      
      }

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Detail zásilky {props.info}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {packetDetail} 
        </Modal.Body>

      </Modal>

      <Modal 
        show={showE} 
        onHide={handleCloseE} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Změna stavu zásilky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(SubmissionPacketDetail))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useDialsStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'

interface DialsProgramVersionsUpdateProps {
  id: string,
  showAs: string,
}

const DialsProgramVersionsUpdate: React.FC<DialsProgramVersionsUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  
  let programVersion = rootDialsStore.dialsProgramVersions.find(pv => pv.id === props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    programVersion = rootDialsStore.dialsProgramVersions.find(pv => pv.id === props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (programIdData:string, nameData:string, versionData:number, supportDateToData:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_DIALS_PROGRAM_VERSIONS')+'/'+props.id, { id: props.id, name: nameData, programId: programIdData, version: versionData, supportDateTo: supportDateToData !== '' ? new Date(supportDateToData!).toISOString() : null }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootDialsStore.fetchDialsProgramVersionsAll()

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  const programs = rootDialsStore.dialsPrograms
  .slice()
  .map(dp => (
    <option key={dp.id} value={dp.id}>{dp.name}</option>
  ))

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    name: Yup.string()
    .required('Název verze programu musí být vyplněn!'),
    programId: Yup.string()
    .required('Program musí být vybrán!'),
    version: Yup.number()
    .required('Verze programu musí být vyplněna!')
    .min(1,'Minimální hodnota je 1!'),
    
  })   
  return (
    <>
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava verze programu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Verze programu byla úspěšně upravena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                programId: programVersion?.programId,
                name: programVersion?.name,
                version: programVersion?.version,
                supportDateTo: moment(new Date(programVersion?.supportDateTo!)).format("YYYY-MM-DD"),
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.programId!, values.name!, values.version!, values.supportDateTo!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="programId">
                    <Form.Row>
                      <FormLabel column lg={3}>Program</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="programId" 
                          onChange={handleChange}
                          value={values.programId!}
                          isInvalid={!!errors.programId}
                          >
                            <option value="">--- Vyberte ---</option>
                            {programs}
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.programId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name!}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}

                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                  <FormGroup controlId="version">
                    <Form.Row>
                      <FormLabel column lg={3}>Verze</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="version"
                          value={values.version!}
                          onChange={handleChange}
                          isValid={touched.version && !errors.version} 
                          isInvalid={!!errors.version}

                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.version}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>    
                  <FormGroup controlId="supportDateTo">
                    <Form.Row>
                      <FormLabel column lg={3}>Podpora do</FormLabel>
                      <Col>
                      <FormControl 
                            type="date"
                            name="supportDateTo"
                            value={values.supportDateTo!}
                            onChange={handleChange}
                            isValid={touched.supportDateTo && !errors.supportDateTo} 
                            isInvalid={!!errors.supportDateTo}
                          />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.supportDateTo}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                   
                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(DialsProgramVersionsUpdate))

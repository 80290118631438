import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

//import CustomersSendInvites from './Overviews.Customers.SendInvites.part'
//import CustomersOtherActionsSendEmail from '../../../parts/customersOtherActions/Customers.OtherActions.SendEmail.part'
//import CustomersOtherActionsReminder from '../../../parts/customersOtherActions/Customers.OtherActions.Reminder.part'
//import CustomersOtherActionsGenerateOffer from '../../../parts/customersOtherActions/Customers.OtherActions.GenerateOffer.part'
//import CustomersOtherActionsPublishOffer from '../../../parts/customersOtherActions/Customers.OtherActions.PublishOffer.part'
import CustomersOtherActionsUpgradeFreeCreateLicenses from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeCreateLicenses.part'
import CustomersOtherActionsUpgradeFreePublishLicenses from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreePublishLicenses.part'
import CustomersOtherActionsUpgradeElectronicCreateProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicCreateProformas.part'
import CustomersOtherActionsUpgradeElectronicDeleteProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeleteProformas.part'
import CustomersOtherActionsUpgradeElectronicPublishProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPublishProformas.part'
import CustomersOtherActionsUpgradeElectronicUnpublishProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishProformas.part'
import CustomersOtherActionsUpgradeElectronicDeletePublishedProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeletePublishedProformas.part'
//import CustomersOtherActionsUpgradeElectronicPaidProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPaidProformas.part'
import CustomersOtherActionsUpgradeElectronicCreateFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicCreateFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicPublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPublishFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeleteFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishFinalDocuments.part'

import CustomersOtherActionsUpgradeCashCreateFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashCreateFinalDocuments.part'

import { DropdownButton, ButtonGroup } from 'react-bootstrap'


//import { useStore } from '../../../store'
import { observer } from 'mobx-react'

interface CustomersSelectedActionsProps {
  id: string,
  count: number
}

const CustomersSelectedActions: React.FC<CustomersSelectedActionsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
    
  return (
    <>    
    {rights &&<DropdownButton as={ButtonGroup} id="dropdown-basic-button" title={"Vybráno: "+props.count+" záznamů"} size="sm">

          {(props.id === '1037' || props.id === '1040' || props.id === '1043') &&<CustomersOtherActionsUpgradeFreeCreateLicenses mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}
          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<CustomersOtherActionsUpgradeFreePublishLicenses mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}

          {(props.id === '1019' || props.id === '1025' || props.id === '1031') &&<CustomersOtherActionsUpgradeElectronicCreateProformas mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}

          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeElectronicPublishProformas mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}
          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeElectronicDeleteProformas mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}

          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicUnpublishProformas mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}
          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicDeletePublishedProformas mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}

          {(props.id === '1022' || props.id === '1028' || props.id === '1034') &&<CustomersOtherActionsUpgradeElectronicCreateFinalDocuments mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}
          
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeElectronicPublishFinalDocuments mode='selected' id='' id2='' id3='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments mode='selected' id='' id2='' id3='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}

          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments mode='selected' id='' id2='' id3='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}

          {(props.id === '1001' || props.id === '1007' || props.id === '1013') &&<CustomersOtherActionsUpgradeCashCreateFinalDocuments mode='selected' id='' multiselect={true} showAs='drop' filterId={props.id} info='' count={props.count} />}


          

        </DropdownButton>}
    </>    
  )  
  
}

export default withRouter(observer(CustomersSelectedActions))

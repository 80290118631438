import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation";

import EmailingWhiteListUpdate from './Emailing.WhiteList.Update.part'
import EmailingSendEmails  from '../../emailingSend/Emailing.SendEmails.part'

import { useEmailingStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../../sharedComponents/filterPanel/FilterPanel.part'


const EmailingWhiteListList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  const rightsEM:boolean = ((localStorage.getItem('Emailing')! === '1')  ) ? true : false
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')

    if (sessionStorage.getItem('emailingWhiteListSearchValue') !== '') {
      
    }

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete e-mail "+text+" odebrat z interního Whitelistu?",'Ano, odebrat', 'Ne', {title: 'Odebrání e-mailu z interního Whitelistu'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  //const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()
  
  
  const items = rootEmailingStore.emailingWhiteList.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{item.email}</td>
      <td>{item.note}</td>
      
      {rightsEM &&<td>
        <ButtonGroup>
          <EmailingWhiteListUpdate id={item.id} showAs='button' />
      
          {rightsEM &&<>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            {rightsEM && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.email!) } }>Smazat</Dropdown.Item>} 
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{item.email}</strong><br />
          {item.note}<br />
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rightsEM && <>
          <ButtonGroup>
            <EmailingWhiteListUpdate id={item.id} showAs='button' />
            {rightsEM &&<>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                {rightsEM && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.email!) } }>Smazat</Dropdown.Item>} 
              </DropdownButton>  
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_EMAILING_WHITELIST')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootEmailingStore.fetchEmailingWhiteList(Number(sessionStorage.getItem('emailingWhiteListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('emailingWhiteListSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('emailingWhiteListSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootEmailingStore.fetchEmailingWhiteList(pageNumber)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('emailingWhiteListOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('emailingWhiteListSearchPage', String(1))
        await rootEmailingStore.fetchEmailingWhiteList(1)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (emailData:string, noteData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_EMAILING_WHITELIST'), { email: emailData, note: noteData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootEmailingStore.fetchEmailingWhiteList(Number(sessionStorage.getItem('emailingWhiteListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('emailingWhiteListSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    email: Yup.string()
      .email('E-mailová adresa je ve špatném formátu!')
      .required('E-mail musí být vyplněn!')
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'emailingWhiteList'} 
        filterSearchArray='emailingWhiteListSearchArray' 
        pageVariable='emailingWhiteListSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['Email','Note']}  
        title="Marketing - Interní Whitelist e-mailů"
        titleCount = {Number(localStorage.getItem('lastEmailingWhiteListTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rightsEM &&
          <span title="Přidat e-mail">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat e-mail
          </Button>
          </span>}
        secondaryRightButtons={<EmailingSendEmails showAs='button' />}  
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam e-mailů v interním Whitelistu neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th title='E-mailová adresa, která bude zahrnuta do obesílání bez ohledu na to, zda se nachází u obesílaných zákazníků' className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('emailingWhiteListOrderBy') === 'Email' || localStorage.getItem('emailingWhiteListOrderBy') === null || localStorage.getItem('emailingWhiteListOrderBy') === undefined) ? 'EmailDesc' : 'Email'  )}>
                  E-mail
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('emailingWhiteListOrderBy') === 'Email' || localStorage.getItem('emailingWhiteListOrderBy') === null || localStorage.getItem('emailingWhiteListOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('emailingWhiteListOrderBy') === 'EmailDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th title='Důvod zařazení e-mailové adresy do obesílání' className="jsw-table-head">Poznámka</th>
                {rightsEM &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('emailingWhiteListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('emailingWhiteListSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastEmailingWhiteListTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání e-mailu z interního Whitelistu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Interní Whitelist - přidání e-mailu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>E-mail byl úspěšně přidán na interní Whitelist!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              initialValues={{ 
                email: '', 
                note: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.email!, values.note!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="email">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                                     
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Přidávám e-mail...' : 'Přidat e-mail'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(EmailingWhiteListList))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Row } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

//import { confirm } from "../../../../Utils/Confirmation";

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

interface CustomersGDPRUpdateProps {
  label: string,
  showIcon: boolean,
  block: boolean
}

const CustomersGDPRUpdate: React.FC<CustomersGDPRUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)  
  
  // Připojení store
  const rootStore = useStore()
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  
  // Funkce pro změnu adresy
  const handleUpdate = (isAdministratorData:boolean, isPrimaryProcessorData:boolean) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_GDPR')+customer?.id+'/gdpr', { customerId: customer?.id, isAdministrator: isAdministratorData, isPrimaryProcessor: isPrimaryProcessorData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Změna dat ve Store
          (async () => {
            await rootStore.fetchCustomerOpened()
            // Pokud je zákazník aktivní, načítáme také licence a způsoby použití
            if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive === true) {
              await rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
              rootStore.fetchUsageMode(localStorage.getItem('openedCustomerId')!,rootStore.getCustomerOpenedLicensesActiveActual()[0]?.license.program.id!,  rootStore.getCustomerOpenedLicensesActiveActual()[1]?.license.program.id!)
            }
          })() 
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({

    isAdministrator: Yup.boolean().when('isPrimaryProcessor', {
      is: false,
      then: Yup.boolean().oneOf([true],'Alespoň jedna možnost musí být vybrána')
    }),
    
 
      
    
  }) 
  
  
  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth();
  let day = d.getDate();
  let plus = (month > 8) ? 2:1
  //console.log(month)
  //console.log(plus)
  //if (month > 9) plus = 1
  let c = new Date(year + plus, month, day);



  return (
    <span className="button-box">
      <div className={rights && customer?.isActive ? '' : 'nodisplay'}>
        <Button variant="primary" size="sm" onClick={handleShow}>
          {props.showIcon && <span><i className="fas fa-user-shield"></i>&nbsp;</span>}
          {customer?.deliveryAddress === null ? props.label : props.label}
        </Button>
        
        
      </div>
      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Podepsání smlouvy GDPR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Smlouva byla úspěšně podepsána!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                isAdministrator: false, 
                isPrimaryProcessor: false
              
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.isAdministrator!, values.isPrimaryProcessor!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <h5>Ustanovení smlouvy o zpracování osobních údajů</h5>
                  <Row>
                    <Col>
                      <strong>Poskytovatel služby:</strong><br />
                      Ježek software s.r.o.<br />
                      Mariánská 3233<br />
                      470 01 Česká Lípa<br />
                      IČO: 27282805
                    </Col>
                    <Col>
                      <strong>Příjemce služby:</strong><br />
                      {customer?.name !== '' ? <span>{customer?.name}</span> : ''}
                      {customer?.name !== '' ? <br /> : ''}
                      {customer?.person !== '' ? <span>{customer?.person}</span> : ''}
                      {customer?.person !== '' ? <br /> : ''}
                      {customer?.billingAddress?.street}&nbsp;{customer?.billingAddress?.houseNumber}
                      {customer?.billingAddress?.identificationNumber === null || customer?.billingAddress?.identificationNumber === '' ? '':'/'}
                      {customer?.billingAddress?.identificationNumber}
                      <br />
                      {customer?.billingAddress?.postalCode}&nbsp;{customer?.billingAddress?.city}
                      <br />
                      IČO:&nbsp;{customer?.companyRegistrationNumber}
                    </Col>
                  </Row>
                  <br />
                  Ježek software (dále jen <strong>Poskytovatel</strong>) s vámi (dále jen <strong>Příjemci</strong>) uzavírá tuto smlouvu, jejímž předmětem je dohoda o zpracování osobních údajů a jejich ochraně.<br /><br />
                  <ol>
                    <li>Příjemce služby prohlašuje, že je buď <strong>správce dat</strong>, nebo má od primárního správce dat <strong>uděleno zmocnění</strong> k uzavření smlouvy s poskytovatelem a účel této smlouvy je v souladu se všemi uzavřenými ujednáními. Příjemce služby tak zodpovídá za to, že informace, které nemá právo zpřístupnit, nebudou poskytovali zpřístupněny.</li>
                    <li><strong>Osobní data</strong>, která Příjemce služby eviduje v programu STEREO nebo DUEL nebo jiným způsobem, budou poskytovateli zpřístupněna za účelem konzultací správnosti zpracování Příjemcem služby, dohledání a případné opravy chybného zadání nebo identifikace chybových stavů programu nebo za jiným účelem. Data mohou být poskytovateli zpřístupněna prostřednictvím aplikace pro <strong>vzdálený přístup</strong> poskytnutím záložní kopie nebo jiným způsobem.</li>
                    <li>Poskytovatel se bude <strong>řídit obecným nařízením EU2016/679 (GDPR)</strong>, zejména článkem 28 a následujícími. Poskytovatel se zavazuje data zpracovávat s ohledem na jejich maximální zabezpečení a nahlásit Příjemci služby všechny případné bezpečnostní incidenty, které při zpracování zaznamená. Za bezpečné zpřístupnění dat odpovídá Příjemce služby. Po poskytnutí služby poskytovatel neprodleně smaže případné záložní kopie i všechna z nich obnovená či jinak poskytnutá data. Poskytovatel není oprávněn zpřístupnit data třetím osobám.</li>
                    <li>Tato smlouva se považuje za uzavřenou potvrzením tlačítka Podepsat smlouvu a uzavírá se na dobu určitou do 28. února {moment(c).format('YYYY')} s výpovědní lhůtou 1 měsíc.</li>
                  </ol>
                  

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Typ smlouvy</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isAdministrator">
                    <Form.Row>
                      
                      <Col md="1" style={{marginTop: '5px'}}>
                        <FormControl 
                          type="checkbox"
                          name="isAdministrator"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isAdministrator}
                        
                          
                          isValid={touched.isAdministrator && !errors.isAdministrator} 
                          isInvalid={!!errors.isAdministrator}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isAdministrator}
                        </Form.Control.Feedback>
                      </Col>
                      <FormLabel column>Správce dat (zpracovávaná data jsou vlastní)</FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isPrimaryProcessor">
                    <Form.Row>
                      
                      <Col md="1" style={{marginTop: '5px'}}>
                        <FormControl 
                          type="checkbox"
                          name="isPrimaryProcessor"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isPrimaryProcessor}
                          
                          isValid={touched.isPrimaryProcessor && !errors.isPrimaryProcessor} 
                          isInvalid={!!errors.isPrimaryProcessor}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isPrimaryProcessor}
                        </Form.Control.Feedback>
                      </Col>
                      <FormLabel column>Prvotní zpracovatel (např. účetní kancelář)</FormLabel>
                    </Form.Row>  
                  </FormGroup>
                     
                             
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Podepisuji smlouvu...' : 'Podepsat smlouvu'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
          
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersGDPRUpdate))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getModuleOrder, getUrl } from '../../../Utils/Common'
// eslint-disable-next-line
//import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Modal, Dropdown, Card, Spinner } from 'react-bootstrap'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios'

import moment from 'moment'

interface LicensesTransfersDetailProps {
  id: string,
  showAs: string,
}

const LicensesTransfersDetail: React.FC<LicensesTransfersDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  let transfer = rootStore.getLicensesTransferById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showLic, setShowLic] = useState(false)
  
  //const [showLic, setShowLic] = useState(false)
  const [license, setLicense] = useState(
    {
      count : null,
      program : 
        {
          name : null,
          code : null
        },
      programVersion :
        {
          version : null
        },
      licenseType :
        {
          code : null
        },
      modules :
        [
          {
              count: null,
              moduleId: null,
              module: {
                  id: null,
                  name: null,
                  code: null,
                  programId: null
              }
          }
          
      ]    

    }
    )

  let url = 'licenses/'+transfer?.sourceLicenseId+'/detail'
  let toDate:string = transfer?.transferDate!
  let include:boolean = true
  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootStore.fetchTemplateById(props.id)
    //template = rootStore.getTemplateById(props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShowLic(false)
    setShow(true)

    const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout') 
      }  

    await axios.get(getUrl('URL_CHANGES')+url,{
      headers: {
        'Authorization': 'Bearer ' + aToken
      },params: {
        toDate,include
      }  }).then(response => {
        //console.log(response.data.detail)
        //setBefore(JSON.stringify(response.data.detail))
        
          setLicense(response.data.detail)
        
        
        //console.log(JSON.stringify(beforeA))
        //setBefore(response.data.detail)
        //beforeTxt = response.data.detail
        
      //return response.data
      
      
    }).catch(error => {
      
    });

    setShowLic(true)

    })()
  }
   
  return (
    <>
      {rights && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Detail</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Detail</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail převodu</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{wordWrap: 'break-word'}}>
          <strong>Datum a čas:</strong> {moment(transfer?.transferDate).format("D.M.YYYY H:mm")}<br />
          <strong>Původní zákazník:</strong> {transfer?.sourceCustomerAccountNumber} / {transfer?.sourceCustomerBusinessName}<br />
          <strong>Cílový zákazník:</strong> {transfer?.targetCustomerAccountNumber} / {transfer?.targetCustomerBusinessName}<br />
          <strong>Program:</strong> {transfer?.sourceProgram}&nbsp;{transfer?.sourceProgramVersion}<br />
          <strong>Provedl:</strong> {transfer?.firstName} {transfer?.surName}<br />
          
          <br />
    {showLic &&
    <Card 
      className={license.program?.code === 'D' ? 'border-left-danger':'border-left-info'}
    >
      <Card.Header as="h4">
          <img className="noprint" alt="logo programu" src={"images/ico-header-"+license.program.code+".png"} style={{marginBottom: '5px', marginRight: '15px' }} />
          {license.program?.name} {license.programVersion.version}
        </Card.Header>
      <Card.Body >
      <div className="float-left">
        (Typ licence: {license.licenseType.code}, Počet PC: {license.count})
        <br /><br />
        <strong>Moduly:</strong><br />
        <table>
          <tbody>
          {license.modules.sort(function(a:any, b:any) {
                var nameA = getModuleOrder(a.module.code); 
                var nameB = getModuleOrder(b.module.code); 
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              }).slice().map(modules => (<tr key={modules.module.id}><td className="licenseModuleName">{modules.module.name}</td><td>{modules.count}</td></tr>))}   
          </tbody>
        </table>     
      </div>
           
      <div className="cleaner divide"></div>
      </Card.Body>

    </Card>}
    
    {!showLic && <><Spinner animation='border'  />&nbsp;<br /></>}



          
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(LicensesTransfersDetail))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal, Table } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useDialsStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import SelectOptionsDeliveryCzechPostProducts from '../../../../../sharedComponents/filterPanel/components/SelectOptions.DeliveryCzechPostProducts'

interface DialsDeliveryCzechPostTemplatesUpdateProps {
  id: string,
  showAs: string,
}

const DialsDeliveryCzechPostTemplateUpdate: React.FC<DialsDeliveryCzechPostTemplatesUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  let item = rootDialsStore.getDialsDeliveryCzechPostTemplateById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    item = rootDialsStore.getDialsDeliveryCzechPostTemplateById(props.id)

    rootDialsStore.dialsDeliveryCzechPostServicesAll.map(service => {
      if (item?.services?.includes(service.id)) { service.setChecked(true) } else { service.setChecked(false) }
      return ( <></> )
    })

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (noteData:string, nameData:string, technologicalNumberData:string, weightData:number, productIdData:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_DIALS_DELIVERY_CZECH_POST_TEMPLATES')+'/'+props.id, 
          { id: props.id, 
            name: nameData, 
            note: noteData, 
            technologicalNumber: technologicalNumberData,
            productId: productIdData,
            weight: weightData,
            services: rootDialsStore.dialsDeliveryCzechPostServicesAll.filter(items => items.checked === true).map(item => item.id)  
          }, 
          { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootDialsStore.fetchDialsDeliveryCzechPostTemplates( Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')))
          //rootStore.fetchTemplates(sessionStorage.getItem('templatesSearchValue')!, sessionStorage.getItem('templatesSearchTypeValue')!, sessionStorage.getItem('templatesSearchIsActiveValue')! === '0' ? false : true, sessionStorage.getItem('templatesSearchWithSystemValue')! === '0' ? false : true, 10,  Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage'))) 

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    name: Yup.string()
    .required('Název šablony musí být vyplněn!'),
    
  })   
  return (
    <>
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava šablony zásilky pro Českou poštu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Šablona zásilky byla úspěšně upravena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                name: item?.name,
                note: item?.note,
                weight: item?.weight,
                productId : item?.productId,
                technologicalNumber: item?.technologicalNumber,
                changes:''
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.note!, values.name!, values.technologicalNumber!, values.weight!, values.productId!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name!}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="technologicalNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Identifikátor podavatele</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="technologicalNumber"
                          value={values.technologicalNumber!}
                          onChange={handleChange}
                          isValid={touched.technologicalNumber && !errors.technologicalNumber} 
                          isInvalid={!!errors.technologicalNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.technologicalNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="weight">
                    <Form.Row>
                      <FormLabel column lg={3}>Váha ($,$$$)</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="weight"
                          value={values.weight!}
                          onChange={handleChange}
                          isValid={touched.weight && !errors.weight} 
                          isInvalid={!!errors.weight}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.weight}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="productId">
                    <Form.Row>
                      <FormLabel column lg={3}>Produkt</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="productId" 
                          onChange={handleChange}
                          value={values.productId!}
                          isInvalid={!!errors.productId}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            <SelectOptionsDeliveryCzechPostProducts />
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.productId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <h5>Služby</h5>

                  <Table hover>
                    <tbody>
                      {rootDialsStore.dialsDeliveryCzechPostServicesAll.map(item => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={item.checked}
                              className=""
                              id={'rowcheck'+item.id}
                              onChange={() => {item.setChecked(!item.checked); setFieldValue("changes","")}}
                              style={{height: '18px', width: '18px'}}
                            />
                          </td>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(DialsDeliveryCzechPostTemplateUpdate))

import React, { useState } from 'react'
import Loader from "react-loader"


import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import { Container, Form, Col, Button, Card, Badge, Table, Row, Modal } from 'react-bootstrap'

import FormGroup from 'react-bootstrap/FormGroup';


import { Formik } from 'formik'
import * as Yup from 'yup'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'

//import MUIDataTable from "mui-datatables"

import Pagination from 'react-js-pagination'


function History(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [showD, setShowD] = useState(false)
  const [error, setError] = useState(null)


  // Připojení store
  const rootStore = useStore()
  
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    //setLoadedSide(false)
    window.scrollTo(0, 0)
    await checkVersion()

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else { 
      //if (localStorage.getItem('openedCustomerId') !== null) {  
        //await handleClose(rootStore.customerOpened[0].isActive) //!!! vyřešit.... isactive je undefined - předřadit načtení?
        //setLoadedSide(true)
      //}
      //else { setLoadedSide(true) }
      
      await rootStore.fetchCustomerList(sessionStorage.getItem('customersSearchValue')!, sessionStorage.getItem('customersSearchTypeValue')!,Number(sessionStorage.getItem('customersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('customersSearchPage')))  
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          //if (localStorage.getItem('openedCustomerId') !== null) {
            //await rootStore.fetchCustomerOpened()
          //}
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { 
          await rootStore.fetchCustomerOpened() 
        }
        
      }  
      
    }
    

    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  //Počet záznamů
  const countData:number = Object.keys(rootStore.customerList).length

  // Historie do karet
  const historyListDataCard = 
    <div>
    <Card>
      <Card.Body>
          <div className="float-right">
            <Button variant="primary" size="sm" onClick={() => { handleOpen("idcko") } } >Detail</Button>
          </div>
          12.02.2021 10:15<br />
          <i>(Adam Aul)</i><br />
          <strong>Dodací adresa</strong><br />
          <Badge variant="info">Změna</Badge><br />
          
      </Card.Body>
    </Card>
    <br />
    </div>

  // Historie do řádků tabulky
  const historyListDataTable = 
    <tr>
      <td>12.02.2021 10:15</td>
      <td>Adam Aul</td>
      <td><strong>Dadací adresa</strong></td>
      <td></td>
      <td><Badge variant="info">Změna</Badge></td>
      <td><Button variant="primary" size="sm" onClick={() => { handleOpen("idcko") } } >Detail</Button></td>
    </tr>



  // Funkce po otevření firmy
  const handleOpen = async (id:string) => {
    setShowD(true)


  }
  
  // Funkce po otevření firmy
  const handleClose = () => {
    setShowD(false)


  }

  const handlePageChange = (pageNumber:number) => {
    (async () => { 
      setLoaded(false);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout') 
      }
      sessionStorage.setItem('historySearchPage', String(pageNumber))
      // Předat hledanou hodnotu action pro načtení zákazníků
      //await rootStore.fetchCustomerList(sessionStorage.getItem('historySearchTypeValue')!, sessionStorage.getItem('customersSearchOperationTypeValue')!, pageNumber)
      setLoaded(true);
      // Hodnoty hledání uložit do sessionStorage
      
    })()
  }

  // Pro BE hledání
  const handleSearch = (historySearchTypeData:string, historySearchOperationTypeData:string) => {
    setError(null)
    setLoading(true);
    (async () => { 
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Předat hledanou hodnotu action pro načtení zákazníků
      //await rootStore.fetchCustomerList(searchData, searchTypeData, 1)
      setLoading(false);
      // Hodnoty hledání uložit do sessionStorage
      sessionStorage.setItem('historySearchTypeValue', historySearchTypeData)
      sessionStorage.setItem('historySearchOperationTypeValue', historySearchOperationTypeData)
      sessionStorage.setItem('historySearchPage', '1')
    })()
  }

  // Schéma validace formuláře
  const schema = Yup.object().shape({
      
  })  
  
  return (
    <div className="App">

        <TopMenu />
        <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
        <div id="layoutSidenav">
        
          <SideMenu />
          
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            
              <Container fluid>
                <br />
                
                <div>
                  <h4>Historie změn</h4>
                  <br />
                  <Row sm={1} md={2}>
                    <Col
                      md={{order:1}}
                      sm={{order:2}}
                      xs={{order:2}}
                      className="col-open-customer"
                    >

                      <Container>
                      
                      
                      <Formik
                        validationSchema={schema}
                        initialValues={{ historySearchType: sessionStorage.getItem('customersSearchTypeValue') !== null ? sessionStorage.getItem('historySearchTypeValue')! : 'all', historySearchOperationType: sessionStorage.getItem('customersSearchOperationTypeValue') !== null ? sessionStorage.getItem('historySearchOperationTypeValue')! : 'all' }}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleSearch(values.historySearchType, values.historySearchOperationType)              
                            setSubmitting(false);
                          }, 1);
                        }}
                      >
                        {({ 
                          handleSubmit,
                          handleChange,
                          handleReset,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting }) => (
                          <Form onSubmit={handleSubmit}>
                              
                              <FormGroup controlId="formHistorySearchType">
                              <Form.Row>
                                <Form.Control as="select" 
                                  name="historySearchType"
                                  defaultValue={sessionStorage.getItem('historySearchTypeValue')! !== null ? sessionStorage.getItem('historySearchTypeValue')! : 'all'} 
                                  onChange={handleChange}>
                                    <option value="all">Všechny oblasti</option>
                                    <option value="ba">Fakturační adresa</option>
                                    <option value="da">Dodací adresa</option>
                                    <option value="c">Kontakty</option>
                                    <option value="l">Licence</option>
                                </Form.Control>
                                </Form.Row>
                              </FormGroup>
                              <FormGroup controlId="formHistorySearchOperationType">
                              <Form.Row>
                                <Form.Control as="select" 
                                  name="historySearchOperationType"
                                  defaultValue={sessionStorage.getItem('historySearchOperationTypeValue')! !== null ? sessionStorage.getItem('historySearchOperationTypeValue')! : 'all'} 
                                  onChange={handleChange}>
                                    <option value="all">Všechny typy operací</option>
                                    <option value="ch">Změna</option>
                                    <option value="del">Mazání</option>
                                    <option value="ins">Pořízení</option>
                                </Form.Control>
                                </Form.Row>
                              </FormGroup>          
                              <FormGroup controlId="formHistorySearchText">
                                <Form.Row>  
                                  <Button type="submit" variant="success" disabled={!(isValid ) || loading} block>{loading ? 'Filtruji...' : 'Filtrovat'}</Button>  
                                </Form.Row> 
                              </FormGroup>                      
                            {error}
                            <br />
                            
                            
                          </Form>
                        )}
                      </Formik> 
                      </Container> 
                    </Col>
                    <Col 
                      md={{order:2}}
                      sm={{order:1}}
                      xs={{order:1}}
                      className="col-open-customer"
                    >
                      
                      
                      <br />                   
                    </Col>
                  </Row>
                </div>

                

                


                  <div className="showMobile">
                  {countData === 0 ? <div>Histoie změn neobsahuje žádné záznamy.<br /><br /></div> : ''}
                      {historyListDataCard}
                  </div>
                  <div className="showWeb">
                    <Table responsive hover>
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head">Datum a čas</th>
                          <th className="jsw-table-head">Uživatel</th>
                          <th className="jsw-table-head">Oblast</th>
                          <th className="jsw-table-head">Upřesnění</th>
                          <th className="jsw-table-head">Typ</th>
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyListDataTable}
                      </tbody>
                    </Table> 
                    {countData === 0 ? <div>Historie změn neobsahuje žádné záznamy.<br /><br /></div> : ''}
                  </div>  
                  <br />  
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={Number(sessionStorage.getItem('historySearchPage')) === 0 ? 1:Number(sessionStorage.getItem('historySearchPage')) }
                    itemsCountPerPage={10}
                    totalItemsCount={Number(localStorage.getItem('customersTotalCount'))}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(1)}
                    />
                
                {error}
              </Container>
              
            </main>
          </div>
        </div>
      </Loader>
      <Modal 
        show={showD} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail historie změn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5><strong>Dodací adresa</strong></h5><i>12.02.2021 10:22 (Adam Aul)</i><br /><br />
          <Row>
            <Col><strong>Původní stav</strong><br /><br />Ulice: Mariánska<br />Číslo popisné: 3333<br /></Col>
            <Col><strong>Nový stav</strong><br /><br />Ulice: Mariánská<br />Číslo popisné: 3233<br /></Col>
          </Row>
          <br />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default observer(History)
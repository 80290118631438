import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Row } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

const CustomersInformationUpdate: React.FC = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  
  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [loadingAres, setLoadingAres] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showARES, setShowARES] = useState(false)

  //ARES DATA
  const [ARESname, setARESname] = useState('')
  const [ARESulice, setARESulice] = useState('')
  const [AREScp, setAREScp] = useState('')
  const [ARESco, setARESco] = useState('')
  const [ARESpsc, setARESpsc] = useState('')
  const [ARESmisto, setARESmisto] = useState('')
  const [ARESdic, setARESdic] = useState('')
  const [ARESwarning, setARESwarning] = useState('')


  // Připojení store
  const rootStore = useStore()
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    setARESwarning('')
  }
  const handleCloseARES = () => {
    setShowARES(false)
    setError(null)
    //sessionStorage.removeItem('autoCloseModal')
  }

  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')        
    setShow(true) 
    setARESwarning('')
  }

  // Funkce pro změnu údajů
  const handleUpdate = (nameData:string, companyRegistrationNumberData:string, taxIdentificationNumberData:string, isTaxAbleData:boolean, titleData:string, firstNameData:string, surNameData:string, titleAfterData:string, streetData:string, houseNumberData:string, identificationNumberData:string, postalCodeData:string, cityData:string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      await axios.patch(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id, { id: customer?.id , name: nameData, title: titleData, firstName: firstNameData, surName: surNameData, titleAfter: titleAfterData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          // Změna dat ve Store
          customer?.setInformations(nameData,taxIdentificationNumberData,isTaxAbleData, titleData, firstNameData, surNameData, titleAfterData, companyRegistrationNumberData)
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

      // Zavolání API jen pro velkého ježka
      rightsBH && await axios.post(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id+'/identifications', { customerId: customer?.id , companyRegistrationNumber: companyRegistrationNumberData, taxIdentificationNumber: taxIdentificationNumberData, isTaxAble: isTaxAbleData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        
        // Změna dat ve Store
        customer?.setInformations(nameData,taxIdentificationNumberData,isTaxAbleData, titleData, firstNameData, surNameData, titleAfterData, companyRegistrationNumberData)
          
        // Nastavovačky, co se má v případě, že je to OK, zobrazit
        setLoading(false)
        setShowForm('nodisplay')
        setShowInfo(true)
        sessionStorage.setItem('autoCloseModal','1');

        // Po 2800 ms celé modální okno zavřeme
        setTimeout(() => {
          if (sessionStorage.getItem('autoCloseModal') === '1') {  
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }
        }, 2800);

      }).catch(error => {
        setLoading(false)
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
      });

      // Zavolání API
      await axios.patch(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id+'/address', { addressType: 'billing', street: streetData, houseNumber: houseNumberData, identificationNumber: identificationNumberData, postalCode: postalCodeData, city: cityData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        
        // Změna dat ve Store
        customer?.setBillingAddress(streetData,houseNumberData,identificationNumberData,postalCodeData,cityData)
          
        // Nastavovačky, co se má v případě, že je to OK, zobrazit
        setLoading(false)
        setShowForm('nodisplay')
        setShowInfo(true)
        sessionStorage.setItem('autoCloseModal','1');

        // Po 2800 ms celé modální okno zavřeme
        setTimeout(() => {
          if (sessionStorage.getItem('autoCloseModal') === '1') {  
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }
        }, 2800);

      }).catch(error => {
        setLoading(false)
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
      });

    })()

  }
  

 const schemaUpdate = Yup.object().shape({

  name: Yup.string().when(['surName'], {
    is: (surName) => {
      return !(surName)
    },
    then: Yup.string().required('Pokud není vyplněno příjmení, musíte zadat obchodní název.')
  }),
  street: Yup.string()
  .required('Ulice musí být vyplněna!'),
  houseNumber: Yup.string()
  .required('Číslo popisné musí být vyplněno!'),
  postalCode: Yup.string()
  .required('PSČ musí být vyplněno!'),
  city: Yup.string()
  .required('Město musí být vyplněno!'),
    /*
  surNAme: Yup.string().when(['name'], {
    is: (name) => {
      return !(name)
    },
    then: Yup.string().required()
  })
  */
})


  return (
    <span className="button-box">
      <span className={rights && customer?.isActive ? '' : 'nodisplay'}>
        <Button variant="primary" size="sm" onClick={handleShow}>
          Upravit
        </Button>
      </span>
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava fakturačních údajů</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Fakturační údaje byly úspěšně upraveny!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                name: customer?.name, 
                companyRegistrationNumber: customer?.companyRegistrationNumber !== null ? customer?.companyRegistrationNumber : '', 
                taxIdentificationNumber: customer?.taxIdentificationNumber !== null ? customer?.taxIdentificationNumber : '',
                isTaxAble: customer?.isTaxAble,
                title: customer?.title !== null ? customer?.title : '',
                firstName: customer?.firstName !== null ? customer?.firstName : '',
                surName: customer?.surName !== null ? customer?.surName : '',
                titleAfter: customer?.titleAfter !== null ? customer?.titleAfter : '',
                street: customer?.billingAddress.street !== null ? customer?.billingAddress.street : '', 
                houseNumber: customer?.billingAddress.houseNumber !== null ? customer?.billingAddress.houseNumber : '', 
                identificationNumber: customer?.billingAddress.identificationNumber !== null ? customer?.billingAddress.identificationNumber : '',
                postalCode: customer?.billingAddress.postalCode !== null ? customer?.billingAddress.postalCode : '',
                city: customer?.billingAddress.city !== null ? customer?.billingAddress.city : '' 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.name!, values.companyRegistrationNumber!, values.taxIdentificationNumber!, values.isTaxAble!, values.title!, values.firstName!, values.surName!, values.titleAfter!, values.street!, values.houseNumber!, values.identificationNumber!, values.postalCode!, values.city!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Základní údaje</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>                  
                  <Form.Row>
                    <FormGroup as={Col} controlId="name">
                      <FormLabel >Název</FormLabel>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name!}
                          onChange={handleChange}
                          disabled={rightsBH ? false:true}
                          
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                        />
                        {rightsBH === false && <span><i className="fas fa-info-circle"></i>&nbsp;<i>Změna názvu není dovolena. Pro změnu kontaktuje, prosím, naše obchodní oddělení!</i></span>}
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="title">
                      <FormLabel >Titul</FormLabel>
                        <FormControl 
                          type="text"
                          name="title"
                          value={values.title!}
                          onChange={handleChange}
                          disabled={rightsBH ? false:true}
                          
                          isValid={touched.title && !errors.title} 
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} sm={4}  controlId="name">
                      <FormLabel>Jméno</FormLabel>
                        <FormControl 
                          type="text"
                          name="firstName"
                          value={values.firstName!}
                          onChange={handleChange}
                          disabled={rightsBH ? false:true}
                          
                          isValid={touched.firstName && !errors.firstName} 
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                        </FormGroup> 
                        <FormGroup as={Col} sm={4} md={4}  controlId="surName">
                        <FormLabel>Příjmení</FormLabel>
                        <FormControl 
                          type="text"
                          name="surName"
                          value={values.surName!}
                          onChange={handleChange}
                          disabled={rightsBH ? false:true}
                          
                          isValid={touched.surName && !errors.surName} 
                          isInvalid={!!errors.surName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.surName}
                        </Form.Control.Feedback>
                    </FormGroup> 
                    <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="titleAfter">
                      <FormLabel >Titul za</FormLabel>
                        <FormControl 
                          type="text"
                          name="titleAfter"
                          value={values.titleAfter!}
                          onChange={handleChange}
                          disabled={rightsBH ? false:true}
                          
                          isValid={touched.titleAfter && !errors.titleAfter} 
                          isInvalid={!!errors.titleAfter}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.titleAfter}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>
                  {rightsBH === false && <span><i className="fas fa-info-circle"></i>&nbsp;<i>Změna jména není dovolena. Pro změnu kontaktuje, prosím, naše obchodní oddělení!</i></span>}
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Fakturační adresa</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>

                  <Form.Row>
                    <FormGroup as={Col}  controlId="street">
                      <FormLabel>Ulice</FormLabel>
                      <FormControl 
                        type="text"
                        name="street"
                        value={values.street!}
                        onChange={handleChange}
                        
                        isValid={touched.street && !errors.street} 
                        isInvalid={!!errors.street}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} lg={2} controlId="houseNumber">
                      <FormLabel>Číslo popisné</FormLabel>
                        <FormControl 
                          type="text"
                          name="houseNumber"
                          value={values.houseNumber!}
                          onChange={handleChange}
                          
                          isValid={touched.houseNumber && !errors.houseNumber} 
                          isInvalid={!!errors.houseNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.houseNumber}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} lg={2} controlId="identificationNumber">
                        <FormLabel>Číslo orientační</FormLabel>
                        <FormControl 
                          type="text"
                          name="identificationNumber"
                          value={values.identificationNumber!}
                          onChange={handleChange}
                            
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.identificationNumber}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>

                  <Form.Row>  
                    <FormGroup as={Col} lg={3} controlId="postalCode">
                      <FormLabel>PSČ</FormLabel>
                      <FormControl 
                        type="text"
                        name="postalCode"
                        value={values.postalCode!}
                        onChange={handleChange}
                        isValid={touched.postalCode && !errors.postalCode} 
                        isInvalid={!!errors.postalCode}
                      />
                        
                      <Form.Control.Feedback type="invalid">
                        {errors.postalCode}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} controlId="city">
                      <FormLabel>Město</FormLabel>
                      <FormControl 
                        type="text"
                        name="city"
                        value={values.city!}
                        onChange={handleChange}
                        
                        isValid={touched.city && !errors.city} 
                        isInvalid={!!errors.city}
                      />
                      
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </FormGroup> 
                  </Form.Row>   
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Identifikační údaje</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="companyRegistrationNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>IČO</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="companyRegistrationNumber"
                          value={values.companyRegistrationNumber!}
                          onChange={handleChange}
                          disabled={rightsBH ? false:true}
                          
                          isValid={touched.companyRegistrationNumber && !errors.companyRegistrationNumber} 
                          isInvalid={!!errors.companyRegistrationNumber}
                        />
                        <i className="fas fa-info-circle"></i>&nbsp;<i>Změna IČ není dovolena. Pokud chcete převést licenci na jinou firmu, kontaktuje, prosím, naše obchodní oddělení!</i>
                        {rightsBH && <span><br /><Button 
                                        size="sm" 
                                        variant="primary" 
                                        onClick={async() => {

                                          setLoadingAres(true)
                                          await axios.get('https://www.jezeksw.cz/aresTemp.php?ico='+values.companyRegistrationNumber!, 
                                          ).then(response => {
                                            console.log(response.data)
                                            if (response.data.vysledek! === 'ok') {
                                              
                                              
                                              
                                              setARESname(response.data.firma)
                                              setARESulice(response.data.ulice)
                                              setAREScp(response.data.cp)
                                              setARESco(response.data.co)
                                              setARESpsc(response.data.psc)
                                              setARESmisto(response.data.mesto)
                                              setARESdic(response.data.dic)
                                              setShowARES(true)
                                              //console.log(response.data.firma)
                                              /*
                                              setFieldValue('name',response.data.firma, true)
                                              setFieldValue('title','', true)
                                              setFieldValue('firstName','', true)
                                              setFieldValue('surName','', true)
                                              setFieldValue('titleAfter','', true)
                                              setFieldValue('street',response.data.ulice, true)
                                              setFieldValue('houseNumber',response.data.cp, true)
                                              setFieldValue('identificationNumber',response.data.co, true)
                                              setFieldValue('postalCode',response.data.psc, true)
                                              setFieldValue('city',response.data.mesto, true)
                                              setFieldValue('taxIdentificationNumber',response.data.dic, true)    
                                              */                                        
                                            }
                                            setLoadingAres(false)
                                            setARESwarning('')
                                  
                                          }).catch(error => {
                                            setLoadingAres(false)
                                            //console.log(error)
                                            setARESwarning(error.response.data.vysledek)
                                          });

                                        }}
                                      >
                                        {loadingAres ? 'Načítám data z ARESu...':'Načíst data z ARESu'}
                                      </Button>{ARESwarning !== '' && <span style={{color: 'red'}}><br />{ARESwarning}</span>}</span>}
                        <Form.Control.Feedback type="invalid">
                          {errors.companyRegistrationNumber}
                        </Form.Control.Feedback>
                        
                        <Modal 
                          show={showARES} 
                          onHide={handleCloseARES} 
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          style={{backgroundColor: 'rgba(200, 58, 58, 0.4)'}}
                          centered>
                          <Modal.Header closeButton >
                            <Modal.Title>Načtení údajů z ARESu</Modal.Title>
                          </Modal.Header>
                          <Modal.Body >
                            <Row>
                              <Col>
                                <strong>Evidované údaje</strong><br />
                                Název: {customer?.name}<br />
                                Jméno: {customer?.title}{values.title !== '' &&' '}{values.firstName}{values.firstName !== '' &&' '}{values.surName}{values.surName !== '' &&' '}{values.titleAfter}<br />
                                Ulice: {customer?.billingAddress.street}<br />
                                Č.p. : {customer?.billingAddress.houseNumber}<br />
                                Č.o. : {customer?.billingAddress.identificationNumber}<br />
                                PSČ  : {customer?.billingAddress.postalCode}<br />
                                Město: {customer?.billingAddress.city}<br />
                                <br />
                                DIČ  : {customer?.taxIdentificationNumber} 
                              </Col>
                              <Col>
                                <strong>Načtené údaje</strong><br />
                                Název: {customer?.name !== ARESname ? <span style={{color: 'red'}}>{ARESname}</span>:<span>{ARESname}</span>}<br />
                                Jméno: {customer?.surName !== '' ? <span style={{color: 'red'}}>!!!</span>:<span></span>}<br />
                                Ulice: {customer?.billingAddress.street !== ARESulice ? <span style={{color: 'red'}}>{ARESulice}</span>:<span>{ARESulice}</span>}<br />
                                Č.p. : {customer?.billingAddress.houseNumber !== AREScp ? <span style={{color: 'red'}}>{AREScp}</span>:<span>{AREScp}</span>}<br />
                                Č.o. : {customer?.billingAddress.identificationNumber !== ARESco ? <span style={{color: 'red'}}>{ARESco}</span>:<span>{ARESco}</span>}<br />
                                PSČ  : {customer?.billingAddress.postalCode !== ARESpsc ? <span style={{color: 'red'}}>{ARESpsc}</span>:<span>{ARESpsc}</span>}<br />
                                Město: {customer?.billingAddress.city !== ARESmisto ? <span style={{color: 'red'}}>{ARESmisto}</span>:<span>{ARESmisto}</span>}<br />
                                <br />
                                DIČ  : {customer?.taxIdentificationNumber !== ARESdic ? <span style={{color: 'red'}}>{ARESdic}</span>:<span>{ARESdic}</span>}<br />                                         
                              </Col>
                            </Row>
                            <br /><br />
                            <Button block variant="success" 
                              onClick={async() => {

                                    setFieldValue('name',ARESname, false)
                                    setFieldValue('title','', false)
                                    setFieldValue('firstName','', false)
                                    setFieldValue('surName','', false)
                                    setFieldValue('titleAfter','', false)
                                    setFieldValue('street',ARESulice, false)
                                    setFieldValue('houseNumber',AREScp, false)
                                    setFieldValue('identificationNumber',ARESco, false)
                                    setFieldValue('postalCode',ARESpsc, false)
                                    setFieldValue('city',ARESmisto, false)
                                    setFieldValue('taxIdentificationNumber',ARESdic, false)    
                                    
                                    setARESname('')
                                    setARESulice('')
                                    setAREScp('')
                                    setARESco('')
                                    setARESpsc('')
                                    setARESmisto('')
                                    setARESdic('')

                                    handleCloseARES()

                                    

                                }}>Nahradit údaje</Button>

                          </Modal.Body>
                        </Modal>

                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="taxIdentificationNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>DIČ</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="taxIdentificationNumber"
                          value={values.taxIdentificationNumber!}
                          onChange={handleChange}
                          disabled={rightsBH ? false:true}
                          
                          isValid={touched.taxIdentificationNumber && !errors.taxIdentificationNumber} 
                          isInvalid={!!errors.taxIdentificationNumber}
                        />
                        {rightsBH === false && <span><i className="fas fa-info-circle"></i>&nbsp;<i>Změna DIČ není dovolena. Pro změnu kontaktuje, prosím, naše obchodní oddělení!</i></span>}
                        <Form.Control.Feedback type="invalid">
                          {errors.taxIdentificationNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isTaxAble">
                    <Form.Row>
                      <FormLabel column lg={3}>Plátce DPH</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isTaxAble"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isTaxAble}
                          disabled={rightsBH ? false:true}
                          isValid={touched.isTaxAble && !errors.isTaxAble} 
                          isInvalid={!!errors.isTaxAble}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isTaxAble}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersInformationUpdate))

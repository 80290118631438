import React, {useState}  from 'react'


import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Form, Card, Modal, Alert, FormGroup, Col, Row } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
//import FormGroup from 'react-bootstrap/FormGroup'
//import FormLabel from 'react-bootstrap/FormLabel'


import { useStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, getModuleOrder, getUrl } from '../../../Utils/Common'

import { confirm } from "../../../Utils/Confirmation"

import { Formik } from 'formik'
import * as Yup from 'yup'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

//import { authFetch } from '../../../authProvider'


function LicensesTransfer(props:any) {
  
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
const [errorTL, setErrorTL] = useState(null)


  const [loading, setLoading] = useState(false)
  const [loadingNew, setLoadingNew] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [sendMail, setSendMail] = useState(false)
  const [sendMailOK, setSendMailOK] = useState(true)

  const [customerDeepLoading, setCustomerDeepLoading] = useState(false)

   // Otevírání a zavírání modálního okna Přidání nové pozvánky
   const handleClose = () => { 
    setShow(false)
    setShowForm('nodisplay')
    setError(null)
    setErrorTL(null)
    sessionStorage.removeItem('autoCloseModal')
    rootStore.deleteLicencesTransfer()
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    rootStore.deleteLicencesTransfer()


    setError(null)
    setErrorTL(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  } 

    // Načtení Store
    const rootStore = useStore()

  // Deklarace a nastavení stavů pro seznamy ala google
  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })
  
  const [stateNew, setStateNew] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })

  const customerListAllF = rootStore.customerListAll

  const [customers, setCustomers] = useState(customerListAllF.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  
  const [customersNew, setCustomersNew] = useState(customerListAllF.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  

  

  // Filtrování zákazníků v seznamu
  const filterCustomers = (value:string) => {
    //setCustomers(rootStore.getCustomerListAllByFilter(value))
    if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessName?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

      (async () => {
        setCustomerDeepLoading(true)
        await rootStore.fetchCustomerListAllNoCache(value,'',1,true)
        //setCustomers(rootStore.getCustomerListAllByFilter(value))
        console.log(localStorage.getItem('customerQuickSearchValue'))
        setCustomers(rootStore.getCustomerListAllByFilterWithoutICO(localStorage.getItem('customerQuickSearchValue')!))
        setCustomerDeepLoading(false)
      })();
    }
    else {
      setCustomers(rootStore.getCustomerListAllByFilterWithoutICO(value))
    }
  }

  // Filtrování zákazníků v seznamu
  const filterCustomersNew = (value:string) => {
    //setCustomersNew(rootStore.getCustomerListAllByFilter(value))
    if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessName?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

      (async () => {
        setCustomerDeepLoading(true)
        await rootStore.fetchCustomerListAllNoCache(value,'',1,true)
        //setCustomers(rootStore.getCustomerListAllByFilter(value))
        console.log(localStorage.getItem('customerQuickSearchValue'))
        setCustomersNew(rootStore.getCustomerListAllByFilterWithoutICO(localStorage.getItem('customerQuickSearchValue')!))
        setCustomerDeepLoading(false)
      })();
    }
    else {
      setCustomersNew(rootStore.getCustomerListAllByFilterWithoutICO(value))
    }
  }

  // Nastavení práv zda jsme Ježci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 



// O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomer =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (customers[activeItem]?.accountNumber !== undefined) {
        setFieldValue('licenseNumber',customers[activeItem].accountNumber)
        handleOld(customers[activeItem].accountNumber!)

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('licenseNumber','')
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customers && activeItem === customers.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  

};

// O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomerNew =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = stateNew;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!stateNew.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setStateNew({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (customersNew[activeItem]?.accountNumber !== undefined) {
        setFieldValue('licenseNumber',customersNew[activeItem].accountNumber)
        handleNew(customersNew[activeItem].accountNumber!)

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setStateNew({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('licenseNumber','')
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setStateNew({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('custN'+String(activeItem-1)), document.getElementById('scrollcustomerN'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customersNew && activeItem === customersNew.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setStateNew({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('custN'+String(activeItem+1)), document.getElementById('scrollcustomerN'))
  }
  

};


  //Počet záznamů
  const countData:number = Object.keys(rootStore.licensesTransferOldCustomerLicenses).length

  const isLoadedOldCustomer:boolean = Object.keys(rootStore.licensesTransferOldCustomer).length > 0 ? true:false
  const isLoadedNewCustomer:boolean = Object.keys(rootStore.licensesTransferNewCustomer).length > 0 ? true:false

  //const licensesTransferOldCustomer = rootStore.licensesTransferOldCustomer[0]

  //console.log(countData)

  // Seznam přehledů do řádků karty
  const licenses = rootStore.licensesTransferOldCustomerLicenses.slice().map(licensesTransferOldCustomerLicenses => (
    
    <div key={licensesTransferOldCustomerLicenses.license.id}>
      <Card.Body >
      <div className="float-left">
        <h5><strong>{licensesTransferOldCustomerLicenses.license.program.name} {licensesTransferOldCustomerLicenses.license.programVersion.version}</strong></h5> (Typ licence: {licensesTransferOldCustomerLicenses.license.licenseType.code}, Počet PC: {licensesTransferOldCustomerLicenses.license.count})
        <br /><br />
        <strong>Moduly:</strong><br />
        <table><tbody>
        {licensesTransferOldCustomerLicenses.license.modules!.slice().sort(function(a:any, b:any) {
              var nameA = getModuleOrder(a.module.code); 
              var nameB = getModuleOrder(b.module.code); 
              //var nameA = (a.module.code); 
              //var nameB = (b.module.code); 
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            }).map(modules => (<tr key={modules.module.id}><td className="licenseModuleName">{modules.module.name}</td><td>{modules.count}</td></tr>))}   
        </tbody></table>     
      </div>
      <div className="float-right">
        <Button variant="danger" size="sm" disabled={isLoadedNewCustomer === false || isLoadedOldCustomer === false} onClick={() => { handleOnClickTransferLicense(rootStore.licensesTransferOldCustomer[0]?.id,rootStore.licensesTransferNewCustomer[0]?.id,licensesTransferOldCustomerLicenses.license.id!, licensesTransferOldCustomerLicenses.license.program.name+' '+licensesTransferOldCustomerLicenses.license.programVersion.version) } }>Převést</Button>
      </div>          
      <div className="cleaner divide"></div>
      </Card.Body>
      <hr />
    </div>
    
  ))




  
  const handleOld = (licenseNumber:string) => {
    (async () => {
      setLoading(true)  
      rootStore.deleteLicencesTransferOldCustomerLicenses()
      await rootStore.fetchLicensesTransferOldCustomer(licenseNumber)
      if ( Object.keys(rootStore.licensesTransferOldCustomer).length > 0 && rootStore.licensesTransferOldCustomer[0]?.id !== undefined) {
        await rootStore.fetchLicensesTransferOldCustomerLicenses(rootStore.licensesTransferOldCustomer[0]?.id)
      }
      else {
        alert('Nenalezeno!')
      }
      setLoading(false)
    })()
  }

  const handleNew = (licenseNumber:string) => {
    (async () => {
      setLoadingNew(true)  
      await rootStore.fetchLicensesTransferNewCustomer(licenseNumber)
      if (Object.keys(rootStore.licensesTransferNewCustomer).length === 0 && rootStore.licensesTransferOldCustomer[0]?.id === undefined) {alert('Nenalezeno!')}
      setLoadingNew(false) 
    })()
  }  

  // Dotaz před přenosem licence
  const handleOnClickTransferLicense = (oldCustomer:string, newCustomer:string, license:string, licenseText:string) => {
    (async () => {
      if (await confirm("Opravdu chcete licenci "+licenseText+" převést?",'Ano, převést', 'Ne', {title: 'Převod licence'})) {
        transferLicense(oldCustomer,newCustomer,license)
      } else {
      
      }  
    })()
  }

  // Funkce pro přenos licence
  const transferLicense = (oldCustomer:string, newCustomer:string, license:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      // Zavolání API   
      await axios.post(getUrl('URL_TRANSFER_LICENSE'), { sourceCustomerId: oldCustomer, targetCustomerId: newCustomer, sourceLicenseId: license }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          (async () => {
            if (sendMail === true) {

              await axios.post(getUrl('URL_TRANSFER_LICENSE')+'/'+response.data.id+'/email', { id: response.data.id,  sourceCustomerId: response.data.sourceCustomerId, targetCustomerId: response.data.targetCustomerId }, { headers: { 'Authorization': 'Bearer ' + aToken}}
              ).then(response => {
                

              }).catch(error => {
                setSendMailOK(false)
              });
              
              // Po přenosu vyčistit store
              rootStore.deleteLicencesTransfer()
              //rootStore.fetchLicensesTransfersList(sessionStorage.getItem('transfersSearchValue')!, sessionStorage.getItem('transfersSearchTypeValue')!,Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')))
              rootStore.fetchLicensesTransfersListNew(Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')))
              setShowInfo(true)
              setShowForm('nodisplay')


            }
            else {
              
              rootStore.deleteLicencesTransfer()
              //rootStore.fetchLicensesTransfersList(sessionStorage.getItem('transfersSearchValue')!, sessionStorage.getItem('transfersSearchTypeValue')!,Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')))
              rootStore.fetchLicensesTransfersListNew(Number(sessionStorage.getItem('transfersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersSearchPage')))
              setShowInfo(true)
              setShowForm('nodisplay')
            }




          })();   
          

        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setErrorTL(errorMessage)
          //setShowETL(true)
        });

      })()    
  }



  // Schéma validace formuláře
  const schemaOld = Yup.object().shape({
    licenseNumber: Yup.string()
      .required('Číslo zákazníka musí být vyplněno!')
      .min(5, 'Číslo zákazníka musí obsahovat 5 znaků!')
      .max(5, 'Číslo zákazníka musí obsahovat 5 znaků!')
  })   
  const schemaNew = Yup.object().shape({
    licenseNumber: Yup.string()
      .required('Číslo zákazníka musí být vyplněno!')
      .min(5, 'Číslo zákazníka musí obsahovat 5 znaků!')
      .max(5, 'Číslo zákazníka musí obsahovat 5 znaků!')
  })   
 


  return (
    <>
      <Button variant="success" size="sm" onClick={handleShow}>
      <i className="fas fa-plus"></i>  Zahájit nový převod licence
      </Button>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Převod licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Licence byla úspěšně převedena!{sendMailOK === false && " E-maily se však nepodařilo odeslat!"}​</strong>
          </Alert> 
          <div className={showForm} >
                <Card>
                  <Card.Header>Původní zákazník</Card.Header>
                  <Card.Body>
                  <Formik
                    validationSchema={schemaOld}
                    initialValues={{ licenseNumber: isLoadedOldCustomer && rootStore.licensesTransferOldCustomer[0]?.accountNumber !== null ? rootStore.licensesTransferOldCustomer[0]?.accountNumber : '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleOld(values.licenseNumber)              
                        setSubmitting(false);
                      }, 1);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup controlId="licenseNumber">
                          <Form.Row>
                        <Form.Label  column lg={1} md={2} sm={12} xs={12}>
                          Zákazník
                        </Form.Label>
                        <Col lg={2} md={2} sm={6} xs={6}>

                        <FormControl 
                          type="text"
                          name="licenseNumber"
                          
                          value={values.licenseNumber!}
                          onChange={(e) => {handleChange(e);  state.displayItems = true;  state.activeItem = 0; localStorage.setItem('customerQuickSearchValue', e.target.value);    
                            //setCustomers(customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(e.target.value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(e.target.value!.toLowerCase())))
                             filterCustomers(e.target.value) 
                            }}
                          //tabIndex={1}
                          autoComplete="off" 
                          autoFocus={true}
                          isValid={touched.licenseNumber && !errors.licenseNumber} 
                          isInvalid={!!errors.licenseNumber}
                          onKeyDown={(e:any) => handleKeyDownCustomer(e, setFieldValue, 1)}
                          onBlur={(e:any) => {setTimeout(() => {setState({activeItem: 0, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue}); },200 ) }}
                        />
                        {values.licenseNumber! !== '' && state.displayItems ?
                            <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customers.map((dp,index) => {
                                        return (
                                            <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={async (e:any) => {await setFieldValue("licenseNumber",dp.accountNumber); state.displayItems = false; handleOld(dp.accountNumber!)}}
                                                key = {dp.id}
                                                >
                                                {dp.accountNumber} - {dp.businessName}
                                            </li>
                                        )
                                    }).slice(0, 50)
                                }
                                </ul>
                            </div>
                            : null
                        }

                          <Form.Control.Feedback type="invalid">
                          {errors.licenseNumber}
                        </Form.Control.Feedback>
                          
                          </Col>
                          <Col>
                       
                        <Button type="submit" variant="primary" disabled={loading}>{loading ? 'Načítám...' : 'Načíst'}</Button> 
                        
                            </Col>
                        </Form.Row>
                        </FormGroup>
                        
                                         
                        
                        {error}
                        
                      </Form>
                    )}
                  </Formik>
                  
                  <strong>
                  {isLoadedOldCustomer && rootStore.licensesTransferOldCustomer[0]?.accountNumber !== undefined ? '#'+rootStore.licensesTransferOldCustomer[0]?.accountNumber+', ' : ''}
                  {isLoadedOldCustomer && rootStore.licensesTransferOldCustomer[0]?.accountNumber !== undefined && rootStore.licensesTransferOldCustomer[0]?.businessName}
                  </strong>
                  </Card.Body>
                </Card>
                <br />
                <Card>
                  <Card.Header>Nový zákazník</Card.Header>
                  <Card.Body>
                  <Formik
                    validationSchema={schemaNew}
                    initialValues={{ licenseNumber: isLoadedNewCustomer && rootStore.licensesTransferNewCustomer[0]?.accountNumber !== null ? rootStore.licensesTransferNewCustomer[0]?.accountNumber : '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleNew(values.licenseNumber)              
                        setSubmitting(false);
                      }, 1);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup controlId="licenseNumber">
                          <Form.Row>
                        <Form.Label  column lg={1}  md={2} sm={12} xs={12}>
                          Zákazník
                        </Form.Label>
                        <Col lg={2}  md={2} sm={6} xs={6}>

                        <FormControl 
                          type="text"
                          name="licenseNumber"
                          
                          value={values.licenseNumber!}
                          onChange={(e) => {handleChange(e);  stateNew.displayItems = true; localStorage.setItem('customerQuickSearchValue', e.target.value);    
                            //setCustomers(customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(e.target.value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(e.target.value!.toLowerCase())))
                             filterCustomersNew(e.target.value) 
                            }}
                          //tabIndex={1}
                          autoComplete="off" 
                          autoFocus={true}
                          isValid={touched.licenseNumber && !errors.licenseNumber} 
                          isInvalid={!!errors.licenseNumber}
                          onKeyDown={(e:any) => handleKeyDownCustomerNew(e, setFieldValue, 1)}
                          onBlur={(e:any) => {setTimeout(() => {setStateNew({activeItem: 0, filteredItems: stateNew.filteredItems, displayItems: false, inputValue: stateNew.inputValue}); },200 ) }}
                          
                        />
                        {values.licenseNumber! !== '' && stateNew.displayItems ?
                            <div id="scrollcustomerN" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customersNew.map((dp,index) => {
                                        return (
                                            <li id={'custN'+String(index)} className={`${stateNew.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={async (e:any) => {await setFieldValue("licenseNumber",dp.accountNumber); stateNew.displayItems = false; handleNew(dp.accountNumber!)}}
                                                key = {dp.id}
                                                >
                                                {dp.accountNumber} - {dp.businessName}
                                            </li>
                                        )
                                    }).slice(0, 50)
                                }
                                </ul>
                            </div>
                            : null
                        }

                          <Form.Control.Feedback type="invalid">
                          {errors.licenseNumber}
                        </Form.Control.Feedback>
                          
                          </Col>
                          <Col>
                       
                        <Button type="submit" variant="primary" disabled={loadingNew}>{loadingNew ? 'Načítám...' : 'Načíst'}</Button> 
                        
                            </Col>
                        </Form.Row>
                        </FormGroup>
                        
                        {error}
                      </Form>
                    )}
                  </Formik>
                  <strong>
                  {isLoadedNewCustomer && rootStore.licensesTransferNewCustomer[0]?.accountNumber !== undefined ? '#'+rootStore.licensesTransferNewCustomer[0]?.accountNumber+', ' : ''}
                  {isLoadedNewCustomer && rootStore.licensesTransferNewCustomer[0]?.businessName}
                  </strong>
                  </Card.Body>



                </Card>
                <br />
                <Card>
                  <Card.Header>Pokyny k převodu licence</Card.Header>
                  <Card.Body>
                  <Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isActive"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={() => setSendMail(!sendMail)}
                          checked={sendMail}
                          
                          
                        />
                        </Col>
                        <Col>Odeslat zákazníkům pokyny k převodu licence</Col>
                  </Row>
                  </Card.Body>



                </Card>
                <br />
                {errorTL}
                <Card>
                  <Card.Header>Dostupné licence</Card.Header>
                  
                    {countData === 0 ? <Card.Body>Nejsou dostupné žádné aktivní licence!</Card.Body> : ''}
                    
                  
                  {licenses}
                </Card>


                <br /><br />

                </div>
        </Modal.Body>

      </Modal>
      
    </> 
  );
}

export default observer(LicensesTransfer)

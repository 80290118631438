import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Badge, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation";

import SubmissionsUpdate from './Submissions.Update.part'
import SubmissionsDetail from './Submissions.Detail.part'

//import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'

import moment from 'moment'
import NumberFormat from 'react-number-format'

const SubmissionsList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')

    if (sessionStorage.getItem('submissionsSearchValue') !== '') {
      
    }

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete distribuci "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání distribuce'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

  const handleOnClickSetHandedOverItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu byla distribuce "+text+" předána dopravci?",'Ano, předána', 'Ne', {title: 'Předání distribuce dopravci'})) {
        setHandedOverItem(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  
  
  const items = rootStore.submissions.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{moment(new Date(item.distributionDate!)).format("DD.MM.YYYY HH:mm")}</td>
      <td><SubmissionsDetail submissionId={item.id} fromSubMenu={false} asLink={true} linkText={item.note!} /></td>
      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} fixedDecimalScale={true} value={item.count} /></td>
      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} fixedDecimalScale={true} value={item.returnCount} /></td>
      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.amount} /></td>
      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={3} fixedDecimalScale={true} value={item.weight} /> kg</td>
      <td>
        {item.isExported === true && <Badge variant="info">Exportováno</Badge>}
        {item.isSubmitted === true  && <>&nbsp;<Badge variant="success">Předáno dopravci</Badge></>}
      </td>
      {rights &&<td>
        <ButtonGroup>
          
          <SubmissionsDetail submissionId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
          {rightsBH &&<>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <Dropdown.Item onClick={async () => { await rootStore.downloadSubmissionFileFromApi(item?.id!, moment(new Date(item?.distributionDate!)).format('YYYY-MM-DD HH-mm')+' - export'); item.setExportedFlag(true);}}>Exportovat TXT pro Podání online</Dropdown.Item>
            {item.isSubmitted === false && <Dropdown.Item onClick={() => { handleOnClickSetHandedOverItem(item.id,moment(new Date(item.distributionDate!)).format("DD.MM.YYYY HH:mm"));  } }>Předat dopravci</Dropdown.Item>} 
            <SubmissionsUpdate id={item.id} showAs='drop' />
            <Dropdown.Divider />
            {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.distributionDate!)).format("DD.MM.YYYY HH:mm")) } }>Smazat</Dropdown.Item>} 
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{moment(new Date(item.distributionDate!)).format("DD.MM.YYYY HH:mm")}</strong><br />
          <strong>{item.note}</strong><br />
          <strong>Počet</strong>: {item.count}<br />
          <strong>Vráceno</strong>: {item.returnCount}<br />
          <strong>Dobírka</strong>: <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.amount} /><br />
          <strong>Váha</strong>: <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={3} fixedDecimalScale={true} value={item.weight} /> kg<br />
          {item.isExported === true && <Badge variant="info">Exportováno</Badge>}
          {item.isSubmitted === true  && <>&nbsp;<Badge variant="success">Předáno dopravci</Badge></>}
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rights && <>
          <ButtonGroup>
            <SubmissionsDetail submissionId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
            
            {rightsBH &&<>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                <Dropdown.Item onClick={async () => { await rootStore.downloadSubmissionFileFromApi(item?.id!, moment(new Date(item?.distributionDate!)).format('YYYY-MM-DD HH-mm')+' - export'); item.setExportedFlag(true);}}>Exportovat TXT</Dropdown.Item>
                {item.isSubmitted === false && <Dropdown.Item onClick={() => { handleOnClickSetHandedOverItem(item.id,moment(new Date(item.distributionDate!)).format("DD.MM.YYYY HH:mm"));  } }>Předat dopravci</Dropdown.Item>} 
                <SubmissionsUpdate id={item.id} showAs='drop' />
                
                <Dropdown.Divider />
                {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.distributionDate!)).format("DD.MM.YYYY HH:mm")) } }>Smazat</Dropdown.Item>} 

              </DropdownButton>  
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_SUBMISSIONS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchSubmissions(Number(sessionStorage.getItem('submissionsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionsSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

  const setHandedOverItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.post(getUrl('URL_SUBMISSIONS')+'/'+id+'/handed-over', {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchSubmissions(Number(sessionStorage.getItem('submissionsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionsSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('submissionsSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootStore.fetchSubmissions(pageNumber)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('submissionsOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('submissionsSearchPage', String(1))
        await rootStore.fetchSubmissions(1)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (distributionDateData:string, noteData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_SUBMISSIONS'), { distributionDate: new Date(distributionDateData).toISOString(), note: noteData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootStore.fetchSubmissions(Number(sessionStorage.getItem('submissionsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionsSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    distributionDate: Yup.string()
    .required('Datum distribuce musí být vyplněno!'),
    
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'submissions'} 
        filterSearchArray='submissionsSearchArray' 
        pageVariable='submissionsSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['IsSubmitted','IsExported','DistributionDate','Note','ReturnCount','Count','Weight','Amount','Packets.PacketCode']}  
        title="Distribuce zásilek"
        titleCount = {Number(localStorage.getItem('lastSubmissionsSearchTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rightsBH &&
          <span title="Přidat novou distribuci">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat distribuci
          </Button>
          </span>}
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam distribucí neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th title='Předpokládaný datum distribuce zásilek' className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('submissionsOrderBy') === 'DistributionDate' || localStorage.getItem('submissionsOrderBy') === null || localStorage.getItem('submissionsOrderBy') === undefined) ? 'DistributionDateDesc' : 'DistributionDate'  )}>
                  Datum distribuce
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('submissionsOrderBy') === 'DistributionDate' || localStorage.getItem('submissionsOrderBy') === null || localStorage.getItem('submissionsOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('submissionsOrderBy') === 'DistributionDateDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" title='Popis distribuce'>Popis</th>
                <th className="jsw-table-head" style={{textAlign: 'right', cursor: 'pointer'}} title="Celkový počet zásilek v distribuci" onClick={() =>  handleOrderChange((localStorage.getItem('submissionsOrderBy') !== 'Count' ) ? 'Count' : 'CountDesc'  )}>
                  Počet
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('submissionsOrderBy') === 'Count' ) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('submissionsOrderBy') === 'CountDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" style={{textAlign: 'right', cursor: 'pointer'}} title="Počet vrácených zásilek" onClick={() =>  handleOrderChange((localStorage.getItem('submissionsOrderBy') !== 'ReturnCount' ) ? 'ReturnCount' : 'ReturnCountDesc'  )}>
                  Vráceno
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('submissionsOrderBy') === 'ReturnCount' ) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('submissionsOrderBy') === 'ReturnCountDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" style={{textAlign: 'right', cursor: 'pointer'}} title="Celková hodnota všech dobírek"onClick={() =>  handleOrderChange((localStorage.getItem('submissionsOrderBy') !== 'Amount' ) ? 'Amount' : 'AmountDesc'  )}>
                  Dobírka
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('submissionsOrderBy') === 'Amount' ) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('submissionsOrderBy') === 'AmountDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" style={{textAlign: 'right', cursor: 'pointer'}} title="Celková hmotnost v kg" onClick={() =>  handleOrderChange((localStorage.getItem('submissionsOrderBy') !== 'Weight' ) ? 'Weight' : 'WeightDesc'  )}>
                  Hmotnost
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('submissionsOrderBy') === 'Weight' ) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('submissionsOrderBy') === 'WeightDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" title='Stav, ve kterém se distribuce momentálně nachází'>Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('submissionsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionsSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastSubmissionsSearchTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání distribuce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Distribuce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Distribuce byla úspěšně přidána!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              initialValues={{ 
                distributionDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"), 
                note: '', 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.distributionDate!, values.note!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="distributionDate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="distributionDate"
                          value={values.distributionDate}
                          onChange={handleChange}
                          isValid={touched.distributionDate && !errors.distributionDate} 
                          isInvalid={!!errors.distributionDate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.distributionDate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Popis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                                     
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám distribuci...' : 'Uložit distribuci'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(SubmissionsList))

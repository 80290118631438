import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import NumberFormat from 'react-number-format'

interface CustomersSendInvitesProps {
  id: string,
  mode: string,
  showAs: string,
  filterId: string,
  info: string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined|null,
  searchValue?:string|undefined|null,
  searchType?:string|undefined|null, 
  searchScenario?:string|undefined|null,
  count?: number|undefined
}

const CustomersSendInvites: React.FC<CustomersSendInvitesProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)


  // Připojení store
  const rootStore = useStore()

    // Otevírání a zavírání modálního okna Změny hesla
    const handleClose = () => {
      setShow(false)
      setError(null)
      sessionStorage.removeItem('autoCloseModal')
    }
    const handleShow = async () => {
      
      if (props.filtered === true) {
        await rootStore.fetchDatafiltersCustomersForFilteredActions(Number(props.filterId))
      }
  
      sessionStorage.removeItem('autoCloseModal')
      setError(null)
      setShowInfo(false)
      setShowForm('')
      setShow(true)
    }


  // Funkce pro odeslání e-mailu
  const handleSendInvites = (id:string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      const idsToSend = rootStore.datafiltersCustomersForFilteredActions.map(dfc => dfc.id )
      const idsToSendSelected = rootStore.datafiltersCustomers.filter(dfc => dfc.checked === true).map(dfc => dfc.id )

      // Zavolání API
      if (props.mode === "all") {
        await axios.post(getUrl('URL_BULK_INVITES'), { mode: props.mode, selectedIds: [] }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          //console.log(response)

          rootStore.fetchDatafiltersCustomers(Number(id), Number(sessionStorage.getItem('selectionDetailPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailPage')))  
          rootStore.fetchOverviewsCustomers() 

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API
      
      await axios.post(getUrl('URL_BULK_INVITES'), { mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', selectedIds: props.mode === 'all' ? props.filtered === true ? idsToSend : [] : props.multiselect === true ? idsToSendSelected : [props.id] }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          //console.log(response.data)

          rootStore.fetchDatafiltersCustomers(Number(id), Number(sessionStorage.getItem('selectionDetailPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailPage')))  
          rootStore.fetchOverviewsCustomers() 

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

        
    })()
  }

    // Schéma validace formuláře
    const schemaUpdate = Yup.object().shape({
      confirmation: Yup.boolean()
      .oneOf([true], 'Není zatrhnuto potvrzení!'),
    })  
 
  return (
    <>
    {rights &&<>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
          {props.mode === 'all' && 'Odeslat pozvánky'}
          {(props.mode === 'selected' && props.multiselect === true) && 'Odeslat pozvánky'}
          {(props.mode === 'selected' && props.multiselect !== true) && 'Odeslat pozvánku'}
        </Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>
          {props.mode === 'all' && 'Odeslat pozvánky'}
          {(props.mode === 'selected' && props.multiselect === true) && 'Odeslat pozvánky'}
          {(props.mode === 'selected' && props.multiselect !== true) && 'Odeslat pozvánku'}
        </Button>}
    </>}

    <Modal 
      show={show} 
      onHide={handleClose} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.mode === 'all' && <>Hromadné odeslání pozvánek (<NumberFormat displayType={'text'} thousandSeparator={' '} value={localStorage.getItem('lastDatafilterTotalCount')!} />)</>}
          {(props.mode === 'selected' && props.multiselect === true) && <>Odeslání pozvánek ({props.count})</>}
          {(props.mode === 'selected' && props.multiselect !== true) && 'Odeslání pozvánky'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(props.mode === 'selected' && props.multiselect !== true) &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
          {props.info}<br /><hr /><br />
        </div>}

        <Alert variant="success" show={showInfo}>
          <strong>Pozvánky byly úspěšně odeslány!​​</strong>
          
        </Alert>  
        <div className={showForm} >

          <Formik
            validationSchema={schemaUpdate}
            initialValues={{ 
              scenarioId: '',
              confirmation: false
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                handleSendInvites(props.filterId)              
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ 
              handleSubmit,
              handleChange,
              
              values,
              touched,
              isValid,
              errors,
              isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                
                
                <FormGroup controlId="information">
                  <Form.Row>
                    <FormLabel column ><strong>Potvrzení</strong></FormLabel>
                  </Form.Row>  
                </FormGroup>  
                <FormGroup controlId="confirmation">
                  <Form.Row>
                    
                    <Col md="1">
                      <FormControl 
                        type="checkbox"
                        name="confirmation"
                        size="sm"
                        className="checkbox-small"
                        style={{marginTop: '10px'}}
                        //value={values.taxAble!}
                        onChange={handleChange}
                        checked={values.confirmation}
                        
                        isValid={touched.confirmation && !errors.confirmation} 
                        isInvalid={!!errors.confirmation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmation}
                      </Form.Control.Feedback>
                    </Col>
                    <FormLabel column>Pro pokračování v odeslání je nezbytné potvrdit, že ho chcete provést.</FormLabel>
                  </Form.Row>  
                </FormGroup>
                {error}
                <br />
                <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám pozvánky...' : 'Odeslat pozvánky'}</Button>
                
              </Form>
            )}
          </Formik>  
        </div>
      </Modal.Body>

    </Modal>
    
    </>  
  )  
  
}

export default withRouter(observer(CustomersSendInvites))

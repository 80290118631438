import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import CustomersOtherActionsSendEmail from './Customers.OtherActions.SendEmail.part'
//import CustomersOtherActionsGenerateOffer from './Customers.OtherActions.GenerateOffer.part'

import CustomersOtherActionsEmailHistoryForCustomer from './Customers.OtherActions.EmailHistoryForCustomer.part'
import CustomersOtherActionsChangeState from './Customers.OtherActions.ChangeState.part'
import CustomersOtherActionsActivateLicense from './Customers.OtherActions.ActivateLicense.part'
import CustomersOtherActionsDeactivateLicense from './Customers.OtherActions.DeactivateLicense.part'
import CustomersOtherActionsHistory from './Customers.OtherActions.History.part'
import CustomersNamesUpdate from './Customers.OtherActions.NamesReplace.part'
import CustomersFilesDetail from '../../pages/customers/dashboard/components/Customers.Dashboard.Files.Modal.part'

import { DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap'


//import { useStore } from '../../../store'
import { observer } from 'mobx-react'

interface CustomersOtherActionsProps {
  id: string,
  isActive: boolean,
  accountNumber?: string
}

const CustomersOtherActions: React.FC<CustomersOtherActionsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rightsSH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
    
  return (
    <>
    {rightsSH === true && <DropdownButton as={ButtonGroup} id="dropdown-basic-button" title="••• " variant='outline-secondary' size="sm" className={rightsSH ? '' : 'nodisplay'} >
          {rightsBH === true && <CustomersOtherActionsSendEmail mode='selected' id={props.id} filterId={0} showAs='drop' fromCustomerDashboard={true} accountNumber={props.accountNumber} />}
          {rightsBH === true && <CustomersOtherActionsChangeState id={props.id} showAs='drop' />}
          {rightsBH === true && <Dropdown.Divider />}
          {rightsSH === true && <CustomersFilesDetail customerId={props.id} showAs='drop' />}
          {rightsSH === true && <CustomersOtherActionsEmailHistoryForCustomer showAs='drop' />}
          {rightsSH === true && <CustomersOtherActionsHistory label='' block={false} showAs='drop' title='' historyType='customer' historyParam={props.id} />}
          {rightsSH === true && <CustomersNamesUpdate label='Přejmenování osob' showAs='drop' customerId={props.id} />}
          <div className='nodisplay'>
          {rightsBH === true && props.isActive === true && <Dropdown.Divider />}
          {rightsBH === true && props.isActive === true && <CustomersOtherActionsActivateLicense id={props.id} />}
          {rightsBH === true && props.isActive === true && <CustomersOtherActionsDeactivateLicense id={props.id} />}
          </div>
        </DropdownButton>}
    </>
  )  
  
}

export default withRouter(observer(CustomersOtherActions))

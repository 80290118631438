import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import CustomersOtherActionsGenerateOffer from '../../../parts/customersOtherActions/Customers.OtherActions.GenerateOffer.part'
import CustomersOtherActionsPublishOffer from '../../../parts/customersOtherActions/Customers.OtherActions.PublishOffer.part'

import CustomersOtherActionsUpgradeFreeCreateLicenses from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeCreateLicenses.part'
import CustomersOtherActionsUpgradeFreePublishLicenses from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreePublishLicenses.part'
import CustomersOtherActionsUpgradeElectronicCreateProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicCreateProformas.part'
import CustomersOtherActionsUpgradeElectronicPublishProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPublishProformas.part'
//import CustomersOtherActionsUpgradeElectronicUnpublishProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishProformas.part'
import CustomersOtherActionsUpgradeElectronicPaidProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPaidProformas.part'
import CustomersOtherActionsUpgradeElectronicCreateFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicCreateFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicPublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicPublishFinalDocuments.part'
//import CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishFinalDocuments.part'
//import CustomersOtherActionsUpgradeFreeUnpublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeUnpublishFinalDocuments.part'

import CustomersOtherActionsUpgradeCashCreateFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashCreateFinalDocuments.part'
import CustomersOtherActionsUpgradeCashFinalize from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashFinalize.part'
import CustomersOtherActionsCashCreateSubmissions from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashCreateSubmissions.part'
import CustomersOtherActionsCashRepackingPacket from '../../../parts/customersOtherActions/Customers.OtherActions.RepackingPacket.part'

//import { useStore } from '../../../store'
import { observer } from 'mobx-react'

interface CustomersSingleActionsProps {
  id: string,
  recordId: string,
  recordId2: string,
  recordId3: string,
  infoText: string,
  packetId?: string|undefined|null
}

const CustomersSingleActions: React.FC<CustomersSingleActionsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
    
  return (
    <>    
    {rights &&<>
          {props.id === '6' &&<CustomersOtherActionsGenerateOffer mode='selected' id={props.recordId} showAs='button' info={props.infoText} filterId={props.id} />}
          {props.id === '7' &&<CustomersOtherActionsPublishOffer mode='selected' id={props.recordId} showAs='button' info={props.infoText} filterId={props.id} />}
          
          {(props.id === '1037' || props.id === '1040' || props.id === '1043') &&<CustomersOtherActionsUpgradeFreeCreateLicenses mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<CustomersOtherActionsUpgradeFreePublishLicenses mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          

          {(props.id === '1019' || props.id === '1025' || props.id === '1031') &&<CustomersOtherActionsUpgradeElectronicCreateProformas mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeElectronicPublishProformas mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          
          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicPaidProformas mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          {(props.id === '1022' || props.id === '1028' || props.id === '1034') &&<CustomersOtherActionsUpgradeElectronicCreateFinalDocuments mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeElectronicPublishFinalDocuments mode='selected' id={props.recordId} id2={props.recordId2} id3={props.recordId3} showAs='button' filterId={props.id} info={props.infoText} />}
          

          {(props.id === '1001' || props.id === '1007' || props.id === '1013') &&<CustomersOtherActionsUpgradeCashCreateFinalDocuments mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<CustomersOtherActionsCashCreateSubmissions mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} id2='' id3='' />}
          {(props.id === '1003' || props.id === '1009' || props.id === '1015') &&<CustomersOtherActionsUpgradeCashFinalize mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          {(props.id === '1004' || props.id === '1010' || props.id === '1016') &&<CustomersOtherActionsUpgradeCashFinalize mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} />}
          {(props.id === '1005' || props.id === '1011' || props.id === '1017') &&<CustomersOtherActionsCashRepackingPacket mode='selected' id={props.recordId} showAs='button' filterId={props.id} info={props.infoText} packetId={props.packetId}  />}
        </>}
    </>    
  )  
  
}

export default withRouter(observer(CustomersSingleActions))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

//import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
//import NumberFormat from 'react-number-format'

interface SubmissionPacketReturnProps {
  submissionId: string,
  packetId: string,
  showAs: string,
}

const SubmissionPacketReturn: React.FC<SubmissionPacketReturnProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()

  //let item = rootStore.getSubmissionById(props.id)
  const editedPacket = rootStore.submissions.find(s => s.id === props.submissionId)?.packets?.find(p => p.id === props.packetId)
  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (returnDateData:string, returnNoteData:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_PACKETS')+'/'+props.packetId+'/return', { id: props.packetId, note: returnNoteData, returnDate: new Date(returnDateData).toISOString() }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')

          axios.get(getUrl('URL_SUBMISSIONS')+'/'+props.submissionId,{
            headers: {
              'Authorization': 'Bearer ' + aToken
            }  }).then(response => {
  
              rootStore.submissions.find(s => s.id === props.submissionId)!.setValuesFromData(response.data)
            
          }).catch(error => {
            
          });

          
          
          rootStore.fetchPacketsForSubmission(props.submissionId, Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
          //rootStore.fetchTemplates(sessionStorage.getItem('templatesSearchValue')!, sessionStorage.getItem('templatesSearchTypeValue')!, sessionStorage.getItem('templatesSearchIsActiveValue')! === '0' ? false : true, sessionStorage.getItem('templatesSearchWithSystemValue')! === '0' ? false : true, 10,  Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage'))) 

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    returnDate: Yup.string()
    .required('Datum vrácení zásilky musí být vyplněn!'),
    
    
  })   
  return (
    <span >
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Vrácení zásilky</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Vrácení zásilky</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Vrácení zásilky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Zásilka byla úspěšně nastavena jako vrácená!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                returnDate: moment(editedPacket?.returnDate! !== null ? new Date( editedPacket?.returnDate! ) : new Date()).format("YYYY-MM-DDTHH:mm"), 
                returnNote: editedPacket?.returnNote,
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.returnDate!, values.returnNote!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="returnDate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum vrácení</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="returnDate"
                          value={values.returnDate!}
                          onChange={handleChange}
                          isValid={touched.returnDate && !errors.returnDate} 
                          isInvalid={!!errors.returnDate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.returnDate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="returnNote">
                    <Form.Row>
                      <FormLabel column lg={3}>Popis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="returnNote"
                          value={values.returnNote!}
                          onChange={handleChange}
                          isValid={touched.returnNote && !errors.returnNote} 
                          isInvalid={!!errors.returnNote}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.returnNote}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                                   
                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(SubmissionPacketReturn))

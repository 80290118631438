import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'
import CustomerHeader from '../../../parts/customerHeader/CustomerHeader.parts'

import UpgradeOfferListActual from './components/Upgrade.OfferList.Actual.part'
import UpgradeOfferListHistory from './components/Upgrade.OfferList.History.part'

import { Container, Button } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore, useUpgradeStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'

function UpgradeOfferList(props:any) {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')  ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
  if (!localStorage.getItem('openedCustomerId')) {
    props.history.push('/customers-open')
  }

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const rootUpgradeStore = useUpgradeStore()

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'upgrade-offers') 
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      //Načtení souvisejících číselníků podle práv
      //if (rightsBH) await rootStore.fetchTemplates() 
      if (rightsBH) await rootUpgradeStore.fetchUpgradeOffersScenariosAll()  
      
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()

          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { await rootStore.fetchCustomerOpened() }
      }   
      //Při každém otevření stránky načítáme objednávky            
      await rootUpgradeStore.fetchUpgradeOffersToCustomer(localStorage.getItem('openedCustomerId')!)
      await rootDialsStore.fetchDialsPriceListsServices()
      setLoaded(true) // Nastavení pro Loader, že je strana načtena
  
      await rootDialsStore.fetchDialsPrograms()
      rootStore.fetchUsageMode(localStorage.getItem('openedCustomerId')!,rootDialsStore.dialsPrograms[0].id,rootDialsStore.dialsPrograms[1].id)

      rootUpgradeStore.fetchUpgradeOffersListToCustomer(localStorage.getItem('openedCustomerId')!)
      rootDialsStore.fetchDialsLicenseTypesAll()
      rootDialsStore.fetchDialsModulesAll()
      rootDialsStore.fetchDialsPriceListsModulesAll()
      
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }     
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  // Funkce pro zobrazení nebo skrytí detailních cen
  const showHide = (id:string) => {
    let el=document.getElementById("detail"+id)?.style!; 
    el.display=(el.display === 'block')?'none':'block';

    let elBS=document.getElementById("buttonShow"+id)?.style!;
    elBS.display = (el.display === 'block')?'none':'block'

    let elBH=document.getElementById("buttonHide"+id)?.style!;
    elBH.display = (el.display === 'block')?'block':'none'
    
  }
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <CustomerHeader />
              <Container fluid>
                <UpgradeOfferListActual />
                <br /><br />

                <Button variant='secondary' style={{display: 'block'}} id={"buttonShowA"} onClick={() => {showHide("A")}}><i className="fas fa-angle-double-down"></i>&nbsp;Zobrazit archiv objednávek</Button>
                <Button variant='secondary' style={{display: 'none'}}  id={"buttonHideA"} onClick={() => {showHide("A")}}><i className="fas fa-angle-double-up"></i>&nbsp;Skrýt archiv objednávek</Button>
                
                <div id='detailA' style={{display: 'none'}}>
                
                {!rightsBH &&<UpgradeOfferListHistory />}
                {rightsBH &&<UpgradeOfferListActual isHistory={true} />}
                </div>
                <br /><br />
                
              </Container>

            </Loader>  
            </main>
          </div>
        </div>      
      
    </div> 
  );
}

export default observer(UpgradeOfferList)

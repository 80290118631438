import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'

interface OthersTemplateSendTestProps {
  id: string,
  showAs: string,
}

const OthersTemplateSendTest: React.FC<OthersTemplateSendTestProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('Emailing')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  let template = rootStore.getTemplateById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    await rootStore.fetchTemplateById(props.id)
    template = rootStore.getTemplateById(props.id)

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleSendTest = (values:any) => {
    setError(null);
    //setLoading(true);
    //console.log(JSON.stringify(values, null, 4));
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      let VariablesPrepare = values.variables.map((vr:any) => ({
        variables: vr.name+'~'+vr.value
      }))
      let variablesToSend = VariablesPrepare.map((v:any) => v.variables)

      await axios.post(getUrl('URL_TEMPLATES')+'/'+props.id+'/test-email', { templateId: props.id, emailAddresses: [values.email], variables: variablesToSend }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  const schemaSendTest = Yup.object().shape({
    email: Yup.string()
    .required('E-mail musí být vyplněn!')
    .email('Vyplňte platný e-mail!'),
    
  })   
  return (
    <span >
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Odeslat testovací e-mail</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Odeslat test</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Odeslat testovací e-mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Testovací e-mail byl úspěšně odeslán!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaSendTest}
              initialValues={{ 
                email: '', 
                variables: template?.variablesData,
                
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSendTest(values)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <h5 className="card-title">Proměnné</h5>
                  {Object.keys(values.variables!).length === 0 && 'Tato šablona neobsahuje žádné proměnné.'}
                  <FieldArray name="variables">
                        {() => (values.variables!.map((variable, i) => {
                            //const variableErrors = errors.variables?.length && errors.variables[i] || {};
                            //const variableTouched = touched.variables?.length && touched.variables[i] || {};
                            //console.log(variable)
                            return (
                                <div key={i} className="list-group list-group-flush">
                                  
                                    <div className="list-group-item">
                                    <div className="form-group">
                                        <div className="form-row">
                                                
                                                <label className="form-label col-form-label col-lg-3">{variable!.name}</label>
                                                <div className="col-lg-6">
                                                  <Field name={`variables.${i}.value`} type="text" className={'form-control'} />
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            );
                        }))}
                        </FieldArray>

                  {error}
                  <br />
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám test...' : 'Odeslat test'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(OthersTemplateSendTest))

import { types } from 'mobx-state-tree'

export const DatafiltersOffersNewForFilteredActions = types
  .model({
    offerId: types.optional(types.string, ''),
    documentId: types.optional(types.string, ''),
    taxDocumentId: types.optional(types.string, ''),
    customerId: types.optional(types.string, ''),
    licenseDocumentId: types.optional(types.string, ''),
    paymentDocumentId: types.optional(types.string, '')
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Card, Button, Modal, Alert, Form, Col, DropdownButton, ButtonGroup} from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { useStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'


import CustomersDashboardCategoriesUpdatePart from './Customers.Dashboard.Categories.Update.part'

const CustomersDashboardCategories: React.FC = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    if (rightsBH) rootDialsStore.fetchDialsCategories()
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }  



  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const categories = rootStore.customerOpenedCategories

  const categoriesIds = categories.map(ci => ( ci.id ))
  

  //rootDialsStore.fetchDialsCategories()
  const dialsCategories = rootDialsStore.dialsCategories
  
 // Seznam kategorií číselníku pro formulář
 const dialsCategoriesData = dialsCategories
 .filter(dc => categoriesIds.includes(dc.id) === false)
 .slice()
 .sort(function(a:any, b:any) {
    var nameA = a.name; 
    var nameB = b.name; 
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  })
.map(dialsCategories => (
  <option key={dialsCategories.id} value={dialsCategories.id}>{dialsCategories.name}</option>
 ))  


  // Funkce pro přidání kategorie
  const handleNewCategories = (idData:string) => {
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      const categoriesIdsList:any = categoriesIds.concat(idData)

      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_CATEGORIES')+'/'+customer?.id+'/categories/', { customerId: localStorage.getItem('openedCustomerId')!, customerCategoryIds: categoriesIdsList }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          rootStore.fetchCustomerOpenedCategories()

          // Po 2800 ms celé modální okno zavřeme

          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }
 
  // Schéma validace formuláře
  const schemaNewCategories = Yup.object().shape({
    id: Yup.string()
    .required('Musíte vybrat kategorii!'),
  })   
 
  return (
    <Card
      bg='light'
      text='dark'
    >
      <Card.Header  as="h5">Kategorie
        {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsHistory label='' block={false} showAs='drop' title='' historyType='categories' historyParam='' />
          </DropdownButton>
        </div>}
        </Card.Header>

      <Card.Body>
        {categories.map(categories =>
        <div key={categories.id}>
          
          <div className="float-right">
            <CustomersDashboardCategoriesUpdatePart id={categories.id} />
          </div>          
          <div className="float-left">
            {categories.name}
          </div>

          <div className="cleaner divide"></div>
        </div>  )}

        
        <br />

        {rightsBH && customer?.isActive &&
        <Button variant="success" size="sm" onClick={handleShow}>
          + Přidat kategorii
        </Button>
        }
        <Modal 
          show={show} 
          onHide={handleClose} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Přidání kategorie</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="success" show={showInfo}>
              <strong>Kategorie byla úspěšně přidána!​</strong>
            </Alert> 
            <div className={showForm} >
              <Formik
                validationSchema={schemaNewCategories}
                initialValues={{ id: '' }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    handleNewCategories(values.id)              
                    setSubmitting(false);
                  }, 1);
                }}
              >
                {({ 
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  isInitialValid,
                  errors,
                  isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    
                    <FormGroup controlId="id">
                      <Form.Row>
                        <FormLabel column lg={3} md={4}>Kategorie</FormLabel>
                        <Col md="6">
                          <Form.Control as="select" 
                            name="id" 
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.id}
                            
                            
                            >
                              <option value="">---Vyberte kategorii---</option>
                              {dialsCategoriesData}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.id}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>

                    {error}
                    <br />
                    <Button type="submit" variant="success" disabled={!(isValid) || values.id === '' || loading} block>{loading ? 'Přidávám kategorii...' : 'Přidat kategorii'}</Button>
                    
                  </Form>
                )}
              </Formik>  
            </div>
          </Modal.Body>

        </Modal>





      </Card.Body>
    </Card>

  )  
  
}

export default withRouter(observer(CustomersDashboardCategories))

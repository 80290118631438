import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, deadLineBadgeState, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import {ButtonGroup, Dropdown, DropdownButton, Modal, Card, Badge} from 'react-bootstrap'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation";

import SupportContactsDetail from './Support.Contacts.Detail.part'
import SupportContactsCustomerInfo from './Support.Contacts.CustomerInfo.part'
import SupportContactsSolveUpdate from './Support.Contacts.SolveUpdate.part'
import SupportContactsSolutionAddUpdate from './Support.Contacts.SolutionAddUpdate.part'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'

interface SupportContactsListCardProps {
  isCustomer?:boolean,
  customerId?:string,
  showLoader?:boolean    
}

const SupportContactsListCard: React.FC<SupportContactsListCardProps & RouteComponentProps> = (props:any) => {
  // Nastavení stavů pro zobrazení
  
  const [error, setError] = useState(null)

  const [showE, setShowE] = useState(false)

  // Načtení Store
  const rootStore = useStore()
  



  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false




  const customerListAllF = rootStore.customerListAll


  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteContact = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete kontakt "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání kontaktu'})) {
        deleteContact(id) 
      } else {
      
      }  
    })()
  }

  
  // Seřazení seznamu podle data pořízení
  const contacts = rootStore.supports.slice().sort(function(a:any, b:any) {
    var sort1A = new Date(a.lastEditDate); 
    var sort1B = new Date(b.lastEditDate); 

    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }
    return 0;
  })
  
 
  // Seznam pozvánek do karet
  const linesContactsCard = contacts.slice().map((contact) => (
    <div key={contact.id}>
    <Card>
      <Card.Body>
        {rights && <>
          
        <div className="float-right">
        <ButtonGroup>
        <SupportContactsDetail supportId={contact.id} isCustomer={props.isCustomer} />
        <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
        {contact.customerId !== null && props.isCustomer !== true && 
          <>
            <SupportContactsCustomerInfo customerId={customerListAllF.find(cla => cla.id === contact.customerId)?.id!} isActiveCustomer={customerListAllF.find(cla => cla.id === contact.customerId)?.isActive!} customerName={customerListAllF.find(cla => cla.id === contact.customerId)?.businessName} fromSubMenu={true}  /> 
            
          </>
          }   
          <SupportContactsSolutionAddUpdate supportId={contact?.id} showAs="drop" />
          <SupportContactsSolveUpdate supportId={contact?.id} showAs="drop" />
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => { handleOnClickDeleteContact(contact.id, contact.subject!) } } style={{color: 'red'}} >Smazat kontakt</Dropdown.Item>
        </DropdownButton>
      </ButtonGroup>
        </div>
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          {moment(contact.lastEditDate !== '' ? contact.lastEditDate : contact.createDate).format("DD.MM.YYYY HH:mm")}<br />
          <strong>{contact.accountNumber}</strong><br />
          <strong>{customerListAllF.find(cla => cla.id === contact.customerId)?.businessName}</strong><br />
          <strong>{contact.businessName}</strong><br />
          <strong>{contact.subject}</strong><br />
          <br />
          {rootStore.hedgehogUsers.find(hu => hu.id === contact.solverUserId)?.fullName}
          <br />
          <Badge variant='secondary' title='Počet řešení' style={{marginRight: '5px'}} >{contact.solutionCount}</Badge>
          {contact.isFinished === false && <Badge  style={{marginRight: '5px'}} variant={deadLineBadgeState(contact?.deadLineDate!.replace(' ', 'T'))} title={contact.deadLineDate !== '' ? new Date(contact?.deadLineDate!.replace(' ', 'T')) >= new Date() ? 'Nevyřešeno před termínem '+moment(contact.deadLineDate).format("DD.MM.YYYY HH:mm"):'Nevyřešeno po termínu '+moment(contact.deadLineDate).format("DD.MM.YYYY HH:mm"):'Nevyřešeno'}>Nevyřešeno</Badge>}
          {contact.isTrouble === true && <Badge variant='danger' style={{marginLeft: '10px'}}>Problém</Badge>}
          
          <br />
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteContact = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_SUPPORT_CONTACTS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchSupports(1,props.isCustomer)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }


  return (
    <>
      {linesContactsCard}


      <Modal 
        show={showE} 
        onHide={handleClose} 
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      
      
    </>
  )  
  
}

export default withRouter(observer(SupportContactsListCard))

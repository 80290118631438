import { types } from 'mobx-state-tree'

export const EmailingBlackList = types
  .model({
    id: types.optional(types.string, ''),
    email: types.optional(types.string,''),
    reason: types.optional(types.string,''),
    customerId: types.maybeNull(types.string),
    createDate: types.optional(types.string,''),
    source: types.optional(types.string,''),
    isVerified: types.optional(types.boolean,false),
  })
  .views(self => ({
    get sourceCzech() {
      let source = self.source
      if (self.source === '') source = 'není' 
      return source 
    }
  }))
  .actions(self => ({
    

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getUser } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

//import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
//import NumberFormat from 'react-number-format'

interface TasksUpdateProps {
  id: string,
  showAs: string,
}

const TasksUpdate: React.FC<TasksUpdateProps & RouteComponentProps>  = (props:any) => {
  
  // Připojení store
  const rootStore = useStore()
  
  // Načtení úkolu
  let item = rootStore.getTaskById(props.id)

  // Nastavení práv pro editaci
  const rightsAuthor:boolean = (item?.authorId === getUser().id) ? true : false
  const rightsResponsible:boolean = (item?.responsibleUserId === getUser().id) ? true : false


  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [isFromManagementMeeting, setIsFromManagementMeeting] = useState(false)


  // Seznam pracovníků JSW pro lookup
  const tasksHedgehogs = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === true)
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.surName; 
    var sort1B = b.surName; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(hu => (
    <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
  ))  
  
  
  // Otevírání a zavírání modálního okna pro editaci
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    
    item = rootStore.getTaskById(props.id)
    sessionStorage.removeItem('autoCloseModal')    

    // Pokud je úkol z porady, tak se zkontroluje, zda se jedná o poradu vedení. Pokud ano, není možné měnit příznak isPrivate na true.
    if (item?.meetingId !== null) {
      
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      await axios
        .get(getUrl('URL_MEETINGS')+'/'+item?.meetingId,{headers: {'Authorization': 'Bearer ' + aToken} })
        .then(response => {
          if (response.data.isManagement === true) {
            setIsFromManagementMeeting(true)
          }
        })
        .catch(error => {
        
        })

    }  

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    
  }

  // Funkce pro změnu adresy
  const handleUpdate = (deadlineData:string, textData:string, responsibleUserIdData:string, isPrivateData:boolean, isDoneData:boolean, isCompletedData:boolean) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_TASKS')+'/'+props.id, { id: props.id, deadline: new Date(deadlineData).toISOString(), text: textData, responsibleUserId: responsibleUserIdData, isPrivate: isPrivateData, isDone: isDoneData, isCompleted: isCompletedData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootStore.fetchTasks( Number(sessionStorage.getItem('tasksSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('tasksSearchPage')))
          rootStore.fetchTaskPoints(props.id)
          //rootStore.fetchTemplates(sessionStorage.getItem('templatesSearchValue')!, sessionStorage.getItem('templatesSearchTypeValue')!, sessionStorage.getItem('templatesSearchIsActiveValue')! === '0' ? false : true, sessionStorage.getItem('templatesSearchWithSystemValue')! === '0' ? false : true, 10,  Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage'))) 

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }
 

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    deadline: Yup.string()
    .required('Datum porady musí být vyplněno!'),
    responsibleUserId: Yup.string()
    .required('Osoba odpovídající za řešení úkolu musí být vyplněna!'),
    
  })   
  return (
    <span >
      {(rightsAuthor === true || rightsResponsible === true) && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava úkolu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Úkol byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                deadline: moment(new Date(item?.deadLine!)).format("YYYY-MM-DDTHH:mm"), 
                text: item?.text, 
                responsibleUserId: item?.responsibleUserId,
                isPrivate: item?.isPrivate,
                isDone: item?.isDone,
                isCompleted: item?.isCompleted,
                temp: '',
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.deadline!, values.text!, values.responsibleUserId!, values.isPrivate!, values.isDone!, values.isCompleted!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="deadline">
                    <Form.Row>
                      <FormLabel column lg={3}>Termín</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="deadline"
                          value={values.deadline}
                          onChange={handleChange}
                          isValid={touched.deadline && !errors.deadline} 
                          isInvalid={!!errors.deadline}
                          disabled={!rightsAuthor}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.deadline}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="text">
                    <Form.Row>
                      <FormLabel column lg={3}>Úkol</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="text"
                          value={values.text}
                          onChange={handleChange}
                          isValid={touched.text && !errors.text} 
                          isInvalid={!!errors.text}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="responsibleUserId">
                    <Form.Row>
                      <FormLabel column lg={3}>Odpovídá</FormLabel>
                      <Col>
                          <Form.Control as="select" 
                            name="responsibleUserId" 
                            //onChange={async(e) => {await handleChange(e); await controlAfterSelectType(e.target.value);  if (rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId !== '') { await setFieldValue("solutionSupportTypeId", rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId ) } }}
                            onChange={handleChange}
                            value={values.responsibleUserId!}
                            isInvalid={!!errors.responsibleUserId}
                            disabled={!rightsAuthor}
                            //tabIndex={4}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 3:7, handleSubmit)}
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              tasksHedgehogs
                              }
                          </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.responsibleUserId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isPrivate">
                    <Form.Row>
                      <FormLabel column lg={3}>Soukromý</FormLabel>
                      <Col>
                      <FormControl 
                          type="checkbox"
                          name="isPrivate"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isPrivate}
                          disabled={(!rightsAuthor || isFromManagementMeeting) ? true : false}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isPrivate && !errors.isPrivate} 
                          isInvalid={!!errors.isPrivate}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isPrivate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isDone">
                    <Form.Row>
                      <FormLabel column lg={3}>Splněno</FormLabel>
                      <Col>
                      <FormControl 
                          type="checkbox"
                          name="isDone"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isDone}
                          //disabled={getUser().id !== item?.responsibleUserId}
                          //disabled={!rightsAuthor}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isDone && !errors.isDone} 
                          isInvalid={!!errors.isDone}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isDone}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isCompleted">
                    <Form.Row>
                      <FormLabel column lg={3}>Uzavřeno</FormLabel>
                      <Col>
                      <FormControl 
                          type="checkbox"
                          name="isCompleted"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isCompleted}
                          disabled={!rightsAuthor}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isCompleted && !errors.isCompleted} 
                          isInvalid={!!errors.isCompleted}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isCompleted}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  

                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>

                  
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(TasksUpdate))

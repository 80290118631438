//import {URL_MAIN_API} from './UrlApi'
//export const URL_API = URL_MAIN_API

export const URL_API = sessionStorage.getItem('URL')

export const URL_DOKLADY_API = sessionStorage.getItem('DOKLADY_URL')
//export const URL_API = "https://e-upgrade-be-test.azurewebsites.net/v1/"
//export const URL_API = window.Appsettings.apiendpoint

/* ACCOUNTS */
export const URL_ACCOUNT = URL_API+"accounts/"
export const URL_LOGIN = URL_API+"accounts/login"
export const URL_REFRESH_TOKEN = URL_API+"accounts/refresh-token"
export const URL_LOGOUT = URL_API+"accounts/logout"
export const URL_FORGOTTEN_PASSWORD = URL_API+"accounts/forgotten-password"
export const URL_RESET_PASSWORD = URL_API+"accounts/reset-password"
export const URL_REGISTRATION = URL_API+"accounts/registration"
export const URL_CONFIRM_REGISTRATION = URL_API+"accounts/confirm-registration"
export const URL_CHANGE_PASSWORD = URL_API+"accounts/change-password"
export const URL_RESEND_CONFIRMATION_EMAIL = URL_API+"accounts/resend-confirmation-email"

/* INVITES */
export const URL_INVITES = URL_API+"invites"
export const URL_INVITES_EMAIL_FROM_TOKEN = URL_API+"accounts/email-from-invite/"

/* CUSTOMERS */
export const URL_CUSTOMER_LIST  = URL_API+"customers"
export const URL_CUSTOMER_OPEN  = URL_API+"customers/open/"
export const URL_CUSTOMER_CLOSE = URL_API+"customers/close/"
export const URL_CUSTOMER_CATEGORIES = URL_API+"customers/"
export const URL_CUSTOMER_USAGE_MODE = URL_API+"customers/"
export const URL_CUSTOMER_USERS = URL_API+"customers/"
export const URL_CUSTOMER_OFFER_COUNT = URL_API+"customers/"
export const URL_CUSTOMER_CHANGE_STATE = URL_API+"customers/"
export const URL_CUSTOMER_LICENSES = URL_API+"customers/"
export const URL_CUSTOMER_SERVICES = URL_API+"customers/"
export const URL_CUSTOMER_GDPR = URL_API+"customers/"
export const URL_CUSTOMER_UPGRADE_OFFERS = URL_API+"customers/"
export const URL_CUSTOMER_EMAILS_SENT = URL_API+"admin/emails/customers/"
export const URL_CUSTOMER_NOTES = URL_API+"customers/"

/* OVERVIEWS */
export const URL_OVERVIEWS_CUSTOMERS = URL_API+"overviews/customers"
export const URL_OVERVIEWS_OFFERS = URL_API+"overviews/offers"

/* DATAFILTERS */
export const URL_DATAFILTERS_CUSTOMERS = URL_API+"datafilters/customers/"
export const URL_DATAFILTERS_OFFERS = URL_API+"datafilters/offers/"

export const URL_DATAFILTERS_INVOICES = URL_API+"invoices/upgrade/"

/* TEMPLATES */
export const URL_TEMPLATES = URL_API+"templates"

/* OPERATIONS */
export const URL_OPERATIONS = URL_API+"operations"

/* BULK OPERATIONS */
export const URL_BULK_INVITES = URL_API+"bulkoperations/invites"
export const URL_BULK_REMINDERS = URL_API+"bulkoperations/reminders"
export const URL_BULK_EMAILS  = URL_API+"bulkoperations/emails"
export const URL_BULK_OFFERS  = URL_API+"bulkoperations/offers"

/* CUSTOMER LICENSES */
//export const URL_LICENSES_TRANSFERS_LIST = URL_API+"licenses/"
export const URL_LICENSES_STATE = URL_API+"licenses/"
export const URL_TRANSFER_CUSTOMER_LICENSES = URL_API+"licenses/customer/"
export const URL_TRANSFER_LICENSE = URL_API+"licenses/transfer"
export const URL_LICENSES_CHANGE = URL_API+"licenses/"
export const URL_LICENSES_ADD = URL_API+"licenses"

/* CUSTOMER SERVICES */
export const URL_SERVICES = URL_API+"services/customer/"

/* OFFERS */
export const URL_OFFERS = URL_API+"offers/"
export const URL_OFFERS_SCENARIOS = URL_API+"offers/scenarios"
export const URL_OFFERS_CALCULATE = URL_API+"offers/calculate"

/* DIALS */
export const URL_DIALS_CATEGORIES  = URL_API+"dials/categories"
export const URL_DIALS_PROGRAMS  = URL_API+"dials/programs"
export const URL_DIALS_PROGRAM_VERSIONS  = URL_API+"dials/program-versions"
export const URL_DIALS_LICENSE_TYPES  = URL_API+"dials/license-types"
export const URL_DIALS_SERVICES  = URL_API+"dials/services"
export const URL_DIALS_MODULES  = URL_API+"dials/modules"
export const URL_DIALS_PRICE_LISTS_MODULES  = URL_API+"dials/price-lists/modules/actual/"
export const URL_DIALS_PRICE_LISTS_SERVICES  = URL_API+"dials/price-lists/services"

export const URL_DIALS_SUPPORT_TYPES  = URL_API+"dials/support-type"
export const URL_DIALS_SUPPORT_THEMES  = URL_API+"dials/support-theme"
export const URL_DIALS_FREETEXTS  = URL_API+"freetexts"
export const URL_DIALS_OPERATIONS_PREFERENCES  = URL_API+"processes/preferences"

export const URL_DIALS_DELIVERY_CZECH_POST_SERVICES  = URL_API+"delivery/czech-post/services"
export const URL_DIALS_DELIVERY_CZECH_POST_PRODUCTS  = URL_API+"delivery/czech-post/products"
export const URL_DIALS_DELIVERY_CZECH_POST_TEMPLATES  = URL_API+"delivery/czech-post/packets/templates"


export const URL_SUBMISSIONS  = URL_API+"delivery/czech-post/submissions"
export const URL_PACKETS  = URL_API+"delivery/czech-post/packets"
//export const URL_DIALS_DELIVERY_CZECH_POST_SERVICES  = "https://api-upgrade.jezeksw.cz/v1/delivery/czech-post/service"

/* DOCUMENTS */
export const URL_DOCUMENTS  = URL_API+"customers/"
export const URL_DOCUMENTS_BH  = URL_API+"documents/"

/* SUPPORT */
export const URL_SUPPORT_CONTACTS_ADD = URL_API+"supports"
export const URL_SUPPORT_CONTACTS = URL_API+"supports"

/* FILES */
export const URL_FILES = URL_API+"files"



/* ADMIN */
export const URL_ADMIN_EMAILS_SENT = URL_API+"admin/emails/email"
export const URL_ADMIN_EMAILS = URL_API+"admin/emails"
export const URL_CHANGES = URL_API+"changes/"
export const URL_ADMIN_VERIFY_CODES_DUEL = URL_API+"admin/verifyCodes/duel/"
export const URL_ADMIN_VERIFY_CODES_STEREO = URL_API+"admin/verifyCodes/stereo/"
export const URL_ADMIN_VERIFY_CODES = URL_API+"admin/verifyCodes/"

/* CALCULATORS */


export const JEJDA = "Nastala chyba!"
export const LOGIN_JEJDA = "Nastala chyba!"

export const CLIENT_URL = "https://ts-sway.cz/files/apiUsers.php"
export const CLIENT_UPDATE_URL = "https://ts-sway.cz/files/apiUsersUpdate.php"
export const ORDER_URL = "https://ts-sway.cz/files/apiOrders.php"
export const ORDER_UPDATE_URL = "https://ts-sway.cz/files/apiOrdersUpdate.php"


import { types } from 'mobx-state-tree'

const BillingAddress = types.model('BillingAddress',{
  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string)
})

const DeliveryAddress = types.model('DeliveryAddress',{
  name: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  surName: types.maybeNull(types.string),
  titleAfter: types.maybeNull(types.string),
  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string)
})

export const LicensesTransferNewCustomer = types
  .model({
    id: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
    companyRegistrationNumber: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    surName: types.maybeNull(types.string),
    titleAfter: types.maybeNull(types.string),

    billingAddress: types.frozen(BillingAddress),
    deliveryAddress: types.maybeNull(DeliveryAddress),

    //count: types.optional(types.number, 0),
    //items: types.maybe(Items)
    //items: types.array(Items)
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Button, Dropdown, Col, Alert, Modal, Row, Badge } from 'react-bootstrap'


import { useEmailingStore, useStore } from '../../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'


interface DatafiltersCustomersCustomListDetailProps {
  id: string,
  showAs: string,
}

const DatafiltersCustomersCustomListDetail: React.FC<DatafiltersCustomersCustomListDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsEM:boolean = ((localStorage.getItem('Emailing')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()
  const rootStore = useStore()

  let item = rootEmailingStore.getDatafiltersCustomersCustomListById(props.id)

  // Nastavení stavů pro zobrazení 

  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    await rootEmailingStore.deleteEmailingConditions()
    item = await rootEmailingStore.getDatafiltersCustomersCustomListById(props.id)
    if (!(item!.data === undefined || item!.data === null || item!.data === '')) await rootEmailingStore.fetchEmailingConditionsFromData(JSON.parse(item!.data))
    sessionStorage.removeItem('autoCloseModal')
    
    setShow(true)
    })()
  }
  

  const conditions = rootEmailingStore.emailingConditions
 
     
  return (
    <span >
      {rightsEM && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Detail</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Detail</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{item?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Vytvořil</strong><br />
          {rootStore.hedgehogUsers.find(hu => hu.id === item?.userId)?.fullName} ({item?.createdAt !== null && moment(item?.createdAt).format("DD.MM.YYYY HH:mm")})
          <br /><br />
          <strong>Poznámka</strong>
          <br />
          <p style={{whiteSpace: 'pre-line'}}>
            {item?.note}
          </p>
          <br /><br />
                  <Row>
                    
                    <Col>
                    { !(item?.data === undefined || item?.data === null || item?.data === '') ?
                      <Alert variant="info">
                        <strong>Podmínky</strong><br />
                        <br />
                        {conditions.map((item,index) => (
                          <div key={item.id}>
                            {index > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} >{item.operatorBefore}</Badge><br /></>}
                            {item.isSystem && <div style={{backgroundColor: '#deeafc', padding: '10px', borderRadius: '10px'}}>
                                <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                 {item.includedConditions?.map((itemInside,indexInside) => (
                                  <div key={itemInside.id}>
                                    {indexInside > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} >{itemInside.operatorBefore}</Badge><br /></>}

                                    {itemInside.isSystem && <div style={{backgroundColor: '#c0d7fa', padding: '10px', borderRadius: '10px'}}>
                                      <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                      {itemInside.includedConditions?.map((itemInside2,indexInside2) => (
                                        <div key={itemInside2.id}>
                                          {indexInside2 > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}}>{itemInside2.operatorBefore}</Badge><br /></>}

                                          {itemInside2.isSystem && <div style={{backgroundColor: '#adceff', padding: '10px', borderRadius: '10px'}}>
                                            <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                            {itemInside2.includedConditions?.map((itemInside3,indexInside3) => (
                                              <div key={itemInside3.id}>
                                                {indexInside3 > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} >{itemInside3.operatorBefore}</Badge><br /></>}

                                                {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside3.id )?.name}{itemInside3.props1 !== null &&<>&nbsp;({itemInside3.props1}{itemInside3.props2 !== null &&<>-{itemInside3.props2}</>})</>}
                                                
                                              </div>
                                            ))}
                                            <br />
                                            
                                            

                                            </div><strong>)</strong>
                                            
                                              
                                          </div>}


                                          {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside2.id )?.name}{itemInside2.props1 !== null &&<>&nbsp;({itemInside2.props1}{itemInside2.props2 !== null &&<>-{itemInside2.props2}</>})</>}
                                          
                                        </div>
                                      ))}
                                      <br />
                                      

                                      </div><strong>)</strong>
                                      
                                        
                                    </div>}

                                    {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside.id )?.name}{itemInside.props1 !== null &&<>&nbsp;({itemInside.props1}{itemInside.props2 !== null &&<>-{itemInside.props2}</>})</>}
                                    
                                  </div>
                                 ))}
                                <br />
                                

                                </div><strong>)</strong>
                                
                                  
                              </div>}
                            {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === item.id )?.name}{item.props1 !== null &&<>&nbsp;({item.props1}{item.props2 !== null &&<>-{item.props2}</>})</>}
                            
                            </div>
                        ))}

                        <br />
    
                        
                      </Alert>
                      :
                      <Alert variant="info">
                        <strong>Podmínky</strong><br />
                        <br />
                        <i>Tento filtr byl vytvořen mimo prostředí portálu, proto jeho podmínky nelze vizualizovat.</i>
                      </Alert>}  
                    </Col>
                  </Row>
  
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(DatafiltersCustomersCustomListDetail))

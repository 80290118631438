import { types } from 'mobx-state-tree'


export const DatafiltersCustomersForFilteredActions = types
  .model({
    id: types.optional(types.string, ''),
    
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

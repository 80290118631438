import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUser} from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { getUrl } from '../../../../Utils/Common'
//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

const AccountsMyAccountChangePassword: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }  

  // Funkce pro změnu hesla
  const handleChangePassword = (oldPasswordData:string, newPasswordData:string, confirmNewPasswordData:string) => {
    setError(null)
    setLoading(true)
    const user = getUser();
    // Zpracování požadavku
    (async () => {

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    // Zavolání API pro změnu
    await axios.post(getUrl('URL_CHANGE_PASSWORD'), { id: user.id , oldPassword: oldPasswordData, newPassword: newPasswordData, confirmNewPassword: confirmNewPasswordData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        // Nastavovačky, co se má v případě, že je to OK, zobrazit
        setLoading(false)
        setShowForm('nodisplay')
        setShowInfo(true)
        sessionStorage.setItem('autoCloseModal','1');

        // Po 2800 ms celé modální okno zavřeme
        setTimeout(() => {
          if (sessionStorage.getItem('autoCloseModal') === '1') {  
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }
        }, 2800);

      }).catch(error => {
        setLoading(false)
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
      });


    })()

  }

  // Schéma validace formuláře
  const schemaChangePassword = Yup.object().shape({
    oldPassword: Yup.string()
      .required('Staré heslo musí být vyplněno!'),

    newPassword: Yup.string()
      .required('Heslo musí být vyplněno!')
      .matches(
        /[A-Z]/,
        "Heslo musí obsahovat alespoň jedno velké písmeno."
      )
      .matches(
        /[a-z]/,
        "Heslo musí obsahovat alespoň jedno malé písmeno"
      )
      .matches(
        /[0-9]/,
        "Heslo musí obsahovat alespoň jednu číslici."
      )
      .matches(
        /[@$!%*#?&_+]/,
        "Heslo musí obsahovat alespoň jeden speciální znak (@, $, !, %, *, #, ?, &, _, +)."
      )
      .min(8, 'Heslo musí obsahovat mininálně 8 znaků.'),
      
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Hesla se neshodují!")
      .required('Potvrzení hesla musí být vyplněno!'),
  })   
  return (
    <span className="button-box">

      <Button variant="primary" size="sm" onClick={handleShow}>
        Změnit heslo
      </Button>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Změna hesla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Vaše heslo bylo úspěšně změněno!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaChangePassword}
              initialValues={{ oldPassword: '', newPassword: '', confirmNewPassword: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleChangePassword(values.oldPassword, values.newPassword, values.confirmNewPassword)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="formOldPassword">
                    <Form.Row>
                      <FormLabel column lg={4}>Staré heslo</FormLabel>
                      <Col>
                        <FormControl 
                          type="password"
                          name="oldPassword"
                          value={values.oldPassword}
                          onChange={handleChange}
                          
                          isValid={touched.oldPassword && !errors.oldPassword} 
                          isInvalid={!!errors.oldPassword}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.oldPassword}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="formNewPassword">
                    <Form.Row>
                      <FormLabel column lg={4}>Nové heslo</FormLabel>
                      <Col>
                        <FormControl 
                          type="password"
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          
                          isValid={touched.newPassword && !errors.newPassword} 
                          isInvalid={!!errors.newPassword}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.newPassword}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="formConfirmNewPassword">
                    <Form.Row>
                      <FormLabel column lg={4}>Ověření hesla</FormLabel>
                      <Col>
                        <FormControl 
                          type="password"
                          name="confirmNewPassword"
                          value={values.confirmNewPassword}
                          onChange={handleChange}
                          
                          isValid={touched.confirmNewPassword && !errors.confirmNewPassword}
                          isInvalid={!!errors.confirmNewPassword} 
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmNewPassword}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                 
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Měním heslo...' : 'Změnit heslo'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(AccountsMyAccountChangePassword))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Modal, Alert, Form, Col, Badge, Table, Card} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import NumberFormat from 'react-number-format'

import CustomersDashboardNotesUpdate from './Customers.Dashboard.Notes.Update.part'

import Pagination from 'react-js-pagination'

import moment from 'moment'

const CustomersDashboardNotes: React.FC = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBHSH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') ) ? true : false
  
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [page, setPage] = useState(1)

  const [filterType, setFilterType] = useState(!(localStorage.getItem('noteFilterType') === undefined || localStorage.getItem('noteFilterType') === null)  ? localStorage.getItem('noteFilterType') : '');


  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }  

  // Načtení Store
  const rootStore = useStore()

  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const customerNotes = rootStore.customerOpenedNotes.filter(data => {if (filterType === '') return data; else if (filterType === 'A') return data.isActive === true; else return data.isActive === false  })
  
  const countNotes = Object.keys(customerNotes).length
 

    // Funkce pro změnu strany
    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);

        setPage(pageNumber)

        
        
        
        //sessionStorage.setItem('supportSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        //await rootStore.fetchSupports(pageNumber,props.isCustomer)
        setLoading(false);  
      })()
    }

  
  // Funkce pro založení nového kontaktu
  // eslint-disable-next-line
  const handleNewNote = (dateData:string, noteData:string, isNotifyData:boolean, isActiveData:boolean) => {
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 
      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_NOTES')+customer?.id+'/notes', {  customerId: customer?.id!, note: noteData, isNotify: isNotifyData, isActive: isActiveData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          rootStore.fetchCustomerOpenedNotes(customer?.id!)

          // Po 2800 ms celé modální okno zavřeme

          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }


  const linesNotesCard = customerNotes.filter((cn,index) => index >= -5+(page*5) && index <= -1+(page*5)  ).map(notes => (
    <div key={notes.id}>
    <Card>
      <Card.Body style={!notes.isActive ? {} : notes.isNotify ? {fontWeight: 'bold'}: {}}>
        {rightsBHSH && <>
          {rightsBHSH &&<>  
            <div className="float-right">
              <CustomersDashboardNotesUpdate id={notes.id} /> 
            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          {moment(notes.date).format("DD.MM.YYYY")} (<span title={'Naposledy změnil: ' + rootStore.hedgehogUsers.find(hu => hu.id === notes.userId)?.fullName}>{rootStore.hedgehogUsers.find(hu => hu.id === notes.authorId)?.fullName}</span>)
          <br />
          {!notes.isActive && <><Badge variant="secondary">Neaktivní</Badge>&nbsp;&nbsp;</>}{notes.note}
          <br />
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   


  let d = new Date();
  let year:number = d.getFullYear();
  let month:number = d.getMonth();
  let monthTxt:string = month<9 ? '0':''
  let day:number = d.getDate();
  let dayTxt:string = day<10 ? '0':''
  let todayTxt:string = String(year) + '-' + monthTxt + String(month+1) + '-' + dayTxt + String(day)
 
  // Schéma validace formuláře
  const schemaNewNote = Yup.object().shape({
    note: Yup.string()
    .required('Poznámka musí být vyplněna!'),
    })
    
  // Schéma validace formuláře filtru
  const schemaUpdate = Yup.object().shape({
  
  }) 

  return (
    <>
      <h4>Poznámky (<NumberFormat displayType={'text'} thousandSeparator={' '} value={countNotes} />)</h4>
      <div className="float-right">
        
        <Formik
          validationSchema={schemaUpdate}
          initialValues={{ 
            noteType: !(localStorage.getItem('noteFilterType') === undefined || localStorage.getItem('noteFilterType') === null)  ? localStorage.getItem('noteFilterType') : 'A'
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              localStorage.setItem('noteFilterType', values.noteType!)
              setFilterType(values.noteType!)
              setSubmitting(false);
            }, 100);
          }}
        >
          {({ 
            handleSubmit,
            handleChange,
          
            values,
            touched,
            isValid,
            errors,
            isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                  <Col>
                  <Form.Control as="select" 
                      name="noteType" 
                      size='sm'
                      onChange={async (e:any) => { await handleChange(e); await handleSubmit(e); }}
                      value={values.noteType!}
                      >
                        <option value="">--- vše ---</option>
                        <option value="A">Aktivní</option>
                        <option value="N">Neaktivní</option>
                    </Form.Control>
                  </Col>
              </Form.Row>
            </Form>
          )}
        </Formik> 
      </div>  
      
      
   
      {rightsBHSH && customer?.isActive &&
        <Button variant="success" size="sm" onClick={handleShow}>
          + Přidat poznámku
        </Button>
      }
      <br /><br />
      {countNotes === 0 && <div>Seznam poznámek neobsahuje žádné záznamy.<br /><br /></div>}
      {countNotes !== 0 &&
      <> 
      <div className="showMobile">
          {linesNotesCard}<br />
      </div>

      <div className="showWeb">
      <Table hover>
        <thead>
          <tr className="jsw-table-head">
            <th className="jsw-table-head" style={{maxWidth: '150px'}}>Datum</th>
            <th className="jsw-table-head">Poznámka</th>
            <th className="jsw-table-head">Pořídil</th>
            <th className="jsw-table-head">Akce</th>
          </tr>
        </thead>
        <tbody>
          {customerNotes.filter((cn,index) => index >= -5+(page*5) && index <= -1+(page*5)  ).map(notes =>
          <tr key={notes.id} style={!notes.isActive ? {} : notes.isNotify ? {fontWeight: 'bold'}: {}}>

                  <td style={{width: '100px'}}>{moment(notes.date).format("DD.MM.YYYY")}</td>
                  <td>{!notes.isActive && <><Badge variant="secondary">Neaktivní</Badge>&nbsp;&nbsp;</>}{notes.note}</td>
                  <td style={{width: '190px'}} title={'Naposledy změnil: ' + rootStore.hedgehogUsers.find(hu => hu.id === notes.userId)?.fullName}>{rootStore.hedgehogUsers.find(hu => hu.id === notes.authorId)?.fullName}</td>
                  <td>
                    <div className="">
                      <CustomersDashboardNotesUpdate id={notes.id} />
                    </div>
                  </td>
          </tr>
          )}



        </tbody>
      </Table>
      </div>
      
      
      </>
      }


    

        
        <br />


        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={page}
          itemsCountPerPage={5}
          totalItemsCount={countNotes}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />

        
        
        <Modal 
          show={show} 
          onHide={handleClose} 
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Nová poznámka</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="success" show={showInfo}>
              <strong>Poznámka byla úspěšně přidána!​</strong>
            </Alert> 
            <div className={showForm} >
              <Formik
                validationSchema={schemaNewNote}
                initialValues={{ date: todayTxt, note: '', isNotify: false, isActive: true }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    handleNewNote(values.date, values.note, values.isNotify, values.isActive)              
                    setSubmitting(false);
                  }, 1);
                }}
              >
                {({ 
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>

                    <FormGroup controlId="date" className='nodisplay'>
                      <Form.Row>
                        <FormLabel column lg={3}>Datum</FormLabel>
                        <Col>
                          <FormControl 
                            type="date"
                            name="date"
                            value={values.date!}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date} 
                            isInvalid={!!errors.date}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>  

                    <FormGroup controlId="note">
                      <Form.Row>
                        <FormLabel column lg={3}>Poznámka</FormLabel>
                        <Col>
                          <FormControl 
                            type="text"
                            name="note"
                            value={values.note!}
                            onChange={handleChange}
                            isValid={touched.note && !errors.note} 
                            isInvalid={!!errors.note}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.note}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>                      
                    
                    <FormGroup controlId="isNotify">
                      <Form.Row>
                        <FormLabel column lg={3}>Upozornění</FormLabel>
                        <Col md="1">
                          <FormControl 
                            type="checkbox"
                            name="isNotify"
                            size="sm"
                            className="checkbox-small"
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.isNotify}
                            
                            isValid={touched.isNotify && !errors.isNotify} 
                            isInvalid={!!errors.isNotify}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.isNotify}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>
                    <FormGroup controlId="isActive">
                      <Form.Row>
                        <FormLabel column lg={3}>Aktivní</FormLabel>
                        <Col md="1">
                          <FormControl 
                            type="checkbox"
                            name="isActive"
                            size="sm"
                            className="checkbox-small"
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.isActive}
                            
                            isValid={touched.isActive && !errors.isActive} 
                            isInvalid={!!errors.isActive}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.isActive}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>

                    {error}
                    <br />
                    <Button type="submit" variant="success" disabled={loading} block>{loading ? 'Přidávám poznámku...' : 'Přidat poznámku'}</Button>
                    
                  </Form>
                )}
              </Formik>  
            </div>
          </Modal.Body>

        </Modal>






    </>
  )  
  
}

export default withRouter(observer(CustomersDashboardNotes))

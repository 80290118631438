import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'
// eslint-disable-next-line
import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Table, Card } from 'react-bootstrap'
// eslint-disable-next-line
import { useStore, useDialsStore, useAppStore } from '../../store'
import { observer } from 'mobx-react'

import FilterPanel from '../../sharedComponents/filterPanel/FilterPanel.part'
import Pagination from 'react-js-pagination'

import moment from 'moment'
import NumberFormat from 'react-number-format'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../Utils/Confirmation";

import AppProDuelReceiptEdit from './App.ProDuel.Receipt.Edit.part'
import AppProDuelReceiptDetail from './App.ProDuel.Receipt.Detail.part'

interface AppProDuelReceiptsProps {
  masterView:boolean,
  customerId:string,
  companyId:string,
  tokenId?:string,

  fromSubMenu?:boolean,
  asLink?:boolean,
  linkText?:string,
  
  fromExternal?:boolean,  
  setPageLoading?:any,
}

const AppProDuelReceipts: React.FC<AppProDuelReceiptsProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  const rightsEdit:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')) ? true : false

  // Nastavení stavů pro zobrazení  
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

  const [loadingReceipts, setLoadingReceipts] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  // eslint-disable-next-line
  const [refreshAfterClose, setRefreshAfterClose] = useState(false)

  type AppProDuelReceiptsSearchArray = {filterName: string, filterValue: string, displayValue: string}
  // eslint-disable-next-line
  let receiptsSearchArray = new Array<AppProDuelReceiptsSearchArray>()
    
  // Připojení store
  //const rootStore = useStore()
  const rootAppStore = useAppStore()
  //const rootDialsStore = useDialsStore()

  // Nastavení editivaného kontaktu
  //const submission = rootStore.getSubmissionById(props.submissionId)
//  const submission = rootStore.getSubmissionById(sId)


  //const customer = rootAppStore.getAppProduelCustomerById(props.customerId)
  const company = rootAppStore.appProduelCompanies.find(c => c.id === props.companyId)
  const token = company?.tokens?.find(t => t.id === props.tokenId)


  const handleClose = () => { 
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    sessionStorage.removeItem('autoCloseModal')
    
    if (refreshAfterClose === true && props.fromExternal !== true) {
      props.setPageLoading(true)
      //await rootStore.fetchSubmissions( Number(sessionStorage.getItem('submissionsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionsSearchPage')))
      props.setPageLoading(false)
    }
    
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    localStorage.removeItem('appProDuelReceiptsSearchArray')
    sessionStorage.removeItem('appProDuelReceiptsSearchPage')
    setLoading(true)
    setLoadingReceipts(true)

    //let submissionId:string = ''

    /*
    if (props.fromExternal === true) {

      receiptsSearchArray.push({filterName: 'startFilter', filterValue: '(Id~eq~'+props.packetId+')', displayValue: "Id účtenky" })
      localStorage.setItem('appProDuelReceiptsSearchArray',JSON.stringify(receiptsSearchArray))

      sessionStorage.setItem('appProDuelReceiptsSearchPage', '1')

      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      

      await axios.get(getUrl('URL_PACKETS')+'/'+props.packetId,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        } }).then(response => {

        submissionId = response.data.submissionId
        setSId(response.data.submissionId)

      }).catch(error => {
        
      });

      await axios.get(getUrl('URL_SUBMISSIONS')+'/'+submissionId,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        } }).then(response => {

        rootStore.addSubmissionFromData(response.data)

      }).catch(error => {
        
      });


      
    }
    else {
      submissionId = props.submissionId
      setSId(props.submissionId)
    }
    */


    setShowDetail(true)
    
  
    await rootAppStore.fetchAppProduelReceipts(1,token?.token!,props.masterView)
    await setLoadingReceipts(false)
      
    await setLoading(false)

}

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete účtenku "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání účtenky'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

// Funkce pro smazání pozvánky
const deleteItem = (id:string) => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    // Zavolání API
    
    await axios.delete(getUrl('URL_DOKLADY_API')+'receipts/'+id, { headers: { 'X-API-KEY': '' + token?.token } }
      ).then(response => {
        // Po smazání přenačíst seznam
        (async () => {
        setLoadingReceipts(true)
        await rootAppStore.fetchAppProduelReceipts(1,token?.token!,props.masterView)
        setLoadingReceipts(false)
        })( )
        
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setShowE(true)          
      });
 
    })()    
}


const handlePageChange = (pageNumber:number) => {
  (async () => { 
    setLoadingReceipts(true);
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    sessionStorage.setItem('appProDuelReceiptsSearchPage', String(pageNumber))
    await rootAppStore.fetchAppProduelReceipts(pageNumber,token?.token!,props.masterView)
    setLoadingReceipts(false);  
  })()
}

const handleOrderChange = (order:string) => {
  (async () => { 
    setLoadingReceipts(true);
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    localStorage.setItem('appProDuelReceiptsOrderBy', order)
    sessionStorage.setItem('appProDuelReceiptsSearchPage', String(1))
    
    await rootAppStore.fetchAppProduelReceipts(1,token?.token!,props.masterView)

    setLoadingReceipts(false);  
  })()
}

const receiptsCount:number = rootAppStore.appProduelReceipts.length

const receiptsListTable = rootAppStore.appProduelReceipts.map((item) => (
  <tr key={item.id}>
    <td>
      {moment(new Date(item.receiptDate)).format('DD.MM.YYYY')}
    </td>
    <td>{item.note}</td>
    <td>{item.signature}</td>
    
    <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.amount} /></td>
    <td>{item.currencyCode}</td>
    <td><Badge variant={item.isProcessed ? 'success':'danger'} title={item.isProcessed ? 'Zpracováno v ekonomickém systému Duel '+moment(new Date(item.receiptDate)).format('DD.MM.YYYY HH:mm'):'Účtenka prozatím nebyla zpracována v ekonomickém systému DUEL'}>{item.isProcessed ? 'Zpracováno':'Nezpracováno'}</Badge></td>
    
    {rights &&<td>
      <ButtonGroup>
        <AppProDuelReceiptDetail receiptId={item.id} tokenId={token?.id!} companyId={props.companyId} showAs='button' />
        <>  
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            {(rightsEdit === true && props.masterView === false) && <>
              <AppProDuelReceiptEdit receiptId={item.id} tokenId={token?.id!} companyId={props.companyId} mode='edit' showAs='drop' />
              <Dropdown.Divider />
            
            
            </>}
            
            
            <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.receiptDate)).format('DD.MM.YYYY HH:mm')) } }>Smazat</Dropdown.Item>
          </DropdownButton>  
        </>
      </ButtonGroup>
    </td>}
  </tr>
)) 


const receiptsListCard = rootAppStore.appProduelReceipts.map((item) => (
  <div key={item.id}>
  <Card>
    <Card.Body>
      
      <div style={{minWidth: '150px', float: 'left'}}>
        <strong>{moment(new Date(item.receiptDate)).format('DD.MM.YYYY')}</strong><br />
        {item.note}<br />
        <span style={{fontStyle: 'italic'}}>{item.signature}</span><br />
        <strong><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.amount} /> {item.currencyCode}</strong><br />
        <Badge variant={item.isProcessed ? 'success':'danger'} title={item.isProcessed ? 'Zpracováno v ekonomickém systému Duel '+moment(new Date(item.receiptDate)).format('DD.MM.YYYY HH:mm'):'Účtenka prozatím nebyla zpracována v ekonomickém systému DUEL'}>{item.isProcessed ? 'Zpracováno':'Nezpracováno'}</Badge>
        <br /><br />
      </div>
      <div style={{float: 'right'}}>
      {rights && <>
        <ButtonGroup>
          <AppProDuelReceiptDetail receiptId={item.id} tokenId={token?.id!} companyId={props.companyId} showAs='button' />
          <>  
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            {(rightsEdit === true && props.masterView === false) && <>
              <AppProDuelReceiptEdit receiptId={item.id} tokenId={token?.id!} companyId={props.companyId} mode='edit' showAs='drop' />
              <Dropdown.Divider />
            
            
            </>}
            <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.receiptDate)).format('DD.MM.YYYY HH:mm')) } }>Smazat</Dropdown.Item>
          </DropdownButton>  
          </>
        </ButtonGroup>
      </>}
      </div>
    </Card.Body>
  </Card>
  <br />
  </div>
)) 

const receiptsList = <>
        <div className="showMobile">
          {receiptsListCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head" style={{cursor: 'pointer', width: '180px'}} onClick={() =>  handleOrderChange((localStorage.getItem('appProDuelReceiptsOrderBy') === 'ReceiptDate' || localStorage.getItem('appProDuelReceiptsOrderBy') === null || localStorage.getItem('appProDuelReceiptsOrderBy') === undefined) ? 'ReceiptDateDesc' : 'ReceiptDate'  )}>
                  Datum vystavení
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('appProDuelReceiptsOrderBy') === 'ReceiptDate' || localStorage.getItem('appProDuelReceiptsOrderBy') === null || localStorage.getItem('appProDuelReceiptsOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('appProDuelReceiptsOrderBy') === 'ReceiptDateDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head">Poznámka</th>
                <th className="jsw-table-head">Podpis</th>
                
                <th className="jsw-table-head" style={{textAlign: 'right', width: '200px'}}>Částka</th>
                <th className="jsw-table-head" style={{width: '60px'}}>Měna</th>
                <th className="jsw-table-head" style={{width: '100px'}}>Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {receiptsListTable}
            </tbody>
          </Table>
        </div> 
        <br />  

</>



// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const receiptDetail = 
      <>

          {showDetail &&<>
          <div>

            {props.masterView === false &&<>
              <Row>
                <Col>
                  
                  <Alert variant='secondary'>
                
                  <Row>
                    <Col md={3} lg={2}><strong>API klíč:</strong></Col>
                    <Col>{token?.name}</Col>
                  </Row>
    
                  </Alert>
                </Col>
    
              </Row>

              
              <hr />
            </>}

            <FilterPanel 
              //isCustomer={props.isCustomer} 
              filterPage={'appProDuelReceipts'} 
              filterSearchArray='appProDuelReceiptsSearchArray' 
              pageVariable='appProDuelReceiptsSearchPage' 
              setPageLoading={setLoadingReceipts} 
              filterItems={['Note','ReceiptDate','Amount','Signature']}  
              title="Seznam účtenek"
              titleCount = {Number(localStorage.getItem('lastAppProDuelReceiptsSearchTotalCount')!)}
              stateNewFilter={stateNewFilter}
              setStateNewFilter={setStateNewFilter}
              otherId={token?.token}
              isCustomer={props.masterView}

              secondaryLeftButtons={<></>}
              />

              <br />
            
            {loadingReceipts === true 
              ? 
              <div><Spinner animation='border'  />&nbsp;<br /></div> 
              :
              receiptsCount === 0 ? <div>Seznam účtenek neobsahuje žádné záznamy.<br /><br /></div> : receiptsList }  
                
             

            <br />  
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={Number(sessionStorage.getItem('appProDuelReceiptsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('appProDuelReceiptsSearchPage')) }
              itemsCountPerPage={10}
              totalItemsCount={Number(localStorage.getItem('lastAppProDuelReceiptsSearchTotalCount'))}
              pageRangeDisplayed={5}
              onChange={handlePageChange.bind(1)}
              /> 
          
          </div>
          </>}

          </>



return ( 
  <>
  {props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Účtenky</Dropdown.Item>
      </>
      :
      props.asLink === true 
        ? 
        <span className='as-link-no-color' title={'Účtenky pro API klíč '+props.linkText} onClick={() => {startSequence()}}>{props.linkText}</span> 
        : 
        <>
          <Button variant='outline-primary' size="sm" style={{lineHeight: '0.6'}} onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Detail distribuce' ><i className="fas fa-search"></i>&nbsp;</span>Účtenky</Button>
        </>
      
    }
  
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání účtenky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

    <Modal 
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {company?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {receiptDetail}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(AppProDuelReceipts))

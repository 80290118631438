import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'



import { useStore } from '../../../store'
import { observer } from 'mobx-react'



interface HedgehogsProps {
      
}

const SelectOptionsHedgehogs: React.FC<HedgehogsProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootStore = useStore()
  
  // Seznam pracovníků JSW 
  const hedgehogs = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === true)
  .slice()
  .map(hu => (
    <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
  ))  
  

  //const datum = new Date().toISOString()
  return (
    <>
      {hedgehogs}
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsHedgehogs))

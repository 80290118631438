import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

//import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
//import NumberFormat from 'react-number-format'

interface SubmissionsUpdateProps {
  id: string,
  showAs: string,
}

const SubmissionUpdate: React.FC<SubmissionsUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()

  let item = rootStore.getSubmissionById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    item = rootStore.getSubmissionById(props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (distributionDateData:string, noteData:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_SUBMISSIONS')+'/'+props.id, { id: props.id, note: noteData, distributionDate: new Date(distributionDateData).toISOString() }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootStore.fetchSubmissions( Number(sessionStorage.getItem('submissionsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionsSearchPage')))
          //rootStore.fetchTemplates(sessionStorage.getItem('templatesSearchValue')!, sessionStorage.getItem('templatesSearchTypeValue')!, sessionStorage.getItem('templatesSearchIsActiveValue')! === '0' ? false : true, sessionStorage.getItem('templatesSearchWithSystemValue')! === '0' ? false : true, 10,  Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage'))) 

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    distributionDate: Yup.string()
    .required('Datum distribuce musí být vyplněn!'),
    
    
  })   
  return (
    <span >
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava distribuce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Distribuce byla úspěšně upravena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                distributionDate: moment(new Date(item?.distributionDate!)).format("YYYY-MM-DDTHH:mm"), 
                note: item?.note,
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.distributionDate!, values.note!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="distributionDate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="distributionDate"
                          value={values.distributionDate!}
                          onChange={handleChange}
                          isValid={touched.distributionDate && !errors.distributionDate} 
                          isInvalid={!!errors.distributionDate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.distributionDate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Popis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                                   
                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(SubmissionUpdate))

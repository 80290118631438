import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getModuleOrder, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Card, CardDeck, Modal, Alert, DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation"

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'

const CustomersLicensesNonactive: React.FC = (props:any) => {
  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  const [showE, setShowE] = useState(false)

  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    //setShowForm('')
    setShow(true)
  }  

  // Dotaz před akcí
  const handleOnClickActivate = (licenseId: string, licenseName: string) => {
    (async () => {
      if (await confirm("Opravdu chcete licenci "+licenseName+" aktivovat?",'Ano, aktivovat', 'Ne', {title: 'Potvrzení aktivace licence'})) {
        handleActivateLicense(licenseId,true)
      } else {
      
      }  
    })()
  }

  // Funkce pro deaktivaci licence
  const handleActivateLicense = (licenseIdData: string, isActiveData: boolean) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_LICENSES_STATE')+licenseIdData+"/state", { licenseId: licenseIdData, customerId: localStorage.getItem('openedCustomerId')!, isActive: isActiveData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
          
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
            handleShow()
            setShowInfo(true)
            sessionStorage.setItem('autoCloseModal','1');

            // Po 2800 ms celé modální okno zavřeme
            setTimeout(() => {
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                setShow(false)
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                //setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)
        });
    })()
  }

  // Načtení Store
  const rootStore = useStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  //Počet záznamů
  const countData:number = Object.keys(rootStore.getCustomerOpenedLicensesNonActive()).length + Object.keys(rootStore.getCustomerOpenedLicensesNonActiveDefault()).length

  const licensesNonactiveStereo = rootStore.getCustomerOpenedLicensesNonactiveStereoActual().map(customerOpenedLicenses => (
    <div key={customerOpenedLicenses.license.id}>
    <CardDeck>
      <Card
        bg='light'
        text='dark'
        className={customerOpenedLicenses.license.program.code === 'D' ? 'border-left-danger':'border-left-info'}
      >
      <div >
        <Card.Header as="h5">{customerOpenedLicenses.license.program.name} {customerOpenedLicenses.license.programVersion.version}
        {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version+' ('+customerOpenedLicenses.license.status+')'} historyType='license' historyParam={customerOpenedLicenses.license.id!} />
            <Dropdown.Item onClick={() => { handleOnClickActivate(customerOpenedLicenses.license.id!, customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version) } }>Aktivovat</Dropdown.Item>
          </DropdownButton>
        </div>} 
        </Card.Header>
        <Card.Body >
          
          Typ licence: {customerOpenedLicenses.license.licenseType.code}, Počet PC: {customerOpenedLicenses.license.count}
          <br /><br />
          <strong>Moduly:</strong><br />
          <table>
            <tbody>
            {customerOpenedLicenses.license.modules!.slice().sort(function(a:any, b:any) {
                  var nameA = getModuleOrder(a.module.code); 
                  var nameB = getModuleOrder(b.module.code); 
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                }).map(modules => (<tr key={modules.module.id}><td className="licenseModuleName">{modules.module.name}</td><td>{modules.count}</td></tr>))}   
            </tbody>
          </table>     

        </Card.Body>
      </div>
      </Card>

      {

      rootStore.getCustomerOpenedLicensesNonactiveStereoDefaultByVersion(customerOpenedLicenses.license.programVersion.version!).map(customerOpenedLicensesDef => (
        
          <Card
            bg='light'
            text='dark'
            key={customerOpenedLicensesDef.license.id}
            className={customerOpenedLicensesDef.license.program.code === 'D' ? 'border-left-danger border-left-dotted':'border-left-info border-left-dotted'}
          >
          <div >
            <Card.Header as="h5">{customerOpenedLicensesDef.license.program.name} {customerOpenedLicensesDef.license.programVersion.version}{customerOpenedLicensesDef.license.status === 'V' ? ' (výchozí licence)':''}
            {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerOpenedLicensesDef.license.program.name+' '+customerOpenedLicensesDef.license.programVersion.version+' ('+customerOpenedLicensesDef.license.status+')'} historyType='license' historyParam={customerOpenedLicensesDef.license.id!} />
           
          </DropdownButton>
        </div>} 
            </Card.Header>
            <Card.Body >
              Typ licence: {customerOpenedLicensesDef.license.licenseType.code}, Počet PC: {customerOpenedLicensesDef.license.count}
              <br /><br />
              <strong>Moduly:</strong><br />
              <table>
                <tbody>
                {customerOpenedLicensesDef.license.modules!.slice().sort(function(a:any, b:any) {
                      var nameA = getModuleOrder(a.module.code); 
                      var nameB = getModuleOrder(b.module.code); 
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                    }).map(modulesDef => (<tr key={modulesDef.module.id}><td className="licenseModuleName">{modulesDef.module.name}</td><td>{modulesDef.count}</td></tr>))}   
                </tbody>
              </table>     

            </Card.Body>
          </div>
          </Card>


      ))



      }

    </CardDeck>
    <br /><br />
    </div>
  ))
 
  const licensesNonactiveDuel = rootStore.getCustomerOpenedLicensesNonactiveDuelActual().map(customerOpenedLicenses => (
    <div key={customerOpenedLicenses.license.id}>
    <CardDeck>
      <Card
        bg='light'
        text='dark'
        className={customerOpenedLicenses.license.program.code === 'D' ? 'border-left-danger':'border-left-info'}
      >
      <div >
        <Card.Header as="h5">
          {customerOpenedLicenses.license.program.name} {customerOpenedLicenses.license.programVersion.version}
          {rightsSHBH &&<div style={{float:'right'}}>
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
              <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version+' ('+customerOpenedLicenses.license.status+')'} historyType='license' historyParam={customerOpenedLicenses.license.id!} />
              <Dropdown.Item onClick={() => { handleOnClickActivate(customerOpenedLicenses.license.id!, customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version) } }>Aktivovat</Dropdown.Item>
            </DropdownButton>
          </div>} 
        </Card.Header>
        <Card.Body >
        
          Typ licence: {customerOpenedLicenses.license.licenseType.code}, Počet PC: {customerOpenedLicenses.license.count}
          <br /><br />
          <strong>Moduly:</strong><br />
          <table>
            <tbody>
            {customerOpenedLicenses.license.modules!.slice().sort(function(a:any, b:any) {
                  var nameA = getModuleOrder(a.module.code); 
                  var nameB = getModuleOrder(b.module.code); 
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                }).map(modules => (<tr key={modules.module.id}><td className="licenseModuleName">{modules.module.name}</td><td>{modules.count}</td></tr>))}   
            </tbody>
          </table>     

        </Card.Body>
      </div>
      </Card>

      {

      rootStore.getCustomerOpenedLicensesNonactiveDuelDefaultByVersion(customerOpenedLicenses.license.programVersion.version!).map(customerOpenedLicensesDef => (
        
          <Card
            bg='light'
            text='dark'
            key={customerOpenedLicensesDef.license.id}
            className={customerOpenedLicensesDef.license.program.code === 'D' ? 'border-left-danger border-left-dotted':'border-left-info border-left-dotted'}
          >
          <div >
            <Card.Header as="h5">
              {customerOpenedLicensesDef.license.program.name} {customerOpenedLicensesDef.license.programVersion.version}{customerOpenedLicensesDef.license.status === 'V' ? ' (výchozí licence)':''}
              {rightsSHBH &&<div style={{float:'right'}}>
                <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                  <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerOpenedLicensesDef.license.program.name+' '+customerOpenedLicensesDef.license.programVersion.version+' ('+customerOpenedLicensesDef.license.status+')'} historyType='license' historyParam={customerOpenedLicensesDef.license.id!} />
                </DropdownButton>
              </div>} 
            </Card.Header>
            <Card.Body >
              Typ licence: {customerOpenedLicensesDef.license.licenseType.code}, Počet PC: {customerOpenedLicensesDef.license.count}
              <br /><br />
              <strong>Moduly:</strong><br />
              <table>
                <tbody>
                {customerOpenedLicensesDef.license.modules!.slice().sort(function(a:any, b:any) {
                      var nameA = getModuleOrder(a.module.code); 
                      var nameB = getModuleOrder(b.module.code); 
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                    }).map(modulesDef => (<tr key={modulesDef.module.id}><td className="licenseModuleName">{modulesDef.module.name}</td><td>{modulesDef.count}</td></tr>))}   
                </tbody>
              </table>     

            </Card.Body>
          </div>
          </Card>


      ))



      }

    </CardDeck>
    <br /><br />
    </div>
  ))


  return (
    <div>
      <h4>Neaktivní licence</h4>
      <br />
      {countData === 0 ? <div>Seznam neaktivních licencí neobsahuje žádné záznamy.<br /><br /></div> : ''}
      
        {licensesNonactiveStereo}
        
        {licensesNonactiveDuel}

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Aktivace licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Aktivace licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Operace byla úspěšně provedena!​</strong>
            
          </Alert>  
          
        </Modal.Body>

      </Modal>


    </div>

  )  
  
}

export default withRouter(observer(CustomersLicensesNonactive))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Button, Modal, Card, Table } from 'react-bootstrap'

import { useDialsStore } from '../../store'
import { observer } from 'mobx-react'

interface LoadFreeTextProps {
  pole:string,
  setFieldValue:any,
  showFormField:any,
  originalText?:string,
  handleKeyDown?:any,
  handleSubmit?:any    
}

const LoadFreeText: React.FC<LoadFreeTextProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  
  //const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  
  const [insertNewContact, setInsertNewContact] = useState(false)

  // Připojení store
  const rootDialsStore = useDialsStore()
  const originalText:string = props.originalText !== undefined ? props.originalText : ''
  
  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    //setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  
// Startovací sequence při otevření formuláře
const startSequence = () => {
  rootDialsStore.fetchDialsFreeText()
  setInsertNewContact(true)
  //setShowInfo(false) 
}

const freeTexts = rootDialsStore.dialsFreeText.slice().sort(function(a:any, b:any) {
  var sort1A = a.name; 
  var sort1B = b.name; 

  if (sort1A! < sort1B!) {
    return -1;
  }
  if (sort1A! > sort1B!) {
    return 1;
  }
  return 0;
})

  //Počet záznamů
  const countData:number = Object.keys(freeTexts).length

  // Seznam pozvánek do řádků tabulky
  const linesFreeText = freeTexts.map((freeText) => (
    <tr key={freeText.id}>
      <td>{freeText.name}</td>
      
      {rights &&<td>
      
      
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">
        <Button size='sm' variant='primary' onClick={async() => { await props.showFormField(false); await props.setFieldValue(props.pole,originalText+freeText.text); console.log(props.pole); await setTimeout( props.showFormField(true), 1000 ); await handleCloseMain(); }}>Vybrat</Button>
          
        </div>
      
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesFreeTextCard = freeTexts.map((freeText) => (
    <div key={freeText.id}>
    <Card>
      <Card.Body>
        {rights && <>
            
            <div className="float-right">
              <Button size='sm' variant='primary' onClick={async() => { await props.showFormField(false); await props.setFieldValue(props.pole,originalText+freeText.text); console.log(props.pole); await setTimeout( props.showFormField(true), 1000 ); await handleCloseMain(); }}>Vybrat</Button>
            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{freeText.name}</strong><br />
          
          <br />
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))  

return ( 
  <>
  <><Button variant='outline-primary' onKeyDown={(e:any) => { if (props.handleKeyDown !== undefined)  props.handleKeyDown(e, props.handleSubmit)}} style={{marginTop: '10px'}} size="sm" onClick={() => {startSequence()}} >Vybrat text z číselníku</Button></>
  
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Výběr volného textu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
        <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam volných textů neobsahuje žádné záznamy.<br /><br /></div>
        <div className={countData === 0 ? 'nodisplay' : ''}>
          <div className="showMobile">
            {linesFreeTextCard}<br />
          </div>
          <div className="showWeb">
          <Table hover>
              <thead>
                <tr className="jsw-table-head">
                  <th className="jsw-table-head">Název</th>
                  
                  {rights &&<th className="jsw-table-head">Akce</th>}
                </tr>
              </thead>
              <tbody>
                {linesFreeText}
              </tbody>
            </Table>
          </div> 
          <br />  
          
        </div>  

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(LoadFreeText))

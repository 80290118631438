import { types } from 'mobx-state-tree'

export const DialsPrograms = types
  .model({
    id: types.optional(types.string, ''),
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

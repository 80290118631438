import { getToken, errorEvaluation, getUrl } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { CalculatorsUpgradeScenarios } from './calculatorsUpgradeScenarios.store'
import { CalculatorsUpgradeCustomer } from './calculatorsUpgradeCustomer.store'
import { CalculatorsUpgradeCustomerLicenses } from './calculatorsUpgradeCustomerLicenses.store'


import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/


export const RootCalculatorsStore = types
  .model({
    calculatorsUpgradeScenarios: types.array(CalculatorsUpgradeScenarios),
    calculatorsUpgradeCustomerLicenses: types.array(CalculatorsUpgradeCustomerLicenses),
    
    calculatorsUpgradeCustomer: types.array(CalculatorsUpgradeCustomer),
    
  })
  .views(self => ({
    getUpgradeCustomerLicenseActiveDefaultByProgramId(programId:string) {
      return self.calculatorsUpgradeCustomerLicenses.find(data => data.license.isActive === true && data.license.program.id === programId && data.license.status === 'V')
    },
    getUpgradeCustomerLicenseActiveActualByProgramId(programId:string) {
      return self.calculatorsUpgradeCustomerLicenses.find(data => data.license.isActive === true && data.license.program.id === programId && data.license.status === 'A')
    },
  }))
  .actions(self => ({
    fetchCalculatorsUpgradeScenarios: flow(function* fetchProjects(scenarioId:string) {
      try {

      //  yield sleep(300)


       const accessToken = yield getToken()

       const cl = yield axios.get(getUrl('URL_OFFERS_SCENARIOS'),{
         headers: {
           'Authorization': 'Bearer ' + accessToken
         }}).then(response => {
         
         return response.data.filter((data: { id: string; }) => data.id === scenarioId)
         
       }).catch(error => {
         errorEvaluation(error)
       });
        //console.log(cl)
        //const rootUpgradeStore = useUpgradeStore()
        applySnapshot(self.calculatorsUpgradeScenarios,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst scénář!', error)

    } 
    }),

    fetchCalculatorsUpgradeScenarios2: flow(function* fetchProjects(scenario) {
      try {

        yield sleep(0)


       //const accessToken = yield getToken()

       const scenarioData = {id: scenario.id, distributionDate: scenario.distributionDate, program: {id: scenario.program.id, name: scenario.program.name, code: scenario.program.code}, programVersion: {id: scenario.programVersion.id, name: scenario.programVersion.name, version: scenario.programVersion.version,   programId: scenario.programVersion.programId} }

        console.log(scenario)
        //const rootUpgradeStore = useUpgradeStore()
        applySnapshot(self.calculatorsUpgradeScenarios,[scenarioData]) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst scénář!', error)

    } 
    }),

    deleteCalculatorsUpgradeScenarios: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.calculatorsUpgradeScenarios.clear()
                
      } catch (error) {
        console.error('Failed to clear upgradeOffersScenarios', error)
      }
    }),  
    
    fetchCalculatorsUpgradeCustomer: flow(function* fetchProjects(accountNumber:string) {
      try {

      //  yield sleep(300)
       //applySnapshot(self.calculatorsUpgradeCustomer,{})
       self.calculatorsUpgradeCustomer.clear()
       const accessToken = yield getToken()
       // eslint-disable-next-line
       const filter = 'AccountNumber~like~'+accountNumber
       const page = 1
       const pagesize = 1

         const searchData = yield axios.get(getUrl('URL_CUSTOMER_LIST'),{
         headers: {
           'Authorization': 'Bearer ' + accessToken
         },params: {
           filter,
           page,
           pagesize
         }  }).then(response => {

         return response.data.items
         
       }).catch(error => {
         errorEvaluation(error)
       });

        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+searchData[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.calculatorsUpgradeCustomer,[cl]) 
          
    } catch (error) {
      //applySnapshot(self.calculatorsUpgradeCustomer,{})
      self.calculatorsUpgradeCustomer.clear()
      console.error('Nepodařilo se načíst zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
 
    fetchCalculatorsUpgradeCustomerLicenses: flow(function* fetchProjects(customerId:string) {
      try {

      //  yield sleep(300)
       self.calculatorsUpgradeCustomerLicenses.clear()
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_TRANSFER_CUSTOMER_LICENSES')+customerId+'?onlyActive=true' ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.calculatorsUpgradeCustomerLicenses,cl) 
          
    } catch (error) {
      self.calculatorsUpgradeCustomerLicenses.clear()
      console.error('Nepodařilo se načíst licence zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteCalculatorsUpgrade: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        //applySnapshot(self.calculatorsUpgradeCustomer,{})
        self.calculatorsUpgradeCustomer.clear()
        self.calculatorsUpgradeCustomerLicenses.clear()
        
                
      } catch (error) {
        console.error('Failed to delete licenses transfer', error)
      }
    }),

    deleteCalculatorsUpgradeCustomerLicenses: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.calculatorsUpgradeCustomerLicenses.clear()        
      } catch (error) {
        console.error('Failed to delete licenses transfer', error)
      }
    }),


    

  }))

export type RootCalculatorsStoreType = Instance<typeof RootCalculatorsStore>

export type CalculatorsUpgradeScenariosStoreType = Instance<typeof CalculatorsUpgradeScenarios>
export type CalculatorsUpgradeCustomerStoreType = Instance<typeof CalculatorsUpgradeCustomer>
export type CalculatorsUpgradeCustomerLicensesStoreType = Instance<typeof CalculatorsUpgradeCustomerLicenses>


import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Alert, Button, Badge, Form } from 'react-bootstrap'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import { getToken, setUserRoles, getUrl } from '../../Utils/Common'
import { login } from '../../authProvider'

//import * as Constants from '../../Constants/Constants'

import CustomersOtherActions from '../customersOtherActions/Customers.OtherActions.part'

import CustomersFilesDetail from '../../pages/customers/dashboard/components/Customers.Dashboard.Files.Modal.part'

function CustomerHeader(props:any) {
  // eslint-disable-next-line
  const [error, setError] = useState(null)


  // Načtení Store
  const rootStore = useStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)


  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') ) ? true : false

  



  const handleShowHowUser = () => {
    if (localStorage.getItem('BigUser') === "1" && localStorage.getItem('BigUserBefore') === '0') {
        localStorage.removeItem('BigUser')
        localStorage.removeItem('BigUserBefore')
        
        if (localStorage.getItem('BigHedgehogBefore') === '1') { localStorage.setItem('BigHedgehog', '1') }
        if (localStorage.getItem('SmallHedgehogBefore') === '1') { localStorage.setItem('SmallHedgehog', '1') }
        localStorage.removeItem('BigHedgehogBefore')
        localStorage.removeItem('SmallHedgehogBefore')
        


        window.location.reload()
    }
    else {
        if (localStorage.getItem('BigUser') !== "1") { 
            localStorage.setItem('BigUser', '1')
            localStorage.setItem('BigUserBefore', '0')
            if (localStorage.getItem('BigHedgehog') === '1') { localStorage.setItem('BigHedgehogBefore', '1') }
            if (localStorage.getItem('SmallHedgehog') === '1') { localStorage.setItem('SmallHedgehogBefore', '1') }
            localStorage.removeItem('BigHedgehog')
            localStorage.removeItem('SmallHedgehog')


            window.location.reload()
         }
        
        
    }
  }


  // Funkce pro zavření firmy
  const handleClose = async () => {
    setError(null)

    
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    
    if (customer?.isActive === true) {
      // Zavolání API
      await axios.get(getUrl('URL_CUSTOMER_CLOSE')+localStorage.getItem('openedCustomerId'),{
        headers: {
          'Authorization': 'Bearer ' + aToken
        }}
      ).then(response => {
        (async () => {

        
        // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
        setUserRoles(response.data.roles)
        login(response.data.token)

        // Smazání id otevřené firmy z proměnné v localStore
        localStorage.removeItem('openedCustomerId')

        // Smazání otevřené firmy ze store
        await rootStore.deleteCustomerOpened()

        // Přesměrování na stránku s přehledem firmy
        if (localStorage.getItem('openedFromOverviews') === '1') {
          // Nastavení informace, že firma byla otevřená z přehledu
          localStorage.removeItem('openedFromOverviews')
          if (localStorage.getItem('lastDatafilterType') === 'customers') {
            await props.history.push('/overviews-detail/'+localStorage.getItem('lastDatafilter'))
          }
          if (localStorage.getItem('lastDatafilterType') === 'offers') {
            await props.history.push('/overviews-detail-offer/'+localStorage.getItem('lastDatafilter'))
          }
          
        }
        else {
          // Nastavení informace, že firma byla otevřená z přehledu
          localStorage.removeItem('openedFromOverviews')
          await props.history.push('/customers-open')
        }
        
        })();
      }).catch(error => {
        
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        
      });
    }
    else {
      localStorage.removeItem('openedCustomerId')
      await rootStore.deleteCustomerOpened()
      if (localStorage.getItem('openedFromOverviews') === '1') {
        // Nastavení informace, že firma byla otevřená z přehledu
        localStorage.removeItem('openedFromOverviews')
        if (localStorage.getItem('lastDatafilterType') === 'customers') {
          await props.history.push('/overviews-detail/'+localStorage.getItem('lastDatafilter'))
        }
        if (localStorage.getItem('lastDatafilterType') === 'offers') {
          await props.history.push('/overviews-detail-offer/'+localStorage.getItem('lastDatafilter'))
        }
      }
      else {
        // Nastavení informace, že firma byla otevřená z přehledu
        localStorage.removeItem('openedFromOverviews')
        await props.history.push('/customers-open')
      }
    }
  }



   
  return( 
    <div style={{position: 'sticky', top: '56px', zIndex: 1037}}>
    <Alert variant="secondary" className="alert-no-border-radius">
      <div className="float-right small-float-left">
        Číslo zákazníka:&nbsp;<strong>{customer?.accountNumber}</strong>
        {rights ? <span style={{marginLeft: '10px'}}></span> : '' }
        {rights ? <CustomersOtherActions id={localStorage.getItem('openedCustomerId')!} isActive={customer?.isActive!} accountNumber={customer?.accountNumber!} /> : '' }
        
        {localStorage.getItem('customersTotalCountInit') !== '1' ? <span style={{marginLeft: '10px'}}></span> : '' }
        {localStorage.getItem('customersTotalCountInit') !== '1' ? <Button variant="danger" size="sm" onClick={() => { handleClose() } } ><i className="fas fa-times"></i>&nbsp;&nbsp;Zavřít</Button> : ''}
        
      </div>
      <div className="small-cleaner"></div>
      <div className="float-left">
        <span className="h4">{customer?.name === null || customer?.name === "" ? customer?.person : customer?.name}</span>
        {rights ? <br /> : '' }
        {rights && customer?.isActive === false && <Badge variant="danger"  style={{marginRight: '5px'}} title='Příznak, zda je zákazník aktivní.' >Neaktivní</Badge>}
        {rights && customer?.isActive === true && <Badge variant="info"  style={{marginRight: '5px'}}  title='Příznak, zda je zákazník aktivní.' >Aktivní</Badge>}

        {(rights === true && props.showStatuts === true) && <>
        <Badge style={{marginRight: '5px'}} variant={props.statusGDPR === true ? 'info' : 'danger'} title='Příznak, zda má zákazník podepsanou GDPR smlouvu.'>GDPR</Badge>
        <Badge style={{marginRight: '5px'}} variant={props.statusVS === true ? 'info' : 'danger'} title='Příznak, zda má zákazník předplacenou vzdálenou správu.'>VS</Badge>
        <Badge style={{marginRight: '5px'}} variant={props.statusNonActualV === true ? 'danger' : 'info'} title='Příznak, zda zákazník má alespoň jednu aktuální verzi programu.'>{props.statusNonActualV === true ? 'Neaktuální' : 'Aktuální'}</Badge>
        <Badge style={{marginRight: '5px'}} variant={props.statusStart === true ? 'info' : 'light'} title='Příznak, zda má zákazník DUEL registrovaný v režimu StartVerze.'>Startverze</Badge>
        <Badge style={{marginRight: '5px'}} variant={props.statusNewCustomer === true ? 'info' : 'light'} title='Příznak, zda se jedná o nového zákazníka (do 3 měsíců).'>Nový</Badge>
        <Badge style={{marginRight: '5px'}} variant={props.statusNotes === true ? 'danger' : 'light' } title='Příznak, zda má zákazník aktivní upozornění.'>Upozornění</Badge>
        <CustomersFilesDetail customerId={localStorage.getItem('openedCustomerId')!} showAs='badge' badgeFilesCount={props.statusFiles} />
        </>}

      </div>
      
      <div className="cleaner"></div>

      {rights === true || localStorage.getItem('BigUserBefore') === '0'
          ?
          <div className="nodisplay">
              <div className="float-left">
              <Form.Check 
              type="switch"
              id="show-how-user"
              label=""
              name="show-how-user"
              //disabled={!rights || !upgradeOffer.showControlButtons}
              checked={localStorage.getItem('BigUser') === '1' ? true:false}
              onChange={async (e:any) => {await handleShowHowUser()}}
              //onClick={async () => {await handleShowHowUser();}}
              //checked={true}
              />
              </div>
              <div className="float-left" style={{marginLeft: '0px'}}>
              Zobrazit jako uživatel
              </div>
              <div className="cleaner"></div>
          </div>
          
          
          : ''    
      }

    </Alert>
    </div>
  )
}
  
export default withRouter(observer(CustomerHeader))

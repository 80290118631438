import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, setUserRoles, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Col, Modal, Row, CardDeck, Container, Spinner, Dropdown, Badge } from 'react-bootstrap'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { login } from '../../../authProvider'
import { ROUTES } from '../../../Routes'

import CustomersDashboardInformation from '../../customers/dashboard/components/Customers.Dashboard.Information.part'
import CustomersDashboardDeliveryAddress from '../../customers/dashboard/components/Customers.Dashboard.DeliveryAddress.part'
import CustomersDashboardContacts from '../../customers/dashboard/components/Customers.Dashboard.Contacts.part'
import CustomersDashboardCategories from '../../customers/dashboard/components/Customers.Dashboard.Categories.part'
import CustomersDashboardLicenses from '../../customers/dashboard/components/Customers.Dashboard.Licenses.part'
import CustomersDashboardNotes from '../../customers/dashboard/components/Customers.Dashboard.Notes.part'

interface SupportContactsCustomerInfoProps {  
  customerId?:string,
  isActiveCustomer?:boolean,
  showExternal?:boolean,
  customerName?:string,
  customerNameFull?:string,
  fromSubMenu?:boolean
}

const SupportContactsCustomerInfo: React.FC<SupportContactsCustomerInfoProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  // eslint-disable-next-line
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)  
  const [opening, setOpening] = useState(false)

  // Připojení store
  const rootStore = useStore()

  const customerLoaded = rootStore.customerOpened.slice()[0]

  // Otevírání a zavírání zobrazení formuláře
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleShow = (id:string,isActive:boolean) => {
    sessionStorage.removeItem('autoCloseModal')
    handleOpen(id,isActive)
    setShow(true) 
  }

  // Funkce pro otevření firmy
  const handleOpen = async (id:string,isActive:boolean) => {
    setError(null)
    setOpening(true)

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    
    if (isActive === true) {
      // Zavolání API
      await axios.get(getUrl('URL_CUSTOMER_OPEN')+id,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        }}
      ).then(response => {
        (async () => {
        
        // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
        setUserRoles(response.data.roles)
        login(response.data.token)

        // Nastavení id otevřené firmy do proměnné v localStore
        localStorage.setItem('openedCustomerId', id)

        // Načtení otevřené firmy do store
        await rootStore.fetchCustomerOpened()
        if (rights === true) rootStore.fetchCustomerOpenedCategories()
        if (rights === true) rootStore.fetchCustomerOpenedServices(id)
        if (rights === true) rootStore.fetchCustomerOpenedNotes(id);
        (async () => {
          // Pokud je zákazník aktivní, načítáme také licence a způsoby použití
          if (rootStore.getOpenedCustomerById(id!)?.isActive === true) {
            await rootStore.fetchCustomerOpenedLicenses(id!)
            rootStore.fetchUsageMode(id!,rootStore.getCustomerOpenedLicensesActiveActual()[0]?.license.program.id!,  rootStore.getCustomerOpenedLicensesActiveActual()[1]?.license.program.id!)
          }
        })()   
        setOpening(false)

        
        })();
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)

        setOpening(false)
      });
    }
    else {
      localStorage.setItem('openedCustomerId', id)
      await rootStore.fetchCustomerOpened()
      if (rights === true) rootStore.fetchCustomerOpenedCategories()
      if (rights === true) rootStore.fetchCustomerOpenedNotes(id)
      setOpening(false)
      
    }
  }

  if (props.showExternal === true) handleShow(props.customerId!, props.isActiveCustomer!)







      



return ( 
  <>
    {props.fromSubMenu === true
    ?
    <>
      <Dropdown.Item onClick={() => {handleShow(props.customerId!, props.isActiveCustomer!)}}>Detail zákazníka</Dropdown.Item>
    </>
    :
    <span className='as-link-no-color' title={'Detail zákazníka '+props.customerNameFull} onClick={() => {handleShow(props.customerId!, props.isActiveCustomer!)}}>{props.customerName}</span>
    }
  


    <Modal 
      show={show} 
      onHide={handleClose} 
      backdrop="static"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Detail zákazníka {opening !== true &&<Badge variant='secondary'>{customerLoaded?.accountNumber}</Badge>}
          &nbsp;<a target='_blank' href={'#'+ROUTES.dashboard.getLink()} rel="noopener noreferrer"><i className="fas fa-external-link-alt"></i></a>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
        {opening &&<div style={{textAlign: 'center'}}><Spinner animation="border" variant="secondary" /></div>}
        {!opening &&
        <Container fluid>
          
          <Row>
            <Col style={{minWidth:'300px', marginTop: '25px'}}>
              
              <CustomersDashboardInformation />
              <br />
              <CustomersDashboardDeliveryAddress />
            </Col>
            <Col style={{minWidth:'300px', marginTop: '25px'}}>
              <CustomersDashboardContacts />
            </Col>
          </Row>
          
          
          <br /><br />
          <CustomersDashboardLicenses />
          <br />
          {rights && <>
          
          <CustomersDashboardNotes />
          <br /><br />
          </>}
          <CardDeck>
            {rights && <CustomersDashboardCategories />}
          </CardDeck>
          <br /><br />
        </Container>}
        
      </Modal.Body>
    </Modal>






  </>
)

}

export default withRouter(observer(SupportContactsCustomerInfo))

import { types } from 'mobx-state-tree'

export const CustomerOpenedHistory = types
  .model({
    id: types.optional(types.string, ''),
    userId: types.maybeNull(types.string),
    userFirstName: types.maybeNull(types.string),
    userSurName: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    operation: types.maybeNull(types.string),
    scope: types.maybeNull(types.string),
    data: types.maybeNull(types.string)
  })
  .views(self => ({
    get userFullName() {
      if (self.userFirstName !== null) {
        if (self.userSurName !== null) {
          return self.userFirstName + ' ' + self.userSurName
        }
        else {
          return self.userSurName
        }
      }
      else {
        if (self.userSurName !== null) {
          return self.userSurName
        }
        else {
          return ''
        }
      }
      
    },
    get operationName() { 
      if (self.operation === 'insert') { return 'Přidání' }
      else if (self.operation === 'update') { return 'Úprava' }
      else if (self.operation === 'delete') { return 'Smazání' }
      else { return '' }
    },
    get scopeName() { 
      if (self.scope === 'customer') { return 'Zákazník' }
      else if (self.scope === 'customers') { return 'Zákazníci' }
      else if (self.scope === 'contact') { return 'Kontakt' }
      else if (self.scope === 'contacts') { return 'Kontakty' }
      else if (self.scope === 'offer') { return 'Nabídka' }
      else if (self.scope === 'Offers') { return 'Nabídky' }
      else if (self.scope === 'templates') { return 'Šablony e-mailu' }
      else if (self.scope === 'billing_address') { return 'Fakturační adresa' }
      else if (self.scope === 'billing_addresses') { return 'Fakturační adresy' }
      else if (self.scope === 'delivery_address') { return 'Dodací adresa' }
      else if (self.scope === 'delivery_addresses') { return 'Dodací adresy' }
      else if (self.scope === 'customer_categories') { return 'Kategorie zákazníka' }
      else if (self.scope === 'customer_licenses') { return 'Licence zákazníka' }
      else if (self.scope === 'licenses') { return 'Licence' }
      else if (self.scope === 'customer_services') { return 'Služby zákazníka' }
      else if (self.scope === 'customer_notes') { return 'Poznámky zákazníka' }
      else if (self.scope === 'license') { return 'Licence' }

      else { return self.scope }
    }
  }))
  .actions(self => ({
    

  }))

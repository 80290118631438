import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

import {Container, Image} from 'react-bootstrap'

import LogoutInfo from '../../../parts/logoutInfo/LogoutInfo.parts'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { observer } from 'mobx-react'
import { useStore } from '../../../store' 

import { removeUserSession, getToken, checkVersion } from '../../../Utils/Common'
import { getUrl } from '../../../Utils/Common'
//import * as Constants from '../../../Constants/Constants'
import { authFetch, logout } from '../../../authProvider'

import Loader from "react-loader"

function Logout(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true)
  
  // Připojení store
  const rootStore = useStore()
  
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {
      setLoaded(false) // Nastavení pro Loader, že není strana načtena
      window.scrollTo(0, 0) // Odrolování nahoru
      //removeUserSession() // Odebrání session uživatele
      //logout() // Odhlášení z doplňku pro řízení tokenů
      await checkVersion()
      // Vymazání store
      await rootStore.deleteRootStore()
      /*
      await rootStore.deleteCustomerList()
      await rootStore.deleteCustomerOpened()  
      await rootStore.deleteLoggedUser()
      await rootStore.deleteInvites()
      await rootStore.deleteCustomerUsers()
      await rootStore.deleteOvervievsCustomers()
      await rootStore.deleteDatafiltersCustomers()
      //await 
      */

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        //sessionStorage.setItem('automaticLogout', '1')
        //props.history.push('/logout')
      }

      // Odeslání odhlášení na BE
      await authFetch(getUrl('URL_LOGOUT'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }}).then(response => {
        
        }).catch(error => {
    
        });
        await removeUserSession() // Odebrání session uživatele
        await logout() // Odhlášení z doplňku pro řízení tokenů
        // Pokud se jedná o automatické odhlášení, okamžitě přepnout na loginpage
        if (sessionStorage.getItem('automaticLogout') !== null) {
          props.history.push('/login')
        }
        // Pokud ne, zobrazit potvrzení odhlášení a na loginpage přepnout za 10s
        else {
          setLoaded(true)
          setTimeout(() => {
            props.history.push('/login') 
          }, 10000);
        }
    })();
  })

  useEffect(() => {
    return () => {
      deleteTimeout()
      
    }
  }, [])

  const deleteTimeout = async () => {
    for (var i = 0; i < 99999; i++)
	    clearTimeout(i);
  }

  return(
    <div className="App">
        
        
        <main>
          <Loader 
            loaded={loaded}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={true}
            hwaccel={true}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
          >
          <Container fluid className="nopadding-right">
            <div className="row no-gutter">
              
              <div className="col-md-8 col-lg-6">
                <div className="login d-flex align-items-center py-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9 col-lg-8 mx-auto">
                        <h2 className="login-text-vertical-center"><Image src="jezekswSmall.png" className="login-logo" /> Zákaznický portál</h2>
                        <hr />
                        <br />
                        <h3 className="login-heading mb-4">Bezpečně jsme Vás odhlásili!</h3>
                        <p>{sessionStorage.getItem('automaticLogout') !== null ? 'Z důvodu nečinnosti došlo k automatickému odhlášení.' : ''}</p>
                        
                        <p className="mb-0">
                          Pokud se chcete vrátit, <Link to={ROUTES.login.getLink()}>přihlásit se můžete zde...</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
                <div className="login d-flex align-items-center py-5">
                  <Container className="login-text-white login-container">
                    <div className="row">
                      <div className="mx-auto">
                        <LogoutInfo />
                      </div>
                    </div>
                  </Container>
                </div>  
              </div>
            </div>
          </Container>

          
          </Loader>     
        </main>
      
    </div> 
  )
}

export default observer(Logout)
import { types } from 'mobx-state-tree'


export const CustomerListAll = types
  .model({
    id: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.optional(types.string, ''),
    companyRegistrationNumber: types.optional(types.string, ''),
    taxIdentificationNumber: types.optional(types.string, ''),
    isTaxAble: types.optional(types.boolean, false),
    isActive: types.optional(types.boolean, false),

  })
  .views(self => ({
    get customersListItems() {
      return null //self.items
    },
    get businessNameForSearch() {
      //if (self.title !== null || self.firstName !== null || self.surName !== null)
      
      return self.businessName+' '+ self.companyRegistrationNumber 
    },
    get businessNameShort() {
      //if (self.title !== null || self.firstName !== null || self.surName !== null)
      if (self.businessName.length > 30) {
        return self.businessName.substring(0,30) + '...'
      }
      else
      {
        return self.businessName
      }
    },
  }))
  .actions(self => ({
    

  }))

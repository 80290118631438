import React  from 'react'
import {Card} from 'react-bootstrap'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import CustomersDashboardDeliveryAddressUpdate from './Customers.Dashboard.DeliveryAddress.Update.part'

const CustomersDashboardDeliveryAddress: React.FC = () => {

  // Načtení Store
  const rootStore = useStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  //console.log(customer)
  return (
    <Card
      bg='light'
      text='dark' 
      style={{}}     
    >
      <Card.Header  as="h5">Dodací údaje</Card.Header>
      <Card.Body>
        <Card.Text>
          {customer?.deliveryAddress === null ? '(stejné jako fakturační)' : ''}

          {customer?.deliveryAddress !== null && customer?.deliveryAddress?.name !== '' ? <span><strong>{customer?.deliveryAddress?.name}</strong></span> : ''}
          {customer?.deliveryAddress !== null && customer?.deliveryAddress?.name !== '' ? <br /> : ''}

          {customer?.deliveryAddress !== null && customer?.deliveryPerson !== '' ? <span><strong>{customer?.deliveryPerson}</strong></span> : ''}
          {customer?.deliveryAddress !== null && customer?.deliveryPerson !== '' ? <br /> : ''}
          <br />
          {customer?.deliveryAddress?.street}&nbsp;{customer?.deliveryAddress?.houseNumber}
          {customer?.deliveryAddress === null || customer?.deliveryAddress?.identificationNumber === null || customer?.deliveryAddress?.identificationNumber === '' ? '':'/'}
          {customer?.deliveryAddress?.identificationNumber}
          {customer?.deliveryAddress !== null ? <br /> : ''}
          {customer?.deliveryAddress?.postalCode}&nbsp;{customer?.deliveryAddress?.city}
          {customer?.deliveryAddress !== null ? <br /> : ''}
          {customer?.deliveryAddress !== null ? <br /> : ''}
          {customer?.deliveryAddress !== null && customer?.deliveryAddress?.phoneNumber !== '' ? <span><strong>Telefon:</strong>&nbsp;{customer?.deliveryAddress?.phoneNumber}</span> : ''} 

        </Card.Text>
        
        <CustomersDashboardDeliveryAddressUpdate label="Upravit" showIcon={false} block={false} validateOnMount={false} />
      </Card.Body>
    </Card>
  )  
  
}

export default observer(CustomersDashboardDeliveryAddress)

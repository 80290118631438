import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getDataFiltersNewFirstUrlPart, getDataFiltersNewProgramId, getDataFiltersNewIsStereoLan } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { useStore } from '../../store'

import NumberFormat from 'react-number-format'

interface CustomersOtherActionsUpgradeFreeCreateLicensesProps {
  mode: string,
  id: string,
  //filterId:number
  showAs: string,
  filterId: string,
  info: string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined,
  searchValue?:string|undefined,
  searchType?:string|undefined, 
  searchScenario?:string|undefined,
  count?: number|undefined
}

const CustomersOtherActionsUpgradeFreeCreateLicenses: React.FC<CustomersOtherActionsUpgradeFreeCreateLicensesProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  const rootStore = useStore()

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    sessionStorage.removeItem('autoCloseModal')
    await setError(null)
    await setShowInfo(false)
    await setShowForm('')
    if (props.filtered === true) {
      await rootStore.fetchDatafiltersOffersNewForFilteredActions(Number(props.filterId),props.searchValue,props.searchType,props.searchScenario)
    }
    
    
    await setShow(true)
  }

  // Funkce pro odeslání e-mailu
  const handleCreateLicenses = () => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      if (props.mode === "all") {
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/final', { mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', selectedIds: props.mode === 'all' ? props.filtered === true ? rootStore.datafiltersOffersNewForFilteredActions.map(dfon => dfon.offerId) : [] : props.multiselect === true ? rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true).map(dfon => dfon.offerId) : [props.id], isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response)
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API
      
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/final', { mode: props.mode, selectedIds: props.mode === 'all' ? [] : props.multiselect === true ? rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true).map(dfon => dfon.offerId) : [props.id], isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)

          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    confirmation: Yup.boolean()
    .oneOf([true], 'Není zatrhnuto potvrzení!'),
  })   
  return (
    <>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Vytvořit licenční lístky'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Vytvořit licenční lístky'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvořit licenční lístek'}
          </Dropdown.Item>}
          
          {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Vytvořit licenční lístky'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Vytvořit licenční lístky'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvořit licenční lístek'}
          </Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mode === 'all' && <>Hromadné vytvoření licenčních lístků (<NumberFormat displayType={'text'} thousandSeparator={' '} value={localStorage.getItem('lastDatafilterOfferTotalCount')!} />)</>}
            {(props.mode === 'selected' && props.multiselect === true) && <>Vytvoření licenčních lístků ({props.count})</>}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvoření licenčního lístku'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(props.mode === 'selected' && props.multiselect !== true) &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}

          <Alert variant="success" show={showInfo}>
            {(props.mode === 'selected' && props.multiselect !== true) ? <strong>Požadavek na vytvoření licenčního lístku byl odeslán!​</strong>:<strong>Požadavek na vytvoření licenčních lístků byl odeslán!​</strong>}
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                scenarioId: '',
                confirmation: false
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleCreateLicenses()              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Potvrzení</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="confirmation">
                    <Form.Row>
                      
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="confirmation"
                          size="sm"
                          className="checkbox-small"
                          style={{marginTop: '10px'}}
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.confirmation}
                          
                          isValid={touched.confirmation && !errors.confirmation} 
                          isInvalid={!!errors.confirmation}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmation}
                        </Form.Control.Feedback>
                      </Col>
                      <FormLabel column>Pro pokračování v operaci je nezbytné potvrdit, že ji chcete spustit.</FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám požadavek...' : 'Odeslat požadavek'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsUpgradeFreeCreateLicenses))

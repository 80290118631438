import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation"

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'


import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'

interface CustomersNotesUpdateProps {
  id: string
}

const CustomersNotesUpdate: React.FC<CustomersNotesUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') ) ? true : false
  const rightsBHSH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const customerNote = rootStore.customerOpenedNotes.find(note => note.id === props.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  let notename:string

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteNote = () => {
    (async () => {
      notename = customerNote?.note!.length! > 25 ? customerNote?.note!.substring(0,25)!+'...' : customerNote?.note!
      if (await confirm("Opravdu chcete poznámku \""+ notename +"\" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání poznámky'})) {
        deleteNote()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání kontaktu
  const deleteNote = () => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      // Zavolání API   
      await axios.delete(getUrl('URL_CUSTOMER_NOTES')+customer?.id+'/notes/'+props.id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání také odebrat ze store
          rootStore.fetchCustomerOpenedNotes(customer?.id!)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          
          setShowE(true)
        });
  
      })()    
  }

  // Funkce pro změnu kontaktu
  const handleUpdate = (dateData:string, noteData:string, isNotifyData:boolean, isActiveData:boolean) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_NOTES')+customer?.id+'/notes/'+props.id, { id: props.id, customerId: customer?.id!, note: noteData, isNotify: isNotifyData, isActive: isActiveData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          // Změna dat ve Store
          rootStore.fetchCustomerOpenedNotes(customer?.id!)
          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    note: Yup.string()
    .required('Poznámka musí být vyplněna!'),
    })

    let d = new Date(customerNote?.date!);
    let year:number = d.getFullYear();
    let month:number = d.getMonth();
    let monthTxt:string = month<9 ? '0':''
    let day:number = d.getDate();
    let dayTxt:string = day<10 ? '0':''
    let todayTxt:string = String(year) + '-' + monthTxt + String(month+1) + '-' + dayTxt + String(day)


  return (
    <>
      
      
      {rightsBHSH && customer?.isActive  &&
        <>
        <ButtonGroup>
          <Button variant="outline-primary" size="sm" onClick={handleShow} title="Upravit poznámku" style={{lineHeight: '0.6'}}>
            Upravit
          </Button>
          
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport"> 
            {rightsBH &&<CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerNote?.note!.length! > 25 ? customerNote?.note!.substring(0,25)!+'...' : customerNote?.note!} historyType='customerNote' historyParam={customerNote?.id!} />}
            {rightsBH && <Dropdown.Divider />}
            <Dropdown.Item onClick={() => { handleOnClickDeleteNote()} } style={{color: 'red'}}>Smazat poznámku</Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>
        </>
      }
      {((customer?.isActive === false) && rightsBH)  &&
        <>
        <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
          <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerNote?.note!.length! > 25 ? customerNote?.note!.substring(0,25)!+'...' : customerNote?.note!} historyType='customerNote' historyParam={customerNote?.id!} />
          </DropdownButton>
        </>
      }
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání poznámky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava poznámky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Kontakt byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                date: todayTxt, 
                note: customerNote?.note, 
                isNotify: customerNote?.isNotify,
                isActive: customerNote?.isActive
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.date, values.note!, values.isNotify!, values.isActive!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
               
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="date" className='nodisplay'>
                      <Form.Row>
                        <FormLabel column lg={3}>Datum</FormLabel>
                        <Col>
                          <FormControl 
                            type="date"
                            name="date"
                            value={values.date!}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date} 
                            isInvalid={!!errors.date}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>  

                    <FormGroup controlId="note">
                      <Form.Row>
                        <FormLabel column lg={3}>Poznámka</FormLabel>
                        <Col>
                          <FormControl 
                            type="text"
                            name="note"
                            value={values.note!}
                            onChange={handleChange}
                            isValid={touched.note && !errors.note} 
                            isInvalid={!!errors.note}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.note}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>                      
                    
                    <FormGroup controlId="isNotify">
                      <Form.Row>
                        <FormLabel column lg={3}>Upozornění</FormLabel>
                        <Col md="1">
                          <FormControl 
                            type="checkbox"
                            name="isNotify"
                            size="sm"
                            className="checkbox-small"
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.isNotify}
                            
                            isValid={touched.isNotify && !errors.isNotify} 
                            isInvalid={!!errors.isNotify}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.isNotify}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>
                    <FormGroup controlId="isActive">
                      <Form.Row>
                        <FormLabel column lg={3}>Aktivní</FormLabel>
                        <Col md="1">
                          <FormControl 
                            type="checkbox"
                            name="isActive"
                            size="sm"
                            className="checkbox-small"
                            //value={values.taxAble!}
                            onChange={handleChange}
                            checked={values.isActive}
                            
                            isValid={touched.isActive && !errors.isActive} 
                            isInvalid={!!errors.isActive}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.isActive}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersNotesUpdate))

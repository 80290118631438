import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//import { getToken } from '../../../../Utils/Common'
// eslint-disable-next-line
//import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Modal, Dropdown, Badge, Spinner, Alert } from 'react-bootstrap'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'

interface OthersOperationDetailProps {
  id: string,
  showAs: string,
}

const OthersOperationsDetail: React.FC<OthersOperationDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  let operation = rootStore.getOperationById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showInfoCopy, setShowInfoCopy] = useState(false)
  const [showInfoLoadCopy, setShowInfoLoadCopy] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootStore.fetchTemplateById(props.id)
    //template = rootStore.getTemplateById(props.id)

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    if (operation?.isFinished !== true)  await rootStore.fetchStateForOperation(props.id)
    if (operation?.isError === true) rootStore.fetchErrorsForOperation(props.id)
    })()
  }


  const handleCopy = async (textToCopy:string) => {
          
    //console.log(textToCopy)
    await setShowInfoLoadCopy(true)
    console.log(showInfoLoadCopy)
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = textToCopy;
    //dummy.value = verifyCode ;
    dummy.select();
    await document.execCommand("copy");
    document.body.removeChild(dummy);

    setShowInfoCopy(true)
    setShowInfoLoadCopy(false)

    setTimeout(() => {
        
        setShowInfoCopy(false)
        //setShowInfo(false)
        //setShowForm('')
      
    }, 2800); 

  }
   
  return (
    <>
      {rights && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Detail</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Detail</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Detail operace&nbsp;
            {(operation!.stateLoaded === false) 
              ? 
              <Spinner animation='border' size="sm" /> 
              : 
              <>
                {operation!.state === 1 && <Badge variant="primary">Probíhá</Badge>}
                {operation!.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
                {operation!.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
                {operation!.state === 4 && <Badge variant="success">Dokončeno</Badge>}
              </>}  
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{wordWrap: 'break-word'}}>
                  
          <strong title='Datum a čas spuštění operace'>Spuštěno:</strong> {moment(operation!.startOn).format("D.M.YYYY H:mm")}<br />
          <strong title='Název operace'>Název:</strong> {operation!.nameForMortal}<br />
          <strong title='Uživatel, ktrý spustil operaci'>Uživatel:</strong> {operation!.userName}<br />
          <strong title='Počet položek, které bez ohledu na chyby prošly zpracováním / celkový počet položek, které byly předány ke zpracování'>Zpracováno:</strong> {operation!.finishedCount}/{operation!.totalCount}<br />
          <strong title='Datum a čas kdy bude operace považována za dokončenou, bez ohledu na její průběh'>Expirace:</strong> {moment(operation!.expireOn).format("D.M.YYYY H:mm")}<br />
          <strong>Poslední aktivita:</strong> {moment(operation!.lastActivity).format("D.M.YYYY H:mm")}<br />
          
          <strong>Chyby:</strong><br />
                    {(operation!.stateLoadedErrors === false) 
                    ? 
                    <Spinner animation='border' size="sm" /> 
                    : 
                    <>
                      {operation!.errors !== null && <>{operation?.errors.substring(0,1024)}{operation?.errors!.length! > 1024 && "..."}</>}  
                      {(operation?.errors === null || operation?.errors === '') && 'Operace neobsahuje žádné chyby'}
                    </>}
                    <br /><br />
                    {operation?.errors !== null && <><Button variant='primary' size="sm" onClick={async() => { await setShowInfoLoadCopy(true); handleCopy(operation?.errors!)}}>{showInfoLoadCopy === true ? <><Spinner animation='border'/>&nbsp;&nbsp;Zkopírovat chybu do schránky</>:'Zkopírovat chybu do schránky'}</Button></>}
                    <br /><br />

                    <Alert variant="info" show={showInfoCopy}>
            
                      <i className="fas fa-info-circle"></i>&nbsp;Údaje byly zkopírovány do schránky!
                      
                    </Alert>

                    
                      <Alert variant="info" show={showInfoLoadCopy}>
            
                        <Spinner animation='border'/>&nbsp;&nbsp;Kopíruji text do schránky...
                      
                      </Alert>
                    
                    <br />
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(OthersOperationsDetail))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUser, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Badge} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../../Utils/Confirmation";

import DialsSupportTypesUpdate from './Dials.SupportTypes.Update.part'

import { useDialsStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'


const DialsSupportTypesList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)


  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteType = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete typ kontaktu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání typu kontaktu'})) {
        deleteDialsSupportType(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  const rootDialsStore = useDialsStore()
  
  const supportTypes = rootDialsStore.dialsSupportTypes.slice().sort(function(a:any, b:any) {
    var sort1A = a.name; 
    var sort1B = b.name; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })

  const supportTypesSolution = rootDialsStore.dialsSupportTypes.slice().sort(function(a:any, b:any) {
    var sort1A = a.name; 
    var sort1B = b.name; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  }).map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  )) 
  
  //Počet záznamů
  const countData:number = Object.keys(supportTypes).length

  // Seznam pozvánek do řádků tabulky
  const linesSupportTypes = supportTypes.map((supportType) => (
    <tr key={supportType.id}>
      <td>{supportType.name}</td>
      <td>{supportTypes.find(st => st.id === supportType.solutionDefaultTypeId)?.name}</td>
      <td>{supportType.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}</td>
      
      {rights &&<td>
      
      {rightsBH &&<>
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">
          <ButtonGroup>
            <DialsSupportTypesUpdate id={supportType.id} showAs='button' />
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
              
              
              <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteType(supportType.id,supportType.name!) } }>Smazat</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
          
        </div>
      </>}
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesSupportTypesCard = supportTypes.map((supportType) => (
    <div key={supportType.id}>
    <Card>
      <Card.Body>
        {rights && <>
          {rightsBH &&<>  
            <div className="float-right">
              <ButtonGroup>
                <DialsSupportTypesUpdate id={supportType.id} showAs='button' />
                <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                  <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteType(supportType.id,supportType.name!) } }>Smazat</Dropdown.Item>
                </DropdownButton> 
              </ButtonGroup> 
            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{supportType.name}</strong><br />
          {supportType.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}
          <br />
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteDialsSupportType = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_DIALS_SUPPORT_TYPES')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootDialsStore.fetchDialsSupportTypes()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }



    
  
  // Funkce pro založení nové pozvánky
  const handleNewDialsSupportType = (nameData:string, isActiveData:boolean, solutionDefaultTypeIdData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_DIALS_SUPPORT_TYPES'), { name: nameData, isActive: isActiveData, solutionDefaultTypeId: solutionDefaultTypeIdData === '' ? null : solutionDefaultTypeIdData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootDialsStore.fetchDialsSupportTypes()
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewType = Yup.object().shape({
    name: Yup.string()
    .required('Název typu kontaktu musí být vyplněn!'),
    
  }) 
  
  return (
    <Container fluid>



      <br />


      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam typů kontaktů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesSupportTypesCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Název</th>
                <th className="jsw-table-head">Typ kontaktu řešení</th>
                <th className="jsw-table-head">Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesSupportTypes}
            </tbody>
          </Table>
        </div> 
        <br />  
        
      </div>  
      {rightsBH &&
      <Button variant="success" onClick={handleShow}>
        + Přidat typ kontaktu
      </Button>
      }

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání typu kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Typ kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Typ kontaktu byl úspěšně přidán!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewType}
              initialValues={{ 
                name: '',
                isActive: true, 
                solutionDefaultTypeId: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewDialsSupportType(values.name!, values.isActive!, values.solutionDefaultTypeId!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="solutionDefaultTypeId">
                    <Form.Row>
                        <FormLabel column lg={3}>Typ kontaktu řešení</FormLabel>
                        <Col>
                          <Form.Control as="select" 
                            name="solutionDefaultTypeId" 
                            onChange={handleChange}
                            value={values.solutionDefaultTypeId!}
                            isInvalid={!!errors.solutionDefaultTypeId}

                            
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              supportTypesSolution
                              }
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.solutionDefaultTypeId}
                          </Form.Control.Feedback>
                        </Col>
                    </Form.Row>
                  </FormGroup>
                  
                  <FormGroup controlId="isActive">
                    <Form.Row>
                      <FormLabel column lg={3}>Aktivní</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isActive"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isActive}
                          
                          isValid={touched.isActive && !errors.isActive} 
                          isInvalid={!!errors.isActive} 
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isActive}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup>                    
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám typ kontaktu...' : 'Uložit typ kontaktu'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(DialsSupportTypesList))

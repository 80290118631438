import React, { useState, useEffect }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

import { confirm } from "../../../Utils/Confirmation";

import { Formik } from 'formik'
import * as Yup from 'yup'

import { TextEditor } from "../../../Utils/draft-js/TextEditor";

import moment from 'moment'

import FilesAdd from '../../../parts/files/Files.Add.part'

import LoadFreeText from '../../../sharedComponents/loadFreeText/LoadFreeText.part'

interface SupportContactsSolutionAddUpdateProps {
  editing?:boolean,
  supportId?:string,
  solutionId?:string,
  isCustomer?:boolean,
  showAs?:string    
} 

const SupportContactsSolutionAddUpdate: React.FC<SupportContactsSolutionAddUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [insertNewContact, setInsertNewContact] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [contactLenght, setContactLenght] = useState(0)

  const [showInvoice, setShowInvoice] = useState(false)
  const [showSolve, setShowSolve] = useState(false)

  const [showSolText, setShowSolText] = useState(true);

  
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  const [interval, startInterval] = useState(setInterval(() => {},1000000))


  // Nastavení editivaného kontaktu
  const editedContact = rootStore.supports.find(s => s.id === props.supportId)

  // Seznam typů kontaktu pro rozevírací seznam
  const supportTypes = rootDialsStore.dialsSupportTypes
  .slice()
  .filter(dst => dst.isActive === true)
  .sort(function(a:any, b:any) {
    var sort1A = a.order; 
    var sort1B = b.order; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  )) 

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    clearInterval(interval)
  }

    // Seznam pracovníků JSW 
    const supportHedgehogs = rootStore.hedgehogUsers
    .filter(hu => hu.isEmailConfirmed === true)
    .slice()
    .sort(function(a:any, b:any) {
     var sort1A = a.surName; 
     var sort1B = b.surName; 
 
     if (sort1A! < sort1B!) {
       return -1;
     }
     if (sort1A! > sort1B!) {
       return 1;
     }
     return 0;
   })
    .map(hu => (
      <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
    ))  
 
   // Seznam pracovníků neaktivní 
   const supportHedgehogsInactive = rootStore.hedgehogUsers
   .filter(hu => hu.isEmailConfirmed === false)
   .slice()
   .sort(function(a:any, b:any) {
     var sort1A = a.surName; 
     var sort1B = b.surName; 
 
     if (sort1A! < sort1B!) {
       return -1;
     }
     if (sort1A! > sort1B!) {
       return 1;
     }
     return 0;
   })
   .map(hu => (
     <option key={hu.id} value={hu.id} style={{color: 'lightgrey'}}>{hu.surName}&nbsp;{hu.firstName}</option>
   )) 

  // Šipky u tlačítek pro rozevírání / sbalení
  const arrowDown = <svg className="svg-inline--fa fa-angle-double-down fa-w-10" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M143 256.3L7 120.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0L313 86.3c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.4 9.5-24.6 9.5-34 .1zm34 192l136-136c9.4-9.4 9.4-24.6 0-33.9l-22.6-22.6c-9.4-9.4-24.6-9.4-33.9 0L160 352.1l-96.4-96.4c-9.4-9.4-24.6-9.4-33.9 0L7 278.3c-9.4 9.4-9.4 24.6 0 33.9l136 136c9.4 9.5 24.6 9.5 34 .1z"></path></svg>
  const arrowUp = <svg className="svg-inline--fa fa-angle-double-up fa-w-10" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M177 255.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 351.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 425.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1zm-34-192L7 199.7c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l96.4-96.4 96.4 96.4c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9l-136-136c-9.2-9.4-24.4-9.4-33.8 0z"></path></svg>

  useEffect(() => {
    return () => {
      deleteInterval()
    }
  }, [])

  const deleteInterval = async () => {
    for (var i = 0; i < 99999; i++)
	    clearInterval(i);
  }
  
  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteSolution = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete řešení "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání řešení'})) {
        deleteSolution(id)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání pozvánky
  const deleteSolution = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      
      let dataToSendDelete:any

      let totalDurationDelete:number = 0
      editedContact?.solutions.filter(s => s.id !== id)?.map((s) => ( totalDurationDelete = totalDurationDelete+s.duration! ))
      
      dataToSendDelete = {
        id: props.supportId,
        solverUserId: editedContact?.solverUserId,
        deadLineDate: editedContact?.deadLineDate !== '' ? new Date(editedContact?.deadLineDate!).toISOString() : null,
        createDate: new Date(editedContact?.createDate!).toISOString(),
        supportTypeId: editedContact?.supportTypeId,
        isDemo: editedContact?.isDemo,
        customerId: editedContact?.customerId,
        accountNumber: editedContact?.accountNumber,
        businessName: editedContact?.businessName,
        subject: editedContact?.subject,
        request: editedContact?.request,
        supportThemeIds: editedContact?.supportThemes,
        isTrouble: editedContact?.isTrouble,
        feeling: editedContact?.feeling,
        isFinished: editedContact?.isFinished,
        duration: totalDurationDelete,
        deleteSolutionIds: [id],
        solutionCount: Object.keys(editedContact?.solutions!).length-1

        }

      // Nastavení adresy požadavku podle typu NOVÝ/EDITACE
      let urlForEditingDelete:string = ''
      urlForEditingDelete = '/'+props.supportId


      //Před smazáním dohledáme soubory
      await rootStore.batchDeleteFilesByLinkId(id)



      // Zavolání API
      await axios.post(getUrl('URL_SUPPORT_CONTACTS_ADD')+urlForEditingDelete, dataToSendDelete , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            

          sessionStorage.setItem('autoCloseModal','1')

          rootStore.fetchSupportById(props.supportId)
          sessionStorage.setItem('contactDetailEdited', 'Y')

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
   
      })()    
  }


  // Funkce po odeslání formuláře
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      //localStorage.setItem('lastSupportType',values.supportType) // Nastavení posledního použitého typu kontaktu

      let totalDuration:number = 0

      if (props.editing === true) {
        editedContact?.solutions.filter(s => s.id !== props.solutionId)?.map((s) => ( totalDuration = totalDuration+s.duration! ))
      }
      
      //console.log(startDate)

      let dataToSend:any

      if (props.editing === true) {
        dataToSend = {
          id: props.supportId,
          solverUserId: values.solverUserId,
          deadLineDate: new Date(values.deadLineDate).toISOString(),
          //solverUserId: editedContact?.solverUserId,
          //deadLineDate: editedContact?.deadLineDate !== '' ? new Date(editedContact?.deadLineDate!).toISOString() : null,
          createDate: new Date(editedContact?.createDate!).toISOString(),
          supportTypeId: editedContact?.supportTypeId,
          isDemo: editedContact?.isDemo,
          customerId: editedContact?.customerId,
          accountNumber: editedContact?.accountNumber,
          businessName: editedContact?.businessName,
          subject: editedContact?.subject,
          request: editedContact?.request,
          supportThemeIds: editedContact?.supportThemes,
          isTrouble: editedContact?.isTrouble,
          feeling: editedContact?.feeling,
          isFinished: values.isFinished,
          duration: totalDuration+values.solutionDuration,
          solutionCount: editedContact?.solutionCount,
          editSolutions: [{
            id: props.solutionId,
            supportTypeId: values.solutionSupportTypeId,
            createDate: new Date(values.solutionDate).toISOString(),
            text: values.solText,
            toInvoice: values.toInvoice,
            isData: values.isData,
            duration: values.solutionDuration ,
          }]

          }
        }
        else {
          dataToSend = {
            id: props.supportId,
            solverUserId: values.solverUserId,
            deadLineDate: new Date(values.deadLineDate).toISOString(),
            //solverUserId: editedContact?.solverUserId,
            //deadLineDate: editedContact?.deadLineDate !== '' ? new Date(editedContact?.deadLineDate!).toISOString() : null,
            createDate: new Date(editedContact?.createDate!).toISOString(),
            supportTypeId: editedContact?.supportTypeId,
            isDemo: editedContact?.isDemo,
            customerId: editedContact?.customerId,
            accountNumber: editedContact?.accountNumber,
            businessName: editedContact?.businessName,
            subject: editedContact?.subject,
            request: editedContact?.request,
            supportThemeIds: editedContact?.supportThemes,
            isTrouble: editedContact?.isTrouble,
            feeling: editedContact?.feeling,
            isFinished: values.isFinished,
            duration: values.solutionDuration === 0 ? ((moment(new Date()).diff(startDate, 'minutes') === 0 ? 1 : moment(new Date()).diff(startDate, 'minutes')) +Number(editedContact?.duration)) : editedContact?.duration+values.solutionDuration,
            newSolution: {
              supportTypeId: values.solutionSupportTypeId,
              createDate: new Date(values.solutionDate).toISOString(),
              text: values.solText,
              toInvoice: values.toInvoice,
              isData: values.isData,
              duration: values.solutionDuration === 0 ? moment(new Date()).diff(startDate, 'minutes') === 0 ? 1 : moment(new Date()).diff(startDate, 'minutes') : values.solutionDuration,
            },
            solutionCount: Object.keys(editedContact?.solutions!).length+1

            }
        }

      // Nastavení adresy požadavku podle typu NOVÝ/EDITACE
      let urlForEditing:string = ''
      urlForEditing = '/'+props.supportId

      // Zavolání API
      await axios.post(getUrl('URL_SUPPORT_CONTACTS_ADD')+urlForEditing, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          setShowInvoice(false)
          setInsertNewContact(false)
          sessionStorage.setItem('autoCloseModal','1')

          rootStore.fetchSupportById(props.supportId)
          clearInterval(interval)

          sessionStorage.setItem('contactDetailEdited', 'Y')


          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShowForm('')
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    solutionSupportTypeId: Yup.string()
      .required('Typ kontaktu řešení musí být vyplněn!'),
    
  })  
  
 // Započetí odpočítávání délky řešení kontaktu
 const startCounting = async (startD:any) => {
  await deleteInterval()
  setStartDate(startD) 
  startInterval(setInterval(() => { setContactLenght(moment(new Date()).diff(startD, 'minutes'));  console.log(startD); },10000))
}

// Startovací sequence při otevření formuláře
const startSequence = () => {
  if (editedContact?.customerId === rootStore.customerOpened[0]?.id!) {

  }
  else {

  }
  setStartDate(new Date()) 
  setInsertNewContact(true)
  //setStartDate(new Date()) 
  setShowInfo(false) 
  setShowForm('')
  startCounting(new Date()) 
   
  
}

// Odchytávání kláves po stisknutí s parametrem pro korekci indexu následujícího pole ve formuláři
const handleKeyDownNextWIthCorrection = (e:any, correction:number, handleSubmit:any) => {
  
  if (e.keyCode === 13) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
    if (e.ctrlKey) {              // CTRL + ENTER?  
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
    }

    // Nastavení další pozice kurzoru ve formuláři pokud je stisknutý ENTER
    var form = e.currentTarget.form;
    var index = Array.prototype.indexOf.call(form, e.target);
    console.log(index)
    form.elements[index+1+correction].focus()
  }
  
};

const handleKeyDownCtrlEnter = (e:any, handleSubmit:any) => {
  
  if (e.keyCode === 13 && e.ctrlKey && !loading) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
  }
 
};

// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>
          <Alert variant="success" show={showInfo}>
            <strong>Řešení úspěšně {props.editing === true ? 'upraveno' : 'přidáno'}!​</strong>
            
          </Alert>  
          {insertNewContact &&<>
          <div className={showForm} >
          <Alert variant="info" > 
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                solutionSupportTypeId: props.editing === true ?  editedContact?.solutions.find(s => s.id === props.solutionId)?.supportTypeId : sessionStorage.getItem('lastSupportType') === undefined || sessionStorage.getItem('lastSupportType') === null ? '': rootDialsStore.dialsSupportTypes.find(st => st.id === sessionStorage.getItem('lastSupportType'))?.solutionDefaultTypeId === null ? '' : rootDialsStore.dialsSupportTypes.find(st => st.id === sessionStorage.getItem('lastSupportType'))?.solutionDefaultTypeId ,
                isData: props.editing === true ? editedContact?.solutions.find(s => s.id === props.solutionId)?.isData : false,
                toInvoice: props.editing === true ? editedContact?.solutions.find(s => s.id === props.solutionId)?.toInvoice : false,
                solutionDuration: props.editing === true ? editedContact?.solutions.find(s => s.id === props.solutionId)?.duration : 0,
                solText: props.editing === true ? editedContact?.solutions.find(s => s.id === props.solutionId)?.text : '',
                solutionDate: props.editing === true ?  moment(editedContact?.solutions.find(s => s.id === props.solutionId)?.createDate).format("YYYY-MM-DDTHH:mm"):moment(startDate).format("YYYY-MM-DDTHH:mm"),
                
                isFinished: editedContact?.isFinished,

                solverUserId: editedContact?.solverUserId,
                deadLineDate: moment(editedContact?.deadLineDate).format("YYYY-MM-DDTHH:mm"),
              
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values!)              
                  setSubmitting(false);
                }, 1);
              }}
              
              
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                    <strong>Řešení kontaktu</strong><br />
                    </Form.Row>
                    <Form.Row>
                      <FormGroup as={Col} md={3} sm={4} xs={12} controlId="solutionDate">
                        
                        <FormLabel>Datum a čas</FormLabel>
                        
                          <FormControl 
                            type="datetime-local"
                            name="solutionDate"
                            value={values.solutionDate!}
                            onChange={handleChange}
                            isValid={touched.solutionDate && !errors.solutionDate} 
                            isInvalid={!!errors.solutionDate}
                            onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.solutionDate}
                          </Form.Control.Feedback>
                      </FormGroup>

                    <FormGroup as={Col} md={3} sm={4} xs={12} controlId="solutionSupportTypeId">
                      <FormLabel>Typ kontaktu</FormLabel>
                        <Form.Control as="select" 
                          name="solutionSupportTypeId" 
                          onChange={handleChange}
                          value={values.solutionSupportTypeId!}
                          isInvalid={!!errors.solutionSupportTypeId}

                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            supportTypes
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.solutionSupportTypeId}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup controlId="isData" style={{minWidth: '40px', paddingLeft: '15px', paddingRight: '15px'}}>
                      <FormLabel style={{textAlign: 'center' }}>Data</FormLabel>
                      
                        <FormControl 
                          type="checkbox"
                          name="isData"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isData}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.isData && !errors.isData} 
                          isInvalid={!!errors.isData}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isData}
                        </Form.Control.Feedback>
                    </FormGroup>
                    
                    <FormGroup as={Col} lg={2} md={2} sm={3} xs={4}  controlId="solutionDuration">
                      <FormLabel>Doba ({contactLenght}  min.)</FormLabel>
                        <FormControl 
                          type="number"
                          name="solutionDuration"
                          value={values.solutionDuration!}
                          onChange={handleChange}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.solutionDuration && !errors.solutionDuration} 
                          isInvalid={!!errors.solutionDuration}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.solutionDuration}
                        </Form.Control.Feedback>
                    </FormGroup>
                    </Form.Row> 
                    <Form.Row>
                    <FormGroup as={Col}  controlId="solText">
                      <FormLabel>Popis řešení</FormLabel>
                        {showSolText &&
                        <TextEditor
                          setFieldValue={(val) => setFieldValue("solText", val)}
                          value={values.solText!}
                        />}
                        <div>
                          <LoadFreeText 
                            setFieldValue={setFieldValue} 
                            showFormField={setShowSolText} 
                            pole="solText" 
                            originalText={values.solText!} 
                            handleKeyDown={handleKeyDownCtrlEnter}
                            handleSubmit={handleSubmit}    
                            />
                        </div>
                        <div className="invalid-feedback" style={{display: 'block'}}>{errors.solText}</div>
                        <Form.Control.Feedback type="invalid">
                          {errors.solText}
                        </Form.Control.Feedback>
                    </FormGroup>
                    </Form.Row>
                    <FormGroup controlId="isFinished">
                      <Form.Row>
                      <FormLabel  column lg={3}>Nastavit kontakt jako vyřešený</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isFinished"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isFinished}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.isFinished && !errors.isFinished} 
                          isInvalid={!!errors.isFinished}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isFinished}
                        </Form.Control.Feedback>
                        </Col>
                        </Form.Row>
                    </FormGroup>
                    
                    {<Button 
                      variant='secondary' 
                      size='sm' 
                      title='Fakturovat řešení'
                      style={{lineHeight: '1.15', minWidth: '230px', marginBottom: '10px'}} 
                      onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                      onClick={(e) => { setShowInvoice(!showInvoice); }}
                      >{!showInvoice ? <span><div style={{float:'right'}}>{arrowDown}</div>Fakturace</span>:<><div style={{float:'right'}}>{arrowUp}</div>Fakturace</>}
                    </Button>}
                    <br />
                    
                    {showInvoice &&
                    <>
                    <Form.Row>
                    <strong>Podklady pro fakturaci</strong><br />
                    </Form.Row>
                    <FormGroup controlId="isFinished">
                      <Form.Row>
                      <FormLabel  column lg={3}>Fakturovat řešení</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="toInvoice"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.toInvoice}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.toInvoice && !errors.toInvoice} 
                          isInvalid={!!errors.toInvoice}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.toInvoice}
                        </Form.Control.Feedback>
                        </Col>
                        </Form.Row>
                    </FormGroup>

                    </>}


                    <>
                      {<Button 
                        variant='secondary' 
                        size='sm' 
                        title='Předat kontakt (Alt+J)'
                        style={{lineHeight: '1.15', minWidth: '230px', marginBottom: '10px'}} 
                        onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                        onClick={(e) => { setShowSolve(!showSolve); }}
                        >{!showSolve ? <span><div style={{float:'right'}}>{arrowDown}</div>Odpovídá / termín</span>:<><div style={{float:'right'}}>{arrowUp}</div>Odpovídá / termín</>}
                      </Button>}
                      <br />
                    </>

                    {showSolve &&
                    <>
                    <Form.Row>
                    <strong>Odpovědná osoba a termín</strong><br />
                    </Form.Row>
                    <Form.Row>
                    <FormGroup as={Col} md={3} sm={4} xs={12} controlId="solverUserId">
                      <FormLabel>Osoba</FormLabel>
                        <Form.Control as="select" 
                          name="solverUserId" 
                          onChange={(e) => {handleChange(e); if (e.target.value !== '') { setFieldValue('isFinished', false) } }}
                          value={values.solverUserId!}
                          isInvalid={!!errors.solverUserId}

                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            supportHedgehogs
                            }
                            <option value="">--- NEAKTIVNÍ UŽIVATELÉ ---</option>
                            {
                            supportHedgehogsInactive
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.solverUserId}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={3} sm={5}  controlId="deadLineDate">
                      <FormLabel>Termín</FormLabel>
                        <FormControl 
                          type="datetime-local"
                          name="deadLineDate"
                          value={values.deadLineDate!}
                          onChange={handleChange}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.deadLineDate && !errors.deadLineDate} 
                          isInvalid={!!errors.deadLineDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.deadLineDate}
                        </Form.Control.Feedback>
                    </FormGroup> 
                    </Form.Row> 
                    </>
                    }         
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám...' : 'Uložit změny'}</Button>
                  
                </Form>

              )}
            </Formik>  
            
            </Alert>
          </div>
          </>}
          </>



return ( 
  <>
  <>
  {props.editing===true?
  <ButtonGroup style={{}}>
    <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Upravit</>:'+ Pořídit nové řešení'}</Button>
    
    <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
          <FilesAdd id={props.solutionId} type='support' masterId={props.supportId} showAs='drop' />
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => { handleOnClickDeleteSolution(props.solutionId, '') } } style={{color: 'red'}} >Smazat řešení</Dropdown.Item>
          
    </DropdownButton>
  </ButtonGroup>
  :
  props.showAs !== 'drop' ?
  <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Upravit</>:'+ Pořídit nové řešení'}</Button>
  :
  <><Dropdown.Item onClick={() => {startSequence()}}>Přidat nové řešení</Dropdown.Item></>
  
  }
  </>
  
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editing === true ? 'Úprava' : 'Přidání'}&nbsp;řešení
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(SupportContactsSolutionAddUpdate))

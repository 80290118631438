import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button, Alert, Modal, Dropdown, Table } from 'react-bootstrap'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../Utils/Confirmation"

import { useStore } from '../../store'
import { observer } from 'mobx-react'


interface CustomersOtherActionsActivateLicenseProps {
  id: string,
}

const CustomersOtherActionsActivateLicense: React.FC<CustomersOtherActionsActivateLicenseProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  const rootStore = useStore()

  // Seznam neaktivních licencí do tabulky
  const licensesInTable = rootStore.getCustomerOpenedLicensesNonActive().map(license => (
    <tr key={license.license.id}>
      <td>{license.license.program.name} {license.license.programVersion.version}</td>
      <td><Button variant="danger" size="sm" onClick={() => { handleOnClickActivate(license.license.id!, license.license.program.name+' '+license.license.programVersion.version) } }>Aktivovat</Button></td>
    </tr>
  ))
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před akcí
  const handleOnClickActivate = (licenseId: string, licenseName: string) => {
    (async () => {
      if (await confirm("Opravdu chcete licenci "+licenseName+" aktivovat?",'Ano, aktivovat', 'Ne', {title: 'Potvrzení aktivace licence'})) {
        handleActivateLicense(licenseId,true)
      } else {
      
      }  
    })()
  }
  
  // Funkce pro odeslání e-mailu
  const handleActivateLicense = (licenseIdData: string, isActiveData: boolean) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_LICENSES_STATE')+licenseIdData+"/state", { licenseId: licenseIdData, customerId: localStorage.getItem('openedCustomerId')!, isActive: isActiveData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          rootStore.fetchCustomerOpenedLicenses(props.id)
          
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }
   
  return (
    <span>
      <div className={rights ? '' : 'nodisplay'}>
          <Dropdown.Item onClick={() => { handleShow() } }>Aktivace licence</Dropdown.Item>
      </div>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        scrollable
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Aktivace licence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Licence byla úspěšně aktivována!​</strong>
          </Alert>  
          {error}
          <div className={showForm} >
            <Table responsive hover>
              <thead>
                <tr className="jsw-table-head">
                  <th className="jsw-table-head">Licence</th>
                  <th className="jsw-table-head">Akce</th>
                </tr>
              </thead>
              <tbody>{licensesInTable}</tbody>
            </Table>
             
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsActivateLicense))

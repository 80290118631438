import { types } from 'mobx-state-tree'


export const CustomerOfferCount = types
  .model({
    offerId: types.optional(types.string, ''),
    offerState: types.optional(types.string, ''),
    distributionDate: types.optional(types.string, ''),
  })
  .views(self => ({

  }))
  .actions(self => ({
    
  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { deadLineBadgeState } from '../../../Utils/Common'

import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton } from 'react-bootstrap'

import { useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

import SupportContactsAdd from './Support.Contacts.Add.part'
import SupportContactsSolveUpdate from './Support.Contacts.SolveUpdate.part'
import SupportContactsSolutionAddUpdate from './Support.Contacts.SolutionAddUpdate.part'

import moment from 'moment'
import FilesList from '../../../parts/files/Files.List.part'
import FilesAdd from '../../../parts/files/Files.Add.part'
//import FilesAdd from '../../../parts/files/Files.Add.part'

interface SupportContactsDetailProps {
  isCustomer?:boolean, 
  customerId?:string
  editing?:boolean,
  supportId?:string,
  fromList?:boolean,
  fromSumMenu?:boolean    
}

const SupportContactsDetail: React.FC<SupportContactsDetailProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)

  const [insertNewContact, setInsertNewContact] = useState(false)
// eslint-disable-next-line
  const [selectedCustomer, setSelectedCustomer] = useState('')

  


  
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  // Nastavení editivaného kontaktu
  const editedContact = rootStore.supports.find(s => s.id === props.supportId)
  const selectedCustomerStored = rootStore.customerListAll.find(cla => cla.id === editedContact?.customerId)?.businessName! !== undefined ? rootStore.customerListAll.find(cla => cla.id === editedContact?.customerId)?.businessName! : ''


  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    
    if (sessionStorage.getItem('contactDetailEdited') === 'Y') {
      //sessionStorage.setItem('supportSearchPage', '1')
      
      
       rootDialsStore.addHelper('contacts')
       console.log(Number(sessionStorage.getItem('supportSearchPage') !== null ? sessionStorage.getItem('supportSearchPage') : 0 ))
       rootStore.fetchSupports(Number(sessionStorage.getItem('supportSearchPage') !== 'null' ? sessionStorage.getItem('supportSearchPage') : 0 ) === 0 ? 1 : Number(sessionStorage.getItem('supportSearchPage')) ,props.isCustomer)
       rootDialsStore.removeHelper('contacts')
       sessionStorage.setItem('contactDetailEdited', 'N')
       
    }

    sessionStorage.removeItem('autoCloseModal')
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    setLoading(true)
    sessionStorage.setItem('contactDetailEdited', 'N')

    if (editedContact?.customerId === rootStore.customerOpened[0]?.id!) {
      setSelectedCustomer(rootStore.customerOpened[0]?.businessName!)
    }
    else {
      setSelectedCustomer(rootStore.customerListAll.find(cla => cla.id === editedContact?.customerId)?.businessName!)
    }
    
  
  setInsertNewContact(true)

  await rootStore.fetchSupportById(props.supportId)
  await setLoading(false)
  await rootStore.fetchFilesForSupport(props.supportId)

  

}


// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>

          {insertNewContact &&<>
          <div>

            <Row>
              <Col lg={{span: 8, order :1}}>
                <h4>Požadavek</h4>
                <Alert variant='secondary'>

                <Row>
                  <Col md={3} lg={2}><strong>Pořízeno:</strong></Col>
                  <Col>{moment(editedContact?.createDate).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Pořídil:</strong></Col>
                  <Col>{rootStore.hedgehogUsers.find(hu => hu.id === editedContact?.authorId)?.fullName}</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Upraveno:</strong></Col>
                  <Col>{moment(editedContact?.lastEditDate).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Upravil:</strong></Col>
                  <Col>{rootStore.hedgehogUsers.find(hu => hu.id === editedContact?.userId)?.fullName}</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Typ kontaktu:</strong></Col>
                  <Col>{rootDialsStore.dialsSupportTypes.find(dst => dst.id === editedContact?.supportTypeId)?.name}</Col>
                </Row>    
                <Row>
                  <Col md={3} lg={2}><strong>Osoba:</strong></Col>
                  <Col>{editedContact?.businessName}</Col>
                </Row>
                {editedContact?.isTrouble === true &&
                <Row>
                  <Col md={3} lg={2}><strong>Problém:</strong></Col>
                  <Col>{editedContact?.isTrouble === true ? 'Ano':'Ne'}</Col>
                </Row>
                }
                <Row>
                  <Col md={3} lg={2}><strong>Celková doba:</strong></Col>
                  <Col>{editedContact?.duration}&nbsp;min.</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Témata:</strong></Col>
                  <Col>{editedContact?.supportThemes?.slice().map((st,index) => ( <Badge key={index} variant='warning' style={{marginRight: '10px'}}>{rootDialsStore.dialsSupportThemes.find(dst => dst.id === st)?.name}</Badge> ))}  </Col>
                </Row>
                <br />
                <strong style={{fontSize: '120%'}}>{editedContact?.subject}</strong><br />
                <br />
                {editedContact?.request !== '' &&
                <>
                <div className="content" dangerouslySetInnerHTML={{__html: editedContact?.request!}}></div>
                
                </>
                }
                <FilesList id={editedContact?.id!} type='support' masterId={editedContact?.id!} />
                <ButtonGroup style={{}}>
                  <SupportContactsAdd editing={true} supportId={editedContact?.id} isCustomer={props.isCustomer} />
                  <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                    <FilesAdd id={props.supportId} type='support' masterId={props.supportId} showAs='drop' />
                  </DropdownButton>
                </ButtonGroup>
                </Alert>
              </Col>
              <Col lg={{span: 4, order :2}}>
                <>
                  <h4>Odpovídá / termín</h4>
                  <Alert variant='secondary'>
                  <Row>
                    <Col md={3} lg={2}><strong>Osoba:</strong></Col>
                    <Col>{rootStore.hedgehogUsers.find(hu => hu.id === editedContact?.solverUserId)?.fullName}</Col>
                  </Row>
                  <Row>
                    <Col md={3} lg={2}><strong>Termín:</strong></Col>
                    <Col>{moment(editedContact?.deadLineDate).format("DD.MM.YYYY HH:mm")}</Col>
                  </Row>
                  <SupportContactsSolveUpdate supportId={editedContact?.id} />
                  </Alert>
                </>
              </Col>
            </Row>
              
                <>
                  <div style={{float: 'right'}}></div> 
                  <h4>Řešení</h4>
                  <SupportContactsSolutionAddUpdate supportId={editedContact?.id} /><br /><br />
                  {loading === true 
                    ? 
                    <><Spinner animation='border'  />&nbsp;</>
                    : 
                    <>
                      <>
                        {Object.keys(editedContact?.solutions!).length === 0 &&<>Seznam řešení neobsahuje žádné záznamy.</>}
                      </>

                      {editedContact?.solutions.slice().sort(function(a:any, b:any) {
                        var sort1A = new Date(a.createDate.replace(' ', 'T')); 
                        var sort1B = new Date(b.createDate.replace(' ', 'T')); 

                        if (sort1A! < sort1B!) {
                          return 1;
                        }
                        if (sort1A! > sort1B!) {
                          return -1;
                        }
                        return 0;
                      }).map(ss => (
                
                        <Alert variant='info' key={ss.id}>
                        <Row>
                          <Col md={3} lg={2}><strong>Datum a čas:</strong></Col>
                          <Col>{moment(ss.createDate).format("DD.MM.YYYY HH:mm")}</Col>
                        </Row>
                        <Row>
                          <Col md={3} lg={2}><strong>Pořídil:</strong></Col>
                          <Col>{rootStore.hedgehogUsers.find(hu => hu.id === ss?.authorId)?.fullName}</Col>
                        </Row>
                        <Row>
                          <Col md={3} lg={2}><strong>Upravil:</strong></Col>
                          <Col>{rootStore.hedgehogUsers.find(hu => hu.id === ss?.userId)?.fullName}</Col>
                        </Row>
                        <Row>
                          <Col md={3} lg={2}><strong>Typ kontaktu:</strong></Col>
                          <Col>{rootDialsStore.dialsSupportTypes.find(dst => dst.id === ss.supportTypeId)?.name}</Col>
                        </Row>
                        <Row>
                          <Col md={3} lg={2}><strong>Doba:</strong></Col>
                          <Col>{ss.duration}&nbsp;min.</Col>
                        </Row>
                        
                        <Row>
                          <Col md={3} lg={2}><strong>Data:</strong></Col>
                          <Col>{ss.isData === true ? 'Ano':'Ne'}</Col>
                        </Row>
                        
                        <Row>
                          <Col md={3} lg={2}><strong>Fakturovat:</strong></Col>
                          <Col>{ss.toInvoice === true ? 'Ano':'Ne'}</Col>
                        </Row>
                        <>
                          <br />
                          <div className="content" dangerouslySetInnerHTML={{__html: ss?.text!}}></div>
                          <br />
                        </>  
                        
                        <FilesList id={ss.id} type='support' masterId={editedContact?.id} />
                        



                        <SupportContactsSolutionAddUpdate editing={true} supportId={editedContact?.id} solutionId={ss.id} />
                        </Alert>
                
                      ))}
              
                    </>
                  }
                </>
              
          
          </div>
          </>}

          </>



return ( 
  <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Detail kontaktu</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail kontaktu' onClick={() => {startSequence()}}>{editedContact?.subject}</span>
      </>
    :
    <>
      <Button variant='outline-primary' size="sm" style={{lineHeight: '0.6'}} onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Detail kontaktu' ><i className="fas fa-search"></i>&nbsp;</span>Detail</Button>
    </>
    }
  
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
        <div className="showMobile">
          Detail kontaktu <br />
          {editedContact?.isDemo === true && editedContact.accountNumber === '' ? 'Demoverze ':''}{editedContact?.accountNumber} - {selectedCustomerStored.length > 25 ? selectedCustomerStored.substring(0,25) + '...' : selectedCustomerStored }
          {editedContact?.isFinished === false &&<><br /><Badge variant={deadLineBadgeState(editedContact?.deadLineDate!.replace(' ', 'T'))} style={{marginLeft: '10px'}}>Nevyřešeno</Badge></>}  
        </div>
        <div className="showWeb">
          Detail kontaktu <Badge variant='secondary'>{editedContact?.isDemo === true && editedContact.accountNumber === '' ? 'Demoverze ':''}{editedContact?.accountNumber} - {selectedCustomerStored}</Badge>
          {editedContact?.isFinished === false &&<Badge variant={deadLineBadgeState(editedContact?.deadLineDate!.replace(' ', 'T'))} style={{marginLeft: '10px'}}>Nevyřešeno</Badge>}  
        </div>  
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(SupportContactsDetail))

import { types } from 'mobx-state-tree'


export const AppProduelTokens = types
  .model({
    id: types.optional(types.string, ''),
    companyId: types.optional(types.string, ''),
    token: types.optional(types.string,''),
    name: types.optional(types.string,''),
    createDate: types.optional(types.string, ''),
    isRevoked: types.optional(types.boolean, false),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    
  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, Spinner } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'


interface AccountUpdateProps {
  id: string,
  email: string,
  action: string,
  showAs: string,
  fromAccounts?: boolean
}

const AccountUpdateDisable: React.FC<AccountUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const [companies, setCompanies] = useState(<></>)

  type CompaniesArray = {id: string, accountNumber: string, businessName: string, companyRegistrationNumber: string, taxIdentificationNumber: string, isTaxAble: boolean, isActive: boolean}
  let companiesArray = new Array<CompaniesArray>()

  // Připojení store
  const rootStore = useStore()
  
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async() => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    setLoadingCompanies(true)

    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }  
    // Zavolání API
    await axios.get(getUrl('URL_ACCOUNT')+props.id+'/customers?page=1&pagesize=99999', { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        
        response.data.items.map((item: {id: string, accountNumber: string, businessName: string, companyRegistrationNumber: string, taxIdentificationNumber: string, isTaxAble: boolean, isActive: boolean}) => {
          companiesArray.push({id: item.id, accountNumber: item.accountNumber, businessName: item.businessName, companyRegistrationNumber: item.companyRegistrationNumber, taxIdentificationNumber: item.taxIdentificationNumber, isTaxAble: item.isTaxAble, isActive: item.isActive})
          console.log(companiesArray)
          return true
        }
        
        )
        
        setCompanies(<>{companiesArray.map(c => (<>{c.accountNumber} / {c.businessName}<br /></>))}</>)
        setLoadingCompanies(false)
      }).catch(error => {
        setLoadingCompanies(false)
      });

    //setShow(true)  
  }


  // Funkce pro změnu kontaktu
  const handleUpdate = (noteData:string) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      let url:string = getUrl('URL_ACCOUNT')+props.id+'/'
      if (props.action==='E') {
        url = url + 'enable'
      } else {
        url = url + 'disable'
      }


      // Zavolání API
      await axios.post(url, { note: noteData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          // Změna dat ve Store
          if (props.fromAccounts === true) {
            rootStore.fetchAccounts( Number(sessionStorage.getItem('accountsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('accountsSearchPage')))
          }
          else {
          rootStore.fetchCustomerUsers()
          }
          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')

           
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          

          // Po 2800 ms celé modální okno zavřeme
          /*
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);*/

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    note: Yup.string()
    .required('Důvod musí být vyplněn!'),
    })



  return (
    <>
      
      
      {props.showAs === 'drop'  && 
        <>
        <Dropdown.Item style={{color: 'red'}} onClick={handleShow}>{props.action==='E'?'Odblokovat účet':'Zablokovat účet'}</Dropdown.Item>
        </>
      }
      


      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.action==='E'?'Odblokování účtu':'Zablokování účtu'} {props.email}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Účet {props.email} byl úspěšně {props.action==='E'?'odblokován':'zablokován'}!​</strong>
            
          </Alert>  
          <div className={showForm} >
            {props.action==='E'?'':<>Akce zablokování účtu se použije buď pro dočasné nebo trvalé zamezení možnosti se vybranému účtu přihlašovat do celého zákaznického portálu. Účet je však možné kdykoliv zpětně odblokovat.<br /><br /></>}
            <strong>Uživatel má přístup do následujících firem:</strong><br />{loadingCompanies ? <Spinner animation='border' /> : <>{companies}</>}
            <br />
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                note: '', 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.note!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
               
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <FormGroup controlId="note">
                      <Form.Row>
                        <FormLabel column lg={3}>Důvod</FormLabel>
                        <Col>
                          <FormControl 
                            type="text"
                            name="note"
                            value={values.note!}
                            onChange={handleChange}
                            isValid={touched.note && !errors.note} 
                            isInvalid={!!errors.note}
                          />
                          
                          <Form.Control.Feedback type="invalid">
                            {errors.note}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>                      
                    
                    
                  {error}
                  <br />
                  <Button type="submit" variant={props.action==='E'?'success':'danger'} disabled={!(isValid) || loading} block>{loading ? props.action==='E'?'Odblokování účtu...':'Zablokování účtu...' : props.action==='E'?'Odblokovat účet':'Zablokovat účet'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(AccountUpdateDisable))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal, Row, Badge } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useEmailingStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface DatafiltersCustomersCustomListUpdateProps {
  id: string,
  showAs: string,
}

const DatafiltersCustomersCustomListUpdate: React.FC<DatafiltersCustomersCustomListUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsEM:boolean = ((localStorage.getItem('Emailing')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()

  let item = rootEmailingStore.getDatafiltersCustomersCustomListById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    await rootEmailingStore.deleteEmailingConditions()
    item = await rootEmailingStore.getDatafiltersCustomersCustomListById(props.id)
    if (!(item!.data === undefined || item!.data === null || item!.data === '')) await rootEmailingStore.fetchEmailingConditionsFromData(JSON.parse(item!.data))
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (nameData:string, noteData:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST')+'/'+props.id, { id: props.id, name: nameData, note: noteData, data: item?.data }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootEmailingStore.fetchDatafiltersCustomersCustomList( Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  const conditions = rootEmailingStore.emailingConditions
 
  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    name: Yup.string()
      .required('Název musí být vyplněn!')
    
  })   
  return (
    <span >
      {rightsEM && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava uživatelského dotazu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Uživatelský dotaz byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                name: item?.name, 
                note: item?.note, 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.name!, values.note!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name!}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl as="textarea" style={{height: "150px"}}
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          //autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <Row>
                    
                    <Col>
                    { !(item?.data === undefined || item?.data === null || item?.data === '') ?
                      <Alert variant="info">
                        <strong>Podmínky</strong><br />
                        <br />
                        {conditions.map((item,index) => (
                          <div key={item.id}>
                            {index > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} >{item.operatorBefore}</Badge><br /></>}
                            {item.isSystem && <div style={{backgroundColor: '#deeafc', padding: '10px', borderRadius: '10px'}}>
                                <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                 {item.includedConditions?.map((itemInside,indexInside) => (
                                  <div key={itemInside.id}>
                                    {indexInside > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} >{itemInside.operatorBefore}</Badge><br /></>}

                                    {itemInside.isSystem && <div style={{backgroundColor: '#c0d7fa', padding: '10px', borderRadius: '10px'}}>
                                      <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                      {itemInside.includedConditions?.map((itemInside2,indexInside2) => (
                                        <div key={itemInside2.id}>
                                          {indexInside2 > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}}>{itemInside2.operatorBefore}</Badge><br /></>}

                                          {itemInside2.isSystem && <div style={{backgroundColor: '#adceff', padding: '10px', borderRadius: '10px'}}>
                                            <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                            {itemInside2.includedConditions?.map((itemInside3,indexInside3) => (
                                              <div key={itemInside3.id}>
                                                {indexInside3 > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} >{itemInside3.operatorBefore}</Badge><br /></>}

                                                {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside3.id )?.name}{itemInside3.props1 !== null &&<>&nbsp;({itemInside3.props1}{itemInside3.props2 !== null &&<>-{itemInside3.props2}</>})</>}
                                                
                                              </div>
                                            ))}
                                            <br />
                                            
                                            

                                            </div><strong>)</strong>
                                            
                                              
                                          </div>}


                                          {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside2.id )?.name}{itemInside2.props1 !== null &&<>&nbsp;({itemInside2.props1}{itemInside2.props2 !== null &&<>-{itemInside2.props2}</>})</>}
                                          
                                        </div>
                                      ))}
                                      <br />
                                      

                                      </div><strong>)</strong>
                                      
                                        
                                    </div>}

                                    {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside.id )?.name}{itemInside.props1 !== null &&<>&nbsp;({itemInside.props1}{itemInside.props2 !== null &&<>-{itemInside.props2}</>})</>}
                                    
                                  </div>
                                 ))}
                                <br />
                                

                                </div><strong>)</strong>
                                
                                  
                              </div>}
                            {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === item.id )?.name}{item.props1 !== null &&<>&nbsp;({item.props1}{item.props2 !== null &&<>-{item.props2}</>})</>}
                            
                            </div>
                        ))}

                        <br />
                        
                      </Alert>
                      :
                      <Alert variant="info">
                        <strong>Podmínky</strong><br />
                        <br />
                        <i>Tento filtr byl vytvořen mimo prostředí portálu, proto jeho podmínky nelze vizualizovat.</i>
                      </Alert>}  
                    </Col>
                  </Row>
                  
                                   
                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(DatafiltersCustomersCustomListUpdate))

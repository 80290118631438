import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'
// eslint-disable-next-line
import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Table, Card } from 'react-bootstrap'
// eslint-disable-next-line
import { useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'
import Pagination from 'react-js-pagination'

import moment from 'moment'
import NumberFormat from 'react-number-format'

import SubmissionPacketDetail from './Submissions.Packet.Detail.part'
import SubmissionPacketEdit from './Submissions.Packet.Edit.part'
import SubmissionPacketReturn from './Submissions.Packet.Return.part'
import SubmissionsPacketCopy from './Submissions.Packet.Copy.part'

import SupportContactsCustomerInfo from '../../support/components/Support.Contacts.CustomerInfo.part'
//import CustomersOtherActionsCashRepackingPacket from '../../../parts/customersOtherActions/Customers.OtherActions.RepackingPacket.part'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation";

interface SubmissionsDetailProps {
  submissionId?:string,
  fromSubMenu?:boolean,
  asLink?:boolean,
  linkText?:string,
  packetId?:string,
  fromExternal?:boolean,  
  setPageLoading?:any,
}

const SubmissionsDetail: React.FC<SubmissionsDetailProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

  const [loadingDocumentTypes, setLoadingDocumentTypes] = useState(false)
  const [loadingPackets, setLoadingPackets] = useState(false)

  const [showDetail, setShowDetail] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  // eslint-disable-next-line
  const [refreshAfterClose, setRefreshAfterClose] = useState(false)

  const [sId, setSId] = useState('')

  type PacketsSearchArray = {filterName: string, filterValue: string, displayValue: string}
  let packetsSearchArray = new Array<PacketsSearchArray>()
    
  // Připojení store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()

  // Nastavení editivaného kontaktu
  //const submission = rootStore.getSubmissionById(props.submissionId)
  const submission = rootStore.getSubmissionById(sId)

  const customerListAllF = rootStore.customerListAll

  const handleClose = () => { 
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    sessionStorage.removeItem('autoCloseModal')
    
    if (refreshAfterClose === true && props.fromExternal !== true) {
      props.setPageLoading(true)
      await rootStore.fetchSubmissions( Number(sessionStorage.getItem('submissionsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionsSearchPage')))
      props.setPageLoading(false)
    }
    
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    localStorage.removeItem('submissionPacketsSearchArray')
    sessionStorage.removeItem('submissionPacketsSearchPage')
    setLoading(true)
    setLoadingDocumentTypes(true)
    setLoadingPackets(true)

    let submissionId:string = ''

    if (props.fromExternal === true) {

      packetsSearchArray.push({filterName: 'startFilter', filterValue: '(Id~eq~'+props.packetId+')', displayValue: "Id zásilky" })
      localStorage.setItem('submissionPacketsSearchArray',JSON.stringify(packetsSearchArray))

      sessionStorage.setItem('submissionPacketsSearchPage', '1')

      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      

      await axios.get(getUrl('URL_PACKETS')+'/'+props.packetId,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        } }).then(response => {

        submissionId = response.data.submissionId
        setSId(response.data.submissionId)

      }).catch(error => {
        
      });

      await axios.get(getUrl('URL_SUBMISSIONS')+'/'+submissionId,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        } }).then(response => {

        rootStore.addSubmissionFromData(response.data)

      }).catch(error => {
        
      });


      
    }
    else {
      submissionId = props.submissionId
      setSId(props.submissionId)
    }

    setShowDetail(true)
    if (Object.keys(rootStore.customerListAll).length < 100) await rootStore.fetchCustomerListAll('', '', 1)
  //await rootStore.fetchPacketsForSubmission(props.fromExternal === true ? submissionId : props.submissionId,1)
  await rootStore.fetchPacketsForSubmission(submissionId,1)
  await setLoadingPackets(false)
  //await rootStore.fetchDocumentTypesForSubmission(props.fromExternal === true ? submissionId : props.submissionId)
  await rootStore.fetchDocumentTypesForSubmission(submissionId)
  await setLoadingDocumentTypes(false)
  await setLoading(false)

}

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete zásilku "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání zásilky'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteReturn = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete zrušit vrácení zásilky "+text+"?",'Ano, zrušit vrácení', 'Ne', {title: 'Zrušení vrácení zásilky'})) {
        deleteReturn(id)
      } else {
      
      }  
    })()
  }  

// Funkce pro smazání pozvánky
const deleteItem = (id:string) => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    // Zavolání API
    
    await axios.delete(getUrl('URL_PACKETS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        // Po smazání přenačíst detail distribuce
        //axios.get(getUrl('URL_SUBMISSIONS')+'/'+props.submissionId,{
        axios.get(getUrl('URL_SUBMISSIONS')+'/'+sId,{  
          headers: {
            'Authorization': 'Bearer ' + aToken
          }  }).then(response => {

            //rootStore.submissions.find(s => s.id === props.submissionId)!.setValuesFromData(response.data)
            rootStore.submissions.find(s => s.id === sId)!.setValuesFromData(response.data)
          
        }).catch(error => {
          
        });
        
        // Po smazání přenačíst seznam zásilek
        //rootStore.fetchPacketsForSubmission(props.submissionId,Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
        rootStore.fetchPacketsForSubmission(sId,Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setShowE(true)          
      });
 
    })()    
}

// Funkce pro smazání pozvánky
const deleteReturn = (id:string) => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    // Zavolání API
    
    await axios.delete(getUrl('URL_PACKETS')+'/'+id+'/return', { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        // Po smazání přenačíst seznam pozvánek
        //rootStore.fetchPacketsForSubmission(props.submissionId,Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
        rootStore.fetchPacketsForSubmission(sId,Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setShowE(true)          
      });
 
    })()    
}

const handlePageChange = (pageNumber:number) => {
  (async () => { 
    setLoadingPackets(true);
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    sessionStorage.setItem('submissionPacketsSearchPage', String(pageNumber))
    // Předat hledanou hodnotu action pro načtení zákazníků
    //await rootStore.fetchPacketsForSubmission(props.submissionId, pageNumber)
    await rootStore.fetchPacketsForSubmission(sId, pageNumber)
    setLoadingPackets(false);  
  })()
}

const handleOrderChange = (order:string) => {
  (async () => { 
    setLoadingPackets(true);
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    localStorage.setItem('submissionPacketsOrderBy', order)
    // Předat hledanou hodnotu action pro načtení zákazníků
    sessionStorage.setItem('submissionPacketsSearchPage', String(1))
    //await rootStore.fetchPacketsForSubmission(props.submissionId, 1)
    await rootStore.fetchPacketsForSubmission(sId, 1)
    setLoadingPackets(false);  
  })()
}

const packetsCount:number = !(submission?.packets === null || submission?.packets === undefined) ? Object.keys(submission?.packets!).length : 0

const packetsListTable = !(submission?.packets === null || submission?.packets === undefined) ? submission?.packets!.map((item) => (
  <tr key={item.id}>
    <td>
    <a target='_blank' href={"https://www.postaonline.cz/trackandtrace/-/zasilka/cislo?parcelNumbers="+item.packetCode} rel="noopener noreferrer">
      {item.packetCode}
    </a>
      </td>
    <td>
      {item.customerId !== null && <SupportContactsCustomerInfo customerId={item.customerId} isActiveCustomer={customerListAllF.find(cla => cla.id === item.customerId)?.isActive!} customerName={item.nameWithPerson} customerNameFull={item.nameWithPerson}  /> }  
      {item.customerId === null && item.nameWithPerson}
    </td>
    <td>{item.invoiceNumber}</td>
    <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.amount} /></td>
    <td>{item.returnDate !== null && <Badge variant="danger" title={item.returnNote !== null ? item.returnNote : ''}>Vráceno {moment(new Date(item.returnDate)).format('DD.MM.YYYY')}</Badge>}</td>
    {rights &&<td>
      <ButtonGroup>
        <SubmissionPacketDetail submissionId={sId} showAs='button' packetId={item.id} info={item.packetCode!} />
        <>  
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <SubmissionPacketEdit submissionId={sId} showAs='drop' packetId={item.id} mode='edit' info={item.packetCode!} />
            <Dropdown.Item tag="a" href={"https://www.postaonline.cz/trackandtrace/-/zasilka/cislo?parcelNumbers="+item.packetCode} target="_blank" rel="noopener noreferrer">
              Sledovat zásilku na portále ČP
            </Dropdown.Item>
            {item.customerId !== null &&<SupportContactsCustomerInfo customerId={item.customerId} isActiveCustomer={customerListAllF.find(cla => cla.id === item.customerId)?.isActive!} customerName={customerListAllF.find(cla => cla.id === item.customerId)?.businessName} fromSubMenu={true}  /> }
            {item.returnDate === null ?
            <SubmissionPacketReturn submissionId={sId} showAs='drop' packetId={item.id} />
            :
            <Dropdown.Item onClick={() => { handleOnClickDeleteReturn(item.id,item.packetCode!) } }>Zrušit vrácení zásilky</Dropdown.Item>
            }
            <SubmissionsPacketCopy packetId={item.id} info={item.packetCode!} showAs='drop' refreshAfterClose={setRefreshAfterClose} />
            
            <Dropdown.Divider />
            <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.packetCode!) } }>Smazat</Dropdown.Item>
          </DropdownButton>  
        </>
      </ButtonGroup>
    </td>}
  </tr>
)) : <></>


const packetsListCard = !(submission?.packets === null || submission?.packets === undefined) ? submission?.packets!.map((item) => (
  <div key={item.id}>
  <Card>
    <Card.Body>
      
      <div style={{minWidth: '150px', float: 'left'}}>
        <strong>{item.packetCode}</strong><br />
        <strong>{item.companyName}{(item.person !== '' && item.companyName !== '') && ', '}{item.person}</strong><br />
        <strong>Číslo faktury</strong>: {item.invoiceNumber}<br />
        <strong>Dobírka</strong>: {item.amount}<br />
        {item.returnDate !== null && <Badge variant="danger">Vráceno {moment(new Date(item.returnDate)).format('DD.MM.YYYY')}</Badge>}
        <br />
      </div>
      <div style={{float: 'right'}}>
      {rights && <>
        <ButtonGroup>
          <SubmissionPacketDetail submissionId={sId} showAs='button' packetId={item.id} info={item.packetCode!} />
          <>  
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <SubmissionPacketEdit submissionId={sId} showAs='drop' packetId={item.id} mode='edit' info={item.packetCode!} />
            <Dropdown.Item tag="a" href={"https://www.postaonline.cz/trackandtrace/-/zasilka/cislo?parcelNumbers="+item.packetCode} target="_blank" rel="noopener noreferrer">
              Sledovat zásilku na portále ČP
            </Dropdown.Item>
            {item.customerId !== null &&<SupportContactsCustomerInfo customerId={item.customerId} isActiveCustomer={customerListAllF.find(cla => cla.id === item.customerId)?.isActive!} customerName={customerListAllF.find(cla => cla.id === item.customerId)?.businessName} fromSubMenu={true}  /> }
            {item.returnDate === null ?
            <SubmissionPacketReturn submissionId={sId} showAs='drop' packetId={item.id} />
            :
            <Dropdown.Item onClick={() => { handleOnClickDeleteReturn(item.id,item.packetCode!) } }>Zrušit vrácení zásilky</Dropdown.Item>
            }
            <SubmissionsPacketCopy packetId={item.id} info={item.packetCode!} showAs='drop' refreshAfterClose={setRefreshAfterClose} /> 
            
            <Dropdown.Divider />
            <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.packetCode!) } }>Smazat</Dropdown.Item>
          </DropdownButton>  
          </>
        </ButtonGroup>
      </>}
      </div>
    </Card.Body>
  </Card>
  <br />
  </div>
)) : 0  

const packetsList = <>
        <div className="showMobile">
          {packetsListCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('submissionPacketsOrderBy') === 'PacketCode' || localStorage.getItem('submissionPacketsOrderBy') === null || localStorage.getItem('submissionPacketsOrderBy') === undefined) ? 'PacketCodeDesc' : 'PacketCode'  )}>
                  Kód zásilky
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('submissionPacketsOrderBy') === 'PacketCode' || localStorage.getItem('submissionPacketsOrderBy') === null || localStorage.getItem('submissionPacketsOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('submissionPacketsOrderBy') === 'PacketCodeDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head">Název</th>
                <th className="jsw-table-head">Číslo faktury</th>
                <th className="jsw-table-head" style={{textAlign: 'right'}}>Dobírka</th>
                <th className="jsw-table-head">Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {packetsListTable}
            </tbody>
          </Table>
        </div> 
        <br />  

</>



// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const submissionDetail = 
      <>

          {showDetail &&<>
          <div>

            <Row>
              <Col lg={{span: 9, order :1}}>
                <h4>Distribuce</h4>
                <Alert variant='secondary'>

                <Row>
                  <Col md={3} lg={2}><strong>Datum distribuce:</strong></Col>
                  <Col>{moment(submission?.distributionDate).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>
                
                <Row>
                  <Col md={3} lg={2}><strong>Upravil:</strong></Col>
                  <Col>{rootStore.hedgehogUsers.find(hu => hu.id === submission?.userId)?.fullName}</Col>
                </Row>
   
                <Row>
                  <Col md={3} lg={2}><strong>Popis:</strong></Col>
                  <Col>{submission?.note}</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Celkový počet zásilek:</strong></Col>
                  <Col>{submission?.count}</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Počet vrácených zásilek:</strong></Col>
                  <Col>{submission?.returnCount}</Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Celková hodnota dobírek:</strong></Col>
                  <Col><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={submission?.amount} /></Col>
                </Row>
                <Row>
                  <Col md={3} lg={2}><strong>Celková hmotnost:</strong></Col>
                  <Col><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={3} fixedDecimalScale={true} value={submission?.weight} /> kg</Col>
                </Row>
                {submission?.isExported === true &&
                <Row>
                  <Col md={3} lg={2}><strong>Exportováno:</strong></Col>
                  <Col>{submission?.isExported === true ? 'Ano':'Ne'}</Col>
                </Row>
                }
                {submission?.isSubmitted === true &&
                <Row>
                  <Col md={3} lg={2}><strong>Předáno dopravci:</strong></Col>
                  <Col>{submission?.isSubmitted === true ? 'Ano':'Ne'}</Col>
                </Row>
                }
                
                </Alert>
              </Col>
              <Col lg={{span: 3, order :2}}>
                <h4>Datové podklady</h4>
                <Alert variant='secondary'>
                
                {loadingDocumentTypes === true ? <div style={{textAlign: 'center'}}><Spinner animation='border'  />&nbsp;<br /></div>

                :
                <>
                {submission?.documentTypes?.map(dt => (
                  <Row key={dt}>
                    <Button size="sm" block style={{marginBottom: '5px'}} onClick={async () => { 
                    await rootStore.downloadSubmissionDocumentFromApi(submission?.id,
                      dt === 'License' ? moment(new Date(submission.distributionDate!)).format('YYYY-MM-DD HH-mm')+' - licencni listky' : moment(new Date(submission.distributionDate!)).format('YYYY-MM-DD HH-mm')+' - danove doklady',dt
                      );
                    }
                    }>{dt === 'License' ? 'Stáhnout licenční lístky' : 'Stáhnout daňové doklady'}</Button>
                    
                  </Row> 
                ))}
                </>}
                

                <Row>
                  <Button size="sm" variant='success' block style={{marginBottom: '5px'}} onClick={async () => { 
                    await rootStore.downloadSubmissionFileFromApi(submission?.id!, moment(new Date(submission?.distributionDate!)).format('YYYY-MM-DD HH-mm')+' - export');
                    submission?.setExportedFlag(true);
                    }
                    }>Exportovat TXT pro Podání online</Button></Row>

                </Alert>    
              </Col>
            </Row>

            
            <hr />

            <FilterPanel 
              //isCustomer={props.isCustomer} 
              filterPage={'submissionPackets'} 
              filterSearchArray='submissionPacketsSearchArray' 
              pageVariable='submissionPacketsSearchPage' 
              setPageLoading={setLoadingPackets} 
              filterItems={['PacketCode','PacketNumber','CompanyName','FirstName','SurName','InvoiceNumber','ProductId','Services','Note','ReturnDate','ReturnNote']}  
              title="Seznam zásilek"
              titleCount = {Number(localStorage.getItem('lastSubmissionPacketsSearchTotalCount')!)}
              stateNewFilter={stateNewFilter}
              setStateNewFilter={setStateNewFilter}
              //otherId={props.submissionId}
              otherId={sId}

              secondaryLeftButtons={rights &&
                <span title="Přidat novou zásilku">
                  <SubmissionPacketEdit submissionId={sId} mode='add' showAs='button' info='' />
                </span>}
              />

              <br />
            
            {loadingPackets === true 
              ? 
              <div><Spinner animation='border'  />&nbsp;<br /></div> 
              :
              packetsCount === 0 ? <div>Seznam zásilek neobsahuje žádné záznamy.<br /><br /></div> : packetsList }  
                
             

            <br />  
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')) }
              itemsCountPerPage={10}
              totalItemsCount={Number(localStorage.getItem('lastSubmissionPacketsSearchTotalCount'))}
              pageRangeDisplayed={5}
              onChange={handlePageChange.bind(1)}
              /> 
          
          </div>
          </>}

          </>



return ( 
  <>
  {props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Detail distribuce</Dropdown.Item>
      </>
      :
      props.asLink === true 
        ? 
        <span className='as-link-no-color' title={'Detail distribuce '+props.linkText} onClick={() => {startSequence()}}>{props.linkText}</span> 
        : 
        <>
          <Button variant='outline-primary' size="sm" style={{lineHeight: '0.6'}} onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Detail distribuce' ><i className="fas fa-search"></i>&nbsp;</span>Detail</Button>
        </>
      
    }
  
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání zásilky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

    <Modal 
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Detail distribuce 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {submissionDetail}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(SubmissionsDetail))

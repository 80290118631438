import { types } from 'mobx-state-tree'

//Customer
const Customer = types.model('Customer',{
  id: types.optional(types.string, ''),
})

export const DatafiltersOffersForFilteredActions = types
  .model({
    id: types.optional(types.string, ''),
    customer: types.frozen(Customer),  
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

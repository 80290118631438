import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
/*
import { logout } from '../authProvider'
import { getToken, removeUserSession } from './Common'
import { useStore } from '../store'
*/

// handle the private routes

interface PrivateRouteProps extends RouteProps {
  component: any
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props
  //const [logged] = useAuth()
  
  /*
  const rootStore = useStore();
  (async () => {
  const aToken = await getToken()
  console.log('AA')
  console.log(aToken)
  if ((aToken === null) || (aToken === undefined)) {   
    sessionStorage.setItem('automaticLogout', '1')
    removeUserSession()
    logout()
    await rootStore.deleteCustomerList()
    await rootStore.deleteCustomerOpened()  
    await rootStore.deleteLoggedUser()
    await rootStore.deleteInvites()
    await rootStore.deleteCustomerUsers()
    window.location.reload()
  }
  })();
  */

  return (
      <Route
          {...rest}
          render={(routeProps) =>
            localStorage.getItem('isLogged') ? (
                  <Component {...routeProps} />
              ) : (
                      <Redirect
                          to={{
                              pathname: '/login',
                              state: { from: routeProps.location }
                          }}
                      />
                  )
          }
      />
  )
}

export default PrivateRoute
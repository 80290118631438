import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getUser, getToken, getUrl } from '../../../Utils/Common'

import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Card } from 'react-bootstrap'

import { useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

import { confirm } from "../../../Utils/Confirmation";

import MeetingsUpdate from './Meetings.Update.part'
//import SupportContactsSolveUpdate from './Support.Contacts.SolveUpdate.part'
import MeetingsPointAddUpdate from './Meetings.PointAddUpdate.part'

import TasksDetail from '../../tasks/components/Tasks.Detail.part'

import moment from 'moment'
import FilesList from '../../../parts/files/Files.List.part'
import FilesAdd from '../../../parts/files/Files.Add.part'
//import FilesAdd from '../../../parts/files/Files.Add.part'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import axios from 'axios';

//import ScrollToTopBtn from "../../../sharedComponents/scrollToTop/ScrollToTopBtn"


interface MeetingsDetailProps { 
  meetingId?:string
  editing?:boolean,
  //supportId?:string,
  fromList?:boolean,
  fromSubMenu?:boolean,
  setPageLoading?:any    
}

const MeetingsDetail: React.FC<MeetingsDetailProps & RouteComponentProps> = (props:any) => {
  
  const modalRef = React.useRef<HTMLDivElement>(null);
  
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false



  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //eslint-disable-next-line
  const [showE, setShowE] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  // Nastavení editivaného kontaktu
  const item = rootStore.meetings.find(s => s.id === props.meetingId)
  
  const rightsAuthor:boolean = (item?.authorId === getUser().id) ? true : false
  const rightsMeeting:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false
  const rightsManagement:boolean = ((localStorage.getItem('Management')! === '1')) ? true : false
  //console.log(rightsAuthor)

  const rightsEdit:boolean = ((rightsAuthor === true) || (rightsMeeting === true && item?.isManagement === false) || (rightsManagement === true && item?.isManagement === true)) ? true : false

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    
    if (sessionStorage.getItem('meetingsDetailEdited') === 'Y') {
      //sessionStorage.setItem('supportSearchPage', '1')
      
      
       //rootDialsStore.addHelper('contacts')
       //console.log(Number(sessionStorage.getItem('supportSearchPage') !== null ? sessionStorage.getItem('supportSearchPage') : 0 ))
       await rootStore.fetchUnreadMeetingIds()
       rootStore.fetchMeetings(Number(sessionStorage.getItem('meetingsSearchPage') !== 'null' ? sessionStorage.getItem('meetingsSearchPage') : 0 ) === 0 ? 1 : Number(sessionStorage.getItem('meetingsSearchPage')))
       //rootDialsStore.removeHelper('contacts')
       sessionStorage.setItem('meetingsDetailEdited', 'N')
       
    }

    sessionStorage.removeItem('autoCloseModal')
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    setLoading(true)
    sessionStorage.setItem('meetingsDetailEdited', 'N')
    await rootStore.fetchMeetingPoints(props.meetingId)
    
    
    //Pokud je porada publikovaná, nastavit jako přečtenou
    if (item?.isPublished === true) {
      //await rootStore.setMeetingAsRead(props.meetingId)
    }

    await rootStore.fetchMeetingWhoRead(props.meetingId)
    await rootStore.fetchTasksForMeeting(props.meetingId)


    
  
  setShowDetail(true)

  //await rootStore.fetchSupportById(props.supportId)
  await setLoading(false)
  await rootStore.fetchFilesForMeeting(props.meetingId)

  

}

// Funkce pro tisk porady
function PrintElem(elem:string)
{
  let mywindow = window.open('', 'PRINT', 'height=700,width=1024');

  mywindow!.document.write('<html><head><title>' + document.title  + '</title>');

  mywindow!.document.write('<style type="text/css">body {font-family: Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";} button {display:none !important;} h5 {font-size: 1.25rem;} h4 {font-size: 1.5rem; margin-top: 5px; margin-bottom: 5px;} h3 {font-size: 1.75rem;} .offer-under-modules{margin-top: 10px !important;} .float-right{float: right;} .float-left{float: left;} .cleaner{clear: both;} .noprint {display: none !important;}</style>');
  mywindow!.document.write('<link href="/dist/css/styles.css" rel="stylesheet">');
  mywindow!.document.write('</head><body >');
  mywindow!.document.write('<div class="float-right" style="font-weight: bold; font-size: 1.0rem">Termín: '+ moment(item?.date).format("DD.MM.YYYY") +'</div>');
  mywindow!.document.write('<h5>'+item?.text+'</h5>');
  mywindow!.document.write('<hr />');
  
  mywindow!.document.write('');
  mywindow!.document.write(document.getElementById(elem)!.innerHTML);
  mywindow!.document.write('</body></html>');

  mywindow!.document.close(); // necessary for IE >= 10
  mywindow!.focus(); // necessary for IE >= 10*/

  //mywindow!.print();
  //mywindow!.close();

  return true;
}

  // Dotaz před smazáním úkolu
  const handleOnClickDeleteTask = (taskId:string, meetingPointId:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete úkol "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání úkolu'})) {
        deleteTask(taskId, meetingPointId)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání úkolu
  const deleteTask = (taskId:string, meetingPointId:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_TASKS')+'/'+taskId, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam úkolů

          const editedMeetingPoint = rootStore.getMeetingById(props.meetingId)?.points.find(mp => mp.id === meetingPointId)

          let dataToSend = { id: editedMeetingPoint?.id, caption: editedMeetingPoint?.caption, text: editedMeetingPoint?.text, taskId: null }

          // Zavolání API
          axios.post(getUrl('URL_MEETINGS')+'/'+props.meetingId+'/points/'+meetingPointId, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
            ).then(response => {

              }).catch(error => {
                //setLoading(false)
                //const errorMessage:any = (<ErrorAlert errorData={error} />)
                //setError(errorMessage)
              });

          rootStore.fetchMeetingPoints(props.meetingId)
          rootStore.fetchTasksForMeeting(props.meetingId)
        }).catch(error => {
          //const errorMessage:any = (<ErrorAlert errorData={error} />)
          //setError(errorMessage)
          //setShowE(true)          
        });
   
      })()    
  }

  const handleOnClickSetPublished = (id:string, text:string, actualState:boolean) => {
    (async () => {
      if (actualState === true ) {
        if (await confirm("Opravdu chcete u porady "+text+" zrušit publikování?",'Ano, zrušit', 'Ne', {title: 'Zrušení publikování porady'})) {
          setPublished(id,actualState)
        } else {
        
        }   
      }
      else {
        if (await confirm("Opravdu chcete poradu "+text+" publikovat?",'Ano, publikovat', 'Ne', {title: 'Publikování porady'})) {
          setPublished(id,actualState)
        } else {
        
        }   
      }
    })()
  }

  const setPublished = (id:string,actualState:boolean) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      if (actualState === true) {
        await axios.delete(getUrl('URL_MEETINGS')+'/'+id+'/publish', { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam pozvánek
            rootStore.fetchMeetings(Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))
            rootStore.fetchMeetingPoints(id)
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setShowE(true)          
          });
      }
      else {
        await axios.post(getUrl('URL_MEETINGS')+'/'+id+'/publish', {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchMeetings(Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))
          rootStore.fetchMeetingPoints(id)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
      }
   
      })()    
  }


// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>

          {showDetail &&<>
          <div id='meetingDetail'>

            <Row>
              <Col lg={{span: 8, order :1}}>
                <h4 className='noprint'>Porada</h4>
                <Alert variant='secondary'>

                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Termín:</strong></Col>
                  <Col>{moment(item?.date).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Pořídil:</strong></Col>
                  <Col>{rootStore.hedgehogUsers.find(hu => hu.id === item?.authorId)?.fullNameBySurName}</Col>
                </Row>
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Cíl porady:</strong></Col>
                  <Col>{item?.text}</Col>
                </Row>
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Kategorie:</strong></Col>
                  <Col>{rootDialsStore.dialsMeetingsCategoriesAll.find(mc => mc.id === item?.categoryId)?.name}</Col>
                </Row>    
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Délka:</strong></Col>
                  <Col>{item?.duration}&nbsp;min.</Col>
                </Row>
                {(item?.isManagement === false && item?.employeeWithAccess!.length > 0) && 
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Seznam účastníků:</strong></Col>
                  <Col>{item?.employeeWithAccess?.slice().map((em,index) => ( <span key={index}>{index > 0 && ', '}{Object.keys(item.readers.filter(r => r.userId === em)).length === 0 ? <strong>{rootStore.hedgehogUsers.find(hu => hu.id === em)?.fullNameBySurName}</strong>:<span title={'Přečteno: '+ moment(item.readers.find(r => r.userId === em)?.readDate).format("DD.MM.YYYY HH:mm")}>{rootStore.hedgehogUsers.find(hu => hu.id === em)?.fullNameBySurName}</span>}</span>))}  </Col>
                </Row>
                }

                {(item?.isManagement === true || item?.employeeWithAccess!.length === 0) && 
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Poradu přečetli:</strong></Col>
                  <Col>{item?.readers?.slice().map((rd,index) => ( <span key={index}>{index > 0 && ', '}<span title={'Přečteno: '+ moment(rd.readDate).format("DD.MM.YYYY HH:mm")}>{rootStore.hedgehogUsers.find(hu => hu.id === rd.userId)?.fullNameBySurName}</span></span>))}  </Col>
                </Row>
                }

                {item!.absentEmployees!.length > 0 &&
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Seznam nepřítomných:</strong></Col>
                  <Col>{item?.absentEmployees?.slice().map((aem,index) => ( <span key={index}>{index > 0 && ', '}{rootStore.hedgehogUsers.find(hu => hu.id === aem)?.fullNameBySurName}</span> ))}  </Col>
                </Row>
                }

                <br />
                <div className='noprint'>
                <FilesList id={item?.id!} type='meeting' masterId={item?.id!} />
                </div>
                
                {rightsEdit === true &&
                <ButtonGroup style={{}}>
                  <MeetingsUpdate id={item?.id!} showAs='button' />
                  <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                    <FilesAdd id={props.meetingId} type='support' masterId={props.meetingId} showAs='drop' />
                  </DropdownButton>
                </ButtonGroup>
                }

                </Alert>
              </Col>
              <Col lg={{span: 4, order :2}}>
                <h4 className='noprint'>Akce</h4>
                <Alert variant='secondary' className='noprint'>
                  <Button block variant="secondary" size="sm" onClick={() => {PrintElem('meetingDetail')}}><i className="fas fa-print"></i>&nbsp;Vytisknout poradu</Button>
                  {item?.isPublished === true && <><hr />
                    <Button disabled={item?.readers!.filter(r => r.userId === getUser()?.id!).length === 0  ? false:true } block variant="primary" size="sm" onClick={async () => { await rootStore.setMeetingAsRead(props.meetingId); await rootStore.fetchMeetingWhoRead(props.meetingId) }}><i className="fas fa-book-reader"></i>&nbsp;Nastavit poradu jako přečtenou</Button>
                  </>}
                  {rightsEdit === true && <><hr />
                    {item?.pointCount! > 0 &&
                      <Button block variant={item?.isPublished === true ? 'danger':'success'} size="sm" onClick={() => {handleOnClickSetPublished(item?.id!, item?.text!, item?.isPublished!)}}>{item?.isPublished! === true ? 'Zrušit publikování porady':'Publikovat poradu'}</Button>
                    }
                  </>}
                </Alert>
                {error}
              </Col>
              
            </Row>
              
                <>
                  <div style={{float: 'right'}}></div> 
                  <h4>Body porady</h4>
                  
                  {rightsEdit === true && <MeetingsPointAddUpdate meetingId={item?.id} />} 
                  
                  {rightsEdit === true && <><br className='noprint' /><br className='noprint' /></>}
                  {loading === true 
                    ? 
                    <><Spinner animation='border'  />&nbsp;</>
                    : 
                    <>
                      <>
                        {Object.keys(item?.points!).length === 0 &&<>Seznam bodů porady neobsahuje žádné záznamy.</>}
                      </>

                      {item?.points.slice().sort((a:any, b:any) => a.rank - b.rank)
                      //.sort((a, b) => (new Date(a.createDate) > new Date(b.createDate)) ? 1 : -1)
                      .map(ss => (
                
                        <>
                        <Alert variant='info' key={ss.id}>
                          <Alert.Heading><strong>{ss.rank === null ? 0 : ss.rank+1}.&nbsp;{ss.caption}</strong></Alert.Heading>
                            <>
                              <div className="content" dangerouslySetInnerHTML={{__html: ss?.text!}}></div>
                              <br />
                            </>  
                            <div className='noprint'>
                              <FilesList id={ss.id} type='meeting' masterId={item?.id} />
                            </div>

                            {ss.taskId !== null && <>
                              {rootStore.tasks.filter(t => t.id === ss.taskId).length === 0 &&<strong>K bodu porady je vytvořen úkol. Pravděpodobně však nemáte práva k jeho zobrazení.</strong>}
                              {rootStore.tasks.filter(t => t.id === ss.taskId).map((task, index) => (
                                <Card>
                                  <Card.Header>
                                    <strong>Úkol</strong>
                                  </Card.Header>
                                  <Card.Body>
                              
                                    <div style={{minWidth: '150px', float: 'left'}}>
                                      <strong>{moment(new Date(task.deadLine!)).format("DD.MM.YYYY HH:mm")}</strong><br />
                                      <strong>{task.text}</strong><br />
                                      <strong>Autor</strong>: {rootStore.hedgehogUsers.find(hu => hu.id === task.authorId)?.fullNameBySurName}<br />
                                      <strong>Odpovídá</strong>: {rootStore.hedgehogUsers.find(hu => hu.id === task.responsibleUserId)?.fullNameBySurName}
                                      {(task.isDone === true || task.isCompleted === true || task.isPrivate === true) && <br />} 
                                      {task.isDone === true && <Badge variant="success" title={'Datum splnění úkolu: '+moment(new Date(task.doneDate!)).format("DD.MM.YYYY HH:mm")}>Splněno</Badge>}
                                      {task.isCompleted === true  && <>&nbsp;<Badge variant="success" title={'Datum uzavření úkolu: '+moment(new Date(task.completedDate!)).format("DD.MM.YYYY HH:mm")}>Uzavřeno</Badge></>}
                                      {task.isPrivate === true  && <>&nbsp;<Badge variant="warning" title={'Příznak, že úkol byl označen jako soukromý'}>Soukromý</Badge></>}
                                      
                                      
                                    </div>
                                    <div style={{float: 'right'}}>
                                      {rights && <>
                                        <ButtonGroup>
                                          <TasksDetail taskId={task.id} fromSubMenu={false} setPageLoading={setLoading} />
                                  
                                          <>  
                                            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">

                                              {(task.authorId === getUser().id) && <>
                                                <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteTask(task.id, ss.id, moment(new Date(task.deadLine!)).format("DD.MM.YYYY HH:mm")) } }>Smazat</Dropdown.Item>
                                              </>}
                                            </DropdownButton>  
                                          </>  
                                        </ButtonGroup>
                                      </>}
                                    </div>
                                  </Card.Body>
                                </Card>
                              ))
                              }
                        
                        
                              <br />
                        
                            </>}



                          {rightsEdit === true && <MeetingsPointAddUpdate editing={true} meetingId={item?.id} pointId={ss.id} />}


                          

                        </Alert>
                        <div className='showMobile'>
                          <Button variant="light" size='sm' style={{float: 'right', borderRadius: '0'}} onClick={() => {modalRef.current?.scrollIntoView({ behavior: 'smooth' });}}>↑ nahoru</Button>
                          <div style={{clear: 'both', paddingBottom: '10px'}}></div>
                        </div>
                        </>
                
                      ))}
              
                    </>
                  }
                </>
              
          
          </div>
          </>}

          </>





return ( 
  <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Detail porady</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail porady' onClick={() => {startSequence()}}>{rootStore.meetingsUnread.includes(item?.id!) === true ? <strong><span style={{color: item?.absentEmployees?.includes(getUser().id) === true ? '#e74a3b':''}}>{item?.text}</span></strong> : <span style={{color: item?.absentEmployees?.includes(getUser().id) === true ? '#e74a3b':''}}>{item?.text}</span>}</span>
      </>
    :
    <>
      <Button variant='outline-primary' size="sm" style={{}} onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Detail porady' ><i className="fas fa-search"></i>&nbsp;</span>Detail</Button>
    </>
    }
  
    <Modal 
      
      scrollable
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
        <div className="showMobile">
          Detail porady <span style={{display: 'none'}}><br />
          {item?.text}
          {item?.isManagement === true &&<><br /><Badge variant='success' style={{marginLeft: '10px'}}>Porada vedení</Badge></>}  
          </span>
        </div>
        <div className="showWeb">
          Detail porady <Badge variant='secondary'>{item?.text}</Badge>
          {item?.isManagement === true &&<Badge variant='success' style={{marginLeft: '10px'}}>Porada vedení</Badge>}  
        </div>  
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      <div ref={modalRef}> 
        {contactForm}
      </div>
      
      <div className='showWeb'>
      <Button variant="light" size='sm' style={{float: 'right', borderRadius: '0'}} onClick={() => {modalRef.current?.scrollIntoView({ behavior: 'smooth' });}}>↑ nahoru</Button>
      <div style={{clear: 'both', paddingBottom: '10px'}}></div>
      </div>

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(MeetingsDetail))

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

interface DeliveryCzechPostProductsProps {
      
}

const SelectOptionsDeliveryCzechPostProducts: React.FC<DeliveryCzechPostProductsProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootDialsStore = useDialsStore()

  // Seznam typů kontaktu pro rozevírací seznam
  const items = rootDialsStore.dialsDeliveryCzechPostProductsAll
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.prefix; 
    var sort1B = b.prefix; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(item => (
    <option key={item.id} value={item.id}>{item.prefix} - {item.name}</option>
  ))   

  return (
    <>
      {items}
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsDeliveryCzechPostProducts))
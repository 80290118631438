import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Modal } from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
//import FormGroup from 'react-bootstrap/FormGroup'
//import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation"

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

//import { Formik } from 'formik'
//import * as Yup from 'yup'

interface CustomersCategoriesUpdateProps {
  id: string
}

const CustomersCategoriesUpdate: React.FC<CustomersCategoriesUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Nastavení stavů pro zobrazení
  //const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  //const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const customerCategories = rootStore.customerOpenedCategories

  const customerCategoryToEdit = customerCategories.find(category => category.id === props.id)


  const categoriesIdsList = customerCategories.filter(cc => cc.id !== props.id).map(ci => ( ci.id ))
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  /*
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    //setShowInfo(false)
    //setShowForm('')
    //setShow(true)
  }
  */

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteCategories = () => {
    (async () => {
      if (await confirm("Opravdu chcete kategorii "+customerCategoryToEdit?.name+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání kategorie'})) {
        deleteCategory()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání kontaktu
  const deleteCategory = () => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      
      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_CATEGORIES')+'/'+customer?.id+'/categories/', { customerId: localStorage.getItem('openedCustomerId')!, customerCategoryIds: categoriesIdsList }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          rootStore.fetchCustomerOpenedCategories()


        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)
        });
  
      })()    
  }

  return (
    <span>
      <div className={rightsBH && customer?.isActive ? '' : 'nodisplay'}>
        &nbsp;&nbsp;
        <Button variant="outline-danger" size="sm" onClick={() => { handleOnClickDeleteCategories() } } >
          <svg className="bi bi-trash-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
          </svg>
        </Button>
      </div>
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      
    </span>
  )  
  
}

export default withRouter(observer(CustomersCategoriesUpdate))

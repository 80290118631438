import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Badge, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../../Utils/Confirmation";

import DialsDeliveryCzechPostProductUpdate from './Dials.Delivery.CzechPost.Products.Update.part'

import { useDialsStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../../../sharedComponents/filterPanel/FilterPanel.part'


const DialsDeliveryCzechPostProductsList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')

    if (sessionStorage.getItem('dialsDeliveryCzechPostProductsSearchValue') !== '') {
      
    }

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete položku "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání produktu České pošty'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  //const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  
  
  const items = rootDialsStore.dialsDeliveryCzechPostProducts.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{item.prefix}</td>
      <td>{item.name}</td>
      <td>{item.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}</td>
      {rights &&<td>
        <ButtonGroup>
          <DialsDeliveryCzechPostProductUpdate id={item.id} showAs='button' />
      
          {rightsBH &&<>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>} 
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{item.prefix}</strong><br />
          <strong>{item.name}</strong><br />
          {item.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}&nbsp;
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rights && <>
          <ButtonGroup>
            <DialsDeliveryCzechPostProductUpdate id={item.id} showAs='button' />
            {rightsBH &&<>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>} 
              </DropdownButton>  
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_DIALS_DELIVERY_CZECH_POST_PRODUCTS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootDialsStore.fetchDialsDeliveryCzechPostProducts(Number(sessionStorage.getItem('dialsDeliveryCzechPostProductsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('dialsDeliveryCzechPostProductsSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('dialsDeliveryCzechPostProductsSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootDialsStore.fetchDialsDeliveryCzechPostProducts(pageNumber)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('dialsDeliveryCzechPostProductsOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('dialsDeliveryCzechPostProductsSearchPage', String(1))
        await rootDialsStore.fetchDialsDeliveryCzechPostProducts(1)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (prefixData:string, nameData:string, isActiveData:boolean) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_DIALS_DELIVERY_CZECH_POST_PRODUCTS'), { name: nameData, prefix: prefixData, isActive: isActiveData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootDialsStore.fetchDialsDeliveryCzechPostProducts(Number(sessionStorage.getItem('dialsDeliveryCzechPostProductsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('dialsDeliveryCzechPostProductsSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    name: Yup.string()
    .required('Název produktu musí být vyplněn!'),
    prefix: Yup.string()
    .required('Prefix je nutné vyplnit!'),
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'dialsDeliveryCzechPostProducts'} 
        filterSearchArray='dialsDeliveryCzechPostProductsSearchArray' 
        pageVariable='dialsDeliveryCzechPostProductsSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['Name','Prefix','IsActive']}  
        title="Produkty České pošty"
        titleCount = {Number(localStorage.getItem('lastDialsDeliveryCzechPostProductsTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rightsBH &&
          <span title="Přidat nový produkt">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat produkt
          </Button>
          </span>}
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam produktů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === 'Prefix' || localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === null || localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === undefined) ? 'PrefixDesc' : 'Prefix'  )}>
                  Prefix
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === 'Prefix' || localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === null || localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === 'PrefixDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head">Název</th>
                <th className="jsw-table-head">Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('dialsDeliveryCzechPostProductsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('dialsDeliveryCzechPostProductsSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastDialsDeliveryCzechPostProductsTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání produktu České pošty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Produkt České pošty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Produkt byl úspěšně přidán!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              initialValues={{ 
                prefix: '', 
                name: '',
                isActive: true, 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.prefix!, values.name!, values.isActive!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="prefix">
                    <Form.Row>
                      <FormLabel column lg={3}>Prefix</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="prefix"
                          value={values.prefix}
                          onChange={handleChange}
                          isValid={touched.prefix && !errors.prefix} 
                          isInvalid={!!errors.prefix}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.prefix}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isActive">
                    <Form.Row>
                      <FormLabel column lg={3}>Aktivní</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isActive"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isActive}
                          
                          isValid={touched.isActive && !errors.isActive} 
                          isInvalid={!!errors.isActive} 
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isActive}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup>                    
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám produkt...' : 'Uložit produkt'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(DialsDeliveryCzechPostProductsList))

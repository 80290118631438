import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getRoleName, getToken, getUrl, getUser } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Badge, Spinner, Row} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation";

import MeetingsUpdate from './Meetings.Update.part'
import MeetingsDetail from './Meetings.Detail.part'
import MeetingsPointAddUpdate from './Meetings.PointAddUpdate.part'

import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'

import moment from 'moment'
import NumberFormat from 'react-number-format'

const MeetingsList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [showAddHedgehogs, setShowAddHedgehogs] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  const rightsMeeting:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false
  const rightsManagement:boolean = ((localStorage.getItem('Management')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    rootStore.hedgehogUsers.map((hu,index) => {
      hu.selectItem(false)
      return ''
    })
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')

    if (sessionStorage.getItem('meetingsSearchValue') !== '') {
      
    }

    rootStore.hedgehogUsers.map((hu,index) => {
      hu.selectItem(false)
      return ''
    })

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  const handleOpenAddHedgehogs = () => {    
    setShowAddHedgehogs(true)
    
  }

  const handleCloseAddHedgehogs = () => {
    setShowAddHedgehogs(false)
    //setDataPreviewSeparator('')
    //setDataPreviewArray([])
  }

  const handleAddHedgehogs = () => {    
    

    handleCloseAddHedgehogs()
    
    //setShowAddHedgehogs(true)
    
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete poradu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání porady'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

  const handleOnClickSetPublished = (id:string, text:string, actualState:boolean) => {
    (async () => {
      if (actualState === true ) {
        if (await confirm("Opravdu chcete u porady "+text+" zrušit publikování?",'Ano, zrušit', 'Ne', {title: 'Zrušení publikování porady'})) {
          setPublished(id,actualState)
        } else {
        
        }   
      }
      else {
        if (await confirm("Opravdu chcete poradu "+text+" publikovat?",'Ano, publikovat', 'Ne', {title: 'Publikování porady'})) {
          setPublished(id,actualState)
        } else {
        
        }   
      }
    })()
  }

 

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  
  
  const items = rootStore.meetings.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{moment(new Date(item.date!)).format("DD.MM.YYYY HH:mm")}</td>
      <td><MeetingsDetail meetingId={item.id} fromSubMenu={false} fromList={true}  /></td>
      <td>{rootDialsStore.getDialsMeetingsCategoryAllById(item.categoryId!)?.name}</td>
      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} fixedDecimalScale={true} value={item.duration} /></td>
      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} fixedDecimalScale={true} value={item.pointCount} /></td>
      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={0} fixedDecimalScale={true} value={item.incompleteTaskCount} /></td>
      <td>
        {item.isPublished === true && <Badge variant="info">Publikováno</Badge>}
        {item.isManagement === true  && <>&nbsp;<Badge variant="success">Porada vedení</Badge></>}
      </td>
      {rights &&<td>
        <ButtonGroup>
          
          <MeetingsDetail meetingId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
          {(item?.authorId === getUser().id || (rightsManagement === true && item.isManagement === true) || (rightsMeeting === true && item.isManagement === false)) &&<>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <MeetingsPointAddUpdate meetingId={item?.id} fromSubmenu={true} showAs='drop' /> 
            {item.pointCount > 0 && <Dropdown.Item onClick={() => { handleOnClickSetPublished(item.id,moment(new Date(item.date!)).format("DD.MM.YYYY HH:mm"),item.isPublished);  } }>{item.isPublished ? 'Zrušit publikování' : 'Publikovat'}</Dropdown.Item>}
            <MeetingsUpdate id={item.id} showAs='drop' />
            <Dropdown.Divider />
            <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.date!)).format("DD.MM.YYYY HH:mm")) } }>Smazat</Dropdown.Item>
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{moment(new Date(item.date!)).format("DD.MM.YYYY HH:mm")}</strong><br />
          <strong>{item.text}</strong>{item.isFuture === true  && <>&nbsp;<Badge variant="warning">Nadcházející</Badge></>}{item.absentEmployees?.includes(getUser().id) === true  && <>&nbsp;<Badge variant="danger">Nepřítomen</Badge></>}<br />
          {rootDialsStore.getDialsMeetingsCategoryAllById(item.categoryId!)?.name}<br />
          <strong>Délka (min.)</strong>: {item.duration}<br />
          <strong>Počet bodů</strong>: {item.pointCount}<br />
          <strong>Počet úkolů</strong>: {item.taskCount}<br />
          <strong>Počet nevyřešených</strong>: <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.incompleteTaskCount} /><br />
          {item.isPublished === true && <Badge variant="info">Publikováno</Badge>}
          {item.isManagement === true  && <>&nbsp;<Badge variant="success">Porada vedení</Badge></>}
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rights && <>
          <ButtonGroup>
            <MeetingsDetail meetingId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
            
            {(item?.authorId === getUser().id || (rightsManagement === true && item.isManagement === true) || (rightsMeeting === true && item.isManagement === false)) &&<>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                <MeetingsPointAddUpdate meetingId={item?.id} fromSubmenu={true} showAs='drop' /> 
                {item.pointCount > 0 && <Dropdown.Item onClick={() => { handleOnClickSetPublished(item.id,moment(new Date(item.date!)).format("DD.MM.YYYY HH:mm"), item.isPublished);  } }>{item.isPublished ? 'Zrušit publikování' : 'Publikovat'}</Dropdown.Item>} 
                <MeetingsUpdate id={item.id} showAs='drop' />
                
                <Dropdown.Divider />
                <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.date!)).format("DD.MM.YYYY HH:mm")) } }>Smazat</Dropdown.Item>

              </DropdownButton>  
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_MEETINGS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchMeetings(Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

  const setPublished = (id:string,actualState:boolean) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      if (actualState === true) {
        await axios.delete(getUrl('URL_MEETINGS')+'/'+id+'/publish', { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam pozvánek
            rootStore.fetchMeetings(Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setShowE(true)          
          });
      }
      else {
        await axios.post(getUrl('URL_MEETINGS')+'/'+id+'/publish', {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchMeetings(Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
      }
   
      })()    
  }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('meetingsSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootStore.fetchMeetings(pageNumber)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('meetingsOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('meetingsSearchPage', String(1))
        await rootStore.fetchMeetings(1)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (dateData:string, textData:string, durationData:number, categoryIdData:string, isManagementData:boolean) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_MEETINGS'), { date: new Date(dateData).toISOString(), text: textData, duration:durationData, categoryId: categoryIdData, isManagement: isManagementData, employeesWithAccess: isManagementData === true ? [] : rootStore.hedgehogUsers.filter(hu => hu.checked === true).map(hu => hu.id) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then (response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');
          setShowInfo(true);

          (async () => {

            await rootStore.fetchUnreadMeetingIds()
            await rootStore.fetchMeetings(Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))

            // Po 2800 ms celé modální okno zavřeme
            setTimeout(() => {
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')

            }, 2800);

          })()

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }


 // Seznam kategorií porad pro rozevírací seznam
 const dialsMeetingsCategories = rootDialsStore.dialsMeetingsCategoriesAll
 .slice()
 //.filter(dst => dst.isActive === true)
 .sort(function(a:any, b:any) {
   var sort1A = a.name; 
   var sort1B = b.name; 

   if (sort1A! < sort1B!) {
     return -1;
   }
   if (sort1A! > sort1B!) {
     return 1;
   }
   return 0;
 })
 .map(item => (
   <option key={item.id} value={item.id}>{item.name}</option>
 ))  



  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    date: Yup.string()
    .required('Datum porady musí být vyplněno!'),
    categoryId: Yup.string()
    .required('Kategorie porady musí být vybrána!'),
    text: Yup.string()
    .required('Cíl porady musí být vyplněn!'),
    duration: Yup.number()
    .required('Délka trvání musí být vyplněna!'),
    
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'meetings'} 
        filterSearchArray='meetingsSearchArray' 
        pageVariable='meetingsSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['Date','AuthorId','IsPublished','Text','IsManagement','IncompletedMeetingsTasks','UnreadMeetings']}  
        title="Porady"
        titleCount = {Number(localStorage.getItem('lastMeetingsSearchTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rights &&
          <span title="Přidat novou poradu">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat poradu
          </Button>
          </span>}
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam porad neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th title='Datum a čas začátku porady' className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('meetingsOrderBy') === 'Date' || localStorage.getItem('meetingsOrderBy') === null || localStorage.getItem('meetingsOrderBy') === undefined) ? 'DateDesc' : 'Date'  )}>
                  Datum porady
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('meetingsOrderBy') === 'Date' || localStorage.getItem('meetingsOrderBy') === null || localStorage.getItem('meetingsOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('meetingsOrderBy') === 'DateDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" title='Cíl porady'>Cíl porady</th>
                <th className="jsw-table-head" title='Kategorie porady'>Kategorie</th>
                <th className="jsw-table-head" title='Předpokládaná délka porady v minutách' style={{textAlign: 'right'}}>Délka (min.)</th>

                <th className="jsw-table-head" style={{textAlign: 'right'}} title="Počet bodů dané porady">Počet bodů</th>
                <th className="jsw-table-head" style={{textAlign: 'right', cursor: 'pointer', display: 'none'}} title="Počet zadaných úkolů" onClick={() =>  handleOrderChange((localStorage.getItem('meetingsOrderBy') !== 'TaskCount' ) ? 'TaskCount' : 'TaskCountDesc'  )}>
                  Počet úkolů
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('meetingsOrderBy') === 'TaskCount' ) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('meetingsOrderBy') === 'TaskCountDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" style={{textAlign: 'right', cursor: 'pointer', display: 'none'}} title="Počet vyřešených úkolů" onClick={() =>  handleOrderChange((localStorage.getItem('meetingsOrderBy') !== 'CompletedTaskCount' ) ? 'CompletedTaskCount' : 'CompletedTaskCountDesc'  )}>
                  Vyřešeno
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('meetingsOrderBy') === 'CompletedTaskCount' ) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('meetingsOrderBy') === 'CompletedTaskCountDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" style={{textAlign: 'right'}} title="Počet nevyřešených úkolů">Nevyřešeno úkolů</th>
                
                <th className="jsw-table-head" title='Stav, ve kterém se porada momentálně nachází'>Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastMeetingsSearchTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání porady</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Přidání porady</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Porada byla úspěšně přidána!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ 
                date: moment(new Date()).format("YYYY-MM-DDTHH:mm"), 
                text: '', 
                duration: 0,
                categoryId: '',
                isManagement: false,
                temp: '',

              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.date!, values.text!, values.duration!, values.categoryId!, values.isManagement)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                validateField,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="date">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="date"
                          value={values.date}
                          onChange={handleChange}
                          isValid={touched.date && !errors.date} 
                          isInvalid={!!errors.date}
                          onBlur={() => validateField('date')}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.date}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="text">
                    <Form.Row>
                      <FormLabel column lg={3}>Cíl porady</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="text"
                          value={values.text}
                          onChange={handleChange}
                          isValid={touched.text && !errors.text} 
                          isInvalid={!!errors.text}
                          onBlur={() => validateField('text')}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="duration">
                    <Form.Row>
                      <FormLabel column lg={3}>Délka (min.)</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="duration"
                          value={values.duration}
                          onChange={handleChange}
                          isValid={touched.duration && !errors.duration} 
                          isInvalid={!!errors.duration}
                          onBlur={() => validateField('duration')}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.duration}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="categoryId">
                    <Form.Row>
                      <FormLabel column lg={3}>Kategorie porady</FormLabel>
                      <Col>
                          <Form.Control as="select" 
                            name="categoryId" 
                            //onChange={async(e) => {await handleChange(e); await controlAfterSelectType(e.target.value);  if (rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId !== '') { await setFieldValue("solutionSupportTypeId", rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId ) } }}
                            onChange={handleChange}
                            value={values.categoryId!}
                            isInvalid={!!errors.categoryId}
                            onBlur={() => validateField('categoryId')}
                            //tabIndex={4}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 3:7, handleSubmit)}
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              dialsMeetingsCategories
                              }
                          </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.categoryId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isManagement">
                    <Form.Row>
                      <FormLabel column lg={3}>Porada vedení</FormLabel>
                      <Col>
                      <FormControl 
                          type="checkbox"
                          name="isManagement"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isManagement}
                          disabled={rightsManagement === false}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isManagement && !errors.isManagement} 
                          isInvalid={!!errors.isManagement}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isManagement}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  {values.isManagement === false && <>

                  <strong>Účastníci porady:</strong><br />
                  {rootStore.hedgehogUsers.filter(hu => hu.checked === true).length === 0 && <span >Nikdo nebyl vybrán, porada je určena pro všechny.</span>}
                  {rootStore.hedgehogUsers.filter(hu => hu.checked === true).map(hu => (
                    <div key={hu.id}>{hu.fullName}</div>
                  
                  ))
                  }
                  <Button size='sm' block style={{marginTop: '10px'}} variant='secondary' onClick={() => { handleOpenAddHedgehogs() } }>Upravit výběr účastníků</Button>

                  </>}          
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám poradu...' : 'Uložit poradu'}</Button>

                  <Modal 
                    show={showAddHedgehogs} 
                    onHide={handleCloseAddHedgehogs}        
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Výběr účatníků porady</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th className="jsw-table-head" style={{width: '30px'}}></th>
                            <th className="jsw-table-head">Jméno</th>
                            <th className="jsw-table-head">E-mail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true).map((item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={item.checked}
                                  className=""
                                  id={'rowcheck'+item.id}
                                  onChange={() => {item.selectItem(!item.checked); setFieldValue('temp', new Date().toISOString) }}
                                  style={{height: '18px', width: '18px'}}
                                />
                              </td>
                              <td>{item.fullNameBySurName}</td>
                              <td>{item.email}</td>
                            </tr>
                          ))}
                         
                        </tbody>
                      </Table>
                      <h5>Výběry</h5>
                      <ButtonGroup className='btn-block'>
                        <Button variant="secondary" onClick={() => { rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true && !(u.email === 'obchod@jezeksw.cz' || u.email === 'uzivatel.jezeksw@seznam.cz' || u.email === 'sync@jezeksw.cz' || u.email === 'test@jezeksw.cz')).map((i, index) =>  { i.selectItem(true); return null; }); setFieldValue('temp', new Date().toISOString) }} >Vybrat všechny</Button>
                        
                      </ButtonGroup>
                      <hr />
                      <Row>
                        {rootDialsStore.dialsRoles.filter(r => !(r === 'SmallHedgehog' || r === 'BigHedgehog' || r === 'SmallUser' || r === 'BigUser')).map((item, index) => (<Col key={index} md={6} sm={12} style={{marginBottom: '5px'}}>
                          <Button variant="secondary" block onClick={() => { rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true && u.roles.includes(item) === true).map((i, index) =>  { i.selectItem(true); return null; }); setFieldValue('temp', new Date().toISOString) }} >{getRoleName(item)}</Button>    
                        </Col>))}
                        {rootDialsStore.dialsUserGroupsAll.map((item, index) => (<Col key={index} md={6} sm={12} style={{marginBottom: '5px'}}>
                          <Button variant="secondary" block onClick={() => { rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true && item.users.includes(u.id) === true).map((i, index) =>  { i.selectItem(true); return null; }); setFieldValue('temp', new Date().toISOString) }} >{item.name}</Button>    
                        </Col>))}

                      </Row>
                      <hr />
                      <Button variant='outline-danger' block onClick={() => { rootStore.hedgehogUsers.map((i, index) =>  { i.selectItem(false); return null; }); setFieldValue('temp', new Date().toISOString) }}>Zrušit výběr</Button>
                      <hr />
                      <Button variant="success" block onClick={() => { handleAddHedgehogs() }} >Hotovo, zavřít okno</Button>

                  </Modal.Body>
                </Modal>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(MeetingsList))

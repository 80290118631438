import { types } from 'mobx-state-tree'

export const CustomerOpenedHistoryNextSite = types
  .model({
    id: types.optional(types.string, ''),
    //userId: types.maybeNull(types.string),
    //userFirstName: types.maybeNull(types.string),
    //userSurName: types.maybeNull(types.string),
    //userName: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    //operation: types.maybeNull(types.string),
    //scope: types.maybeNull(types.string),
    //data: types.maybeNull(types.string)
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Badge, Spinner, Row} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation";

import OthersTemplateUpdate from './Others.Templates.Update.part'
import OthersTemplateDetail from './Others.Templates.Detail.part'
import OthersTemplateSendTest from './Others.Templates.SendTest.part'
import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'

import EmailingSendEmails  from '../../../emailing/emailingSend/Emailing.SendEmails.part'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../../sharedComponents/filterPanel/FilterPanel.part'


const OthersTemlatesList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)

  const [newTemplateValues, setNewTemplateValues] = useState({name: '', subject: '', plainText: '', htmlText: '', isActive: true})
  


  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('Emailing')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    setNewTemplateValues({name: '', subject: '', plainText: '', htmlText: '', isActive: true})
    sessionStorage.removeItem('autoCloseModal')

    if (sessionStorage.getItem('templatesSearchValue') !== '') {
      
    }

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

// Kopie šablony
const handleOnClickCopyTemplate = async (id:string) => {
  await rootStore.fetchTemplateById(id)
  const template = rootStore.getTemplateById(id)
  if (template === undefined) {
    return
  }

  setNewTemplateValues({name: 'Kopie - '+ template.name, subject: template.subject, plainText: template.plainText!, htmlText: template.htmlText!, isActive: template.isActive})  
  sessionStorage.removeItem('autoCloseModal')
  setError(null)
  setShowInfo(false)
  setShowForm('')
  setShow(true)
}

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteTemplate = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete šablonu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání šablony e-mailu'})) {
        deleteTemplate(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  const rootStore = useStore()
  
  const templates = rootStore.templates.slice().sort(function(a:any, b:any) {
    var sort1A = a.name; 
    var sort1B = b.name; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  
  //Počet záznamů
  const countData:number = Object.keys(templates).length

  // Seznam pozvánek do řádků tabulky
  const linesTemplates = templates.map((template) => (
    <tr key={template.id}>
      <td>{template.name}</td>
      <td>{template.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}</td>
      <td>{template.isSystem === true ? <Badge variant="secondary">Systémová</Badge>:<Badge variant="info">Uživatelská</Badge>}</td>
      {rights &&<td>
        <ButtonGroup>
          <OthersTemplateDetail id={template.id} showAs='button' />
      
          {rightsBH &&<>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <OthersTemplateUpdate id={template.id} showAs='drop' />
            {rightsBH && <Dropdown.Item onClick={() => { handleOnClickCopyTemplate(template.id) } }>Kopie šablony</Dropdown.Item>}
            <OthersTemplateSendTest id={template.id} showAs='drop' />
            <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={template.name!} historyType='template' historyParam={template.id!} />
            {rightsBH && template.isSystem === false &&<Dropdown.Divider />}
            {rightsBH && template.isSystem === false && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteTemplate(template.id,template.name) } }>Smazat</Dropdown.Item>
            } 
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesTemplatesCard = templates.map((template) => (
    <div key={template.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{template.name}</strong><br />
          {template.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}&nbsp;
          {template.isSystem === true ? <Badge variant="secondary">Systémová</Badge>:<Badge variant="info">Uživatelská</Badge>}
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rights && <>
          <ButtonGroup>
            <OthersTemplateDetail id={template.id} showAs='button' />
            {rightsBH &&<>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                <OthersTemplateUpdate id={template.id} showAs='drop' />
                {rightsBH && <Dropdown.Item onClick={() => { handleOnClickCopyTemplate(template.id) } }>Kopie šablony</Dropdown.Item>}
                <OthersTemplateSendTest id={template.id} showAs='drop' />
                <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={template.name!} historyType='template' historyParam={template.id!} />
                {rightsBH && template.isSystem === false &&<Dropdown.Divider />}
                {rightsBH && template.isSystem === false && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteTemplate(template.id,template.name) } }>Smazat</Dropdown.Item>} 
              </DropdownButton>  
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteTemplate = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_TEMPLATES')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchTemplatesNew(Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('templatesSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootStore.fetchTemplatesNew(pageNumber)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewTemplate = (subjectData:string, nameData:string, plainTextData:string, htmlTextData:string, isActiveData:boolean) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_TEMPLATES'), { name: nameData, subject: subjectData, plainText: plainTextData, htmlText: htmlTextData, isActive: isActiveData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootStore.fetchTemplatesNew(Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewTemplate = Yup.object().shape({
    name: Yup.string()
    .required('Název šablony musí být vyplněn!'),
    subject: Yup.string()
    .required('Předmět je nutné vyplnit!'),
    htmlText: Yup.string().when(['plainText'], {
      is: (plainText) => {
        return !(plainText)
      },
      then: Yup.string().required('Pokud není zadána textová šablona, musíte zadat html šablonu.')
    }),
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'templates'} 
        filterSearchArray='templatesSearchArray' 
        pageVariable='templatesSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['Name','Subject','IsActive','IsSystem']}  
        title="Šablony e-mailů"
        titleCount = {Number(localStorage.getItem('lastTemplatesSearchTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rightsBH &&
          <span title="Přidat novou šablonu">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat šablonu
          </Button>
          </span>}
        secondaryRightButtons={<EmailingSendEmails showAs='button' />}  
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam šablon neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesTemplatesCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Název</th>
                <th className="jsw-table-head">Stav</th>
                <th className="jsw-table-head">Typ</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesTemplates}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastTemplatesSearchTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání šablony</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90wf"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Šablona e-mailu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Šablona e-mailu byla úspěšně přidána!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewTemplate}
              initialValues={{ 
                subject: newTemplateValues.subject, 
                name: newTemplateValues.name,
                plainText: newTemplateValues.plainText,
                htmlText: newTemplateValues.htmlText,
                isActive: newTemplateValues.isActive, 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewTemplate(values.subject!, values.name!, values.plainText!, values.htmlText!, values.isActive!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6} style={{marginBottom: '10px'}}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="subject">
                    <Form.Row>
                      <FormLabel column lg={3}>Předmět</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl 
                          type="text"
                          name="subject"
                          value={values.subject}
                          onChange={handleChange}
                          isValid={touched.subject && !errors.subject} 
                          isInvalid={!!errors.subject}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.subject}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="plainText">
                    <Form.Row>
                      <FormLabel column lg={3}>Textový obsah</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl as="textarea" style={{height: "150px"}}
                          type="text"
                          name="plainText"
                          value={values.plainText}
                          onChange={handleChange}
                          isValid={touched.plainText && !errors.plainText} 
                          isInvalid={!!errors.plainText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.plainText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>           
                  <FormGroup controlId="htmlText">
                    <Form.Row>
                      <FormLabel column lg={3}>HTML obsah</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl as="textarea" style={{height: "450px"}} 
                          type="text"
                          name="htmlText"
                          value={values.htmlText}
                          onChange={handleChange}
                          isValid={touched.htmlText && !errors.htmlText} 
                          isInvalid={!!errors.htmlText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.htmlText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="isActive">
                    <Form.Row>
                      <FormLabel column lg={3}>Aktivní</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isActive"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isActive}
                          
                          isValid={touched.isActive && !errors.isActive} 
                          isInvalid={!!errors.isActive} 
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isActive}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup>                    
                  {error}
                  <br />
                  
                  </Col>
                  <Col>
                  <strong>Náhled:</strong>
                  <div className="content" dangerouslySetInnerHTML={{__html: values.htmlText!}}></div>
                  </Col>
                </Row>
                  <br /><br />
                <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám šablonu e-mailu...' : 'Uložit šablonu e-mailu'}</Button>
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(OthersTemlatesList))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal } from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface UpgradeGenerateOfferProps {
  mode: string,
  id: string,
  //filterId:number
}

const UpgradeGenerateOffer: React.FC<UpgradeGenerateOfferProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  //const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()

  //rootUpgradeStore.fetchUpgradeOffersScenarios()

  // Šablony e-mailů
  const scenarios = rootUpgradeStore.upgradeOffersScenarios
  .sort((a, b) => {
    if (a.distributionDate! > b.distributionDate!) {
      return -1;
    }
    if (a.distributionDate! < b.distributionDate!) {
      return 1;
    }
    if (a.program.name! > b.program.name!) {
      return 1;
    }
    if (a.program.name! < b.program.name!) {
      return -1;
    }
    
    return 0;
  
  })
  //.sort((a, b) => (a.distributionDate! > b.distributionDate!) ? 1 : -1)
  //.sort((a, b) => (a.program.name! > b.program.name!) ? 1 : -1)
  .map(scenario => (
    <option key={scenario.id} value={scenario.id}>{scenario.program.name} {scenario.programVersion.version}</option>
  ))
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Funkce pro odeslání e-mailu
  const handleGenerateOffer = (scenarioData:string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      /*
      await axios.post(getUrl('URL_BULK_OFFERS'), { mode: props.mode, items: props.id === '' ? [] : [props.id], scenarioId: scenarioData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response)
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
*/
      // Zavolání API
      
      await axios.post(getUrl('URL_OFFERS')+'create', { customerId: props.id, upgradeScenarioId: scenarioData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          (async () => {
            await rootUpgradeStore.fetchUpgradeOffersToCustomer(localStorage.getItem('openedCustomerId')!)
            
            // Nastavovačky, co se má v případě, že je to OK, zobrazit
            await setLoading(false)
            await setShowForm('nodisplay')
            await setShowInfo(true)
            await sessionStorage.setItem('autoCloseModal','1');
            //console.log(response.data)
            //await window.scrollTo(0, 0)
            
            /*
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = response.data.id ;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            */

            // Po 2800 ms celé modální okno zavřeme
            await setTimeout(() => {
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                setShow(false)
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                //window.scrollTo(0, 0)
                //setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);
          }) ()

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    scenarioId: Yup.string()
    .required('Musíte vybrat scénář objednávky!'),
  })   
  return (
    <span>
      <Button variant="success" onClick={() => { handleShow() } }>Vytvořit objednávku</Button>
      

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.mode === 'all' ? 'Hromadné vytvoření objednávek' : 'Vytvoření objednávky'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Objednávka byla úspěšně vytvořena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                scenarioId: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleGenerateOffer(values.scenarioId!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Parametry</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="scenarioId">
                    <Form.Row>
                      <FormLabel column lg={3}>Scénář</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="scenarioId" 
                          onChange={handleChange}
                          value={values.scenarioId!}
                          
                          isInvalid={!!errors.scenarioId}
                          >
                            <option value="">--- Vyberte scénář ---</option>
                            
                            {scenarios}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.scenarioId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Vytvářím objednávku...' : 'Vytvořit objednávku'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(UpgradeGenerateOffer))

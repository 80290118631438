import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, deadLineBadgeState, getUrl} from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import {ButtonGroup, Dropdown, DropdownButton,  Modal, Badge} from 'react-bootstrap'


//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation";

import SupportContactsDetail from './Support.Contacts.Detail.part'
import SupportContactsCustomerInfo from './Support.Contacts.CustomerInfo.part'
import SupportContactsSolveUpdate from './Support.Contacts.SolveUpdate.part'
import SupportContactsSolutionAddUpdate from './Support.Contacts.SolutionAddUpdate.part'

import SupportContactsListSupportTypesIcon from './Support.Contacts.List.part.SupportTypesIcon'

import { useStore} from '../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'

interface SupportContactsListListProps {
  isCustomer?:boolean,
  customerId?:string,
  showLoader?:boolean    
}

const SupportContactsListList: React.FC<SupportContactsListListProps & RouteComponentProps> = (props:any) => {
  // Nastavení stavů pro zobrazení
  
  const [error, setError] = useState(null)

  const [showE, setShowE] = useState(false)

  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()



  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  const customerListAllF = rootStore.customerListAll


  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteContact = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete kontakt "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání kontaktu'})) {
        deleteContact(id)
      } else {
      
      }  
    })()
  }



  
  // Seřazení seznamu podle data pořízení
  const contacts = rootStore.supports.slice().sort(function(a:any, b:any) {
    
    var sort1A = new Date(a.lastEditDate !== '' ? a.lastEditDate : a.createDate)
    var sort1B = new Date(b.lastEditDate !== '' ? b.lastEditDate : b.createDate)

    if (sort1A! < sort1B!) {
      
      
      return (localStorage.getItem('supportOrderBy') === 'LastEditDate' || localStorage.getItem('supportOrderBy') === null || localStorage.getItem('supportOrderBy') === undefined) ? -1 : 1;
    }
    if (sort1A! > sort1B!) {
      return (localStorage.getItem('supportOrderBy') === 'LastEditDate' || localStorage.getItem('supportOrderBy') === null || localStorage.getItem('supportOrderBy') === undefined) ? 1 : -1;
    }
    return 0;
  })

  
  // Seznam kontaktů do řádků tabulky
  const linesContacts = contacts.slice().map((contact) => {
    //console.log('bbbbb')
    return (
    <tr key={contact.id}>
      <td style={{width : '150px'}} title={'Datum vytvoření: '+moment(contact.createDate).format("DD.MM.YYYY HH:mm")+', Datum poslední změny: '+moment(contact.lastEditDate).format("DD.MM.YYYY HH:mm")}>
        {moment(contact.lastEditDate !== '' ? contact.lastEditDate : contact.createDate).format("DD.MM.YYYY HH:mm")}
        
      </td>
      <td style={{width: '70px'}}>
        <SupportContactsListSupportTypesIcon supportTypeId={contact.supportTypeId!} />
      </td>
      {props.isCustomer !== true &&
        <td style={{width : '70px'}}>
          {contact.customerId === null && contact.accountNumber}
          {contact.customerId !== null && <SupportContactsCustomerInfo customerId={customerListAllF.find(cla => cla.id === contact.customerId)?.id!} isActiveCustomer={customerListAllF.find(cla => cla.id === contact.customerId)?.isActive!} customerName={contact.accountNumber!} customerNameFull={customerListAllF.find(cla => cla.id === contact.customerId)?.businessName}  /> }    
        </td>
      }
      {props.isCustomer !== true &&
        <td style={{width : '280px'}}>
          
          {contact.customerId !== null && <SupportContactsCustomerInfo customerId={customerListAllF.find(cla => cla.id === contact.customerId)?.id!} isActiveCustomer={customerListAllF.find(cla => cla.id === contact.customerId)?.isActive!} customerName={customerListAllF.find(cla => cla.id === contact.customerId)?.businessNameShort} customerNameFull={customerListAllF.find(cla => cla.id === contact.customerId)?.businessName}  /> }  
          
        </td>
      }
      <td>{contact.businessName}</td>
      <td>
        <SupportContactsDetail supportId={contact.id} isCustomer={props.isCustomer} fromList={true} />
      </td>
      <td>
        <Badge variant='secondary' title='Počet řešení' style={{marginRight: '5px'}} >{contact.solutionCount}</Badge>
        {contact.isFinished === false && <Badge variant={deadLineBadgeState(contact?.deadLineDate!.replace(' ', 'T'))} title={contact.deadLineDate !== '' ? new Date(contact?.deadLineDate!.replace(' ', 'T')) >= new Date() ? 'Nevyřešeno před termínem '+moment(contact.deadLineDate).format("DD.MM.YYYY HH:mm"):'Nevyřešeno po termínu '+moment(contact.deadLineDate).format("DD.MM.YYYY HH:mm"):'Nevyřešeno'}>Nevyřešeno</Badge>}
        {contact.isTrouble === true && <Badge style={{marginLeft: '10px'}} variant='danger'>Problém</Badge>}

      </td>
      <td title={"Termín: "+moment(contact.deadLineDate).format("DD.MM.YYYY HH:mm")}>{rootStore.hedgehogUsers.find(hu => hu.id === contact.solverUserId)?.fullName}</td>
      {rights &&<td>
      <div className="float-left"></div>
      <ButtonGroup>
        <SupportContactsDetail supportId={contact.id} isCustomer={props.isCustomer} />
        <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
        {contact.customerId !== null && props.isCustomer !== true && 
          <>
            <SupportContactsCustomerInfo customerId={customerListAllF.find(cla => cla.id === contact.customerId)?.id!} isActiveCustomer={customerListAllF.find(cla => cla.id === contact.customerId)?.isActive!} customerName={customerListAllF.find(cla => cla.id === contact.customerId)?.businessName} fromSubMenu={true}  /> 
            
          </>
          } 
          <SupportContactsSolutionAddUpdate supportId={contact?.id} showAs="drop" />
          <SupportContactsSolveUpdate supportId={contact?.id} showAs="drop" />
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => { handleOnClickDeleteContact(contact.id, contact.subject!) } } style={{color: 'red'}} >Smazat kontakt</Dropdown.Item>
        </DropdownButton>
      </ButtonGroup>
      </td>}
    </tr>
  )})
  

  // Funkce pro smazání pozvánky
  const deleteContact = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_SUPPORT_CONTACTS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchSupports(1,props.isCustomer)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }


      


  return (
    <>
      {linesContacts}


      <Modal 
        show={showE} 
        onHide={handleClose} 
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      
      
    </>
  )  
  
}

export default withRouter(observer(SupportContactsListList))

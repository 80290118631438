import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../parts/TopMenu/SideMenu.parts'

import MeetingsList from './components/Meetings.List.part'

import { Container } from 'react-bootstrap'

import { useMount } from '../../helpers/lifecycle-hooks'
import { useDialsStore } from '../../store'
import { useStore } from '../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../Utils/Common'

//import axios from 'axios'

function Meetings(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  
  // Nastavení práv
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Načtení Store
  const rootDialsStore = useDialsStore()
  const rootStore = useStore()
  

  //const partName = "submissions"

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'meetings')

    await checkVersion()

    if (localStorage.getItem('meetingsOrderBy') === null || localStorage.getItem('meetingsOrderBy') === undefined) localStorage.setItem('meetingsOrderBy', 'DateDesc')
    

    let meetingsSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
    localStorage.setItem('meetingsSearchArray',JSON.stringify(meetingsSearchDataArray))

    sessionStorage.setItem('meetingsSearchPage', '1')

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
          }
          //await rootStore.fetchCustomerList('','',1)
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')

          
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      }  

      await rootStore.fetchUnreadMeetingIds()

      //Práce pro nastavení výchozího filtru dle preferencí
      await rootStore.fetchLoggedUserPreferences()
      const meetingsDefaultFilter = rootStore.getLoggedUserPreferenceByKey('meetingsDefaultFilter')?.value

      if (meetingsDefaultFilter === 'UnreadMeetings') {
        meetingsSearchDataArray.push({filterName: meetingsDefaultFilter, filterValue: '(Cokoliv, přepisuje se)', displayValue: "Ano" })
        localStorage.setItem('meetingsSearchArray',JSON.stringify(meetingsSearchDataArray))
        sessionStorage.setItem('meetingsSearchPage', '1')
      }

      //Načtení porad
      await rootStore.fetchMeetings( Number(sessionStorage.getItem('meetingsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('meetingsSearchPage')))
    }  
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    rootStore.fetchHedgehogUsersFromAccounts()
    //rootStore.fetchHedgehogUsersFromAccountsUserGroups()
    rootDialsStore.fetchDialsMeetingsCategoriesAll()
    await rootDialsStore.fetchDialsUserGroupsAll()
    rootDialsStore.fetchDialsUserGroupsAllUsers()
    rootDialsStore.fetchDialsRoles()
    })() 
  })
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu loadingVersion={!loaded} />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <Container fluid>
                <br />
                              
              </Container>

              <MeetingsList />
              <br /><br />
            </Loader>  
            </main>
          </div>
        </div>      
      
    </div> 
  );
}

export default observer(Meetings)

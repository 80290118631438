import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken } from '../../Utils/Common'


import { Button, ButtonGroup,  Form, Col, Modal, Dropdown, Card, Row, Container, Table, Spinner, Badge } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'
import moment from 'moment'

interface CustomersOtherActionsEmailHistoryProps {
  showAs: string,
  email: string
}

const CustomersOtherActionsEmailHistory: React.FC<CustomersOtherActionsEmailHistoryProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
 
  // Připojení store
  const rootStore = useStore()
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    rootStore.deleteCustomerOpenedEmailSent()
    sessionStorage.removeItem('emailsSentSearchValue')
    sessionStorage.removeItem('emailsSentSearchTypeValue')
    sessionStorage.removeItem('emailsSentSearchTIDValue')
    sessionStorage.removeItem('emailsSentSearchPage')
    localStorage.removeItem('customerEmailsSentTotalCount')

    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    sessionStorage.removeItem('autoCloseModal')
    setLoaded(false)
    
    if (!(sessionStorage.getItem('emailsSentSearchValue') === '' || sessionStorage.getItem('emailsSentSearchValue') === undefined || sessionStorage.getItem('emailsSentSearchValue') === null) || !(sessionStorage.getItem('emailsSentSearchTIDValue') === '' || sessionStorage.getItem('emailsSentSearchTIDValue') === undefined || sessionStorage.getItem('emailsSentSearchTIDValue') === null)) {
      setShowCancel(true);
    }

    if (rights) rootStore.fetchTemplates('','',true,true,9999,1);
    //if (rights) rootStore.fetchTemplates('','',true,false,9999,1) 
    (async () => { 
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout') 
      }      
      
      await rootStore.fetchCustomerOpenedEmailSentByEmail(props.email, sessionStorage.getItem('emailsSentSearchTypeValue')! === 'TID' ? sessionStorage.getItem('emailsSentSearchTIDValue')! : sessionStorage.getItem('emailsSentSearchValue')!, sessionStorage.getItem('emailsSentSearchTypeValue')!,Number(sessionStorage.getItem('emailsSentSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('emailsSentSearchPage'))) 
      setLoaded(true)
    })()
    setError(null)
    //setShowInfo(false)
    //setShowForm('')
    setShow(true)

  }

    //Počet záznamů
    const countData:number = Object.keys(rootStore.customerOpenedEmailsSent).length

    // Šablony e-mailů
    const templates = rootStore.templates.map(template => (
      <option key={template.id} value={template.id}>{template.name}</option>
    ))

    // Seznam e-mailů do karet
    const emailsSentDataCard = rootStore.customerOpenedEmailsSent.map(customerOpenedEmailSent => (
      <div key={customerOpenedEmailSent.id}>
      <Card>
        <Card.Body>
            <div className="float-right">
              
            </div>
            {moment(customerOpenedEmailSent.sendDate).format("DD.MM.YYYY HH:mm")}<br />
            <strong>{customerOpenedEmailSent.templateName}</strong><br />
            <i>{customerOpenedEmailSent.userFullName}</i><br />
            {customerOpenedEmailSent.customerId === localStorage.getItem('openedCustomerId') ? <Badge variant="success">{customerOpenedEmailSent.customerBusinessName!}</Badge>:<Badge variant="secondary">{customerOpenedEmailSent.customerBusinessName!}</Badge>}
        </Card.Body>
      </Card>
      <br />
      </div>
    ))

      // Seznam e-mailů do řádků tabulky
  const emailsSentDataTable = rootStore.customerOpenedEmailsSent.map(customerOpenedEmailSent => (
    <tr key={customerOpenedEmailSent.id}>
      <td>{moment(customerOpenedEmailSent.sendDate).format("DD.MM.YYYY HH:mm")}</td>
      <td>{customerOpenedEmailSent.templateName}</td>
      <td>{customerOpenedEmailSent.userFullName}</td>
      <td>{customerOpenedEmailSent.customerId === localStorage.getItem('openedCustomerId') ? <Badge variant="success">{customerOpenedEmailSent.customerBusinessName !== null ? customerOpenedEmailSent.customerBusinessName!.substring(0,10):''}...</Badge>:<Badge variant="secondary">{customerOpenedEmailSent.customerBusinessName!}</Badge>}</td>
    </tr>
  ))

  const handlePageChange = (pageNumber:number) => {
    (async () => { 
      setLoaded(false);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout') 
      }
      sessionStorage.setItem('emailsSentSearchPage', String(pageNumber))
      // Předat hledanou hodnotu action pro načtení zákazníků
      await rootStore.fetchCustomerOpenedEmailSentByEmail(props.email, sessionStorage.getItem('emailsSentSearchTypeValue')! === 'TID' ? sessionStorage.getItem('emailsSentSearchTIDValue')! : sessionStorage.getItem('emailsSentSearchValue')!, sessionStorage.getItem('emailsSentSearchTypeValue')!, pageNumber)
      setLoaded(true);
      // Hodnoty hledání uložit do sessionStorage
      
    })()
  }

  // Pro BE hledání
  const handleSearch = (emailsSentSearchTypeData:string, emailsSentSearchData:string, emailsSentSearchTIDData:string) => {
    setError(null)
    setLoaded(false)
    setLoading(true);
    (async () => { 
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Předat hledanou hodnotu action pro načtení zákazníků
      await rootStore.fetchCustomerOpenedEmailSentByEmail(props.email, emailsSentSearchTypeData === 'TID' ? emailsSentSearchTIDData : emailsSentSearchData, emailsSentSearchTypeData, 1)
      setLoading(false)
      setLoaded(true)
      // Hodnoty hledání uložit do sessionStorage
      sessionStorage.setItem('emailsSentSearchTypeValue', emailsSentSearchTypeData)
      sessionStorage.setItem('emailsSentSearchTIDValue', emailsSentSearchTIDData)
      sessionStorage.setItem('emailsSentSearchValue', emailsSentSearchData)
      sessionStorage.setItem('emailsSentSearchPage', '1')
      if (emailsSentSearchData === '' && emailsSentSearchTIDData === '') { setShowCancel(false) } else { setShowCancel(true) }
    })()
  }

  // Schéma validace formuláře
  const schema = Yup.object().shape({
      
  })  
  
  const options = <>
  <option value="TID">Název šablony</option>
                                    <option value="UN">E-mail odesílatele</option>
  </>
  return (
    <span>
      {rights &&<>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Odeslané e-maily</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Odeslané e-maily</Button>}
      </>}
      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90w"
        style={{maxWidth: 'none !important'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Odeslané e-maily <br /><Badge variant="secondary" style={{fontSize: '20px'}}>{props.email}</Badge></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '180px'}}>
          <div>
                  
                  <Row sm={1} md={2}>
                    <Col
                      md={{order:1}}
                      sm={{order:2}}
                      xs={{order:2}}
                      className="col-open-customer"
                    >

                      <Container>
                      
                      
                      <Formik
                        validationSchema={schema}
                        initialValues={{ 
                          emailsSentSearchType: sessionStorage.getItem('emailsSentSearchTypeValue') !== null ? sessionStorage.getItem('emailsSentSearchTypeValue')! : 'TID', 
                          emailsSentSearchText: sessionStorage.getItem('emailsSentSearchValue') !== null ? sessionStorage.getItem('emailsSentSearchValue')! : '',
                          templateId: sessionStorage.getItem('emailsSentSearchTIDValue') !== null ? sessionStorage.getItem('emailsSentSearchTIDValue')! : '',  
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleSearch(values.emailsSentSearchType, values.emailsSentSearchText, values.templateId)              
                            setSubmitting(false);
                          }, 400);
                        }}
                      >
                        {({ 
                          handleSubmit,
                          handleChange,
                          handleReset,
                          setFieldValue,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting }) => (
                          <Form onSubmit={handleSubmit}>
                              
                              <FormGroup controlId="formEmailsSentSearchType">
                              <Form.Row>
                                <Form.Control as="select" 
                                  name="emailsSentSearchType"
                                  defaultValue={sessionStorage.getItem('emailsSentSearchTypeValue')! !== null ? sessionStorage.getItem('emailsSentSearchTypeValue')! : 'TID'} 
                                  onChange={handleChange}
                                  
                                  >
                                    
                                    {options}
                                </Form.Control>
                                </Form.Row>
                              </FormGroup> 
                              {values.emailsSentSearchType !== 'TID' &&<FormGroup controlId="formEmailsSentSearchText">
                                <Form.Row>
                                  <label className="integratedButton">
                                    <FormControl 
                                      autoFocus
                                      type="text"
                                      name="emailsSentSearchText"
                                      value={values.emailsSentSearchText}
                                      onChange={handleChange}
                                      placeholder="Hledaný výraz"
                                      onFocus={function(e:any) { e.target.select()}}
                                      isInvalid={!!errors.emailsSentSearchText}
                                    /> 
                                    
                                  </label>
                                </Form.Row> 
                              </FormGroup>}
                              {values.emailsSentSearchType === 'TID' &&<FormGroup controlId="templateId">
                                <Form.Row>
                                  <label className="integratedButton">
                                    <Form.Control as="select" 
                                      name="templateId" 
                                      onChange={handleChange}
                                      value={values.templateId!}
                                      >
                                        <option value="">--- Vyberte šablonu ---</option>
                                        {templates}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.templateId}
                                    </Form.Control.Feedback>
                                  </label>
                                </Form.Row>  
                              </FormGroup>}
                              <FormGroup controlId="formHistorySearchText">
                                <Form.Row>  
                                  <ButtonGroup  className="btn-block">
                                    <Button type="submit" variant="success" disabled={!(isValid ) || loading} block>{loading ? 'Filtruji...' : 'Filtrovat'}</Button>  
                                    {(values.emailsSentSearchText !== '' || showCancel) &&
                                    <Button 
                                    variant="danger" 
                                    size="sm" 
                                    disabled={loading ? true : false} 
                                    onClick={() => { 
                                      if ((sessionStorage.getItem('emailsSentSearchValue') === '' || sessionStorage.getItem('emailsSentSearchValue') === undefined || sessionStorage.getItem('emailsSentSearchValue') === null) && (sessionStorage.getItem('emailsSentSearchTIDValue') === '' || sessionStorage.getItem('emailsSentSearchTIDValue') === undefined || sessionStorage.getItem('emailsSentSearchTIDValue') === null))
                                        //{ sessionStorage.setItem('selectionDetailOfferSearchTextValue',''); sessionStorage.setItem('selectionDetailOfferSearchTypeValue',''); sessionStorage.setItem('selectionDetailOfferSearchScenarioValue',''); sessionStorage.setItem('selectionDetailOfferPage', '1'); window.location.reload(); } 
                                        { setFieldValue('emailsSentSearchText',''); } 
                                      else 
                                        { setFieldValue('emailsSentSearchText',''); values.templateId = '';  handleSearch(values.emailsSentSearchType, '', '') ;}   
                                      }} >
                                    Zrušit filtrování
                                  </Button>
                                    }
                                  </ButtonGroup>
                                </Form.Row> 
                              </FormGroup>                      
                            {error}
                            <br />
                            
                            
                          </Form>
                        )}
                      </Formik> 
                      </Container> 
                    </Col>
                    <Col 
                      md={{order:2}}
                      sm={{order:1}}
                      xs={{order:1}}
                      className="col-open-customer"
                    >
                      
                      
                      <br />                   
                    </Col>
                  </Row>
                </div>

                
                {loaded === false &&<div style={{textAlign: 'center', minHeight: '100px', paddingTop: '30px'}}><Spinner animation="border"  /></div>}
                
                {loaded === true &&<>
                


                  <div className="showMobile">
                  {countData === 0 ? <div>Histoie odeslaných e-mailů neobsahuje žádné záznamy.<br /><br /></div> : ''}
                      {emailsSentDataCard}
                  </div>
                  <div className="showWeb">
                    <Table responsive hover>
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head">Datum a čas</th>
                          <th className="jsw-table-head">Šablona</th>
                          <th className="jsw-table-head">Odesílatel</th>
                          <th className="jsw-table-head" style={{width: '50px'}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {emailsSentDataTable}
                      </tbody>
                    </Table> 
                    {countData === 0 ? <div>Histoie odeslaných e-mailů neobsahuje žádné záznamy.<br /><br /></div> : ''}
                  </div> 
                  <br />  
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={Number(sessionStorage.getItem('emailsSentSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('emailsSentSearchPage')) }
                    itemsCountPerPage={10}
                    totalItemsCount={Number(localStorage.getItem('customerEmailsSentTotalCount'))}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(1)}
                    />
                  </>}
                
                {error}
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsEmailHistory))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../../Utils/Confirmation";

import DialsDeliveryCzechPostTemplateUpdate from './Dials.Delivery.CzechPost.Templates.Update.part'

import { useDialsStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../../../sharedComponents/filterPanel/FilterPanel.part'
import SelectOptionsDeliveryCzechPostProducts from '../../../../../sharedComponents/filterPanel/components/SelectOptions.DeliveryCzechPostProducts'
//import SelectOptionsDeliveryCzechPostServices from '../../../../../sharedComponents/filterPanel/components/SelectOptions.DeliveryCzechPostServices'



const DialsDeliveryCzechPostTemplatesList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')

    rootDialsStore.dialsDeliveryCzechPostServicesAll.map( item => (
      item.setChecked(false)
      //return ()
    ))

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete šablonu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání šablony pro Českou poštu'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  //const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  
  
  const items = rootDialsStore.dialsDeliveryCzechPostTemplates.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td>{item.note}</td>
      <td></td>
      {rights &&<td>
        <ButtonGroup>
          <DialsDeliveryCzechPostTemplateUpdate id={item.id} showAs='button' />
      
          {rightsBH &&<>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>} 
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{item.name}</strong><br />
          <strong>{item.note}</strong><br />
          
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rights && <>
          <ButtonGroup>
            <DialsDeliveryCzechPostTemplateUpdate id={item.id} showAs='button' />
            {rightsBH &&<>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                {rightsBH && <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>} 
              </DropdownButton>  
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_DIALS_DELIVERY_CZECH_POST_TEMPLATES')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootDialsStore.fetchDialsDeliveryCzechPostTemplates(Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('dialsDeliveryCzechPostTemplatesSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootDialsStore.fetchDialsDeliveryCzechPostTemplates(pageNumber)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('dialsDeliveryCzechPostTemplatesOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('dialsDeliveryCzechPostTemplatesSearchPage', String(1))
        await rootDialsStore.fetchDialsDeliveryCzechPostTemplates(1)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (noteData:string, nameData:string, technologicalNumberData:string, weightData:number, productIdData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_DIALS_DELIVERY_CZECH_POST_TEMPLATES'), 
          { name: nameData, 
            note: noteData, 
            technologicalNumber: technologicalNumberData,
            productId: productIdData,
            weight: weightData,
            services: rootDialsStore.dialsDeliveryCzechPostServicesAll.filter(items => items.checked === true).map(item => item.id) 
          }, 
          { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootDialsStore.fetchDialsDeliveryCzechPostTemplates(Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    name: Yup.string()
    .required('Název šablony musí být vyplněn!'),
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'dialsDeliveryCzechPostTemplates'} 
        filterSearchArray='dialsDeliveryCzechPostTemplatesSearchArray' 
        pageVariable='dialsDeliveryCzechPostTemplatesSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['Name']}  
        title="Šablony zásilek pro Českou poštu"
        titleCount = {Number(localStorage.getItem('lastDialsDeliveryCzechPostTemplatesTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rightsBH &&
          <span title="Přidat novou šablonu">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat šablonu zásilky
          </Button>
          </span>}
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam šablon neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === 'Name' || localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === null || localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === undefined) ? 'NameDesc' : 'Name'  )}>
                  Název
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === 'Name' || localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === null || localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === 'NameDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head">Poznámka</th>
                <th className="jsw-table-head">Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('dialsDeliveryCzechPostTemplatesSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastDialsDeliveryCzechPostTemplatesTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání šablony zásilky pro Českou poštu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Šablona zásilky pro Českou poštu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Šablona zásilky byla úspěšně přidána!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              initialValues={{ 
                note: '', 
                name: '',
                technologicalNumber: 'M60388',
                weight: 0.500,
                productId: '',
                changes:''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.note!, values.name!, values.technologicalNumber!,values.weight!,values.productId!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="technologicalNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Identifikátor podavatele</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="technologicalNumber"
                          value={values.technologicalNumber}
                          onChange={handleChange}
                          isValid={touched.technologicalNumber && !errors.technologicalNumber} 
                          isInvalid={!!errors.technologicalNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.technologicalNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="weight">
                    <Form.Row>
                      <FormLabel column lg={3}>Váha ($,$$$)</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="weight"
                          value={values.weight}
                          onChange={handleChange}
                          isValid={touched.weight && !errors.weight} 
                          isInvalid={!!errors.weight}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.weight}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="productId">
                    <Form.Row>
                      <FormLabel column lg={3}>Produkt</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="productId" 
                          onChange={handleChange}
                          value={values.productId!}
                          isInvalid={!!errors.productId}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            <SelectOptionsDeliveryCzechPostProducts />
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.productId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <h5>Služby</h5>

                  <Table hover>
                    <tbody>
                      {rootDialsStore.dialsDeliveryCzechPostServicesAll.map(item => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={item.checked}
                              className=""
                              id={'rowcheck'+item.id}
                              onChange={() => {item.setChecked(!item.checked);; setFieldValue("changes","")}}
                              style={{height: '18px', width: '18px'}}
                            />
                          </td>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                                    
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám šablonu...' : 'Uložit šablonu'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(DialsDeliveryCzechPostTemplatesList))

import { types } from 'mobx-state-tree'


export const AppProduelReceipts = types
  .model({
    id: types.optional(types.string, ''),
    signature: types.optional(types.string, ''),
    amount: types.optional(types.number, 0),
    currencyCode: types.optional(types.string, ''),
    note: types.maybeNull(types.string),
    receiptDate: types.optional(types.string, ''),
    createDate: types.optional(types.string, ''),
    processDate: types.maybeNull(types.string),
    isProcessed: types.optional(types.boolean, false),
    image: types.maybeNull(types.string),
  })
  .views(self => ({
    get imageBase64stringToData() {
      return `data:image/png;base64,${self.image}`
    }
    
  }))
  .actions(self => ({
    
  }))

import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl, getUser } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Badge, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation";

import TasksUpdate from './Tasks.Update.part'
import TasksAdd from './Tasks.Add.part'
import TasksDetail from './Tasks.Detail.part'

//import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'

import moment from 'moment'
//import NumberFormat from 'react-number-format'

const TasksList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  //const rightsMeeting:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false
  //const rightsManagement:boolean = ((localStorage.getItem('Management')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    rootStore.hedgehogUsers.map((hu,index) => {
      hu.selectItem(false)
      return ''
    })
  }
//eslint-disable-next-line
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')

    if (sessionStorage.getItem('tasksSearchValue') !== '') {
      
    }



    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }



  

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete úkol "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání úkolu'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  
  
  const items = rootStore.tasks.slice()


  // Seznam pracovníků JSW 
  const tasksHedgehogs = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === true)
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.surName; 
    var sort1B = b.surName; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(hu => (
    <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
  ))  
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id} style={{color: item.isCompleted === true ? '#c7c5c5' : '', backgroundColor: (item.isDone === true && item.isCompleted === false && item.authorId === getUser().id) ? '#ebffee':''}}>
      <td>{moment(new Date(item.deadLine!)).format("DD.MM.YYYY HH:mm")}</td>
      <td><TasksDetail taskId={item.id} fromSubMenu={false} fromList={true}  /></td>
      <td>{rootStore.hedgehogUsers.find(hu => hu.id === item.authorId)?.fullName}</td>
      <td>{rootStore.hedgehogUsers.find(hu => hu.id === item.responsibleUserId)?.fullName}</td>
      <td>
        {item.isDone === true && <Badge variant="success" title={'Datum splnění úkolu: '+moment(new Date(item.doneDate!)).format("DD.MM.YYYY HH:mm")}>Splněno</Badge>}
        {item.isCompleted === true  && <>&nbsp;<Badge variant="success" title={'Datum uzavření úkolu: '+moment(new Date(item.completedDate!)).format("DD.MM.YYYY HH:mm")}>Uzavřeno</Badge></>}
        {item.isPrivate === true  && <>&nbsp;<Badge variant="warning" title={'Příznak, že úkol byl označen jako soukromý'}>Soukromý</Badge></>}
        {item.meetingId !== null  && <>&nbsp;<Badge variant="primary" title={'Příznak, že úkol vytvořen z porady'}>Porada</Badge></>}
      </td>
      {rights &&<td>
        <ButtonGroup>
          
          <TasksDetail taskId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
          <>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            
            
            <TasksUpdate id={item.id} showAs='drop' />
            {(item.authorId === getUser().id && item.meetingId === null) && <>
              <Dropdown.Divider />
              <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.deadLine!)).format("DD.MM.YYYY HH:mm")) } }>Smazat</Dropdown.Item>
            </>}
          </DropdownButton>
          </>
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{moment(new Date(item.deadLine!)).format("DD.MM.YYYY HH:mm")}</strong><br />
          <strong>{item.text}</strong><br />
          <strong>Autor</strong>: {rootStore.hedgehogUsers.find(hu => hu.id === item.authorId)?.fullName}<br />
          <strong>Odpovídá</strong>: {rootStore.hedgehogUsers.find(hu => hu.id === item.responsibleUserId)?.fullName}<br />
          
          {item.isDone === true && <Badge variant="success" title={'Datum splnění úkolu: '+moment(new Date(item.doneDate!)).format("DD.MM.YYYY HH:mm")}>Splněno</Badge>}
          {item.isCompleted === true  && <>&nbsp;<Badge variant="success" title={'Datum uzavření úkolu: '+moment(new Date(item.completedDate!)).format("DD.MM.YYYY HH:mm")}>Uzavřeno</Badge></>}
          {item.isPrivate === true  && <>&nbsp;<Badge variant="warning" title={'Příznak, že je úkol označen jako soukromý'}>Soukromý</Badge></>}
          {item.meetingId !== null  && <>&nbsp;<Badge variant="primary" title={'Příznak, že byl úkol vytvořen z porady'}>Porada</Badge></>}
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rights && <>
          <ButtonGroup>
            <TasksDetail taskId={item.id} fromSubMenu={false} setPageLoading={setLoading} />
            
            <>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                
                
                <TasksUpdate id={item.id} showAs='drop' />
                {(item.authorId === getUser().id && item.meetingId === null) && <>
                <Dropdown.Divider />
                <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,moment(new Date(item.deadLine!)).format("DD.MM.YYYY HH:mm")) } }>Smazat</Dropdown.Item>
                </>}
              </DropdownButton>  
            </>
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_TASKS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchTasks(Number(sessionStorage.getItem('tasksSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('tasksSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

  

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('tasksSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootStore.fetchTasks(pageNumber)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('tasksOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('tasksSearchPage', String(1))
        await rootStore.fetchTasks(1)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (deadlineData:string, textData:string, responsibleUserIdData:string, isPrivateData:boolean) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_TASKS'), { deadline: new Date(deadlineData).toISOString(), text: textData, responsibleUserId: responsibleUserIdData, isPrivate: isPrivateData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootStore.fetchTasks(Number(sessionStorage.getItem('tasksSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('tasksSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }






  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    deadline: Yup.string()
    .required('Termín splnění musí být vyplněn!'),
    
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'tasks'} 
        filterSearchArray='tasksSearchArray' 
        pageVariable='tasksSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['DeadLine','AuthorId','IsPrivate','IsDone','IsCompleted','Text','ResponsibleUserId','MyTasks','MyNotFinishTasks','MyNotFinishTasksPrivate','MyNotFinishTasksAfterTerm','AllNotFinishTasksAfterTerm','AuthorNotFinishTasks','AuthorNotFinishTasksAfterTerm','AuthorNotClosedTasks']}  
        title="Úkoly"
        titleCount = {Number(localStorage.getItem('lastTasksSearchTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rights &&
          <span title="Přidat nový úkol">
          <TasksAdd showAs='button' style={{marginBottom: '15px', marginLeft: '0px'}} />
          </span>}
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam úkolů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th title='Datum a čas termínu splnění' className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('tasksOrderBy') === 'Deadline' || localStorage.getItem('tasksOrderBy') === null || localStorage.getItem('tasksOrderBy') === undefined) ? 'DeadlineDesc' : 'Deadline'  )}>
                  Termín splnění
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('tasksOrderBy') === 'Deadline' || localStorage.getItem('tasksOrderBy') === null || localStorage.getItem('tasksOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('tasksOrderBy') === 'DeadlineDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head" title='Text úkolu - pouze část textu'>Úkol</th>
                <th className="jsw-table-head" title='Autor úkolu'>Autor</th>
                <th className="jsw-table-head" title='Osoba odpovědná za řešení úkolu'>Odpovídá</th>

                
                
                <th className="jsw-table-head" title='Stav, ve kterém se úkol momentálně nachází'>Stav</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('tasksSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('tasksSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastTasksSearchTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání úkolu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Přidání úkolu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Úkol byl úspěšně přidán!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              initialValues={{ 
                deadline: moment(new Date()).format("YYYY-MM-DDTHH:mm"), 
                text: '', 
                
                responsibleUserId: '',
                isPrivate: false,
                temp: '',

              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.deadline!, values.text!, values.responsibleUserId!, values.isPrivate!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="deadline">
                    <Form.Row>
                      <FormLabel column lg={3}>Termín</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="deadline"
                          value={values.deadline}
                          onChange={handleChange}
                          isValid={touched.deadline && !errors.deadline} 
                          isInvalid={!!errors.deadline}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.deadline}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="text">
                    <Form.Row>
                      <FormLabel column lg={3}>Úkol</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="text"
                          value={values.text}
                          onChange={handleChange}
                          isValid={touched.text && !errors.text} 
                          isInvalid={!!errors.text}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="responsibleUserId">
                    <Form.Row>
                      <FormLabel column lg={3}>Odpovídá</FormLabel>
                      <Col>
                          <Form.Control as="select" 
                            name="responsibleUserId" 
                            //onChange={async(e) => {await handleChange(e); await controlAfterSelectType(e.target.value);  if (rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId !== '') { await setFieldValue("solutionSupportTypeId", rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId ) } }}
                            onChange={handleChange}
                            value={values.responsibleUserId!}
                            isInvalid={!!errors.responsibleUserId}

                            //tabIndex={4}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 3:7, handleSubmit)}
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              tasksHedgehogs
                              }
                          </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.responsibleUserId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isPrivate">
                    <Form.Row>
                      <FormLabel column lg={3}>Soukromý</FormLabel>
                      <Col>
                      <FormControl 
                          type="checkbox"
                          name="isPrivate"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isPrivate}
                          //disabled={rightsManagement === false}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isPrivate && !errors.isPrivate} 
                          isInvalid={!!errors.isPrivate}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isPrivate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                         
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám úkol...' : 'Uložit úkol'}</Button>

                  
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(TasksList))

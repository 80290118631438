import { types } from 'mobx-state-tree'


export const Templates = types
  .model({
    id: types.optional(types.string, ''),
    //created: types.maybeNull(types.string),
    subject: types.optional(types.string, ''),
    //purpose: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    htmlText: types.maybeNull(types.string),
    plainText: types.maybeNull(types.string),
    isActive: types.optional(types.boolean, false),
    isSystem: types.optional(types.boolean, false),
    variables: types.maybeNull(types.array(types.string)),
  })
  .views(self => ({
    get variablesData() {
      return self.variables?.map(vr => ({name: vr, value: ''}) )
    }  
  }))
  .actions(self => ({
    

  }))

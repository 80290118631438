import React, { useState, useEffect }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import TasksSolutionAddUpdate from './Tasks.SolutionAddUpdate.part'

import axios from 'axios';

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { confirm } from "../../../Utils/Confirmation";

import { Formik } from 'formik'
import * as Yup from 'yup'

import { TextEditor } from "../../../Utils/draft-js/TextEditor";

//import moment from 'moment'

import FilesAdd from '../../../parts/files/Files.Add.part'

import LoadFreeText from '../../../sharedComponents/loadFreeText/LoadFreeText.part'

interface TasksPointAddUpdateProps {
  editing?:boolean,
  taskId?:string,
  pointId?:string,
  isCustomer?:boolean,
  showAs?:string,
  masterRights?:boolean,    
} 

const TasksPointAddUpdate: React.FC<TasksPointAddUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [insertNewContact, setInsertNewContact] = useState(false)
  
  const [showSolText, setShowSolText] = useState(true);

  
  // Připojení store
  const rootStore = useStore()

  // Nastavení editivaného bodu úkolu
  const editedPoint = rootStore.getTaskById(props.taskId)?.points.find(p => p.id === props.pointId)

  // Seznam pracovníků JSW pro lookup
  const tasksHedgehogs = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === true)
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.surName; 
    var sort1B = b.surName; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(hu => (
    <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
  )) 

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    //clearInterval(interval)
  } 
 
   
  useEffect(() => {
    return () => {
      deleteInterval()
    }
  }, [])

  const deleteInterval = async () => {
    for (var i = 0; i < 99999; i++)
	    clearInterval(i);
  }
  
  // Dotaz před smazáním bodu úkolu
  const handleOnClickDeletePoint = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete bod úkolu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání bodu úkolu'})) {
        deletePoint(id)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání bodu úkolu
  const deletePoint = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      
      //Před smazáním dohledáme soubory
      await rootStore.batchDeleteFilesByLinkId(id)

      // Zavolání API
      await axios.delete(getUrl('URL_TASKS')+'/'+props.taskId+'/points/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            

          sessionStorage.setItem('autoCloseModal','1')

          rootStore.fetchTaskPoints(props.taskId)
          sessionStorage.setItem('taskDetailEdited', 'Y')

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
   
      })()    
  }


  // Funkce po odeslání formuláře
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend:any
      let urlForEditing:string = ''

      if (props.editing === true) {
        dataToSend = {
          id: props.pointId,
          text: values.text,  
          responsibleUserId: values.responsibleUserId !== '' ? values.responsibleUserId : null,    
          isDone: values.isDone,    
        }
        urlForEditing = '/'+props.pointId
        }
        else {
          dataToSend = {
            text: values.text,
            responsibleUserId: values.responsibleUserId !== '' ? values.responsibleUserId : null,  
            }
        }

      // Zavolání API
      await axios.post(getUrl('URL_TASKS')+'/'+props.taskId+'/points'+urlForEditing, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          setInsertNewContact(false)
          sessionStorage.setItem('autoCloseModal','1')
          rootStore.fetchTaskPoints(props.taskId)
          rootStore.fetchTaskSolutions(props.taskId)
          //clearInterval(interval)

          sessionStorage.setItem('taskDetailEdited', 'Y')


          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShowForm('')
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    text: Yup.string()
      .required('Text bodu úkolu musí být vyplněn!'),
    
  })  
  
 // Započetí odpočítávání délky řešení kontaktu
 const startCounting = async (startD:any) => {
  await deleteInterval()
  
  
}

// Startovací sequence při otevření formuláře
const startSequence = () => {
  setInsertNewContact(true)
  //setStartDate(new Date()) 
  setShowInfo(false) 
  setShowForm('')
  startCounting(new Date()) 
   
}

const handleKeyDownCtrlEnter = (e:any, handleSubmit:any) => {
  
  if (e.keyCode === 13 && e.ctrlKey && !loading) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
  }
 
};

// VYKRESLENÍ FORMULÁŘE S BODEM ÚKOLU 
const contactForm = 
      <>
          <Alert variant="success" show={showInfo}>
            <strong>Bod úkolu úspěšně {props.editing === true ? 'upraven' : 'přidán'}!​</strong>
            
          </Alert>  
          {insertNewContact &&<>
          <div className={showForm} >
          <Alert variant="info" > 
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                text: props.editing === true ?  editedPoint?.text : '',                
                responsibleUserId: props.editing === true ? editedPoint?.responsibleUserId : rootStore.getTaskById(props.taskId)?.responsibleUserId,
                isDone: props.editing === true ? editedPoint?.isDone : false,
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values!)              
                  setSubmitting(false);
                }, 1);
              }}

            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                    <strong>Bod úkolu</strong><br />
                    </Form.Row>
                    
                    
                    <FormGroup controlId="responsibleUserId">
                    <Form.Row>
                      <FormLabel column lg={3}>Odpovídá</FormLabel>
                      <Col>
                          <Form.Control as="select" 
                            name="responsibleUserId" 
                            //onChange={async(e) => {await handleChange(e); await controlAfterSelectType(e.target.value);  if (rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId !== '') { await setFieldValue("solutionSupportTypeId", rootDialsStore.dialsSupportTypes.find(st => st.id === e.target.value)?.solutionDefaultTypeId ) } }}
                            onChange={handleChange}
                            value={values.responsibleUserId!}
                            isInvalid={!!errors.responsibleUserId}
                            disabled={props.masterRights === true ? false : true}
                            //tabIndex={4}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 3:7, handleSubmit)}
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              tasksHedgehogs
                              }
                          </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.responsibleUserId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  {props.editing === true &&
                    <FormGroup controlId="isDone">
                    <Form.Row>
                      <FormLabel column lg={3}>Splněno</FormLabel>
                      <Col lg={1}>
                      <FormControl 
                          type="checkbox"
                          name="isDone"
                          size="sm"
                          className="checkbox-small"
                          onChange={handleChange}
                          checked={values.isDone}
                          //disabled={getUser().id !== item?.responsibleUserId}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}
                          isValid={touched.isDone && !errors.isDone} 
                          isInvalid={!!errors.isDone}

                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isDone}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>}
                  <hr />
                    <FormGroup as={Col}  controlId="text">
                      <FormLabel>Text</FormLabel>
                        {showSolText &&
                        <TextEditor
                          setFieldValue={(val) => setFieldValue("text", val)}
                          value={values.text!}
                        />}
                        <div>
                          <LoadFreeText 
                            setFieldValue={setFieldValue} 
                            showFormField={setShowSolText} 
                            pole="text" 
                            originalText={values.text!} 
                            handleKeyDown={handleKeyDownCtrlEnter}
                            handleSubmit={handleSubmit}    
                            />
                        </div>
                        <div className="invalid-feedback" style={{display: 'block'}}>{errors.text}</div>
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                    </FormGroup>

                    

                    
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám...' : 'Uložit změny'}</Button>
                  
                </Form>

              )}
            </Formik>  
            
            </Alert>
          </div>
          </>}
          </>



return ( 
  <>
  <>
  {props.editing===true?
  <ButtonGroup style={{}}>
    <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Upravit</>:'+ Přidat bod úkolu'}</Button>
    <TasksSolutionAddUpdate taskId={props.taskId} linkId={props.pointId} pointSolution={true} />
    <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
          <FilesAdd id={props.pointId} type='task' masterId={props.taskId} showAs='drop' />
          {props.masterRights === true && <>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => { handleOnClickDeletePoint(props.pointId, '') } } style={{color: 'red'}} >Smazat bod úkolu</Dropdown.Item>
          </>}
    </DropdownButton>
  </ButtonGroup>
  :
  props.showAs !== 'drop' ?
  <Button variant={props.editing===true?'primary':'success'} size="sm" onClick={() => {startSequence()}} >{props.editing===true?<>Upravit</>:'+ Přidat bod úkolu'}</Button>
  :
  <><Dropdown.Item onClick={() => {startSequence()}}>Přidat bod úkolu</Dropdown.Item></>
  
  }
  </>
  
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editing === true ? 'Úprava' : 'Přidání'}&nbsp;bodu úkolu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(TasksPointAddUpdate))

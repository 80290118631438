import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

//import ErrorAlert from '../../../errorAlert/ErrorAlert.part'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
import { useAppStore } from '../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

//import { confirm } from "../../Utils/Confirmation";

import moment from 'moment'


interface AppProDuelReceiptEditProps {
  receiptId?: string,
  tokenId: string,
  companyId: string,
  mode: string,
  showAs: string
}

const AppProDuelReceiptEdit: React.FC<AppProDuelReceiptEditProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  //const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

 
  // Připojení store
  const rootAppStore = useAppStore()
  
  const editedReceipt = rootAppStore.appProduelReceipts.find(r => r.id === props.receiptId)
  const company = rootAppStore.appProduelCompanies.find(c => c.id === props.companyId)
  const token = company?.tokens?.find(t => t.id === props.tokenId)


  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleCloseE = () => { 
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleShow = async () => {

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }



  // Funkce pro odeslání e-mailu
  const handleCreateReceipt = (values:any, setFieldValue:any) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      

      // Zavolání API
      if (props.mode === "add") {
      await axios.post(getUrl('URL_DOKLADY_API')+ 'receipts', 
            { 
              signature: values.signature,  
              receiptDate: new Date(values.receiptDate).toISOString(),
              amount: values.amount,
              note: values.note,
              currencyCode: values.currencyCode,
      
            }, { headers: { 'X-API-KEY': '' + token?.token} }
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response)

          rootAppStore.fetchAppProduelReceipts(Number(sessionStorage.getItem('appProDuelReceiptsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('appProDuelReceiptsSearchPage')),token?.token!,false)
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API
      
      await axios.post(getUrl('URL_DOKLADY_API')+'receipts/'+props.receiptId, 
            { 
              id: props.receiptId,
              receiptDate: new Date(values.receiptDate).toISOString(),
              amount: values.amount,
              note: values.note,
              currencyCode: values.currencyCode,

                        
            }, { headers: { 'X-API-KEY': '' + token?.token}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)

          rootAppStore.fetchAppProduelReceipts(Number(sessionStorage.getItem('appProDuelReceiptsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('appProDuelReceiptsSearchPage')),token?.token!,false)
          

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    
    amount: Yup.number()
      .required('Částka účtenky musí být vyplněna'),
    currencyCode: Yup.string()
      .required('Měna musí být vyplněna'),
    receiptDate: Yup.string()
      .required('Datum vystavení účtenky musí být vyplněno'),

  })   
  return (
    <>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
            {props.mode === 'add' ? 'Zadat novou účtenku':'Upravit účtenku' }
          </Dropdown.Item>}
          {(props.showAs === 'button') &&<>
          {props.mode === 'add' ?
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant={props.mode === 'add' ? "success" : "outline-primary"} onClick={() => { handleShow() } }>
            {props.mode === 'add' ? <><i className="fas fa-plus"></i> Přidat účtenku</>:<>Upravit účtenku</>}
          </Button>
          :
          <Button size="sm" style={{}} variant={props.mode === 'add' ? "success" : "primary"} onClick={() => { handleShow() } }>
            {props.mode === 'add' ? <><i className="fas fa-plus"></i> Přidat účtenku</>:<>Upravit účtenku</>}
          </Button>}
          </>
          }
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        backdrop="static"
        style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mode === 'add' && <>Přidání nové účtenky</>}
            {props.mode === 'edit' && <>Úprava účtenky</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          
          <Alert variant="success" show={showInfo}>
            <strong>{props.mode === 'add' ? 'Účtenka byla úspěšně přidána!':'Účtenka byla úspěšně upravena!'}​</strong>
            
          </Alert>  
          <div className={showForm} >
            
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                amount: props.mode === 'add' ? 0 : editedReceipt?.amount,
                currencyCode: props.mode === 'add' ? 'Kč' : editedReceipt?.currencyCode,
                note: props.mode === 'add' ? '' : editedReceipt?.note!,
                receiptDate: props.mode === 'add' ? moment(new Date()).format("YYYY-MM-DD") : moment(new Date(editedReceipt?.receiptDate!)).format("YYYY-MM-DD"),
                signature: props.mode === 'add' ? '' : editedReceipt?.signature,
                
              }}
              onSubmit={(values, { setSubmitting, setFieldValue }) => {
                setTimeout(() => {
                  handleCreateReceipt(values,setFieldValue)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <FormGroup controlId="receiptDate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum vystavení</FormLabel>
                      <Col>
                        <FormControl 
                          type="date"
                          name="receiptDate"
                          value={values.receiptDate}
                          onChange={handleChange}
                          isValid={touched.receiptDate && !errors.receiptDate} 
                          isInvalid={!!errors.receiptDate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.receiptDate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  

                  
                  <FormGroup controlId="amount">
                    <Form.Row>
                      <FormLabel column lg={3}>Částka</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="amount"
                          value={values.amount}
                          onChange={handleChange}
                          isValid={touched.amount && !errors.amount} 
                          isInvalid={!!errors.amount}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.amount}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="currencyCode">
                    <Form.Row>
                      <FormLabel column lg={3}>Měna</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="currencyCode"
                          value={values.currencyCode}
                          onChange={handleChange}
                          isValid={touched.currencyCode && !errors.currencyCode} 
                          isInvalid={!!errors.currencyCode}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.currencyCode}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 

                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>   
                  <FormGroup controlId="signature">
                    <Form.Row>
                      <FormLabel column lg={3}>Podpis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="signature"
                          value={values.signature}
                          onChange={handleChange}
                          isValid={touched.signature && !errors.signature} 
                          isInvalid={!!errors.signature}
                          disabled={props.mode === 'add' ? false : true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.signature}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ?  'Ukládám účtenku...' : props.mode === 'add' ? 'Přidat účtenku':'Uložit účtenku'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>

      <Modal 
        show={showE} 
        onHide={handleCloseE} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Změna stavu účtenky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(AppProDuelReceiptEdit))

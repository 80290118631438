import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

import Loader from "react-loader"
import axios from 'axios'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'
import CustomersMassActions from '../components/Overviews.Customers.MassActions.part'
import CustomersSingleActions from '../components/Overviews.Customers.SingleActions.part'
import CustomersSingleOtherActions from '../components/Overviews.Customers.SingleOtherActions.part'
import CustomersSelectedActions from '../components/Overviews.Customers.SelectedActions.part'

import UpgradeScenarioHeader from '../../../parts/upgradeScenarioHeader/UpgradeScenarioHeader.parts'

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import { Container, Button, ButtonGroup,  Card, Badge, Table, Dropdown, DropdownButton, Nav, Spinner} from 'react-bootstrap'

import NumberFormat from 'react-number-format'

//import * as Constants from '../../../Constants/Constants'

//import { confirm } from "../../../Utils/Confirmation"

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore, useUpgradeStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

import { useParams } from 'react-router'
import { OverviewsDetailOfferUrlParams } from '../../../Routes'

import { getToken, getUrl, setUserRoles, handleMenuContent, showColumnDataFiltersNew, getDataFiltersNextList, getDataFiltersPreviousList, getDataFiltersNextListText, getDataFiltersPreviousListText, getOverviewsListNameById, checkVersion } from '../../../Utils/Common'
import { login } from '../../../authProvider'

import Pagination from 'react-js-pagination'

import moment from 'moment'

import UpgradeOfferPreview  from '../../upgrade/components/Upgrade.Offer.Preview.part'
import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'


function OverviewsList(props:any) {
  const { id } = useParams<OverviewsDetailOfferUrlParams>()
  //const order = useStore().getOrderById(String(id))

  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(false);
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  //const [loadedChange, setLoadedChange] = useState(true)
  const [opening, setOpening] = useState(false)
  const [openingId, setOpeningId] = useState('')
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showCancel, setShowCancel] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(false)

  //const [selectedList, setSelectedList] = useState([])

  // Nastavení práv pro velkou tabulku
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const rootUpgradeStore = useUpgradeStore()

  let filterItemsArray = ['CustomerFulltext','AccountNumber','CompanyRegistrationNumber']
  
  if (Number(id) < 1000 ) filterItemsArray.push('DistributionMethod')
  if (Number(id) < 1000 ) filterItemsArray.push('ServiceId')
  if (Number(id) < 1000 ) filterItemsArray.push('UpgradeScenarioId')

  if (Number(id) > 1000 ) filterItemsArray.push('Email')
  if (showColumnDataFiltersNew(id,'isDiscount')) filterItemsArray.push('IsDiscount')
  if (showColumnDataFiltersNew(id,'isLicenseChanged')) filterItemsArray.push('IsLicenseChanged')
  if (showColumnDataFiltersNew(id,'isLicenseChangedInProtectedPeriod')) filterItemsArray.push('IsLicenseChangedInProtectedPeriod')
  if (showColumnDataFiltersNew(id,'isNewNetworkLicense')) filterItemsArray.push('IsNewNetworkLicense')
  if ((showColumnDataFiltersNew(id,'invoiceNumber') || showColumnDataFiltersNew(id,'invoiceNumber2') || showColumnDataFiltersNew(id,'proformaNumber'))) filterItemsArray.push('InvoiceNumber')
  

  /*
  -- DOČASNĚ VYPNUTO, NEBOŤ SE NEPOUŽÍVÁ
  const handleReloadPageWithNewTimeStamp = async () => {
    
      if (await confirm("Přejete si aktualizovat seznam?",'Ano', 'Ne, pokračovat v předešlé práci', {title: 'Aktualizace seznamu '})) {

        localStorage.removeItem('lastDatafilterTimeStamp')
        localStorage.removeItem('lastDatafilterTime')
        localStorage.removeItem('lastDatafilter')
        localStorage.removeItem('lastDatafilterType') 


      } else {
               
      }  
    }
    */
  
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
      //let overviewsOfferSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
      //localStorage.setItem('overviewsOfferSearchArray',JSON.stringify(overviewsOfferSearchDataArray))
    
      //console.log(Number(id))
      //console.log(+localStorage.getItem('lastDatafilter')!)

      await setLoaded(false) // Nastavení pro Loader, že není strana načtena

      await rootStore.deleteDatafiltersOffers()

      if (Number(id) !== +localStorage.getItem('lastDatafilter')!) {
        
        if (+localStorage.getItem('lastDatafilter')! !== 9999) {
        
          let overviewsOfferSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
          localStorage.setItem('overviewsOfferSearchArray',JSON.stringify(overviewsOfferSearchDataArray))
        
          sessionStorage.removeItem('selectionDetailOfferSearchTextValue')
          sessionStorage.removeItem('selectionDetailOfferSearchTypeValue')
          sessionStorage.removeItem('selectionDetailOfferSearchScenarioValue')
          sessionStorage.setItem('selectionDetailOfferPage', '1')
        }
    }
    sessionStorage.setItem('selectionDetailOfferPage', '1')

    await rootUpgradeStore.fetchUpgradeOffersScenariosAll()
    
    if (localStorage.getItem('upgradeScenarioDuelSelected') === undefined || localStorage.getItem('upgradeScenarioDuelSelected') === null) {
      localStorage.setItem('upgradeScenarioDuelSelected', rootUpgradeStore.upgradeOffersScenarios.find(us => us.program.code === 'D' && us.isActive === true)!.id)
      localStorage.setItem('upgradeScenarioStereoSelected', rootUpgradeStore.upgradeOffersScenarios.find(us => us.program.code === 'S' && us.isActive === true)!.id)

    }

    await checkVersion()
    setStateNewFilter(true)

    if (Number(id) > 1000 && Number(id) === +localStorage.getItem('lastDatafilter')!) {  
      //await handleReloadPageWithNewTimeStamp()
      localStorage.removeItem('lastDatafilterTimeStamp')
      localStorage.removeItem('lastDatafilterTime')
    }
    //await setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)

    if (!(sessionStorage.getItem('selectionDetailOfferSearchTextValue') === '' || sessionStorage.getItem('selectionDetailOfferSearchTextValue') === undefined || sessionStorage.getItem('selectionDetailOfferSearchTextValue') === null) || !(sessionStorage.getItem('selectionDetailOfferSearchScenarioValue') === '' || sessionStorage.getItem('selectionDetailOfferSearchScenarioValue') === undefined || sessionStorage.getItem('selectionDetailOfferSearchScenarioValue') === null)) {
      setShowCancel(true);
    }

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {  
      if (Number(id) < 1000) {
        await rootStore.fetchDatafiltersOffers(Number(id), Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))  
      }
      else {
        await rootStore.fetchDatafiltersOffersNew(Number(id), Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))  
      }
      // Testovaní
      //await rootStore.fetchDatafiltersOffersNew(Number(id),'58d3ca24-b1e0-469e-b752-a602054d8be5',sessionStorage.getItem('selectionDetailOfferSearchTextValue')!, sessionStorage.getItem('selectionDetailOfferSearchTypeValue')!, sessionStorage.getItem('selectionDetailOfferSearchScenarioValue')!, Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))  
      
      
      //rootStore.fetchTemplates()
      rootDialsStore.fetchDialsServices()
      rootUpgradeStore.fetchUpgradeOffersScenariosAll()
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
            await rootStore.fetchCustomerOfferCount()
          }
          if (Number(id) < 1000) {
            //await rootStore.fetchDatafiltersOffers(Number(id),sessionStorage.getItem('selectionDetailOfferSearchTextValue')!, sessionStorage.getItem('selectionDetailOfferSearchTypeValue')!, sessionStorage.getItem('selectionDetailOfferSearchScenarioValue')!, Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))
          }
          else {
            //await rootStore.fetchDatafiltersOffersNew(Number(id),sessionStorage.getItem('selectionDetailOfferSearchTextValue')!, sessionStorage.getItem('selectionDetailOfferSearchTypeValue')!, sessionStorage.getItem('selectionDetailOfferSearchScenarioValue')!, Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))
          }
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      } 
    }   
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    await setLoaded(true) // Nastavení pro Loader, že je strana načtena
    
    })() 
  })


  const handleChangeList = async (id:number) => {
    (async () => {  
    if (Number(id) !== +localStorage.getItem('lastDatafilter')!) {
      if (+localStorage.getItem('lastDatafilter')! !== 9999) {

        let overviewsOfferSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
        localStorage.setItem('overviewsOfferSearchArray',JSON.stringify(overviewsOfferSearchDataArray))

      sessionStorage.removeItem('selectionDetailOfferSearchTextValue')
      sessionStorage.removeItem('selectionDetailOfferSearchTypeValue')
      sessionStorage.removeItem('selectionDetailOfferSearchScenarioValue')
      sessionStorage.setItem('selectionDetailOfferPage', '1')
      }
    }

    if (Number(id) > 1000 && Number(id) === +localStorage.getItem('lastDatafilter')!) {  
      //await handleReloadPageWithNewTimeStamp()
      localStorage.removeItem('lastDatafilterTimeStamp')
      localStorage.removeItem('lastDatafilterTime')
    }
    await setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)

    if (!(sessionStorage.getItem('selectionDetailOfferSearchTextValue') === '' || sessionStorage.getItem('selectionDetailOfferSearchTextValue') === undefined || sessionStorage.getItem('selectionDetailOfferSearchTextValue') === null) || !(sessionStorage.getItem('selectionDetailOfferSearchScenarioValue') === '' || sessionStorage.getItem('selectionDetailOfferSearchScenarioValue') === undefined || sessionStorage.getItem('selectionDetailOfferSearchScenarioValue') === null)) {
      setShowCancel(true);
    }
    else { setShowCancel(false) }

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {  
      if (Number(id) < 1000) {
        await rootStore.fetchDatafiltersOffers(Number(id), Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))  
      }
      else {
        await rootStore.fetchDatafiltersOffersNew(Number(id), Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))  
      }
      // Testovaní
      //await rootStore.fetchDatafiltersOffersNew(Number(id),'58d3ca24-b1e0-469e-b752-a602054d8be5',sessionStorage.getItem('selectionDetailOfferSearchTextValue')!, sessionStorage.getItem('selectionDetailOfferSearchTypeValue')!, sessionStorage.getItem('selectionDetailOfferSearchScenarioValue')!, Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))  
      
      
      //rootStore.fetchTemplates()
      rootDialsStore.fetchDialsServices()
      rootUpgradeStore.fetchUpgradeOffersScenariosAll()
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
            await rootStore.fetchCustomerOfferCount()
          }
          if (Number(id) < 1000) {
            //await rootStore.fetchDatafiltersOffers(Number(id),sessionStorage.getItem('selectionDetailOfferSearchTextValue')!, sessionStorage.getItem('selectionDetailOfferSearchTypeValue')!, sessionStorage.getItem('selectionDetailOfferSearchScenarioValue')!, Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))
          }
          else {
            //await rootStore.fetchDatafiltersOffersNew(Number(id),sessionStorage.getItem('selectionDetailOfferSearchTextValue')!, sessionStorage.getItem('selectionDetailOfferSearchTypeValue')!, sessionStorage.getItem('selectionDetailOfferSearchScenarioValue')!, Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')))
          }
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      } 
    }   
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    await setLoaded(true) // Nastavení pro Loader, že je strana načtena
    
    })() 
  }
    
  //Počet záznamů
  const countData:number = Number(id) < 1000 ? Object.keys(rootStore.datafiltersOffers).length : Object.keys(rootStore.datafiltersOffersNew).length

  const countDataChecked:number = Number(id) < 1000 ? Object.keys(rootStore.datafiltersOffers.filter(dfo => dfo.checked === true  )).length : Object.keys(rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true  )).length

  // Seznam výběrů do karet
  const selectionsListDataCard = rootStore.datafiltersOffers.map(offerList => (
    <div key={offerList.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{offerList.customer.businessName}</strong><br />
          <Badge variant="info">Číslo zákazníka: {offerList.customer.accountNumber}</Badge>
          <br />
          <strong>{offerList.upgradeScenario.program.name}&nbsp;{offerList.upgradeScenario.programVersion.version}</strong>
          <br />(<i>
          IČO: {offerList.customer.companyRegistrationNumber}
          </i>)
        </div>
        <div className="float-right" style={{textAlign: 'right'}}>
            
            <CustomersSingleActions id={id} recordId={offerList.id} recordId2='' recordId3='' infoText={offerList.infoText!}  />
            
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                <Dropdown.Item onClick={() => { handleOpen(offerList.customer.id!,offerList.customer.isActive!) } } >{opening && openingId === offerList.customer.id ? 'Otevírám zákazníka...' : 'Otevřít zákazníka'}</Dropdown.Item>
                {offerList.id !== '' &&<UpgradeOfferPreview id={offerList.id} showAs='drop' block={true} /> }
                <CustomersSingleOtherActions id={id} offerId={offerList.id} recordId='' recordId2='' recordId3='' infoText={offerList.infoText!} customerId={offerList.customer.id!} />
            </DropdownButton>
          </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))

    // Seznam výběrů do karet
    const selectionsListDataCardNew = rootStore.datafiltersOffersNew.map(offerList => (
      <div key={offerList.offerId}>
      <Card>
        <Card.Body>
          
          <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{offerList.businessName}</strong><br />
          <Badge variant="info">Číslo zákazníka: {offerList.accountNumber}</Badge>
          <br />
          <strong>{offerList.programName}&nbsp;{offerList.actualVersion}</strong><br />

          {showColumnDataFiltersNew(id,'isDiscount')&&<>Poskytnuta sleva: {offerList.isDiscount ? 'A':'N'}<br /></>}
          {showColumnDataFiltersNew(id,'isLicenseChanged')&&<>Změna licence v rámci upgrade: {offerList.isLicenseChanged ? 'A':'N'}<br /></>}
          {showColumnDataFiltersNew(id,'isLicenseCahngedInProtectedPeriod')&&<>Změna v OO: {offerList.isLicenseChangedInProtectedPeriod ? 'A':'N'}<br /></>}

          {showColumnDataFiltersNew(id,'invoiceNumber')&&<>Číslo zál. faktury: {offerList.invoiceNumber}<br /></>}
          {showColumnDataFiltersNew(id,'dateOfIssue')&&<>Datum vystavení: {moment(offerList.dateOfIssue).format("DD.MM.YYYY")}<br /></>}

          {showColumnDataFiltersNew(id,'proformaNumber')&&<>Číslo zál. faktury: {offerList.proformaNumber}<br /></>}
          {showColumnDataFiltersNew(id,'dateOfPayment')&&<>Datum úhrady: {moment(offerList.dateOfPayment).format("DD.MM.YYYY")}<br /></>}

          {showColumnDataFiltersNew(id,'invoiceNumber2')&&<>Číslo faktury: {offerList.invoiceNumber}<br /></>}
          {showColumnDataFiltersNew(id,'dateOfIssue2')&&<>Datum vystavení: {moment(offerList.dateOfIssue).format("DD.MM.YYYY")}<br /></>}

          {showColumnDataFiltersNew(id,'distributionDate')&&<>Datum distribuce: {moment(offerList.distributionDate).format("DD.MM.YYYY HH:mm")}</>}
          {showColumnDataFiltersNew(id,'returnDate')&&<>Datum vrácení: {moment(offerList.returnDate).format("DD.MM.YYYY HH:mm")}</>}

          {showColumnDataFiltersNew(id,'vatBase')&&<>Celkem bez DPH: <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={offerList.vatBase} /><br /></>}

          {showColumnDataFiltersNew(id,'dateOfPublishing')&&<>Datum zveřejnění: {moment(offerList.dateOfPublishing).format("DD.MM.YYYY")}<br /></>}
          </div>
          <div className="float-right" style={{textAlign: 'right'}}>
            
            <ButtonGroup>
              <CustomersSingleActions id={id} recordId={offerList.offerId !== '' ? offerList.offerId : offerList.documentId !== '' ? offerList.documentId : offerList.taxDocumentId} recordId2={offerList.licenseDocumentId} recordId3={offerList.paymentDocumentId} infoText={offerList.infoText!} />
            
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                <Dropdown.Item onClick={() => { handleOpen(offerList.customerId!,true) } } >{opening && openingId === offerList.customerId ? 'Otevírám zákazníka...' : 'Otevřít zákazníka'}</Dropdown.Item>
                {offerList.offerId !== '' &&<UpgradeOfferPreview id={offerList.offerId} showAs='drop' block={true} /> }
                <CustomersSingleOtherActions id={id} offerId={offerList.offerId} recordId={offerList.documentId !== '' ? offerList.documentId : offerList.taxDocumentId} recordId2={offerList.licenseDocumentId} recordId3={offerList.paymentDocumentId} infoText={offerList.infoText!} customerId={offerList.customerId!} packetId={offerList.packetId} />
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Card.Body>
      </Card>
      <br />
      </div>
    ))

  // Seznam zákazníků do řádků tabulky
  const selectionsListDataTable = rootStore.datafiltersOffers.map(offerList => (
    <tr key={offerList.id}>
      <td>
          <input
            type="checkbox"
            checked={offerList.checked}
            className=""
            id={'rowcheck'+offerList.id}
            onChange={() => offerList.selectItem(!offerList.checked)}
            style={{height: '18px', width: '18px'}}
          />
        </td>
      
      <td><strong>{offerList.customer.accountNumber}</strong></td>
      <td>
        {!opening &&<span className="as-link-no-color" onClick={() => { handleOpen(offerList.customer.id!,offerList.customer.isActive!) } }>{offerList.customer.businessName}</span>}
        {opening &&<span className="as-link-no-color">{offerList.customer.businessName}</span>}
      </td>
      <td>{offerList.customer.companyRegistrationNumber}</td>
      <td>{offerList.upgradeScenario.program.name}&nbsp;{offerList.upgradeScenario.programVersion.version}</td>
      <td>
        <div className="float-left">
            <ButtonGroup>
              <CustomersSingleActions id={id} recordId={offerList.id} recordId2='' recordId3='' infoText={offerList.infoText!} />
              
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                <Dropdown.Item onClick={() => { handleOpen(offerList.customer.id!,offerList.customer.isActive!) } } >{opening && openingId === offerList.customer.id ? 'Otevírám zákazníka...' : 'Otevřít zákazníka'}</Dropdown.Item>
                {offerList.id !== '' &&<UpgradeOfferPreview id={offerList.id} showAs='drop' block={true} /> }
                <CustomersSingleOtherActions id={id} offerId={offerList.id} recordId='' recordId2='' recordId3='' infoText={offerList.infoText!} customerId={offerList.customer.id!}  />
              </DropdownButton>
            
            
            
            
            </ButtonGroup>
        </div>  
        
        
      </td>
    </tr>
  ))

    // Seznam zákazníků do řádků tabulky
    const selectionsListDataTableNew = rootStore.datafiltersOffersNew.map(offerList => (
      <tr key={offerList.offerId}>
        <td>
          <input
            type="checkbox"
            checked={offerList.checked}
            className=""
            id={'rowcheck'+offerList.offerId}
            onChange={() => offerList.selectItem(!offerList.checked)}
            style={{height: '18px', width: '18px'}}
          />
        </td>
        {showColumnDataFiltersNew(id,'accountNumber')&&<td><strong>{offerList.accountNumber}</strong></td>}
        {showColumnDataFiltersNew(id,'businessName')&&<td>
          {!opening &&<span className="as-link-no-color" onClick={() => { handleOpen(offerList.customerId!,true) } }>{offerList.businessName}</span>}
          {opening &&<span className="as-link-no-color">{offerList.businessName}</span>}
          </td>}
        
        {showColumnDataFiltersNew(id,'programName')&&<td>{offerList.programName}&nbsp;{offerList.actualVersion}</td>}
        {showColumnDataFiltersNew(id,'isDiscount')&&<td title='Příznak zda byla poskytnuta sleva v rámci upgrade'>{offerList.isDiscount ? 'A':'N'}</td>}
        {showColumnDataFiltersNew(id,'isLicenseChanged')&&<td title='Příznak zda byla licence změněna v rámci upgrade'>{offerList.isLicenseChanged ? 'A':'N'}</td>}
        {showColumnDataFiltersNew(id,'isLicenseChangedInProtectedPeriod')&&<td title='Příznak zda byla licence změněna v ochraném období'>{offerList.isLicenseChangedInProtectedPeriod ? 'A':'N'}</td>}

        {showColumnDataFiltersNew(id,'invoiceNumber')&&<td style={{textAlign: 'right'}}>{offerList.invoiceNumber}</td>}
        {showColumnDataFiltersNew(id,'dateOfIssue')&&<td>{moment(offerList.dateOfIssue).format("DD.MM.YYYY")}</td>}

        {showColumnDataFiltersNew(id,'proformaNumber')&&<td style={{textAlign: 'right'}}>{offerList.proformaNumber}</td>}
        {showColumnDataFiltersNew(id,'dateOfPayment')&&<td>{moment(offerList.dateOfPayment).format("DD.MM.YYYY")}</td>}

        {showColumnDataFiltersNew(id,'invoiceNumber2')&&<td style={{textAlign: 'right'}}>{offerList.invoiceNumber}</td>}
        {showColumnDataFiltersNew(id,'dateOfIssue2')&&<td>{moment(offerList.dateOfIssue).format("DD.MM.YYYY")}</td>}
        {showColumnDataFiltersNew(id,'distributionDate')&&<td>{moment(offerList.distributionDate).format("DD.MM.YYYY HH:mm")}</td>}
        {showColumnDataFiltersNew(id,'returnDate')&&<td>{moment(offerList.returnDate).format("DD.MM.YYYY HH:mm")}</td>}

        {showColumnDataFiltersNew(id,'vatBase')&&<td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={offerList.vatBase} /></td>}

        {showColumnDataFiltersNew(id,'dateOfPublishing')&&<td>{moment(offerList.dateOfPublishing).format("DD.MM.YYYY")}</td>}
        <td>
          
          <div className="float-left">
            <ButtonGroup>
              <CustomersSingleActions id={id} recordId={offerList.offerId !== '' ? offerList.offerId : offerList.documentId !== '' ? offerList.documentId : offerList.taxDocumentId} recordId2={offerList.licenseDocumentId} recordId3={offerList.paymentDocumentId} infoText={offerList.infoText!} packetId={offerList.packetId} />
              
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                <Dropdown.Item onClick={() => { handleOpen(offerList.customerId!,true) } } >{opening && openingId === offerList.customerId ? 'Otevírám zákazníka...' : 'Otevřít zákazníka'}</Dropdown.Item>
                {offerList.offerId !== '' &&<UpgradeOfferPreview id={offerList.offerId} showAs='drop' block={true} /> }
                <CustomersSingleOtherActions id={id} offerId={offerList.offerId} recordId={offerList.documentId !== '' ? offerList.documentId : offerList.taxDocumentId} recordId2={offerList.licenseDocumentId} recordId3={offerList.paymentDocumentId} infoText={offerList.infoText!} customerId={offerList.customerId!} packetId={offerList.packetId!} />
              </DropdownButton>
            
            
            
            
            </ButtonGroup>
          </div>  
          
        </td>
      </tr>
    ))

  // Funkce po otevření firmy
  const handleOpen = async (id:string,isActive:boolean) => {
    setError(null)
    setOpening(true)
    setOpeningId(id)
    
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    
    if (isActive === true) {
      // Zavolání API
      await axios.get(getUrl('URL_CUSTOMER_OPEN')+id,{
        headers: {
          'Authorization': 'Bearer ' + aToken
        }}
      ).then(response => {
        (async () => {
        
        // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
        setUserRoles(response.data.roles)
        login(response.data.token)

        // Nastavení id otevřené firmy do proměnné v localStore
        localStorage.setItem('openedCustomerId', id)

        // Nastavení informace, že firma byla otevřená z přehledu
        localStorage.setItem('openedFromOverviews', '1')

        // Načtení otevřené firmy do store
        await rootStore.fetchCustomerOpened()
        await rootStore.fetchCustomerOfferCount()

        setOpening(false)

        // Přesměrování na stránku s přehledem firmy
        await props.history.push('/customers-dashboard')
        })();
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setOpening(false)
      });
    }
    else {
      // Nastavení id otevřené firmy do proměnné v localStore
      localStorage.setItem('openedCustomerId', id)

      // Nastavení informace, že firma byla otevřená z přehledu
      localStorage.setItem('openedFromOverviews', '1')

      // Načtení otevřené firmy do store
      await rootStore.fetchCustomerOpened()
      //await rootStore.fetchCustomerOfferCount()

      setOpening(false)

      // Přesměrování na stránku s přehledem firmy
      await props.history.push('/customers-dashboard')
    }
  }

 
  const handlePageChange = (pageNumber:number) => {
    (async () => { 
      setLoaded(false);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      sessionStorage.setItem('selectionDetailOfferPage', String(pageNumber))
      // Předat hledanou hodnotu action pro načtení zákazníků
      if (Number(id) < 1000) {
        await rootStore.fetchDatafiltersOffers(Number(id), pageNumber)
      }
      else {
        await rootStore.fetchDatafiltersOffersNew(Number(id), pageNumber)
      } 
      setLoaded(true);  
    })()
  }

  const unselectAll = () => {
    
    if (Number(id) > 999) {
    rootStore.datafiltersOffersNew.map(
      dfon => (
        dfon.selectItem(false)
      )
    )
      }
    else {
      rootStore.datafiltersOffers.map(
        dfo => (
          dfo.selectItem(false)
        )
      )
    }  
  }


  const refreshPage = () => {
    window.location.reload();
  }


  return (
    <div className="App">

        <TopMenu />
        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <UpgradeScenarioHeader refreshPage={refreshPage} />
              <div className="showWeb">
              <Nav>
              <ButtonGroup className="btn-block">
                {getDataFiltersPreviousList(Number(id)) !== '' &&<NavLink className="dropdown-item" to={ROUTES.overviewsDetailOffer.getLink(Number(getDataFiltersPreviousList(Number(id))))} onClick={() => {handleChangeList(Number(getDataFiltersPreviousList(Number(id))))}}><div style={{textAlign: 'center', color: '#858796'}}>&lt;&lt;&lt; {getDataFiltersPreviousListText(Number(id))}</div></NavLink>}
                {getDataFiltersNextList(Number(id)) !== '' &&<NavLink className="dropdown-item" to={ROUTES.overviewsDetailOffer.getLink(Number(getDataFiltersNextList(Number(id))))} onClick={() => {handleChangeList(Number(getDataFiltersNextList(Number(id))))}}><div style={{textAlign: 'center', color: '#858796'}}>{getDataFiltersNextListText(Number(id))} &gt;&gt;&gt;</div></NavLink>}
                
              </ButtonGroup>
              </Nav> 
              </div>     

              <div className="showMobile">
              <Nav>
              <ButtonGroup className="btn-block">
                {getDataFiltersPreviousList(Number(id)) !== '' &&<NavLink className="dropdown-item" to={ROUTES.overviewsDetailOffer.getLink(Number(getDataFiltersPreviousList(Number(id))))} onClick={() => {handleChangeList(Number(getDataFiltersPreviousList(Number(id))))}}><div style={{textAlign: 'center', color: '#858796'}}>&lt;&lt;&lt; {getDataFiltersPreviousListText(Number(id))}</div></NavLink>}
                
                
              </ButtonGroup>
              <ButtonGroup className="btn-block">
                {getDataFiltersNextList(Number(id)) !== '' &&<NavLink className="dropdown-item" to={ROUTES.overviewsDetailOffer.getLink(Number(getDataFiltersNextList(Number(id))))} onClick={() => {handleChangeList(Number(getDataFiltersNextList(Number(id))))}}><div style={{textAlign: 'center', color: '#858796'}}>{getDataFiltersNextListText(Number(id))} &gt;&gt;&gt;</div></NavLink>}
              </ButtonGroup>
              </Nav>

              </div>



              <Container fluid>
              <br />
              <FilterPanel 
                //isCustomer={props.isCustomer} 
                filterPage={'overviewsOffer'} 
                filterSearchArray='overviewsOfferSearchArray' 
                pageVariable='selectionDetailOfferPage' 
                setPageLoading={setLoading} 
                filterItems={filterItemsArray}  
                title={getOverviewsListNameById(id)}
                titleCount = {Number(localStorage.getItem('lastDatafilterOfferTotalCount')!)}
                stateNewFilter={stateNewFilter}
                setStateNewFilter={setStateNewFilter}
                overviewsNumber={Number(id)}
                setIsFiltered={setIsFiltered}
                secondaryRightButtons={<CustomersMassActions id={id} style={{marginBottom: '15px', marginLeft: '5px'}} multiselect={countDataChecked > 0 ? true:false} multiselectCount={countDataChecked} filtered={isFiltered} searchValue={sessionStorage.getItem('selectionDetailOfferSearchTextValue')!} searchType={sessionStorage.getItem('selectionDetailOfferSearchTypeValue')!} searchScenario={sessionStorage.getItem('selectionDetailOfferSearchScenarioValue')!} />}
                titleDescription={Number(id) > 999 && <> 
                  {
                    localStorage.getItem('lastDatafilterTime')! !== undefined && <i>Stav k  {moment(localStorage.getItem('lastDatafilterTime')!).format("DD.MM.YYYY H:mm:ss")}<br /></i>
                  }
                  </>}
                //secondaryLeftButtons={<NewLicensesTransfer />}
                />


                <br />
                
                
                {(loading === true) 
                ? 
                <><Spinner animation='border'  />&nbsp;<br /></>
                :
                <>
                  <div className="showMobile">
                    {countData === 0 ? <div>Seznam objednávek neobsahuje žádné záznamy.<br /><br /></div> : ''}
                    {Number(id) < 1000 ? selectionsListDataCard:selectionsListDataCardNew}
                  </div>
                  <div className="showWeb">
                    <Table hover>
                      {Number(id) < 1000 ?
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head" style={{width: '30px'}}></th>
                          <th className="jsw-table-head row-slim">Zákazník</th>
                          <th className="jsw-table-head">Název</th>
                          <th className="jsw-table-head">IČO</th>
                          <th className="jsw-table-head">Program</th>
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead> :
                      <thead>
                        <tr className="jsw-table-head">
                          {Number(id) > 0 && <th className="jsw-table-head" style={{width: '30px'}}></th>}
                          {showColumnDataFiltersNew(id,'accountNumber')&&<th className="jsw-table-head row-slim">Zákazník</th>}
                          {showColumnDataFiltersNew(id,'businessName')&&<th className="jsw-table-head">Název</th>}
                          
                          {showColumnDataFiltersNew(id,'programName')&&<th className="jsw-table-head">Program</th>}
                          {showColumnDataFiltersNew(id,'isDiscount')&&<th className="jsw-table-head" title='Příznak zda byla poskytnuta sleva v rámci upgrade'>Sleva</th>}
                          {showColumnDataFiltersNew(id,'isLicenseChanged')&&<th className="jsw-table-head" title='Příznak zda byla licence změněna v rámci upgrade'>ZLUPG</th>}
                          {showColumnDataFiltersNew(id,'isLicenseChangedInProtectedPeriod')&&<th className="jsw-table-head" title='Příznak zda byla licence změněna v ochraném období'>ZLOO</th>}

                          {showColumnDataFiltersNew(id,'invoiceNumber')&&<th className="jsw-table-head" style={{textAlign: 'right'}}>Číslo faktury</th>}
                          {showColumnDataFiltersNew(id,'dateOfIssue')&&<th className="jsw-table-head">Datum vystavení</th>}

                          {showColumnDataFiltersNew(id,'proformaNumber')&&<th className="jsw-table-head" style={{textAlign: 'right'}}>Číslo zálohové faktury</th>}
                          {showColumnDataFiltersNew(id,'dateOfPayment')&&<th className="jsw-table-head">Datum úhrady</th>}

                          {showColumnDataFiltersNew(id,'invoiceNumber2')&&<th className="jsw-table-head" style={{textAlign: 'right'}}>Číslo faktury</th>}
                          {showColumnDataFiltersNew(id,'dateOfIssue2')&&<th className="jsw-table-head">Datum vystavení</th>}

                          {showColumnDataFiltersNew(id,'distributionDate')&&<th className="jsw-table-head">Datum distribuce</th>}
                          {showColumnDataFiltersNew(id,'returnDate')&&<th className="jsw-table-head">Datum vrácení</th>}

                          {showColumnDataFiltersNew(id,'vatBase')&&<th className="jsw-table-head"  style={{textAlign: 'right'}}>Bez DPH</th>}
                          {showColumnDataFiltersNew(id,'dateOfPublishing')&&<th className="jsw-table-head">Publikováno</th>}
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead>}
                      <tbody>
                        {Number(id) < 1000 ? selectionsListDataTable : selectionsListDataTableNew}
                      </tbody>
                    </Table>
                    
                    {countDataChecked > 999 && <CustomersSelectedActions id={id} count={countDataChecked}  />}
                    {countDataChecked > 0 && <Button variant="primary" size="sm" onClick={() => {unselectAll()}} >Zrušit výběr</Button>}
                    {countData === 0 ? <div>Seznam objednávek neobsahuje žádné záznamy.<br /><br /></div> : ''}
                  </div>  
                  <br />  
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={Number(sessionStorage.getItem('selectionDetailOfferPage')) === 0 ? 1:Number(sessionStorage.getItem('selectionDetailOfferPage')) }
                    itemsCountPerPage={10}
                    totalItemsCount={Number(localStorage.getItem('lastDatafilterOfferTotalCount'))}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(1)}
                    />
                </>}
                {error}
              </Container>
              
              </Loader>
            </main>
          </div>
        </div>

    </div>
  );
}

export default observer(OverviewsList)
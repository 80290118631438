import { types } from 'mobx-state-tree'

export const HedgehogUsers = types
  .model({
    id: types.optional(types.string, ''),
    email: types.optional(types.string,''),
    surName: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    phone: types.maybeNull(types.string),
    isEmailConfirmed: types.optional(types.boolean, false),
    isAdmin: types.optional(types.boolean, false),
    isEmployee: types.optional(types.boolean, false),
    isDisabled: types.optional(types.boolean, false),
    roles: types.optional(types.array(types.string), []),
    userGroups: types.optional(types.array(types.string), []),
    createDate: types.maybeNull(types.string),
    checked: types.optional(types.boolean, false),
    checked2: types.optional(types.boolean, false)  
  })
  .views(self => ({
    get fullName() {
      return `${self.firstName} ${self.surName}`
    },
    get fullNameBySurName() {
      return `${self.surName} ${self.firstName}`
    }
  }))
  .actions(self => ({
    setFirstName(newName: string | undefined) {
      if (newName) {
        self.firstName = newName
      }
    },
    setSurName(newName: string | undefined) {
      if (newName) {
        self.surName = newName
      }
    },
    setEmail(newEmail: string | undefined) {
      if (newEmail) {
        //self.email = email
      }
    },
    setPhone(newPhone: string | undefined) {
      if (newPhone) {
        self.phone = newPhone
      }
    },
    selectItem(newState: boolean) {
      self.checked = newState
    },
    selectItem2(newState: boolean) {
      self.checked2 = newState
    },
    setUserGroups(groups:any) {
      self.userGroups = groups
    },
  }))

import { types } from 'mobx-state-tree'

export const DialsSupportTypes = types
  .model({
    id: types.optional(types.string, ''),
    name: types.maybeNull(types.string),
    solutionDefaultTypeId: types.maybeNull(types.string),
    isActive: types.optional(types.boolean, false),
    data: types.maybeNull(types.string),

  })
  .views(self => ({
    get order() {
      if (!(self.data === null || self.data === '')) 
      {
      const jsondata = JSON.parse(self.data)
      return jsondata.order
      }
      else return 999 
    }
  }))
  .actions(self => ({
    

  }))

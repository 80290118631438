import React, { useState } from 'react'
import Loader from "react-loader"

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import { Container, Card, CardDeck, Button } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore, useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'

import OverviewsCustomers from '../components/Overviews.Customers.part'
import OverviewsOffers from '../components/Overviews.Offers.part'

import { Link } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

import NumberFormat from 'react-number-format'

import UpgradeScenarioHeader from '../../../parts/upgradeScenarioHeader/UpgradeScenarioHeader.parts'

function OverviewsList(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);


  // Nastavení práv pro velkou tabulku
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()
  
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'overviews')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {  
      
      //if (Object.keys(rootStore.datafiltersOffersCounts).length === 0) await rootStore.fetchDatafiltersOffersNewEmptyCount()
      await rootStore.fetchDatafiltersOffersNewEmptyCount();

      await rootUpgradeStore.fetchUpgradeOffersScenariosAll()

      if (localStorage.getItem('upgradeScenarioDuelSelected') === undefined || localStorage.getItem('upgradeScenarioDuelSelected') === null) {
        localStorage.setItem('upgradeScenarioDuelSelected', rootUpgradeStore.upgradeOffersScenarios.find(us => us.program.code === 'D' && us.isActive === true)!.id)
        localStorage.setItem('upgradeScenarioStereoSelected', rootUpgradeStore.upgradeOffersScenarios.find(us => us.program.code === 'S' && us.isActive === true)!.id)

      }
      
      await (async () => { 
        const promise1 = rootStore.fetchOverviewsCustomers()
        const promise2 = rootStore.fetchOverviewsOffers() 
        const promise3 = rootUpgradeStore.fetchUpgradeOffersScenariosAll()
        return Promise.all([promise1,promise2,promise3])
      })()  
      
      //await   
      //await  



      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
          }
          //await rootStore.fetchCustomerList(sessionStorage.getItem('customersSearchValue')!, sessionStorage.getItem('customersSearchTypeValue')!,Number(sessionStorage.getItem('customersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('customersSearchPage')))
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      } 
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <div className="App">

        <TopMenu />
        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <UpgradeScenarioHeader refreshPage={refreshPage} />
              <Container fluid>
                <br />
                <div>
                  <h4>Seznamy</h4> 
                  <br />
                  <OverviewsCustomers mode='onlyOffers' />
                  <br />
                  <OverviewsOffers />
                  <br />

                  <Card
                    bg='light'
                    text='dark'
                    
                  >
                    <Card.Header  as="h4">
                      Elektronická distribuce
                      <Button 
                        style={{float: 'right'}} 
                        size="sm" 
                        variant="secondary" 
                        onClick={() => { 
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1019','1020','1021','1022','1023','1024') 
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1025','1026','1027','1028','1029','1030')
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1031','1032','1033','1034','1035','1036')
                        }}
                        >
                          Zjistit počty
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      <CardDeck>
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-danger'
                        >
                          <Card.Header  as="h5">DUEL</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1019)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1019')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1019')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1020)}>Vytvořené zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1020')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1020')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1021)}>Zveřejněné zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1021')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1021')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1022)}>Zaplacené zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1022')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1022')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1023)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1023')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1023')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1024)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1024')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1024')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-info'
                        >
                          <Card.Header  as="h5">STEREO</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1025)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1025')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1025')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1026)}>Vytvořené zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1026')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1026')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1027)}>Zveřejněné zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1027')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1027')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1028)}>Zaplacené zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1028')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1028')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1029)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1029')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1029')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1030)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1030')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1030')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-info'
                        >
                          <Card.Header  as="h5">STEREO LAN</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1031)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1031')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1031')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1032)}>Vytvořené zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1032')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1032')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1033)}>Zveřejněné zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1033')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1033')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1034)}>Zaplacené zálohové faktury</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1034')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1034')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1035)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1035')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1035')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1036)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1036')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1036')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                      </CardDeck>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card
                    bg='light'
                    text='dark'
                    
                  >
                    <Card.Header  as="h4">
                      Fyzická distribuce
                      <Button 
                        style={{float: 'right'}} 
                        size="sm" 
                        variant="secondary" 
                        onClick={() => { 
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1001','1002','1003','1004','1005','1006')
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1007','1008','1009','1010','1011','1012')
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1013','1014','1015','1016','1017','1018')
                        }}
                        >
                          Zjistit počty
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      <CardDeck>
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-danger'
                        >
                          <Card.Header  as="h5">DUEL</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1001)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1001')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1001')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1002)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1002')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1002')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1003)}>Zahájená distribuce zásilek</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1003')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1003')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1004)}>Zásilky předané dopravci</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1004')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1004')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1005)}>Vrácené zásilky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1005')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1005')?.count} />: '-'}</div><div className="cleaner" />
                            <div style={{display: 'none'}}>
                            <Link to={ROUTES.overviewsDetailOffer.getLink(1005)}>Zaplaceno</Link><br />
                            </div>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1006)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1006')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1006')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-info'
                        >
                          <Card.Header  as="h5">STEREO</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1007)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1007')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1007')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1008)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1008')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1008')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1009)}>Zahájená distribuce zásilek</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1009')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1009')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1010)}>Zásilky předané dopravci</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1010')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1010')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1011)}>Vrácené zásilky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1011')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1011')?.count} />: '-'}</div><div className="cleaner" />
                            <div style={{display: 'none'}}>
                            <Link to={ROUTES.overviewsDetailOffer.getLink(1009)}>Vytištěné finální dokumenty</Link><br />
                            <Link to={ROUTES.overviewsDetailOffer.getLink(1010)}>Elektronicky podáno</Link><br />
                            <Link to={ROUTES.overviewsDetailOffer.getLink(1011)}>Zaplaceno</Link><br />
                            </div>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1012)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1012')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1012')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-info'
                        >
                          <Card.Header  as="h5">STEREO LAN</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1013)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1013')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1013')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1014)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1014')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1014')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1015)}>Zahájená distribuce zásilek</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1015')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1015')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1016)}>Zásilky předané dopravci</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1016')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1016')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1017)}>Vrácené zásilky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1017')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1017')?.count} />: '-'}</div><div className="cleaner" />
                            <div style={{display: 'none'}}>
                            <Link to={ROUTES.overviewsDetailOffer.getLink(1015)}>Vytištěné finální dokumenty</Link><br />
                            <Link to={ROUTES.overviewsDetailOffer.getLink(1016)}>Elektronicky podáno</Link><br />
                            <Link to={ROUTES.overviewsDetailOffer.getLink(1017)}>Zaplaceno</Link><br />
                            </div>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1018)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1018')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1018')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                      </CardDeck>
                    </Card.Body>
                  </Card> 
                  <br />
                  <Card
                    bg='light'
                    text='dark'
                    
                  >
                    <Card.Header  as="h4">
                      Distribuce zadarmistů
                      <Button 
                        style={{float: 'right'}} 
                        size="sm" 
                        variant="secondary" 
                        onClick={() => { 
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1037','1038','1039',null,null,null)
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1040','1041','1042',null,null,null)
                          rootStore.fetchDatafiltersOffersNewCountsForGroup('1043','1044','1045',null,null,null)
                        }}
                        >
                          Zjistit počty
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      <CardDeck>
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-danger'
                        >
                          <Card.Header  as="h5">DUEL</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1037)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1037')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1037')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1038)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1038')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1038')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1039)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1039')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1039')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-info'
                        >
                          <Card.Header  as="h5">STEREO</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1040)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1040')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1040')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1041)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1041')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1041')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1042)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1042')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1042')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                        <Card
                          bg='light'
                          text='dark'
                          className='border-left-info'
                        >
                          <Card.Header  as="h5">STEREO LAN</Card.Header>
                          <Card.Body>
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1043)}>Schválené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1043')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1043')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1044)}>Vytvořené finální dokumenty</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1044')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1044')?.count} />: '-'}</div><div className="cleaner" />
                            <div className="float-left"><Link to={ROUTES.overviewsDetailOffer.getLink(1045)}>Vyřízené objednávky</Link></div><div className="float-right">{rootStore.getDatafiltersCountById('1045')?.count !== null ?<NumberFormat displayType={'text'} thousandSeparator={' '} value={rootStore.getDatafiltersCountById('1045')?.count} />: '-'}</div><div className="cleaner" />
                          </Card.Body>
                        </Card> 
                      </CardDeck>
                    </Card.Body>
                  </Card> 
                  
                  <br />
                </div>
              </Container>
            </Loader>
            </main>
          </div>
        </div>
      
    </div>
  );
}

export default observer(OverviewsList)
import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getDataFiltersNewFirstUrlPart, getDataFiltersNewProgramId, getDataFiltersNewIsStereoLan, getDataFiltersNewOperationNameForControl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, Badge, Spinner, Table } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
import { useStore, useDialsStore } from '../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

import NumberFormat from 'react-number-format'
import moment from 'moment'

import OthersOperationsDetail from '../../pages/others/operations/components/Others.Operations.Detail.part'

import SelectOptionsDeliveryCzechPostProducts from '../../sharedComponents/filterPanel/components/SelectOptions.DeliveryCzechPostProducts'
import SelectOptionsSubmissions from '../../sharedComponents/filterPanel/components/SelectOptions.Submissions'

interface CustomersOtherActionsUpgradeCashCreateSubmissionsProps {
  mode: string,
  id: string,
  id2: string,
  id3: string,
  //filterId:number
  showAs: string,
  filterId: string,
  info: string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined|null,
  searchValue?:string|undefined|null,
  searchType?:string|undefined|null, 
  searchScenario?:string|undefined|null,
  count?: number|undefined
}

const CustomersOtherActionsUpgradeCashCreateSubmissions: React.FC<CustomersOtherActionsUpgradeCashCreateSubmissionsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [distributionIdToSend, setDistributionIdToSend] = useState('')
 
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  let operations = rootStore.getRuningOperationsByName(getDataFiltersNewOperationNameForControl(props.filterId))
  //Počet záznamů
  const countData:number = Object.keys(operations).length

  const templates = rootDialsStore.dialsDeliveryCzechPostTemplatesAll
  .slice()
  .map(tmpl => (
    <option key={tmpl.id} value={tmpl.id}>{tmpl.name}&nbsp;-&nbsp;{tmpl.note}</option>
  ))  

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    await rootStore.fetchOperations()
    await rootStore.fetchSubmissionsAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostProductsAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostServicesAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostTemplatesAll()

    sessionStorage.removeItem('packet-first-useful-number')
    await rootStore.getLastNumberForPacket()

    rootDialsStore.dialsDeliveryCzechPostServicesAll.map(ds => 
      {
      if (ds.code === '7') { ds.setChecked(true) }
      if (ds.code === '41') { ds.setChecked(true) }
      if (ds.code === '45') { ds.setChecked(true) }
      if (ds.code === 'S') { ds.setChecked(true) }
      if (ds.code === '30') { ds.setChecked(true) }
      return ( '' )
      }
    )

    rootStore.fetchStatesForOperations()
    //operations = await rootStore.getOperationsByName(getDataFiltersNewOperationNameForControl(props.filterId))

    if (props.filtered === true) {
      await rootStore.fetchDatafiltersOffersNewForFilteredActions(Number(props.filterId),props.searchValue,props.searchType,props.searchScenario)
    }

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }



  // Funkce pro odeslání e-mailu
  const handleCreateSubmissions = (submissionId:string,distributionDate:string,note:string,technologicalNumber:string,weight:number,code:number,packetNote:string, productId:string, setFieldValue:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let submissionIdToSend:string = ''

      if (submissionId === '') {
        await axios.post(getUrl('URL_SUBMISSIONS'), { distributionDate: new Date(distributionDate).toISOString(), note: note }, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            submissionIdToSend = response.data.id
            setDistributionIdToSend(response.data.id);
            
            //setFieldValue("submissionId",response.data.id);


            (async () => {
            await rootStore.fetchSubmissionsAll()
            
            //submissionIdToSend = response.data.id
            //setDistributionIdToSend(response.data.id)
            
            setFieldValue("submissionId",response.data.id)
            })()
          }).catch(error => {
            setLoading(false)
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            return
          });
      }
      else {
        submissionIdToSend = submissionId
      }



      const idsToSend = rootStore.datafiltersOffersNewForFilteredActions.map(dfon => dfon.taxDocumentId )

      const idsToSendSelected = rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true).map(dfon => dfon.taxDocumentId )

      //const idsToSend = rootStore.datafiltersOffersNewForFilteredActions.map(dfon => ({ docId : dfon.documentId }))

      // Zavolání API
      if (props.mode === "all") {
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/distribution-started', 
            { 
              submissionId: submissionIdToSend,  
              technologicalNumber: technologicalNumber,
              productId: productId, 
              weight: weight,
              packetNumber: String(code),
              note: packetNote,
              services: rootDialsStore.dialsDeliveryCzechPostServicesAll.filter(items => items.checked === true).map(item => item.id), 
              mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', 
              selectedIds: props.mode === 'all' ? props.filtered === true ? idsToSend : [] : props.multiselect === true ? idsToSendSelected : [props.id], 
              isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), 
              timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')) 
            }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response)
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API
      
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/distribution-started', 
            { 
              submissionId: submissionIdToSend,  
              technologicalNumber: technologicalNumber,
              productId: productId, 
              weight: weight,
              packetNumber: code,
              note: packetNote,
              services: rootDialsStore.dialsDeliveryCzechPostServicesAll.filter(items => items.checked === true).map(item => item.id), 
              mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', 
              selectedIds: props.mode === 'all' ? props.filtered === true ? idsToSend : [] : props.multiselect === true ? idsToSendSelected : [props.id], 
              isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), 
              timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')) 
            }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)


          

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

    })()
  }

  // Schéma validace formuláře 
  const schemaUpdate = Yup.object().shape({
    confirmation: Yup.boolean()
    .oneOf([true], 'Není zatrhnuto potvrzení!'),
    packetNumber: Yup.number()
      .min(1, 'Minimální hodnota je 1')
      .max(9999, 'Maximální hodnota je 9999'),
  })   
  return (
    <>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Vytvoření zásilek'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Vytvoření zásilek'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvořit zásilku'}
          </Dropdown.Item>}
          {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Vytvoření zásilek'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Vytvoření zásilek'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvořit zásilku'}
          </Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mode === 'all' && <>Hromadné vytváření zásilek (<NumberFormat displayType={'text'} thousandSeparator={' '} value={localStorage.getItem('lastDatafilterOfferTotalCount')!} />)</>}
            {(props.mode === 'selected' && props.multiselect === true) && <>Vytváření zásilek ({props.count})</>}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Vytvoření zásilky'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(props.mode === 'selected' && props.multiselect !== true) &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}
          
          <Alert variant="success" show={showInfo}>
            <strong>Požadavek na vytvoření zásilek byl odeslán!​</strong>
            
          </Alert>  
          <div className={showForm} >
            {props.mode === 'alll' &&<div style={{fontStyle: 'italic'}}>
              <i className="fas fa-info-circle"></i>&nbsp;Hromadné operace probíhájí za všechny záznamy. Případně použitý filtr nebude zohledněn.<br /><br />
            </div>}
            
            <div className={countData === 0 ? 'nodisplay' : ''}>
              <br /><strong>Právě probíhají operace, které mohou mít vliv na tuto akci:</strong><hr />
              {operations.map((operation) => (
                <div key={operation.id}>
                  {(operation.isFinished !== true && operation.stateLoaded !== true) 
                    ? 
                    <Spinner animation='border' size="sm" /> 
                    : 
                    <>
                      {operation.state === 1 && <Badge variant="primary">Probíhá</Badge>}
                      {operation.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
                      {operation.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
                      {operation.state === 4 && <Badge variant="success">Dokončeno</Badge>}
                    </>}
                  &nbsp;
                  {operation.nameForMortal} 
                  &nbsp;&nbsp;&nbsp;
                  <div className="float-right">
                  <OthersOperationsDetail id={operation.id} showAs='button' />
                  </div>
                  <hr />
                </div>
               ))}
              <br />
            </div>
            
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                scenarioId: '',
                confirmation: false,
                productId: rootDialsStore.dialsDeliveryCzechPostProductsAll.find(p => p.prefix === 'DR')?.id,
                submissionId: distributionIdToSend,
                distributionDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                note: 'UPGRADE '+moment(new Date()).format("YYYY"),
                technologicalNumber: 'M60388',
                weight: 0.500,
                packetNumber: 0,

                packetNote: '',
                template: '',
                changes:''
              }}
              onSubmit={(values, { setSubmitting, setFieldValue }) => {
                setTimeout(() => {
                  handleCreateSubmissions(values.submissionId!,values.distributionDate!,values.note!,values.technologicalNumber!,values.weight!,values.packetNumber!,values.packetNote!,values.productId!,setFieldValue)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Distribuce</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="submissionId">
                    <Form.Row>
                      <FormLabel column lg={3}>Distribuce</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="submissionId" 
                          onChange={handleChange}
                          value={values.submissionId!}
                          isInvalid={!!errors.submissionId}
                          >
                            <option value="">--- nová distribuce ---</option>
                            {
                            <SelectOptionsSubmissions />
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.submissionId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  {values.submissionId === '' && <>

                  <FormGroup controlId="distributionDate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="distributionDate"
                          value={values.distributionDate}
                          onChange={handleChange}
                          isValid={touched.distributionDate && !errors.distributionDate} 
                          isInvalid={!!errors.distributionDate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.distributionDate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  </>}

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Zásilka</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="template">
                    <Form.Row>
                      <FormLabel column lg={3}>Šablona</FormLabel>
                      <Col lg={6}>
                        <Form.Control as="select" 
                          name="template" 
                          onChange={handleChange}
                          //onChange={(e) => {handleChange(e); if (e.target.value !== '') { setFieldValue('isFinished', false) } }}
                          value={values.template!}
                          isInvalid={!!errors.template}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            templates
                            }
                            
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.template}
                        </Form.Control.Feedback>
                      </Col>
                      <Col>
                        <Button 
                          disabled={values.template !== '' ? false:true}
                          block 
                          onClick={async () => { 
                            setFieldValue("technologicalNumber", rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.technologicalNumber);
                            setFieldValue("weight", rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.weight); 
                            setFieldValue("productId", rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.productId); 
                            await rootDialsStore.dialsDeliveryCzechPostServicesAll.map(ds => 
                              {
                                if (rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.services?.includes(ds.id)) {ds.setChecked(true)}
                                else {ds.setChecked(false)}
                                return ( '' )
                              }
                                 
                            )
                            setFieldValue("changes", "A");
                          }}  
                        >Načíst</Button>
                      </Col>
                    </Form.Row>  
                  </FormGroup>


                  <FormGroup controlId="technologicalNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Identifikátor podavatele</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="technologicalNumber"
                          value={values.technologicalNumber}
                          onChange={handleChange}
                          isValid={touched.technologicalNumber && !errors.technologicalNumber} 
                          isInvalid={!!errors.technologicalNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.technologicalNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="packetNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Zásilky číslovat od:</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="packetNumber"
                          value={values.packetNumber}
                          onChange={handleChange}
                          isValid={touched.packetNumber && !errors.packetNumber} 
                          isInvalid={!!errors.packetNumber}
                        />
                        <span title='Nabízí se nejvyšší použité číslo zásilky + 1 z datumově poslední distribuce.'><i className="fas fa-info-circle"></i>&nbsp;<i>Doporučené číslo: {sessionStorage.getItem('packet-first-useful-number')}</i></span>
                        <Form.Control.Feedback type="invalid">
                          {errors.packetNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="weight">
                    <Form.Row>
                      <FormLabel column lg={3}>Váha ($,$$$)</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="weight"
                          value={values.weight}
                          onChange={handleChange}
                          isValid={touched.weight && !errors.weight} 
                          isInvalid={!!errors.weight}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.weight}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="packetNote">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka zásilky</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="packetNote"
                          value={values.packetNote}
                          onChange={handleChange}
                          isValid={touched.packetNote && !errors.packetNote} 
                          isInvalid={!!errors.packetNote}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.packetNote}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="productId">
                    <Form.Row>
                      <FormLabel column lg={3}>Produkt</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="productId" 
                          onChange={handleChange}
                          value={values.productId!}
                          isInvalid={!!errors.productId}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            <SelectOptionsDeliveryCzechPostProducts />
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.productId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Služby</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>  

                  <Table hover>
                    <tbody>
                      {rootDialsStore.dialsDeliveryCzechPostServicesAll.map(item => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={item.checked}
                              className=""
                              id={'rowcheck'+item.id}
                              onChange={() => {item.setChecked(!item.checked); setFieldValue("changes","")}}
                              style={{height: '18px', width: '18px'}}
                            />
                          </td>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Potvrzení</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="confirmation">
                    <Form.Row>
                      
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="confirmation"
                          size="sm"
                          className="checkbox-small"
                          style={{marginTop: '10px'}}
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.confirmation}
                          
                          isValid={touched.confirmation && !errors.confirmation} 
                          isInvalid={!!errors.confirmation}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmation}
                        </Form.Control.Feedback>
                      </Col>
                      <FormLabel column>Pro pokračování v operaci je nezbytné potvrdit, že ji chcete spustit.</FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám požadavek...' : 'Odeslat požadavek'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsUpgradeCashCreateSubmissions))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
import { useStore, useDialsStore } from '../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

//import NumberFormat from 'react-number-format'
import moment from 'moment'

//import OthersOperationsDetail from '../../pages/others/operations/components/Others.Operations.Detail.part'

//import SelectOptionsDeliveryCzechPostProducts from '../../sharedComponents/filterPanel/components/SelectOptions.DeliveryCzechPostProducts'
import SelectOptionsSubmissions from '../../sharedComponents/filterPanel/components/SelectOptions.Submissions'

import SubmissionPacketEdit from '../../pages/submissions/components/Submissions.Packet.Edit.part'

interface CustomersOtherActionsUpgradeCashRepackingPacketProps {
  mode: string,
  id: string,
  //filterId:number
  showAs: string,
  filterId: string,
  info: string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined|null,
  searchValue?:string|undefined|null,
  searchType?:string|undefined|null, 
  searchScenario?:string|undefined|null,
  count?: number|undefined,
  packetId: string
}

const CustomersOtherActionsUpgradeCashRepackingPacket: React.FC<CustomersOtherActionsUpgradeCashRepackingPacketProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [distributionIdToSend, setDistributionIdToSend] = useState('')

  const [editSubmissionId, setEditSubmissionId] = useState('')
  const [editPacketId, setEditPacketId] = useState('')
  const [editPacketCode, setEditPacketCode] = useState('')
 
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    if (showInfo === true) {
      localStorage.setItem('lastDatafilter','9999')
      window.location.reload();
    }
  }
  const handleShow = async () => {
    //await rootStore.fetchOperations()
    //await rootStore.fetchPacketsById(props.packetId)
    await rootStore.fetchSubmissionsAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostProductsAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostServicesAll()
    sessionStorage.removeItem('packet-first-useful-number')
    await rootStore.getLastNumberForPacket()
    
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Funkce pro odeslání e-mailu
  const handleCreateSubmissions = (values:any, setFieldValue:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let submissionIdToSend:string = ''

      if (values.submissionId === '') {
        await axios.post(getUrl('URL_SUBMISSIONS'), { distributionDate: new Date(values.distributionDate).toISOString(), note: values.note }, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            submissionIdToSend = response.data.id
            setDistributionIdToSend(response.data.id);
            
            //setFieldValue("submissionId",response.data.id);


            (async () => {
            await rootStore.fetchSubmissionsAll()
            
            //submissionIdToSend = response.data.id
            //setDistributionIdToSend(response.data.id)
            
            setFieldValue("submissionId",response.data.id)
            })()
          }).catch(error => {
            setLoading(false)
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            return
          });
      }
      else {
        submissionIdToSend = values.submissionId
      }




      await axios.post(getUrl('URL_PACKETS')+'/'+props.packetId+'/duplicate', 
        { 
          submissionId: submissionIdToSend,  

          id: props.packetId,
          
          packetNumber: values.packetNumber,
          
        }, { headers: { 'Authorization': 'Bearer ' + aToken}}
    ).then(response => {
      // Nastavovačky, co se má v případě, že je to OK, zobrazit
      setEditSubmissionId(response.data.submissionId)
      setEditPacketId(response.data.id)
      setEditPacketCode(response.data.packetCode)

      rootStore.submissions.find(s => s.id === response.data.submissionId)?.setPackets([response.data])

      //console.log(rootStore.submissions.find(s => s.id === response.data.submissionId))
      //console.log(editSubmissionId)
      //console.log(editPacketId)


      setLoading(false)
      setShowForm('nodisplay')
      setShowInfo(true)
      sessionStorage.setItem('autoCloseModal','1');
      //console.log(response)
      // Po 2800 ms celé modální okno zavřeme
      /*setTimeout(() => {
        if (sessionStorage.getItem('autoCloseModal') === '1') {  
          setShow(false)
          setError(null)
          sessionStorage.removeItem('autoCloseModal')
          //setShowInfo(false)
          //setShowForm('')
        }
      }, 2800);*/

    }).catch(error => {
      setLoading(false)
      const errorMessage:any = (<ErrorAlert errorData={error} />)
      setError(errorMessage)
    });



    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({

    packetNumber: Yup.number()
      .min(1, 'Minimální hodnota je 1')
      .max(9999, 'Maximální hodnota je 9999'),
  })   
  return (
    <>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
            Přebalit zásilku
          </Dropdown.Item>}
          {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>
            Přebalit zásilku
          </Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Přebalení zásilky
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(props.mode === 'selected' && props.multiselect !== true) &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}
          
          <Alert variant="success" show={showInfo}>
            <strong>Přebalení zásilky bylo úspěšně provedeno!​</strong>
          </Alert>  
          {showInfo === true && <><br /><SubmissionPacketEdit submissionId={editSubmissionId} showAs='button' packetId={editPacketId} mode='edit' info={editPacketCode!}  /></>}

          <div className={showForm} >

            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                scenarioId: '',
                confirmation: false,
                submissionId: distributionIdToSend,
                distributionDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                note: 'UPGRADE '+moment(new Date()).format("YYYY"),

                
                packetNumber: 0,
                



              }}
              onSubmit={(values, { setSubmitting, setFieldValue }) => {
                setTimeout(() => {
                  handleCreateSubmissions(values,setFieldValue)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Distribuce</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="submissionId">
                    <Form.Row>
                      <FormLabel column lg={3}>Distribuce</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="submissionId" 
                          onChange={handleChange}
                          value={values.submissionId!}
                          isInvalid={!!errors.submissionId}
                          >
                            <option value="">--- nová distribuce ---</option>
                            {
                            <SelectOptionsSubmissions />
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.submissionId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  {values.submissionId === '' && <>

                  <FormGroup controlId="distributionDate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="distributionDate"
                          value={values.distributionDate}
                          onChange={handleChange}
                          isValid={touched.distributionDate && !errors.distributionDate} 
                          isInvalid={!!errors.distributionDate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.distributionDate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  </>}

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Zásilka</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 
                  
                  <FormGroup controlId="packetNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Číslo zásilky:</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="packetNumber"
                          value={values.packetNumber}
                          onChange={handleChange}
                          isValid={touched.packetNumber && !errors.packetNumber} 
                          isInvalid={!!errors.packetNumber}
                        />
                        <span title='Nabízí se nejvyšší použité číslo zásilky + 1 z datumově poslední distribuce.'><i className="fas fa-info-circle"></i>&nbsp;<i>Doporučené číslo: {sessionStorage.getItem('packet-first-useful-number')}</i></span>
                        <Form.Control.Feedback type="invalid">
                          {errors.packetNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
 

                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Přebaluji zásilku...' : 'Přebalit zásilku'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsUpgradeCashRepackingPacket))

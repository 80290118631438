import { types } from 'mobx-state-tree'

export const LicensesTransfers = types
  .model({
    id: types.optional(types.string, ''),
    sourceCustomerId: types.maybeNull(types.string),
    sourceCustomerAccountNumber: types.maybeNull(types.string),
    sourceCustomerBusinessName: types.maybeNull(types.string),
    targetCustomerId: types.maybeNull(types.string),
    targetCustomerAccountNumber: types.maybeNull(types.string),
    targetCustomerBusinessName: types.maybeNull(types.string),
    sourceLicenseId: types.maybeNull(types.string),
    sourceProgram: types.maybeNull(types.string),
    sourceProgramVersion: types.maybeNull(types.string),
    targetLicenseId: types.maybeNull(types.string),
    targetProgram: types.maybeNull(types.string),
    targetProgramVersion: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    surName: types.maybeNull(types.string),
    transferDate: types.maybeNull(types.string),    
   })
  .views(self => ({

  }))
  .actions(self => ({
    

  }))

import React, { useState } from 'react'
import Loader from "react-loader"

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import { Container } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
//eslint-disable-next-line
import { useStore, useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'

import OverviewsCustomers from '../components/Overviews.Customers.part'
//import OverviewsOffers from '../components/Overviews.Offers.part'

//import { Link } from 'react-router-dom'
//import { ROUTES } from '../../../Routes'

//import NumberFormat from 'react-number-format'

//import UpgradeScenarioHeader from '../../../parts/upgradeScenarioHeader/UpgradeScenarioHeader.parts'

function OverviewsList(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);


  // Nastavení práv pro velkou tabulku
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  //const rootUpgradeStore = useUpgradeStore()
  
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'overviews')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {  
      

      
      await (async () => { 
        const promise1 = rootStore.fetchOverviewsCustomers()
        
        return Promise.all([promise1])
      })()  
      
      //await   
      //await  



      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
          }
          //await rootStore.fetchCustomerList(sessionStorage.getItem('customersSearchValue')!, sessionStorage.getItem('customersSearchTypeValue')!,Number(sessionStorage.getItem('customersSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('customersSearchPage')))
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      } 
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })



  return (
    <div className="App">

        <TopMenu />
        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              
              <Container fluid>
                <br />
                <div>
                  <h4>Seznamy zákazníků</h4> 
                  <br />
                  <OverviewsCustomers mode='onlyCustomers' />
                  <br />
                </div>
              </Container>
            </Loader>
            </main>
          </div>
        </div>
      
    </div>
  );
}

export default observer(OverviewsList)
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'



import { useDialsStore } from '../../../store'
import { observer } from 'mobx-react'



interface SupportTypesProps {
      
}

const SelectOptionsSupportTypes: React.FC<SupportTypesProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootDialsStore = useDialsStore()

  // Seznam typů kontaktu pro rozevírací seznam
  const supportTypes = rootDialsStore.dialsSupportTypes
  .slice()
  .filter(dst => dst.isActive === true)
  .sort(function(a:any, b:any) {
    var sort1A = a.order; 
    var sort1B = b.order; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  ))   



  

  //const datum = new Date().toISOString()
  return (
    <>
      {supportTypes}
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsSupportTypes))

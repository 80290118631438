import React, {useState} from 'react'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'

import { Badge, Card, Button, Form, Table, Modal, Alert, CardDeck, Spinner, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap'

import { useStore, useUpgradeStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'
import { getToken, getUrl, getModuleOrder, getPriceListDetailOrder, getDataFiltersNewNumberByState } from '../../../../Utils/Common'

import { ROUTES } from '../../../../Routes'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'
import { confirm } from "../../../../Utils/Confirmation"

import NumberFormat from 'react-number-format'

import UpgradeOfferDetailEdit from '../../components/Upgrade.OfferDetail.Edit.part'
import UpgradeGenerateOffer from '../../components/Upgrade.GenerateOffer.part'
import UpgradeOfferAddIndividualItem from '../../components/Upgrade.Offer.AddIndividualItem.part'
import UpgradeOfferEditIndividualItem from '../../components/Upgrade.Offer.EditIndividualItem.part'
import UpgradeOfferEditState from '../../components/Upgrade.Offer.EditState.part'
import UpgradeOfferResetState from '../../components/Upgrade.Offer.ResetState.part'
import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'

import CustomersDashboardDeliveryAddressUpdate from '../../../customers/dashboard/components/Customers.Dashboard.DeliveryAddress.Update.part'
import CustomersGDPRUpdate from '../../../customers/dashboard/components/Customers.Dashboard.GDPR.Update.part'
import CustomersDashboardLicensesEditModeOfUse from '../../../customers/dashboard/components/Customers.Dashboard.Licenses.EditModeOfUse.part'

import UpgradeOfferState  from '../../components/Upgrade.Offer.StateToDiv.part'

import moment from 'moment'

interface UpgradeOfferListProps {
  isHistory?: boolean
}

const UpgradeOfferListActual: React.FC<UpgradeOfferListProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')  ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  
  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [showE, setShowE] = useState(false)
  const [showProg, setShowProg] = useState(false)
  const [showControl, setShowControl] = useState('')
  const [showFinalize, setShowFinalize] = useState('')
  
  // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
  if (!localStorage.getItem('openedCustomerId')) {
    props.history.push('/customers-open')
  }

  // Načtení Store
  const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()
  const rootDialsStore = useDialsStore()

  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const usageMode = rootStore.customerOpenedUsageMode

  let customerName:string = ''
  let customerSurName:string = ''
  let customerPhone:string = ''

  let countPhoneContacts:number = 0

  if (!(localStorage.getItem('openedCustomerId') === null || localStorage.getItem('openedCustomerId') === undefined)) countPhoneContacts = Object.keys(customer?.contacts.filter(cc => cc.type! === 'P')!).length
  //console.log(countPhoneContacts)

  if (customer?.deliveryAddress !== null) {
    if (customer?.deliveryAddress.name !== null) customerName = customer?.deliveryAddress.name!
    if (customer?.deliveryAddress.surName !== null) customerSurName = customer?.deliveryAddress.surName!
    if (customer?.deliveryAddress.phoneNumber !== null) customerPhone = customer?.deliveryAddress.phoneNumber!
  }

  
  //const upgradeOffers = rootUpgradeStore.upgradeOffers
  
  // Počet záznamů
  const countData:number = Object.keys(rootUpgradeStore.upgradeOffers.filter(d => d.upgradeScenario.isActive === !props.isHistory)).length

  // Zjištění stavu pro kontroly před závazným objednáním
  const customerDeliveryAddressIsOK = customer?.deliveryAddress !== null ?  ((customerName === ''  && customerSurName === '') ) ? false:true : true
  const customerDeliveryAddressIsOKforD = customer?.deliveryAddress !== null ?  ((customerName === ''  && customerSurName === '') || (customerPhone === '')) ? false:true : countPhoneContacts > 0 ? true:false
  const customerGDPRIsOK = rightsBH ? true : customer?.gdprIsActiveForUpgrade

  //console.log(customerDeliveryAddressIsOKforD)

  // Způsoby distribuce
  const distributionMethods = [
    {
      code: 'E',
      name: 'Stažením z internetu (zálohová faktura)'
    },
    {
      code: 'D',
      name: 'Na dobírku (distribuční náklady 500,00 Kč)'
    }
  ]

  // Funkce pro zobrazení nebo skrytí detailních cen
  const showHide = (id:string) => {
    let el=document.getElementById("detail"+id)?.style!; 
    el.display=(el.display === 'block')?'none':'block';

    let elBS=document.getElementById("buttonShow"+id)?.style!;
    elBS.display = (el.display === 'block')?'none':'block'

    let elBH=document.getElementById("buttonHide"+id)?.style!;
    elBH.display = (el.display === 'block')?'block':'none'
    
  }

  // Funkce pro doplnění delšího názvu u služeb 
  const getServicesIndividualName = (code:string) => {
    let longText:string = '' 
    //if (code === 'VS') longText = ' - roční předplatné'
    return longText
  }

  // Otevírání a zavírání modálního okna
  const handleClose = () => {
    //setShow(false)
    setShowControl('')
    setShowFinalize('')
    setShowInfo(false)
    setShowForm('')
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    }


  const setUpgradeScenario = (upgradeScenarioId:string) => {

    
  
    let selectedScenario = rootUpgradeStore.upgradeOffersScenarios.find(us => us.id === upgradeScenarioId)
    let selectedVersion = selectedScenario?.programVersion.version!
  
    let actualDversion:number = rootUpgradeStore.upgradeOffersScenarios.find(usda => usda.isActive === true && usda.program.code === 'D')?.programVersion.version! 
    let actualSversion:number = rootUpgradeStore.upgradeOffersScenarios.find(usda => usda.isActive === true && usda.program.code === 'S')?.programVersion.version!
  
    let diffBetweenActualAndSelectedVersion:number = (selectedScenario?.program.code === 'D'? actualDversion:actualSversion) - selectedVersion!
  
    let stereoSelectedVersion:number = actualSversion - diffBetweenActualAndSelectedVersion
    let duelSelectedVersion:number   = actualDversion - diffBetweenActualAndSelectedVersion
  
    if (selectedScenario?.program.code === 'D') {
      localStorage.setItem('upgradeScenarioDuelSelected', upgradeScenarioId!)
      localStorage.setItem('upgradeScenarioStereoSelected', rootUpgradeStore.upgradeOffersScenarios.find(uss => uss.program.code === 'S' && uss.programVersion.version === stereoSelectedVersion)?.id!)
    }
    else {
      localStorage.setItem('upgradeScenarioDuelSelected', rootUpgradeStore.upgradeOffersScenarios.find(uss => uss.program.code === 'D' && uss.programVersion.version === duelSelectedVersion)?.id!)
      localStorage.setItem('upgradeScenarioStereoSelected', upgradeScenarioId!)
  
    }
  
  }  

  // Otevření seznamu
  const openList = async (state:string,licenceNumber:string,program:string, isStereoLan:boolean, section:string) => {
    let listNumber:string = getDataFiltersNewNumberByState(program,isStereoLan,section,state)
    
    console.log(licenceNumber)

    let overviewsOfferSearchDataArray = [{filterName: "AccountNumber", filterValue: licenceNumber, displayValue: ""}]
    localStorage.setItem('overviewsOfferSearchArray',JSON.stringify(overviewsOfferSearchDataArray))
    
    localStorage.setItem('lastDatafilter','9999')
    
    //sessionStorage.setItem('selectionDetailOfferSearchTextValue', licenceNumber)
    //sessionStorage.setItem('selectionDetailOfferSearchTypeValue', 'AN')
    //sessionStorage.removeItem('selectionDetailOfferSearchScenarioValue')
    //sessionStorage.setItem('selectionDetailOfferPage', '1')

    await props.history.push('/overviews-detail-offer/'+listNumber)
  }

    
  // Dotaz před smazáním objednávky
  const handleOnClickDeleteOffer = (id:string, name:string) => {
    (async () => {
      if (await confirm("Opravdu chcete objednávku "+name+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání objednávky'})) {
        deleteOffer(id)
      } else {
      
      }  
    })()
  }
  
  // Funkce pro smazání objednávky
  const deleteOffer = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      // Zavolání API   
      await axios.delete(getUrl('URL_OFFERS')+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání také odebrat ze store
          rootUpgradeStore.fetchUpgradeOffersToCustomer(localStorage.getItem('openedCustomerId')!)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          
          setShowE(true)
        });
  
      })()    
  }

  // Funkce pro tisk objednávky
  function PrintElem(elem:string)
    {
      let mywindow = window.open('', 'PRINT', 'height=700,width=1024');

      mywindow!.document.write('<html><head><title>' + document.title  + '</title>');

      mywindow!.document.write('<style type="text/css">button {display:none !important;} h5 {font-size: 1.25rem;} h4 {font-size: 1.5rem;} h3 {font-size: 1.75rem;} .offer-under-modules{margin-top: 10px !important;} .float-right{float: right;} .float-left{float: left;} .cleaner{clear: both;} .noprint {display: none !important;}</style>');

      mywindow!.document.write('</head><body >');
      mywindow!.document.write('<div class="float-right" style="font-weight: bold; font-size: 1.5rem">Číslo zákazníka: '+ customer?.accountNumber +'</div>');
      mywindow!.document.write('<h4>'+customer?.businessName+'</h4>');
      mywindow!.document.write('<hr />');
      
      mywindow!.document.write('<h2>Objednávka upgrade | Ježek software s.r.o.</h2>');
      mywindow!.document.write(document.getElementById(elem)!.innerHTML);
      mywindow!.document.write('</body></html>');

      mywindow!.document.close(); // necessary for IE >= 10
      mywindow!.focus(); // necessary for IE >= 10*/

      mywindow!.print();
      mywindow!.close();

      return true;
    }

  // Funkce pro změnu stavu služby - provádí se celý přepočet objednávky
  const handleService = (offerId:string,serviceId:string,state:boolean) => {
    (async () => {
      await setShowProg(true)
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   

      const serviceIds = state === false ? rootUpgradeStore.getUpgradeOfferById(offerId)?.offerServices.filter(data => data.service.id !== serviceId).map(os => (os.service.id)) : rootUpgradeStore.getUpgradeOfferById(offerId)?.offerServices.filter(data => data.service.id !== serviceId).map(os => (os.service.id)).concat(serviceId)
      //const offerItems = rootUpgradeStore.getUpgradeOfferById(offerId)?.offerItems

      let dataToSend={}

      dataToSend = {
        offerId: offerId,  
        targetLicense: rootUpgradeStore.getUpgradeOfferById(offerId)?.targetLicense,
        serviceIds: serviceIds,
        //offerItems: offerItems,
        distributionMethod: rootUpgradeStore.getUpgradeOfferById(offerId)?.distributionMethod
        }
      
      // Zavolání API   
      let url = getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+localStorage.getItem('openedCustomerId')!+'/offers/'+offerId
      if (rightsBH) url = getUrl('URL_OFFERS')+offerId

      await axios.post(url, dataToSend, 
        { 
          headers: { 'Authorization': 'Bearer ' + aToken}
        }
          ).then(response => {
            (async () => {
              await rootUpgradeStore.getUpgradeOfferById(offerId)?.setData(response.data)
              const dataToSave = rootUpgradeStore.upgradeOffers.filter(data => data.id !== offerId).concat(response.data)
              await rootUpgradeStore.fetchUpgradeOffersFromData(dataToSave)
              // Nastavovačky, co se má v případě, že je to OK, zobrazit
              setShowProg(false)
            })()

          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setShowProg(false)
            setShowE(true)
          });
      })()    
  }   
  
  // Funkce pro změnu způsobu distribuce - provádí se celý přepočet objednávky
  const handleDistributionMethod = (offerId:string,distributionMethod:string,state:boolean) => {
    (async () => {
      await setShowProg(true)
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      
      const serviceIds = rootUpgradeStore.getUpgradeOfferById(offerId)?.offerServices.map(os => (os.service.id))

      let dataToSend={}

      dataToSend = {
        offerId: offerId,  
        targetLicense: rootUpgradeStore.getUpgradeOfferById(offerId)?.targetLicense,
        serviceIds: serviceIds,
        distributionMethod: distributionMethod === "E" ? state ? "E" : "D" : state ? "D" : "E"
        }

      // Zavolání API 
      let url = getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+localStorage.getItem('openedCustomerId')!+'/offers/'+offerId
      if (rightsBH) url = getUrl('URL_OFFERS')+offerId

      await axios.post(url, dataToSend, 
        { 
          headers: { 'Authorization': 'Bearer ' + aToken}
        }
          ).then(response => {
            (async () => {
              await rootUpgradeStore.getUpgradeOfferById(offerId)?.setData(response.data)
              const dataToSave = rootUpgradeStore.upgradeOffers.filter(data => data.id !== offerId).concat(response.data)
              await rootUpgradeStore.fetchUpgradeOffersFromData(dataToSave)
              
              // Nastavovačky, co se má v případě, že je to OK, zobrazit
              setShowProg(false)
            })()
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            setShowProg(false)
            setShowE(true)
          });
      })()    
  }

  // Funkce pro závazné objednání
  const handleFinalize = (idData:string) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+customer?.id+'/offers/'+idData+'/state/approve', {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          (async () => {
            await rootUpgradeStore.getUpgradeOfferById(idData)?.setData(response.data)
            const dataToSave = rootUpgradeStore.upgradeOffers.filter(data => data.id !== idData).concat(response.data)
            await rootUpgradeStore.fetchUpgradeOffersFromData(dataToSave)

            // Nastavovačky, co se má v případě, že je to OK, zobrazit
            await setLoading(false)
            await setShowForm('nodisplay')
            await setShowInfo(true)
            await sessionStorage.setItem('autoCloseModal','1')

            // Po 2800 ms celé modální okno zavřeme
            /*
            await setTimeout(() => {
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                setShowFinalize('')
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                //setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);
            */
          })()
        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });    
    })()
  }

  // Seznam objednávek do karet
  const upgradeOffersList = rootUpgradeStore.upgradeOffers.filter(uo => uo.upgradeScenario.isActive === !props.isHistory).slice().sort(function(a:any, b:any) {
    

    var sort1A = a.upgradeScenario.programVersion.version; 
    var sort1B = b.upgradeScenario.programVersion.version; 

    var sort2B = a.upgradeScenario.program.code 
    var sort2A = b.upgradeScenario.program.code

    if (sort2A! < sort2B!) {
      return -1;
    }
    if (sort2A! > sort2B!) {
      return 1;
    }
    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }


/*

    var sort1A = (a.upgradeScenario.programVersion.version-a.actualLicense.programVersion.version)
    var sort1B = (b.upgradeScenario.programVersion.version-b.actualLicense.programVersion.version); 

    var sort2B = a.upgradeScenario.program.code 
    var sort2A = b.upgradeScenario.program.code
    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }
    if (sort2A! < sort2B!) {
      return -1;
    }
    if (sort2A! > sort2B!) {
      return 1;
    }*/


    
    /*
    var nameA = a.upgradeScenario.program.code; 
    var nameB = b.upgradeScenario.program.code; 
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    */
    return 0;
  }).map(upgradeOffer => (
    <div key={upgradeOffer.id}>
      <Card
        bg='light'
        text='dark'
        key={upgradeOffer.id}
        id={upgradeOffer.id}
        className={upgradeOffer?.upgradeScenario.program.code === 'D' ? 'border-left-danger':'border-left-info'}
      >
        <Card.Header as="h3">
          <img className="noprint" alt="logo programu" src={"images/ico-header-"+upgradeOffer?.upgradeScenario.program.code+".png"} style={{marginBottom: '5px', marginRight: '15px' }} />
          {upgradeOffer?.upgradeScenario.program.name} <span>{upgradeOffer?.actualLicense.programVersion.version}&nbsp;&#8594;&nbsp;</span>{upgradeOffer?.upgradeScenario.programVersion.version}&nbsp;<Badge variant={upgradeOffer?.upgradeScenario.program.code === 'D' ? 'secondary':'secondary'}>pro rok {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()}</Badge>
          
          <UpgradeOfferState state={upgradeOffer.finalState !== null ? upgradeOffer.finalState : upgradeOffer?.state!} actual={true} distributionMethod={upgradeOffer?.distributionMethod!} totalZero={upgradeOffer?.isUpgradeFree} returnNote={upgradeOffer.lastReturnNote} />
        </Card.Header>
        <Card.Body>
          <div className="float-left">  
            <h5>Licence</h5> 
            <span className="noprint nodisplay"><br /></span>
            <strong>Typ licence:</strong> {upgradeOffer?.targetLicense.licenseType.code}&nbsp;-&nbsp;{upgradeOffer?.targetLicense.licenseType.name}
            <br /><br />
            <strong>Celkový počet PC:</strong> {upgradeOffer?.targetLicense.count}
            <br /><br />
            <strong>Moduly:</strong>
            
            <Table bordered size="sm" style={{width: '300px'}}>
              <tbody>
              {upgradeOffer?.targetLicense.modules! !== undefined && upgradeOffer?.targetLicense.modules!.slice().sort(function(a:any, b:any) {
                    var nameA = getModuleOrder(a.module.code); 
                    var nameB = getModuleOrder(b.module.code); 
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map(modules => (
                  
                    <tr key={modules.module.id}>
                      <td>{modules.module.name}</td>
                      <td style={{textAlign: 'right'}}>{modules.count}&nbsp;PC</td>
                      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.totalPrice!} />&nbsp;Kč</td>
                    </tr>  
                  ))
              }
              </tbody> 
            </Table>
            {upgradeOffer.upgradeScenario.program.code === 'D' &&<div className="offer-under-modules" style={{fontSize: '90%', marginTop: '-10px', marginBottom: '20px'}}>Na rozšíření v rámci upgrade získáte slevu 20 %.</div>}
                
            <h5>Služby</h5>
            {rootDialsStore.getDialsPriceListsServicesToDate(upgradeOffer.upgradeScenario.distributionDate ?? '').map(dialsService => (
              <div key={upgradeOffer.id+dialsService.service.id}>
                <div className="float-left">
                <Form.Check 
                  type="switch"
                  id={"custom-switch-"+upgradeOffer.id+dialsService.service.id}
                  label=""
                  name={"service-"+upgradeOffer.id+dialsService.service.id}
                  disabled={!rights || !upgradeOffer.showControlButtons}
                  onClick={async () => {await handleService(upgradeOffer.id, dialsService.service.id!, Object.keys(upgradeOffer.offerServices.filter(data => data.service.id === dialsService.service.id)).length > 0 ? false:true);}}
                  defaultChecked={Object.keys(upgradeOffer.offerServices.filter(data => data.service.id === dialsService.service.id)).length > 0 ? true:false}
                />
                </div>
                <div className="float-left" style={{marginLeft: '0px'}}>
                  {dialsService.service.name+''+getServicesIndividualName(dialsService.service.code!)}{dialsService.service.code !== 'I' && <span>&nbsp;(<NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={dialsService.price!} />&nbsp;Kč)</span>}&nbsp;
                  <OverlayTrigger 
                    //trigger="click" 
                    placement="bottom" 
                    overlay={
                      <Popover id="popover-basic">
                        {dialsService.service.code === 'VS' &&<Popover.Title as="h3">Vzdálená správa</Popover.Title>}
                        {dialsService.service.code === 'I' &&<Popover.Title as="h3">Instalace upgrade</Popover.Title>}
                        {dialsService.service.code === 'VS' &&
                        <Popover.Content>
                          Roční předplatné služby, kdy se k vám technici hotline budou moci během telefonátu vzdáleně připojit až na 30 minut denně.
                        </Popover.Content>}
                        {dialsService.service.code === 'I' &&
                        <Popover.Content>
                          Placená služba, kdy vám technik od Ježka program nainstaluje, zaregistruje a převede data jedné firmy. Cena je 600&nbsp;Kč/30&nbsp;minut a fakturována bude samostatně podle skutečné doby.
                        </Popover.Content>}
                      </Popover>
                    }
                  >
                    <Button variant="link" size="sm" style={{padding: '0px', marginTop: '-5px', textDecoration: 'none', fontSize: '120%', color: 'darkgrey'}}><i className="fas fa-question-circle"></i></Button>
                  </OverlayTrigger>
                </div>
                <div className="cleaner"></div>
              </div>

            ))}
  
            <br />

            
            <div style={{display: 'none'}}>
            <h5>ZD</h5>
            {distributionMethods.map(dm => (
              <div key={upgradeOffer.id+dm.code}>
                <div className="float-left">
                <Form.Check 
                  type="switch"
                  id={"custom-switch-"+upgradeOffer.id+dm.code}
                  label=""
                  name={"service-"+upgradeOffer.id+dm.code}
                  disabled={!rights || !upgradeOffer.showControlButtons}
                  onChange={async (e:any) => {await handleClose()}}
                  onClick={async () => {await handleDistributionMethod(upgradeOffer.id, dm.code, upgradeOffer.distributionMethod === dm.code ? false:true);}}
                  checked={upgradeOffer.distributionMethod === dm.code ? true:false}
                />
                </div>
                <div className="float-left" style={{marginLeft: '0px'}}>
                  {dm.name}&nbsp;
                  <OverlayTrigger 
                    //trigger="click" 
                    placement="bottom" 
                    overlay={
                      <Popover id="popover-basic">
                        {dm.code === 'E' &&<Popover.Title as="h3">Stažení z internetu</Popover.Title>}
                        {dm.code === 'D' &&<Popover.Title as="h3">Dobírka</Popover.Title>}
                        {dm.code === 'E' &&
                        <Popover.Content>
                          Začátkem ledna {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()} e-mailem zašleme zálohovou fakturu. Po úhradě a po uvolnění nové verze do distribuce obdržíte e-mailem daňový doklad, registrační kódy, odkaz na stažení programu a návodu k instalaci.
                        </Popover.Content>}
                        {dm.code === 'D' &&
                        <Popover.Content>
                          DVD s programem, registrační kódy a&nbsp;daňový doklad přijdou v balíčku, který zaplatíte doručovateli České pošty.
                        </Popover.Content>}
                      </Popover>
                    }
                  >
                    <Button variant="link" size="sm" style={{padding: '0px', marginTop: '-5px', textDecoration: 'none', fontSize: '120%', color: 'darkgrey'}}><i className="fas fa-question-circle"></i></Button>
                  </OverlayTrigger>
                </div>
                <div className="cleaner"></div>
              </div>

            ))}
  
            <br />
            </div>


            <div className='noprint'>
            <h5>Způsob distribuce</h5>
            <div className="float-left">
            <Form.Check 
              type="switch"
              id={"custom-switch-distE"+upgradeOffer.id}
              label=""
              name={"distEl"+upgradeOffer.id}
              disabled={!rights || !upgradeOffer.showControlButtons}
              checked={upgradeOffer.distributionMethod === 'E' ? true:false}
              onChange={async (e:any) => {await handleClose()}}
              onClick={async () => {await handleDistributionMethod(upgradeOffer.id,"E",upgradeOffer.distributionMethod === 'E' ? false:true);}}
              //checked={true}
            />
            </div>
            <div className="float-left" style={{marginLeft: '0px'}}>
              Stažením z internetu (zálohová faktura)&nbsp;
              <OverlayTrigger 
                //trigger="click" 
                placement="bottom" 
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Title as="h3">Stažení z internetu</Popover.Title>
                    <Popover.Content>
                    Začátkem ledna {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()} e-mailem zašleme zálohovou fakturu. Po úhradě a po uvolnění nové verze do distribuce obdržíte e-mailem daňový doklad, registrační kódy, odkaz na stažení programu a návodu k instalaci.
                    </Popover.Content>
                  </Popover>
                }
              >
                <Button variant="link" size="sm" style={{padding: '0px', marginTop: '-5px', textDecoration: 'none', fontSize: '120%', color: 'darkgrey'}}><i className="fas fa-question-circle"></i></Button>
              </OverlayTrigger>
            </div>
            <div className="cleaner"></div>
            <div className="float-left">
            
            <Form.Check 
              type="switch"
              id={"custom-switch-distD"+upgradeOffer.id}
              label=""
              name={"distDob"+upgradeOffer.id}
              disabled={!rights || !upgradeOffer.showControlButtons}
              checked={upgradeOffer.distributionMethod === 'D' ? true:false}
              onChange={async (e:any) => {await handleClose()}}
              onClick={async () => {await handleDistributionMethod(upgradeOffer.id,"D",upgradeOffer.distributionMethod === 'D' ? false:true);}}
              //checked={true}
            />
            </div>
            <div className="float-left" style={{marginLeft: '0px'}}>
              Na dobírku (distribuční náklady 500,00&nbsp;Kč)&nbsp;
              <OverlayTrigger 
                //trigger="click" 
                placement="bottom" 
                overlay={
                  <Popover id={"popover-basic-d-"+upgradeOffer.id}>
                    <Popover.Title as="h3">Dobírka</Popover.Title>
                    <Popover.Content>
                    DVD s programem, registrační kódy a&nbsp;daňový doklad přijdou v balíčku, který zaplatíte doručovateli České pošty.
                    </Popover.Content>
                  </Popover>
                }
              >
                <Button variant="link" size="sm" style={{padding: '0px', marginTop: '-5px', textDecoration: 'none', fontSize: '120%', color: 'darkgrey'}}><i className="fas fa-question-circle"></i></Button>
              </OverlayTrigger>
            </div>
            <div className="cleaner"></div>
            </div>

            <div className='onlyprint'>
              <h5>Způsob distribuce</h5>
                <div className={upgradeOffer.distributionMethod === 'E' ? '':'noprint'}>
                  Stažením z internetu (zálohová faktura)
                </div>
                <div className={upgradeOffer.distributionMethod === 'D' ? '':'noprint'}>
                  Na dobírku (distribuční náklady 500,00&nbsp;Kč)
                </div>
            </div>

            
            <br />
            {(Object.keys(upgradeOffer.offerItems).length > 0 || (rightsBH && upgradeOffer.showControlButtons)) &&<h5>Individuální položky</h5>}
            <Table bordered size="sm" style={{}}>
              <tbody>
              {upgradeOffer.offerItems.map(offerItem => (
                <tr key={offerItem.id}>
                  <td title={rightsSHBH ? offerItem.note!:offerItem.name!} >{offerItem.name}{offerItem.type === 'L' && <>&nbsp;({offerItem.value} %)</>}</td>
                  <td style={{textAlign: 'right'}}>
                    <NumberFormat 
                      displayType={'text'} 
                      thousandSeparator={'.'} 
                      decimalSeparator={','} 
                      decimalScale={2} 
                      fixedDecimalScale={true} 
                      value={offerItem.price!} 
                    />&nbsp;Kč
                  </td>
                  {rightsBH && upgradeOffer.showControlButtons &&<td><UpgradeOfferEditIndividualItem id={offerItem.id} offerId={upgradeOffer.id} /></td>}
                </tr>
              ))}
              </tbody>
            </Table>
            {rightsBH && upgradeOffer.showControlButtons &&<UpgradeOfferAddIndividualItem id={upgradeOffer.id} />}
            {rightsBH && upgradeOffer.showControlButtons &&<br />}
            <br />
            </div>
          <div className="float-right offer-control" style={{fontWeight: 'normal', maxWidth: '230px', fontSize: '90%'}}>
            <h5>Celkem</h5>
            <h3><Badge variant="secondary"><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.vatBase!} />&nbsp;Kč</Badge></h3>  
            <strong>Uvedené ceny jsou bez DPH.</strong>
            <br />
            Při distribuci k nim bude připočtena daň dle aktuální legislativy.
            <br /><br />
            <strong>Orientační výpočet DPH:</strong>
            <br />
            <span style={{fontStyle: 'italic'}}>
            Daň <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.vat!} />&nbsp;Kč
            <br />
            Celkem s daní <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.totalPrice!} />&nbsp;Kč
            <br />
            <br />
            {((upgradeOffer?.state === 'A' || upgradeOffer?.state === 'O') && upgradeOffer?.distributionMethod === 'E' && upgradeOffer?.totalPrice !== 0) && <><span style={{fontStyle: 'italic'}}>NEPLAŤTE dopředu! Vyčkejte, prosím, na zálohovou fakturu!</span><br /><br /></>}
            {((upgradeOffer?.state === 'A' || upgradeOffer?.state === 'D' || upgradeOffer?.state === 'T' || upgradeOffer?.state === 'U') && upgradeOffer?.distributionMethod === 'D' && upgradeOffer?.totalPrice !== 0) && <><span style={{fontStyle: 'italic'}}>NEPLAŤTE dopředu! Dobírku zaplatíte dopravci!</span><br /><br /></>}

            
            <NavLink style={{textDecoration: 'none', display: 'none'}} to={ROUTES.upgradeOfferDetail.getLink(upgradeOffer.id)}>
              <Button block variant="primary" size="sm"><i className="fas fa-search-dollar"></i>&nbsp;Zobrazit detail objednávky</Button>
            </NavLink>
            
            <Button block variant="secondary" size="sm" onClick={() => {PrintElem(upgradeOffer.id)}}><i className="fas fa-print"></i>&nbsp;Vytisknout objednávku</Button>
            <div  style={{marginBottom: '10px'}}></div>
            {rights && upgradeOffer.showControlButtons && <UpgradeOfferDetailEdit id={upgradeOffer.id} label="Upravit rozsah licence" showIcon={true} block={true} />}
            {rights && upgradeOffer.showControlButtons &&<div  style={{marginBottom: '10px'}}></div>}
            {rights && upgradeOffer.showControlButtons && upgradeOffer.state !== 'C' && <UpgradeOfferResetState id={upgradeOffer.id} label="Obnovit původní stav" showIcon={true} block={true} />}
            {rights && upgradeOffer.showControlButtons &&<div  style={{marginBottom: '10px'}}></div>}
            
            {rightsBH && upgradeOffer.state !== 'S' && (upgradeOffer.state === 'C' || upgradeOffer.state === 'P' || upgradeOffer.state === 'A' || upgradeOffer.state === 'R') &&<UpgradeOfferEditState id={upgradeOffer.id} label="Změnit stav objednávky" showIcon={true} block={true} />}
            {rightsBH &&<div  style={{marginBottom: '10px'}}></div>}

            {rightsBH && ['A', 'O', 'M', 'I', 'D', 'T', 'U', 'F'].includes(upgradeOffer.state!) &&<Button block variant="outline-primary" size="sm"  onClick={async() => {await setUpgradeScenario(upgradeOffer.upgradeScenario.id!);  openList(upgradeOffer.finalState !== null ? upgradeOffer.finalState : upgradeOffer.state!, upgradeOffer.customer.accountNumber!, upgradeOffer.upgradeScenario.program.name!, upgradeOffer.upgradeScenario.program.code === 'D' ? false: upgradeOffer.targetLicense.licenseType.code === 'D' ? true:false, upgradeOffer.isUpgradeFree ? 'Z' : upgradeOffer.distributionMethod === 'E' ? 'E' : 'F' )} }><i className="fas fa-external-link-square-alt"></i>&nbsp;Otevřít v seznamu faktur</Button>}
            {rightsBH && <div  style={{marginBottom: '10px'}}></div>}

            {rightsBH && (upgradeOffer.state === 'C' || upgradeOffer.state === 'P'  || upgradeOffer.state === 'A'  || upgradeOffer.state === 'R'  || upgradeOffer.state === 'S'  || upgradeOffer.state === 'N') && <Button block variant="outline-danger" size="sm" onClick={() => { handleOnClickDeleteOffer(upgradeOffer.id,upgradeOffer?.upgradeScenario.program.name+' '+upgradeOffer?.upgradeScenario.programVersion.version) } } ><i className="fas fa-trash-alt"></i>&nbsp;Smazat objednávku</Button>}
            {rightsBH &&<div  style={{marginBottom: '10px'}}></div>}
            
            {rightsSHBH && <CustomersOtherActionsHistory label='' block={true} title={upgradeOffer?.upgradeScenario.program.name +" "+upgradeOffer?.actualLicense.programVersion.version+" → "+upgradeOffer?.upgradeScenario.programVersion.version} showAs='button' historyType='offerByScenario' historyParam={upgradeOffer.upgradeScenario.id!} />}
            {rightsSHBH &&<div  style={{marginBottom: '10px'}}></div>}

            {rights && upgradeOffer.showControlButtons && upgradeOffer.state !== 'A' && upgradeOffer.state !== 'C'
              ? 
              ((!customerGDPRIsOK && upgradeOffer.servicesCount > 0 ) || (!customerDeliveryAddressIsOK) || (!customerDeliveryAddressIsOKforD && upgradeOffer.distributionMethod === 'D') || (usageMode.find(um => um.programId === upgradeOffer?.upgradeScenario.program.id)?.usageMode === 'x' && !(localStorage.getItem('BigHedgehog')! === '1'))) 
                ?
                <Button block variant="success" size="lg" onClick={() => {setShowControl(upgradeOffer.id) } }><i className="fas fa-cart-arrow-down"></i>&nbsp;Závazně objednat</Button>
                :
                <Button block variant="success" size="lg" onClick={() => {setShowFinalize(upgradeOffer.id) } }><i className="fas fa-cart-arrow-down"></i>&nbsp;Závazně objednat</Button>
              : ''}

            {(upgradeOffer.distributionMethod === 'D' && upgradeOffer.state === 'D' && (upgradeOffer.finalState === 'G' || rightsSHBH)  && !(upgradeOffer.invoices === null || upgradeOffer.invoices === undefined)) && <>
                {upgradeOffer.invoices?.slice().map(i => (<span key={i.id}>
                  {Object.keys(i.packets!).length > 0 && <h5 style={{fontStyle: 'normal', paddingTop: '10px'}}>Sledování zásilky:</h5>}
                  {i.packets?.filter(p => p.submission?.isSubmitted === true || p.submission?.isExported === true).slice().map( p => (<span key={p.id}>
                    <a target='_blank' href={"https://www.postaonline.cz/trackandtrace/-/zasilka/cislo?parcelNumbers="+p.packetCode} title="Sledování zásilky na portálu České pošty" rel="noopener noreferrer">
                      {p.packetCode} {p.returnDate !== null ? <><br />(Vráceno {moment(new Date(p.returnDate)).format('DD.MM.YYYY')}</> : <><br />(Předáno dopravci {moment(new Date(p.submission?.distributionDate!)).format('DD.MM.YYYY')}</>})
                    </a>
                    <br />
                  </span>))}
                </span>))}
            </>}  

            <Modal
              show={showControl === upgradeOffer.id} 
              onHide={handleClose} 
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title>Ještě je potřeba...</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {((!customerGDPRIsOK && upgradeOffer.servicesCount > 0 ) || (!customerDeliveryAddressIsOK) || (!customerDeliveryAddressIsOKforD && upgradeOffer.distributionMethod === 'D') || (usageMode.find(um => um.programId === upgradeOffer?.upgradeScenario.program.id)?.usageMode === 'x' && !(localStorage.getItem('BigHedgehog')! === '1')))  
                  ?
                  <Alert variant="danger" style={{display: 'none'}}>Objednávku není možné dokončit, věnujte pozornost následujícím důvodům!</Alert>
                  :
                  <div>
                    <Alert variant="success">Nyní je vše v pořádku, pokračujte v dokončení objednávky!</Alert>
                    <br />
                    <Button block variant="success" size="lg" onClick={() => {setShowControl(''); setShowFinalize(upgradeOffer.id) } }><i className="fas fa-cart-arrow-down"></i>&nbsp;Pokračovat k dokončení objednávky</Button>
                  </div>
                }
                 
                {(!customerGDPRIsOK && upgradeOffer.servicesCount > 0) &&<span>Podepsat smlouvu o zpracování osobních údajů "GDPR" (pro poskytování vybraných služeb).<br /></span>} 
                {(!customerGDPRIsOK && upgradeOffer.servicesCount > 0) &&<CustomersGDPRUpdate label="Podepsat smlouvu" showIcon={true} block={false} />}   
                {(!customerGDPRIsOK && upgradeOffer.servicesCount > 0) &&<span><br /><br /></span>}

                {(!customerDeliveryAddressIsOK && upgradeOffer.distributionMethod === 'D' ) &&<span>Vyplnit dodací údaje (pro správné doručení zásilky).<br /></span>} 
                {(!customerDeliveryAddressIsOK && upgradeOffer.distributionMethod !== 'D' ) &&<span>Vyplnit dodací údaje.<br /></span>} 
                {(!customerDeliveryAddressIsOK) &&<CustomersDashboardDeliveryAddressUpdate label="Vyplnit dodací údaje" showIcon={true} block={false} validateOnMount={true} />}   
                {(!customerDeliveryAddressIsOK) &&<span><br /><br /></span>}

                {(!customerDeliveryAddressIsOKforD && customerDeliveryAddressIsOK && upgradeOffer.distributionMethod === 'D') &&<span>Vyplnit kompletní dodací údaje (pro správné doručení zásilky na dobírku).<br /></span>} 
                {(!customerDeliveryAddressIsOKforD && customerDeliveryAddressIsOK && upgradeOffer.distributionMethod === 'D') &&<CustomersDashboardDeliveryAddressUpdate label="Vyplnit dodací údaje" showIcon={true} block={false} validateOnMount={true} />}   
                {(!customerDeliveryAddressIsOKforD && customerDeliveryAddressIsOK && upgradeOffer.distributionMethod === 'D') &&<span><br /><br /></span>}

                {(usageMode.find(um => um.programId === upgradeOffer?.upgradeScenario.program.id)?.usageMode === 'x' && !(localStorage.getItem('BigHedgehog')! === '1')) &&<span>Doplnit režim používání programu {upgradeOffer?.upgradeScenario.program.name}.<br /></span>} 
                {(usageMode.find(um => um.programId === upgradeOffer?.upgradeScenario.program.id)?.usageMode === 'x' && !(localStorage.getItem('BigHedgehog')! === '1')) &&<span style={{paddingTop: '10px', display: 'inline-block'}}><CustomersDashboardLicensesEditModeOfUse showIcon={true} label="Doplnit režim používání" id="" block={false} programId={upgradeOffer?.upgradeScenario.program.id!} programCode={upgradeOffer?.upgradeScenario.program.code!} /></span>}   
                {(usageMode.find(um => um.programId === upgradeOffer?.upgradeScenario.program.id)?.usageMode === 'x' && !(localStorage.getItem('BigHedgehog')! === '1')) &&<span><br /><br /></span>}
                
                {((!customerGDPRIsOK && upgradeOffer.servicesCount > 0 ) || (!customerDeliveryAddressIsOK))  
                  ?
                  <div>
                    <hr />
                    <div className="float-right">
                      <Button size="sm" variant="secondary" onClick={handleClose}>Zavřít okno</Button>                            
                    </div>
                  </div>
                  :
                  ''}
              </Modal.Body>
            </Modal>

            <Modal
              show={showFinalize === upgradeOffer.id} 
              onHide={handleClose} 
              
              dialogClassName="modal-90w"
              style={{maxWidth: 'none !important'}}
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  <img alt="logo programu" src={"images/ico-header-"+upgradeOffer?.upgradeScenario.program.code+".png"} style={{marginBottom: '5px', marginRight: '15px' }} />
                  {showInfo ? 'Dokončení':'Shrnutí'} objednávky upgrade {upgradeOffer?.upgradeScenario.program.name} {upgradeOffer?.upgradeScenario.programVersion.version}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Alert variant="success" show={showInfo}>
                  <strong>Objednávka upgrade programu {upgradeOffer?.upgradeScenario.program.name} {upgradeOffer?.upgradeScenario.programVersion.version} byla úspěšně dokončena!​</strong>
                </Alert>


                <div className={showForm} >
                <Row>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                  <Card bg='light' text='dark' style={{height: '100%'}}>
                      <Card.Body>
                        <h5>Fakturační údaje</h5>
                        {customer?.name !== '' ? <span><strong>{customer?.name}</strong></span> : ''}
                        {customer?.name !== '' ? <br /> : ''}

                        {customer?.person !== '' ? <span><strong>{customer?.person}</strong></span> : ''}
                        {customer?.person !== '' ? <br /> : ''}
                        <br />
                        {customer?.billingAddress?.street}&nbsp;{customer?.billingAddress?.houseNumber}
                        {customer?.billingAddress?.identificationNumber === null || customer?.billingAddress?.identificationNumber === '' ? '':'/'}
                        {customer?.billingAddress?.identificationNumber}
                        <br />
                        {customer?.billingAddress?.postalCode}&nbsp;{customer?.billingAddress?.city}
                        <br /><br />
                        <strong>IČO:</strong>&nbsp;{customer?.companyRegistrationNumber}
                        <br />
                        <strong>DIČ:</strong>&nbsp;{customer?.taxIdentificationNumber}
                        <br />
                        <strong>Plátce DPH:</strong>&nbsp;{customer?.isTaxAble === true ? 'Ano':'Ne'}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    <Card bg='light' text='dark'>
                      <Card.Body>
                        <h5>Dodací údaje</h5>
                        {customer?.deliveryAddress === null ? '(stejné jako fakturační)' : ''}

                        {customer?.deliveryAddress !== null && customer?.deliveryAddress?.name !== '' ? <span><strong>{customer?.deliveryAddress?.name}</strong></span> : ''}
                        {customer?.deliveryAddress !== null && customer?.deliveryAddress?.name !== '' ? <br /> : ''}

                        {customer?.deliveryAddress !== null && customer?.deliveryPerson !== '' ? <span><strong>{customer?.deliveryPerson}</strong></span> : ''}
                        {customer?.deliveryAddress !== null && customer?.deliveryPerson !== '' ? <br /> : ''}
                        <br />
                        {customer?.deliveryAddress?.street}&nbsp;{customer?.deliveryAddress?.houseNumber}
                        {customer?.deliveryAddress === null || customer?.deliveryAddress?.identificationNumber === null || customer?.deliveryAddress?.identificationNumber === '' ? '':'/'}
                        {customer?.deliveryAddress?.identificationNumber}
                        {customer?.deliveryAddress !== null ? <br /> : ''}
                        {customer?.deliveryAddress?.postalCode}&nbsp;{customer?.deliveryAddress?.city}
                        {customer?.deliveryAddress !== null ? <br /> : ''}
                        {customer?.deliveryAddress !== null ? <br /> : ''}
                        {customer?.deliveryAddress !== null && customer?.deliveryAddress?.phoneNumber !== '' ? <span><strong>Telefon:</strong>&nbsp;{customer?.deliveryAddress?.phoneNumber}</span> : ''} 
                      </Card.Body>
                    </Card>
                    <div  style={{marginBottom: '15px'}}></div>
                    <Card bg='light' text='dark'>
                      <Card.Body>
                        <h5>Způsob distribuce</h5>
                        {upgradeOffer.distributionMethod === 'E' ? 'Stažením z internetu (zálohová faktura)':'Na dobírku (distribuční náklady 500,00 Kč)'}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                  <CardDeck>
                    
                    
                  </CardDeck>
                  <div  style={{marginBottom: '15px'}}></div>
                  <Card bg='light' text='dark' border='success' >
                    <Card.Body>
                      <div className="float-left">
                        <h4>Celkem</h4>
                        
                      </div>
                      <div className="float-right" style={{textAlign: 'right', fontWeight: 'normal', fontSize: '90%'}}>
                        <h3><Badge variant="secondary"><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.vatBase!} />&nbsp;Kč</Badge></h3>  
                        <strong>Uvedené ceny jsou bez DPH.</strong>
                        <br />
                        Při distribuci k nim bude připočtena daň dle aktuální legislativy.
                        <br />
                        <strong>Orientační výpočet DPH:</strong>
                        <br />
                        <span style={{fontStyle: 'italic'}}>
                        Daň <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.vat!} />&nbsp;Kč,
                        {' '}
                        Celkem s daní <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.totalPrice!} />&nbsp;Kč
                        </span>
                      </div>

                    </Card.Body>
                  </Card>
                  <br />
                  {error}
                  <br />
                  <Button block variant="success" size="lg" onClick={() => {handleFinalize(upgradeOffer.id) } } disabled={loading}><i className="fas fa-cart-arrow-down"></i>&nbsp;{loading ? 'Dokončování objednávky...' : 'Dokončit objednávku'}</Button>
                </div>

                {showInfo && 
                <div>
                  <p>
                    Co se bude dít dál?
                    <br /><br />

                    {upgradeOffer.isUpgradeFree === true ?
                    <>
                      {upgradeOffer.isBeforeDistributionDate === true ?
                      <>
                      Upgrade obdržíte zdarma. Licenční lístek s registračními kódy a návodem ke stažení a instalaci nové verze programu vám po zahájení jeho <strong>distribuce (druhá polovina ledna {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()})</strong> automaticky zašleme na fakturační e-mail.
                      </>
                      :
                      <>
                      Licenční lístek s registračními kódy a návodem ke stažení a instalaci nové verze programu vám v nejbližších dnech automaticky zašleme na fakturační e-mail.
                      </>
                      }
                    
                    </>
                    :
                    <>
                      {upgradeOffer.distributionMethod === 'E' ? 
                      <>
                        {upgradeOffer.isBeforeDistributionDate === true ?
                        <>
                        Protože jste si zvolili způsob distribuce nové verze elektronickou formou, <strong>zašleme vám v první polovině ledna {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()} na fakturační e-mail zálohovou fakturu</strong>. Po její úhradě a po uvolnění nové verze programu do distribuce (druhá polovina ledna {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()}) obdržíte také finální daňový doklad, licenční lístek s registračními kódy a návod ke stažení a instalaci nové verze programu.
                        </>
                        :
                        <>
                        Protože jste si zvolili způsob distribuce nové verze elektronickou formou, <strong>zašleme vám v nejbližších dnech na fakturační e-mail zálohovou fakturu</strong>. Po její úhradě obdržíte také finální daňový doklad, licenční lístek s registračními kódy a návod ke stažení a instalaci nové verze programu.
                        </>
                        }
                      </>
                      :
                      <>
                        {upgradeOffer.isBeforeDistributionDate === true ?
                        <>
                        Protože jste si zvolili způsob distribuce na dobírku, <strong>balíček s novou verzí programu předáme dopravci obratem po uvolnění nové verze do distribuce (druhá polovina ledna {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()})</strong>. Kromě instalačního nosiče naleznete v balíčku také daňový doklad, registrační kódy a návod na instalaci.<br /><br /><strong>Dobírku, prosím, standardně zaplaťte doručovateli.</strong>
                        </>
                        :
                        <>
                        Protože jste si zvolili způsob distribuce na dobírku, <strong>balíček s novou verzí programu pro vás v nejbližších dnech zabalíme a předáme dopravci</strong>. Kromě instalačního nosiče naleznete v balíčku také daňový doklad, registrační kódy a návod na instalaci.<br /><br /><strong>Dobírku, prosím, standardně zaplaťte doručovateli.</strong>
                        </>
                        }
                      </>
                      }
                    </>
                    }


                    <br /><br />
                    Stejné informace naleznete také v e-mailu, který jsme Vám právě odeslali.<br /><br />
                    Děkujeme Vám za objednávku.

                    <br /><br />
                    <span className='nodisplay'><strong>Předpokládaný termín distribuce je v pondělí 23. ledna 2023.</strong></span>
                  </p>
                  <hr />
                  <div className="float-right">
                    <Button size="sm" variant="secondary" onClick={handleClose}>Zavřít okno</Button>                            
                  </div>
                </div>
                }
  

              </Modal.Body>
            </Modal>            

            </span>
            <br />
          </div>  
          
            <div className='cleaner'></div>

            {upgradeOffer.defaultLicense === null &&<div style={{marginBottom: '20px'}}><i className="fas fa-info-circle noprint"></i>&nbsp;<i>Program jste zakoupili po 1. 9. {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()-1 }, pro aktuální rozsah licence získáváte upgrade na rok {new Date(upgradeOffer.upgradeScenario.distributionDate!).getFullYear()} zdarma.</i></div> }

            <Button variant='secondary' style={{display: 'block'}} id={"buttonShow"+upgradeOffer.id} onClick={() => {showHide(upgradeOffer.id)}}><i className="fas fa-angle-double-down"></i>&nbsp;Zobrazit detailní rozpis cen modulů</Button>
            <Button variant='secondary' style={{display: 'none'}}  id={"buttonHide"+upgradeOffer.id} onClick={() => {showHide(upgradeOffer.id)}}><i className="fas fa-angle-double-up"></i>&nbsp;Skrýt detailní rozpis cen modulů</Button>
            
            <div className="offer-detail" id={"detail"+upgradeOffer.id} style={{display: 'none'}}>
              <br className="noprint" />
            <h5>Detailní rozpis cen</h5>
                
                {upgradeOffer?.targetLicense.modules! !== undefined && upgradeOffer?.targetLicense.modules!.slice().sort(function(a:any, b:any) {
                  var nameA = getModuleOrder(a.module.code); 
                  var nameB = getModuleOrder(b.module.code); 
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                }).map(modules => (
                
                  <div key={modules.module.id} style={{marginBottom: '10px'}}>
                  <Card
                    bg='light'
                    text='dark'
                    style={{minWidth: '300px'}}
                  >
                    <Card.Header as="h5">
                      <strong>{modules.module.name}</strong>
                      <div className="float-right" style={{textAlign: 'right', fontWeight: 'normal', minWidth: '150px'}}>
                        <Badge variant="secondary"><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.totalPrice!} />&nbsp;Kč</Badge>
                      </div>
                      <div className="cleaner"></div>
                    </Card.Header>
                    <Card.Body className='card-no-mouse-over-effect'>
                      
                      <div className="float-left">
                        <table>
                          <tbody>
                            <tr>
                              <td></td>
                              <td style={{textAlign:'center', minWidth: '80px'}}><Badge pill variant={upgradeOffer?.upgradeScenario.program.code === 'D' ? 'secondary':'secondary'}>Aktuální</Badge></td>
                              <td style={{textAlign:'center', minWidth: '20px'}}></td>
                              <td style={{textAlign:'center', minWidth: '80px'}}><Badge pill variant={upgradeOffer?.upgradeScenario.program.code === 'D' ? 'secondary':'secondary'}>Požadovaný</Badge></td>
                              
                            </tr>
                            <tr>
                              <td>Počet&nbsp;PC</td>
                              <td style={{textAlign:'center'}}><input type="text" disabled={true} defaultValue={upgradeOffer?.actualLicense.modules?.find(actualModule => actualModule.module.id === modules.module.id)?.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} /></td>
                              <td style={{textAlign:'center'}}><i className="fas fa-angle-double-right"></i></td>
                              <td style={{textAlign:'center'}}><input type="text" disabled={true} value={modules.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} /></td>
                              
                            </tr>
                          </tbody>
                        </table>
                        
                        {Object.keys(upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.items!).length > 0 &&
                        <div>
                        <hr />
                        <h6 style={{fontWeight: 'normal'}}>Detailní rozpis ceny:</h6> 
                        {upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.items?.slice().sort(function(a:any, b:any) {
                          var nameA = getPriceListDetailOrder(a.priceType, a.isFirst); 
                          var nameB = getPriceListDetailOrder(b.priceType, b.isFirst);
                          
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        }).map((priceDetail, index) => (
                          <div key={index} style={{fontStyle: 'italic'}}>
                            
                            {priceDetail.priceType === 'P' && 'Upgrade'}
                            {priceDetail.priceType === 'F' && 'Upgrade z rozšíření v ochranném období'}
                            {priceDetail.priceType === 'S' && 'Doplatek za přechod na vyšší typ licence'}
                            {priceDetail.priceType === 'E' && 'Rozšíření v rámci upgrade s 20% slevou'}
                            
                            {priceDetail.priceType !== 'F' ? priceDetail.isFirst ? ' 1. PC (':' další PC (':' ('}
                            <strong>{priceDetail.count}x</strong>):&nbsp;
                            <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={priceDetail.price!} />&nbsp;Kč
                          </div>
                        ))}
                        </div>}

                      </div>
                      <div className="cleaner"></div>
                    </Card.Body>
                  </Card>
                  </div>
                
                ))} 
                <br />
          </div>
        </Card.Body>
      </Card>
      <br />
    </div>  
  ))
   
  return (
    <div>
      {!props.isHistory ?<h4>Aktuální objednávky upgrade</h4>:<><br /><h4>Archiv objednávek</h4></>}
      {countData > 0 && <p>Počet objednávek: {countData}</p>}
      <br />
      
      {countData === 0 ? <div>Seznam aktuálních objednávek neobsahuje žádné záznamy.<br /><br /></div> : ''}
      {upgradeOffersList}
      {rightsBH === true && customer?.isActive === true && <UpgradeGenerateOffer mode='selected' id={customer?.id} />}

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání objednávky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={showProg} 
        onHide={handleClose} 
        //size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Uložení změn</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign: 'center'}}>
          <Spinner animation="border" variant="danger" />&nbsp;Probíhá ukládání změn
        </Modal.Body>
      </Modal>

    </div>
  )  
}

export default withRouter(observer(UpgradeOfferListActual))

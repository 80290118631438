import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation"

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import CustomersOtherActionsEmailHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.EmailHistory.part'
import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'
import CustomersOtherActionsSendEmail from '../../../../parts/customersOtherActions/Customers.OtherActions.SendEmail.part'

interface CustomersContactsUpdateProps {
  id: string
}

const CustomersContactsUpdate: React.FC<CustomersContactsUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') ) ? true : false
  const rightsBHSH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const customerContact = customer?.contacts.find(contact => contact.id === props.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteContact = () => {
    (async () => {
      if (await confirm("Opravdu chcete kontakt "+customerContact?.value+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání kontaktu'})) {
        deleteContact()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání kontaktu
  const deleteContact = () => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      // Zavolání API   
      await axios.delete(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id+'/contacts/'+props.id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání také odebrat ze store
          customer?.deleteContact(props.id)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          
          setShowE(true)
        });
  
      })()    
  }

  // Funkce pro změnu kontaktu
  const handleUpdate = (typeData:string, valueData:string, noteData:string, isBillingData:boolean) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.patch(getUrl('URL_CUSTOMER_LIST')+'/'+customer?.id+'/contacts/'+props.id, { id: props.id, type: typeData, value: valueData, note: noteData, isBilling: isBillingData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          // Změna dat ve Store
          customer?.setContact(props.id,typeData,valueData,noteData,isBillingData)
          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    value: Yup.string().when('type', {
      is: 'P',
      then: Yup.string().required('Hodnota musí být vyplněna!').matches(/^([0-9+ ]{1,100})$/, 'Telefonní číslo může obsahovat pouze číslice, mezeru a znak +').max(17, 'Telefon může obsahovat maximálně 17 znaků.'),
      otherwise: Yup.string().required('Hodnota musí být vyplněna!').email('E-mailová adresa je ve špatném formátu!')
    }),  
    /*
    value: Yup.string()
    .required('Hodnota musí být vyplněna!'),*/
  })   
  return (
    <>
      
      
      {rights && customer?.isActive  &&
        <>
          <Button variant="primary" size="sm" onClick={handleShow}>
            <i className="fas fa-edit"></i>
          </Button>
          <span style={{marginLeft: '10px'}}></span>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <Dropdown.Item onClick={() => { handleOnClickDeleteContact()} }>Smazat kontakt</Dropdown.Item>
            {rightsBH && <Dropdown.Divider />}
            {rightsBH && customerContact?.type! === 'E' &&<CustomersOtherActionsSendEmail mode='selected' id={props.id} filterId={0} showAs='drop' toConcreteEmail={true} concreteEmail={customerContact?.value!} />}
            {rightsBH && <Dropdown.Divider />}
            {rightsBH && customerContact?.type! === 'E' &&<CustomersOtherActionsEmailHistory showAs='drop' email={customerContact?.value!} />}
            {rightsBH &&<CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerContact?.value!} historyType='customerContact' historyParam={customerContact?.id!} />}
            
          </DropdownButton>
        </>
      }
      {((customer?.isActive === false) && rightsBHSH)  &&
        <>
        <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
          {customerContact?.type! === 'E' &&<CustomersOtherActionsEmailHistory showAs='drop' email={customerContact?.value!} />}
          <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerContact?.value!} historyType='customerContact' historyParam={customerContact?.id!} />
          </DropdownButton>
        </>
      }
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Kontakt byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                type: customerContact?.type, 
                value: customerContact?.value, 
                note: customerContact?.note,
                isBilling: customerContact?.isBilling
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.type!, values.value!, values.note!, values.isBilling!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
               
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="type">
                    <Form.Row>
                      <FormLabel column lg={3}>Typ</FormLabel>
                      <Col md="3">
                        <Form.Control as="select" 
                          name="type" 
                          onChange={handleChange}
                          value={values.type!}
                          >
                            <option value="P">Telefon</option>
                            <option value="E">E-mail</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.type}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                    
                  <FormGroup controlId="value">
                    <Form.Row>
                      <FormLabel column lg={3}>Kontakt</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="value"
                          value={values.value!}
                          onChange={handleChange}
                          isValid={touched.value && !errors.value} 
                          isInvalid={!!errors.value}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.value}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>            

                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Popis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="isBilling" className={values.type === 'P' ? 'nodisplay':''}>
                    <Form.Row>
                      <FormLabel column lg={3}>Fakturační e-mail:</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isBilling"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isBilling}
                          
                          isValid={touched.isBilling && !errors.isBilling} 
                          isInvalid={!!errors.isBilling}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.isBilling}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersContactsUpdate))

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { observer } from 'mobx-react'

interface UpgradeOfferStateProps {
  state: string,
  actual: boolean,
  distributionMethod : string,
  totalZero? :boolean,
  returnNote? :string,
}

const UpgradeOfferState: React.FC<UpgradeOfferStateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  return (
    <>
<div className="float-right" style={{fontSize: '50%', lineHeight: '20px', textAlign: 'right'}}>
            {props.state === "C" &&
            <span>
              <span style={{color: props.actual ? '#2e59d9':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Vytvořeno</span>
              {props.actual && <> 
              <br />
              (čeká na zveřejnění)
              </> }
            </span>}
            {props.state === "P" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Nabídnuto</span>
              {props.actual && <> 
              <br />
              (čeká na závazné objednání)
              </> }
            </span>}
            {props.state === "A" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Objednáno</span>
              {props.actual && <> 
              <br />
              (čeká se na {props.totalZero ? 'zahájení distribuce' : props.distributionMethod === "E" ? rightsSHBH ? "vytvoření zálohové faktury": "vystavení zálohové faktury" : "zahájení distribuce" })
              </> }
            </span>}

            {props.state === "O" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;{rightsSHBH ? "Zálohová faktura vytvořena" : "Objednáno"}</span>
              {props.actual && <>
              <br />
              (čeká se na {rightsSHBH ? "zveřejnění" : "vytvoření"} zálohové faktury)
              </> }
            </span>}
            {props.state === "M" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;{rightsSHBH ? "Zálohová faktura vystavena" : "Zálohová faktura vystavena"}</span>
              {props.actual && <>
              <br />
              (čeká se na přijetí platby)
              </> }
            </span>}
            {props.state === "I" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Zaplaceno</span>
              {props.actual && <>
              <br />
              (čeká se na {rightsSHBH ? "vytvoření finálních dokumentů": "zahájení distribuce"})
              </> }
            </span>}
            {props.state === "D" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;{rightsSHBH ? "Finální dokumenty vytvořeny": props.totalZero ? 'Objednáno' : props.distributionMethod === "E" ? "Zaplaceno" : "Objednáno"}</span>
              {props.actual && <>
              <br />
              (čeká se na {rightsSHBH ? props.distributionMethod === "E" ? "jejich zveřejnění" : "provedení distribuce" : "zahájení distribuce"})
              </> }
            </span>}
            {props.state === "T" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;{rightsSHBH ? "Distribuce zahájena" : "Distribuce zahájena"}</span>
              {props.actual && <>
              <br />
              (čeká se na {rightsSHBH ? "předání záilky dopravci": "předání záilky dopravci"})
              </> }
            </span>}
            {props.state === "G" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Zásilka předána dopravci</span>
              {props.actual && <>
              <br />
              ({rightsSHBH ? "čeká se na přijetí platby": "dobírku zaplatíte doručovateli"})
              </> }
            </span>}
            {props.state === "E" &&
            <span>
              <span style={{color: props.actual ? 'orange':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Zásilka byla vrácena</span>
              {props.actual && <>
              <br />
              <>({props.returnNote})</>
              </> }
            </span>}

            {props.state === "R" &&
            <span>
              <span style={{color: props.actual ? 'red':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Odmítnuto</span>
              {props.actual && <>
              <br />
              (na žádost zákazníka)
              </> }
            </span>}
            {props.state === "S" &&
            <span>
              <span style={{color: props.actual ? 'red':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Pozastaveno</span>
              {props.actual && <>
              <br />
              (připravuje se nová objednávka)
              </> }
            </span>}
            {props.state === "N" &&
            <span>
              <span style={{color: props.actual ? 'red':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Zneplatněno</span>
              
            </span>}
            {props.state === "F" &&
            <span>
              <span style={{color: props.actual ? 'green':'#858796'}}><i className="fas fa-circle noprint"></i>&nbsp;Hotovo</span>
              {props.actual && <>
              <br />
              (objednávka je vyřízena)
              </> }
            </span>}
          </div>
          <div className="cleaner"></div>
    </>
  )  
  
} 

export default withRouter(observer(UpgradeOfferState))

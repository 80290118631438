import React from 'react'
import { withRouter } from 'react-router-dom'

import {Container, ButtonGroup, Table, Card} from 'react-bootstrap'

import DialsOperationsPreferencesUpdate from './Dials.Operations.Preferences.Update.part'

import { useDialsStore, useStore } from '../../../../../store'
import { observer } from 'mobx-react'

const DialsOperationsPreferencesList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení

  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Načtení Store
  const rootDialsStore = useDialsStore()
  const rootStore = useStore()
  
  const dialsOperationsPreferences1 = rootDialsStore.dialsOperationsPreferences.filter(dop => dop.order > 0 && dop.order < 7 ).slice().sort(function(a:any, b:any) {
    var sort1A = a.order; 
    var sort1B = b.order; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  
  //Počet záznamů
  const countData:number = Object.keys(dialsOperationsPreferences1).length

  // Seznam pozvánek do řádků tabulky
  const linesOperationsPreferences1 = dialsOperationsPreferences1.map((dop) => (
    <tr key={dop.id}>
      <td>{dop.keyName}</td>
      <td>
        {dop.valueType === 'service' && rootDialsStore.dialsServices.find(s => s.id === dop.value)?.name}
        {dop.valueType === 'supportTheme' && rootDialsStore.dialsSupportThemes.find(s => s.id === dop.value)?.name}
        {dop.valueType === 'supportType' && rootDialsStore.dialsSupportTypes.find(s => s.id === dop.value)?.name}
        {dop.valueType === 'user' && rootStore.hedgehogUsers.find(s => s.id === dop.value)?.fullName}
      </td>
      
      {rights &&<td>
      
      {rightsBH &&<>
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">
          <ButtonGroup>
            <DialsOperationsPreferencesUpdate id={dop.id} showAs='button' />
          </ButtonGroup>
          
        </div>
      </>}
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesOperationsPreferencesCard1 = dialsOperationsPreferences1.map((dop) => (
    <div key={dop.id}>
    <Card>
      <Card.Body>
        {rights && <>
          {rightsBH &&<>  
            <div className="float-right">
            <ButtonGroup>
              <DialsOperationsPreferencesUpdate id={dop.id} showAs='button' />
            </ButtonGroup>
            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{dop.keyName}</strong><br />
          {dop.valueType === 'service' && rootDialsStore.dialsServices.find(s => s.id === dop.value)?.name}
          {dop.valueType === 'supportTheme' && rootDialsStore.dialsSupportThemes.find(s => s.id === dop.value)?.name}
          {dop.valueType === 'supportType' && rootDialsStore.dialsSupportTypes.find(s => s.id === dop.value)?.name}
          {dop.valueType === 'user' && rootStore.hedgehogUsers.find(s => s.id === dop.value)?.fullName}
          <br />
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  
    
  
 
  
  return (
    <Container fluid>

      <br />
      <h5>Automatické generování kontaktů po úhradě objednávky upgrade vyplývající z objednané služby "Instalace upgrade technikem JSW"</h5>
      <br />

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam preferencí neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesOperationsPreferencesCard1}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Název</th>
                <th className="jsw-table-head">Hodnota</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesOperationsPreferences1}
            </tbody>
          </Table>
        </div> 
        <br />  
        
      </div>  

      
    </Container>
  )  
  
}

export default withRouter(observer(DialsOperationsPreferencesList))

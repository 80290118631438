// eslint-disable-next-line
import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUser } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'
// eslint-disable-next-line
import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Row, Badge, Spinner} from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
//import FormGroup from 'react-bootstrap/FormGroup'
//import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../Constants/Constants'
// eslint-disable-next-line
//import axios from 'axios';

//import { confirm } from "../../../../Utils/Confirmation";

import OthersOperationsDetail from './Others.Operations.Detail.part'


import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

//import { Formik } from 'formik'
//import * as Yup from 'yup'

//import Pagination from 'react-js-pagination'

import moment from 'moment'


const OthersOperationsList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line



  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  

  // Načtení Store
  const rootStore = useStore()
  
  const operations = rootStore.operations
  
  //Počet záznamů
  const countData:number = Object.keys(operations).length

  // Seznam pozvánek do řádků tabulky
  const linesOperations = operations.map((operation) => (
    <tr key={operation.id}>
      <td>{moment(operation.startOn).format("D.M.YYYY H:mm")}</td> 
      <td>{operation.nameForMortal}</td>
      <td>{operation.userName}</td>
      <td>{operation.finishedCount}/{operation.totalCount}</td>
      <td>{moment(operation.expireOn).format("D.M.YYYY H:mm")}</td>

      <td>
        {(operation.stateLoaded === false) 
        ? 
        <Spinner animation='border' size="sm" /> 
        : 
        <>
          {operation.state === 1 && <Badge variant="primary">Probíhá</Badge>}
          {operation.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
          {operation.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
          {operation.state === 4 && <Badge variant="success">Dokončeno</Badge>}
          <Badge variant='secondary' style={{marginLeft: '5px'}}>{moment(operation.isFinished ? operation.lastActivity : new Date()).diff(operation.startOn, 'minutes')}&nbsp;min.</Badge>
        </>}
      </td>
      
      {rights &&<td>
      <div className="float-left"></div>
      <ButtonGroup>
        <OthersOperationsDetail id={operation.id} showAs='button' />
        <Button size='sm' variant='outline-secondary' onClick={() => rootStore.fetchStateForOperation(operation.id) }><i className="fas fa-sync-alt"></i></Button>

      </ButtonGroup>
      {rightsBH &&<>
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">

          
        </div>
      </>}
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesOperationsCard = operations.map((operation) => (
    <div key={operation.id}>
    <Card>
      <Card.Body>
        {rights && <>
          {rightsBH &&<>  
            <div className="float-right">

            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        <div className="float-right">
        <ButtonGroup>
          <OthersOperationsDetail id={operation.id} showAs='button' />
          <Button size='sm' variant='outline-secondary' onClick={() => rootStore.fetchStateForOperation(operation.id) }><i className="fas fa-sync-alt"></i></Button>
        </ButtonGroup>
          
        </div>
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
        {(operation.stateLoaded === false) 
        ? 
        <Spinner animation='border' size="sm" /> 
        : 
        <>
          {operation.state === 1 && <Badge variant="primary">Probíhá</Badge>}
          {operation.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
          {operation.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
          {operation.state === 4 && <Badge variant="success">Dokončeno</Badge>}
          <Badge variant='secondary' style={{marginLeft: '5px'}}>{moment(operation.isFinished ? operation.lastActivity : new Date()).diff(operation.startOn, 'minutes')}&nbsp;min.</Badge>
        </>}
          <br />          
          <strong>Spuštěno:</strong> {moment(operation.startOn).format("D.M.YYYY H:mm")}<br />
          <strong>Název:</strong> {operation.nameForMortal}<br />
          <strong>Uživatel:</strong> {operation.userName}<br />
          <strong>Zpracováno:</strong> {operation.finishedCount}/{operation.totalCount}<br />
          <strong>Expirace:</strong> {moment(operation.expireOn).format("D.M.YYYY H:mm")}<br />

        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   




  
  


  return (
    <Container fluid>
      <br />
      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam operací neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesOperationsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head" title='Datum a čas spuštění operace'>Spuštěno</th>
                <th className="jsw-table-head" title='Název operace'>Název</th>
                <th className="jsw-table-head" title='Uživatel, ktrý spustil operaci'>Uživatel</th>
                <th className="jsw-table-head" title='Počet položek, které bez ohledu na chyby prošly zpracováním / celkový počet položek, které byly předány ke zpracování'>Zpracováno</th>
                <th className="jsw-table-head" title='Datum a čas kdy bude operace považována za dokončenou, bez ohledu na její průběh'>Expirace</th>
                <th className="jsw-table-head" title='Informace o průběhu operace'>Stav</th>
                
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesOperations}
            </tbody>
          </Table>
        </div> 
        <br />  
      </div>  
      
      
    </Container>
  )  
  
}

export default withRouter(observer(OthersOperationsList))

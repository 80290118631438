import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';


import { useStore } from '../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'


interface FilesAddProps {
  id: string,
  type: string,
  masterId: string,
  showAs: string
}

const FilesAdd: React.FC<FilesAddProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci


  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  // Připojení store
  const rootStore = useStore()
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    //setShowE(false)
    setFileName('')
    setNewFile(null)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
   
  }

  // Funkce pro změnu kontaktu
  const handleInsert = (filesData:string, noteData:string) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      const data = new FormData() 
      
      data.set('linkId', props.id)
      data.set('note', noteData) 
      if (newFile !== null) data.append('file', newFile!)

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
       
      // Zavolání API
      await axios.post(getUrl('URL_FILES'), data, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          // Změna dat ve Store
          if (props.type === 'support') rootStore.fetchFilesForSupport(props.masterId)
          if (props.type === 'meeting') rootStore.fetchFilesForMeeting(props.masterId)
          if (props.type === 'task') rootStore.fetchFilesForTask(props.masterId)
          if (props.type === 'customer') rootStore.fetchFilesForCustomer(props.masterId)  

          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          setFileName('')
          setNewFile(null)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              setShowForm('')
            }

            setError(null)
            //sessionStorage.removeItem('autoCloseModal')
            setShowInfo(false)
            setShowForm('')
          }, 1800);

        }).catch(error => {
          setLoading(false)
          /*
          console.log('RQ')
          console.log(error.request)
          console.log('ME')
          console.log(error.message)
          console.log('RE')
          console.log(error.response)
          */
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  const [newFile, setNewFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(0);

  const setFile = async (evt:any) => {
    await setNewFile(evt.target.files[0]);
    await setFileName(evt.target.files[0].name);
    await setFileSize(evt.target.files[0].size);
    
    //await console.log(fileName);
    //await console.log(fileSize);
    //await console.log(fileType);
  }

  const UNSUPPORTED_FORMATS = [".dzs", ".dzz", ".dgz", ".bak", ".jsf", ".tsf"];
  const FILE_SIZE = 20 * 1024 * 1024;

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    file: Yup.mixed() 
    .required("Soubor musí být vybrán!")
    .test('fileType', "Nepovolená přípona souboru (dzs, dzz, dgz, bak, jsf, tsf)", value => !UNSUPPORTED_FORMATS.includes(fileName.substr(fileName.length - 4, 4).toLowerCase()) )
    .test('fileSize', "Překročena maximální velikost souboru (20 MB)", value => fileSize <= FILE_SIZE) 

  })
  



  return (
    <>
        {props.showAs === 'button' && 
        <>
          <span style={{marginLeft: '10px'}}></span>
          <Button variant="primary" size="sm" onClick={handleShow}>
            <i className="fas fa-paperclip"></i>
          </Button>  
        </>}
        {props.showAs === 'buttonWithText' && 
        <>
          <Button variant="success" size="sm" onClick={handleShow}>
          <i className="fas fa-plus"></i> Přidat soubor
          </Button>  
        </>}
        {props.showAs === 'drop' && <Dropdown.Item onClick={handleShow} >Přidat soubor</Dropdown.Item>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        backdrop="static" 
        size="lg"
        //aria-labelledby="example-custom-modal-styling-title"
        aria-labelledby="contained-modal-title-vcenter"
        //dialogClassName="modal-90w"
        style={{backgroundColor: 'rgba(128, 128, 128, 0.5)'}}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Přidání souboru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5><strong>Přidat soubor</strong></h5>
          <Alert variant="success" show={showInfo}>
            <strong>Soubor byl úspěšně nahrán!​</strong>
          </Alert> 
          <div className={showForm} >
            {showForm === '' &&
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                file: '', 
                note: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleInsert(values.file!, values.note!)
                  setSubmitting(false);
                }, 400);
              }}
              
              validateOnChange
            >
              {({ 
                handleSubmit,
                handleChange,
                validateForm,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>  
                  <FormGroup controlId="file">
                    <Form.Row>
                      <FormLabel column lg={3}>Soubor (max. 20 MB)</FormLabel>
                      <Col>
                      
                      <label style={{border: 'none',display: 'inline-block', cursor: 'pointer', marginRight: '12px'}}>
                      <input
                        type="file"
                        id="file" 
                        name="file"
                        className="form-control"
                        value={values.file!} 
                        onChange={async (e) => {await handleChange(e); await setFile(e); await validateForm() }}
                        style={{display: 'none'}}
                      />
                          <span className='btn btn-primary btn-sm'>Výběr souboru...</span> 
                      </label>
                      {fileName}


                      
                        <div className="invalid-feedback" style={{display: 'block'}}>{errors.file}</div>
                        <Form.Control.Feedback type="invalid">
                          {errors.file}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Popis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                              
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Přidávám soubor...' : 'Přidat soubor'}</Button>
                  
                </Form>
              )}
            </Formik>  
            }
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(FilesAdd))

import { destroy, types } from 'mobx-state-tree'
//import { useUpgradeStore } from '../store'

//const rootUpgradeStore = useUpgradeStore()

export const Files = types
  .model({
    id: types.optional(types.string, ''),
    fileName: types.optional(types.string, ''),
    contentType: types.optional(types.string, ''),
    note: types.maybeNull(types.string),
    size: types.maybeNull(types.number),
    userId: types.maybeNull(types.string),
    linkId: types.maybeNull(types.string),
    createDate: types.optional(types.string, '')
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    deleteFile() {
      destroy(self)
      
    },
    setInformations(newNote: string) {

      self.note = newNote

    },

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import NumberFormat from 'react-number-format'

interface CustomersOtherActionsReminderProps {
  mode: string,
  id: string,
  filterId:number,
  reminderType: string,
  showAs: string,
  info: string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined|null,
  searchValue?:string|undefined|null,
  searchType?:string|undefined|null, 
  searchScenario?:string|undefined|null,
  count?: number|undefined
}

const CustomersOtherActionsReminder: React.FC<CustomersOtherActionsReminderProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  const rootStore = useStore()

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {

    if (props.filtered === true) {
      if (props.reminderType === 'offer') await rootStore.fetchDatafiltersOffersForFilteredActions(Number(props.filterId),'','','')
      else await rootStore.fetchDatafiltersCustomersForFilteredActions(Number(props.filterId))
    }

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Funkce pro odeslání e-mailu
  const handleSendReminder = (reminderDaysData: number) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      const idsToSend = props.reminderType === 'offer' ?  [...new Set(rootStore.datafiltersOffersForFilteredActions.map(dfo => dfo.customer.id ))] : rootStore.datafiltersCustomersForFilteredActions.map(dfc => dfc.id )
      const idsToSendSelected = props.reminderType === 'offer' ?  [...new Set(rootStore.datafiltersOffers.filter(dfo => dfo.checked === true).map(dfo => dfo.customer.id))] : rootStore.datafiltersCustomers.filter(dfc => dfc.checked === true).map(dfc => dfc.id )


      // Zavolání API
      if (props.mode === "all") {
        await axios.post(getUrl('URL_BULK_REMINDERS'), { mode: props.mode, selectedIds: [], reminderType: props.reminderType ,reminderDays: reminderDaysData*(-1) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          //console.log(response)

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API
      
      await axios.post(getUrl('URL_BULK_REMINDERS'), { mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', selectedIds: props.mode === 'all' ? props.filtered === true ? idsToSend : [] : props.multiselect === true ? idsToSendSelected : [props.id], reminderType: props.reminderType ,reminderDays: reminderDaysData*(-1) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          //console.log(response.data)

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    reminderDays: Yup.number()
    .required('Musíte zadat počet dní!')
    .min(0,'Můžete zadat minimálně 0 dní!'),
  })   
  return (
    <>
      {rights &&<>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
          Připomenout 
          {props.mode === 'all' && <>
            {props.reminderType === 'invite' &&' pozvánky'}
            {props.reminderType === 'incompleteRegistration' &&' aktivace'}
            {props.reminderType === 'offer' &&' objednávky'}          
          
          </>}
          {(props.mode === 'selected' && props.multiselect === true) && <>
            {props.reminderType === 'invite' &&' pozvánky'}
            {props.reminderType === 'incompleteRegistration' &&' aktivace'}
            {props.reminderType === 'offer' &&' objednávky'}          
          
          </>}
          {(props.mode === 'selected' && props.multiselect !== true) && <>
            {props.reminderType === 'invite' &&' pozvánku'}
            {props.reminderType === 'incompleteRegistration' &&' aktivaci'}
            {props.reminderType === 'offer' &&' objednávku'}          
          
          </>}
        </Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>
        Připomenout 
          {props.mode === 'all' && <>
            {props.reminderType === 'invite' &&' pozvánky'}
            {props.reminderType === 'incompleteRegistration' &&' aktivace'}
            {props.reminderType === 'offer' &&' objednávky'}          
          
          </>}
          {(props.mode === 'selected' && props.multiselect === true) && <>
            {props.reminderType === 'invite' &&' pozvánky'}
            {props.reminderType === 'incompleteRegistration' &&' aktivace'}
            {props.reminderType === 'offer' &&' objednávky'}          
          
          </>}
          {(props.mode === 'selected' && props.multiselect !== true) && <>
            {props.reminderType === 'invite' &&' pozvánku'}
            {props.reminderType === 'incompleteRegistration' &&' aktivaci'}
            {props.reminderType === 'offer' &&' objednávku'}          
          
          </>}
        </Button>}


   
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
          {props.mode === 'all' && <>
            {props.reminderType === 'invite' &&'Hromadné připomenutí pozvánek '}
            {props.reminderType === 'incompleteRegistration' &&'Hromadné připomenutí aktivací '}
            {props.reminderType === 'offer' &&'Hromadné připomenutí objednávek '}          
            (<NumberFormat displayType={'text'} thousandSeparator={' '} value={props.reminderType === 'offer'? localStorage.getItem('lastDatafilterOfferTotalCount')! : localStorage.getItem('lastDatafilterTotalCount')!} />)
          </>}
          {(props.mode === 'selected' && props.multiselect === true) && <>
            {props.reminderType === 'invite' &&'Připomenutí pozvánek '}
            {props.reminderType === 'incompleteRegistration' &&'Připomenutí aktivací '}
            {props.reminderType === 'offer' &&'Připomenutí objednávek '}          
            ({props.count})
          </>}
          {(props.mode === 'selected' && props.multiselect !== true) && <>
            {props.reminderType === 'invite' &&'Připomenutí pozvánky '}
            {props.reminderType === 'incompleteRegistration' &&'Připomenutí aktivace '}
            {props.reminderType === 'offer' &&'Připomenutí objednávky '}          
          
          </>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>
              {props.reminderType === 'invite' &&'Pozvánky '}
              {props.reminderType === 'incompleteRegistration' &&'Aktivace '} 
              {props.reminderType === 'offer' &&'Objednávky '}
              byly úspěšně připomenuty!​
            </strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                reminderDays: 14
              }}
              initialTouched={{ 
                reminderDays: true,
              }}
              validateOnMount
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSendReminder(values.reminderDays!)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Nastavení připomenutí</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="formEmail">
                    <Form.Row>
                      <FormLabel column lg={3}>Počet dní tolerance</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="reminderDays"
                          
                          value={values.reminderDays}
                          onChange={handleChange}
                          
                          isValid={touched.reminderDays && !errors.reminderDays} 
                          isInvalid={!!errors.reminderDays}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.reminderDays}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám připomenutí...' : 'Odeslat připomenutí'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsReminder))

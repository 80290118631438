import { getToken, errorEvaluation, getUrl } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { UpgradeOffers } from './upgradeOffers.store'
import { UpgradeOffersDetail } from './upgradeOffersDetail.store'
import { UpgradeOffersScenarios } from './upgradeOffersScenarios.store'
import { UpgradeOffersList } from './upgradeOffersList.store'
 
import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootUpgradeStore = types
  .model({
    upgradeOffers: types.array(UpgradeOffers),
    upgradeOffersDetail: types.array(UpgradeOffersDetail),
    upgradeOffersScenarios: types.array(UpgradeOffersScenarios),
    upgradeOffersList: types.array(UpgradeOffersList),
    
  })
  .views(self => ({
    getUpgradeOffersScenarioById(id: string) {
      return self.upgradeOffersScenarios.find(scenario => scenario.id === id)
    },
    getUpgradeOfferById(id: string) {
      return self.upgradeOffers.find(upgradeOffer => upgradeOffer.id === id)
    },
  }))
  .actions(self => ({
    fetchUpgradeOfferById: flow(function* fetchProjects(id: string) {
      try {

      //  yield sleep(300)


       let url = getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+localStorage.getItem('openedCustomerId')!+'/offers/'+id

       if ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) url = getUrl('URL_OFFERS')+id

      
       const accessToken = yield getToken()
       
        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        //applySnapshot(self.upgradeOffersDetail,[cl]) 
        self.upgradeOffers.push(cl)
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

  }))  
  .actions(self => ({

    fetchUpgradeOffers: flow(function* fetchProjects(id: string) {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()
       
       

        const cl = yield axios.get(getUrl('URL_OFFERS')+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffers,[cl]) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUpgradeOffersDetail: flow(function* fetchProjects(id: string) {
      try {

      //  yield sleep(300)


       let url = getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+localStorage.getItem('openedCustomerId')!+'/offers/'+id

       if ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) url = getUrl('URL_OFFERS')+id

      
       const accessToken = yield getToken()
       
        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffersDetail,[cl]) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    
    

    fetchUpgradeOffersToCustomer: flow(function* fetchProjects(customerId:string) {
      try {

      //  yield sleep(300)
      self.upgradeOffers.clear()

       const accessToken = yield getToken()

       let url = getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+customerId+'/offers/active-upgrade-scenario'

       if ((localStorage.getItem('SmallHedgehog')! === '1')) url = getUrl('URL_OFFERS')+'customer/'+customerId+'/active-upgrade-scenario'
       if ((localStorage.getItem('BigHedgehog')! === '1')) url = getUrl('URL_OFFERS')+'customer/'+customerId

        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)


        if (localStorage.getItem('BigHedgehog')! === '1') {
          cl.map((uo: {id:string}) => {
            self.fetchUpgradeOfferById(uo.id)
            return('')
          })
        }
        else {
        applySnapshot(self.upgradeOffers,cl); 
        }
        




        yield (async() => {
          
        
        await self.upgradeOffers.filter(uo => uo.state === 'D' && uo.distributionMethod === 'D').map(uo => {
          //uo.setFinalState()
          axios.get(getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+customerId+'/offers/'+uo.id+'/invoices',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            }}).then(response => {
            //console.log(response.data)
            //return response.data
            
            (async() => {
            await uo.setInvoices(response.data.items)
            await uo.setTempDate()
            await uo.setFinalState()

            await uo.invoices?.map(i => {
              axios.get(getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+customerId+'/delivery/czech-post/packets?fiter=LinkId~eq~'+i.id,{
                headers: {
                  'Authorization': 'Bearer ' + accessToken
                }}).then(response => {
                //console.log(response.data)
                //return response.data
                
                
                (async() => {
                await i.setPackets(response.data.items)
                await uo.setTempDate()
                await uo.setFinalState()

                await i.packets?.map(p => {
                  axios.get(getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+customerId+'/delivery/czech-post/submissions/'+p.submissionId,{
                    headers: {
                      'Authorization': 'Bearer ' + accessToken
                    }}).then(response => {
                    //console.log(response.data)
                    //return response.data
                    (async() => {
                    console.log(response.data)
                    await p.setSubmission(response.data)
                    await uo.setTempDate()
                    await uo.setFinalState()

                    })()
                    
                  }).catch(error => {
                    errorEvaluation(error)
                  });
    
                  
    
                  return('')
                })
              })();

              }).catch(error => {
                errorEvaluation(error)
              });

              

              return('')
            })

            })();

          }).catch(error => {
            errorEvaluation(error)
          });
          /*
          uo.invoices?.map(i => {
            axios.get(getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+customerId+'/delivery/czech-post/packets?fiter=LinkId~eq~'+i.id,{
              headers: {
                'Authorization': 'Bearer ' + accessToken
              }}).then(response => {
              //console.log(response.data)
              //return response.data
              i.setPackets(response.data)
            }).catch(error => {
              errorEvaluation(error)
            });

            return('')
          })
          */
        

          return ('')

        })
      })();

          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUpgradeOffersFromData: flow(function* fetchProjects(data: any) {
      try {

      yield sleep(0)
      
       
        //console.log(cl)
        applySnapshot(self.upgradeOffers,data) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUpgradeOffersFromSelf: flow(function* fetchProjects() {
      try {

      yield sleep(0) 
       const data = self.upgradeOffers
       self.upgradeOffers.clear();

       (async () => {
        
        
        await applySnapshot(self.upgradeOffers,data) 
       }) ()
        //console.log(cl)
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUpgradeOffersListToCustomer: flow(function* fetchProjects(customerId:string) {
      try {

      //  yield sleep(300)

       self.upgradeOffersList.clear()
      
       const accessToken = yield getToken()

       let url = getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+customerId+'/offers'

       if ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) url = getUrl('URL_OFFERS')+'customer/'+customerId+''

       const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffersList,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteUpgradeOffers: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.upgradeOffers.clear()
                
      } catch (error) {
        console.error('Failed to clear upgradeOffers', error)
      }
    }),    

    fetchUpgradeOffersScenarios: flow(function* fetchProjects() {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()
       const onlyIsActive = true

        const cl = yield axios.get(getUrl('URL_OFFERS_SCENARIOS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            onlyIsActive
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffersScenarios,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst scénáře nabídky!', error)

    } 
    }),
    fetchUpgradeOffersScenariosAll: flow(function* fetchProjects() {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()
       const onlyIsActive = false 
       
        const cl = yield axios.get(getUrl('URL_OFFERS_SCENARIOS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            onlyIsActive
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffersScenarios,cl) 

        if ((localStorage.getItem('upgradeScenarioDuelSelected') === undefined || localStorage.getItem('upgradeScenarioDuelSelected') === null || localStorage.getItem('upgradeScenarioStereoSelected') === undefined || localStorage.getItem('upgradeScenarioStereoSelected') === null)) {
          localStorage.setItem('upgradeScenarioDuelSelected', self.upgradeOffersScenarios.find(us => us.program.code === 'D' && us.isActive === true)!.id.toString())
          localStorage.setItem('upgradeScenarioStereoSelected', self.upgradeOffersScenarios.find(us => us.program.code === 'S' && us.isActive === true)!.id.toString())
        }  



          
    } catch (error) {
      console.error('Nepodařilo se načíst scénáře nabídky!', error)

    } 
    }),

    deleteUpgradeOffersScenarios: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.upgradeOffersScenarios.clear()
                
      } catch (error) {
        console.error('Failed to clear upgradeOffersScenarios', error)
      }
    }),     
    
    
    getLastNumberForInvoice: flow(function* fetchProjects(invoiceTypeScopeData: string, invoiceTypeData: string, ) {
      try {

       yield sleep(100)
      
       let url = getUrl('URL_API')+'invoices/first-useful-number?invoiceType='+invoiceTypeData+'&invoiceTypeScope='+invoiceTypeScopeData
      
       const accessToken = yield getToken()
       
       yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          sessionStorage.setItem('first-useful-number', response.data)
            //console.log(response.data)
          //return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst číslo!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    

  }))

export type RootUpgradeStoreType = Instance<typeof RootUpgradeStore>
export type UpgradeOffersStoreType = Instance<typeof UpgradeOffers>
export type UpgradeOffersScenariosStoreType = Instance<typeof UpgradeOffersScenarios>
import { types } from 'mobx-state-tree'


export const Operations = types
  .model({
    id: types.optional(types.string, ''),
    errors: types.maybeNull(types.string),
    isError: types.optional(types.boolean, false),
    expireOn: types.maybeNull(types.string),
    finishedCount: types.optional(types.number, 0),
    lastActivity: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    startOn: types.maybeNull(types.string),
    totalCount: types.optional(types.number, 0),
    userId: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
    isFinished: types.optional(types.boolean, false),
    stateLoaded: types.maybeNull(types.boolean),
    stateLoadedErrors: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get state() {
      let state:number = 0
      //let expDate = new Date()
      if (self.isFinished !== true) {
        if (self.isError !== true) state = 1
        else state = 2
      }
      else {
        if (self.isError !== true) state = 4
        else state = 3
      }

      /*
      if ((self.totalCount !== self.finishedCount) && (self.errors === null) && ( new Date(self.expireOn!.replace(' ', 'T')) > new Date())) state = 1 
      if (((self.totalCount !== self.finishedCount) && (self.errors !== null)) && ( new Date(self.expireOn!.replace(' ', 'T')) > new Date())) state = 2
      if (((self.totalCount === self.finishedCount) && (self.errors !== null)) && ( new Date(self.expireOn!.replace(' ', 'T')) > new Date())) state = 3
      if (((self.totalCount !== self.finishedCount) || (self.errors !== null)) && ( new Date(self.expireOn!.replace(' ', 'T')) <= new Date())) state = 3
      if ((self.totalCount === self.finishedCount) && (self.errors === null)) state = 4
      */
      return state
      
    }, 
    get nameForMortal() {
      let name:string = ''
      
      if (self.name === '') name = '' 
      
      if (self.name === 'CreateTotalZeroFinalDocumentsForDuel') name = 'Vytvoření konečných dokumentů pro DUEL - zadarmisté'
      if (self.name === 'CreateTotalZeroFinalDocumentsForStereo') name = 'Vytvoření konečných dokumentů pro STEREO - zadarmisté'
      if (self.name === 'CreateTotalZeroFinalDocumentsForStereoLan') name = 'Vytvoření konečných dokumentů pro STEREO LAN - zadarmisté'
      if (self.name === 'PublishTotalZeroFinalDocumentsForDuel') name = 'Publikování konečných dokumentů pro DUEL - zadarmisté'
      if (self.name === 'PublishTotalZeroFinalDocumentsForStereo') name = 'Publikování konečných dokumentů pro STEREO - zadarmisté'
      if (self.name === 'PublishTotalZeroFinalDocumentsForStereoLan') name = 'Publikování konečných dokumentů pro STEREO LAN - zadarmisté'
      if (self.name === 'CreateElectronicProformaInvoiceForDuel') name = 'Vytvoření zálohových faktur pro DUEL - elektronická distribuce'
      if (self.name === 'CreateElectronicProformaInvoiceForStereo') name = 'Vytvoření zálohových faktur pro STEREO - elektronická distribuce'
      if (self.name === 'CreateElectronicProformaInvoiceForStereoLan') name = 'Vytvoření zálohových faktur pro STEREO LAN - elektronická distribuce'
      if (self.name === 'PublishElectronicProformaInvoicesForDuel') name = 'Publikování zálohových faktur pro DUEL - elektronická distribuce'
      if (self.name === 'PublishElectronicProformaInvoicesForStreo') name = 'Publikování zálohových faktur pro STEREO - elektronická distribuce'
      if (self.name === 'PublishElectronicProformaInvoicesForStereoLan') name = 'Publikování zálohových faktur pro STEREO LAN - elektronická distribuce'
      if (self.name === 'CreateElectronicFinalDocumentsForDuel') name = 'Vytvoření konečných dokumentů pro DUEL - elektronická distribuce'
      if (self.name === 'CreateElectronicFinalDocumentsForStereo') name = 'Vytvoření konečných dokumentů pro STEREO - elektronická distribuce'
      if (self.name === 'CreateElectronicFinalDocumentsForStereoLan') name = 'Vytvoření konečných dokumentů pro STEREO LAN - elektronická distribuce'
      if (self.name === 'PublishElectronicFinalDocumentsForDuel') name = 'Publikování konečných dokumentů pro DUEL - elektronická distribuce'
      if (self.name === 'PublishElectronicFinalDocumentsForStereo') name = 'Publikování konečných dokumentů pro STEREO - elektronická distribuce'
      if (self.name === 'PublishElectronicFinalDocumentsForStereoLan') name = 'Publikování konečných dokumentů pro STEREO LAN - elektronická distribuce'
      if (self.name === 'FinalizeElectronicFinalDocumentsForDuel') name = 'Publikování konečných dokumentů pro DUEL - elektronická distribuce'
      if (self.name === 'FinalizeElectronicFinalDocumentsForStereo') name = 'Publikování konečných dokumentů pro STEREO - elektronická distribuce'
      if (self.name === 'FinalizeElectronicFinalDocumentsForStereoLan') name = 'Publikování konečných dokumentů pro STEREO LAN - elektronická distribuce'

      if (self.name === 'CreateCashFinalDocumentsForDuel') name = 'Vytvoření konečných dokumentů pro DUEL - fyzická distribuce'
      if (self.name === 'CreateCashFinalDocumentsForStereo') name = 'Vytvoření konečných dokumentů pro STEREO - fyzická distribuce'
      if (self.name === 'CreateCashFinalDocumentsForStereoLan') name = 'Vytvoření konečných dokumentů pro STEREO LAN - fyzická distribuce'

      if (self.name === 'DeleteElectronicProforma') name = 'Mazání zálohových faktur - elektronická distribuce'
      if (self.name === 'DeleteElectronicFinal') name = 'Mazání finálních dokumentů - elektronická distribuce'
      
      if (self.name === 'CreateUpgradeTaxInvoiceForDuel') name = 'Vytvoření daňových dokladů pro DUEL - elektronická distribuce'
      if (self.name === 'CreateUpgradeTaxInvoiceForStereo') name = 'Vytvoření daňových dokladů pro STEREO - elektronická distribuce'
      if (self.name === 'CreateUpgradeTaxInvoiceForStereoLan') name = 'Vytvoření daňových dokladů pro STEREO LAN - elektronická distribuce'
      if (self.name === 'CreateUpgradePaymentInvoiceForDuel') name = ''
      if (self.name === 'CreateUpgradePaymentInvoiceForStereo') name = ''
      if (self.name === 'CreateUpgradePaymentInvoiceForStereoLan') name = ''
      if (self.name === 'CreateUpgradeLicenseForDuel') name = ''
      if (self.name === 'CreateUpgradeLicenseForStereo') name = ''

      if (self.name === 'UnpublishCashFinal') name = 'Zrušení publikování finálních dokumentů - fyzická distribuce'
      if (self.name === 'DeleteCashFinal') name = 'Mazání finálních dokumentů - fyzická distribuce'
      if (self.name === 'DeleteTotalZeroFinal') name = 'Mazání finálních dokumentů - zadarmisté'
      if (self.name === 'CrateInvoicesForTotalZeroDuel') name = 'Vytvoření konečných dokumentů pro DUEL - zadarmisté'

      if (self.name === 'UnpublishElectronicProforma') name = 'Zrušení publikování zálohových faktur - elektronická distribuce'
      if (self.name === 'UnpublishElectronicFinal') name = 'Zrušení publikování finálních dokumentů - elektronická distribuce'
      if (self.name === 'UnpublishTotalZeroFinal') name = 'Zrušení publikování finálních dokumentů - ditribice zadarmistů'

      if (self.name === 'FinalizeTotalZeroFinalDocumentsForDuel') name = 'Publikování konečných dokumentů pro DUEL - zadarmisté'
      if (self.name === 'FinalizeTotalZeroFinalDocumentsForStereo') name = 'Publikování konečných dokumentů pro STEREO - zadarmisté'
      if (self.name === 'FinalizeTotalZeroFinalDocumentsForStereoLan') name = 'Publikování konečných dokumentů pro STEREO LAN - zadarmisté'

      if (self.name === 'SendEmails') name = 'Odeslání e-mailů'
      if (self.name === 'OffersReminder') name = 'Připomínky - nabídky'
      if (self.name === 'InvitesReminder') name = 'Připomínky - pozvánky'
      if (self.name === 'IncompleteRegistrationReminder') name = 'Připomínky - dokončení registrace'
      if (self.name === 'PublishOffers') name = 'Publikování nabídek'
      if (self.name === 'CreateOffers') name = 'Vytvoření nabídek'
      if (self.name === 'CreateInvites') name = 'Pozvánky'

      if (self.name === 'CreateCashDistributionStartedDuel') name = 'Generování zásilek pro DUEL - fyzická distribuce'
      if (self.name === 'CreateCashDistributionStartedStereo') name = 'Generování zásilek pro STEREO - fyzická distribuce'
      if (self.name === 'CreateCashDistributionStartedStereoLan') name = 'Generování zásilek pro STEREO LAN - fyzická distribuce'

      if (self.name?.includes('SendEmails - ')) name = 'Odeslání e-mailů - ' + self.name?.replace('SendEmails - ', '')
      

      if (name === '') name = self.name!
      return name
      //return self.variables?.map(vr => ({name: vr, value: ''}) )
    }
    
  }))
  .actions(self => ({
    setNewData(newIsError: boolean, newFinishedCount: number , newTotalCount: number, newIsFinished: boolean, newLastActivity: string) {
      self.isError = newIsError
      self.finishedCount = newFinishedCount
      self.totalCount = newTotalCount
      self.isFinished = newIsFinished
      self.stateLoaded = true
      self.lastActivity = newLastActivity

    },
    setErrors(newErrors: string) {
      self.errors = newErrors
      self.stateLoadedErrors = true

    },
    setNewLoadingState(newLoadingState: boolean) {
      self.stateLoaded = newLoadingState

    },
    setNewLoadingErrorState(newLoadingErrorsState: boolean) {
      self.stateLoadedErrors = newLoadingErrorsState

    },

  }))

import React, {useState}  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Button, ButtonGroup, Modal, Form, Col, Alert } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { useMount } from '../../../../helpers/lifecycle-hooks'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { useStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'
import { getToken, getUrl } from '../../../../Utils/Common'

import { Formik } from 'formik'
import * as Yup from 'yup'

//import NumberFormat from 'react-number-format'

interface CustomersLicensesEditProps {
  id: string,
  label: string,
  showIcon: boolean,
  block: boolean
}

const CustomersLicensesEdit: React.FC<CustomersLicensesEditProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')  ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
    
  // Nastavení proměnné pro Loader
  //const [loaded, setLoaded] = useState(true);
  
  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line
  const [loadingCalculate, setLoadingCalculate] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  //const [showE, setShowE] = useState(false)

  const [TotalInfo, setTotalInfo] = useState('')
  const [YInfo, setYInfo] = useState('')
  const [KInfo, setKInfo] = useState('')
  const [UInfo, setUInfo] = useState('')
  const [FInfo, setFInfo] = useState('')
  const [MInfo, setMInfo] = useState('')
  const [SInfo, setSInfo] = useState('')
  const [PInfo, setPInfo] = useState('')
  const [EInfo, setEInfo] = useState('')
  const [JInfo, setJInfo] = useState('')
  const [AInfo, setAInfo] = useState('')
  const [RInfo, setRInfo] = useState('')

  
  // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
  if (!localStorage.getItem('openedCustomerId')) {
    props.history.push('/customers-open')
  }

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  //const rootUpgradeStore = useUpgradeStore()

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    //setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      //Načtení souvisejících číselníků podle práv

      //Při každém otevření stránky načítáme objednávky
      //await rootUpgradeStore.fetchUpgradeOffers(props.id)
      
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }     
    //setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  // Načtení informací o aktuálním zákazníkovy do proměnné
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

  const license = rootStore.getCustomerOpenedLicenseById(props.id)
  
  //const upgradeOffer = rootUpgradeStore.upgradeOffers.find(uo => uo.id === props.id)
  // eslint-disable-next-line

  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    //setPrices()
    setShow(true)
    
  }

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    //setShowPrice(true)
    //setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    setTotalInfo('')
    setYInfo('')
    setKInfo('')
    setUInfo('')
    setFInfo('')
    setMInfo('')
    setSInfo('')
    setPInfo('')
    setEInfo('')
    setJInfo('')
    setAInfo('')
    setRInfo('')
    
  }

  const getModuleCount = (moduleCode:string,programCode:string,values:any) => {
    let count:number = 99
    //console.log(values)
    if (programCode === "S") {
      if (moduleCode === "K") count = values.KCheck ? values.TCount : 0
      if (moduleCode === "U") count = values.UCheck ? values.TCount : 0
      if (moduleCode === "F") count = values.FCheck ? values.TCount : 0
      if (moduleCode === "M") count = values.MCheck ? values.TCount : 0
      if (moduleCode === "S") count = values.SCheck ? values.TCount : 0
      if (moduleCode === "P") count = values.PCheck ? values.TCount : 0
      if (moduleCode === "E") count = values.ECheck ? values.TCount : 0
      if (moduleCode === "J") count = values.JCheck ? values.TCount : 0
      if (moduleCode === "A") count = values.ACheck ? values.TCount : 0
      if (moduleCode === "Y") count = values.YCheck ? values.TCount : 0
      if (moduleCode === "R") count = values.RCheck ? values.TCount : 0
    }
    else {
      if (moduleCode === "K") count = values.KCount
      if (moduleCode === "U") count = values.UCount
      if (moduleCode === "F") count = values.FCount
      if (moduleCode === "M") count = values.MCount
      if (moduleCode === "S") count = values.SCount
      if (moduleCode === "P") count = values.PCount
      if (moduleCode === "E") count = values.ECount
      if (moduleCode === "J") count = values.JCount
      if (moduleCode === "A") count = values.ACount
      if (moduleCode === "Y") count = values.YCount
      if (moduleCode === "R") count = values.RCount
    }

    return count
  }

  // Seznam typů licencí
  const licenseTypes = rootDialsStore.dialsLicenseTypes
  .slice()
  .filter(data => data.programId === license?.license.program.id )
  .sort(function(a:any, b:any) {
      var nameA = a.code; 
      var nameB = b.code; 
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
  .map(dialsLicenseTypes => (
    <option key={dialsLicenseTypes.id} value={dialsLicenseTypes.id}>{dialsLicenseTypes.code} - {dialsLicenseTypes.name}</option>
  ))   

  // Seznam verzí programů
  const programVersions = rootDialsStore.dialsProgramVersions
  .slice()
  .filter(data => data.programId === license?.license.program.id )
  .sort(function(a:any, b:any) {
      var nameA = a.version; 
      var nameB = b.version; 
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    })
  .map(dialsProgramVersions => (
    <option key={dialsProgramVersions.id} value={dialsProgramVersions.id}>{dialsProgramVersions.version}</option>
  ))  

  // Seznam typů licencí A,B
  const licenseTypesAB = rootDialsStore.dialsLicenseTypes
  .filter(data => (data.code === 'A' || data.code === 'B') && data.programId === license?.license.program.id) 

  // Seznam typů licencí C,D
  const licenseTypesCD = rootDialsStore.dialsLicenseTypes
  .filter(data => (data.code === 'C' || data.code === 'D') && data.programId === license?.license.program.id) 

  // ID licence A
  const licenseTypesIdA = rootDialsStore.dialsLicenseTypes
  .find(data => data.code === 'A' && data.programId === license?.license.program.id) 

  // ID licence B
  const licenseTypesIdB = rootDialsStore.dialsLicenseTypes
  .find(data => data.code === 'B' && data.programId === license?.license.program.id)   

  // ID licence C
  const licenseTypesIdC = rootDialsStore.dialsLicenseTypes
  .find(data => data.code === 'C' && data.programId === license?.license.program.id) 

  // ID licence D
  const licenseTypesIdD = rootDialsStore.dialsLicenseTypes
  .find(data => data.code === 'D' && data.programId === license?.license.program.id)   



  //const targetLicense = upgradeOffer?.targetLicense

  const tLicenseType = license?.license.licenseType.id
  const tProgramVersion = license?.license.programVersion.id

  const visibleY = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'Y')).length > 0 ? true:false  
  const visibleK = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'K')).length > 0 ? true:false  
  const visibleU = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'U')).length > 0 ? true:false  
  const visibleF = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'F')).length > 0 ? true:false  
  const visibleM = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'M')).length > 0 ? true:false  
  const visibleS = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'S')).length > 0 ? true:false  
  const visibleP = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'P')).length > 0 ? true:false  
  const visibleE = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'E')).length > 0 ? true:false  
  const visibleJ = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'J')).length > 0 ? true:false  
  const visibleA = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'A')).length > 0 ? true:false  
  const visibleR = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id && data.code === 'R')).length > 0 ? true:false  
  
  const tTotalCount = license?.license.count
  const tYCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'Y')?.count : 0
  const tKCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'K')?.count : 0
  const tUCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'U')?.count : 0
  const tFCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'F')?.count : 0
  const tMCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'M')?.count : 0
  const tSCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'S')?.count : 0
  const tPCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'P')?.count : 0
  const tECount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'E')?.count : 0
  const tJCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'J')?.count : 0
  const tACount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'A')?.count : 0
  const tRCount = license?.license.program.code === 'D' ? license?.license.modules!.find(data => data.module.code === 'R')?.count : 0

  const tYCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'Y')?.count! > 0 ? true:false : false
  const tKCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'K')?.count! > 0 ? true:false : false
  const tUCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'U')?.count! > 0 ? true:false : false
  const tFCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'F')?.count! > 0 ? true:false : false
  const tMCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'M')?.count! > 0 ? true:false : false
  const tSCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'S')?.count! > 0 ? true:false : false
  const tPCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'P')?.count! > 0 ? true:false : false
  const tECheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'E')?.count! > 0 ? true:false : false
  const tJCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'J')?.count! > 0 ? true:false : false
  const tACheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'A')?.count! > 0 ? true:false : false
  const tRCheck = license?.license.program.code === 'S' ? license?.license.modules!.find(data => data.module.code === 'R')?.count! > 0 ? true:false : false



  

  // Funkce pokud hrábnu do editace, vypnu ceny
  const handleHidePrices = () => {
    setTotalInfo('')
    setYInfo('')
    setKInfo('')
    setUInfo('')
    setFInfo('')
    setMInfo('')
    setSInfo('')
    setPInfo('')
    setEInfo('')
    setJInfo('')
    setAInfo('')
    setRInfo('')
    setError(null)
  }

  // Funkce pro přenos licence
  const handleCalculate = (values:any,toClipboard:boolean) => {
    //console.log('calculating');
    //console.log(values);
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   

      setLoadingCalculate(true)
      //const upgradeScenario = upgradeOffer?.upgradeScenario
      //const priceList = rootDialsStore.dialsPriceListsModules.filter(data => data.module.programId === upgradeOffer?.upgradeScenario.program.id)

      //const defaultLicense = upgradeOffer?.defaultLicense
      //console.log(defaultLicense)

      //const actualLicense = upgradeOffer?.actualLicense

      const targetLicense = {
        id: license?.license.id,
        count: values.programCode === 'D' ? values.tYCount : values.tTotalCount,
        isActive: license?.license.isActive,
        status: license?.license.status,
        program: license?.license.program,
        programVersion: license?.license.programVersion,
        licenseType: rootDialsStore.dialsLicenseTypes.find(data => data.id === values.tLicenseType),
        modules: 
          rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id)
          .map(module => ({ 
            count: getModuleCount(module.code!, values.programCode, {TCount: values.tTotalCount, KCount: values.tKCount, UCount: values.tUCount, FCount: values.tFCount, MCount: values.tMCount, SCount: values.tSCount, PCount: values.tPCount, ECount: values.tECount, JCount: values.tJCount, ACount: values.tACount, RCount: values.tRCount, YCount: values.tYCount, KCheck: values.tKCheck, UCheck: values.tUCheck, FCheck: values.tFCheck, MCheck: values.tMCheck, SCheck: values.tSCheck, PCheck: values.tPCheck, ECheck: values.tECheck, JCheck: values.tJCheck, ACheck: values.tACheck, YCheck: values.tYCheck}),
            moduleId: module.id 
          }))
          /*
          license?.license.modules!
          .map(module => ({ 
            count: getModuleCount(module.module.code!, values.programCode, {TCount: values.tTotalCount, KCount: values.tKCount, UCount: values.tUCount, FCount: values.tFCount, MCount: values.tMCount, SCount: values.tSCount, PCount: values.tPCount, ECount: values.tECount, JCount: values.tJCount, ACount: values.tACount, RCount: values.tRCount, YCount: values.tYCount, KCheck: values.tKCheck, UCheck: values.tUCheck, FCheck: values.tFCheck, MCheck: values.tMCheck, SCheck: values.tSCheck, PCheck: values.tPCheck, ECheck: values.tECheck, JCheck: values.tJCheck, ACheck: values.tACheck, YCheck: values.tYCheck}),
            module: module.module 
          }))*/
        
      }

      let dataToSend={}

      dataToSend = {
        //priceList: priceList, 
        //upgradeScenario: upgradeScenario, 
        //defaultLicense: defaultLicense, 
        //activeLicense: actualLicense,
        targetLicense: targetLicense
        }
 
      if (toClipboard === true) {
        /*
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = JSON.stringify(dataToSend) ;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        */
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(JSON.stringify(dataToSend));
        hiddenElement.target = '_blank';
        hiddenElement.download = 'JSON_calculator.txt';
        hiddenElement.click();
      }

      if (toClipboard === false) {
        // Zavolání API   
        await axios.post(getUrl('URL_OFFERS_CALCULATE'), dataToSend, 
        { 
          headers: { 'Authorization': 'Bearer ' + aToken}
        }
          ).then(response => {
            
            
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
            //setShowE(true)
          });

        }
      setLoadingCalculate(false)
      })()    
  }

  // Funkce pro uložení
  const handleSave = (values:any,toClipboard:boolean) => {
    //console.log('calculating');
    //console.log(values);
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   

      setLoading(true)
      //const upgradeScenario = rootCalculatorsStore.calculatorsUpgradeScenarios[0]
      //const priceList = rootDialsStore.dialsPriceListsModules

      const targetLicense = {
        id: license?.license.id,
        count: values.programCode === 'D' ? values.tYCount : values.tTotalCount,
        customerId: localStorage.getItem('openedCustomerId')!,
        programVersionId: values.tProgramVersion,
        licenseTypeId: values.tLicenseType,
        modules: 
        rootDialsStore.dialsModules.filter(data => data.programId === license?.license.program.id)
          .map(module => ({ 
            count: getModuleCount(module.code!, values.programCode, {TCount: values.tTotalCount, KCount: values.tKCount, UCount: values.tUCount, FCount: values.tFCount, MCount: values.tMCount, SCount: values.tSCount, PCount: values.tPCount, ECount: values.tECount, JCount: values.tJCount, ACount: values.tACount, RCount: values.tRCount, YCount: values.tYCount, KCheck: values.tKCheck, UCheck: values.tUCheck, FCheck: values.tFCheck, MCheck: values.tMCheck, SCheck: values.tSCheck, PCheck: values.tPCheck, ECheck: values.tECheck, JCheck: values.tJCheck, ACheck: values.tACheck, YCheck: values.tYCheck}),
            moduleId: module.id 
          }))
          /*
          license?.license.modules!
          .map(module => ({ 
            count: getModuleCount(module.module.code!, values.programCode, {TCount: values.tTotalCount, KCount: values.tKCount, UCount: values.tUCount, FCount: values.tFCount, MCount: values.tMCount, SCount: values.tSCount, PCount: values.tPCount, ECount: values.tECount, JCount: values.tJCount, ACount: values.tACount, RCount: values.tRCount, YCount: values.tYCount, KCheck: values.tKCheck, UCheck: values.tUCheck, FCheck: values.tFCheck, MCheck: values.tMCheck, SCheck: values.tSCheck, PCheck: values.tPCheck, ECheck: values.tECheck, JCheck: values.tJCheck, ACheck: values.tACheck, YCheck: values.tYCheck}),
            moduleId: module.module.id 
          }))
          */
        
      }
      let dataToSend={}

      dataToSend = {
        
        targetLicense: targetLicense,
        }

      console.log(dataToSend)
      if (toClipboard === true) {
        /*
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = JSON.stringify(dataToSend) ;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        */
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(JSON.stringify(dataToSend));
        hiddenElement.target = '_blank';
        hiddenElement.download = 'JSON_calculator.txt';
        hiddenElement.click();
        setLoading(false)
      }

      if (toClipboard === false) {
        // Zavolání API   

        let url = getUrl('URL_LICENSES_CHANGE')+props.id

        //if (rightsBH) url = getUrl('URL_OFFERS')+props.id

        await axios.post(url, targetLicense, 
        { 
          headers: { 'Authorization': 'Bearer ' + aToken}
        }
          ).then(response => {
            (async () => {

            await rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
            // Nastavovačky, co se má v případě, že je to OK, zobrazit
            setLoading(false)
            setShowForm('nodisplay')
            setShowInfo(true)
            sessionStorage.setItem('autoCloseModal','1');

            // Po 2800 ms celé modální okno zavřeme
            setTimeout(() => {
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                setShow(false)
                //setShowPrice(true)
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                //setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);
          })()


          }).catch(error => {
            setLoading(false)
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setError(errorMessage)
          });

        }
      //setLoading(false)
      })()    
  }
  
  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    tLicenseType: Yup.string().required('Typ licence musí být vyplněn!'),
    
    tTotalCount: Yup.number().when('programCode', {
      is: 'S',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      
      .when('tLicenseType', {
        is: licenseTypesIdD?.id,
        then: Yup.number()
        .min(2, 'Minimální počet PC je 2!'),
      })
      .when('tLicenseType', {
        is: licenseTypesIdC?.id,
        then: Yup.number()
        .min(2, 'Minimální počet PC je 2!'),
      })
      .when('tLicenseType', {
        is: licenseTypesIdB?.id,
        then: Yup.number()
        .min(1, 'Minimální počet PC je 1!')
        .max(1, 'Maximální počet PC je 1!'),
      })
      .when('tLicenseType', {
        is: licenseTypesIdA?.id,
        then: Yup.number()
        .min(1, 'Minimální počet PC je 1!')
        .max(1, 'Maximální počet PC je 1!'),
      }),
    }),

    tYCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněnn!')
      .when('tLicenseType', {
        is: licenseTypesIdB?.id,
        then: Yup.number()
        .min(1, 'Minimální počet PC je 1!')
        .max(1, 'Maximální počet PC je 1!'),
      })
      .when('tLicenseType', {
        is: licenseTypesIdD?.id,
        then: Yup.number()
        .min(2, 'Minimální počet PC je 2!'),
      }),
    }),
    tKCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(Yup.ref('tYCount'), 'Počet PC modulu musí být stejný jako počet jader!')
      .max(Yup.ref('tYCount'), 'Počet PC modulu musí být stejný jako počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tUCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tFCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tMCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tSCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tPCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('tSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tECount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('tSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tJCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tACount: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .when('tUCount', {
        is: 0,
        then: Yup.number()
          .min(0, 'Musí být zadán alespoň 1 modul Účetnictví!')
          .max(0, 'Musí být zadán alespoň 1 modul Účetnictví!'),
        otherwise: Yup.number().when('programCode', {
          is: 'D',
          then: Yup.number()  
            .min(0, 'Minimální počet PC je 0!')
            .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
          otherwise: Yup.number()
            .min(0, 'Minimální počet PC je 0!'),
        }),
    }),
    tRCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počet PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    tACheck:Yup.boolean()
    .when('tUCheck', {
      is: false,
      then: Yup.boolean()
        .oneOf([false], 'Musí být zadán modul Účetnictví!'),
    })
  })   

  return (
    <span>
      {rightsBH &&<Button block={props.block} variant="primary" size="sm" onClick={handleShow}>{props.showIcon && <span><i className="fas fa-calculator"></i>&nbsp;</span>}{props.label}</Button>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava {license?.license.status === 'V' ? 'výchozí':'aktuální'} licence {license?.license.program.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Licence byla úspěšně změněna!​</strong>
            
          </Alert>  
          <div className={showForm} >
          <Formik
                    validationSchema={schemaUpdate}
                    initialValues={{ 
                      programCode: license?.license.program.code,
                      tProgramVersion: tProgramVersion !== undefined ? tProgramVersion:'', 
                      tLicenseType: tLicenseType !== undefined ? tLicenseType:'', 



                      tTotalCount: tTotalCount !== undefined ? tTotalCount:0,
                      tYCount: tYCount !== undefined ? tYCount:0, 
                      tKCount: tKCount !== undefined ? tKCount:0, 
                      tUCount: tUCount !== undefined ? tUCount:0, 
                      tFCount: tFCount !== undefined ? tFCount:0,
                      tMCount: tMCount !== undefined ? tMCount:0,
                      tSCount: tSCount !== undefined ? tSCount:0,
                      tPCount: tPCount !== undefined ? tPCount:0,
                      tECount: tECount !== undefined ? tECount:0,
                      tJCount: tJCount !== undefined ? tJCount:0,
                      tACount: tACount !== undefined ? tACount:0,
                      tRCount: tRCount !== undefined ? tRCount:0,
                      
                      tYCheck: tYCheck,
                      tKCheck: tKCheck,
                      tUCheck: tUCheck,
                      tFCheck: tFCheck,
                      tMCheck: tMCheck,
                      tSCheck: tSCheck,
                      tPCheck: tPCheck,
                      tECheck: tECheck,
                      tJCheck: tJCheck,
                      tACheck: tACheck,
                      tRCheck: tRCheck,

                      aTotalCount: license?.license.count!,
                      aYCount: license?.license.modules?.find(module => module.module.code === 'Y')?.count!, 
                      aKCount: license?.license.modules?.find(module => module.module.code === 'K')?.count!, 
                      aUCount: license?.license.modules?.find(module => module.module.code === 'U')?.count!, 
                      aFCount: license?.license.modules?.find(module => module.module.code === 'F')?.count!,
                      aMCount: license?.license.modules?.find(module => module.module.code === 'M')?.count!,
                      aSCount: license?.license.modules?.find(module => module.module.code === 'S')?.count!,
                      aPCount: license?.license.modules?.find(module => module.module.code === 'P')?.count!,
                      aECount: license?.license.modules?.find(module => module.module.code === 'E')?.count!,
                      aJCount: license?.license.modules?.find(module => module.module.code === 'J')?.count!,
                      aACount: license?.license.modules?.find(module => module.module.code === 'A')?.count!,
                      aRCount: license?.license.modules?.find(module => module.module.code === 'R')?.count === undefined||null ? 0 : license?.license.modules?.find(module => module.module.code === 'R')?.count!
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleSave(values,false)              
                        setSubmitting(false);
                      }, 1);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                      handleReset,
                      handleBlur,
                      setFieldValue,
                      values,
                      touched,
                      isValid,
                      errors,
                      dirty,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                                                 
                        <FormControl 
                          type="hidden"
                          name="programCode"
                          value={values.programCode!}
                          onChange={(e:any) => {handleChange(e)}}
                        />

                              <FormGroup as={Col} controlId="aLicenseType">
                                <Form.Row>
                                  <FormLabel column lg={3}>Aktuální verze</FormLabel>
                                  <FormLabel column ><span style={{marginLeft:'16px'}}>{license?.license.programVersion.version}</span></FormLabel>
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tProgramVersion">
                                <Form.Row>
                                  <FormLabel column lg={3}>Požadovaná verze</FormLabel>
                                  <Col>
                                    <Form.Control as="select" 
                                      name="tProgramVersion" 
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      value={values.tProgramVersion!}
                                      isInvalid={!!errors.tProgramVersion}
                                      
                                      >
                                        <option value="">--- Vyberte verzi ---</option>
                                        {
                                        programVersions
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tProgramVersion}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="aLicenseType">
                                <Form.Row>
                                  <FormLabel column lg={3}>Aktuální typ licence</FormLabel>
                                  <FormLabel column ><span style={{marginLeft:'16px'}}>{license?.license.licenseType.code} - {license?.license.licenseType.name}</span></FormLabel>
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tLicenseType">
                                <Form.Row>
                                  <FormLabel column lg={3}>Požadovaný typ licence</FormLabel>
                                  <Col>
                                    <Form.Control as="select" 
                                      name="tLicenseType" 
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      value={values.tLicenseType!}
                                      isInvalid={!!errors.tLicenseType}
                                      
                                      >
                                        <option value="">--- Vyberte typ licence ---</option>
                                        {
                                        licenseTypes
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tLicenseType}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>
                              </FormGroup>
                              
                              <FormGroup as={Col} controlId="information">
                                <Form.Row>
                                <FormLabel column lg={4} sm={3} xs={10}><strong>Modul</strong></FormLabel>
                                <FormLabel column lg={2} sm={2} xs={4}><strong>Aktuální<br />počet PC</strong></FormLabel>
                                <FormLabel column lg={3} sm={4} xs={4}><strong>Požadovaný<br />počet PC</strong></FormLabel>
                                </Form.Row>  
                              </FormGroup> 
                              <FormGroup as={Col} controlId="tTotalCount" className={values.programCode === 'D' ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Celkem PC</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aTotalCount"
                                      id="aTotalCount"
                                      value={values.aTotalCount!}
                                      className="form-control actual-module-count"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className='block-input-with-plus-minus'>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tTotalCount',1 < Number(values.tTotalCount) ? Number(values.tTotalCount)-1 : Number(values.tTotalCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={values.programCode === 'S' ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999 : 999}
                                      min={1}
                                      name="tTotalCount"
                                      value={values.tTotalCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //className={values.programCode === 'D' ? 'nodisplay':''}
                                      //isValid={touched.dYCount && !errors.dYCount} 
                                      isInvalid={!!errors.tTotalCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tTotalCount',(Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999) > Number(values.tTotalCount) ? Number(values.tTotalCount)+1 : Number(values.tTotalCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999) === values.tTotalCount) {
                                          setTotalInfo('U licence A nebo B je maximální počet 1 PC.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>

                                    <Form.Control.Feedback type="invalid">
                                      {errors.tTotalCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{TotalInfo}</div>
                                    </div>
                                    </Col>
                                    <Col lg={3} sm={3} xs={4}></Col>
                                </Form.Row>
                              </FormGroup>  
                              <FormGroup as={Col} controlId="tYCount" className={visibleY !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Systémové jádro</FormLabel>
                                  <Col  lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aYCount"
                                      id="aYCount"
                                      value={values.aYCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aY"
                                      label=""
                                      name="aYCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'Y')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tYCount',(Object.keys(licenseTypesCD.filter(data => data.id === values.tLicenseType) ).length > 0 ? 2:1) < Number(values.tYCount) ? Number(values.tYCount)-1 : Number(values.tYCount),true);
                                        await setFieldValue('tKCount',(Object.keys(licenseTypesCD.filter(data => data.id === values.tLicenseType) ).length > 0 ? 2:1) < Number(values.tYCount) ? Number(values.tYCount)-1 : Number(values.tYCount),true);
                                        if ((Object.keys(licenseTypesCD.filter(data => data.id === values.tLicenseType) ).length > 0 ? 2:-999) === values.tYCount) {
                                          setYInfo('U licence D je minimální počet 2 PC.')
                                        }
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:-999) === values.tYCount) {
                                          setYInfo('U licence B je minimální počet 1 PC.')
                                        }
                                        }} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999}
                                      min={0}
                                      name="tYCount"
                                      value={values.tYCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tYCount && !errors.tYCount} 
                                      isInvalid={!!errors.tYCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tYCount',(Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999) > Number(values.tYCount) ? Number(values.tYCount)+1 : Number(values.tYCount),true);
                                        await setFieldValue('tKCount',(Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999) > Number(values.tYCount) ? Number(values.tYCount)+1 : Number(values.tYCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999) === values.tYCount) {
                                          setYInfo('U licence B je maximální počet 1 PC.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tYCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{YInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tY"
                                      label=""
                                      name="tYCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tYCheck}
                                      
                                    />
                                    </Col>
                                    
                                </Form.Row> 
                              </FormGroup>
                              <FormGroup as={Col} controlId="tKCount" className={visibleK !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Kancelář</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aKCount"
                                      id="aKCount"
                                      value={values.aKCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aK"
                                      label=""
                                      name="aKCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'K')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      disabled={true}
                                      onClick={() => {handleHidePrices();setFieldValue('tKCount',0 < Number(values.tKCount) ? Number(values.tKCount)-1 : Number(values.tKCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      disabled={true}
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tKCount"
                                      value={values.tKCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e);handleCalculate(values,false)}}
                                      className='target-module-count'
                                      //isValid={touched.tKCount && !errors.tKCount} 
                                      isInvalid={!!errors.tKCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      disabled={true}
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tKCount',(license?.license.program.code === 'D' ? values.tYCount!:999) > Number(values.tKCount) ? Number(values.tKCount)+1 : Number(values.tKCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tKCount) {
                                          setKInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tKCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{KInfo}</div>
                                    </div>
                                    
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tK"
                                      label=""
                                      name="tKCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tKCheck}
                                      disabled={true}
                                      
                                    />
                                  </Col>
                                  
                                </Form.Row>
                              </FormGroup> 
                              <FormGroup as={Col} controlId="tUCount" className={visibleU !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Účetnictví</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aUCount"
                                      id="aUCount"
                                      value={values.aUCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aU"
                                      label=""
                                      name="aUCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'U')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tUCount',0 < Number(values.tUCount) ? Number(values.tUCount)-1 : Number(values.tUCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tUCount"
                                      value={values.tUCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e);}}
                                      className='target-module-count'
                                      //isValid={touched.tUCount && !errors.tUCount} 
                                      isInvalid={!!errors.tUCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tUCount',(license?.license.program.code === 'D' ? values.tYCount!:999) > Number(values.tUCount) ? Number(values.tUCount)+1 : Number(values.tUCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tUCount) {
                                          setUInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tUCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{UInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tU"
                                      label=""
                                      name="tUCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tUCheck}
                                      
                                    />
                                    
                                </Col>
                                
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tFCount" className={visibleF !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Daňová evidence</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aFCount"
                                      id="aFCount"
                                      value={values.aFCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aF"
                                      label=""
                                      name="aFCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'F')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tFCount',0 < Number(values.tFCount) ? Number(values.tFCount)-1 : Number(values.tFCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tFCount"
                                      value={values.tFCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tFCount && !errors.tFCount} 
                                      isInvalid={!!errors.tFCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tFCount',(license?.license.program.code === 'D' ? values.tYCount!:999) > Number(values.tFCount) ? Number(values.tFCount)+1 : Number(values.tFCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tFCount) {
                                          setFInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tFCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{FInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tF"
                                      label=""
                                      name="tFCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tFCheck}
                                      
                                    />

                                </Col>
                                
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tMCount" className={visibleM !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Mzdy</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aMCount"
                                      id="aMCount"
                                      value={values.aMCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aM"
                                      label=""
                                      name="aMCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'M')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tMCount',0 < Number(values.tMCount) ? Number(values.tMCount)-1 : Number(values.tMCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tMCount"
                                      value={values.tMCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tMCount && !errors.tMCount} 
                                      isInvalid={!!errors.tMCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tMCount',(license?.license.program.code === 'D' ? values.tYCount!:999) > Number(values.tMCount) ? Number(values.tMCount)+1 : Number(values.tMCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tMCount) {
                                          setMInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tMCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{MInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tM"
                                      label=""
                                      name="tMCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tMCheck}
                                      
                                    />
                                    </Col>
                                    
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tSCount" className={visibleS !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Sklady</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aSCount"
                                      id="aSCount"
                                      value={values.aSCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aS"
                                      label=""
                                      name="aSCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'S')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tSCount',0 < Number(values.tSCount) ? Number(values.tSCount)-1 : Number(values.tSCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tSCount"
                                      value={values.tSCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tSCount && !errors.tSCount} 
                                      isInvalid={!!errors.tSCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tSCount',(license?.license.program.code === 'D' ? values.tYCount!:999) > Number(values.tSCount) ? Number(values.tSCount)+1 : Number(values.tSCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tSCount) {
                                          setSInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tSCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{SInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tS"
                                      label=""
                                      name="tSCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tSCheck}
                                      
                                    />
                                    </Col>
                                    
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tPCount" className={visibleP !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Maloobchod</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aPCount"
                                      id="aPCount"
                                      value={values.aPCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aP"
                                      label=""
                                      name="aPCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'P')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tPCount',0 < Number(values.tPCount) ? Number(values.tPCount)-1 : Number(values.tPCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={values.tSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999 : 0}
                                      min={0}
                                      name="tPCount"
                                      value={values.tPCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tPCount && !errors.tPCount} 
                                      isInvalid={!!errors.tPCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tPCount',(values.tSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999 : 0) > Number(values.tPCount) ? Number(values.tPCount)+1 : Number(values.tPCount),true);
                                        if (values.tSCount! === 0) {
                                          setPInfo('Musí být zadán alespoň 1 modul Sklady!')
                                        }
                                        else if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tPCount) {
                                          setPInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tPCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{PInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tP"
                                      label=""
                                      name="tPCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tPCheck}
                                      
                                    />
                                    </Col>
                                    
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tECount" className={visibleE !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>E-shopy</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aECount"
                                      id="aECount"
                                      value={values.aECount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aE"
                                      label=""
                                      name="aECheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'E')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tECount',0 < Number(values.tECount) ? Number(values.tECount)-1 : Number(values.tECount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={values.tSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999 : 0}
                                      min={0}
                                      name="tECount"
                                      value={values.tECount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tECount && !errors.tECount} 
                                      isInvalid={!!errors.tECount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tECount',(values.tSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999 : 0) > Number(values.tECount) ? Number(values.tECount)+1 : Number(values.tECount),true);
                                        if (values.tSCount! === 0) {
                                          setEInfo('Musí být zadán alespoň 1 modul Sklady!')
                                        }
                                        else if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tECount) {
                                          setEInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tECount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{EInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tE"
                                      label=""
                                      name="tECheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tECheck}
                                      
                                    />
                                    </Col>
                                    
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tJCount" className={visibleJ !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Majetek</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aJCount"
                                      id="aJCount"
                                      value={values.aJCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aJ"
                                      label=""
                                      name="aJCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'J')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tJCount',0 < Number(values.tJCount) ? Number(values.tJCount)-1 : Number(values.tJCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tJCount"
                                      value={values.tJCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tJCount && !errors.tJCount} 
                                      isInvalid={!!errors.tJCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tJCount',(license?.license.program.code === 'D' ? values.tYCount!:999) > Number(values.tJCount) ? Number(values.tJCount)+1 : Number(values.tJCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tJCount) {
                                          setJInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tJCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{JInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tJ"
                                      label=""
                                      name="tJCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tJCheck}
                                      
                                    />
                                    </Col>
                                    
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tACount" className={visibleA !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Analýzy</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aACount"
                                      id="aACount"
                                      value={values.aACount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aA"
                                      label=""
                                      name="aACheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'A')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tACount',0 < Number(values.tACount) ? Number(values.tACount)-1 : Number(values.tACount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={values.tUCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999 : 0}
                                      min={0}
                                      name="tACount"
                                      value={values.tACount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tACount && !errors.tACount} 
                                      isInvalid={!!errors.tACount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tACount',(values.tUCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999 : 0) > Number(values.tACount) ? Number(values.tACount)+1 : Number(values.tACount),true);
                                        //await setTimeout(() => { handleCalculate(values,false); },1000) ;
                                        if (values.tUCount! === 0) {
                                          setAInfo('Musí být zadán alespoň 1 modul Účetnictví!')
                                        }
                                        else if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tACount) {
                                          setAInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tACount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{AInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tA"
                                      label=""
                                      name="tACheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tACheck}
                                      
                                      isInvalid={!!errors.tACheck}
                                    />
                                    {errors.tACheck}
                                    </Col>
                                    
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="tRCount" className={visibleR !== true ? 'nodisplay':''}>
                                <Form.Row>
                                  <FormLabel column lg={4} sm={3} xs={10}>Polotovary dokladů</FormLabel>
                                  <Col lg={2} sm={2} xs={4}>
                                    <input 
                                      type="text"
                                      name="aRCount"
                                      id="aRCount"
                                      value={values.aRCount!}
                                      className={values.programCode === 'D' ? 'form-control actual-module-count':'nodisplay'}
                                      disabled={true}
                                    />
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aR"
                                      label=""
                                      name="aRCheck"
                                      checked={license?.license.modules?.find(module => module.module.code === 'R')?.count! > 0 ? true:false}
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col lg={3} sm={4} xs={4}>
                                    <div className={values.programCode === 'D' ? 'block-input-with-plus-minus':'nodisplay'}>
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={() => {handleHidePrices();setFieldValue('tRCount',0 < Number(values.tRCount) ? Number(values.tRCount)-1 : Number(values.tRCount),true)}} >
                                      <i className="fas fa-minus"></i>
                                    </Button>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tRCount"
                                      value={values.tRCount!}
                                      onChange={(e:any) => {handleHidePrices();handleChange(e)}}
                                      className='target-module-count'
                                      //isValid={touched.tJCount && !errors.tJCount} 
                                      isInvalid={!!errors.tRCount}
                                      
                                    />
                                    <Button 
                                      className='button-plus-minus'
                                      onClick={async() => {
                                        await handleHidePrices();
                                        await setFieldValue('tRCount',(license?.license.program.code === 'D' ? values.tYCount!:999) > Number(values.tRCount) ? Number(values.tRCount)+1 : Number(values.tRCount),true);
                                        if ((Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:license?.license.program.code === 'D' ? values.tYCount!:999) === values.tRCount) {
                                          setRInfo('Počet PC modulu nesmí přesáhnout počet jader.')
                                        }
                                        }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tRCount}
                                    </Form.Control.Feedback>
                                    <div className="feedback">{RInfo}</div>
                                    </div>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tR"
                                      label=""
                                      name="tRCheck"
                                      onChange={handleChange}
                                      onClick={handleHidePrices}
                                      checked={values.tRCheck}
                                      
                                    />
                                    </Col>
                                    
                                </Form.Row>
                              </FormGroup>
                              <br />
                              {error}  
                              <ButtonGroup className="btn-block">
                                
                                <Button type="submit" size="lg" variant="success" disabled={loading || !isValid}>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button> 
                              </ButtonGroup>    
                      </Form>
                    )}
                  </Formik>
                  
          </div>
        </Modal.Body>

      </Modal>

    </span>
  );
}

export default withRouter(observer(CustomersLicensesEdit))

import React, {useState} from 'react'
import TopMenu from '../../parts/TopMenu/TopMenu.parts'

import Jumbotron from 'react-bootstrap/Jumbotron'
import {Container} from 'react-bootstrap'

import { useAuth } from '../../authProvider'
import { useStore } from '../../store'
import { useMount } from '../../helpers/lifecycle-hooks'

const Home: React.FC = (props:any) => {
  const [logged] = useAuth()
  const rootStore = useStore()
  const [loaded, setLoaded] = useState(false)
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
      window.scrollTo(0, 0) // Odrolování nahoru
      setLoaded(false)

      if ((logged) && (localStorage.getItem('isLogged'))) { 
        if (rights) {
          await rootStore.fetchLoggedUserPreferences()
          if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'S') {
            props.history.push('/support')
          }
          else if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'M') {
            props.history.push('/meetings')
          }
          else if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'T') {
            props.history.push('/tasks')
          }
          else if (rootStore.getLoggedUserPreferenceByKey('startPage')?.value === 'L' && localStorage.getItem('lastOpenedSite')) {
            props.history.push('/'+localStorage.getItem('lastOpenedSite'))
          }
          else {
            if (localStorage.getItem('openedCustomerId') !== null ) {
              props.history.push('/customers-dashboard')
            }
            else {
              props.history.push('/customers-open')
            }
          }
        }
        else {
          if (localStorage.getItem('openedCustomerId') !== null ) {
            props.history.push('/customers-dashboard')
          }
          else {
            props.history.push('/customers-open')
          }
        }
      }
      else { 
        props.history.push('/login')  
      } 
    })() 
  })  
  
  return (
    <div className="App">
      {loaded && <>
        <TopMenu />
        <Container>
          <Jumbotron>
            <h1>Vítejte na zákaznickém portále Ježek software!</h1>

            </Jumbotron>        
        </Container>
      </>}
    </div> 
  );
}

export default Home

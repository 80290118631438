import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUser, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../../Utils/Confirmation";

import DialsProgramVersionsUpdate from './Dials.ProgramVersions.Update.part'

import { useDialsStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'


const DialsProgramVersionsList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)


  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete verzi "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání verze programu'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  const rootDialsStore = useDialsStore()
  
  const programVersions = rootDialsStore.dialsProgramVersions
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = new Date(a.supportDateTo); 
    var sort1B = new Date(b.supportDateTo);

    var sort2A = a.programId; 
    var sort2B = b.programId; 
    
    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }
    if (sort2A! < sort2B!) {
      return 1;
    }
    if (sort2A! > sort2B!) {
      return -1;
    }


    return 0;
  })


  const programs = rootDialsStore.dialsPrograms
  .slice()
  .map(dp => (
    <option key={dp.id} value={dp.id}>{dp.name}</option>
  ))   
  
  //Počet záznamů
  const countData:number = Object.keys(programVersions).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = programVersions.map((item) => (
    <tr key={item.id}>
      <td>{rootDialsStore.dialsPrograms.find(p => p.id === item.programId)?.name} {item.version}</td>
      <td>{moment(item.supportDateTo).format("DD.MM.YYYY")}</td>
      
      {rights &&<td>
      
      {rightsBH &&<>
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">
          <ButtonGroup>
            <DialsProgramVersionsUpdate id={item.id} showAs='button' /> 
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
              <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </div>
      </>}
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = programVersions.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        {rights && <>
          {rightsBH &&<>  
            <div className="float-right">
              <ButtonGroup>
                <DialsProgramVersionsUpdate id={item.id} showAs='button' />
                <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                  <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>  
            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{rootDialsStore.dialsPrograms.find(p => p.id === item.programId)?.name} {item.version}</strong><br />
          {moment(item.supportDateTo).format("DD.MM.YYYY")}
          <br />
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_DIALS_PROGRAM_VERSIONS')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootDialsStore.fetchDialsProgramVersionsAll()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }



    
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (programIdData:string, nameData:string, versionData:number, supportDateToData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_DIALS_PROGRAM_VERSIONS'), { name: nameData, programId: programIdData, version: versionData, supportDateTo: supportDateToData !== '' ? new Date(supportDateToData!).toISOString() : null}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootDialsStore.fetchDialsProgramVersionsAll()
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewTheme = Yup.object().shape({
    name: Yup.string()
    .required('Název verze programu musí být vyplněn!'),
    programId: Yup.string()
    .required('Program musí být vybrán!'),
    version: Yup.number()
    .required('Verze programu musí být vyplněna!')
    .min(1,'Minimální hodnota je 1!'),
  }) 
  
  return (
    <Container fluid>



      <br />


      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam verzí programů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Název</th>
                <th className="jsw-table-head">Podpora do</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        
      </div>  
      {rightsBH &&
      <Button variant="success" onClick={handleShow}>
        + Přidat verzi programu
      </Button>
      }

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání verze programu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Verze programu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Verze programu byla úspěšně přidána!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewTheme}
              initialValues={{ 
                programId: '',
                name: '',
                version: 0,
                supportDateTo: moment(new Date()).format("YYYY-MM-DD"),
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.programId!, values.name!, values.version!, values.supportDateTo!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="programId">
                    <Form.Row>
                      <FormLabel column lg={3}>Program</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="programId" 
                          onChange={handleChange}
                          value={values.programId!}
                          isInvalid={!!errors.programId}
                          >
                            <option value="">--- Vyberte ---</option>
                            {programs}
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.programId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}

                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                  <FormGroup controlId="version">
                    <Form.Row>
                      <FormLabel column lg={3}>Verze</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="version"
                          value={values.version}
                          onChange={handleChange}
                          isValid={touched.version && !errors.version} 
                          isInvalid={!!errors.version}

                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.version}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>    
                  <FormGroup controlId="supportDateTo">
                    <Form.Row>
                      <FormLabel column lg={3}>Podpora do</FormLabel>
                      <Col>
                      <FormControl 
                            type="date"
                            name="supportDateTo"
                            value={values.supportDateTo!}
                            onChange={handleChange}
                            isValid={touched.supportDateTo && !errors.supportDateTo} 
                            isInvalid={!!errors.supportDateTo}
                          />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.supportDateTo}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám verzi programu...' : 'Uložit verzi programu'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(DialsProgramVersionsList))

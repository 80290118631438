import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../../parts/TopMenu/SideMenu.parts'

import DialsSupportThemesList from './components/Dials.ProgramVersions.List.part'

import { Container } from 'react-bootstrap'

import { useMount } from '../../../../helpers/lifecycle-hooks'
import { useStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../../Utils/Common'

function DialsProgramVersions(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  
  // Nastavení práv
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'dials-support-themes')
    await checkVersion()
    //if (rights === false) {
    //  props.history.push('/customers-dashboard')
    //}

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
          }
          await rootStore.fetchCustomerList('','',1)
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')

          
        } else {
          
        }
      }

      if (rights === false) {
        props.history.push('/customers-dashboard')
      }  
      await rootDialsStore.fetchDialsProgramVersionsAll()
      await rootDialsStore.fetchDialsPrograms()
    }  
    
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <Container fluid>
                <br />
                <h4>Číselníky - Verze programů</h4>
                              
              </Container>

              <DialsSupportThemesList />
              <br /><br />
            </Loader>  
            </main>
          </div>
        </div>      
      
    </div> 
  );
}

export default observer(DialsProgramVersions)

//import { getLoadableComponent } from "../sharedComponents/LoadableComponent/LoadableComponent"
import Support from './support/Support.page'
import Submissions from './submissions/Submissions.page'

import Home from './home/Home.page'
  
  /* ACCOUNTS */
import AccountsLogin from './accounts/login/Accounts.Login.page'
import AccountsLogout from './accounts/logout/Accounts.Logout.page'
import AccountsForgottenPassword from './accounts/forgottenPassword/Accounts.ForgottenPassword.page'
import AccountsResetPassword from './accounts/resetPassword/Accounts.ResetPassword.page'
import AccountsRegistration from './accounts/registration/Accounts.Registration.page'
import AccountsConfirmRegistration from './accounts/confirmRegistration/Accounts.ConfirmRegistration.page'
import AccountsMyAccount from './accounts/myAccount/Accounts.MyAccount.page'
import Accounts from './accounts/accounts/Accounts.Accounts.page'

  /* CUSTOMERS */
  import CustomersOpen from './customers/open/Customers.Open.page'
  import CustomersDashboard from './customers/dashboard/Customers.Dashboard.page'
  import CustomersDocuments from './customers/documents/Customers.Documents.page'
  import CustomersUserManagement from './customers/userManagement/Customers.UserManagement.page'
  import CustomersLicenses from './customers/licenses/Customers.Licenses.page'
  import CustomersHistory from './customers/history/Customers.History.page'
  import CustomersEmailsSent from './customers/emailsSent/Customers.EmailsSent.page'

  /* SELECTIONS */
  import Overviews from './overviews/list/Overviews.List.page'
  import OverviewsCustomersList from './overviews/list/Overviews.List.OnlyCustomers.page'
  import OverviewsDetail from './overviews/detail/Overviews.Detail.page'
  import OverviewsDetailOffer from './overviews/detail/Overviews.Detail.Offer.page'
  
  /* TEMPLATES */
  import Templates from './others/templates/Others.Templates.page'
  import TemplatesDetail from './overviews/detail/Overviews.Detail.page'

  /* TEMPLATES */
  import Operations from './others/operations/Others.Operations.page'
  

  /* LICENSES TRANSFER */
  import LicensesTransfer from './licensesTransfer/LicencesTransfers.page'
  
  /* UPGRADE */
  import UpgradeOfferList from './upgrade/offerList/Upgrade.OfferList.page'
  import UpgradeOfferDetail from './upgrade/offerDetail/Upgrade.OfferDetail.page'

  /* CALCULATORS */
  import UpgradeCalculator from './calculators/upgradeCalculator/UpgradeCalculator.page'

  /* DIALS */
  import Dials from './others/dials/Dials.page'
  import DialsSupportTypes from './others/dials/supportTypes/Dials.SupportTypes.page'
  import DialsSupportThemes from './others/dials/supportThemes/Dials.SupportThemes.page'
  import DialsProgramVersions from './others/dials/programVersions/Dials.ProgramVersions.page'
  import DialsFreeText from './others/dials/freeText/Dials.FreeText.page'
  import DialsOperationsPreferences from './others/dials/operationsPreferences/Dials.Operations.Preferences.page'
  import DialsDeliveryCzechPostServices from './others/dials/deliveryCzechPostServices/Dials.Delivery.CzechPost.Services.page'
  import DialsDeliveryCzechPostProducts from './others/dials/deliveryCzechPostProducts/Dials.Delivery.CzechPost.Products.page'
  import DialsDeliveryCzechPostTemplates from './others/dials/deliveryCzechPostTemplates/Dials.Delivery.CzechPost.Templates.page'
  import DialsMeetingsCategories from './others/dials/meetingsCategories/Dials.Meetings.Categories.page'
  import DialsUserGroups from './others/dials/userGroups/Dials.User.Groups.page'

   /* EMAILING */
   import EmailingWhiteList from './emailing/emailingWhiteList/Emailing.WhiteList.page'
   import EmailingBlackList from './emailing/emailingBlackList/Emailing.BlackList.page'

   import EmailingUnsubscribe from './emailing/emailingUnsubscribe/Emailing.Unsubscribe.page'

   import DatafiltersCustomersCustomList from './emailing/datafiltersCustomersCustomList/Datafilters.Customers.Custom.List.page'


   /* MEETINGS & TASKS */
   import Meetings from './meetings/Meetings.page'
   import Tasks from './tasks/Tasks.page'
   

export const Pages = {
  //Home: getLoadableComponent(() => import('./home/Home.page')),
  Home: Home,
  
  /* ACCOUNTS */
  /*
  AccountsLogin: getLoadableComponent(() => import('./accounts/login/Accounts.Login.page')),
  AccountsLogout: getLoadableComponent(() => import('./accounts/logout/Accounts.Logout.page')),
  AccountsForgottenPassword: getLoadableComponent(() => import('./accounts/forgottenPassword/Accounts.ForgottenPassword.page')),
  AccountsResetPassword: getLoadableComponent(() => import('./accounts/resetPassword/Accounts.ResetPassword.page')),
  AccountsRegistration: getLoadableComponent(() => import('./accounts/registration/Accounts.Registration.page')),
  AccountsConfirmRegistration: getLoadableComponent(() => import('./accounts/confirmRegistration/Accounts.ConfirmRegistration.page')),
  AccountsMyAccount: getLoadableComponent(() => import('./accounts/myAccount/Accounts.MyAccount.page')),
  */
  AccountsLogin: AccountsLogin,
  AccountsLogout: AccountsLogout,
  AccountsForgottenPassword: AccountsForgottenPassword,
  AccountsResetPassword: AccountsResetPassword,
  AccountsRegistration: AccountsRegistration,
  AccountsConfirmRegistration: AccountsConfirmRegistration,
  AccountsMyAccount: AccountsMyAccount,
  Accounts: Accounts,

  /* CUSTOMERS */
  /*
  CustomersOpen: getLoadableComponent(() => import('./customers/open/Customers.Open.page')),
  CustomersDashboard: getLoadableComponent(() => import('./customers/dashboard/Customers.Dashboard.page')),
  CustomersDocuments: getLoadableComponent(() => import('./customers/documents/Customers.Documents.page')),
  CustomersUserManagement: getLoadableComponent(() => import('./customers/userManagement/Customers.UserManagement.page')),
  CustomersLicenses: getLoadableComponent(() => import('./customers/licenses/Customers.Licenses.page')),
  CustomersHistory: getLoadableComponent(() => import('./customers/history/Customers.History.page')),
  CustomersEmailsSent: getLoadableComponent(() => import('./customers/emailsSent/Customers.EmailsSent.page')),
  */
  CustomersOpen: CustomersOpen,
  CustomersDashboard: CustomersDashboard,
  CustomersDocuments: CustomersDocuments,
  CustomersUserManagement: CustomersUserManagement,
  CustomersLicenses: CustomersLicenses,
  CustomersHistory: CustomersHistory,
  CustomersEmailsSent: CustomersEmailsSent,

  /* SELECTIONS */
  /*
  Overviews: getLoadableComponent(() => import('./overviews/list/Overviews.List.page')),
  OverviewsDetail: getLoadableComponent(() => import('./overviews/detail/Overviews.Detail.page')),
  OverviewsDetailOffer: getLoadableComponent(() => import('./overviews/detail/Overviews.Detail.Offer.page')),
  */
  Overviews: Overviews,
  OverviewsOnlyCustomersList: OverviewsCustomersList,
  OverviewsDetail: OverviewsDetail,
  OverviewsDetailOffer: OverviewsDetailOffer,
  
  /* TEMPLATES */
  /*
  Templates: getLoadableComponent(() => import('./others/templates/Others.Templates.page')),
  TemplatesDetail: getLoadableComponent(() => import('./overviews/detail/Overviews.Detail.page')),
  */
  Templates: Templates,
  TemplatesDetail: TemplatesDetail,

  /* TEMPLATES */
  Operations: Operations,
  

  /* LICENSES TRANSFER */
  //LicensesTransfer: getLoadableComponent(() => import('./licensesTransfer/LicencesTransfers.page')),
  LicensesTransfer: LicensesTransfer,
  
  /* UPGRADE */
  /*
  UpgradeOfferList: getLoadableComponent(() => import('./upgrade/offerList/Upgrade.OfferList.page')),
  UpgradeOfferDetail: getLoadableComponent(() => import('./upgrade/offerDetail/Upgrade.OfferDetail.page')),
  */
  UpgradeOfferList: UpgradeOfferList,
  UpgradeOfferDetail: UpgradeOfferDetail,

  /* CALCULATORS */
  //UpgradeCalculator: getLoadableComponent(() => import('./calculators/upgradeCalculator/UpgradeCalculator.page')),
  UpgradeCalculator: UpgradeCalculator,

  /* SUPPORT */
  //Support: getLoadableComponent(() => import('./support/Support.page')),
  Support: Support,


  /* SUBMISSIONS */
  //Submissions: getLoadableComponent(() => import('./submissions/Submissions.page')),  
  Submissions: Submissions,

  /* MEETINGS & TASKS */
  //Submissions: getLoadableComponent(() => import('./submissions/Submissions.page')),  
  Meetings: Meetings,
  Tasks: Tasks,

  /* DIALS */
  /*
  Dials: getLoadableComponent(() => import('./others/dials/Dials.page')),
  DialsSupportTypes: getLoadableComponent(() => import('./others/dials/supportTypes/Dials.SupportTypes.page')),
  DialsSupportThemes: getLoadableComponent(() => import('./others/dials/supportThemes/Dials.SupportThemes.page')),
  DialsProgramVersions: getLoadableComponent(() => import('./others/dials/programVersions/Dials.ProgramVersions.page')),
  DialsFreeText: getLoadableComponent(() => import('./others/dials/freeText/Dials.FreeText.page')),
  DialsOperationsPreferences: getLoadableComponent(() => import('./others/dials/operationsPreferences/Dials.Operations.Preferences.page')),
  DialsDeliveryCzechPostServices: getLoadableComponent(() => import('./others/dials/deliveryCzechPostServices/Dials.Delivery.CzechPost.Services.page')),
  DialsDeliveryCzechPostProducts: getLoadableComponent(() => import('./others/dials/deliveryCzechPostProducts/Dials.Delivery.CzechPost.Products.page')),
  DialsDeliveryCzechPostTemplates: getLoadableComponent(() => import('./others/dials/deliveryCzechPostTemplates/Dials.Delivery.CzechPost.Templates.page')),
  */
  Dials: Dials,
  DialsSupportTypes: DialsSupportTypes,
  DialsSupportThemes: DialsSupportThemes,
  DialsProgramVersions: DialsProgramVersions,
  DialsFreeText: DialsFreeText,
  DialsOperationsPreferences: DialsOperationsPreferences,
  DialsDeliveryCzechPostServices: DialsDeliveryCzechPostServices,
  DialsDeliveryCzechPostProducts: DialsDeliveryCzechPostProducts,
  DialsDeliveryCzechPostTemplates: DialsDeliveryCzechPostTemplates,
  DialsMeetingsCategories: DialsMeetingsCategories,
  DialsUserGroups: DialsUserGroups,

  /* EMAILING */
  EmailingWhiteList: EmailingWhiteList,
  EmailingBlackList: EmailingBlackList,

  EmailingUnsubscribe: EmailingUnsubscribe,

  DatafiltersCustomersCustomList: DatafiltersCustomersCustomList,

    
}
import { types } from 'mobx-state-tree'

export const CustomerOpenedUsageMode = types
  .model({
    programId: types.maybeNull(types.string),
    usageMode: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

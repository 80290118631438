import React, {useState}  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getModuleOrder } from '../../../../Utils/Common'

import {Card, CardDeck, DropdownButton, ButtonGroup, Dropdown, Modal, Badge } from 'react-bootstrap'

import { useStore, useAppStore } from '../../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'
import CustomersGDPRUpdate  from './Customers.Dashboard.GDPR.Update.part'
import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'
import CustomersDashboardLicensesEditModeOfUse from './Customers.Dashboard.Licenses.EditModeOfUse.part'

import AppProduelDetail from '../../../appProDuel/App.ProDuel.Detail.part'

interface CustomersDashboardLicensesProps {
  loadingAppProDuel?:boolean
}

const CustomersDashboardLicenses: React.FC<CustomersDashboardLicensesProps & RouteComponentProps> = (props:any) => {
  // Načtení Store
  const rootStore = useStore()
  const rootAppStore = useAppStore()

  const [show, setShow] = useState(false)

  const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const usageMode = rootStore.customerOpenedUsageMode
  
  //Počet záznamů
  const countData:number = Object.keys(rootStore.getCustomerOpenedLicensesActiveActual()).length

  const handleCopyLicence = (licenseId:string, startversion?:boolean) => {
          
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = 'CISLO_ZAKAZNIKA='+customer?.accountNumber
    dummy.value = dummy.value + '\nVERZE=' + String(rootStore.getCustomerOpenedLicenseById(licenseId)?.license.programVersion.version)
    
    if (startversion !== true) {
      rootStore.getCustomerOpenedLicenseById(licenseId)?.license.modules!.map(m => {
        dummy.value = dummy.value + '\n' + m.module.name?.toLocaleUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ","_").replaceAll("-","") + "=" + String(m.count)
        return ('')
      })  
    }

    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    
    setShow(true)

    setTimeout(() => {      
        setShow(false)
    }, 1000);
  }

  const licensesActiveActual = rootStore.getCustomerOpenedLicensesActiveActual().map(customerOpenedLicenses => (
    <Card
      bg='light'
      text='dark'
      key={customerOpenedLicenses.license.id}
      className={customerOpenedLicenses.license.program.code === 'D' ? 'border-left-danger':'border-left-info'}
    >
      <Card.Header as="h5">
      <img className="noprint" alt="logo programu" src={"images/ico-header-"+customerOpenedLicenses.license.program.code+".png"} style={{marginBottom: '5px', marginRight: '15px' }} />
        {customerOpenedLicenses.license.program.name} {customerOpenedLicenses.license.programVersion.version} {(customerOpenedLicenses.license.program.code === 'D' && Object.keys(rootStore.customerOpenedCategories.filter(c => c.code === 'S')).length > 0) && <Badge variant="info">Startverze</Badge>}
      {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            {customerOpenedLicenses.license.program.code === 'D' &&
              <Dropdown.Item onClick={() => { handleCopyLicence(customerOpenedLicenses.license.id!, (customerOpenedLicenses.license.program.code === 'D' && Object.keys(rootStore.customerOpenedCategories.filter(c => c.code === 'S')).length > 0) ? true:false) } }>Zkopírovat licenci do schránky</Dropdown.Item>
            }
            <CustomersOtherActionsHistory label='' block={false} showAs='drop' title={customerOpenedLicenses.license.program.name+' '+customerOpenedLicenses.license.programVersion.version} historyType='license' historyParam={customerOpenedLicenses.license.id!} />
          </DropdownButton>
        </div>}
      </Card.Header>
      <Card.Body >
         Typ licence: {customerOpenedLicenses.license.licenseType.code}, Počet PC: {customerOpenedLicenses.license.count}
        <br /><br />
        <strong>Moduly:</strong><br />
        <table>
          <tbody>
            {customerOpenedLicenses.license !== undefined && <>
            {customerOpenedLicenses.license.modules!.slice().sort(function(a:any, b:any) {
              var nameA = getModuleOrder(a.module.code); 
              var nameB = getModuleOrder(b.module.code); 
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            }).map(modules => (<tr key={modules.module.id}><td className="licenseModuleName">{modules.module.name}</td><td>{modules.count}</td></tr>))}   
            </>}
          </tbody>
        </table> 
        
      </Card.Body>
      <Card.Footer>
      <strong>Režim používání:</strong><br />   
        {customerOpenedLicenses.license.program.code === 'D' ?
          <>
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'x' && <>Nezadáno</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'n' && <>Neuvedeno</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'v' && <>Pro vlastní potřebu</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'k' && <>Účetní / mzdová kancelář</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'i' && <>Účetní / mzdová kancelář + pro vlastní potřebu</> }
          </>
          :
          <>
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'x' && <>Nezadáno</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'n' && <>Neuvedeno</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'v' && <>Pro vlastní potřebu</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'k' && <>Účetní / mzdová kancelář</> }
            {usageMode.find(um => um.programId === customerOpenedLicenses.license.program.id)?.usageMode === 'i' && <>Účetní / mzdová kancelář + pro vlastní potřebu</> }
          </>
        }
        
        <div className="float-right">
            <CustomersDashboardLicensesEditModeOfUse showIcon={false} label="Změnit" id="" block={false} programId={customerOpenedLicenses.license.program.id!} programCode={customerOpenedLicenses.license.program.code!} />
        </div>
      </Card.Footer>
    </Card>
  ))

  const services = rootStore.customerOpenedServices.map(customerOpenedServices => (
    <tr key={customerOpenedServices.service.id!}>
      <td className="licenseModuleName">{customerOpenedServices.service.code === 'VS' ? 'Vzdálená správa' : customerOpenedServices.service.name}</td>
      <td style={customerOpenedServices.isActive ? {}:{color:'red'}}>{customerOpenedServices.validTo === '' ? 'není aktivní' : moment(customerOpenedServices.validTo).format("DD.MM.YYYY")}{customerOpenedServices.isActive ? '':<span>&nbsp;<i className="fas fa-exclamation-circle"></i></span>}</td>
    </tr>

  ))  

  const customerCard = <CardDeck>
    <Card
      bg='light'
      text='dark'
      className='border-left-secondary'
    >
      <Card.Header  as="h5">Služby
        {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsHistory label=' služeb' block={false} showAs='drop' title='' historyType='services' historyParam='' />
            {countData === 9999 && <CustomersOtherActionsHistory label=' licencí' block={false} showAs='drop' title='' historyType='licenses' historyParam='' />}
          </DropdownButton>
        </div>}
      </Card.Header>
      <Card.Body>
        
        <table>
          <tbody>
            <tr>
              <td><strong>Služba</strong></td>
              <td><strong>Platnost&nbsp;do</strong></td>
            </tr>
            {services}
          </tbody>
        </table>
        {rootStore.customerOpenedServices.length === 0 && <span>Žádné služby.</span>}  
        
      </Card.Body>
    </Card>
    <Card
      bg='light'
      text='dark'
      className='border-left-secondary'
    >
      <Card.Header  as="h5">Smlouvy
        {rightsSHBH &&<div style={{float:'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            <CustomersOtherActionsHistory label=' služeb' block={false} showAs='drop' title='' historyType='services' historyParam='' />
            {countData === 9999 && <CustomersOtherActionsHistory label=' licencí' block={false} showAs='drop' title='' historyType='licenses' historyParam='' />}
          </DropdownButton>
        </div>}
      </Card.Header>
      <Card.Body>
        
        <table>
          <tbody>
            <tr>
              <td><strong>Smlouva</strong></td>
              <td><strong>Platnost&nbsp;do</strong></td>
            </tr>
            <tr>
              <td className="licenseModuleName">GDPR smlouva</td>
              <td style={customer?.gdprIsActive ? {}:{color:'red'}}>{(customer?.gdprDate === '' || customer?.gdprDate === null)  ? 'nepodepsáno' : moment(customer?.gdprDate).format("DD.MM.YYYY")}{customer?.gdprIsActive ? '':<span>&nbsp;<i className="fas fa-exclamation-circle"></i></span>}</td>
            </tr>
          </tbody>
        </table>
        {(!customer?.gdprIsActiveForUpgrade) &&<CustomersGDPRUpdate label={''+customer?.gdprDate === '' ? 'Podepsat smlouvu GDPR' : 'Prodloužit smlouvu GDPR'} showIcon={true} block={false} />}   
        
      </Card.Body>
    </Card>

  </CardDeck>
 
  return (
    <>
    <div>
      <h4>Licence</h4>
      <br />
      <CardDeck>
        {licensesActiveActual}
        {rightsSHBH &&
        <Card
          bg='light'
          text='dark'
          className='border-left-warning'
        >
          <Card.Header  as="h5">
          <img className="noprint" alt="logo programu" src={"images/ico-header-M.png"} style={{marginBottom: '5px', marginRight: '15px' }} />
            Mobilní aplikace
          </Card.Header>
          <Card.Body>
            
            <table style={{width: '90%'}}>
              <tbody>
                <tr>
                  <td><strong>Aplikace</strong></td>
                  <td></td>
                </tr>
                <tr>
                  <td>proDUEL</td>
                  <td style={{textAlign: 'right'}}>{props.loadingAppProDuel !== true &&<AppProduelDetail portalId={localStorage.getItem('openedCustomerId')!} newCustomer={rootAppStore.getAppProduelCustomerByPortalId(localStorage.getItem('openedCustomerId')!) === undefined ? true : false} />}  </td>
                </tr>	
              </tbody>
            </table>
            
            
          </Card.Body>
        </Card>}
        
      </CardDeck>
      <br />
      <h4>Služby a smlouvy</h4>
      <br />
      {customerCard}
      
    </div>
    
    <Modal onClose={() => setShow(false)} show={show} aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Header><Modal.Title>Kopie licence do schránky</Modal.Title></Modal.Header>
      <Modal.Body>Licence byla zkopírována do schránky!</Modal.Body>
    </Modal>
    
    </>
  )  
  
}

export default withRouter(observer(CustomersDashboardLicenses))

import { types } from 'mobx-state-tree'

export const DatafiltersOffersCounts = types
  .model({
    id: types.optional(types.string, ''),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    
      
     
  }))
  .actions(self => ({
    setCount(newCount: number|null) {
      self.count = newCount
    }

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import TasksDetail from './Tasks.Detail.part'

// eslint-disable-next-line
import axios from 'axios';

//import { useDialsStore } from '../../../store'
import { useStore } from '../../../store'

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
//import NumberFormat from 'react-number-format'

interface TasksAddProps {
  showAs: string,
  fromMeetings?: boolean,
  meetingId?: string,
  meetingPointId?: string,
  style?: any,
  meetingPointUpdate?: any,
  isPrivate?: boolean
}

const TasksAdd: React.FC<TasksAddProps & RouteComponentProps>  = (props:any) => {
  
  // Připojení store
  const rootStore = useStore()

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  //eslint-disable-next-line
  const [newTaskId, setNewTaskId] = useState('')


  // Seznam pracovníků JSW pro lookup
  const tasksHedgehogs = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === true)
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.surName; 
    var sort1B = b.surName; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(hu => (
    <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
  ))  
  
  
  // Otevírání a zavírání modálního okna pro přidání
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    if (props.fromMeetings === true) {
      rootStore.fetchMeetingPoints(props.meetingId)
    }

  }
  const handleShow = () => {
    
    (async () => {
    sessionStorage.removeItem('autoCloseModal')    

    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }

  // Funkce pro přidání úkolu
  const handleAdd = (deadlineData:string, textData:string, responsibleUserIdData:string, isPrivateData:boolean) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_TASKS')+'/', { deadline: new Date(deadlineData).toISOString(), text: textData, responsibleUserId: responsibleUserIdData, isPrivate: isPrivateData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')

          // Pokud se jedná o přidání úkolu k bodu porady, tak se musí provést ještě aktualizace bodu porady s ID úkolu
          if (props.fromMeetings === true) {
            
            rootStore.addTaskFromData(response.data)
            setNewTaskId(response.data.id)

            const editedMeetingPoint = rootStore.getMeetingById(props.meetingId)?.points.find(mp => mp.id === props.meetingPointId)

            let dataToSend = { id: editedMeetingPoint?.id, caption:editedMeetingPoint?.caption, text: editedMeetingPoint?.text, taskId: response.data.id, rank: editedMeetingPoint?.rank }

            // Zavolání API pro update bodu porady
            axios.post(getUrl('URL_MEETINGS')+'/'+props.meetingId+'/points/'+props.meetingPointId, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
              ).then(response => {

                }).catch(error => {
                  //Zatím nic nebudeme odchytávat
                  //setLoading(false)
                  //const errorMessage:any = (<ErrorAlert errorData={error} />)
                  //setError(errorMessage)
                });

          }
          else {
            rootStore.fetchTasks( Number(sessionStorage.getItem('tasksSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('tasksSearchPage')))
          }

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(async () => {

            if (props.fromMeetings === true) {
              await rootStore.fetchMeetingPoints(props.meetingId)
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              
              // Pokud se jedná o přidání úkolu k bodu porady, tak se okno nebude zavírat automaticky
            }
            else {
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }
 

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    deadline: Yup.string()
    .required('Datum porady musí být vyplněno!'),
    responsibleUserId: Yup.string()
    .required('Osoba odpovídající za řešení úkolu musí být vyplněna!'),
    text: Yup.string()
    .required('Text úkolu musí být vyplněn!'),
    
  })   
  return (
    <>
      <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Přidat úkol</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" style={props.style} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat úkol
          </Button> }
        </>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Přidání úkolu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Úkol byl úspěšně přidán!​</strong>

            
            
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ 
                deadline: moment(new Date()).add(7,'day').format("YYYY-MM-DDT15:30"), 
                text: '', 
                responsibleUserId: '',
                //Pokud přijde parametr isPrivate, tak se nastaví na true, jinak na false. Znamená to, že se jedná o úkol z porady označené jako porada vedení.
                isPrivate: props.isPrivate === true ? true : false,
                temp: '',
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleAdd(values.deadline!, values.text!, values.responsibleUserId!, values.isPrivate!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                validateField,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="deadline">
                    <Form.Row>
                      <FormLabel column lg={3}>Termín</FormLabel>
                      <Col>
                        <FormControl 
                          type="datetime-local"
                          name="deadline"
                          value={values.deadline}
                          onChange={handleChange}
                          isValid={touched.deadline && !errors.deadline} 
                          isInvalid={!!errors.deadline}
                          onBlur={() => validateField('deadline')}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.deadline}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="text">
                    <Form.Row>
                      <FormLabel column lg={3}>Úkol</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="text"
                          value={values.text}
                          onChange={handleChange}
                          isValid={touched.text && !errors.text} 
                          isInvalid={!!errors.text}
                          onBlur={() => validateField('text')}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.text}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="responsibleUserId">
                    <Form.Row>
                      <FormLabel column lg={3}>Odpovídá</FormLabel>
                      <Col>
                          <Form.Control as="select" 
                            name="responsibleUserId"                             
                            onChange={handleChange}
                            value={values.responsibleUserId!}
                            isInvalid={!!errors.responsibleUserId}
                            onBlur={() => validateField('responsibleUserId')}
                            //disabled={!rightsAuthor}
                            //tabIndex={4}
                            //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 3:7, handleSubmit)}
                            >
                              <option value="">--- Vyberte ---</option>
                              {
                              tasksHedgehogs
                              }
                          </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.responsibleUserId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  {props.fromMeetings !== true &&
                  <FormGroup controlId="isPrivate">
                    <Form.Row>
                      <FormLabel column lg={3}>Soukromý</FormLabel>
                      <Col>
                      <FormControl 
                          type="checkbox"
                          name="isPrivate"
                          size="sm"
                          className="checkbox-small"
                          onChange={handleChange}
                          checked={values.isPrivate}
                          //V případě, že přijde parametr, že je to soukromý úkol, tak to nelze změnit. Takový příznak přijde v případě,  že je úkol vytvářen z porady vedení.
                          disabled={props.isPrivate === true ? true : false}
                          isValid={touched.isPrivate && !errors.isPrivate} 
                          isInvalid={!!errors.isPrivate}
                          //tabIndex={6}
                          //onKeyDown={(e:any) => handleKeyDown(e, props.isCustomer === true ? 6:10, handleSubmit)}

                          
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isPrivate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  }

                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám úkol...' : 'Uložit úkol'}</Button>

                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(TasksAdd))

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Table } from 'react-bootstrap'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import FilesUpdate from './Files.Update.part'

import { humanFileSize } from '../../Utils/Common'

import moment from 'moment'

interface FilesListProps {
  id: string,
  type: string,
  masterId: string,
  noFilesInfo?: boolean
}

const FilesList: React.FC<FilesListProps & RouteComponentProps>  = (props:any) => {
  
  // Připojení store
  const rootStore = useStore()
  const files = rootStore.files.filter(f => f.linkId === props.id).sort(function(a:any, b:any) {
    var sort1A = new Date(a.createDate); 
    var sort1B = new Date(b.createDate); 

    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }
    return 0;
  })
  
  //Počet záznamů
  const countData:number = Object.keys(files!).length

    // Seznam souborů do řádků tabulky
    const filesTable = files.map(file => (
      <tr key={file.id}>
        <td><span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => rootStore.openFile(file.id,file.contentType,file.fileName)}>{file.fileName}</span></td>
        
        <td>{file.note}</td>
        <td>{humanFileSize(file.size!)}</td>
        <td>{moment(file.createDate).format("DD.MM.YYYY HH:mm")}</td>
        <td>
          <FilesUpdate id={file.id} type={props.type} masterId={props.masterId} />
        </td>
      </tr>
    ))
   
  
  return (
    <>
      {countData !== 0 && <>
          <strong>Seznam souborů</strong>
          <Table responsive hover style={{backgroundColor: '#FFF'}}>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Soubor</th>
                <th className="jsw-table-head">Popis</th>
                <th className="jsw-table-head">Velikost</th>
                <th className="jsw-table-head">Přidáno</th>
                <th className="jsw-table-head row-slim">Akce</th>
              </tr>
            </thead>
            <tbody>
              {filesTable}
            </tbody>
          </Table> 
      </>}
      {countData === 0 && props.noFilesInfo === true && <div>Nebyly nalezeny žádné soubory</div>}
    </>
  )  
  
}

export default withRouter(observer(FilesList))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Modal, Spinner } from 'react-bootstrap'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation"

import { useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

interface UpgradeOfferResetStateProps {
  id: string,
  label: string,
  showIcon: boolean,
  block: boolean
}

const UpgradeOfferEditState: React.FC<UpgradeOfferResetStateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  
  // Nastavení stavů pro zobrazení
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  const [showProg, setShowProg] = useState(false)

  // Připojení store
  const rootUpgradeStore = useUpgradeStore()
  
  const upgradeOffer = rootUpgradeStore.getUpgradeOfferById(props.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShowE(false)
    setShowProg(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  // eslint-disable-next-line
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
  }

  let isII:string = Object.keys(upgradeOffer?.offerItems!).length > 0 ? ' Dodatečně uplatněné individuální položky (slevy) budou zapomenuty!':''
  
  // Dotaz před smazáním položky
  const handleOnClickResetState = () => {
    (async () => {
      if (await confirm("Opravdu chcete objednávku "+upgradeOffer?.upgradeScenario.program.name+" "+upgradeOffer?.upgradeScenario.programVersion.version+" obnovit do původního stavu?" + isII,'Ano, obnovit', 'Ne', {title: 'Obnova objednávky do původního stavu'})) {
        resetState()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání kontaktu
  const resetState = () => {
    (async () => {
      setShowProg(true)
      
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }  
      
      let url = getUrl('URL_CUSTOMER_UPGRADE_OFFERS')+localStorage.getItem('openedCustomerId')!+'/offers/'+props.id+'/state/reset'

      if (rightsBH) url = getUrl('URL_OFFERS')+props.id+'/state/reset'
      
      // Zavolání API   
      await axios.post(url, {}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          (async () => {
            await rootUpgradeStore.getUpgradeOfferById(props.id)?.setData(response.data)
            const dataToSave = rootUpgradeStore.upgradeOffers.filter(data => data.id !== props.id).concat(response.data)
            await rootUpgradeStore.fetchUpgradeOffersFromData(dataToSave)
          
            // Po smazání také odebrat ze store
            //await rootUpgradeStore.fetchUpgradeOffersToCustomer(localStorage.getItem('openedCustomerId')!)
          await setShowProg(false)
          }) ()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowProg(false)
          setShowE(true)
        });
        
  
      })()    
  }

  
 
  return (
    <span>
      {rights &&<Button block={props.block} variant="outline-primary" size="sm" onClick={handleOnClickResetState}>{props.showIcon && <span><i className="fas fa-archive"></i>&nbsp;</span>}{props.label}</Button>}

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Obnova objednávky do původního stavu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={showProg} 
        onHide={handleClose} 
        //size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Obnova objednávky do původního stavu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spinner animation="border" variant="danger" />&nbsp;Probíhá obnova
        </Modal.Body>
      </Modal>
      
      
    </span>
  )  
  
}

export default withRouter(observer(UpgradeOfferEditState))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useEmailingStore, useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface EmailingBlackListUpdateProps {
  id: string,
  showAs: string,
}

const EmailingBlackListUpdate: React.FC<EmailingBlackListUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()

  let item = rootEmailingStore.getEmailingBlackListById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  const [customerDeepLoading, setCustomerDeepLoading] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    item = rootEmailingStore.getEmailingBlackListById(props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }

  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })
  
  // Načtení Store
  const rootStore = useStore()

  const customerListAllF = rootStore.customerListAll
  const [customers, setCustomers] = useState(customerListAllF.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  

   // Filtrování zákazníků v seznamu
   const filterCustomers = (value:string) => {
    //setCustomers(rootStore.getCustomerListAllByFilter(value))
    if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessName?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

      (async () => {
        setCustomerDeepLoading(true)
        await rootStore.fetchCustomerListAllNoCache(value,'',1,true)
        //setCustomers(rootStore.getCustomerListAllByFilter(value))
        console.log(localStorage.getItem('customerQuickSearchValue'))
        setCustomers(rootStore.getCustomerListAllByFilterWithoutICO(localStorage.getItem('customerQuickSearchValue')!))
        setCustomerDeepLoading(false)
      })();
    }
    else {
      setCustomers(rootStore.getCustomerListAllByFilterWithoutICO(value))
    }
  }

  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 

  // O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomer =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (customers[activeItem]?.accountNumber !== undefined) {
        setFieldValue('licenseNumber',customers[activeItem].accountNumber)
        setFieldValue("customerId",customers[activeItem].id)
        setFieldValue("customerName",customers[activeItem].businessName);
        //handleOld(customers[activeItem].accountNumber!)

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('licenseNumber','')
      setFieldValue("customerId",'')
      setFieldValue("customerName",'');
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customers && activeItem === customers.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  

  };



  // Funkce pro změnu adresy
  const handleUpdate = (emailData:string, reasonData:string, customerIdData:string, isVerifiedData:boolean) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend:any = {
        id: props.id, email: emailData, reason: reasonData, customerId: customerIdData, isVerified: isVerifiedData
      }

      if (customerIdData === '') {
        dataToSend = {
          id: props.id, email: emailData, reason: reasonData, isVerified: isVerifiedData
        } 
      }

      // Zavolání API
      
      await axios.post(getUrl('URL_EMAILING_BLACKLIST')+'/'+props.id, dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootEmailingStore.fetchEmailingBlackList( Number(sessionStorage.getItem('emailingBlackListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('emailingBlackListSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    email: Yup.string()
    .email('E-mailová adresa je ve špatném formátu!')
    .required('E-mail musí být vyplněn!')
    
  })   
  return (
    <span >
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava e-mailu v interním Blacklistu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>E-mail v interním Blacklistu byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                email: item?.email, 
                reason: item?.reason, 
                licenseNumber: customerListAllF.find(cl => cl.id === item?.customerId)?.accountNumber,
                customerId: item?.customerId,
                customerName: customerListAllF.find(cl => cl.id === item?.customerId)?.businessName,
                isVerified: item?.isVerified,
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.email!, values.reason!, values.customerId!, values.isVerified!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="email">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email!}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="licenseNumber">
                    <Form.Row>
                    <Form.Label column lg={3}>Zákazník</Form.Label>
                    <Col>

                    <FormControl 
                      type="text"
                      name="licenseNumber"
                      
                      value={values.licenseNumber!}
                      onChange={(e) => {handleChange(e);  state.displayItems = true;  state.activeItem = 0; localStorage.setItem('customerQuickSearchValue', e.target.value);    
                        //setCustomers(customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(e.target.value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(e.target.value!.toLowerCase())))
                          filterCustomers(e.target.value) 
                        }}
                      //tabIndex={1}
                      autoComplete="off" 
                      autoFocus={true}
                      isValid={touched.licenseNumber && !errors.licenseNumber} 
                      isInvalid={!!errors.licenseNumber}
                      onKeyDown={(e:any) => handleKeyDownCustomer(e, setFieldValue, 2)}
                      onBlur={(e:any) => {setTimeout(() => {setState({activeItem: 0, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue}); },200 ) }}
                    />
                    {values.licenseNumber! !== '' && state.displayItems ?
                        <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                            <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                            {
                                customers.map((dp,index) => {
                                    return (
                                        <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                            onClick={async (e:any) => {await setFieldValue("licenseNumber",dp.accountNumber); await setFieldValue("customerId",dp.id); await setFieldValue("customerName",dp.businessName); state.displayItems = false; }}
                                            key = {dp.id}
                                            >
                                            {dp.accountNumber} - {dp.businessName}
                                        </li>
                                    )
                                }).slice(0, 50)
                            }
                            </ul>
                        </div>
                        : null
                    }
                      <strong>{values.customerName}</strong>
                      <Form.Control.Feedback type="invalid">
                      {errors.licenseNumber}
                    </Form.Control.Feedback>
                      
                      </Col>

                    </Form.Row>
                  </FormGroup>
                  <FormGroup controlId="reason">
                    <Form.Row>
                      <FormLabel column lg={3}>Důvod</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="reason"
                          value={values.reason!}
                          onChange={handleChange}
                          isValid={touched.reason && !errors.reason} 
                          isInvalid={!!errors.reason}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.reason}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isVerified">
                    <Form.Row>
                      <FormLabel column lg={3}>Ověřeno správcem Blacklistu</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isVerified"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isVerified}
                          
                          isValid={touched.isVerified && !errors.isVerified} 
                          isInvalid={!!errors.isVerified}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isVerified}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                                   
                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(EmailingBlackListUpdate))

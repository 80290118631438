import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken } from '../../../Utils/Common'

import {Modal, Table, Spinner, Row, Col, Button} from 'react-bootstrap'

import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'


import SupportContactsListList from './Support.Contacts.List.part.List'
import SupportContactsListCard from './Support.Contacts.List.part.Card'
//import SupportContactsAdd from './Support.Contacts.Add.part'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'

import Pagination from 'react-js-pagination'

interface SupportContactsListProps {
  isCustomer?:boolean,
  customerId?:string,
  showLoader?:boolean    
}

const SupportContactsList: React.FC<SupportContactsListProps & RouteComponentProps> = (props:any) => {
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)

  // Načtení Store
  const rootStore = useStore()


  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  const customerListAllF = rootStore.customerListAll
 
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  
  
  // Seřazení seznamu podle data pořízení
  const contacts = rootStore.supports.slice().sort(function(a:any, b:any) {
    
    var sort1A = new Date(a.lastEditDate !== '' ? a.lastEditDate : a.createDate)
    var sort1B = new Date(b.lastEditDate !== '' ? b.lastEditDate : b.createDate)

    if (sort1A! < sort1B!) {
      
      
      return (localStorage.getItem('supportOrderBy') === 'LastEditDate' || localStorage.getItem('supportOrderBy') === null || localStorage.getItem('supportOrderBy') === undefined) ? -1 : 1;
    }
    if (sort1A! > sort1B!) {
      return (localStorage.getItem('supportOrderBy') === 'LastEditDate' || localStorage.getItem('supportOrderBy') === null || localStorage.getItem('supportOrderBy') === undefined) ? 1 : -1;
    }
    return 0;
  })
  
  //Počet záznamů
  const countData:number = Object.keys(contacts).length

    // Funkce pro změnu strany
    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('supportSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootStore.fetchSupports(pageNumber,props.isCustomer)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('supportOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('supportSearchPage', String(1))
        await rootStore.fetchSupports(1,props.isCustomer)
        setLoading(false);  
      })()
    }





    function exportToCsv() {
      let filename:string = 'kontakty-'+moment().format("YYYY-MM-DD-HH-mm")+'.csv'
      let dataToExport:string = 'Datum a čas;Číslo zákazníka;Název zákazníka;Jméno komunikující osoby;Předmět požadavku;Počet řešení;Stav;Odpovídá\n'
      
      contacts.forEach(contact => {
        let state:string = ''
        
        if (contact.isFinished === false) state = contact.deadLineDate !== '' ? new Date(contact?.deadLineDate!.replace(' ', 'T')) >= new Date() ? 'Nevyřešeno před termínem '+moment(contact.deadLineDate).format("DD.MM.YYYY HH:mm"):'Nevyřešeno po termínu '+moment(contact.deadLineDate).format("DD.MM.YYYY HH:mm"):'Nevyřešeno'
        
        dataToExport = dataToExport + moment(contact.lastEditDate !== '' ? contact.lastEditDate : contact.createDate).format("DD.MM.YYYY HH:mm")+';'
        dataToExport = dataToExport + contact.accountNumber+';'
        dataToExport = dataToExport + customerListAllF.find(cla => cla.id === contact.customerId)?.businessName+';'
          dataToExport = dataToExport + contact.businessName+';'
          dataToExport = dataToExport + contact.subject+';'
          dataToExport = dataToExport + String(contact.solutionCount)+';'
          // eslint-disable-next-line
          dataToExport = dataToExport + state +';'
          dataToExport = dataToExport + rootStore.hedgehogUsers.find(hu => hu.id === contact.solverUserId)?.fullName+'\n'
          //console.log(dataToExport)
        })
    
      const csvContent = "data:text/csv;charset=windows-1250,\uFEFF" + encodeURIComponent(dataToExport);
      const link = document.createElement("a");
      link.setAttribute("href", csvContent);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  return (
    <>
      
      
      

      <FilterPanel 
        isCustomer={props.isCustomer} 
        filterPage={'contacts'} 
        filterSearchArray='supportSearchArray' 
        pageVariable='supportSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['CustomerId','BusinessName','Subject','Request','CreateDate','LastEditDate','SupportTypeId','SupportThemeIds','IsTrouble','IsFinished','AuthorId','UserId','SolverUserId','IsDemo','Solutions.Text',"Solutions.Duration","Solutions.ToInvoice","Solutions.IsData","Solutions.SupportTypeId","Solutions.UserId","Solutions.AuthorId","Solutions.CreateDate","Duration","MyNotFinish","MyNotFinishAfterTerm","AllNotFinishAfterTerm"]}  
        title='Seznam kontaktů'
        titleCount={Number(localStorage.getItem('lastSupportTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        //secondaryButton={<SupportContactsAdd />}
        />



      <br />
      

      {(loading === true || props.showLoader === true ) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>
        <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam kontaktů neobsahuje žádné záznamy.<br /><br /></div>
      
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
        <SupportContactsListCard isCustomer={props.isCustomer} customerId={props.customerId}  />¨
          <br />
        </div>
        <div className="showWeb">
        <Table hover responsive>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head" style={{maxWidth: '150px', cursor: 'pointer'}} title="Datum poslední změny" onClick={() =>  handleOrderChange((localStorage.getItem('supportOrderBy') === 'LastEditDate' || localStorage.getItem('supportOrderBy') === null || localStorage.getItem('supportOrderBy') === undefined) ? 'LastEditDateDesc' : 'LastEditDate'  )}>
                  Datum a čas
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('supportOrderBy') === 'LastEditDate' || localStorage.getItem('supportOrderBy') === null || localStorage.getItem('supportOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('supportOrderBy') === 'LastEditDateDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head"></th>
                {props.isCustomer !== true &&<th className="jsw-table-head" style={{maxWidth: '120px'}} title="Číslo zákazníka">Číslo</th>}
                {props.isCustomer !== true &&<th className="jsw-table-head" style={{maxWidth: '300px'}} title="Název zákazníka">Název</th>}
                <th className="jsw-table-head" title="Jméno komunikující osoby">Jméno</th>
                <th className="jsw-table-head" title="Předmět požadavku">Předmět</th>
                <th className="jsw-table-head" title="Souhrnné informace o řešení">Řešení</th>
                <th className="jsw-table-head" title="Osoba odpovědná za vyřešení">Odpovídá</th>
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              <SupportContactsListList isCustomer={props.isCustomer} customerId={props.customerId}   />
              
            </tbody>
          </Table>
        </div> 
        <br />  
        <Row>
          <Col>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={Number(sessionStorage.getItem('supportSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('supportSearchPage')) }
              itemsCountPerPage={10}
              totalItemsCount={Number(localStorage.getItem('lastSupportTotalCount'))}
              pageRangeDisplayed={5}
              onChange={handlePageChange.bind(1)}
              />
          </Col>
          <Col md={3} style={{textAlign: 'right'}}>
            <Button onClick={() => exportToCsv()} variant="outline-secondary" size="sm" >.csv</Button>
          </Col>
        </Row>
      </div>  
      </>}

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání kontaktu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      
      
    </>
  )  
  
}

export default withRouter(observer(SupportContactsList))

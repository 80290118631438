import { types } from 'mobx-state-tree'

export const Packets = types
  .model({
    id: types.optional(types.string, ''),
    userId: types.maybeNull(types.string),
    submissionId: types.maybeNull(types.string),
    technologicalNumber: types.maybeNull(types.string),
    productId: types.maybeNull(types.string),
    packetCode: types.maybeNull(types.string),
    packetNumber: types.maybeNull(types.number),
    amount: types.optional(types.number,0),
    weight: types.optional(types.number,0),
    insuredValue: types.optional(types.number,0),
    currency: types.maybeNull(types.string),
    notePrint: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    invoiceNumber: types.maybeNull(types.number),
    customerId: types.maybeNull(types.string),
    services: types.maybeNull(types.array(types.string)),
    returnDate: types.maybeNull(types.string),
    returnNote: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    surName: types.maybeNull(types.string),
    titleAfter: types.maybeNull(types.string),
    aditionInfo: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    houseNumber: types.maybeNull(types.string),
    sequenceNumber: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    cityPart: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
    isoCountry: types.maybeNull(types.string),
    mobileNumber: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    emailAddress: types.maybeNull(types.string),
    linkId: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

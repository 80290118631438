import { types } from 'mobx-state-tree'



//Customer
const Customer = types.model('Customer',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  companyRegistrationNumber: types.optional(types.string, ''),
  taxIdentificationNumber: types.optional(types.string, ''),
  isTaxAble: types.optional(types.boolean, false),
  isActive: types.optional(types.boolean, false)
})

//Scénář
const SProgram = types.model('SProgram',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const SProgramVersion = types.model('SProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

const UpgradeScenario = types.model('UpgradeScenario',{
  id: types.optional(types.string, ''),
  isActive: types.optional(types.boolean, false),
  program: types.frozen(SProgram),
  programVersion: types.frozen(SProgramVersion),
  })





// SAMOTNÁ NABÍDKA
export const UpgradeOffersList = types
  .model({
    id: types.optional(types.string, ''),
    customer: types.frozen(Customer), 
    distributionMethod: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    totalPrice: types.maybeNull(types.number),
    vat: types.maybeNull(types.number),
    vatBase: types.maybeNull(types.number),
    upgradeScenario: types.frozen(UpgradeScenario),

  })
  .views(self => ({
    
  }))
  .actions(self => ({
    setData(data: any) {
      self = data
      
    }

  }))

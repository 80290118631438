import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { observer } from 'mobx-react'

interface DistributionMethodProps {
      
}

const SelectOptionsDistributionMethod: React.FC<DistributionMethodProps & RouteComponentProps> = (props:any) => {
  
  return (
    <>
      <option value="D">Dobírka</option>
      <option value="E">Elektronicky</option>
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsDistributionMethod))
import { types } from 'mobx-state-tree'

export const CustomerOpenedEmailsSent = types
  .model({
    id: types.optional(types.string, ''),
    templateId: types.maybeNull(types.string),
    templateName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    sendDate: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
    userFirstName: types.maybeNull(types.string),
    userSurName: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
    customerId: types.maybeNull(types.string),
    customerBusinessName: types.maybeNull(types.string)
  })
  .views(self => ({
    get userFullName() {
      if (self.userFirstName !== null) {
        if (self.userSurName !== null) {
          return self.userFirstName + ' ' + self.userSurName
        }
        else {
          return self.userSurName
        }
      }
      else {
        if (self.userSurName !== null) {
          return self.userSurName
        }
        else {
          return ''
        }
      }
      
    }
  }))
  .actions(self => ({
    

  }))

import { types } from 'mobx-state-tree'

const Service = types.model('Module',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})
 
export const DialsPriceListsServices = types
  .model({
    id: types.optional(types.string, ''),
    price: types.maybeNull(types.number),
    service: types.frozen(Service),
    validFrom: types.optional(types.string, ''),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken } from '../../../../Utils/Common'
// eslint-disable-next-line
import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Table, Card } from 'react-bootstrap'
// eslint-disable-next-line
import { useEmailingStore, useStore } from '../../../../store'
import { observer } from 'mobx-react'

import FilterPanel from '../../../../sharedComponents/filterPanel/FilterPanel.part'
import Pagination from 'react-js-pagination'

import SupportContactsCustomerInfo from '../../../support/components/Support.Contacts.CustomerInfo.part'

interface DatafiltersCustomersCustomListPreviewProps {
  id?:string,
  designer?:boolean,
  name?:string,
  note?:string,
  asLink?:boolean,
  linkText?:string, 
  setPageLoading?:any,
  //block?: boolean,
  marginTop?: string,
  title?: string
}

const DatafiltersCustomersCustomListPreview: React.FC<DatafiltersCustomersCustomListPreviewProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('Emailing')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  const [loadingData, setLoadingData] = useState(false)

  const [showDetail, setShowDetail] = useState(false)
  const [stateNewFilter, setStateNewFilter] = useState(true)

  type CustomersSearchArray = {filterName: string, filterValue: string, displayValue: string}
  //eslint-disable-next-line
  let customersSearchArray = new Array<CustomersSearchArray>()
    
  // Připojení store
  const rootEmailingStore = useEmailingStore()
  //const rootStore = useStore()
  //const rootDialsStore = useDialsStore()

  // Nastavení editivaného kontaktu
  const dataSet = rootEmailingStore.datafiltersCustomersDesignerPreview
  

  
  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    sessionStorage.removeItem('autoCloseModal')
    
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    localStorage.removeItem('lastDatafiltersCustomersCustomPreviewSearchTotalCount')
    localStorage.removeItem('datafiltersCustomersCustomPreviewSearchArray')
    sessionStorage.removeItem('datafiltersCustomersCustomPreviewSearchPage')

    setLoading(true)
    setLoadingData(true)


    setShowDetail(true)
    //if (Object.keys(rootStore.customerListAll).length < 100) await rootStore.fetchCustomerListAll('', '', 1)
    //await rootStore.fetchPacketsForSubmission(props.fromExternal === true ? submissionId : props.submissionId,1)

    if (props.designer === true) {
      await rootEmailingStore.fetchDatafiltersCustomersDesignerPreview(1,rootEmailingStore.getEmailingConditionsToFinalString(),null)
    } else {
      await rootEmailingStore.fetchDatafiltersCustomersDesignerPreview(1,'',props.id)
    }
    setLoadingData(false)
    setLoading(false)

}



const handlePageChange = (pageNumber:number) => {
  (async () => { 
    setLoadingData(true);
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    sessionStorage.setItem('datafiltersCustomersCustomPreviewSearchPage', String(pageNumber))
    // Předat hledanou hodnotu action pro načtení zákazníků
    //await rootStore.fetchPacketsForSubmission(props.submissionId, pageNumber)
    await rootEmailingStore.fetchDatafiltersCustomersDesignerPreview(pageNumber,rootEmailingStore.getEmailingConditionsToFinalString(),props.designer === true ? null : props.id)
    setLoadingData(false);  
  })()
}
//eslint-disable-next-line
const handleOrderChange = (order:string) => {
  (async () => { 
    setLoadingData(true);
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    localStorage.setItem('datafiltersCustomersCustomPreviewOrderBy', order)
    // Předat hledanou hodnotu action pro načtení zákazníků
    sessionStorage.setItem('datafiltersCustomersCustomPreviewSearchPage', String(1))
    //await rootStore.fetchPacketsForSubmission(props.submissionId, 1)
    await rootEmailingStore.fetchDatafiltersCustomersDesignerPreview(1,rootEmailingStore.getEmailingConditionsToFinalString(),props.designer === true ? null : props.id)
    setLoadingData(false);  
  })()
}

const dataCount:number = Object.keys(dataSet).length 

const dataListTable = dataSet.map((item) => (
  <tr key={item.id}>
    <td>
      {item.accountNumber}
      </td>
    <td>
      <SupportContactsCustomerInfo customerId={item.id} isActiveCustomer={item.isActive} customerName={item.businessName} customerNameFull={item.businessName}  /> 
      
    </td>
    <td>{item.companyRegistrationNumber}</td>
    <td>
      {item.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}
    </td>
    <td></td>
  </tr>
)) 


const dataListCard = dataSet.map((item) => (
  <div key={item.id}>
  <Card>
      <Card.Body>
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{item.businessName}</strong><br />
          <Badge variant="info">Číslo zákazníka: {item.accountNumber}</Badge>
          <br />
          {item.isActive === false ? <Badge variant="danger">Neaktivní</Badge>:<Badge variant="info">Aktivní</Badge>}
          <br />(<i>
          IČO: {item.companyRegistrationNumber}
          </i>)
        </div>
        <div className="float-right" style={{textAlign: 'right'}}>
          <ButtonGroup>
            
          </ButtonGroup>  
        </div>
      </Card.Body>
    </Card>
  <br />
  </div>
))

const dataList = <>
        <div className="showMobile">
          {dataListCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head row-slim">Zákazník</th>
                <th className="jsw-table-head">Název</th>
                <th className="jsw-table-head">IČO</th>
                <th className="jsw-table-head">Stav</th>
                <th className="jsw-table-head">Akce</th>

              </tr>
            </thead>
            <tbody>
              {dataListTable}
            </tbody>
          </Table>
        </div> 
        <br />  

</>



// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const dataPreview = 
      <>

          {showDetail &&<>
          <div>

            <Row>
              <Col lg={{span: 9, order :1}}>
                <strong>Název filtru:</strong>&nbsp;{props.name}<br />
                <strong>Poznámka:</strong>&nbsp;{props.note}
              </Col>
              
            </Row>

            
            <hr />

            <FilterPanel 
              //isCustomer={props.isCustomer} 
              filterPage={'datafiltersCustomersCustomPreview'} 
              filterSearchArray='datafiltersCustomersCustomPreviewSearchArray' 
              pageVariable='datafiltersCustomersCustomPreviewSearchPage' 
              setPageLoading={setLoadingData} 
              filterItems={['BusinessName2','AccountNumber','CompanyRegistrationNumber']}  
              title="Seznam zákazníků"
              titleCount = {Number(localStorage.getItem('lastDatafiltersCustomersCustomPreviewSearchTotalCount')!)}
              stateNewFilter={stateNewFilter}
              setStateNewFilter={setStateNewFilter}
              masterId={props.designer === true ? null : props.id}
              //secondaryLeftButtons={}
              />

              <br />
            
            {loadingData === true 
              ? 
              <div><Spinner animation='border'  />&nbsp;<br /></div> 
              :
              dataCount === 0 ? <div>Seznam zákazníků neobsahuje žádné záznamy.<br /><br /></div> : dataList }  
                
             

            <br />  
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={Number(sessionStorage.getItem('datafiltersCustomersCustomPreviewSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('datafiltersCustomersCustomPreviewSearchPage')) }
              itemsCountPerPage={10}
              totalItemsCount={Number(localStorage.getItem('lastDatafiltersCustomersCustomPreviewSearchTotalCount'))}
              pageRangeDisplayed={5}
              onChange={handlePageChange.bind(1)}
              /> 
          
          </div>
          </>}

          </>



return ( 
  <>
  {props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Náhled výsledku</Dropdown.Item>
      </>
      :
      props.asLink === true 
        ? 
        <span className='as-link-no-color' title={'Náhled výsledku '+props.linkText} onClick={() => {startSequence()}}>{props.linkText}</span> 
        : 
        <>
          <Button variant='outline-primary' block style={{marginTop: props.marginTop !== undefined ? props.marginTop : '0px'}} size="sm" onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Náhled výsledku' ><i className="fas fa-search"></i>&nbsp;</span>{props.title !== undefined ? props.title : 'Náhled výsledku'}</Button>
        </>
      
    }
  

    <Modal 
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Náhled výsledku uživatelského filtru
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {dataPreview}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(DatafiltersCustomersCustomListPreview))

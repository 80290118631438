import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getDataFiltersNewProgramId } from '../../../Utils/Common';

import CustomersOtherActionsUpgradeOpenDocument from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeOpenDocument'
import CustomersOtherActionsUpgradeElectronicSendTestMailProforma from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicSendTestMailProforma.part'
import CustomersOtherActionsUpgradeElectronicSendTestMailFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicSendTestMailFinalDocuments.part'
//import CustomersOtherActionsUpgradeCashSendTestMailFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashSendTestMailFinalDocuments.part'
import CustomersOtherActionsUpgradeFreeSendTestMailFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeSendTestMailFinalDocuments.part'
import CustomersOtherActionsUpgradeElectronicUnpublishProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishProformas.part'
import CustomersOtherActionsUpgradeElectronicDeleteProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeleteProformas.part'
import CustomersOtherActionsUpgradeElectronicDeletePublishedProformas from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeletePublishedProformas.part'
import CustomersOtherActionsUpgradeElectronicDeletePayment from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeletePayment.part'
import CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicDeleteFinalDocuments.part'
import CustomersOtherActionsUpgradeFreeDeleteFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeDeleteFinalDocuments.part'

import CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeElectronicUnpublishFinalDocuments.part'
import CustomersOtherActionsUpgradeFreeUnpublishFinalDocuments from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeFreeUnpublishFinalDocuments.part'

import CustomersOtherActionsUpgradeCashFinalize from '../../../parts/customersOtherActions/Customers.OtherActions.UpgradeCashFinalize.part'

import SubmissionsDetail from '../../../pages/submissions/components/Submissions.Detail.part'

//import { useStore } from '../../../store'
import { observer } from 'mobx-react'

//import { useUpgradeStore } from '../../../store'

import {  Dropdown } from 'react-bootstrap'

interface CustomersSingleActionsProps {
  id: string,
  offerId: string,
  recordId: string,
  recordId2: string,
  recordId3: string,
  infoText: string,
  customerId: string|undefined|null,
  packetId?: string,
}

const CustomersSingleActions: React.FC<CustomersSingleActionsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
    
  // Připojení store
  //const rootUpgradeStore = useUpgradeStore()
  //const upgradeScenarioId:string = rootUpgradeStore.upgradeOffersScenarios.find(uos => uos.program.id === getDataFiltersNewProgramId(props.id))?.id!
  const upgradeScenarioId:string = getDataFiltersNewProgramId(props.id)

  return (
    <>    
    {rights &&<>
          
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='konečnou fakturu' />}
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId2} customerId={props.customerId} showAs='drop' info='licenční lístek' />}
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<CustomersOtherActionsUpgradeCashFinalize mode='selected' id={props.recordId} showAs='drop' filterId={props.id} info={props.infoText} />}
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<Dropdown.Divider />}
          {(props.id === '1002' || props.id === '1008' || props.id === '1014') &&<CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments mode='selected' id={props.recordId} id2={props.recordId2} id3={props.recordId3} showAs='drop' filterId={props.id} info={props.infoText} />}

          {(props.id === '1003' || props.id === '1009' || props.id === '1015') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='konečnou fakturu' />}
          {(props.id === '1003' || props.id === '1009' || props.id === '1015') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId2} customerId={props.customerId} showAs='drop' info='licenční lístek' />}

          {(props.id === '1004' || props.id === '1010' || props.id === '1016') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='konečnou fakturu' />}
          {(props.id === '1004' || props.id === '1010' || props.id === '1016') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId2} customerId={props.customerId} showAs='drop' info='licenční lístek' />}

          {(props.id === '1005' || props.id === '1011' || props.id === '1017') &&<CustomersOtherActionsUpgradeCashFinalize mode='selected' id={props.recordId} showAs='drop' filterId={props.id} info={props.infoText} />}
          {(props.id === '1005' || props.id === '1011' || props.id === '1017') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='konečnou fakturu' />}
          {(props.id === '1005' || props.id === '1011' || props.id === '1017') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId2} customerId={props.customerId} showAs='drop' info='licenční lístek' />}
          {(props.id === '1005' || props.id === '1011' || props.id === '1017') &&<SubmissionsDetail fromExternal={true} packetId={props.packetId} fromSubMenu={true}  />}

          {(props.id === '1006' || props.id === '1012' || props.id === '1018') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='konečnou fakturu' />}
          {(props.id === '1006' || props.id === '1012' || props.id === '1018') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId2} customerId={props.customerId} showAs='drop' info='licenční lístek' />}
          {(props.id === '1006' || props.id === '1012' || props.id === '1018') &&<Dropdown.Divider />}
          {(props.id === '1006' || props.id === '1012' || props.id === '1018') &&<CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments mode='selected' id={props.recordId} id2={props.recordId2} id3={props.recordId3} showAs='drop' filterId={props.id} info={props.infoText} />}


          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='zálohovou fakturu' />}
          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeElectronicSendTestMailProforma mode='selected' id={props.recordId} upgradeScenarioId={upgradeScenarioId} filterId={props.id} showAs='drop' info={props.infoText} />}
          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<Dropdown.Divider />}
          {(props.id === '1020' || props.id === '1026' || props.id === '1032') &&<CustomersOtherActionsUpgradeElectronicDeleteProformas mode='selected' filterId={props.id} id={props.recordId} showAs='drop' info={props.infoText} />}

          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='zálohovou fakturu' />}
          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicSendTestMailProforma mode='selected' id={props.recordId} upgradeScenarioId={upgradeScenarioId} filterId={props.id} showAs='drop' info={props.infoText} />}
          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<Dropdown.Divider />}
          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicUnpublishProformas mode='selected' id={props.recordId} showAs='drop' filterId={props.id} info={props.infoText} />}
          {(props.id === '1021' || props.id === '1027' || props.id === '1033') &&<CustomersOtherActionsUpgradeElectronicDeletePublishedProformas mode='selected' filterId={props.id} id={props.recordId} showAs='drop' info={props.infoText} />}

          {(props.id === '1022' || props.id === '1028' || props.id === '1034') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='zálohovou fakturu' />}
          {(props.id === '1022' || props.id === '1028' || props.id === '1034') &&<CustomersOtherActionsUpgradeElectronicSendTestMailProforma mode='selected' id={props.recordId} upgradeScenarioId={upgradeScenarioId} filterId={props.id} showAs='drop' info={props.infoText} />}
          {(props.id === '1022' || props.id === '1028' || props.id === '1034') &&<Dropdown.Divider />}
          {(props.id === '1022' || props.id === '1028' || props.id === '1034') &&<CustomersOtherActionsUpgradeElectronicDeletePayment mode='selected' filterId={props.id} id={props.recordId} showAs='drop' info={props.infoText} />}

          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='konečnou fakturu' />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId2} customerId={props.customerId} showAs='drop' info='licenční lístek' />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId3} customerId={props.customerId} showAs='drop' info='daňový doklad na přijatou platbu' />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeElectronicSendTestMailFinalDocuments mode='selected' id={props.recordId} upgradeScenarioId={upgradeScenarioId} filterId={props.id} showAs='drop' info={props.infoText} />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<Dropdown.Divider />}
          {(props.id === '1023' || props.id === '1029' || props.id === '1035') &&<CustomersOtherActionsUpgradeElectronicDeleteFinalDocuments mode='selected' id={props.recordId} id2={props.recordId2} id3={props.recordId3} showAs='drop' filterId={props.id} info={props.infoText} />}
          

          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='konečnou fakturu' />}
          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId2} customerId={props.customerId} showAs='drop' info='licenční lístek' />}
          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId3} customerId={props.customerId} showAs='drop' info='daňový doklad na přijatou platbu' />}
          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<CustomersOtherActionsUpgradeElectronicSendTestMailFinalDocuments mode='selected' id={props.recordId} upgradeScenarioId={upgradeScenarioId} filterId={props.id} showAs='drop' info={props.infoText} />}
          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<Dropdown.Divider />}
          {(props.id === '1024' || props.id === '1030' || props.id === '1036') &&<CustomersOtherActionsUpgradeElectronicUnpublishFinalDocuments mode='selected' id={props.recordId} id2={props.recordId2} id3={props.recordId3} showAs='drop' filterId={props.id} info={props.infoText} />}

          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='licenční lístek' />}
          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<CustomersOtherActionsUpgradeFreeSendTestMailFinalDocuments mode='selected' id={props.recordId2 !== '' ? props.recordId2:props.recordId} upgradeScenarioId={upgradeScenarioId} filterId={props.id} showAs='drop' info={props.infoText} />}
          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<Dropdown.Divider />}
          {(props.id === '1038' || props.id === '1041' || props.id === '1044') &&<CustomersOtherActionsUpgradeFreeDeleteFinalDocuments mode='selected' id={props.recordId} id2={props.recordId2} id3={props.recordId3} showAs='drop' filterId={props.id} info={props.infoText} />}

          {(props.id === '1039' || props.id === '1042' || props.id === '1045') &&<CustomersOtherActionsUpgradeOpenDocument id={props.recordId} customerId={props.customerId} showAs='drop' info='licenční lístek' />}
          {(props.id === '1039' || props.id === '1042' || props.id === '1045') &&<CustomersOtherActionsUpgradeFreeSendTestMailFinalDocuments mode='selected' id={props.recordId2 !== '' ? props.recordId2:props.recordId} upgradeScenarioId={upgradeScenarioId} filterId={props.id} showAs='drop' info={props.infoText} />}
          {(props.id === '1039' || props.id === '1042' || props.id === '1045') &&<Dropdown.Divider />}
          {(props.id === '1039' || props.id === '1042' || props.id === '1045') &&<CustomersOtherActionsUpgradeFreeUnpublishFinalDocuments mode='selected' id={props.recordId} id2={props.recordId2} id3={props.recordId3} showAs='drop' filterId={props.id} info={props.infoText} />}

          

        </>}
    </>    
  )  
  
}

export default withRouter(observer(CustomersSingleActions))

import React, {useState}  from 'react'
import Loader from "react-loader"
//import { ROUTES } from '../../../Routes'
//import { NavLink } from 'react-router-dom'

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'
import CustomerHeader from '../../../parts/customerHeader/CustomerHeader.parts'
import CustomersDocumentsList from './components/Customers.Documents.List.part'
import CustomersDocumentsListArchive from './components/Customers.Documents.List.Archive.part'

import { Container, Button } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
// eslint-disable-next-line
import { useStore,useDialsStore, useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'


function Documents(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);

  // Nastavení práv zda jsme Ježci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  // eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  //const rightsBU:boolean = ((localStorage.getItem('BigUser')! === '1')) ? true : false

  // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
  if (!localStorage.getItem('openedCustomerId')) {
    props.history.push('/customers-open')
  }

  // Načtení Store

  const rootUpgradeStore = useUpgradeStore()
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()

  //rootUpgradeStore.fetchUpgradeOffersScenarios()
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'customers-documents')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      //Načtení souvisejících číselníků podle práv
      //if (rightsBH) await rootStore.fetchTemplates() 
      //if (rightsBH) await rootDialsStore.fetchDialsCategories()
      //if (rightsBH) await rootUpgradeStore.fetchUpgradeOffersScenarios()
      await rootUpgradeStore.fetchUpgradeOffersScenariosAll()
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          //await rootStore.fetchCustomerOpened()
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      } 
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { 
          await rootStore.fetchCustomerOpened()
        }
      }  

      //Pokud mám práva tak vždy načítám další položky 
      await rootStore.fetchCustomerOpenedDocuments(localStorage.getItem('openedCustomerId')!)
      
      rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive && await rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)



    }
    /*if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    */ 
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  // Funkce pro zobrazení nebo skrytí detailních cen
  const showHide = (id:string) => {
    let el=document.getElementById("detail"+id)?.style!; 
    el.display=(el.display === 'block')?'none':'block';

    let elBS=document.getElementById("buttonShow"+id)?.style!;
    elBS.display = (el.display === 'block')?'none':'block'

    let elBH=document.getElementById("buttonHide"+id)?.style!;
    elBH.display = (el.display === 'block')?'block':'none'
    
  }

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <CustomerHeader />
              <Container fluid>
                <h4>Aktuální dokumenty</h4>
                <br />
                <CustomersDocumentsList /><br /><br />

                <Button variant='secondary' style={{display: 'block'}} id={"buttonShowA"} onClick={() => {showHide("A")}}><i className="fas fa-angle-double-down"></i>&nbsp;Zobrazit archiv dokumentů</Button>
                <Button variant='secondary' style={{display: 'none'}}  id={"buttonHideA"} onClick={() => {showHide("A")}}><i className="fas fa-angle-double-up"></i>&nbsp;Skrýt archiv dokumentů</Button>
                <br />
                <div id='detailA' style={{display: 'none'}}>
                <h4>Archiv dokumentů</h4>
                <br />
                <CustomersDocumentsListArchive />

                </div>
                <br /><br />
                
                <Button className="nodisplay" size="sm" onClick={async () => {await rootStore.downloadFileFromApi('zalohovka.pdf','39bf76d2-8ba6-41a1-aa46-cb340ee96821.pdf');}}>Test</Button>
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(Documents)

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal, Row } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface OthersTemplateUpdateProps {
  id: string,
  showAs: string,
}

const OthersTemplateUpdate: React.FC<OthersTemplateUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('Emailing')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  let template = rootStore.getTemplateById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    await rootStore.fetchTemplateById(props.id)
    template = rootStore.getTemplateById(props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (subjectData:string, nameData:string, plainTextData:string, htmlTextData:string, isActiveData:boolean) => {
    setError(null);
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_TEMPLATES')+'/'+props.id, { id: props.id, name: nameData, subject: subjectData, plainText: plainTextData, htmlText: htmlTextData, isActive: isActiveData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootStore.fetchTemplatesNew( Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage')))
          //rootStore.fetchTemplates(sessionStorage.getItem('templatesSearchValue')!, sessionStorage.getItem('templatesSearchTypeValue')!, sessionStorage.getItem('templatesSearchIsActiveValue')! === '0' ? false : true, sessionStorage.getItem('templatesSearchWithSystemValue')! === '0' ? false : true, 10,  Number(sessionStorage.getItem('templatesSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('templatesSearchPage'))) 

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    name: Yup.string()
    .required('Název šablony musí být vyplněn!'),
    subject: Yup.string()
    .required('Předmět je nutné vyplnit!'),
    htmlText: Yup.string().when(['plainText'], {
      is: (plainText) => {
        return !(plainText)
      },
      then: Yup.string().required('Pokud není zadána textová šablona, musíte zadat html šablonu.')
    }),
  })   
  return (
    <span >
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90wf"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava šablony e-mailu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Šablona e-mailu byla úspěšně upravena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                subject: template?.subject, 
                name: template?.name,
                plainText: template?.plainText !== null ? template?.plainText : '',
                htmlText: template?.htmlText !== null ? template?.htmlText : '',
                isActive: template?.isActive, 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.subject!, values.name!, values.plainText!, values.htmlText!, values.isActive!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6} style={{marginBottom: '10px'}}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="subject">
                    <Form.Row>
                      <FormLabel column lg={3}>Předmět</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl 
                          type="text"
                          name="subject"
                          value={values.subject}
                          onChange={handleChange}
                          isValid={touched.subject && !errors.subject} 
                          isInvalid={!!errors.subject}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.subject}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="plainText">
                    <Form.Row>
                      <FormLabel column lg={3}>Textový obsah</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl as="textarea" style={{height: "150px"}}
                          type="text"
                          name="plainText"
                          value={values.plainText}
                          onChange={handleChange}
                          isValid={touched.plainText && !errors.plainText} 
                          isInvalid={!!errors.plainText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.plainText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>           
                  <FormGroup controlId="htmlText">
                    <Form.Row>
                      <FormLabel column lg={3}>HTML obsah</FormLabel>
                      
                    </Form.Row> 
                    <Form.Row >
                      <Col>
                        <FormControl as="textarea" style={{height: "450px"}} 
                          type="text"
                          name="htmlText"
                          value={values.htmlText}
                          onChange={handleChange}
                          isValid={touched.htmlText && !errors.htmlText} 
                          isInvalid={!!errors.htmlText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.htmlText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup> 
                  <FormGroup controlId="isActive">
                    <Form.Row>
                      <FormLabel column lg={3}>Aktivní</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isActive"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isActive}
                          
                          isValid={touched.isActive && !errors.isActive} 
                          isInvalid={!!errors.isActive}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isActive}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                    
                  {error}
                  <br />
                  <strong>Proměnné:</strong><br /> {template?.variables !== null && template?.variables.map((item, index)=> { return (<span key={index}>{item}<br /></span>) })}
                  {!(template?.variables! === undefined || template?.variables! === null) && Object.keys(template?.variables!).length === 0 && <>Není možné použít žádné proměnné.<br /></>}
                  <br />
                  

                  </Col>
                  <Col>
                  <strong>Náhled:</strong>
                    <div className="content" dangerouslySetInnerHTML={{__html: values?.htmlText!}}></div>
                  </Col>
                  </Row>
                  <br /><br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(OthersTemplateUpdate))

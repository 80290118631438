import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'



import { useDialsStore } from '../../../store'
import { observer } from 'mobx-react'



interface SupportThemesProps {
      
}

const SelectOptionsSupportThemes: React.FC<SupportThemesProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootDialsStore = useDialsStore()

 

  // Seznam typů kontaktu pro rozevírací seznam
  const supportThemes = rootDialsStore.dialsSupportThemes
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.name === 'Stereo' ? 2 : a.name === 'Duel' ? 1 : 999; 
    var sort1B = b.name === 'Stereo' ? 2 : b.name === 'Duel' ? 1 : 999; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .filter(dst => dst.isActive === true)
  .map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  ))  

  

  //const datum = new Date().toISOString()
  return (
    <>
      {supportThemes}
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsSupportThemes))

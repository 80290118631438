import React  from 'react'
import {Card} from 'react-bootstrap'

import { Link, withRouter } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import NumberFormat from 'react-number-format'

import { RouteComponentProps } from 'react-router-dom'

interface OverviewsCustomersProps {
  mode:string
}

const OverviewsCustomers: React.FC<OverviewsCustomersProps & RouteComponentProps> = (props:any) => {
 
  // Načtení Store
  const rootStore = useStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  //const overviewsCustomers = rootStore.overviewsCustomer

  //PRoměnná s daty podle parametru
  let filterIds = [1,2,3,4,5,6]

  if (props.mode === 'onlyCustomers') {
    filterIds = [1,2,3,4,5]
  }

  if (props.mode === 'onlyOffers') {
    filterIds = [6]
  }

  // Seznam přehledů do řádků karty
  const overviewsCustomersData = rootStore.overviewsCustomer.filter(oc => filterIds.includes(oc.filterId)).map(overviewsCustomers => (
    <div key={overviewsCustomers.filterId}>
      <div className="float-left">
        <Link to={ROUTES.overviewsDetail.getLink(overviewsCustomers.filterId)}>
        {overviewsCustomers.category === 'customers' ? '': ''}
        {overviewsCustomers.category === 'users' ? 'Uživatelé - ': ''}
        {overviewsCustomers.filterId === 1 ? 'Pozvaní - neregistrovaní':''}
        {overviewsCustomers.filterId === 2 ? 'Dosud nepozvaní':''}
        {overviewsCustomers.filterId === 3 ? 'Registrovaní':''}
        {overviewsCustomers.filterId === 4 ? 'Registrovaní - bez aktivace':''} 
        {overviewsCustomers.filterId === 5 ? 'Neaktivní':''} 
        {overviewsCustomers.filterId === 6 ? 'Aktivní - bez objednávek upgrade':''} 
        </Link>
        
      </div>
      <div className="float-right">
        <NumberFormat displayType={'text'} thousandSeparator={' '} value={overviewsCustomers.count} />
      </div>          
      <div className="cleaner divide"></div>
    </div>
  ))
  
  return (
    <Card
      bg='light'
      text='dark'
      
    >
      <Card.Header  as="h5">Zákazníci</Card.Header>
      <Card.Body>
        {overviewsCustomersData}
          
        
      </Card.Body>
    </Card> 
  )  
  
}

export default withRouter(observer(OverviewsCustomers))

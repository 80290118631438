import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUser } from '../../../../Utils/Common'

import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { getUrl } from '../../../../Utils/Common'
//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

const AccountsMyAccountUpdate: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  
  // Připojení store
  const rootStore = useStore()
  const loggedUsers = rootStore.loggedUsers
  const loggedUser = rootStore.getLoggedUserById(loggedUsers[0]?.id)
  const customerUser = rootStore.getCustomerUserById(loggedUsers[0]?.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    window.location.reload()
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Funkce pro změnu hesla
  const handleUpdate = (surNameData:string, firstNameData:string, phoneData:string) => {
    setError(null)
    setLoading(true)
    const user = getUser();

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
    
    await axios.patch(getUrl('URL_ACCOUNT')+user.id, { id: user.id , surName: surNameData, firstName: firstNameData, phone: phoneData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        
        localStorage.setItem('userNameMenu', firstNameData+' '+surNameData)

        // Změna dat ve Store
        loggedUser?.setSurName(surNameData)
        loggedUser?.setFirstName(firstNameData)
        loggedUser?.setPhone(phoneData)

        customerUser?.setSurName(surNameData)
        customerUser?.setFirstName(firstNameData)
        customerUser?.setPhone(phoneData)
        
        // Nastavovačky, co se má v případě, že je to OK, zobrazit
        setLoading(false)
        setShowForm('nodisplay')
        setShowInfo(true)
        sessionStorage.setItem('autoCloseModal','1');

        // Po 2800 ms celé modální okno zavřeme
        setTimeout(() => {
          if (sessionStorage.getItem('autoCloseModal') === '1') {  
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            window.location.reload()
            //setShowInfo(false)
            //setShowForm('')
          }
        }, 2800);

      }).catch(error => {
        setLoading(false)
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
      });
      

    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    surName: Yup.string()
    .required('Příjmení musí být vyplněno!'),
    firstName: Yup.string()
    .required('Jméno musí být vyplněno!'), 
    phone: Yup.string()
    .matches(/^([0-9+ ]{1,100})$/, 'Telefonní číslo může obsahovat pouze číslice, mezeru a znak +')
    .max(17, 'Telefon může obsahovat maximálně 17 znaků.'),
  })   
  return (
    <>

      <Button variant="primary" size="sm" onClick={handleShow}>
        Upravit
      </Button>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava údajů</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Osobní údaje byly úspěšně upraveny!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ surName: loggedUsers[0]?.surName, firstName: loggedUsers[0]?.firstName, phone: loggedUsers[0]?.phone }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.surName, values.firstName, values.phone)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="firstName">
                    <Form.Row>
                      <FormLabel column lg={4}>Jméno</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          
                          isValid={touched.firstName && !errors.firstName} 
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="surName">
                    <Form.Row>
                      <FormLabel column lg={4}>Příjmení</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="surName"
                          value={values.surName}
                          onChange={handleChange}
                          
                          isValid={touched.surName && !errors.surName} 
                          isInvalid={!!errors.surName}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.surName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="phone">
                    <Form.Row>
                      <FormLabel column lg={4}>Telefon</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="phone"
                          
                          value={values.phone}
                          onChange={handleChange}
                          isValid={touched.phone && !errors.phone} 
                          isInvalid={!!errors.phone}  
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(AccountsMyAccountUpdate))

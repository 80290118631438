import React from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom'

import { ROUTES } from '../../Routes'
import { Navbar,NavDropdown,Button, Form } from 'react-bootstrap'

//import { useStore } from '../../store'
import { observer } from 'mobx-react'

function TopMenu(props:any) {
    // Připojení store
    //const rootStore = useStore();

    // Nastavení práv zda jsme Ježci
    const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
    
    const companyToShow:string = localStorage.getItem('userNameMenu') === undefined ? 'nepřihlášen' : localStorage.getItem('userNameMenu')!

    //var company:string | undefined = localStorage.getItem('userNameMenu')!
    
    /*if (localStorage.getItem('openedCustomerId') !== null ) {
      const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)   
      company = customer?.name
    }
    */
    // var companyToShow:string  = company === undefined ? 'nepřihlášen' : company
    
    const handleMenu = () => {
        document.body.classList.toggle('sb-sidenav-toggled');
      }

      const handleShowHowUser = () => {
        if (localStorage.getItem('BigUser') === "1" && localStorage.getItem('BigUserBefore') === '0') {
            localStorage.removeItem('BigUser')
            localStorage.removeItem('BigUserBefore')
            
            if (localStorage.getItem('BigHedgehogBefore') === '1') { localStorage.setItem('BigHedgehog', '1') }
            if (localStorage.getItem('SmallHedgehogBefore') === '1') { localStorage.setItem('SmallHedgehog', '1') }
            if (localStorage.getItem('EmailingBefore') === '1') { localStorage.setItem('Emailing', '1') }
            localStorage.removeItem('BigHedgehogBefore')
            localStorage.removeItem('SmallHedgehogBefore')
            localStorage.removeItem('EmailingBefore')


            window.location.reload()
        }
        else {
            if (localStorage.getItem('BigUser') !== "1") { 
                localStorage.setItem('BigUser', '1')
                localStorage.setItem('BigUserBefore', '0')
                if (localStorage.getItem('BigHedgehog') === '1') { localStorage.setItem('BigHedgehogBefore', '1') }
                if (localStorage.getItem('SmallHedgehog') === '1') { localStorage.setItem('SmallHedgehogBefore', '1') }
                if (localStorage.getItem('Emailing') === '1') { localStorage.setItem('EmailingBefore', '1') }
                localStorage.removeItem('BigHedgehog')
                localStorage.removeItem('SmallHedgehog')
                localStorage.removeItem('Emailing')


                window.location.reload()
             }
            
            
        }
      }

 

    if (localStorage.getItem('isLogged')) {
        return( 
            <Navbar bg="dark" variant="dark" expand="lg" className="sb-topnav" >
                <Navbar.Brand><Link className="navbar-brand" to="/"><img alt="" src="/logoJSWsmallWhite.png" width="50" height="23" className="d-inline-block align-top" />{' '}{' '}<span style={sessionStorage.getItem("isTestRelease") === '1' ? {color: 'red'} : {}}>{sessionStorage.getItem("isTestRelease") === '1' ? 'Testovací portál':'Zákaznický portál'}</span></Link></Navbar.Brand>
                <button className="btn btn-link btn-sm order-1 order-lg-0 colapsed" id="sidebarToggle" onClick={handleMenu}><span className="navbar-toggler-icon"></span></button>

                <Navbar.Collapse  className="justify-content-end">
                    <NavDropdown style={{minWidth: '190px'}} title={localStorage.getItem('BigUserBefore') === "0" ?  <span style={{color: '#e74a3b'}}><i className="fa fa-flask fa-fw"></i> {companyToShow}</span>:<span><i className="fa fa-user fa-fw"></i> {companyToShow}</span>} id="collasible-nav-dropdown">            
                    <NavLink className="dropdown-item" to={ROUTES.myAccount.getLink()}><i className="fa fa-fingerprint fa-fw"></i>&nbsp;&nbsp;Můj účet</NavLink>
                    {rights === true || localStorage.getItem('BigUserBefore') === '0'
                        ?
                        <div className="dropdown-item" style={{marginLeft: '0px', marginTop: '0px', width: '12rem'}}>
                            <div className="float-left">
                            <Form.Check 
                            type="switch"
                            id="show-how-user"
                            label=""
                            name="show-how-user"
                            //disabled={!rights || !upgradeOffer.showControlButtons}
                            checked={localStorage.getItem('BigUser') === '1' ? true:false}
                            onChange={async (e:any) => {await handleShowHowUser()}}
                            //onClick={async () => {await handleShowHowUser();}}
                            //checked={true}
                            />
                            </div>
                            <div className="float-left" style={{marginLeft: '0px'}}>
                            Pohled zákazníka
                            </div>
                            <div className="cleaner"></div>
                        </div>
                        
                        
                        : ''    
                    }


                    <NavLink className="dropdown-item" to={ROUTES.logout.getLink()}><Button variant="danger" size="sm" block>Odhlásit</Button></NavLink>
                </NavDropdown>
            </Navbar.Collapse> 
                                  
          </Navbar>

        )
    }
    else {
        return(
            <Navbar bg="dark" variant="dark" expand="lg" className="sb-topnav" >
                <Navbar.Brand><Link className="navbar-brand" to="/"><img alt="" src="/logoJSWsmallWhite.png" width="50" height="23" className="d-inline-block align-top" />{' '}{' '}Zákaznický portál</Link></Navbar.Brand>                      
            </Navbar>
        
        )  
    }
      
}
  
export default observer(withRouter(TopMenu))

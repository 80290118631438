import React, { useState } from 'react'
import axios from 'axios'

import { EmailingUnsubscribeUrlParams } from '../../../Routes'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

//import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'
import {Container,  Form, Col, Button, Alert, Image, Row, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { getUrl, checkVersionWithoutRestart } from '../../../Utils/Common'
//import * as Constants from '../../../Constants/Constants'

import { useMount } from '../../../helpers/lifecycle-hooks'

import { Formik } from 'formik'
import * as Yup from 'yup'

function EmailingUnsubscribe(props:any) {
  // Získání tokenu z URL adresy
  const { email, hash } = useParams<EmailingUnsubscribeUrlParams>()

  // Pokud nepřišel token přesměrování na Login
  if (email === '' || hash === '') {
    props.history.push('/login')
  }

  // Kontrola zda se načetla URL BE
  
  /*
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }
  */

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //eslint-disable-next-line
  const [showE, setShowE] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [showLoader, setShowLoader] = useState(false)
  //eslint-disable-next-line
  const [customersCount, setCustomersCount] = useState(0)

  //eslint-disable-next-line
  const errorMessageTooManyCustomers:any = (
    <Alert variant="danger">
        <Alert.Heading>Upozornění</Alert.Heading>
        Vaše e-mailová adresa je registrována u více firem. Odhlášením nebudete získávat informační e-maily pro žádnou z nich.<br /><br />
        <Button variant="danger" onClick={() => { setError(null); setShowE(false); setShowForm('') }}>
          Pokračovat na další krok
        </Button>

    </Alert> )


    // Při načtení stránky 
    useMount(() => {
      (async () => {  
      //setLoaded(false) // Nastavení pro Loader, že není strana načtena
      window.scrollTo(0, 0)
      checkVersionWithoutRestart()
      //setShowForm('nodisplay')
      setShowLoader(true)
      // Dotažení e-mailu z tokenu
      await axios.get(getUrl('URL_EMAILING_EMAIL_CUSTOMERS_COUNT')+email+'/customer-count'
        ).then(response => {
          setCustomersCount(Number(response.data))
          if (Number(response.data) > 1) {
            //setError(errorMessageTooManyCustomers)
            //setShowForm('nodisplay')  
            //setShowE(true)
          }
          else {
            //setShowForm('')
          }
          //setLoading(false);
          //setShowInfo(true)
          //setShowForm('nodisplay')
        }).catch(error => {
          //setLoading(false);
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      setShowForm('')
      setShowLoader(false)
      //setLoaded(true) // Nastavení pro Loader, že je strana načtena
      })() 
    })

  // Po odeslání registračního formuláře
  const handleUnsubscribe = (reasonData:string) => {
    setError(null)
    setLoading(true)

    // Zavolání API
    axios.post(getUrl('URL_EMAILING_UNSUBSCRIBE')+email, { email: email, emailHash: hash, reason: reasonData }
    ).then(response => {
      setLoading(false);
      setShowInfo(true)
      setShowForm('nodisplay')
    }).catch(error => {
      setLoading(false);

      const errorsDataToShow:any = error.response.data.errors

      const errorsCount:number = errorsDataToShow.length

      console.log(errorsCount)

      if (errorsCount === 1) {
        if (errorsDataToShow[0].errorCode === 200004) {
          setLoading(false);
          setShowInfo(true)
          setShowForm('nodisplay')
        }
        else {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        }  

      }
      else {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
      }
    });
  }

  // Schéma validace formuláře
  const schema = Yup.object().shape({
    reason: Yup.string()
      .required('Důvod pro odhlášení informačních e-mailů musí být vyplněn!'),
    email: Yup.string()
      .email('E-mailová adresa není ve správném formátu!'),
    reasonOther: Yup.string().when('reason', {
      is: 'Other',
      then: Yup.string()
      .required('Jiný důvod musí být vyplněn!')
    })
        
  })  

  return (
    <div className="App">
      <main>
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="jezekswSmall.png" className="login-logo" /> Zákaznický portál</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Odhlášení informačních e-mailů</h3>
                    
                    

                    <div className={showForm} >
                      <Formik
                        validationSchema={schema}
                        initialValues={{ email: email, reason: '', reasonOther: '' }}
                        validateOnMount={true}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleUnsubscribe(values.reason !== 'Other' ? values.reason : values.reasonOther);              
                            setSubmitting(false);
                          }, 1);
                        }}
                      >
                      {({ 
                        handleSubmit,
                        handleChange,
                        
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup controlId="email">
                            <Form.Row>
                              <Col>
                                <FormControl 
                                  type="text"
                                  name="email"
                                  value={values.email}
                                  disabled={true}
                                  
                                  isValid={touched.email && !errors.email} 
                                  isInvalid={!!errors.email}
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                  {errors.email}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>

                          {customersCount > 1 &&
                          <Alert variant="warning">
                            <Alert.Heading>Upozornění</Alert.Heading>
                            Vaše e-mailová adresa je registrována u více firem. Odhlášením nebudete získávat informační e-maily pro žádnou z nich. 
                          </Alert>}

                          {showLoader === true &&<Spinner animation="border" variant="secondary" />}


                          <FormGroup controlId="information">
                            <Form.Row>
                              <FormLabel column ><strong>Ještě než odejdete, dejte nám prosím zpětnou vazbu ohledně důvodu odhlášení:</strong></FormLabel>
                            </Form.Row>  
                          </FormGroup>  

                          <FormGroup controlId="reason">
                            <Form.Row>
                              <Col>
                                <Row>
                                  <Col md={2}>
                                    <FormControl 
                                      type="radio"
                                      name="reason"
                                      value='Častá rozesílka, je toho moc'
                                      onChange={handleChange}
                                      size='sm'
                                      //style={{height: '1.5em'}}
                                      isValid={touched.reason && !errors.reason} 
                                      isInvalid={!!errors.reason}
                                    />
                                  </Col>
                                  <FormLabel column>Častá rozesílka, je toho moc</FormLabel>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <FormControl 
                                      type="radio"
                                      name="reason"
                                      value='Informace nepotřebuji, vím, kde je najdu'
                                      onChange={handleChange}
                                      size='sm'
                                      //style={{height: '1.5em'}}
                                      isValid={touched.reason && !errors.reason} 
                                      isInvalid={!!errors.reason}
                                    />
                                  </Col>
                                  <FormLabel column>Informace nepotřebuji, vím, kde je najdu</FormLabel>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <FormControl 
                                      type="radio"
                                      name="reason"
                                      value='STEREO ani DUEL nepoužívám'
                                      onChange={handleChange}
                                      size='sm'
                                      //style={{height: '1.5em'}}
                                      isValid={touched.reason && !errors.reason} 
                                      isInvalid={!!errors.reason}
                                    />
                                  </Col>
                                  <FormLabel column>STEREO ani DUEL nepoužívám</FormLabel>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <FormControl 
                                      type="radio"
                                      name="reason"
                                      value='Nechci uvést důvod'
                                      onChange={handleChange}
                                      size='sm'
                                      //style={{height: '1.5em'}}
                                      isValid={touched.reason && !errors.reason} 
                                      isInvalid={!!errors.reason}
                                    />
                                  </Col>
                                  <FormLabel column>Nechci uvést důvod</FormLabel>
                                </Row>
                                <Row>
                                  <Col md={2}>
                                    <FormControl 
                                      type="radio"
                                      name="reason"
                                      value='Other'
                                      onChange={handleChange}
                                      size='sm'
                                      checked={values.reason === 'Other'}
                                      //style={{height: '1.5em'}}
                                      isValid={touched.reason && !errors.reason} 
                                      isInvalid={!!errors.reason}
                                    />
                                  </Col>
                                  <FormLabel column>Jiný důvod</FormLabel>
                                </Row>
                                <Form.Control.Feedback type="invalid">
                                  {errors.reason}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>
                              
                          </FormGroup>
                          {values.reason === 'Other' &&
                          <FormGroup controlId="reasonOther">
                            <Form.Row>
                              <FormLabel column lg={4}>Jaký?</FormLabel>
                              <Col>
                                <FormControl 
                                  type="text"
                                  name="reasonOther"
                                  value={values.reasonOther}
                                  onChange={handleChange}
                                  
                                  isValid={touched.reasonOther && !errors.reasonOther} 
                                  isInvalid={!!errors.reasonOther}
                                />                                
                                <Form.Control.Feedback type="invalid">
                                  {errors.reasonOther}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          }
                          
                          <span style={{fontStyle: 'italic'}}>Nemusíte se bát, e-maily spojené s objednávkami upgrade a veškeré účetní doklady vám budeme i nadále zasílat na vaši fakturační adresu.</span><br /><br />

                          {error}
                          <br />

                          <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Probíhá odhlášení...' : 'Odhlásit odběr'}</Button>
                          
                        </Form>
                      )}
                      </Formik> 
                    </div> 
                    <br />

                    <Alert variant="success" show={showInfo}>
                            <strong>Odhlášeno, budete nám chybět!</strong><br />
                            
                            <hr />
                            Pokud se chcete přihlásit na Zákaznický portál, <Link to={ROUTES.login.getLink()}>klikněte zde...</Link>
                    </Alert> 
                       
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>   
      </main>
    </div>
  )
}

export default EmailingUnsubscribe
import { types } from 'mobx-state-tree'

export const DatafiltersCustomersCustomVariables = types
  .model({
    id: types.optional(types.string, ''),
    name: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    paramsCount: types.optional(types.number,0),
    selected: types.optional(types.boolean,false),
    props1: types.maybeNull(types.number),
    props2: types.maybeNull(types.number),
  })
  .views(self => ({
    get order() {
      let order:number = 999

      if (self.id === "9d0a6737-de8a-41b2-abd1-871e9a51667b") order = 1 //"Má DUEL"
      if (self.id === "97089742-e609-4438-90ac-37e0e9abab55") order = 2 //"DUEL verze od-do"
      if (self.id === "f91bbd64-0dd2-4709-8343-5fe6abe0832a") order = 3 //"Nemá DUEL"

      if (self.id === "99e66883-1503-437b-aee0-0dd4f9c164fc") order = 11 //"Má STEREO"
      if (self.id === "7dbebb68-1211-48d5-8081-87bb62d33fd9") order = 12 //"STEREO verze od-do"
      if (self.id === "50265c25-288b-4a7c-a757-7db8809c67b9") order = 13 //"Nemá STEREO"

      if (self.id === "720921c9-3535-4edb-b7e3-9c6804554a9e") order = 21 //"DUEL má modul Kancelář"
      if (self.id === "2b1a575b-9147-44f1-bb52-7f659cf75617") order = 22 //"DUEL má modul Účetnictví"
      if (self.id === "7ec6d06b-7297-4536-9a5b-3e1947ac421c") order = 23 //"DUEL má modul Daňová evidence"
      if (self.id === "f4932be2-3d36-4d1f-ba21-ed165fe55e2a") order = 24 //"DUEL má modul Mzdy"
      if (self.id === "91759607-c1df-4034-847b-550dda9186a8") order = 25 //"DUEL má modul Sklady"
      if (self.id === "c03fc664-62f4-4564-88b8-93734256ea02") order = 26 //"DUEL má modul Maloobchod"
      if (self.id === "b6837d58-f391-475e-80cb-8e2912319773") order = 27 //"DUEL má modul E-shop"
      if (self.id === "1b44ea5e-b406-4ca1-b173-cd68421a4673") order = 28 //"DUEL má modul Majetek"
      if (self.id === "67cddf69-55c5-4a16-aabb-fc877777d5f4") order = 29 //"DUEL má modul Analýzy"
      if (self.id === "eefd0a32-7347-4fb2-91ae-0770a7ea5c58") order = 30 //"DUEL má modul Polotovary"

      if (self.id === "f2e7442d-d3fa-4700-8982-57e5e188b5c1") order = 41 //"DUEL nemá modul Kancelář"
      if (self.id === "e66991a5-847e-4ab7-a506-de52e18f1e71") order = 42 //"DUEL nemá modul Účetnictví"
      if (self.id === "4a29f29c-256e-4e8c-a57a-ac985b73b5df") order = 43 //"DUEL nemá modul Daňová evidence"
      if (self.id === "88d1bcd3-6c60-402b-bbf6-e2f3d2bc4c58") order = 44 //"DUEL nemá modul Mzdy"
      if (self.id === "066912b9-a021-4adc-a21f-198980c862f6") order = 45 //"DUEL nemá modul Sklady"
      if (self.id === "a29aeceb-a7c2-4982-a51d-ddfd1d40d539") order = 46 //"DUEL nemá modul Maloobchod"
      if (self.id === "b065125f-c0fe-4383-8ccd-0afbed1226ba") order = 47 //"DUEL nemá modul E-shop"
      if (self.id === "18103a21-5bae-4e19-ae11-8be6d6328e26") order = 48 //"DUEL nemá modul Majetek"
      if (self.id === "52e9597f-599a-4b92-9473-f340a53588e6") order = 49 //"DUEL nemá modul Analýzy"
      if (self.id === "406fe5a6-5573-47c8-a283-9feb80891f57") order = 50 //"DUEL nemá modul Polotovary"

      if (self.id === "8a7ffd88-0154-4281-b1cd-9ab9c4db5d74") order = 50 //"DUEL vlastní"
      if (self.id === "65a5acda-094c-4c34-ba1d-9926d85011cd") order = 51 //"DUEL kancl"
      if (self.id === "d2f3eef2-7018-401d-befc-e43006cca9c0") order = 52 //"DUEL vlastni i kanc"

      
      if (self.id === "e21cfe33-6ec6-437a-a564-5da220ac385d") order = 61 //"STEREO má modul Kancelář"
      if (self.id === "349ad0bc-74d7-4115-bbb8-6413bea01fd8") order = 62 //"STEREO má modul Účetnitcví"
      if (self.id === "d76e5ef6-e43e-45a0-a284-2ee8ec32623b") order = 63 //"STEREO má modul Mzdy"
      if (self.id === "23f2862f-773f-4042-987d-ffe8b0d16fe5") order = 64 //"STEREO má modul Sklady"
      if (self.id === "c2ffb064-a87b-48f2-b0b8-a2d789948a3a") order = 65 //"STEREO má modul Majetek"
      if (self.id === "48b59a36-79c2-4fc2-a241-b1747d25de16") order = 66 //"STEREO má modul Analýzy"
      if (self.id === "abd6aa35-7bb1-4cab-bb7f-bdc5a96b4114") order = 71 //"STEREO nemá modul Kancelář"
      if (self.id === "2ad8c2b0-c6a7-4f59-864a-4015ef26e8cb") order = 72 //"STEREO nemá modul Účetnitcví"
      if (self.id === "1a63b85c-fa39-4f27-af24-22709649c0cf") order = 73 //"STEREO nemá modul Mzdy"
      if (self.id === "db321d68-7634-4d2e-bdb1-911d1df04c6f") order = 74 //"STEREO nemá modul Sklady"
      if (self.id === "01ef8cd8-4c1c-4d60-8144-61a918029f9c") order = 75 //"STEREO nemá modul Majetek"
      if (self.id === "3d5cb895-fa57-4311-9fbc-43f0a0882ef4") order = 76 //"STEREO nemá modul Analýzy"

      if (self.id === "5046def6-d372-4fdb-b073-556c2d4f5545") order = 77 //"STEREO vlastni"
      if (self.id === "ea95e400-2b75-4def-bb64-0f8da3c61517") order = 78 //"STEREO kancl"
      if (self.id === "26bd6444-3d6d-48ed-8ac8-659acdb5e08b") order = 79 //"STEREO vlastni i kancl"

      return order
    },
    get group() {
      let group:number = 999

      if (self.id === "9d0a6737-de8a-41b2-abd1-871e9a51667b") group = 1 //"Má DUEL"
      if (self.id === "97089742-e609-4438-90ac-37e0e9abab55") group = 1 //"DUEL verze od-do"
      if (self.id === "f91bbd64-0dd2-4709-8343-5fe6abe0832a") group = 1 //"Nemá DUEL"

      if (self.id === "99e66883-1503-437b-aee0-0dd4f9c164fc") group = 2 //"Má STEREO"
      if (self.id === "7dbebb68-1211-48d5-8081-87bb62d33fd9") group = 2 //"STEREO verze od-do"
      if (self.id === "50265c25-288b-4a7c-a757-7db8809c67b9") group = 2 //"Nemá STEREO"

      if (self.id === "720921c9-3535-4edb-b7e3-9c6804554a9e") group = 3 //"DUEL má modul Kancelář"
      if (self.id === "2b1a575b-9147-44f1-bb52-7f659cf75617") group = 3 //"DUEL má modul Účetnictví"
      if (self.id === "7ec6d06b-7297-4536-9a5b-3e1947ac421c") group = 3 //"DUEL má modul Daňová evidence"
      if (self.id === "f4932be2-3d36-4d1f-ba21-ed165fe55e2a") group = 3 //"DUEL má modul Mzdy"
      if (self.id === "91759607-c1df-4034-847b-550dda9186a8") group = 3 //"DUEL má modul Sklady"
      if (self.id === "c03fc664-62f4-4564-88b8-93734256ea02") group = 3 //"DUEL má modul Maloobchod"
      if (self.id === "b6837d58-f391-475e-80cb-8e2912319773") group = 3 //"DUEL má modul E-shop"
      if (self.id === "1b44ea5e-b406-4ca1-b173-cd68421a4673") group = 3 //"DUEL má modul Majetek"
      if (self.id === "67cddf69-55c5-4a16-aabb-fc877777d5f4") group = 3 //"DUEL má modul Analýzy"
      if (self.id === "f2e7442d-d3fa-4700-8982-57e5e188b5c1") group = 4 //"DUEL nemá modul Kancelář"
      if (self.id === "e66991a5-847e-4ab7-a506-de52e18f1e71") group = 4 //"DUEL nemá modul Účetnictví"
      if (self.id === "4a29f29c-256e-4e8c-a57a-ac985b73b5df") group = 4 //"DUEL nemá modul Daňová evidence"
      if (self.id === "88d1bcd3-6c60-402b-bbf6-e2f3d2bc4c58") group = 4 //"DUEL nemá modul Mzdy"
      if (self.id === "066912b9-a021-4adc-a21f-198980c862f6") group = 4 //"DUEL nemá modul Sklady"
      if (self.id === "a29aeceb-a7c2-4982-a51d-ddfd1d40d539") group = 4 //"DUEL nemá modul Maloobchod"
      if (self.id === "b065125f-c0fe-4383-8ccd-0afbed1226ba") group = 4 //"DUEL nemá modul E-shop"
      if (self.id === "18103a21-5bae-4e19-ae11-8be6d6328e26") group = 4 //"DUEL nemá modul Majetek"
      if (self.id === "52e9597f-599a-4b92-9473-f340a53588e6") group = 4 //"DUEL nemá modul Analýzy"
      
      if (self.id === "e21cfe33-6ec6-437a-a564-5da220ac385d") group = 5 //"STEREO má modul Kancelář"
      if (self.id === "349ad0bc-74d7-4115-bbb8-6413bea01fd8") group = 5 //"STEREO má modul Účetnitcví"
      if (self.id === "d76e5ef6-e43e-45a0-a284-2ee8ec32623b") group = 5 //"STEREO má modul Mzdy"
      if (self.id === "23f2862f-773f-4042-987d-ffe8b0d16fe5") group = 5 //"STEREO má modul Sklady"
      if (self.id === "c2ffb064-a87b-48f2-b0b8-a2d789948a3a") group = 5 //"STEREO má modul Majetek"
      if (self.id === "48b59a36-79c2-4fc2-a241-b1747d25de16") group = 5 //"STEREO má modul Analýzy"
      if (self.id === "abd6aa35-7bb1-4cab-bb7f-bdc5a96b4114") group = 6 //"STEREO nemá modul Kancelář"
      if (self.id === "2ad8c2b0-c6a7-4f59-864a-4015ef26e8cb") group = 6 //"STEREO nemá modul Účetnitcví"
      if (self.id === "1a63b85c-fa39-4f27-af24-22709649c0cf") group = 6 //"STEREO nemá modul Mzdy"
      if (self.id === "db321d68-7634-4d2e-bdb1-911d1df04c6f") group = 6 //"STEREO nemá modul Sklady"
      if (self.id === "01ef8cd8-4c1c-4d60-8144-61a918029f9c") group = 6 //"STEREO nemá modul Majetek"
      if (self.id === "3d5cb895-fa57-4311-9fbc-43f0a0882ef4") group = 6 //"STEREO nemá modul Analýzy"

      return group
    }
    
  }))
  .actions(self => ({
    selectItem(newState: boolean) {
      self.selected = newState
    },
    setParams(param: string, value: number) {
      if (param === "one") self.props1 = value
      if (param === "two") self.props2 = value
      
    }

  }))

import React  from 'react'
import {Card} from 'react-bootstrap'

import { Link } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import NumberFormat from 'react-number-format'


const OverviewsOffers: React.FC = () => {

  // Načtení Store
  const rootStore = useStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  //const overviewsCustomers = rootStore.overviewsCustomer

  // Seznam přehledů do řádků karty
  const overviewsOffersData = rootStore.overviewsOffer.map(overviewsOffers => (
    <div key={overviewsOffers.filterId}>
      <div className="float-left">
        <Link to={ROUTES.overviewsDetailOffer.getLink(overviewsOffers.filterId)}>
        {overviewsOffers.category === 'offers' ? '': ''}
        {overviewsOffers.filterId === 7 ? 'Vytvořené':''}
        {overviewsOffers.filterId === 8 ? 'Nabídnuté':''}
        {overviewsOffers.filterId === 9 ? 'Schválené':''}
        {overviewsOffers.filterId === 10 ? 'Zneplatněné':''}
        {overviewsOffers.filterId === 11 ? 'Pozastavené':''}
        {overviewsOffers.filterId === 12 ? 'Odmítnuté':''}
        {overviewsOffers.filterId === 13 ? 'Vyřízené':''}
        {overviewsOffers.filterId === 14 ? 'S vytvořenou zálohovou fakturou':''}
        {overviewsOffers.filterId === 15 ? 'Se zveřejněnou zálohovou fakturou':''}
        {overviewsOffers.filterId === 16 ? 'Zaplacené':''}
        {overviewsOffers.filterId === 17 ? 'S vytvořenými finálními dokumenty':''}
        
        
        </Link>
        
      </div>
      <div className="float-right">
        <NumberFormat displayType={'text'} thousandSeparator={' '} value={overviewsOffers.count} />
      </div>          
      <div className="cleaner divide"></div>
    </div>
  ))
  
  return (
    <Card
      bg='light'
      text='dark'
      
    >
      <Card.Header  as="h5">Objednávky</Card.Header>
      <Card.Body>
        {overviewsOffersData}
          
        
      </Card.Body>
    </Card> 
  )  
  
}

export default observer(OverviewsOffers)

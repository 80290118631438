import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Col, Alert, Modal, Row, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'

// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
//import axios from 'axios';

import { useEmailingStore } from '../../../../store'
import { observer } from 'mobx-react'



//import * as Yup from 'yup'
import { sleep } from '../../../../helpers/sleep'

interface DatafiltersCustomersCustomListAddConditionProps {
  addCondition:any,
  isSystem:boolean,
  refresh:any
}

const DatafiltersCustomersCustomListAddCondition: React.FC<DatafiltersCustomersCustomListAddConditionProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsEM:boolean = ((localStorage.getItem('Emailing')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()

  let item = rootEmailingStore.datafiltersCustomersCustomVariables.filter(i => i.order === 999).slice().sort((a, b) => (a.order > b.order) ? 1 : -1)
  let itemToSend = rootEmailingStore.datafiltersCustomersCustomVariables.slice().sort((a, b) => (a.order > b.order) ? 1 : -1)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line 
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    rootEmailingStore.unselectDatafiltersCustomersCustomVariables()
    item = rootEmailingStore.datafiltersCustomersCustomVariables.filter(i => i.order === 999).slice().sort((a, b) => (a.order > b.order) ? 1 : -1)
    itemToSend = rootEmailingStore.datafiltersCustomersCustomVariables.slice().sort((a, b) => (a.order > b.order) ? 1 : -1)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleAdd = (operator:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      if (Object.keys(rootEmailingStore.emailingConditions).length === -1) {
        (async () => {
          await props.addCondition('99999', true, null, null, 'and')
          itemToSend.filter(i => i.selected === true).map(i => {
            rootEmailingStore.emailingConditions[0].addCondition(i.id, false, i.props1, i.props2, operator)
            return null
          })
          
        })()
 
      }  
      else {
      itemToSend.filter(i => i.selected === true).map(i => {
        props.addCondition(i.id, false, i.props1, i.props2, operator)
        return null 
      })
      }
      setShowForm('nodisplay')
      setShowInfo(true)
      sessionStorage.setItem('autoCloseModal','1')

      await sleep(1000)
      props.refresh('tempItem', new Date().toISOString)

      // Po 2800 ms celé modální okno zavřeme
      setTimeout(() => {
        setShow(false)
        setError(null)
        sessionStorage.removeItem('autoCloseModal')
        rootEmailingStore.unselectDatafiltersCustomersCustomVariables()
        //setShowInfo(false)
        //setShowForm('')
      }, 1000);
      
        
    })()

  }

  const item01a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '9d0a6737-de8a-41b2-abd1-871e9a51667b')
  const item01b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'f91bbd64-0dd2-4709-8343-5fe6abe0832a')

  const item02 = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '97089742-e609-4438-90ac-37e0e9abab55')

  const item03a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '720921c9-3535-4edb-b7e3-9c6804554a9e')
  const item03b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'f2e7442d-d3fa-4700-8982-57e5e188b5c1')

  const item04a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '2b1a575b-9147-44f1-bb52-7f659cf75617')
  const item04b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'e66991a5-847e-4ab7-a506-de52e18f1e71')

  const item05a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '7ec6d06b-7297-4536-9a5b-3e1947ac421c')
  const item05b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '4a29f29c-256e-4e8c-a57a-ac985b73b5df')

  const item06a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'f4932be2-3d36-4d1f-ba21-ed165fe55e2a')
  const item06b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '88d1bcd3-6c60-402b-bbf6-e2f3d2bc4c58')

  const item07a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '91759607-c1df-4034-847b-550dda9186a8')
  const item07b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '066912b9-a021-4adc-a21f-198980c862f6')

  const item08a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'c03fc664-62f4-4564-88b8-93734256ea02')
  const item08b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'a29aeceb-a7c2-4982-a51d-ddfd1d40d539')

  const item09a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'b6837d58-f391-475e-80cb-8e2912319773')
  const item09b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'b065125f-c0fe-4383-8ccd-0afbed1226ba')

  const item10a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '1b44ea5e-b406-4ca1-b173-cd68421a4673')
  const item10b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '18103a21-5bae-4e19-ae11-8be6d6328e26')

  const item11a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '67cddf69-55c5-4a16-aabb-fc877777d5f4')
  const item11b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '52e9597f-599a-4b92-9473-f340a53588e6')

  const item13a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'eefd0a32-7347-4fb2-91ae-0770a7ea5c58')
  const item13b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '406fe5a6-5573-47c8-a283-9feb80891f57')

  const item12a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '8a7ffd88-0154-4281-b1cd-9ab9c4db5d74')
  const item12b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '65a5acda-094c-4c34-ba1d-9926d85011cd')
  const item12c = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'd2f3eef2-7018-401d-befc-e43006cca9c0')


  const sitem01a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '99e66883-1503-437b-aee0-0dd4f9c164fc')
  const sitem01b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '50265c25-288b-4a7c-a757-7db8809c67b9')

  const sitem02 = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '7dbebb68-1211-48d5-8081-87bb62d33fd9')

  const sitem03a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'e21cfe33-6ec6-437a-a564-5da220ac385d')
  const sitem03b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'abd6aa35-7bb1-4cab-bb7f-bdc5a96b4114')

  const sitem04a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '349ad0bc-74d7-4115-bbb8-6413bea01fd8')
  const sitem04b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '2ad8c2b0-c6a7-4f59-864a-4015ef26e8cb')

  const sitem06a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'd76e5ef6-e43e-45a0-a284-2ee8ec32623b')
  const sitem06b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '1a63b85c-fa39-4f27-af24-22709649c0cf')

  const sitem07a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '23f2862f-773f-4042-987d-ffe8b0d16fe5')
  const sitem07b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'db321d68-7634-4d2e-bdb1-911d1df04c6f')

  const sitem10a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'c2ffb064-a87b-48f2-b0b8-a2d789948a3a')
  const sitem10b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '01ef8cd8-4c1c-4d60-8144-61a918029f9c')

  const sitem11a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '48b59a36-79c2-4fc2-a241-b1747d25de16')
  const sitem11b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '3d5cb895-fa57-4311-9fbc-43f0a0882ef4')

  const sitem12a = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '5046def6-d372-4fdb-b073-556c2d4f5545')
  const sitem12b = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === 'ea95e400-2b75-4def-bb64-0f8da3c61517')
  const sitem12c = rootEmailingStore.datafiltersCustomersCustomVariables.slice().find(i => i.id === '26bd6444-3d6d-48ed-8ac8-659acdb5e08b')




  
  return (
    <span >
      {rightsEM && <>
        {!props.isSystem &&<Button size="sm" variant="success" style={{marginRight:'5px', marginTop: '5px'}} onClick={() => { handleShow() } }>+ Přidat podmínku</Button>}
        {props.isSystem &&<Button size="sm" variant="success" style={{marginRight:'5px', marginTop: '5px'}} onClick={async() => { await props.addCondition('99999', true, null, null, 'and'); props.refresh('tempItem', new Date().toISOString) } }>+ Přidat skupinu ()</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Přidání podmínky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Podmínka byla úspěšně přidána!​</strong>
            
          </Alert>  
          <div className={showForm} >

            <strong>
              <img className="noprint" alt="logo programu" src={"images/ico-header-d.png"} style={{ marginRight: '15px' }} />
              DUEL
            </strong>
            <br /><br />
            <strong>Program</strong>

              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item01a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item01a?.selected}
                      className=""
                      id={'rowcheck'+item01a?.id}
                      onChange={() => item01a?.selectItem(!item01a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item01a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item01b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item01b?.selected}
                      className=""
                      id={'rowcheck'+item01b?.id}
                      onChange={() => item01b?.selectItem(!item01b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item01b?.name}</span>
                  </Col>
                </Row>              
              </div>

              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col style={{backgroundColor: item02?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item02?.selected}
                      className=""
                      id={'rowcheck'+item02?.id}
                      onChange={() => item02?.selectItem(!item02.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item02?.name}</span>
                  </Col>
                  <Col md={3} sm={3} style={{backgroundColor: item02?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                  
                  <FormControl 
                    type="number"
                    name="paramOne"
                    size="sm"
                    defaultValue={item02?.props1 === null ? 0:item02?.props1} 
                    min={0}
                    //onKeyDown={(e:any) => {handleKeyDownPointsWithCorrection(e, 1 )}} 
                    onChange={(e) => { item02?.setParams('one', Number(e.target.value));   }}
                  />
                </Col>
                <Col md={3} sm={3} style={{backgroundColor: item02?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                  <FormControl 
                    type="number"
                    name="paramTwo"
                    size="sm"
                    defaultValue={item02?.props2 === null ? 0:item02?.props2} 
                    min={0}
                    //onKeyDown={(e:any) => {handleKeyDownPointsWithCorrection(e, 1 )}} 
                    onChange={(e) => { item02?.setParams('two', Number(e.target.value));   }}
                  />
                </Col>
                </Row>              
              </div>
              <br />
              <strong>Moduly programu</strong>

              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item03a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item03a?.selected}
                      className=""
                      id={'rowcheck'+item03a?.id}
                      onChange={() => item03a?.selectItem(!item03a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item03a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item03b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item03b?.selected}
                      className=""
                      id={'rowcheck'+item03b?.id}
                      onChange={() => item03b?.selectItem(!item03b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item03b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item04a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item04a?.selected}
                      className=""
                      id={'rowcheck'+item04a?.id}
                      onChange={() => item04a?.selectItem(!item04a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item04a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item04b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item04b?.selected}
                      className=""
                      id={'rowcheck'+item04b?.id}
                      onChange={() => item04b?.selectItem(!item04b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item04b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item05a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item05a?.selected}
                      className=""
                      id={'rowcheck'+item05a?.id}
                      onChange={() => item05a?.selectItem(!item05a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item05a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item05b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item05b?.selected}
                      className=""
                      id={'rowcheck'+item05b?.id}
                      onChange={() => item05b?.selectItem(!item05b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item05b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item06a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item06a?.selected}
                      className=""
                      id={'rowcheck'+item06a?.id}
                      onChange={() => item06a?.selectItem(!item06a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item06a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item06b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item06b?.selected}
                      className=""
                      id={'rowcheck'+item06b?.id}
                      onChange={() => item06b?.selectItem(!item06b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item06b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item07a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item07a?.selected}
                      className=""
                      id={'rowcheck'+item07a?.id}
                      onChange={() => item07a?.selectItem(!item07a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item07a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item07b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item07b?.selected}
                      className=""
                      id={'rowcheck'+item07b?.id}
                      onChange={() => item07b?.selectItem(!item07b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item07b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item08a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item08a?.selected}
                      className=""
                      id={'rowcheck'+item08a?.id}
                      onChange={() => item08a?.selectItem(!item08a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item08a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item08b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item08b?.selected}
                      className=""
                      id={'rowcheck'+item08b?.id}
                      onChange={() => item08b?.selectItem(!item08b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item08b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item09a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item09a?.selected}
                      className=""
                      id={'rowcheck'+item09a?.id}
                      onChange={() => item09a?.selectItem(!item09a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item09a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item09b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item09b?.selected}
                      className=""
                      id={'rowcheck'+item09b?.id}
                      onChange={() => item09b?.selectItem(!item09b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item09b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item10a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item10a?.selected}
                      className=""
                      id={'rowcheck'+item10a?.id}
                      onChange={() => item10a?.selectItem(!item10a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item10a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item10b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item10b?.selected}
                      className=""
                      id={'rowcheck'+item10b?.id}
                      onChange={() => item10b?.selectItem(!item10b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item10b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item11a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item11a?.selected}
                      className=""
                      id={'rowcheck'+item11a?.id}
                      onChange={() => item11a?.selectItem(!item11a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item11a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item11b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item11b?.selected}
                      className=""
                      id={'rowcheck'+item11b?.id}
                      onChange={() => item11b?.selectItem(!item11b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item11b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item13a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item13a?.selected}
                      className=""
                      id={'rowcheck'+item13a?.id}
                      onChange={() => item13a?.selectItem(!item13a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item13a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item13b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item13b?.selected}
                      className=""
                      id={'rowcheck'+item13b?.id}
                      onChange={() => item13b?.selectItem(!item13b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item13b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <br />
              <strong>Režim použití</strong>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: item12a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item12a?.selected}
                      className=""
                      id={'rowcheck'+item12a?.id}
                      onChange={() => item12a?.selectItem(!item12a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item12a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item12b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item12b?.selected}
                      className=""
                      id={'rowcheck'+item12b?.id}
                      onChange={() => item12b?.selectItem(!item12b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item12b?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: item12c?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={item12c?.selected}
                      className=""
                      id={'rowcheck'+item12c?.id}
                      onChange={() => item12c?.selectItem(!item12c.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item12c?.name}</span>
                  </Col>
                </Row>              
              </div>

            <br /><br />

            <strong>
              <img className="noprint" alt="logo programu" src={"images/ico-header-s.png"} style={{ marginRight: '15px' }} />
              STEREO
            </strong>
            <br /><br />
            <strong>Program</strong>

              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem01a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem01a?.selected}
                      className=""
                      id={'rowcheck'+sitem01a?.id}
                      onChange={() => sitem01a?.selectItem(!sitem01a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem01a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem01b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem01b?.selected}
                      className=""
                      id={'rowcheck'+sitem01b?.id}
                      onChange={() => sitem01b?.selectItem(!sitem01b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem01b?.name}</span>
                  </Col>
                </Row>              
              </div>

              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col style={{backgroundColor: sitem02?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem02?.selected}
                      className=""
                      id={'rowcheck'+sitem02?.id}
                      onChange={() => sitem02?.selectItem(!sitem02.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem02?.name}</span>
                  </Col>
                  <Col md={3} sm={3} style={{backgroundColor: sitem02?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                  
                  <FormControl 
                    type="number"
                    name="paramOne"
                    size="sm"
                    defaultValue={sitem02?.props1 === null ? 0:sitem02?.props1} 
                    min={0}
                    //onKeyDown={(e:any) => {handleKeyDownPointsWithCorrection(e, 1 )}} 
                    onChange={(e) => { sitem02?.setParams('one', Number(e.target.value));   }}
                  />
                </Col>
                <Col md={3} sm={3} style={{backgroundColor: sitem02?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                  <FormControl 
                    type="number"
                    name="paramTwo"
                    size="sm"
                    defaultValue={sitem02?.props2 === null ? 0:sitem02?.props2} 
                    min={0}
                    //onKeyDown={(e:any) => {handleKeyDownPointsWithCorrection(e, 1 )}} 
                    onChange={(e) => { sitem02?.setParams('two', Number(e.target.value));   }}
                  />
                </Col>
                </Row>              
              </div>
              <br />
              <strong>Moduly programu</strong>

              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem03a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem03a?.selected}
                      className=""
                      id={'rowcheck'+sitem03a?.id}
                      onChange={() => sitem03a?.selectItem(!sitem03a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem03a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem03b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem03b?.selected}
                      className=""
                      id={'rowcheck'+sitem03b?.id}
                      onChange={() => sitem03b?.selectItem(!sitem03b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem03b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem04a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem04a?.selected}
                      className=""
                      id={'rowcheck'+sitem04a?.id}
                      onChange={() => sitem04a?.selectItem(!sitem04a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem04a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem04b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem04b?.selected}
                      className=""
                      id={'rowcheck'+sitem04b?.id}
                      onChange={() => sitem04b?.selectItem(!sitem04b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem04b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem06a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem06a?.selected}
                      className=""
                      id={'rowcheck'+sitem06a?.id}
                      onChange={() => sitem06a?.selectItem(!sitem06a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem06a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem06b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem06b?.selected}
                      className=""
                      id={'rowcheck'+sitem06b?.id}
                      onChange={() => sitem06b?.selectItem(!sitem06b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem06b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem07a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem07a?.selected}
                      className=""
                      id={'rowcheck'+sitem07a?.id}
                      onChange={() => sitem07a?.selectItem(!sitem07a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem07a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem07b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem07b?.selected}
                      className=""
                      id={'rowcheck'+sitem07b?.id}
                      onChange={() => sitem07b?.selectItem(!sitem07b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem07b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem10a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem10a?.selected}
                      className=""
                      id={'rowcheck'+sitem10a?.id}
                      onChange={() => sitem10a?.selectItem(!sitem10a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem10a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem10b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem10b?.selected}
                      className=""
                      id={'rowcheck'+sitem10b?.id}
                      onChange={() => sitem10b?.selectItem(!sitem10b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem10b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem11a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem11a?.selected}
                      className=""
                      id={'rowcheck'+sitem11a?.id}
                      onChange={() => sitem11a?.selectItem(!sitem11a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem11a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem11b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem11b?.selected}
                      className=""
                      id={'rowcheck'+sitem11b?.id}
                      onChange={() => sitem11b?.selectItem(!sitem11b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem11b?.name}</span>
                  </Col>
                </Row>              
              </div>
              <br />
              <strong>Režim použití</strong>
              <div style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{}}>
                  <Col md={6} sm={12} style={{backgroundColor: sitem12a?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem12a?.selected}
                      className=""
                      id={'rowcheck'+sitem12a?.id}
                      onChange={() => sitem12a?.selectItem(!sitem12a.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem12a?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem12b?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem12b?.selected}
                      className=""
                      id={'rowcheck'+sitem12b?.id}
                      onChange={() => sitem12b?.selectItem(!sitem12b.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem12b?.name}</span>
                  </Col>
                  <Col md={6} sm={12} style={{backgroundColor: sitem12c?.selected ? '#c1f7d5':'white',padding: '10px'}}>
                    <input
                      type="checkbox"
                      checked={sitem12c?.selected}
                      className=""
                      id={'rowcheck'+sitem12c?.id}
                      onChange={() => sitem12c?.selectItem(!sitem12c.selected)}
                      style={{height: '18px', width: '18px'}}
                    />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{sitem12c?.name}</span>
                  </Col>
                </Row>              
              </div>

            <br /><br />

            {item.map(item => (
              <div key={item.id} style={{borderBottom: '1px solid lightgrey'}}>
                <Row style={{backgroundColor: item.selected ? '#c1f7d5':'white', padding: '10px'}}>
                <Col>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    className=""
                    id={'rowcheck'+item.id}
                    onChange={() => item.selectItem(!item.selected)}
                    style={{height: '18px', width: '18px'}}
                  />&nbsp;&nbsp;<span style={{fontSize: '110%'}}>{item.name}</span>
                </Col>
                <Col md={2} sm={3}>
                  {item.paramsCount > 0 &&
                  <FormControl 
                    type="number"
                    name="paramOne"
                    size="sm"
                    defaultValue={item.props1 === null ? 0:item.props1} 
                    
                    min={0}
                    //onKeyDown={(e:any) => {handleKeyDownPointsWithCorrection(e, 1 )}} 
                    onChange={(e) => { item.setParams('one', Number(e.target.value));   }}
                    
                  />}
                </Col>
                <Col md={2} sm={3}>
                  {item.paramsCount > 1 &&
                  <FormControl 
                    type="number"
                    name="paramTwo"
                    size="sm"
                    defaultValue={item.props2 === null ? 0:item.props2} 
                    min={0}
                    //onKeyDown={(e:any) => {handleKeyDownPointsWithCorrection(e, 1 )}} 
                    onChange={(e) => { item.setParams('two', Number(e.target.value));   }}
                  />}
                </Col>
                
              </Row>
              
              </div>
            ))}
            <br />
            <ButtonGroup>
              <Button variant="success" disabled={Object.keys(rootEmailingStore.datafiltersCustomersCustomVariables.filter(v => v.selected === true)).length === 0} onClick={() => { handleAdd('and') } }>Přidat "A"</Button>
              <Button variant="warning" disabled={Object.keys(rootEmailingStore.datafiltersCustomersCustomVariables.filter(v => v.selected === true)).length === 0} onClick={() => { handleAdd('or') } }>Přidat "NEBO"</Button>
            </ButtonGroup>
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(DatafiltersCustomersCustomListAddCondition))

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

interface UpgradeScenariosProps {
      
}

const SelectOptionsUpgradeScenarios: React.FC<UpgradeScenariosProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootUpgradeStore = useUpgradeStore()

  // Seznam typů kontaktu pro rozevírací seznam
  const items = rootUpgradeStore.upgradeOffersScenarios
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.name; 
    var sort1B = b.name; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(item => (
    <option key={item.id} value={item.id}>{item.program.name}&nbsp;{item.programVersion.version}</option>
  ))   

  return (
    <>
      {items}
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsUpgradeScenarios))
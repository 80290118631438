import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//eslint-disable-next-line
import { getToken, getUrl } from '../../../Utils/Common'
//eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, Table, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
//eslint-disable-next-line
import axios from 'axios';

import { useStore, useEmailingStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import OthersTemplateDetail from '../../../pages/others/templates/components/Others.Templates.Detail.part'
import DatafiltersCustomersCustomListPreview from '../datafiltersCustomersCustomList/components/Datafilters.Customers.Custom.List.List.Preview.part'

import moment from 'moment'


interface EmailingSendEmailsProps {
  mode?: string,
  id?: string,
  filterId?:number,
  showAs:string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined,
  searchValue?:string|undefined,
  searchType?:string|undefined, 
  searchScenario?:string|undefined,
  count?: number|undefined,
  fromCustomerDashboard?: boolean|undefined,
  accountNumber?: string|undefined|null,
  toConcreteEmail?: boolean|undefined,
  concreteEmail?: string,

  source?: string,
  sourceId?: string,
}

const EmailingSendEmails: React.FC<EmailingSendEmailsProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('Emailing')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showOtherSettings, setShowOtherSettings] = useState(false)

  const [sendingTest, setSendingTest] = useState(false)
  const [sendingTestError, setSendingTestError] = useState(false)

  const [testSended, setTestSended] = useState(false)
  const [testSendedTime, setTestSendedTime] = useState('')

  const [showPreview, setShowPreview] = useState(false)
 
  // Připojení store
  const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()

  // Šablony e-mailů
  const templates = rootStore.templates.map(template => (
    <option key={template.id} value={template.id}>{template.name}</option>
  ))

  // Uživatelské filtry
  const customQueries = rootEmailingStore.datafiltersCustomersCustomListAll.map(item => (
    <option key={item.id} value={item.id}>{item.name}</option>
  ))

  // Šipky u tlačítek pro rozevírání / sbalení
  const arrowDown = <svg className="svg-inline--fa fa-angle-double-down fa-w-10" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M143 256.3L7 120.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0L313 86.3c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.4 9.5-24.6 9.5-34 .1zm34 192l136-136c9.4-9.4 9.4-24.6 0-33.9l-22.6-22.6c-9.4-9.4-24.6-9.4-33.9 0L160 352.1l-96.4-96.4c-9.4-9.4-24.6-9.4-33.9 0L7 278.3c-9.4 9.4-9.4 24.6 0 33.9l136 136c9.4 9.5 24.6 9.5 34 .1z"></path></svg>
  const arrowUp = <svg className="svg-inline--fa fa-angle-double-up fa-w-10" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M177 255.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 351.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 425.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1zm-34-192L7 199.7c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l96.4-96.4 96.4 96.4c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9l-136-136c-9.2-9.4-24.4-9.4-33.8 0z"></path></svg>

  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    if (props.filtered === true) {
      await rootStore.fetchDatafiltersOffersNewForFilteredActions(Number(props.filterId),props.searchValue,props.searchType,props.searchScenario)
    }
    
    await sessionStorage.removeItem('autoCloseModal')
    if (rights) await rootStore.fetchTemplates('','',true,false,9999,1) 
    if (rights) await rootEmailingStore.fetchDatafiltersCustomersCustomListAll() 
    if (rights) await rootStore.fetchHedgehogUsers()

    await setError(null)
    await setShowInfo(false)
    await setShowForm('')
    await setShow(true)
    await setSendingTest(false)
    await setSendingTestError(false)
    await setTestSended(false)
    await setShowOtherSettings(false)

  }

  // Funkce pro odeslání e-mailu
  const handleSendEmail = ( isBillingEmailsData: boolean, 
                            isUserEmailsData: boolean, 
                            isOtherEmailsData: boolean, 
                            templateData:string, 
                            source:string, 
                            customQueryId:string, 
                            filterId:string, 
                            accountNumbers:string, 
                            emailAddresses:string, 
                            ignoreWhiteList:boolean, 
                            ignoreBlackList:boolean, 
                            whiteListEmailAddresses:string, 
                            blackListEmailAddresses:string, 
                            name:string,
                            separatorAN:string,
                            separatorEA:string,
                            separatorWL:string,
                            separatorBL:string,
                            ) => {
    setError(null)
    setLoading(true);



    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend:any = {}

      if (source === '1') dataToSend = {
        name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
        templateId: templateData, 
        innerCustomers: {  
          mode: 'all', 
          selectedIds: [], 
          customQueryId: customQueryId, 
          isBillingEmails: isBillingEmailsData, 
          isUserEmails: isUserEmailsData, 
          isOtherEmails: isOtherEmailsData
        },
        outerCustomers: {
          accountNumbers: [],
          isBillingEmails: false,
          isUsersEmails: false,
          isOtherEmails: false
        },
        emailAddresses: [],
        ignoreWhiteList: ignoreWhiteList,
        ignoreBlackList: ignoreBlackList,
        whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
        blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '')  
      }  

      if (source === '2') dataToSend = {
        name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
        templateId: templateData, 
        innerCustomers: {  
          mode: 'all', 
          selectedIds: [], 
          filterId: Number(filterId), 
          isBillingEmails: isBillingEmailsData,  
          isUserEmails: isUserEmailsData, 
          isOtherEmails: isOtherEmailsData
        },
        outerCustomers: {
          accountNumbers: [],
          isBillingEmails: false,
          isUsersEmails: false,
          isOtherEmails: false
        },
        emailAddresses: [],
        ignoreWhiteList: ignoreWhiteList,
        ignoreBlackList: ignoreBlackList,
        whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
        blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '') 
      }  

      if (source === '3') dataToSend = {
        name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
        templateId: templateData, 
        innerCustomers: null,
        outerCustomers: {
          accountNumbers: separatorAN === "E" ? accountNumbers.split(/\r?\n/).filter(i => i !== '') : accountNumbers.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
          isBillingEmails: isBillingEmailsData,
          isUsersEmails: isUserEmailsData,
          isOtherEmails: isOtherEmailsData
        },
        emailAddresses: [],
        ignoreWhiteList: ignoreWhiteList,
        ignoreBlackList: ignoreBlackList,
        whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
        blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '')
      }  

      if (source === '4') dataToSend = {
        name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
        templateId: templateData, 
        innerCustomers: null,
        outerCustomers: {
          accountNumbers: [],
          isBillingEmails: false,
          isUsersEmails: false,
          isOtherEmails: false
        },
        emailAddresses: separatorEA === "E" ? emailAddresses.split(/\r?\n/).filter(i => i !== '') : emailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
        ignoreWhiteList: ignoreWhiteList,
        ignoreBlackList: ignoreBlackList,
        whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
        blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '')
      }  

      //console.log(dataToSend)

      // Zavolání API
      
      await axios.post(getUrl('URL_BULK_EMAILS'), dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        

    })()
  }


  // Funkce pro odeslání e-mailu
  const handleShowAdresses = ( isBillingEmailsData: boolean, 
    isUserEmailsData: boolean, 
    isOtherEmailsData: boolean, 
    templateData:string, 
    source:string, 
    customQueryId:string, 
    filterId:string, 
    accountNumbers:string, 
    emailAddresses:string, 
    ignoreWhiteList:boolean, 
    ignoreBlackList:boolean, 
    whiteListEmailAddresses:string, 
    blackListEmailAddresses:string, 
    name:string,
    separatorAN:string,
    separatorEA:string,
    separatorWL:string,
    separatorBL:string,
    ) => {
    setError(null)
    setShowPreview(true);



  // Zpracování požadavku
  (async () => {

  // Kontrola přihlášení  
  const aToken = await getToken()
  if ((aToken === null) || (aToken === undefined)) { 
  sessionStorage.setItem('automaticLogout', '1')
  props.history.push('/logout')
  }

  let dataToSend:any = {}

  if (source === '1') dataToSend = {
  name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
  templateId: templateData, 
  innerCustomers: {  
  mode: 'all', 
  selectedIds: [], 
  customQueryId: customQueryId, 
  isBillingEmails: isBillingEmailsData, 
  isUserEmails: isUserEmailsData, 
  isOtherEmails: isOtherEmailsData
  },
  outerCustomers: {
  accountNumbers: [],
  isBillingEmails: false,
  isUsersEmails: false,
  isOtherEmails: false
  },
  emailAddresses: [],
  ignoreWhiteList: ignoreWhiteList,
  ignoreBlackList: ignoreBlackList,
  whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
  blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '')  
  }  

  if (source === '2') dataToSend = {
  name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
  templateId: templateData, 
  innerCustomers: {  
  mode: 'all', 
  selectedIds: [], 
  filterId: Number(filterId), 
  isBillingEmails: isBillingEmailsData,  
  isUserEmails: isUserEmailsData, 
  isOtherEmails: isOtherEmailsData
  },
  outerCustomers: {
  accountNumbers: [],
  isBillingEmails: false,
  isUsersEmails: false,
  isOtherEmails: false
  },
  emailAddresses: [],
  ignoreWhiteList: ignoreWhiteList,
  ignoreBlackList: ignoreBlackList,
  whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
  blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '') 
  }  

  if (source === '3') dataToSend = {
  name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
  templateId: templateData, 
  innerCustomers: null,
  outerCustomers: {
  accountNumbers: separatorAN === "E" ? accountNumbers.split(/\r?\n/).filter(i => i !== '') : accountNumbers.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
  isBillingEmails: isBillingEmailsData,
  isUsersEmails: isUserEmailsData,
  isOtherEmails: isOtherEmailsData
  },
  emailAddresses: [],
  ignoreWhiteList: ignoreWhiteList,
  ignoreBlackList: ignoreBlackList,
  whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
  blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '')
  }  

  if (source === '4') dataToSend = {
  name: name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name,
  templateId: templateData, 
  innerCustomers: null,
  outerCustomers: {
  accountNumbers: [],
  isBillingEmails: false,
  isUsersEmails: false,
  isOtherEmails: false
  },
  emailAddresses: separatorEA === "E" ? emailAddresses.split(/\r?\n/).filter(i => i !== '') : emailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
  ignoreWhiteList: ignoreWhiteList,
  ignoreBlackList: ignoreBlackList,
  whiteListEmailAddresses: separatorWL === "E" ? whiteListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : whiteListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
  blackListEmailAddresses: separatorBL === "E" ? blackListEmailAddresses.split(/\r?\n/).filter(i => i !== '') : blackListEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== '')
  }  

  //console.log(dataToSend)

  // Zavolání API

  await axios.post(getUrl('URL_BULK_EMAILS')+'/addresses', dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
  ).then(response => {
  // Nastavovačky, co se má v případě, že je to OK, zobrazit
  setShowPreview(false)
  handleOpenDataPreview('D', '', response.data.items)
    


  }).catch(error => {
  setShowPreview(false)
  //const errorMessage:any = (<ErrorAlert errorData={error} />)
  //setError(errorMessage)
  });



  })()
  }

  // Funkce pro odeslání e-mailu
  const handleSendTest = ( 
    templateData:string, 
    name:string,
    testEmailAddresses:string, 
    separatorTEST:string,
    ) => {
  setSendingTestError(false)
  setSendingTest(true);
  setTestSended(false);
  setTestSendedTime('');

  // Zpracování požadavku
  (async () => {

  // Kontrola přihlášení  
  const aToken = await getToken()
  if ((aToken === null) || (aToken === undefined)) { 
  sessionStorage.setItem('automaticLogout', '1')
  props.history.push('/logout')
  }

  let dataToSend:any = {}

  dataToSend = {
  name: (name === '' ? rootStore.templates.find(t => t.id === templateData)?.name : name)+' - TEST',
  templateId: templateData, 
  innerCustomers: null,
  outerCustomers: {
  accountNumbers: [],
  isBillingEmails: false,
  isUsersEmails: false,
  isOtherEmails: false
  },
  emailAddresses: separatorTEST === "E" ? testEmailAddresses.split(/\r?\n/).filter(i => i !== '') : testEmailAddresses.replaceAll(new RegExp('\\r?\\n','g'),"").split(';').filter(i => i !== ''),
  ignoreWhiteList: false,
  ignoreBlackList: false,
  whiteListEmailAddresses: [],
  blackListEmailAddresses: []
  }  

  //console.log(dataToSend)

  // Zavolání API

  await axios.post(getUrl('URL_BULK_EMAILS'), dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
  ).then(response => {
    // Nastavovačky, co se má v případě, že je to OK, zobrazit
    setSendingTest(false)
    setSendingTestError(false)
    setTestSended(true)
    setTestSendedTime(moment().format('DD.MM.YYYY HH:mm:ss'))
  

  }).catch(error => {
    setSendingTest(false)
    setSendingTestError(true)
    //setTestSendedTime(moment().format('DD.MM.YYYY HH:mm:ss'))
  });

  })()
  }

  const [showDataPreview, setShowDataPreview] = useState(false)
  //const [dataPreview, setDataPreview] = useState('')
  const [dataPreviewArray, setDataPreviewArray] = useState([])
  const [dataPreviewArrayOriginal, setDataPreviewArrayOriginal] = useState([])
  const [dataPreviewFilter, setDataPreviewFilter] = useState('')
  const [dataPreviewPage, setDataPreviewPage] = useState(1)

  const [showAddHedgehogs, setShowAddHedgehogs] = useState(false)
  
  //const [dataPreviewSeparator, setDataPreviewSeparator] = useState('')

  const filterDataPreview = (value:string) => {
    setDataPreviewArray(dataPreviewArrayOriginal.filter((i:string) => i.toLowerCase().includes(value!.toLowerCase()) ).slice())
    setDataPreviewPage(1)
    }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        
        setDataPreviewPage(pageNumber)  
      })()
    }  

  const handleOpenDataPreview = (separator:string, dataList:string, dataListArray:any) => {    
    
    let adresses:any = []

    if (separator === 'E') adresses = dataList.split(/\r?\n/).filter(i => i !== '')
    if (separator === 'S') adresses = dataList.split(';').filter(i => i !== '').map(i => i.replaceAll(new RegExp('\\r?\\n','g'),""))
    
    if (separator === 'E') setDataPreviewArrayOriginal(adresses)
    if (separator === 'S') setDataPreviewArrayOriginal(adresses)
    if (separator === 'D') setDataPreviewArrayOriginal(dataListArray)
    
    if (separator === 'E') setDataPreviewArray(adresses)
    if (separator === 'S') setDataPreviewArray(adresses)
    if (separator === 'D') setDataPreviewArray(dataListArray)
    
    
    //setDataPreviewSeparator(separator)
    setDataPreviewFilter('')
    setDataPreviewPage(1)
    setShowDataPreview(true)
    console.log(separator === 'E' ? dataList.split(/\r?\n/) : dataList.replaceAll(new RegExp('\\r?\\n','g'),"").split(';'))
  }

  const handleCloseDataPreview = () => {
    setShowDataPreview(false)
    //setDataPreviewSeparator('')
    setDataPreviewArray([])
  }

  const handleOpenAddHedgehogs = () => {    
    setShowAddHedgehogs(true)
    
  }

  const handleAddHedgehogs = (separator:string, fieldValue:string, setFieldValue:any) => {    
    console.log(fieldValue)
    let adresses:string = fieldValue

    rootStore.hedgehogUsers.filter(hu => hu.checked === true).map((hu,index) => {


      if (separator === 'E') adresses = adresses + '\n' + hu.email
      if (separator === 'S') adresses = adresses + ';\n' + hu.email
    
      return ''
    })

    setFieldValue('testEmailAddresses', adresses)

    rootStore.hedgehogUsers.map((hu,index) => {
      hu.selectItem(false)
      return ''
    })

    handleCloseAddHedgehogs()
    
    //setShowAddHedgehogs(true)
    
  }

  const handleAddAllHedgehogsToWhiteList = (separator:string, fieldValue:string, setFieldValue:any) => {    
    console.log(fieldValue)
    let adresses:string = fieldValue

    rootStore.hedgehogUsers.filter(hu => hu.isEmailConfirmed === true).map((hu,index) => {
      if (separator === 'E') adresses = adresses + '\n' + hu.email
      if (separator === 'S') adresses = adresses + ';\n' + hu.email
      return ''
    })
    setFieldValue('whiteListEmailAddresses', adresses)    
  }

  const handleCloseAddHedgehogs = () => {
    setShowAddHedgehogs(false)
    //setDataPreviewSeparator('')
    //setDataPreviewArray([])
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    source: Yup.string()
    .required('Musíte vybrat zdroj!'),
    templateId: Yup.string()
    .required('Musíte vybrat šablonu!'),
    emailAddresses: Yup.string().when('source', {
      is: '4',
      then: Yup.string()
      .required('E-mailové adresy musí být zadány!'),
      }),
    accountNumbers: Yup.string().when('source', {
      is: '3',
      then: Yup.string()
      .required('Zákaznická čísla musí být zadána!'),
      }),
    customQueryId: Yup.string().when('source', {
      is: '1',
      then: Yup.string()
      .required('Uživatelský filtr musí být vybrán!'),
      }),  
    filterId: Yup.string().when('source', {
      is: '2',
      then: Yup.string()
      .required('Zákaznický seznam musí být vybrán!'),
      }),  
  })   
  return (
    <span>
      {rights &&<>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Rozeslat e-maily</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Rozeslat e-maily</Button>}
        {props.showAs === 'menu' &&<div className="nav-link" style={{cursor: 'pointer'}} onClick={() => { handleShow() } }><div className="sb-nav-link-icon"><i className="fas fa-paper-plane fa-fw"></i></div> Rozesílání e-mailů</div>}
      </>}
      <Modal 
        show={showDataPreview} 
        onHide={handleCloseDataPreview}        
        aria-labelledby="contained-modal-title-vcenter"
        style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Náhled seznamu hodnot</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <FormControl 
            type="text"
            name="filter"
            value={dataPreviewFilter}
            onChange={async (e:any) => { await setDataPreviewFilter(e.target.value); await filterDataPreview(e.target.value); }}
            placeholder='hledaný text'
            autoFocus={true} 
          />
          <br />
          <h5>Seznam hodnot ({Number(dataPreviewArray.length)})</h5>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Hodnota</th>
              </tr>
            </thead>
            <tbody>
              {dataPreviewArray.filter((i,index) => index >= (0+((dataPreviewPage-1)*20)) && index <= (19+((dataPreviewPage-1)*20))).map((item, index) => (
                <tr key={index}>
                  <td>{item}</td>
                </tr>
              ))}
              
            </tbody>
          </Table>

          <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={dataPreviewPage === 0 ? 1:dataPreviewPage }
          itemsCountPerPage={20}
          totalItemsCount={dataPreviewArray.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
          
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Rozeslání e-mailů (Emailing)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Požadavek na rozeslání e-mailů byl úspěšně odeslán!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount
              initialValues={{ 
                name: '',
                templateId: '', 
                isBillingEmails: false,
                isUserEmails: false,
                isOtherEmails: false,
                source: (props.source === null || props.source === undefined) ? '' : props.source,

                ignoreWhiteList: false,
                ignoreBlackList: false,
                whiteListEmailAddresses: '',
                blackListEmailAddresses: '',
                customQueryId: (props.sourceId === null || props.source === undefined) ? '' : props.sourceId,
                filterId: '',
                accountNumbers: '',
                emailAddresses: '',
                separatorAN: 'E',
                separatorEA: 'E',
                separatorWL: 'E',
                separatorBL: 'E',

                testEmailAddresses: rootStore.loggedUsers[0] === undefined ? '' : rootStore.loggedUsers[0]!.email!,
                separatorTEST: 'E',
                temp: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSendEmail(values.isBillingEmails!, 
                                  values.isUserEmails!, 
                                  values.isOtherEmails!, 
                                  values.templateId!, 
                                  values.source!, 
                                  values.customQueryId!, 
                                  values.filterId!, 
                                  values.accountNumbers!, 
                                  values.emailAddresses!, 
                                  values.ignoreWhiteList!, 
                                  values.ignoreBlackList!, 
                                  values.whiteListEmailAddresses!, 
                                  values.blackListEmailAddresses!, 
                                  values.name!,
                                  values.separatorAN!,
                                  values.separatorEA!,
                                  values.separatorWL!,
                                  values.separatorBL!
                                  )              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3} title='Interní název konkrétní rozesílky, který vystupuje pouze ve Správaci operací.'>Název kampaně</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                          //placeholder='Interní název konkrétní rozesílky, který vystupuje pouze ve Správaci operací.'
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="templateId">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mailová šablona</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="templateId" 
                          onChange={handleChange}
                          value={values.templateId!}
                          isValid={touched.templateId && !errors.templateId} 
                          isInvalid={!!errors.templateId}
                          >
                            <option value="">--- Vyberte šablonu ---</option>
                            {templates}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.templateId}
                        </Form.Control.Feedback>
                        {values.templateId !== '' &&<OthersTemplateDetail id={values.templateId} showAs='button' block marginTop='5px' title='Náhled šablony' />}
                      </Col>
                      
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="source">
                    <Form.Row>
                      <FormLabel column lg={3}>Zdroj</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="source" 
                          onChange={handleChange}
                          value={values.source!}
                          isValid={touched.source && !errors.source} 
                          isInvalid={!!errors.source}
                          >
                            <option value="" >--- Vyberte zdroj adres ---</option>
                            <option value="1">Zákazníci získaní z uživatelského filtru</option>
                            <option value="2">Zákazníci získaní ze systémového seznamu</option>
                            <option value="3">Seznam konkrétních zákaznických čísel</option>
                            <option value="4">Seznam konkrétních e-mailových adres</option>

                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.source}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  {values.source === '1' &&
                  <FormGroup controlId="customQueryId">
                    <Form.Row>
                      <FormLabel column lg={3}>Uživatelský filtr</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="customQueryId" 
                          onChange={handleChange}
                          value={values.customQueryId!}
                          isValid={touched.customQueryId && !errors.customQueryId} 
                          isInvalid={!!errors.customQueryId}
                          autoFocus={true}
                          >
                            <option value="" >--- Vyberte uživatelský filtr ---</option>
                            {customQueries}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.customQueryId}
                        </Form.Control.Feedback>
                        {values.customQueryId !== '' &&<DatafiltersCustomersCustomListPreview name={rootEmailingStore.datafiltersCustomersCustomListAll.find(i => i.id === values.customQueryId)?.name!} note={rootEmailingStore.datafiltersCustomersCustomListAll.find(i => i.id === values.customQueryId)?.note!} designer={false} id={values.customQueryId} title='Náhled výsledku uživatelského filtru' marginTop='5px' />}
                      </Col>
                    </Form.Row>  
                  </FormGroup>}

                  {values.source === '2' &&
                  <FormGroup controlId="filterId">
                    <Form.Row>
                      <FormLabel column lg={3}>Zákaznický seznam</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="filterId" 
                          onChange={handleChange}
                          value={values.filterId!}
                          isValid={touched.filterId && !errors.filterId} 
                          isInvalid={!!errors.filterId}
                          autoFocus={true}
                          >
                            <option value="" >--- Vyberte zákaznický seznam ---</option>

                            <option value="1">Pozvaní - neregistrovaní</option>
                            <option value="2">Dosud nepozvaní</option>
                            <option value="3">Registrovaní</option>
                            <option value="4">Registrovaní - bez aktivace</option>
                            <option value="5">Neaktivní</option>
                            <option value="6">Aktivní - bez objednávek upgrade</option>
                            

                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.filterId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>}
                  
                  {values.source === '3' &&
                  <>
                  <FormGroup controlId="accountNumbers">
                    <Form.Row>
                      <FormLabel column lg={3}>Zákaznická čísla</FormLabel>
                      <Col>
                        <FormControl  as="textarea" style={{height: "150px"}}
                          type="text"
                          name="accountNumbers"
                          value={values.accountNumbers}
                          onChange={handleChange}
                          isValid={touched.accountNumbers && !errors.accountNumbers} 
                          isInvalid={!!errors.accountNumbers}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.accountNumbers}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="separatorAN">
                    <Form.Row>
                      <FormLabel column lg={3} style={{marginTop: '5px'}}>Oddělovač</FormLabel>
                      <Col style={{marginTop: '5px'}}>
                      <Form.Control as="select" 
                          name="separatorAN" 
                          onChange={handleChange}
                          value={values.separatorAN!}
                          isValid={touched.separatorAN && !errors.separatorAN} 
                          isInvalid={!!errors.separatorAN}
                          >
                            <option value="E">Enter</option>
                            <option value="S">Středník</option>
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.separatorAN}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3} style={{marginTop: '5px'}}>
                        <Button variant="outline-primary" onClick={() => { handleOpenDataPreview(values.separatorAN, values.accountNumbers, []) }} >Náhled seznamu</Button>
                      </Col>

                    </Form.Row>  
                  </FormGroup>

                  </>}

                  {values.source === '4' &&
                  <>
                  <FormGroup controlId="emailAddresses">
                    <Form.Row>
                      <FormLabel column lg={3}>Konkrétní e-maily</FormLabel>
                      <Col>
                        <FormControl  as="textarea" style={{height: "150px"}}
                          type="text"
                          name="emailAddresses"
                          value={values.emailAddresses}
                          onChange={handleChange}
                          isValid={touched.emailAddresses && !errors.emailAddresses} 
                          isInvalid={!!errors.emailAddresses}
                          autoFocus={true}
                          />
                        <Form.Control.Feedback type="invalid">
                          {errors.emailAddresses}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="separatorEA">
                    <Form.Row>
                      <FormLabel column lg={3} style={{marginTop: '5px'}}>Oddělovač</FormLabel>
                      <Col style={{marginTop: '5px'}}>
                      <Form.Control as="select" 
                          name="separatorEA" 
                          onChange={handleChange}
                          value={values.separatorEA!}
                          isValid={touched.separatorEA && !errors.separatorEA} 
                          isInvalid={!!errors.separatorEA}
                          >
                            <option value="E">Enter</option>
                            <option value="S">Středník</option>
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.separatorEA}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3} style={{marginTop: '5px'}}>
                        <Button variant="outline-primary" onClick={() => { handleOpenDataPreview(values.separatorEA, values.emailAddresses, []) }} >Náhled seznamu</Button>
                      </Col>

                    </Form.Row>  
                  </FormGroup>
                  </>}

                 
                  {['1','2','3'].includes(values.source)  && <>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Odeslat na adresy</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="isBillingEmails">
                    <Form.Row>
                      <FormLabel column lg={3}>Fakturační</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isBillingEmails"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isBillingEmails}
                          
                          isValid={touched.isBillingEmails && !errors.isBillingEmails} 
                          isInvalid={!!errors.isBillingEmails}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isBillingEmails}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isUserEmails">
                    <Form.Row>
                      <FormLabel column lg={3}>Uživatelské</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isUserEmails"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isUserEmails}
                          
                          isValid={touched.isUserEmails && !errors.isUserEmails} 
                          isInvalid={!!errors.isUserEmails}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isUserEmails}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isOtherEmails">
                    <Form.Row>
                      <FormLabel column lg={3}>Ostatní</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isOtherEmails"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isOtherEmails}
                          
                          isValid={touched.isOtherEmails && !errors.isOtherEmails} 
                          isInvalid={!!errors.isOtherEmails}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isOtherEmails}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  </>}

                  <Alert variant="secondary" style={{marginTop: '10px'}}>

                  {<Button 
                      variant='secondary' 
                      size='sm' 
                      title='Nastavení a další možnosti'
                      style={{lineHeight: '1.15', minWidth: '230px', marginBottom: '10px'}} 
                      onClick={(e) => { setShowOtherSettings(!showOtherSettings); }}
                      >{!showOtherSettings ? <span><div style={{float:'right'}}>{arrowDown}</div>Nastavení WhiteListů a BlackListů&nbsp;</span>:<><div style={{float:'right'}}>{arrowUp}</div>Nastavení WhiteListů a BlackListů&nbsp;</>}
                    </Button>}


                  {showOtherSettings && <>

                  
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Interní WhiteList</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="ignoreWhiteList">
                    <Form.Row>
                      <FormLabel column lg={3}>Ignorovat</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="ignoreWhiteList"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.ignoreWhiteList}
                          
                          isValid={touched.ignoreWhiteList && !errors.ignoreWhiteList} 
                          isInvalid={!!errors.ignoreWhiteList}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ignoreWhiteList}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Interní BlackList</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="ignoreBlackList">
                    <Form.Row>
                      <FormLabel column lg={3}>Ignorovat</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="ignoreBlackList"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.ignoreBlackList}
                          
                          isValid={touched.ignoreBlackList && !errors.ignoreBlackList} 
                          isInvalid={!!errors.ignoreBlackList}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ignoreBlackList}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>



                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Externí WhiteList</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="whiteListEmailAddresses">
                    <Form.Row>
                      <FormLabel column lg={3}>Seznam adres</FormLabel>
                      <Col>
                        <FormControl  as="textarea" style={{height: "150px"}}
                          type="text"
                          name="whiteListEmailAddresses"
                          value={values.whiteListEmailAddresses}
                          onChange={handleChange}
                          isValid={touched.whiteListEmailAddresses && !errors.whiteListEmailAddresses} 
                          isInvalid={!!errors.whiteListEmailAddresses}
                          autoFocus={true}
                        />

                        <Button size='sm' block style={{marginTop: '5px'}} variant='secondary' onClick={() => { handleAddAllHedgehogsToWhiteList( values.separatorWL, values.whiteListEmailAddresses, setFieldValue ) } }>Přidat všechny zaměstnance JSW</Button>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.whiteListEmailAddresses}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="separatorWL">
                    <Form.Row>
                      <FormLabel column lg={3} style={{marginTop: '5px'}}>Oddělovač</FormLabel>
                      <Col style={{marginTop: '5px'}}>
                      <Form.Control as="select" 
                          name="separatorWL" 
                          onChange={handleChange}
                          value={values.separatorWL!}
                          isValid={touched.separatorWL && !errors.separatorWL} 
                          isInvalid={!!errors.separatorWL}
                          >
                            <option value="E">Enter</option>
                            <option value="S">Středník</option>
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.separatorWL}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3} style={{marginTop: '5px'}}>
                        <Button variant="outline-primary" onClick={() => { handleOpenDataPreview(values.separatorWL, values.whiteListEmailAddresses, []) }} >Náhled seznamu</Button>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Externí BlackList</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="blackListEmailAddresses">
                    <Form.Row>
                      <FormLabel column lg={3}>Seznam adres</FormLabel>
                      <Col>
                        <FormControl  as="textarea" style={{height: "150px"}}
                          type="text"
                          name="blackListEmailAddresses"
                          value={values.blackListEmailAddresses}
                          onChange={handleChange}
                          isValid={touched.blackListEmailAddresses && !errors.blackListEmailAddresses} 
                          isInvalid={!!errors.blackListEmailAddresses}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.blackListEmailAddresses}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="separatorBL">
                    <Form.Row>
                      <FormLabel column lg={3} style={{marginTop: '5px'}}>Oddělovač</FormLabel>
                      <Col style={{marginTop: '5px'}}>
                      <Form.Control as="select" 
                          name="separatorBL" 
                          onChange={handleChange}
                          value={values.separatorBL!}
                          isValid={touched.separatorBL && !errors.separatorBL} 
                          isInvalid={!!errors.separatorBL}
                          >
                            <option value="E">Enter</option>
                            <option value="S">Středník</option>
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.separatorBL}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3} style={{marginTop: '5px'}}>
                        <Button variant="outline-primary" onClick={() => { handleOpenDataPreview(values.separatorBL, values.blackListEmailAddresses, []) }} >Náhled seznamu</Button>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                  </>}

                  </Alert>

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Odeslání testovacího e-mailu</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="testEmailAddresses">
                    <Form.Row>
                      <FormLabel column lg={3}>Seznam adres</FormLabel>
                      <Col>
                        <FormControl  as="textarea" style={{height: "150px"}}
                          type="text"
                          name="testEmailAddresses"
                          value={values.testEmailAddresses!}
                          onChange={handleChange}
                          isValid={touched.testEmailAddresses && !errors.testEmailAddresses} 
                          isInvalid={!!errors.testEmailAddresses}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.testEmailAddresses}
                        </Form.Control.Feedback>
                        <Button size='sm' block style={{marginTop: '5px'}} variant='secondary' onClick={() => { handleOpenAddHedgehogs() } }>Přidat zaměstnance JSW</Button>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="separatorTEST">
                    <Form.Row>
                      <FormLabel column lg={3} style={{marginTop: '5px'}}>Oddělovač</FormLabel>
                      <Col style={{marginTop: '5px'}}>
                      <Form.Control as="select" 
                          name="separatorTEST" 
                          onChange={handleChange}
                          value={values.separatorTEST!}
                          isValid={touched.separatorTEST && !errors.separatorTEST} 
                          isInvalid={!!errors.separatorTEST}
                          >
                            <option value="E">Enter</option>
                            <option value="S">Středník</option>
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.separatorTEST}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={3} style={{marginTop: '5px'}}>
                        <Button variant="outline-primary" onClick={() => { handleOpenDataPreview(values.separatorTEST, values.testEmailAddresses!, []) }} >Náhled seznamu</Button>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <Button variant="primary" disabled={!(isValid) || sendingTest || loading } block onClick={() => { handleSendTest(values.templateId!, values.name!, values.testEmailAddresses!, values.separatorTEST!) }} >{sendingTest ? 'Odesílám testovací e-maily...' : 'Odeslat testovací e-maily'}</Button>

                  <Alert variant="info" show={testSended} style={{marginTop: '10px'}}>
                    <strong>Testovací e-maily byly úspěšně {testSendedTime} odeslány!​</strong><br />
                    Zkontrolujte si, zda e-mail dorazil a zda je v pořádku. V případě, že některý e-mail nedorazil, zkontrolujte si, zda je adresa správně zapsaná a zda je v seznamu použit správný oddělovač. <br />V případě, že je vše v pořádku, můžete provést ostré odeslání.
                  </Alert>
                  <Alert variant="danger" show={sendingTestError} style={{marginTop: '10px'}}>
                    <strong>Při odeslání testovacích e-mailů došlo k chybě!​</strong><br />
                    Zkontrolujte si, zda je adresa správně zapsaná a zda je v seznamu použit správný oddělovač.
                  </Alert>    



                  
                  {error}
                  <br />
                  <ButtonGroup className="btn-block">
                    <Button type="submit" variant="success" disabled={!(isValid) || loading || !(testSended)} block>{loading ? 'Rozesílám všechny e-maily...' : 'Rozeslat všechny e-maily'}</Button>
                    <Button variant="outline-primary" onClick={() => { handleShowAdresses(values.isBillingEmails!, 
                                  values.isUserEmails!, 
                                  values.isOtherEmails!, 
                                  values.templateId!, 
                                  values.source!, 
                                  values.customQueryId!, 
                                  values.filterId!, 
                                  values.accountNumbers!, 
                                  values.emailAddresses!, 
                                  values.ignoreWhiteList!, 
                                  values.ignoreBlackList!, 
                                  values.whiteListEmailAddresses!, 
                                  values.blackListEmailAddresses!, 
                                  values.name!,
                                  values.separatorAN!,
                                  values.separatorEA!,
                                  values.separatorWL!,
                                  values.separatorBL!
                                  )  }} disabled={!(isValid)}>{showPreview ? 'Načítám adresy...':'Seznam adres'}</Button>
                  </ButtonGroup>

                  <Modal 
                    show={showAddHedgehogs} 
                    onHide={handleCloseAddHedgehogs}        
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Přidání zaměstnanců JSW</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th className="jsw-table-head" style={{width: '30px'}}></th>
                            <th className="jsw-table-head">Jméno</th>
                            <th className="jsw-table-head">E-mail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rootStore.hedgehogUsers.filter(u => u.isEmailConfirmed === true).map((item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={item.checked}
                                  className=""
                                  id={'rowcheck'+item.id}
                                  onChange={() => {item.selectItem(!item.checked); setFieldValue('temp', new Date().toISOString) }}
                                  style={{height: '18px', width: '18px'}}
                                />
                              </td>
                              <td>{item.fullName}</td>
                              <td>{item.email}</td>
                            </tr>
                          ))}
                          
                        </tbody>
                      </Table>
                      <Button variant="success" block onClick={() => { handleAddHedgehogs(values.separatorTEST, values.testEmailAddresses, setFieldValue) }} >Přidat vybrané zaměstnance</Button>

                  </Modal.Body>
                  </Modal>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(EmailingSendEmails))

import { types } from 'mobx-state-tree'
//import * as Constants from '../Constants/Constants';
import axios from 'axios';
import { getUrl } from '../Utils/Common';

export const Order = types
  .model({
    clientId: types.optional(types.string, ''),
    orderDate: types.optional(types.Date, 20200315),
    software: types.optional(types.string, ''),
    version: types.optional(types.string, ''),
    licenceActual: types.optional(types.string, ''),
    licenceNew: types.optional(types.string, ''),
    systemActual: types.optional(types.number, 0),
    systemNew: types.optional(types.number, 0),
    ucetnictviActual: types.optional(types.number, 0),
    ucetnictviNew: types.optional(types.number, 0),
    totalPrice: types.optional(types.number, 0),
    lastChangeDate: types.optional(types.Date, 20200303),
    //feId: types.identifierNumber,
    id: types.optional(types.string, '')
  })
  .views(self => ({
    get getTotalPrice() {
      var uS:number = 3000
      var tP:number = 0
      tP = self.ucetnictviNew*uS


      return `${tP}`
    }
  }))
  .actions(self => ({
    setLicenceNew(newLicence: string | undefined) {
      if (newLicence) {
        axios.post(getUrl('ORDER_UPDATE_URL'), { id: self.id, licenceNew: newLicence }).then(response => {
         console.log(response.data) 
        }).catch(error => {
          
        });
        self.licenceNew = newLicence

      }
    },
    setSystemNew(newSystem: number | undefined) {
      if (newSystem) {
        axios.post(getUrl('ORDER_UPDATE_URL'), { id: self.id, systemNew: newSystem }).then(response => {
         console.log(response.data) 
        }).catch(error => {
          
        });

        self.systemNew = newSystem
      }
    },
    setUcetnictviNew(newUcetnictvi: number | undefined) {
      if (newUcetnictvi) {
        axios.post(getUrl('ORDER_UPDATE_URL'), { id: self.id, ucetnictviNew: newUcetnictvi }).then(response => {
         console.log(response.data) 
        }).catch(error => {
          
        });
        self.ucetnictviNew = newUcetnictvi

      }
    }
  }))

import { types } from 'mobx-state-tree'

const Customers = types.model('Customers',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  companyRegistrationNumber: types.optional(types.string, ''),
  taxIdentificationNumber: types.optional(types.string, ''),
  isTaxAble: types.optional(types.boolean, false),
  isActive: types.optional(types.boolean, false),
})

export const Accounts = types
  .model({
    id: types.optional(types.string, ''),
    email: types.optional(types.string,''),
    surName: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    phone: types.maybeNull(types.string),
    isEmailConfirmed: types.optional(types.boolean, false),
    isEmployee: types.optional(types.boolean, false),
    isDisabled: types.optional(types.boolean, false),
    roles: types.optional(types.array(types.string), []),
    userGroups: types.optional(types.array(types.string), []),
    customers: types.array(Customers),  
  })
  .views(self => ({
    get fullName() {
      return `${self.firstName} ${self.surName}`
    },
    get fullNameBySurName() {
      return `${self.surName} ${self.firstName}`
    }
  }))
  .actions(self => ({
    setCustomers(customers: any) {
      self.customers = customers
    },
    setRoles(roles:any) {
      self.roles = roles
    },
    setUserGroups(groups:any) {
      self.userGroups = groups
    },
    setIsEmployee(isEmployee:boolean) {
      self.isEmployee = isEmployee
    }
  }))

import { types } from 'mobx-state-tree'

const IncludedConditions3 = types.model('IncludedConditions3',{
  id: types.optional(types.string, ''),
  isSystem: types.optional(types.boolean,false),
  props1: types.maybeNull(types.number),
  props2: types.maybeNull(types.number),
  operatorBefore: types.optional(types.string,'and'),
  //includedConditions: types.maybeNull(types.array(self.)),
})
.actions(self => ({
  changeOperator(operatorBefore:string) {
    if (operatorBefore === 'and') self.operatorBefore = 'or'
    if (operatorBefore === 'or') self.operatorBefore = 'and' 
  }
}))

const IncludedConditions2 = types.model('IncludedConditions2',{
  id: types.optional(types.string, ''),
  isSystem: types.optional(types.boolean,false),
  props1: types.maybeNull(types.number),
  props2: types.maybeNull(types.number),
  operatorBefore: types.optional(types.string,'and'),
  includedConditions: types.maybeNull(types.array(IncludedConditions3)),
})
.actions(self => ({
  addCondition(id:string,isSystem:boolean,props1:number,props2:number,operatorBefore:string) {
    
    self.includedConditions!.push({
      id: id,
      isSystem: isSystem,
      props1: props1,
      props2: props2,
      operatorBefore: operatorBefore,
      
    })
  },
  changeOperator(operatorBefore:string) {
    if (operatorBefore === 'and') self.operatorBefore = 'or'
    if (operatorBefore === 'or') self.operatorBefore = 'and' 
  },
  deleteCondition(i:any) {
    self.includedConditions!.remove(i)
  }
}))

const IncludedConditions = types.model('IncludedConditions',{
  id: types.optional(types.string, ''),
  isSystem: types.optional(types.boolean,false),
  props1: types.maybeNull(types.number),
  props2: types.maybeNull(types.number),
  operatorBefore: types.optional(types.string,'and'),
  includedConditions: types.maybeNull(types.array(IncludedConditions2)),
})
.actions(self => ({
  addCondition(id:string,isSystem:boolean,props1:number,props2:number,operatorBefore:string) {
    
    self.includedConditions!.push({
      id: id,
      isSystem: isSystem,
      props1: props1,
      props2: props2,
      operatorBefore: operatorBefore,
      includedConditions: [],
    })
  },
  changeOperator(operatorBefore:string) {
    if (operatorBefore === 'and') self.operatorBefore = 'or'
    if (operatorBefore === 'or') self.operatorBefore = 'and' 
  },
  deleteCondition(i:any) {
    self.includedConditions!.remove(i)
  }
}))

export const EmailingConditions = types
  .model({
    id: types.optional(types.string, ''),
    isSystem: types.optional(types.boolean,false),
    props1: types.maybeNull(types.number),
    props2: types.maybeNull(types.number),
    operatorBefore: types.optional(types.string,'and'),
    includedConditions: types.maybeNull(types.array(IncludedConditions)),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    addCondition(id:string,isSystem:boolean,props1:number|null,props2:number|null,operatorBefore:string) {
    
      self.includedConditions!.push({
        id: id,
        isSystem: isSystem,
        props1: props1,
        props2: props2,
        operatorBefore: operatorBefore,
        includedConditions: [],
      })
    },
    changeOperator(operatorBefore:string) {
      if (operatorBefore === 'and') self.operatorBefore = 'or'
      if (operatorBefore === 'or') self.operatorBefore = 'and' 
    },
    deleteCondition(i:any) {
      self.includedConditions!.remove(i)
    }

  }))

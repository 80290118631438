import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Spinner, Row, Badge } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation";

import DatafiltersCustomersCustomListUpdate from './Datafilters.Customers.Custom.List.Update.part'
import DatafiltersCustomersCustomListDetail from './Datafilters.Customers.Custom.List.Detail.part'
import DatafiltersCustomersCustomListAddCondition from './Datafilters.Customers.Custom.List.List.AddCondition.part'
import DatafiltersCustomersCustomListPreview from './Datafilters.Customers.Custom.List.List.Preview.part'

import EmailingSendEmails  from '../../emailingSend/Emailing.SendEmails.part'


import { useEmailingStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../../sharedComponents/filterPanel/FilterPanel.part'

import moment from 'moment'


const DatafiltersCustomersCustomListList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)
  


  // Nastavení práv pro editaci
  const rightsEM:boolean = ((localStorage.getItem('Emailing')! === '1') || (localStorage.getItem('BigHedgehog')! === '1') ) ? true : false
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    await rootEmailingStore.deleteEmailingConditions()
    await rootEmailingStore.unselectDatafiltersCustomersCustomVariables()
    
    sessionStorage.removeItem('autoCloseModal')

    if (sessionStorage.getItem('datafiltersCustomersCustomListSearchValue') !== '') {
      
    }
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete uživatelský dotaz "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání uživatelského dotazu'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  //const rootStore = useStore()
  const rootEmailingStore = useEmailingStore()
  
  
  const items = rootEmailingStore.datafiltersCustomersCustomList.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td>{item.note}</td>
      <td>{item.createdAt !== null && moment(item.createdAt).format("DD.MM.YYYY HH:mm")}</td>
      {rightsEM &&<td>
        <ButtonGroup>
          
      
          {rightsEM &&<>
          <DatafiltersCustomersCustomListPreview name={item.name!} note={item.note!} designer={false} id={item.id} />
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">            
            <DatafiltersCustomersCustomListDetail id={item.id} showAs='drop' /> 
            <DatafiltersCustomersCustomListUpdate id={item.id} showAs='drop' />
            <EmailingSendEmails showAs='drop' source='1' sourceId={item.id} />
            <Dropdown.Divider />
            <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{item.name}</strong><br />
          {item.createdAt !== null && moment(item.createdAt).format("DD.MM.YYYY HH:mm")}<br /><br />
          {item.note}<br />
          
          <br />
        </div>
        <div style={{float: 'right'}}>
        {rightsEM && <>
          <ButtonGroup>
          <DatafiltersCustomersCustomListPreview name={item.name!} note={item.note!} designer={false} id={item.id} />
          
            {rightsEM &&<>  
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                <DatafiltersCustomersCustomListDetail id={item.id} showAs='drop' /> 
                <DatafiltersCustomersCustomListUpdate id={item.id} showAs='drop' /> 
                <EmailingSendEmails showAs='drop' source='1' sourceId={item.id} />
                <Dropdown.Divider />
                <Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,item.name!) } }>Smazat</Dropdown.Item>
              </DropdownButton>  
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST')+'/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootEmailingStore.fetchDatafiltersCustomersCustomList(Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')))
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('datafiltersCustomersCustomListSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootEmailingStore.fetchDatafiltersCustomersCustomList(pageNumber)
        setLoading(false);  
      })()
    }
 
    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('datafiltersCustomersCustomListOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('datafiltersCustomersCustomListSearchPage', String(1))
        await rootEmailingStore.fetchDatafiltersCustomersCustomList(1)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (nameData:string, noteData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST')+'/designer', { name: nameData, note: noteData, data: JSON.stringify(rootEmailingStore.emailingConditions), filterSet: rootEmailingStore.getEmailingConditionsToFinalString() }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootEmailingStore.fetchDatafiltersCustomersCustomList(Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')))
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  const conditions = rootEmailingStore.emailingConditions


  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    name: Yup.string()
      .required('Název musí být vyplněn!')
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>

      <FilterPanel 
        //isCustomer={props.isCustomer} 
        filterPage={'datafiltersCustomersCustomList'} 
        filterSearchArray='datafiltersCustomersCustomListSearchArray' 
        pageVariable='datafiltersCustomersCustomListSearchPage' 
        setPageLoading={setLoading} 
        filterItems={['Name','Note']}  
        title="Seznam uživatelských filtrů nad zákazníky"
        titleCount = {Number(localStorage.getItem('lastDatafiltersCustomersCustomListTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        secondaryLeftButtons={rightsEM &&
          <span title="Přidat filtr">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat filtr
          </Button>
          </span>}
        secondaryRightButtons={<EmailingSendEmails showAs='button' />}  
        />

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam uživatelských filtrů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th title='Unikátní název filtru' className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name' || localStorage.getItem('datafiltersCustomersCustomListOrderBy') === null || localStorage.getItem('datafiltersCustomersCustomListOrderBy') === undefined) ? 'NameDesc' : 'Name'  )}>
                  Název
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name' || localStorage.getItem('datafiltersCustomersCustomListOrderBy') === null || localStorage.getItem('datafiltersCustomersCustomListOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'NameDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th title='Detailní popis filtru, respektive podmínek ve filtru' className="jsw-table-head">Poznámka</th>
                <th title='Datum vytvoření filtru' className="jsw-table-head">Datum vytvoření</th>
                {rightsEM &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('datafiltersCustomersCustomListSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastDatafiltersCustomersCustomListTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání uživatelského filtru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90wf"
        backdrop="static"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Přidání uživatelského filtru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Uživatelský filtr byl úspěšně uložen!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              initialValues={{ 
                name: '', 
                note: '',
                tempItem: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.name!, values.note!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name} 
                          isInvalid={!!errors.name}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl  as="textarea" style={{height: "150px"}}
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <br />
                  <Row>
                    
                    <Col>
                      <Alert variant="info">
                        <strong>Podmínky</strong><br />
                        <br />
                        {conditions.map((item,index) => (
                          <div key={item.id}>
                            {index > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} onClick={async() => { await item.changeOperator(item.operatorBefore); setFieldValue('tempItem', new Date().toISOString) } }>{item.operatorBefore}</Badge><br /></>}
                            {item.isSystem && <div style={{backgroundColor: '#deeafc', padding: '10px', borderRadius: '10px'}}>
                                <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                 {item.includedConditions?.map((itemInside,indexInside) => (
                                  <div key={itemInside.id}>
                                    {indexInside > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} onClick={async() => { await itemInside.changeOperator(itemInside.operatorBefore); setFieldValue('tempItem', new Date().toISOString) } }>{itemInside.operatorBefore}</Badge><br /></>}

                                    {itemInside.isSystem && <div style={{backgroundColor: '#c0d7fa', padding: '10px', borderRadius: '10px'}}>
                                      <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                      {itemInside.includedConditions?.map((itemInside2,indexInside2) => (
                                        <div key={itemInside2.id}>
                                          {indexInside2 > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} onClick={async() => { await itemInside2.changeOperator(itemInside2.operatorBefore); setFieldValue('tempItem', new Date().toISOString) } }>{itemInside2.operatorBefore}</Badge><br /></>}

                                          {itemInside2.isSystem && <div style={{backgroundColor: '#adceff', padding: '10px', borderRadius: '10px'}}>
                                            <strong>( <i>Skupina podmínek</i></strong><br /><div style={{paddingLeft: '20px'}}>
                                            {itemInside2.includedConditions?.map((itemInside3,indexInside3) => (
                                              <div key={itemInside3.id}>
                                                {indexInside3 > 0 &&<><Badge variant="info" style={{cursor: 'pointer'}} onClick={async() => { await itemInside3.changeOperator(itemInside3.operatorBefore); setFieldValue('tempItem', new Date().toISOString) } }>{itemInside3.operatorBefore}</Badge><br /></>}

                                                {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside3.id )?.name}{itemInside3.props1 !== null &&<>&nbsp;({itemInside3.props1}{itemInside3.props2 !== null &&<>-{itemInside3.props2}</>})</>}
                                                {!itemInside3.isSystem && <Badge variant='danger' style={{marginLeft: '5px', cursor:'pointer'}} onClick={async () => { await itemInside2.deleteCondition(itemInside3); setFieldValue('tempItem', new Date().toISOString)}} >&nbsp;&nbsp;X&nbsp;&nbsp;</Badge>}
                                              </div>
                                            ))}
                                            <br />
                                            <DatafiltersCustomersCustomListAddCondition addCondition={itemInside2.addCondition} isSystem={false} refresh={setFieldValue} />
                                            

                                            </div><strong>)</strong>
                                            <Badge variant='danger' style={{marginLeft: '5px', cursor:'pointer'}} onClick={async () => { await itemInside.deleteCondition(itemInside2); setFieldValue('tempItem', new Date().toISOString)}} >&nbsp;&nbsp;X&nbsp;&nbsp;</Badge>
                                              
                                          </div>}


                                          {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside2.id )?.name}{itemInside2.props1 !== null &&<>&nbsp;({itemInside2.props1}{itemInside2.props2 !== null &&<>-{itemInside2.props2}</>})</>}
                                          {!itemInside2.isSystem && <Badge variant='danger' style={{marginLeft: '5px', cursor:'pointer'}} onClick={async () => { await itemInside.deleteCondition(itemInside2); setFieldValue('tempItem', new Date().toISOString)}} >&nbsp;&nbsp;X&nbsp;&nbsp;</Badge>}
                                        </div>
                                      ))}
                                      <br />
                                      <DatafiltersCustomersCustomListAddCondition addCondition={itemInside.addCondition} isSystem={false} refresh={setFieldValue} />
                                      <DatafiltersCustomersCustomListAddCondition addCondition={itemInside.addCondition} isSystem={true} refresh={setFieldValue} />

                                      </div><strong>)</strong>
                                      <Badge variant='danger' style={{marginLeft: '5px', cursor:'pointer'}} onClick={async () => { await item.deleteCondition(itemInside); setFieldValue('tempItem', new Date().toISOString)}} >&nbsp;&nbsp;X&nbsp;&nbsp;</Badge>
                                        
                                    </div>}

                                    {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === itemInside.id )?.name}{itemInside.props1 !== null &&<>&nbsp;({itemInside.props1}{itemInside.props2 !== null &&<>-{itemInside.props2}</>})</>}
                                    {!itemInside.isSystem && <Badge variant='danger' style={{marginLeft: '5px', cursor:'pointer'}} onClick={async () => { await item.deleteCondition(itemInside); setFieldValue('tempItem', new Date().toISOString)}} >&nbsp;&nbsp;X&nbsp;&nbsp;</Badge>}
                                  </div>
                                 ))}
                                <br />
                                <DatafiltersCustomersCustomListAddCondition addCondition={item.addCondition} isSystem={false} refresh={setFieldValue} />
                                <DatafiltersCustomersCustomListAddCondition addCondition={item.addCondition} isSystem={true} refresh={setFieldValue} />

                                </div><strong>)</strong>
                                <Badge variant='danger' style={{marginLeft: '5px', cursor:'pointer'}} onClick={async () => { await rootEmailingStore.deleteEmailingCondition(item); setFieldValue('tempItem', new Date().toISOString)}} >&nbsp;&nbsp;X&nbsp;&nbsp;</Badge>
                                  
                              </div>}
                            {rootEmailingStore.datafiltersCustomersCustomVariables.find(v => v.id === item.id )?.name}{item.props1 !== null &&<>&nbsp;({item.props1}{item.props2 !== null &&<>-{item.props2}</>})</>}
                            {!item.isSystem && <Badge variant='danger' style={{marginLeft: '5px', cursor:'pointer'}} onClick={async () => { await rootEmailingStore.deleteEmailingCondition(item); setFieldValue('tempItem', new Date().toISOString)}} >&nbsp;&nbsp;X&nbsp;&nbsp;</Badge>}
                            </div>
                        ))}

                        <br />
                        <DatafiltersCustomersCustomListAddCondition addCondition={rootEmailingStore.pushEmailingConditions} isSystem={false} refresh={setFieldValue} /> 
                        <DatafiltersCustomersCustomListAddCondition addCondition={rootEmailingStore.pushEmailingConditions} isSystem={true} refresh={setFieldValue} /> 
                        
                        
                        <hr /> 
                        <ButtonGroup className='btn-block'>
                          <DatafiltersCustomersCustomListPreview name={values.name} note={values.note} designer={true} />
                          {Object.keys(conditions).length > 0 &&
                            <Button variant='outline-danger' size='sm' onClick={async() => { await rootEmailingStore.deleteEmailingConditions(); setFieldValue('tempItem', new Date().toISOString) } }>Vymazat všechny podmínky</Button>
                          }
                        </ButtonGroup>
                        

                        
                      </Alert>
                    </Col>
                  </Row>


                                     
                  {error}
                  {Object.keys(conditions).length < 0 &&
                    <strong style={{color: 'red'}}>Uživatelský filtr neobsahuje žádnou podmínku. Není možné ho uložit.<br /></strong>
                  }
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading || Object.keys(conditions).length < 1} block>{loading ? 'Ukládám filtr...' : 'Uložit filtr'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(DatafiltersCustomersCustomListList))

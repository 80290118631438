import { types } from 'mobx-state-tree'

const Points = types.model('Points',{
  id: types.optional(types.string, ''),
  meetingId: types.maybeNull(types.string),
  taskId: types.maybeNull(types.string),
  caption: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  createDate: types.optional(types.string, ''),
  rank: types.maybeNull(types.number),
})

const Readers = types.model('Readers',{
  readDate: types.optional(types.string, ''),
  userId: types.maybeNull(types.string),
})

export const Meetings = types
  .model({
    id: types.optional(types.string, ''),
    userId: types.maybeNull(types.string),
    authorId: types.maybeNull(types.string),
    date: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    duration: types.optional(types.number, 0),
    isPublished: types.optional(types.boolean, false),
    isManagement: types.optional(types.boolean, false),
    categoryId: types.maybeNull(types.string),
    employeeWithAccess: types.maybeNull(types.array(types.string)),
    presentEmployees: types.maybeNull(types.array(types.string)),
    absentEmployees: types.maybeNull(types.array(types.string)),
    taskCount: types.optional(types.number, 0),
    completedTaskCount: types.optional(types.number, 0),
    incompleteTaskCount: types.optional(types.number, 0),
    pointCount: types.optional(types.number, 0),
    
    points: types.array(Points),
    readers: types.array(Readers),

    isActualReaded: types.optional(types.boolean, false),
    
    
  })
  .views(self => ({
    get uncompletedTaskCount() {
      return self.taskCount - self.completedTaskCount
    },

    get isFuture() {
      const meetingDate = new Date(self.date)
      const currentDate = new Date()
      return meetingDate > currentDate
    },

  }))
  .actions(self => ({
    setPoints(points: any) {
      self.points = points
    },
    setReaders(readers: any) {
      self.readers = readers
    },
    setPointCount(count: number) {
      self.pointCount = count
    },

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import OthersTemplateDetail from '../../pages/others/templates/components/Others.Templates.Detail.part'

interface CustomersOtherActionsSendEmailProps {
  mode: string,
  id: string,
  filterId:number,
  showAs:string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined,
  searchValue?:string|undefined,
  searchType?:string|undefined, 
  searchScenario?:string|undefined,
  count?: number|undefined,
  fromCustomerDashboard?: boolean|undefined,
  accountNumber?: string|undefined|null,
  toConcreteEmail?: boolean|undefined,
  concreteEmail?: string
}

const CustomersOtherActionsSendEmail: React.FC<CustomersOtherActionsSendEmailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  const rootStore = useStore()

  // Šablony e-mailů
  const templates = rootStore.templates.map(template => (
    <option key={template.id} value={template.id}>{template.name}</option>
  ))
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    if (props.filtered === true) {
      await rootStore.fetchDatafiltersOffersNewForFilteredActions(Number(props.filterId),props.searchValue,props.searchType,props.searchScenario)
    }
    
    await sessionStorage.removeItem('autoCloseModal')
    if (rights) await rootStore.fetchTemplates('','',true,false,9999,1) 
    await setError(null)
    await setShowInfo(false)
    await setShowForm('')
    await setShow(true)

  }

  // Funkce pro odeslání e-mailu
  const handleSendEmail = (isBillingEmailsData: boolean, isUserEmailsData: boolean, isOtherEmailsData: boolean, templateData:string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend:any = {}

      if (props.fromCustomerDashboard === true) {
        dataToSend = { 
          templateId: templateData, 
          innerCustomers: null,
          outerCustomers: {
            accountNumbers: [props.accountNumber],
            isBillingEmails: isBillingEmailsData,
            isUsersEmails: isUserEmailsData,
            isOtherEmails: isOtherEmailsData
          },
          emailAddresses: [],
          ignoreWhiteList: false,
          ignoreBlackList: false,
          whiteListEmailAddresses: [],
          blackListEmailAddresses: []  
        }
      } 
      if (props.toConcreteEmail === true) {
        dataToSend = { 
          templateId: templateData, 
          innerCustomers: null,
          outerCustomers: {
            accountNumbers: [],
            isBillingEmails: false,
            isUsersEmails: false,
            isOtherEmails: false
          },
          emailAddresses: [props.concreteEmail],
          ignoreWhiteList: false,
          ignoreBlackList: false,
          whiteListEmailAddresses: [],
          blackListEmailAddresses: []  
        }
      }
      if (props.fromCustomerDashboard !== true && props.toConcreteEmail !== true) {
        dataToSend = { 
          templateId: templateData, 
          innerCustomers: {  
              mode: props.mode, 
              selectedIds: props.id === '' ? [] : [props.id], 
              filterId: Number(props.filterId) === 0 ? null : Number(props.filterId), 
              isBillingEmails: isBillingEmailsData, 
              isUserEmails: isUserEmailsData, 
              isOtherEmails: isOtherEmailsData
            },
            outerCustomers: {
              accountNumbers: [],
              isBillingEmails: false,
              isUsersEmails: false,
              isOtherEmails: false
            },
            emailAddresses: [],
            ignoreWhiteList: false,
            ignoreBlackList: false,
            whiteListEmailAddresses: [],
            blackListEmailAddresses: []  
        }


      }



      // Zavolání API
      
      await axios.post(getUrl('URL_BULK_EMAILS'), dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    templateId: Yup.string()
    .required('Musíte vybrat šablonu!'),
  })   
  return (
    <span>
      {rights &&<>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Odeslat e-mail{!(props.fromCustomerDashboard === true || props.toConcreteEmail === true) && ' (vše)'}</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }><i className="fas fa-at fa-fw"></i></Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.mode === 'all' ? 'Hromadné odeslání e-mailu' : 'Odeslání e-mailu'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>E-mail byl úspěšně odeslán!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                templateId: '', 
                isBillingEmails: false,
                isUserEmails: false,
                isOtherEmails: false
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSendEmail(values.isBillingEmails!, values.isUserEmails!, values.isOtherEmails!, values.templateId!)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Odeslat na adresy</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  {props.toConcreteEmail !== true && <>
                  <FormGroup controlId="isBillingEmails">
                    <Form.Row>
                      <FormLabel column lg={3}>Fakturační</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isBillingEmails"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isBillingEmails}
                          
                          isValid={touched.isBillingEmails && !errors.isBillingEmails} 
                          isInvalid={!!errors.isBillingEmails}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isBillingEmails}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isUserEmails">
                    <Form.Row>
                      <FormLabel column lg={3}>Uživatelské</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isUserEmails"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isUserEmails}
                          
                          isValid={touched.isUserEmails && !errors.isUserEmails} 
                          isInvalid={!!errors.isUserEmails}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isUserEmails}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isOtherEmails">
                    <Form.Row>
                      <FormLabel column lg={3}>Ostatní</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isOtherEmails"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isOtherEmails}
                          
                          isValid={touched.isOtherEmails && !errors.isOtherEmails} 
                          isInvalid={!!errors.isOtherEmails}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isOtherEmails}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  </>}
                  {props.toConcreteEmail === true && <>
                  <FormGroup controlId="isOtherEmails">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        {props.concreteEmail}
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  </>}
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column lg={5}><strong>Parametry</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="templateId">
                    <Form.Row>
                      <FormLabel column lg={3}>Šablona</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="templateId" 
                          onChange={handleChange}
                          value={values.templateId!}
                          >
                            <option value="">--- Vyberte šablonu ---</option>
                            {templates}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.templateId}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={2}>
                        {values.templateId !== '' &&<OthersTemplateDetail id={values.templateId} showAs='button' />}
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám e-mail...' : 'Odeslat e-mail'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsSendEmail))

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'
import {Container, Form, Col, Button, Alert, Image} from 'react-bootstrap'


import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'; 

import { getUrl, checkVersionWithoutRestart } from '../../../Utils/Common'
//import * as Constants from '../../../Constants/Constants';

import { Formik } from 'formik'
import * as Yup from 'yup'

function ForgottenPassword(props:any) {
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  window.scrollTo(0, 0) // Odrolování nahoru
  
  // Kontrola zda se načetla URL BE
  checkVersionWithoutRestart()

  /*if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }*/

  sessionStorage.removeItem('automaticLogout')
  // Funkce po odeslání formuláře
  const handleForgottenPassword = (emailData:string) => {
    setError(null)
    setLoading(true)
    
    // Volání požadavku
    axios.post(getUrl('URL_FORGOTTEN_PASSWORD'), { email: emailData }
    ).then(response => {
       // Pokud OK, zobrazit potvrzovací hlášení a skrýt formulář
      setLoading(false)
      setShowInfo(true)
      setShowForm('nodisplay')
    }).catch(error => {
      setLoading(false);
      const errorMessage:any = (<ErrorAlert errorData={error} />)
      setError(errorMessage)
    });
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('E-mailová adresa je ve špatném formátu!')
      .required('E-mail musí být vyplněn!')
  })  

  return (
    <div className="App">
      
      <main>
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    <HomeInfo />
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="jezekswSmall.png" className="login-logo" /> Zákaznický portál</h2>
                    <hr />
                    <br />
                    <h3>Obnova hesla</h3>
                    <div className={showForm} >
                      <p>Zadejte, prosím, Vaší e-mailovou adresu použitou při registraci. Odešleme Vám pokyny k obnově hesla.</p>
                    </div>
                    <Alert variant="success" show={showInfo}>
                      <strong>Odesláno!</strong><br />
                      Na Vámi zadaný e-mail byly odeslány pokyny pro obnovení hesla.
                    </Alert> 
                    <div className={showForm} >
                      <Formik
                        validationSchema={schema}
                        initialValues={{ email: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleForgottenPassword(values.email)              
                            setSubmitting(false);
                          }, 1);
                        }}
                      >
                        {({ 
                          handleSubmit,
                          handleChange,
                          
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting }) => (
                          <Form onSubmit={handleSubmit}>
                            
                            <FormGroup controlId="formEmail">
                              <Form.Row>
                                <FormLabel column lg={2}>Email</FormLabel>
                                <Col>
                                  <FormControl 
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    
                                    isValid={touched.email && !errors.email} 
                                    isInvalid={!!errors.email}
                                  />
                                  
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Row>  
                            </FormGroup>
                            
                            {error}
                            <br />
                            <Button type="submit" variant="success" disabled={!(isValid ) || loading} block>{loading ? 'Probíhá obnovení...' : 'Obnovit heslo'}</Button>
                            
                          </Form>
                        )}
                      </Formik>  
                    </div>  
                    <br /><Link to="/login">Zpět na přihlášení.</Link>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>  
      </main>
    </div>
  );
}

export default ForgottenPassword
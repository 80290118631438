import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUser, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card} from 'react-bootstrap'

//import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../../Utils/Confirmation";



import { useStore, useDialsStore } from '../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'


const AccountsMyAccountPreferences: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)


  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním pozvánky
  const handleOnClickDeletePreference = (key:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete předvolbu "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání předvolby uživatele'})) {
        deleteUserPreference(key)
      } else {
      
      }  
    })()
  }

 

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  
  const loggedUserPreferences = rootStore.loggedUserPreferences

  
  //Počet záznamů
  const countData:number = Object.keys(loggedUserPreferences).length

  // Seznam pozvánek do řádků tabulky
  const linesPreferences = loggedUserPreferences.slice().map(preference => (
    <tr key={preference.key}>
      <td>{preference.keyName}</td>
      <td>
        {preference.key === 'supportDefaultTheme'
          ?
            rootDialsStore.dialsSupportThemes.find(dst => dst.id === preference.value)?.name
          :
            preference.valueName

          }
      </td>

      {rights &&<td>
      
      {rights &&<>
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            
            <Dropdown.Item onClick={() => { handleOnClickDeletePreference(preference.key,preference.keyName!) } }>Smazat</Dropdown.Item>
          </DropdownButton>
          
        </div>
      </>}
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesPreferencesCard = loggedUserPreferences.slice().map((preference) => (
    <div key={preference.key}>
    <Card>
      <Card.Body>
        {rights && <>
          {rights &&<>  
            <div className="float-right">
              <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
                
                <Dropdown.Item onClick={() => { handleOnClickDeletePreference(preference.key,preference.keyName!) } }>Smazat</Dropdown.Item>
              </DropdownButton>  
            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{preference.keyName}</strong><br />
          {preference.key === 'supportDefaultTheme'
          ?
            rootDialsStore.dialsSupportThemes.find(dst => dst.id === preference.value)?.name
          :
            preference.valueName

          }
          
          <br />
          
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   

  // Funkce pro smazání pozvánky
  const deleteUserPreference = (key:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_ACCOUNT')+getUser().id+'/preferences/'+key, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootStore.fetchLoggedUserPreferences()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });
   
      })()    
  }



    
  
  // Funkce pro založení nové pozvánky
  const handleNewPreference = (keyData:string, valueData:string) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      // Zavolání API
      await axios.post(getUrl('URL_ACCOUNT')+getUser().id+'/preferences', { key: keyData, value: valueData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootStore.fetchLoggedUserPreferences()
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

  // Schéma validace formuláře
  const schemaNewPreference = Yup.object().shape({
    key: Yup.string()
    .required('Typ předvolby musí být vyplněn!'),
    value: Yup.string()
    .required('Hodnota předvolby musí být vyplněna!'),

    
  }) 
  
  return (
    <Container fluid>



      <br />


      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam předvoleb neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesPreferencesCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th className="jsw-table-head">Typ nastavení</th>
                <th className="jsw-table-head">Hodnota</th>
                
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesPreferences}
            </tbody>
          </Table>
        </div> 
        <br />  
        
      </div>  
      {rights &&
      <Button variant="success" onClick={handleShow}>
        + Přidat předvolbu
      </Button>
      }

      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání předvolby uživatele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Předvolba uživatele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Předvolba uživatele byla úspěšně přidána!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewPreference}
              initialValues={{ 
                key: '',
                value: ''
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewPreference(values.key!, values.value!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  

                  <FormGroup controlId="key">
                    <Form.Row>
                        <FormLabel column lg={3}>Typ předvolby</FormLabel>
                        <Col>
                          <Form.Control as="select" 
                            name="key" 
                            onChange={handleChange}
                            value={values.key!}
                            isInvalid={!!errors.key}

                            
                            >
                              <option value="">--- Vyberte ---</option>
                              <option value="startPage" disabled={rootStore.getLoggedUserPreferenceByKey('startPage')?.key !== undefined ? true:false}>Startovací stránka po otevření portálu</option>
                              <option value="supportDefaultFilter" disabled={rootStore.getLoggedUserPreferenceByKey('supportDefaultFilter')?.key !== undefined ? true:false}>Výchozí filtr při otevření kontaktů</option>
                              <option value="supportDefaultTheme" disabled={rootStore.getLoggedUserPreferenceByKey('supportDefaultTheme')?.key !== undefined ? true:false}>Automatický výběr tématu u nového kontaktu</option>
                              <option value="meetingsDefaultFilter" disabled={rootStore.getLoggedUserPreferenceByKey('meetingsDefaultFilter')?.key !== undefined ? true:false}>Výchozí filtr při otevření porad</option>
                              <option value="tasksDefaultFilter" disabled={rootStore.getLoggedUserPreferenceByKey('tasksDefaultFilter')?.key !== undefined ? true:false}>Výchozí filtr při otevření úkolů</option>
                              
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.key}
                          </Form.Control.Feedback>
                        </Col>
                    </Form.Row>
                  </FormGroup>

                  <FormGroup controlId="value">
                    <Form.Row>
                        <FormLabel column lg={3}>Hodnota</FormLabel>
                        <Col>
                          <Form.Control as="select" 
                            name="value" 
                            onChange={handleChange}
                            value={values.value!}
                            isInvalid={!!errors.value}

                            
                            >
                              <option value="">--- Vyberte ---</option>
                              {values.key === 'startPage' && <>
                              <option value="O">Přepnout zákazníka</option>
                              <option value="S">Kontakty</option>
                              <option value="L">Poslední navštívená stránka</option>
                              <option value="M">Porady</option>
                              <option value="T">Úkoly</option>
                              </>}
                              {values.key === 'supportDefaultFilter' && <>
                              <option value="MyNotFinish">Moje nevyřešené</option>
                              <option value="MyNotFinishAfterTerm">Moje nevyřešené - po termínu</option>
                              <option value="AllNotFinishAfterTerm">Všechny nevyřešené - po termínu</option>
                              </>}

                              {values.key === 'tasksDefaultFilter' && <>
                              <option value="MyNotFinishTasks">Moje nesplněné</option>
                              <option value="MyNotFinishTasksPrivate">Moje soukromé nesplněné</option>
                              <option value="MyNotFinishTasksAfterTerm">Moje nesplněné - po termínu</option>
                              <option value="AllNotFinishTasksAfterTerm">Všechny nesplněné - po termínu</option>
                              <option value="AuthorNotFinishTasks">Mnou zadané - nesplněné</option>
                              <option value="AuthorNotFinishTasksAfterTerm">Mnou zadané - nesplněné po termínu</option>

                              </>}

                              {values.key === 'meetingsDefaultFilter' && <>
                              <option value="UnreadMeetings">Nepřečtené porady</option>
                              </>}

                              {values.key === 'supportDefaultTheme' && <>
                              {rootDialsStore.dialsSupportThemes
                                .slice()
                                .filter(dst => dst.isActive === true)
                                
                                .map(dsp => (
                                  <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
                                ))}
                              
                              </>}


                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.value}
                          </Form.Control.Feedback>
                        </Col>
                    </Form.Row>
                  </FormGroup>
                  
                                     
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám předvolbu...' : 'Uložit předvolbu'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(AccountsMyAccountPreferences))

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'



import { useDialsStore } from '../../../store'
import { observer } from 'mobx-react'



interface SupportContactsListSupportTypesIconProps {
  supportTypeId: string    
}

const SupportContactsListSupportTypesIcon: React.FC<SupportContactsListSupportTypesIconProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootDialsStore = useDialsStore()

  // Seznam typů kontaktu pro rozevírací seznam
  const supportType = rootDialsStore.dialsSupportTypes.find(dst => dst.id === props.supportTypeId)   

  return (
    <>
    {supportType !== undefined && <>
      {supportType?.name!.includes("Příchozí hovor se zákazníkem") && <span title={supportType?.name!}><i className="fas fa-arrow-right"></i>&nbsp;<i className="fas fa-phone-alt"></i></span>}
      {supportType?.name!.includes("Odchozí hovor se zákazníkem")  && <span title={supportType?.name!}><i className="fas fa-phone-alt"></i>&nbsp;<i className="fas fa-arrow-right"></i></span>}

      {supportType?.name!.includes("Příchozí e-mail od zákazníka") && <span title={supportType?.name!}><i className="fas fa-arrow-right"></i>&nbsp;<i className="fas fa-at"></i></span>}
      {supportType?.name!.includes("Odchozí e-mail zákazníkovi")   && <span title={supportType?.name!}><i className="fas fa-at"></i>&nbsp;<i className="fas fa-arrow-right"></i></span>}

      {supportType?.name!.includes("Příchozí dopis od zákazníka")  && <span title={supportType?.name!}><i className="fas fa-arrow-right"></i>&nbsp;<i className="fas fa-envelope"></i></span>}
      {supportType?.name!.includes("Odchozí dopis zákazníkovi")    && <span title={supportType?.name!}><i className="fas fa-envelope"></i>&nbsp;<i className="fas fa-arrow-right"></i></span>}

      {supportType?.name!.includes("Příchozí placenka")            && <span title={supportType?.name!}><i className="fas fa-phone-alt"></i>&nbsp;<i className="fas fa-coins"></i></span>}

      {supportType?.name!.includes("TeamViewer")                   && <span title={supportType?.name!}><i className="fas fa-tv"></i></span>}
      {supportType?.name!.includes("Meet")                         && <span title={supportType?.name!}><i className="fas fa-tv"></i></span>}
      {supportType?.name!.includes("Teams")                        && <span title={supportType?.name!}><i className="fas fa-tv"></i></span>}
      {supportType?.name!.includes("Zoom")                         && <span title={supportType?.name!}><i className="fas fa-tv"></i></span>}

      {supportType?.name!.includes("Chat se zákazníkem")           && <span title={supportType?.name!}><i className="fas fa-comments"></i></span>}

      {supportType?.name!.includes("Interní")                      && <span title={supportType?.name!}><i className="fas fa-building"></i></span>}

      {supportType?.name!.includes("Jednání")                      && <span title={supportType?.name!}><i className="fas fa-handshake"></i></span>}
      
      
      </>}  
    </>
  )  
  
}

export default withRouter(observer(SupportContactsListSupportTypesIcon))

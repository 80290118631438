import { types } from 'mobx-state-tree'

const Solutions = types.model('Solutions',{
  id: types.optional(types.string, ''),
  authorId: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDone: types.optional(types.boolean, false),
  linkId: types.maybeNull(types.string),
  createDate: types.optional(types.string, ''),
})


const Points = types.model('Points',{
  id: types.optional(types.string, ''),
  taskId: types.maybeNull(types.string),
  responsibleUserId: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDone: types.optional(types.boolean, false),
  doneDate: types.maybeNull(types.string),
  solutions: types.array(Solutions),
  createDate: types.optional(types.string, ''),
})
.actions(self => ({
  setSolutions(solutions: any) {
    self.solutions = solutions
  }

}))

export const Tasks = types
  .model({
    id: types.optional(types.string, ''),
    responsibleUserId: types.maybeNull(types.string),
    authorId: types.maybeNull(types.string),
    deadLine: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    
    isDone: types.optional(types.boolean, false),
    doneDate: types.maybeNull(types.string),

    isCompleted: types.optional(types.boolean, false),
    completedDate: types.maybeNull(types.string),

    isPrivate: types.optional(types.boolean, false),
    meetingId: types.maybeNull(types.string),
    
    points: types.array(Points),
    solutions: types.array(Solutions),
    
    
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    setPoints(points: any) {
      self.points = points
    },
    setSolutions(solutions: any) {
      self.solutions = solutions
    },
    setDone(state: boolean) {
      self.isDone = state
      self.doneDate = new Date().toISOString()
    },
    setCompleted(state: boolean) {
      self.isCompleted = state
      self.completedDate = new Date().toISOString()
    },


  }))

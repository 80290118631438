import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { useDialsStore, useStore } from '../../../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface DialsOperationsPreferencesUpdateProps {
  id: string,
  showAs: string,
}

const DialsOperationsPreferencesUpdate: React.FC<DialsOperationsPreferencesUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Připojení store
  //const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const rootStore = useStore()
  
  let preference = rootDialsStore.dialsOperationsPreferences.find(p => p.id === props.id)


  // Seznam typů kontaktu pro rozevírací seznam
  const supportTypes = rootDialsStore.dialsSupportTypes
  .slice()
  .filter(dst => dst.isActive === true)
  .sort(function(a:any, b:any) {
    var sort1A = a.order; 
    var sort1B = b.order; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  ))   

  // Seznam pracovníků JSW 
  const supportHedgehogs = rootStore.hedgehogUsers
   .filter(hu => hu.isEmailConfirmed === true)
   .slice()
   .sort(function(a:any, b:any) {
      var sort1A = a.surName; 
      var sort1B = b.surName; 

      if (sort1A! < sort1B!) {
        return -1;
      }
      if (sort1A! > sort1B!) {
        return 1;
      }
      return 0;
    })
   .map(hu => (
     <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
   ))  


  // Seznam typů kontaktu pro rozevírací seznam
  const supportThemes = rootDialsStore.dialsSupportThemes
  .slice()
  .filter(dst => dst.isActive === true)
  .sort(function(a:any, b:any) {
    var sort1A = a.order; 
    var sort1B = b.order; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  ))  

  // Seznam typů kontaktu pro rozevírací seznam
  const services = rootDialsStore.dialsServices
  .slice()
  .map(dsp => (
    <option key={dsp.id} value={dsp.id}>{dsp.name}</option>
  ))  

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    preference = rootDialsStore.dialsOperationsPreferences.find(p => p.id === props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
  // Funkce pro změnu adresy
  const handleUpdate = (valueData:string) => {
    setError(null);
    //setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_DIALS_OPERATIONS_PREFERENCES')+'/'+props.id, { id: props.id, value: valueData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1')
          rootDialsStore.fetchDialsOperationsPreferences()

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')
            //setShowInfo(false)
            //setShowForm('')
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });

        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    value: Yup.string()
    .required('Hodnota musí být vyplněna!'),
    
  })   
  return (
    <>
      {rightsBH && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Upravit</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava preference</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Preference byla úspěšně upravena!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                value: preference?.value 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.value!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="name">
                    <Form.Row>
                      <FormLabel column lg={3}>{preference?.keyName}</FormLabel>
                      <Col>
                      <Form.Control as="select" 
                            name="value" 
                            onChange={handleChange}
                            value={values.value!}
                            isInvalid={!!errors.value}
                            >
                              <option value="">--- Vyberte ---</option>
                              {preference?.valueType === 'service' && services}
                              {preference?.valueType === 'user' && supportHedgehogs}
                              {preference?.valueType === 'supportTheme' && supportThemes}
                              {preference?.valueType === 'supportType' && supportTypes}
                          </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.value}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                
                  {error}
                  
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(DialsOperationsPreferencesUpdate))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//import { getToken } from '../../../../Utils/Common'
// eslint-disable-next-line
//import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'
import OthersTemplateUpdate from './Others.Templates.Update.part'
import { Button, Modal, Dropdown } from 'react-bootstrap'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

interface OthersTemplateDetailProps {
  id: string,
  showAs: string,
  block?: boolean,
  marginTop?: string,
  title?: string
}

const OthersTemplateDetail: React.FC<OthersTemplateDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('Emailing')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  let template = rootStore.getTemplateById(props.id)

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    await rootStore.fetchTemplateById(props.id)
    template = rootStore.getTemplateById(props.id)
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }
   
  return (
    <>
      {rights && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Detail</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" block={props.block === true ? true:false} style={{marginTop: props.marginTop !== undefined ? props.marginTop : '0px'}} variant="outline-primary" onClick={() => { handleShow() } }>{props.title !== undefined ? props.title : 'Detail'}</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail šablony e-mailu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Předmět:&nbsp;</strong>{template?.subject}<br /><br />
          <div className="content" dangerouslySetInnerHTML={{__html: template?.htmlText!}}></div>
        </Modal.Body>
        <Modal.Footer>
          <div className="float-left nodisplay"><OthersTemplateUpdate id={template?.id!} showAs='button' /></div>
        </Modal.Footer>
      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(OthersTemplateDetail))

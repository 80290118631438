import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
import { getToken, getUrl } from '../../../../Utils/Common'
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'
import {Card, Button, Badge, Modal, Alert, DropdownButton, ButtonGroup} from 'react-bootstrap'

import { useStore, useUpgradeStore } from '../../../../store'
import { observer } from 'mobx-react'

import moment from 'moment'

import { confirm } from "../../../../Utils/Confirmation"
//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';

import CustomersDocumentsSendTestMailProforma from './Customers.Documents.SendTestMailProforma.part'
import CustomersDocumentsSendTestMailFinalDocuments from './Customers.Documents.SendTestMailFinalDocuments.part'

import CustomersDocumentsGenerateVerification from './Customers.Documents.GenerateVerification.part'


const CustomersDocumentsListArchive: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  const [showEO, setShowEO] = useState(false)
  
  // Otevírání a zavírání modálního okna 
  const handleClose = () => { 
    setShowE(false)
    setShowEO(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }  
  
  // Načtení Store
  const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()

  // Nastavení práv zda jsme Ježci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  //const rightsBU:boolean = ((localStorage.getItem('BigUser')! === '1')) ? true : false  

  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  //Počet záznamů
  //const countData:number = Object.keys(rootStore.customerOpenedDocuments).length
  let countDoc:number = 0

  //Pořadí dokumentů
  const documentOrder = (documentType:string) => {
    let order:number = 99
    if (documentType === "Z") order = 4
    if (documentType === "P") order = 3
    if (documentType === "K") order = 2
    if (documentType === "L") order = 1
    return order
  }




  const customerDocumentsCard = rootUpgradeStore.upgradeOffersScenarios.filter(doc => doc.isActive === false)
  .slice()
  .sort(function(a:any, b:any) {
    var sortA = a.isActive; 
    var sortB = b.isActive; 

    var sort1A = a.program.name; 
    var sort1B = b.program.name; 

    var sort2B = a.programVersion.version; 
    var sort2A = b.programVersion.version; 
    
    if (sortA! < sortB!) {
      return 1;
    }
    if (sortA! > sortB!) {
      return -1;
    }
    
    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }
    if (sort2A! < sort2B!) {
      return -1;
    }
    if (sort2A! > sort2B!) {
      return 1;
    }


    return 0;
  })
  .map(scenario => {
    if (rootStore.getCustomerDocumentsCountByScenarioId(scenario.id) > 0) 
    
    {
      countDoc = 1
      return (
      <div key={scenario.id}>
      <Card
        bg='light'
        text='dark'
      >
        <Card.Header as="h5">
          <img className="noprint" alt="logo programu" src={"images/ico-header-"+scenario.program.code+".png"} style={{marginBottom: '5px', marginRight: '15px' }} />
          {scenario.program.name} {scenario.programVersion.version}

          {rightsBH &&
        <div style={{float: 'right'}}>
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
        
        {Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === scenario.id && documents.documentType === 'Z')).length > 0 && 
        <>
          <CustomersDocumentsSendTestMailProforma programId={scenario.program.id!} upgradeScenarioId={scenario.id} showAs='drop' />
          
        </>}  
        {
          ((Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === scenario.id && documents.documentType === 'L')).length > 0 
          &&
          Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === scenario.id && documents.documentType === 'Z')).length > 0 
          ) || 
          (Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === scenario.id && documents.documentType === 'L')).length > 0 
          &&
          Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === scenario.id && documents.documentType === 'Z')).length === 0
          &&
          Object.keys(rootStore.customerOpenedDocuments.filter(documents => documents.upgradeScenarioId === scenario.id && documents.documentType === 'K')).length === 0)
          
          ) &&
        <>
          <CustomersDocumentsSendTestMailFinalDocuments programId={scenario.program.id!} upgradeScenarioId={scenario.id} showAs='drop' />
        </>}  
          <CustomersDocumentsGenerateVerification program={scenario.program.name!+' '+scenario.programVersion.version} programCode={scenario.program.code!} upgradeScenarioId={scenario.id} showAs='drop' />
        </DropdownButton>
        </div>}

        </Card.Header>
        <Card.Body >
          
          {rootStore.customerOpenedDocuments
            .filter(documents => documents.upgradeScenarioId === scenario.id)
            .filter(
              data => {
                if (scenario.programVersion.version! > rootStore.getCustomerOpenedLicensesActiveActual().find(l => l.license.program.id === scenario.program.id)?.license.programVersion.version!) 
                  {
                    //console.log(scenario.programVersion.version!)
                    //console.log(rootStore.getCustomerOpenedLicensesActiveActual().find(l => l.program.id === scenario.program.id)?.programVersion.version!)
                    if (data.isPublished === true) return data.documentType !== 'L' 
                    else return data
                  }
                else 
                  return data 
              })
            .slice()
            .sort(function(a:any, b:any) {
              var sort1A = documentOrder(a.documentType); 
              var sort1B = documentOrder(b.documentType); 
          
               
              if (sort1A! < sort1B!) {
                return -1;
              }
              if (sort1A! > sort1B!) {
                return 1;
              }          
              return 0;
            })
            .map(customerOpenedDocument => { return (
              <div key={customerOpenedDocument.id}  className={(!rightsBH && customerOpenedDocument.documentType === 'Z' && (customerOpenedDocument.paymentDate === '' || customerOpenedDocument.paymentDate === null)) ? 'nodisplay':''} >
              
                  <h5>
                    {customerOpenedDocument.documentType === 'Z' && <span>Zálohová faktura</span>}
                    {customerOpenedDocument.documentType === 'P' && <span>Daňový doklad na přijatou platbu</span>}
                    {customerOpenedDocument.documentType === 'K' && <span>Faktura</span>}
                    {customerOpenedDocument.documentType === 'L' && <span>Licenční lístek (registrační údaje)</span>}
                    
                  </h5>
                  {customerOpenedDocument.documentType === 'L' || (customerOpenedDocument.paymentDate === '' || customerOpenedDocument.paymentDate === null) ? '':
                  <Badge style={{marginBottom: '10px'}} variant='info'>Uhrazeno {moment(customerOpenedDocument.paymentDate).format("D.M.YYYY")}</Badge> 
                  }
                  {customerOpenedDocument.documentType === 'L' || (customerOpenedDocument.paymentDate === '' || customerOpenedDocument.paymentDate === null) ? '': <br />}
                  
                  <Button size="sm" style={{textAlign: 'left', display: 'none'}} onClick={async () => { 
                    await rootStore.downloadFileFromApi(
                      customerOpenedDocument.documentType === 'Z' ? 
                        customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-zalohova-faktura-'+customerOpenedDocument.proformaConfirmationNumber+'.pdf' :
                        customerOpenedDocument.documentType === 'P' ? 
                          customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-danovy-doklad-'+customerOpenedDocument.paymentConfirmationNumber+'.pdf' :
                          customerOpenedDocument.documentType === 'K' ? 
                            customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-faktura-'+customerOpenedDocument.paymentConfirmationNumber+'.pdf' :
                            customerOpenedDocument.documentType === 'L' ? 
                              customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-registracni-udaje.pdf' : 'dokument.pdf'
                          
                    ,
                    customerOpenedDocument.documentName
                      );
                    }
                    }><i className="fas fa-file-download"></i>&nbsp;&nbsp;{customerOpenedDocument.documentType === 'Z' ? 
                    customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-zalohova-faktura-'+customerOpenedDocument.proformaConfirmationNumber+'.pdf' :
                    customerOpenedDocument.documentType === 'P' ? 
                      customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-danovy-doklad-'+customerOpenedDocument.paymentConfirmationNumber+'.pdf' :
                      customerOpenedDocument.documentType === 'K' ? 
                        customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-faktura-'+customerOpenedDocument.paymentConfirmationNumber+'.pdf' :
                        customerOpenedDocument.documentType === 'L' ? 
                          customer?.accountNumber+'-'+scenario.program.name+'-'+scenario.programVersion.version+'-registracni-udaje.pdf' : 'dokument.pdf'
                   }</Button>

                  <Button size="sm" style={{textAlign: 'left'}} onClick={async () => { 
                    await openDocument(customerOpenedDocument.documentName,customerOpenedDocument.id);
                    }
                    }><i className="fa fa-file-pdf fa-fw"></i>&nbsp;&nbsp;Zobrazit PDF</Button>
                    {rightsBH && <span>&nbsp;&nbsp;</span>}
                    {rightsBH &&
                      <Button size="sm" variant="outline-danger" style={{textAlign: 'left'}} onClick={() => {handleOnClickDeleteDocument(customerOpenedDocument.id,customerOpenedDocument.documentType === 'Z' ? 'Zálohová faktura' : customerOpenedDocument.documentType === 'P' ? 'Daňový doklad na přijatou platbu' : customerOpenedDocument.documentType === 'K' ? 'Konečná faktura' : customerOpenedDocument.documentType === 'L' ? 'Licenční lístek' : '')}}>Smazat dokument</Button>
                    }
                    <br /><br />
              </div>
            )
          })
        }



        </Card.Body>
      </Card>
      <br />
      </div>
      )
    }
    else return ''
  })

// Dotaz před smazáním dokumentu
const handleOnClickDeleteDocument = (id:string, text:string) => {
  (async () => {
    if (await confirm("Opravdu chcete dokument "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání dokumentu'})) {
      deleteDocument(id)
    } else {
    
    }  
  })()
}

// Funkce pro smazání dokumentu
const deleteDocument = (id:string) => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    // Zavolání API
    
    await axios.delete(getUrl('URL_DOCUMENTS')+localStorage.getItem('openedCustomerId')+'/documents/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        // Po smazání přenačíst seznam dokumentů
        rootStore.fetchCustomerOpenedDocuments(localStorage.getItem('openedCustomerId')!)
      }).catch(error => {
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setShowE(true)          
      });
 
    })()    
}

// Funkce pro smazání dokumentu
const openDocument = (docName:string, docId?:string) => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      

    let url:string = getUrl('URL_DOCUMENTS')+localStorage.getItem('openedCustomerId')+'/documents/'+docName

    if (rightsBH === true) url = getUrl('URL_DOCUMENTS_BH')+docId


    // Zavolání API
    await axios.get(url,{
      headers: {
        'Authorization': 'Bearer ' + aToken,
      },
      responseType: 'blob',
    })
    .then(response => {
      let blob = new Blob([response.data], {type: 'application/pdf'})
      //const downloadLink = document.createElement("a")
      
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL, "_blank");
    }).catch(error => {
      //console.log(error)

      const errorMessage:any = (
        <Alert variant="danger">
            
                
              Dokument, který se pokoušíte otevřít  není v tuto chvíli dostupný. Zkuste to prosím později nebo kontektujte naše obchodní oddělení.
            
        </Alert> )
      
      //console.log(errorMessage)
      if (error.response.status === 400) {
      setError(errorMessage)
      setShowEO(true)
      }
    });
 
    })()    
}
  
 
  return (
    <div>
      {countDoc === 0 ? <div>Seznam dokumentů neobsahuje žádné záznamy.<br /><br /></div> : ''}
      {customerDocumentsCard}
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání dokumentu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      <Modal 
        show={showEO} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Zobrazení dokumentu PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

    </div>

  )  
  
}

export default withRouter(observer(CustomersDocumentsListArchive))

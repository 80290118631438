import React, {useState}  from 'react'
import { ROUTES } from '../../../Routes'
import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import AccountsMyAccountChangePassword from './components/Accounts.MyAccount.ChangePassword.part'
import AccountsMyAccountUpdate from './components/Accounts.MyAccount.Update.part'

import AccountsMyAccountPreferences from './components/Accounts.MyAccount.Preferences.List.part'

import {Container, Button, CardDeck, Card} from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { getToken, handleMenuContent, checkVersion} from '../../../Utils/Common'

import Loader from "react-loader"

const MyAccount: React.FC = (props:any) => {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  // Nastavení práv zda jsme Ježci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  
  // Při otevření této stránky asynchronní zavolání
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'my-account')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          
          

          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
          }
          await rootStore.fetchCustomerList('','',1)
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      if (rights === true) {
        await rootStore.fetchLoggedUserPreferences()
        await rootDialsStore.fetchDialsSupportThemes()
      }
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })


 const loggedUsers = rootStore.loggedUsers
  
    

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <Container fluid>
                <br />
                <h4>Můj účet</h4>
                <br />
                <CardDeck>
                <Card
                  bg='light'
                  text='dark'
                  style={{ width: '18rem' }}
                >
                  <Card.Header  as="h5">Nastavení uživatele</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <strong>Jméno:</strong><br />{loggedUsers.slice()[0]?.fullName}
                      <br /><br />   
                      <strong>Email:</strong><br />{loggedUsers.slice()[0]?.email}
                      <br /><br />   
                      <strong>Telefon:</strong><br />{loggedUsers.slice()[0]?.phone}
                      <br /><br /> 
                      
                    </Card.Text>
                    
                    <AccountsMyAccountUpdate />&nbsp;&nbsp;
                    <AccountsMyAccountChangePassword />&nbsp;&nbsp;
                    <span className="button-box">
                      <Link to={ROUTES.logout.getLink()}><Button variant="danger" size="sm" >Odhlásit</Button></Link>
                    </span>
                  </Card.Body>
                </Card>

                      
                </CardDeck>
                
                {rights && <>
                <br />
                <h4>Předvolby</h4>
                </>}

                </Container>
                

                {rights &&<AccountsMyAccountPreferences />}

              
            </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(MyAccount)
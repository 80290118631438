import { types } from 'mobx-state-tree'


export const CustomerOpenedNotes = types
  .model({
    id: types.optional(types.string,''),
    customerId: types.optional(types.string,''),
    note: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    isNotify: types.optional(types.boolean,false),
    isActive: types.optional(types.boolean,false),
    userId: types.optional(types.string,''),
    authorId: types.optional(types.string,''),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Button, Modal, Badge, Dropdown, Spinner } from 'react-bootstrap'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import FilesList from '../../../../parts/files/Files.List.part'
import FilesAdd from '../../../../parts/files/Files.Add.part'


interface CustomersFilesDetailProps { 
  customerId?:string
  showAs:string, 
  badgeFilesCount?:number 
}

const CustomersFilesDetail: React.FC<CustomersFilesDetailProps & RouteComponentProps> = (props:any) => {
  
  // Nastavení práv pro editaci
  // eslint-disable-next-line

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)

  const [showDetail, setShowDetail] = useState(false)

  // Připojení store
  const rootStore = useStore()

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    sessionStorage.removeItem('autoCloseModal')
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    setLoading(true)
    setShowDetail(true)

  await rootStore.fetchFilesForCustomer(props.customerId)
  setLoading(false)
  

}



return ( 
  <>
      {props.showAs === 'drop'
      &&
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Zákaznická řešení</Dropdown.Item>
      </>}

      {props.showAs === 'badge'
      &&
      <>
        <Badge style={{marginRight: '5px', cursor: 'pointer'}} variant={props.badgeFilesCount > 0 ? 'info' : 'light' } title='Příznak, kolik zákaznických řešení zákazník má.' onClick={() => {startSequence()}}>Zákaznická řešení ({props.badgeFilesCount})</Badge>
        
      </>}

      {props.showAs === 'button'
      &&
      <>
        <Button variant='outline-primary' size="sm" style={{}} onClick={() => {startSequence()}} >Zákaznická řešení</Button>
      </>}
  
    <Modal 
      
      scrollable
      show={showDetail} 
      onHide={handleCloseMain} 
      size='lg'
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
        
          Zákaznická řešení
        
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
        <FilesAdd id={props.customerId} type='customer' masterId={props.customerId} showAs='buttonWithText' />
        <br /><br />
        {loading ? <Spinner animation='border' /> : 
        <FilesList id={props.customerId} type='customer' masterId={props.customerId} noFilesInfo={true} />     
        }


      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(CustomersFilesDetail))

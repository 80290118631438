import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getDataFiltersNewFirstUrlPart, getDataFiltersNewProgramId, getDataFiltersNewIsStereoLan, getDataFiltersNewOperationNameForControl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, Badge, Spinner } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
import { useStore } from '../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

import NumberFormat from 'react-number-format'

import OthersOperationsDetail from '../../pages/others/operations/components/Others.Operations.Detail.part'

interface CustomersOtherActionsUpgradeElectronicUnpublishProformasProps {
  mode: string,
  id: string,
  //filterId:number
  showAs: string,
  filterId: string,
  info: string,
  multiselect?: boolean|undefined,
  filtered?: boolean|undefined|null,
  searchValue?:string|undefined|null,
  searchType?:string|undefined|null, 
  searchScenario?:string|undefined|null,
  count?: number|undefined
}

const CustomersOtherActionsUpgradeElectronicUnpublishProformas: React.FC<CustomersOtherActionsUpgradeElectronicUnpublishProformasProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)

  // Připojení store
  const rootStore = useStore()
  let operations = rootStore.getRuningOperationsByName(getDataFiltersNewOperationNameForControl(props.filterId))
  //Počet záznamů
  const countData:number = Object.keys(operations).length

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = async () => {
    await rootStore.fetchOperations()
    rootStore.fetchStatesForOperations()
    //operations = await rootStore.getOperationsByName(getDataFiltersNewOperationNameForControl(props.filterId))

    if (props.filtered === true) {
      await rootStore.fetchDatafiltersOffersNewForFilteredActions(Number(props.filterId),props.searchValue,props.searchType,props.searchScenario)
    }

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Funkce pro odeslání e-mailu
  const handlePublishLicenses = () => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      if (props.mode === "all") {
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/proforma/unpublish', { mode: props.mode === 'all' ? ( props.filtered === true ? 'selected': 'all' ) : 'selected', selectedIds: props.mode === 'all' ? props.filtered === true ? rootStore.datafiltersOffersNewForFilteredActions.map(dfon => dfon.documentId) : [] : props.multiselect === true ? rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true).map(dfon => dfon.documentId) : [props.id], isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response)
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API 
      
      await axios.post(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(props.filterId)+getDataFiltersNewProgramId(props.filterId)+'/proforma/unpublish', { mode: props.mode, selectedIds: props.mode === 'all' ? props.filtered === true ? rootStore.datafiltersOffersNewForFilteredActions.map(dfon => dfon.documentId) : [] : props.multiselect === true ? rootStore.datafiltersOffersNew.filter(dfon => dfon.checked === true).map(dfon => dfon.documentId) : [props.id], isStereoLan: getDataFiltersNewIsStereoLan(props.filterId), timeStamp: Number(localStorage.getItem('lastDatafilterTimeStamp')) }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)


          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    confirmation: Yup.boolean()
    .oneOf([true], 'Není zatrhnuto potvrzení!'),
  })   
  return (
    <>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item style={{color: 'red'}} onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Zrušit publikování zálohových faktur'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Zrušit publikování zálohových faktur'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Zrušit publikování zálohové faktury'}
          </Dropdown.Item>}
          {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleShow() } }>
            {props.mode === 'all' && 'Zrušit publikování zálohových faktur'}
            {(props.mode === 'selected' && props.multiselect === true) && 'Zrušit publikování zálohových faktur'}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Zrušit publikování zálohové faktury'}
          </Button>}
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mode === 'all' && <>Hromadné zrušení publikování zálohových faktur (<NumberFormat displayType={'text'} thousandSeparator={' '} value={localStorage.getItem('lastDatafilterOfferTotalCount')!} />)</>}
            {(props.mode === 'selected' && props.multiselect === true) && <>Zrušení publikování zálohových faktur ({props.count})</>}
            {(props.mode === 'selected' && props.multiselect !== true) && 'Zrušení publikování zálohové faktury'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(props.mode === 'selected' && props.multiselect !== true) &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}

          <Alert variant="success" show={showInfo}>
            <strong>Požadavek na zrušení publikování zálohových faktur byl odeslán!​</strong>
            
          </Alert>  
          <div className={showForm} >
          {props.mode === 'alll' &&<div style={{fontStyle: 'italic'}}>
              <i className="fas fa-info-circle"></i>&nbsp;Hromadné operace probíhájí za všechny záznamy. Případně použitý filtr nebude zohledněn.<br /><br />
            </div>}
            
            <div className={countData === 0 ? 'nodisplay' : ''}>
              <br /><strong>Právě probíhají operace, které mohou mít vliv na tuto akci:</strong><hr />
              {operations.map((operation) => (
                <div key={operation.id}>
                  {(operation.isFinished !== true && operation.stateLoaded !== true) 
                    ? 
                    <Spinner animation='border' size="sm" /> 
                    : 
                    <>
                      {operation.state === 1 && <Badge variant="primary">Probíhá</Badge>}
                      {operation.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
                      {operation.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
                      {operation.state === 4 && <Badge variant="success">Dokončeno</Badge>}
                    </>}
                  &nbsp;
                  {operation.nameForMortal} 
                  &nbsp;&nbsp;&nbsp;
                  <div className="float-right">
                  <OthersOperationsDetail id={operation.id} showAs='button' />
                  </div>
                  <hr />
                </div>
               ))}
              <br />
            </div>
            
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                scenarioId: '',
                confirmation: false
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handlePublishLicenses()              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Potvrzení</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="confirmation">
                    <Form.Row>
                      
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="confirmation"
                          size="sm"
                          className="checkbox-small"
                          style={{marginTop: '10px'}}
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.confirmation}
                          
                          isValid={touched.confirmation && !errors.confirmation} 
                          isInvalid={!!errors.confirmation}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmation}
                        </Form.Control.Feedback>
                      </Col>
                      <FormLabel column>Pro pokračování v operaci je nezbytné potvrdit, že ji chcete spustit.</FormLabel>
                    </Form.Row>  
                  </FormGroup>
                  {error}
                  <br />
                  <Button type="submit" variant="danger" disabled={!(isValid) || loading} block>{loading ? 'Odesílám požadavek...' : 'Odeslat požadavek'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsUpgradeElectronicUnpublishProformas))

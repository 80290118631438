import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl, getUser } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button, Dropdown,  Alert, Modal, ButtonGroup } from 'react-bootstrap'

// eslint-disable-next-line
import axios from 'axios';

import { useStore } from '../../../store'

import { observer } from 'mobx-react'


//import NumberFormat from 'react-number-format'

interface MeetingsUpdatePointsOrderProps {
  id: string,
  showAs: string,
}

const MeetingsUpdatePointsOrder: React.FC<MeetingsUpdatePointsOrderProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  
  //const rightsMeeting:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false
  const rightsManagement:boolean = ((localStorage.getItem('Management')! === '1')) ? true : false

  // Připojení store
  const rootStore = useStore()
  

  let item = rootStore.getMeetingById(props.id)

  const rightsAuthor:boolean = (item?.authorId === getUser().id) ? true : false
  const rightsMeeting:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false
  const rightsEdit:boolean = ((rightsAuthor === true) || (rightsMeeting === true && item?.isManagement === false) || (rightsManagement === true && item?.isManagement === true)) ? true : false

  // Nastavení stavů pro zobrazení 
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<React.ReactNode | null>(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  // eslint-disable-next-line
  const [touchDevice, setTouchDevice] = useState(false)

  type MPointsArray = {id: string, meetingId: string|null, taskId: string|null, caption: string|null, text: string|null, createDate: string|null}

//let packetsSearchArray = new Array<PacketsSearchArray>()

const [MPoints, setMPoints] = useState(new Array<MPointsArray>())

const dragItem = React.useRef<any>(null);
// eslint-disable-next-line
const dragOverItem = React.useRef<any>(null);




// Funkce pro aktualizaci pořadí bodů porady při přesouvání
const handleDragOver = (index: number) => {
  if (dragItem.current !== null && dragItem.current !== index) {
    const draggedItem = MPoints[dragItem.current];
    const updatedMPoints = [...MPoints];
    updatedMPoints.splice(dragItem.current, 1);
    updatedMPoints.splice(index, 0, draggedItem);
    setMPoints(updatedMPoints);
    dragItem.current = index;
  }
};

// Funkce pro posun bodů porady nahoru
const moveUp = (currentIndex: number) => {
  if (currentIndex > 0) {
    const updatedMPoints = [...MPoints];
    const draggedItem = updatedMPoints[currentIndex];
    updatedMPoints.splice(currentIndex, 1);
    updatedMPoints.splice(currentIndex - 1, 0, draggedItem);
    setMPoints(updatedMPoints);
    dragItem.current = currentIndex - 1;
  }
};

// Funkce pro posun bodů porady dolů
const moveDown = (currentIndex: number) => {
  if (currentIndex < MPoints.length - 1) {
    const updatedMPoints = [...MPoints];
    const draggedItem = updatedMPoints[currentIndex];
    updatedMPoints.splice(currentIndex, 1);
    updatedMPoints.splice(currentIndex + 1, 0, draggedItem);
    setMPoints(updatedMPoints);
    dragItem.current = currentIndex + 1;
  }
};

// Funkce pro zahájení tažení
const handleDragStart = (index: number) => {
  dragItem.current = index;
};

// Funkce pro ukončení tažení
const handleDragEnd = () => {
  dragItem.current = null;
};


  // Detekce typu zařízení
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 ;
  };

  

  
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    setLoading(false)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    (async () => {
    //await rootDialsStore.fetchTemplateById(props.id)
    item = rootStore.getMeetingById(props.id)
    sessionStorage.removeItem('autoCloseModal')
    setTouchDevice(isTouchDevice())
    setMPoints(item?.points.slice().sort((a:any, b:any) => a.rank - b.rank)!)    
    
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
    })()
  }


  // Funkce pro změnu adresy
  const handleUpdate = async() => {
    setError(null);
    
    setLoading(true);

    // Zpracování požadavku
    

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API

      let errorCount = 0;

      for (let i = 0; i < MPoints.length; i++) {

        let dataToSend = {
          id: MPoints[i].id,
          caption: MPoints[i].caption,
          text: MPoints[i].text,
          taskId: MPoints[i].taskId,
          rank: i
        }

        await axios.post(getUrl('URL_MEETINGS')+'/'+props.id+'/points/'+MPoints[i].id, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          
//eslint-disable-next-line
        }).catch(error => {
          //eslint-disable-next-line
          errorCount = 1
        });

        
      }
      
      
      if (errorCount === 0) {
        //await rootStore.fetchMeetingPoints(props.id)
        setShowForm('nodisplay')
        setShowInfo(true)
        setLoading(false)
        setMPoints([])
        await rootStore.fetchMeetingPoints(props.id)
        setError(null)
        setTimeout(() => {
          setShow(false)
          setError(null)
          sessionStorage.removeItem('autoCloseModal')
          setShowInfo(false)
          //setShowForm('')
        }, 2800);

      }  
      else {
        let errorText = <Alert variant="danger">Změnu pořadí se nepodařilo provést!</Alert>
        setError(errorText)
        setLoading(false)
      }
     
    

  }



   
  return (
    <>
      {rightsEdit && <>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Upravit pořadí</Dropdown.Item>}
        {props.showAs === 'button' &&<Button size="sm" variant="primary" onClick={() => { handleShow() } }>Upravit pořadí</Button>}
        </>
      }
      <Modal 
        show={show} 
        onHide={handleClose} 
        backdrop="static"
        size='lg'
        style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
        dialogClassName="modal"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava pořadí bodů porady</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
          <Alert variant="success" show={showInfo}>
            <strong>Pořadí bodů porad bylo úspěšně změněno!​</strong>
            
          </Alert>  
          {showInfo === false &&
          <div className={showForm} >
            
            <div className='showMobile' style={{}}>
              <div className="list-sort">
                {MPoints.map((mpoint, index) => (
                  <Alert variant="info" style={{marginBottom: '10px'}} 
                    key={index}
                    
                  
                  >                  
                    <ButtonGroup style={{float: 'right'}}>
                      <Button variant='primary' size='sm' onClick={() => moveUp(index)} ><i className="fas fa-arrow-up"></i></Button>
                      <Button variant='primary' size='sm' onClick={() => moveDown(index)} ><i className="fas fa-arrow-down"></i></Button>
                    </ButtonGroup>
                    <strong style={{fontSize: '18px', marginLeft: '20px'}}>{mpoint.caption}</strong>
                  </Alert>
                ))}
              </div>
            </div>

            <div className='showWeb' style={{}}>
              <div className="list-sort">
                {MPoints.map((mpoint, index) => (
                  <div
                    key={index}
                    className="sort-list-item"
                    draggable
      
                    onDragStart={() => handleDragStart(index)}
                    onDragEnter={() => handleDragOver(index)}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-bars"></i>
                    <h3 style={{fontSize: '30px', marginLeft: '20px'}}>{mpoint.caption}</h3>
                  </div>
                ))}
              </div>
            </div>
            <br />
            <Button variant="success" block onClick={() => handleUpdate()}>{loading ? 'Ukládám pořadí...':'Uložit pořadí'}</Button>
          </div>}
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(MeetingsUpdatePointsOrder))

import React  from 'react'
import {Card} from 'react-bootstrap'

import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import CustomersDashboardInformationUpdate from './Customers.Dashboard.Information.Update.part'

const CustomersDashboardInformation: React.FC = () => {

  // Načtení Store
  const rootStore = useStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  return (
    <Card
    bg='light'
    text='dark'
  >
    <Card.Header  as="h5">Fakturační údaje</Card.Header>
    <Card.Body>
      <Card.Text>
        {customer?.name !== '' ? <span><strong>{customer?.name}</strong></span> : ''}
        {customer?.name !== '' ? <br /> : ''}

        {customer?.person !== '' ? <span><strong>{customer?.person}</strong></span> : ''}
        {customer?.person !== '' ? <br /> : ''}
        <br />
        {customer?.billingAddress?.street}&nbsp;{customer?.billingAddress?.houseNumber}
        {customer?.billingAddress?.identificationNumber === null || customer?.billingAddress?.identificationNumber === '' ? '':'/'}
        {customer?.billingAddress?.identificationNumber}
        <br />
        {customer?.billingAddress?.postalCode}&nbsp;{customer?.billingAddress?.city}
        <br /><br />
        <strong>IČO:</strong>&nbsp;{customer?.companyRegistrationNumber}
        <br />
        <strong>DIČ:</strong>&nbsp;{customer?.taxIdentificationNumber}
        <br />
        <strong>Plátce DPH:</strong>&nbsp;{customer?.isTaxAble === true ? 'Ano':'Ne'}
        <br /><br />
        <CustomersDashboardInformationUpdate />
      </Card.Text>
      
    </Card.Body>
  </Card>
  )  
  
}

export default observer(CustomersDashboardInformation)

import { types } from 'mobx-state-tree'


const Packets = types
  .model('Packets',{
    id: types.optional(types.string, ''),
    userId: types.maybeNull(types.string),
    submissionId: types.maybeNull(types.string),
    technologicalNumber: types.maybeNull(types.string),
    productId: types.maybeNull(types.string),
    packetCode: types.maybeNull(types.string),
    packetNumber: types.maybeNull(types.number),
    amount: types.optional(types.number,0),
    weight: types.optional(types.number,0),
    insuredValue: types.optional(types.number,0),
    currency: types.maybeNull(types.string),
    notePrint: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    invoiceNumber: types.maybeNull(types.number),
    customerId: types.maybeNull(types.string),
    services: types.maybeNull(types.array(types.string)),
    returnDate: types.maybeNull(types.string),
    returnNote: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    surName: types.maybeNull(types.string),
    titleAfter: types.maybeNull(types.string),
    aditionInfo: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    houseNumber: types.maybeNull(types.string),
    sequenceNumber: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    cityPart: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
    isoCountry: types.maybeNull(types.string),
    mobileNumber: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    emailAddress: types.maybeNull(types.string),
  })
  .views(self => ({
    get person() {
      //if (self.title !== null || self.firstName !== null || self.surName !== null)
      const title = self.title !== null ? self.title : ''
      const titleAfter = self.titleAfter !== null ? self.titleAfter : ''
      const surName = self.surName !== null ? self.surName : ''
      const firstName = self.firstName !== null ? self.firstName : ''

      const personToReturn = `${title}${title !== '' ? ' ' : ''}${firstName}${firstName !== '' ? ' ' : ''}${surName}${titleAfter !== '' ? ' ' : ''}${titleAfter}`
      return self.titleAfter !== null || self.title !== null || self.firstName !== null || self.surName !== null  ? personToReturn : ''
    },
    get nameWithPerson() {
      const title = self.title !== null ? self.title : ''
      const titleAfter = self.titleAfter !== null ? self.titleAfter : ''
      const surName = self.surName !== null ? self.surName : ''
      const firstName = self.firstName !== null ? self.firstName : ''

      const personToReturn = `${title}${title !== '' ? ' ' : ''}${firstName}${firstName !== '' ? ' ' : ''}${surName}${titleAfter !== '' ? ' ' : ''}${titleAfter}`
      const finalPerson = self.titleAfter !== null || self.title !== null || self.firstName !== null || self.surName !== null  ? personToReturn : ''
      
      const together = (finalPerson !== '' && self.companyName !== '') ? ', ' : ''

      const finalText = (self.companyName !== null ? self.companyName : '') + together + finalPerson

      return finalText
    }
  }))
  .actions(self => ({
    

  }))

export const Submissions = types
  .model({
    id: types.optional(types.string, ''),
    userId: types.maybeNull(types.string),
    distributionDate: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    count: types.optional(types.number, 0),
    weight: types.optional(types.number, 0),
    amount: types.optional(types.number, 0),
    returnCount: types.optional(types.number, 0),
    isExported: types.optional(types.boolean, false),
    isSubmitted: types.optional(types.boolean, false),
    documentTypes: types.maybeNull(types.array(types.string)),
    packets: types.maybeNull(types.array(Packets))
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    setDocumentTypes(data:any) {
      self.documentTypes = data
    },
    setPackets(data:any) {
      self.packets = data
    },
    setExportedFlag(state:boolean) {
      self.isExported = state
    },
    setValuesFromData(data:any) {
      //self.id = data.id
      self.userId = data.userId
      self.distributionDate = data.distributionDate
      self.note = data.note
      self.count = data.count
      self.weight = data.weight
      self.amount = data.amount
      self.returnCount = data.returnCount
      //self.isExported = data.isExported
      self.isSubmitted = data.isSubmitted
      //self.documentTypes = data.documentTypes
      //self.packets = data.packets
    }

  }))

import { types } from 'mobx-state-tree'

export const DatafiltersOffersNew = types
  .model({
    offerId: types.optional(types.string, ''),
    documentId: types.optional(types.string, ''),
    taxDocumentId: types.optional(types.string, ''),
    customerId: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.optional(types.string, ''),
    programName: types.optional(types.string, ''),
    actualVersion: types.maybeNull(types.string),
    programVersion: types.maybeNull(types.string),
    fromVersion: types.optional(types.string, ''),
    vatBase:  types.optional(types.number, 0),
    isDiscount:  types.optional(types.boolean, false),
    isLicenseChanged: types.optional(types.boolean, false),
    isLicenseChangedInProtectedPeriod: types.optional(types.boolean, false),
    isNewNetworkLicense: types.optional(types.boolean, false),
    invoiceNumber: types.optional(types.number, 0),
    dateOfIssue: types.optional(types.string, ''),
    dateOfPublishing: types.optional(types.string, ''),
    dateOfPayment: types.optional(types.string, ''),
    proformaNumber: types.optional(types.number, 0),
    licenseDocumentId: types.optional(types.string, ''),
    paymentDocumentId: types.optional(types.string, ''),
    distributionDate: types.optional(types.string, ''),
    returnDate: types.optional(types.string, ''),
    packetId: types.optional(types.string, ''),
    checked: types.optional(types.boolean, false),
  })
  .views(self => ({
    get infoText() {
      return self.accountNumber + ' - ' + self.businessName
      
    }, 
  }))
  .actions(self => ({
    selectItem(newState: boolean) {
      self.checked = newState
    }

  }))

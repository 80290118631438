import React, { useState } from 'react'
import { Link } from 'react-router-dom'
//import { ROUTES } from '../../../Routes'
import axios from 'axios'

import { getUrl, checkVersionWithoutRestart } from '../../../Utils/Common'

import { ResetPasswordUrlParams } from '../../../Routes'
import { useParams } from 'react-router'

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlertWithoutLogout.part'
import {Container,  Form, Col, Button, Alert, Image} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'

import { Formik } from 'formik'
import * as Yup from 'yup'

function ResetPassword(props:any) {
  // Získání tokenu z URL adresy
  const { resetToken } = useParams<ResetPasswordUrlParams>()
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  // Kontrola zda se načetla URL BE
  checkVersionWithoutRestart()
  /*
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }
  */

  // Funkce po odeslání formuláře
  const handleReset = (resetTokenData:string,newPasswordData:string,confirmNewPasswordData:string) => {
    setError(null)
    setLoading(true)
    // Zavolání API
    axios.post(getUrl('URL_RESET_PASSWORD'), { token: resetTokenData, newPassword: newPasswordData, confirmNewPassword: confirmNewPasswordData }
    ).then(response => {
      setLoading(false)
      setShowInfo(true)
      setShowForm('nodisplay')
      // Po 10s přepnutí na loginpage
      setTimeout(() => {
        props.history.push('/login')
      }, 10000);
    }).catch(error => {
      setLoading(false)
      const errorMessage:any = (<ErrorAlert errorData={error} />)
      setError(errorMessage)
    });
  }

  // Schéma validace formuláře
  const schema = Yup.object().shape({
    newPassword: Yup.string()
    .required('Heslo musí být vyplněno!')
    .matches(
      /[A-Z]/,
      "Heslo musí obsahovat alespoň jedno velké písmeno."
    )
    .matches(
      /[a-z]/,
      "Heslo musí obsahovat alespoň jedno malé písmeno"
    )
    .matches(
      /[0-9]/,
      "Heslo musí obsahovat alespoň jednu číslici."
    )
    .matches(
      /[@$!%*#?&_+]/,
      "Heslo musí obsahovat alespoň jeden speciální znak (@, $, !, %, *, #, ?, &, _, +)."
    )
    .min(8, 'Heslo musí obsahovat mininálně 8 znaků.'),

    confirmNewPassword: Yup.string()
      .required('Heslo musí být vyplněno!'),
  })  


  return (
    <div className="App">
      <main>
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    <HomeInfo />
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="jezekswSmall.png" className="login-logo" /> Zákaznický portál</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Nastavení hesla</h3>
                    <br />
              
                    <Alert variant="success" show={showInfo}>
                      <Alert.Heading>Heslo nastaveno!</Alert.Heading>
                          <p>Nastavení vašeho hesla proběhlo v pořádku.</p>
                          
                    </Alert> 
                    <div className={showForm} >
                      <Formik
                        validationSchema={schema}
                        initialValues={{ newPassword: '', confirmNewPassword: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            handleReset(resetToken, values.newPassword,values.confirmNewPassword);              
                            setSubmitting(false);
                          }, 1);
                        }}
                      >
                      {({ 
                        handleSubmit,
                        handleChange,
                        
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup controlId="formEmail">
                            <Form.Row>
                              <FormLabel column lg={4}>Nové heslo</FormLabel>
                              <Col>
                                <FormControl 
                                  type="password"
                                  name="newPassword"
                                  value={values.newPassword}
                                  onChange={handleChange}
                                  
                                  isValid={touched.newPassword && !errors.newPassword} 
                                  isInvalid={!!errors.newPassword}
                                />
                                
                                <Form.Control.Feedback type="invalid">
                                  {errors.newPassword}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          <FormGroup controlId="formPassword">
                            <Form.Row>
                              <FormLabel column lg={4}>Ověření hesla</FormLabel>
                              <Col>
                                <FormControl 
                                  type="password"
                                  name="confirmNewPassword"
                                  value={values.confirmNewPassword}
                                  onChange={handleChange}
                                  
                                  isValid={touched.confirmNewPassword && !errors.confirmNewPassword}
                                  isInvalid={!!errors.confirmNewPassword} 
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.confirmNewPassword}
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>  
                          </FormGroup>
                          
                          {error}
                          <br />
                          <Button type="submit" variant="success" disabled={loading} block>{loading ? 'Nastavuji heslo...' : 'Nastavit nové heslo'}</Button>
                          
                        </Form>
                      )}
                      </Formik> 
                    </div> 
                    <br /><Link to="/login">Zpět na přihlášení.</Link>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>   
      </main>
    </div>
  )
}

export default ResetPassword
import React  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'



interface HedgehogsInactiveProps {
      
}

const SelectOptionsHedgehogsInactive: React.FC<HedgehogsInactiveProps & RouteComponentProps> = (props:any) => {
  
  // Načtení Store
  const rootStore = useStore()

  // Seznam pracovníků neaktivní 
  const hedgehogsInactive = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === false)
  .slice()
  .map(hu => (
    <option key={hu.id} value={hu.id} style={{color: 'lightgrey'}}>{hu.surName}&nbsp;{hu.firstName}</option>
  ))  

  



  

  //const datum = new Date().toISOString()
  return (
    <>
      {hedgehogsInactive}
      
    </>
  )  
  
}

export default withRouter(observer(SelectOptionsHedgehogsInactive))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, getStatusStringForFiltering, getStatusBooleanForFiltering, getStatusNumberForFiltering, getStatusDateForFiltering, getStatusSelectForFiltering, getUser } from '../../Utils/Common'

import {Button, ButtonGroup, Form, Col, Alert, Row, Badge, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'

import SelectOptionsSupportThemes from './components/SelectOptions.SupportThemes'
import SelectOptionsSupportTypes from './components/SelectOptions.SupportTypes'
import SelectOptionsHedgehogs from './components/SelectOptions.Hedgehogs'
import SelectOptionsHedgehogsInactive from './components/SelectOptions.HedgehogsInactive'
import SelectOptionsDistributionMethod from './components/SelectOptions.DistributionMethod'
import SelectOptionsServices from './components/SelectOptions.Services'
import SelectOptionsUpgradeScenarios from './components/SelectOptions.UpgradeScenarios'

import { useStore, useDialsStore, useUpgradeStore, useEmailingStore, useAppStore } from '../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
import NumberFormat from 'react-number-format'

interface FilterPanelProps {
  isCustomer?:boolean,
  //customerId?:string,
  setPageLoading:any,
  filterPage:string,
  filterSearchArray:string,
  pageVariable:string,
  filterItems:any,
  title?:string,
  titleDescription?:any,
  titleCount?:number,
  stateNewFilter:boolean,
  setStateNewFilter:any,
  secondaryLeftButtons?:any,  
  secondaryRightButtons?:any,
  overviewsNumber?:number,
  setIsFiltered?:any,
  otherId?:string,
  masterId?:string|null 
}

const FilterPanel: React.FC<FilterPanelProps & RouteComponentProps> = (props:any) => {
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [showFormItem, setShowFormItem] = useState(true)
  const [error, setError] = useState(null)

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const rootUpgradeStore = useUpgradeStore()
  const rootEmailingStore = useEmailingStore()
  const rootAppStore = useAppStore()

  //const helperLoading = rootDialsStore.helpers.find(h => h.id === 'contacts')?.status !== true ? false:true
  //console.log(helperLoading)

  let searchDataArrayFirst = [{filterName:"",filterValue:""}]
  if (localStorage.getItem(props.filterSearchArray) !== null ) {
    searchDataArrayFirst = JSON.parse(localStorage.getItem(props.filterSearchArray)!)
  }
  else {
    searchDataArrayFirst = [{filterName:"",filterValue:""}]
  }

  
  const [showCancel, setShowCancel] = useState(Object.keys(searchDataArrayFirst.filter(ssda => ssda.filterName !== "")).length === 0 ? false : true)
  
  //********************** */
  const [showFilterForm, setShowFilterForm] = useState(localStorage.getItem('show'+props.filterPage+'Filter')! === '1' ? true:false)

  
  // Deklarace a nastavení stavů pro seznamy ala google
  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })

  const customerListAllF = rootStore.customerListAll

  const [customers, setCustomers] = useState(customerListAllF.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  

  

  // Filtrování zákazníků v seznamu
  const filterCustomers = (value:string) => {
  //const newList = customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))
  //setCustomers(newList)
  setCustomers(rootStore.getCustomerListAllByFilter(value))
  }
  


  // Smazání položky filtru a nové načtení seznamu
  const refreshPage = () => {
    (async () => { 
      setLoading(true);
      props.setPageLoading(true);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      if (props.filterPage === 'contacts') await rootStore.fetchSupports(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)),props.isCustomer)
      if (props.filterPage === 'customerOpen') await rootStore.fetchCustomerListNew(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'templates') await rootStore.fetchTemplatesNew(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))

      if (props.filterPage === 'dialsDeliveryCzechPostServices') await rootDialsStore.fetchDialsDeliveryCzechPostServices(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'dialsDeliveryCzechPostProducts') await rootDialsStore.fetchDialsDeliveryCzechPostProducts(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'dialsDeliveryCzechPostTemplates') await rootDialsStore.fetchDialsDeliveryCzechPostTemplates(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))

      if (props.filterPage === 'dialsMeetingsCategories') await rootDialsStore.fetchDialsMeetingsCategories(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'dialsUserGroups') await rootDialsStore.fetchDialsUserGroups(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))

      if (props.filterPage === 'submissions') await rootStore.fetchSubmissions(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'submissionPackets') await rootStore.fetchPacketsForSubmission(props.otherId,Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))

      if (props.filterPage === 'licensesTransfers') await rootStore.fetchLicensesTransfersListNew(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'overviewsOffer') {
        localStorage.removeItem('lastDatafilterTimeStamp')
        localStorage.removeItem('lastDatafilterTime')
        localStorage.removeItem('lastDatafilter')
        localStorage.removeItem('lastDatafilterType') 
        if (props.overviewsNumber! < 1000) { await rootStore.fetchDatafiltersOffers(props.overviewsNumber!, Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable))) }
        else { await rootStore.fetchDatafiltersOffersNew(props.overviewsNumber!, Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable))) }
      }
      if (props.filterPage === 'overviewsCustomer') await rootStore.fetchDatafiltersCustomers(props.overviewsNumber!, Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))

      if (props.filterPage === 'emailingWhiteList') await rootEmailingStore.fetchEmailingWhiteList(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'emailingBlackList') await rootEmailingStore.fetchEmailingBlackList(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'datafiltersCustomersCustomList') await rootEmailingStore.fetchDatafiltersCustomersCustomList(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'datafiltersCustomersCustomPreview') await rootEmailingStore.fetchDatafiltersCustomersDesignerPreview(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)),rootEmailingStore.getEmailingConditionsToFinalString(),props.masterId)
      
      if (props.filterPage === 'meetings') await rootStore.fetchUnreadMeetingIds()
      if (props.filterPage === 'meetings') await rootStore.fetchMeetings(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      if (props.filterPage === 'tasks') await rootStore.fetchTasks(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))

      if (props.filterPage === 'accounts') await rootStore.fetchAccounts(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)))
      //if (Object.keys(searchDataArray.filter(ssda => ssda.filterName !== "")).length === 0) { setShowCancel(false); localStorage.removeItem(props.filterSearchArray);  } else { setShowCancel(true) }

      if (props.filterPage === 'appProDuelReceipts') await rootAppStore.fetchAppProduelReceipts(Number(sessionStorage.getItem(props.pageVariable)) === 0 ? 1:Number(sessionStorage.getItem(props.pageVariable)),props.otherId,props.isCustomer)

      setLoading(false);  
      props.setPageLoading(false);
    })()
  }



    // Smazání položky filtru a nové načtení seznamu
    const deleteFilterItem = (filterName:string, filterValue:string) => {
      (async () => { 
        setLoading(true);
        props.setPageLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem(props.pageVariable, '1')

        let searchDataArray = [{filterName:"",filterValue:""}]
        if (localStorage.getItem(props.filterSearchArray) !== null ) {
          searchDataArray = JSON.parse(localStorage.getItem(props.filterSearchArray)!)
        }
        else {
          searchDataArray = [{filterName:"",filterValue:""}]
        }

        searchDataArray = searchDataArray.filter(ssda => !(ssda.filterName === filterName && ssda.filterValue === filterValue))
        localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))


        sessionStorage.setItem(props.pageVariable, '1')
        // Předat hledanou hodnotu action pro načtení zákazníků


        if (props.filterPage === 'contacts') await rootStore.fetchSupports(1,props.isCustomer)
        if (props.filterPage === 'customerOpen') await rootStore.fetchCustomerListNew(1)
        if (props.filterPage === 'templates') await rootStore.fetchTemplatesNew(1)

        if (props.filterPage === 'dialsDeliveryCzechPostServices') await rootDialsStore.fetchDialsDeliveryCzechPostServices(1)
        if (props.filterPage === 'dialsDeliveryCzechPostProducts') await rootDialsStore.fetchDialsDeliveryCzechPostProducts(1)
        if (props.filterPage === 'dialsDeliveryCzechPostTemplates') await rootDialsStore.fetchDialsDeliveryCzechPostTemplates(1)

        if (props.filterPage === 'dialsMeetingsCategories') await rootDialsStore.fetchDialsMeetingsCategories(1)
        if (props.filterPage === 'dialsUserGroups') await rootDialsStore.fetchDialsUserGroups(1)

        if (props.filterPage === 'submissions') await rootStore.fetchSubmissions(1)
        if (props.filterPage === 'submissionPackets') await rootStore.fetchPacketsForSubmission(props.otherId,1)

        if (props.filterPage === 'licensesTransfers') await rootStore.fetchLicensesTransfersListNew(1)
        if (props.filterPage === 'overviewsOffer') {
          if (props.overviewsNumber! < 1000) { await rootStore.fetchDatafiltersOffers(props.overviewsNumber!, 1) }
          else { await rootStore.fetchDatafiltersOffersNew(props.overviewsNumber!, 1) }
        }
        if (props.filterPage === 'overviewsCustomer') await rootStore.fetchDatafiltersCustomers(props.overviewsNumber!, 1)

        if (props.filterPage === 'emailingWhiteList') await rootEmailingStore.fetchEmailingWhiteList(1)
        if (props.filterPage === 'emailingBlackList') await rootEmailingStore.fetchEmailingBlackList(1)
        if (props.filterPage === 'datafiltersCustomersCustomList') await rootEmailingStore.fetchDatafiltersCustomersCustomList(1)
        if (props.filterPage === 'datafiltersCustomersCustomPreview') await rootEmailingStore.fetchDatafiltersCustomersDesignerPreview(1,rootEmailingStore.getEmailingConditionsToFinalString(),props.masterId)

        if (props.filterPage === 'meetings') await rootStore.fetchUnreadMeetingIds()
        if (props.filterPage === 'meetings') await rootStore.fetchMeetings(1)
        if (props.filterPage === 'tasks') await rootStore.fetchTasks(1)

        if (props.filterPage === 'accounts') await rootStore.fetchAccounts(1)
        
        if (props.filterPage === 'appProDuelReceipts') await rootAppStore.fetchAppProduelReceipts(1,props.otherId,props.isCustomer)


        if (Object.keys(searchDataArray.filter(ssda => ssda.filterName !== "")).length === 0) { setShowCancel(false); localStorage.removeItem(props.filterSearchArray); if (props.setIsFiltered !== undefined) { props.setIsFiltered(false) }  } else { setShowCancel(true); if (props.setIsFiltered !== undefined) { props.setIsFiltered(true) } }

        setLoading(false);  
        props.setPageLoading(false);
      })()
    }

    // Pro BE hledání
    const handleSearch = (supportSearchData:string, supportSearchTypeData:string, supportSearchDateFromData:string, supportSearchDateToData:string, supportSearchNumberFromData:number, supportSearchNumberToData:number, supportSearchBooleanValueData:boolean ) => {

      setError(null)
      setLoading(true)
      props.setPageLoading(true);
      (async () => { 
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }


        if (props.stateNewFilter === true) {
          localStorage.removeItem(props.filterSearchArray)
          //props.setStateNewFilter(false)
        }



        let searchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
        if (localStorage.getItem(props.filterSearchArray) !== null ) {
          searchDataArray = JSON.parse(localStorage.getItem(props.filterSearchArray)!)
        }
        else {
          searchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
        }

        let currentUser = {id:''}
        currentUser = JSON.parse(localStorage.getItem('user')!)


        if (supportSearchTypeData === 'CustomerFulltext' && supportSearchData !== '') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((AccountNumber~like~'+supportSearchData+')~or~(BusinessName~like~'+supportSearchData+')~or~(CompanyRegistrationNumber~like~'+supportSearchData+'))', displayValue: supportSearchData })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'BusinessName2' && supportSearchData !== '') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '(BusinessName~like~'+supportSearchData+')', displayValue: supportSearchData })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'MyNotFinish') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((SolverUserId~eq~'+currentUser.id+')~and~(IsFinished~eq~false))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'MyNotFinishAfterTerm') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((SolverUserId~eq~'+currentUser.id+')~and~(IsFinished~eq~false)~and~(DeadLineDate~lt~'+ new Date().toISOString() +'))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'AllNotFinishAfterTerm') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((IsFinished~eq~false)~and~(DeadLineDate~lt~'+ new Date().toISOString() +'))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'MyTasks') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((ResponsibleUserId~eq~'+currentUser.id+'))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'MyNotFinishTasks') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((ResponsibleUserId~eq~'+currentUser.id+')~and~(IsDone~eq~false))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'MyNotFinishTasksPrivate') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((ResponsibleUserId~eq~'+currentUser.id+')~and~(IsDone~eq~false)~and~(IsPrivate~eq~true))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'MyNotFinishTasksAfterTerm') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((ResponsibleUserId~eq~'+currentUser.id+')~and~(IsDone~eq~false)~and~(DeadLine~lt~'+ new Date().toISOString() +'))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'AllNotFinishTasksAfterTerm') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((IsDone~eq~false)~and~(DeadLine~lt~'+ new Date().toISOString() +'))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'AuthorNotFinishTasks') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((AuthorId~eq~'+currentUser.id+')~and~(IsDone~eq~false))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'AuthorNotFinishTasksAfterTerm') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((AuthorId~eq~'+currentUser.id+')~and~(IsDone~eq~false)~and~(DeadLine~lt~'+ new Date().toISOString() +'))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'AuthorNotClosedTasks') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '((AuthorId~eq~'+currentUser.id+')~and~(IsDone~eq~true)~and~(isCompleted~eq~false))', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'IncompletedMeetingsTasks') {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: '(IncompleteTaskCount~gt~0)', displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }

        if (supportSearchTypeData === 'UnreadMeetings') {
          await rootStore.fetchUnreadMeetingIds()

          let unreadMeetingsFilterString:string = rootStore.meetingsUnread.length === 0 ? '' : '('

          rootStore.meetingsUnread.map((mu,index) => {

            if (index !== 0) unreadMeetingsFilterString = unreadMeetingsFilterString + '~or~'

            unreadMeetingsFilterString = unreadMeetingsFilterString + '(Id~eq~'+mu+')'
            return true
          })

          if (rootStore.meetingsUnread.length === 0) { } else { unreadMeetingsFilterString = unreadMeetingsFilterString + ')~and~(EmployeeWithAccess.Id~eq~' + getUser().id + ')'} 

          searchDataArray.push({filterName: supportSearchTypeData, filterValue: unreadMeetingsFilterString, displayValue: "Ano" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')

        }


        
        if (getStatusStringForFiltering(supportSearchTypeData) === true && supportSearchTypeData !== 'CustomerFulltext' && supportSearchTypeData !== 'BusinessName2') {
          if (!(supportSearchData === '' || supportSearchTypeData === '')) {
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: supportSearchData, displayValue:"" })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')
          }
        }

        if (supportSearchTypeData === "CustomerId") {
          if (!(supportSearchData === '')) {
            
            if (customerListAllF.find(cla => cla.accountNumber === supportSearchData)?.id! !== undefined) {

              searchDataArray.push({filterName: supportSearchTypeData, filterValue: customerListAllF.find(cla => cla.accountNumber === supportSearchData)?.id!, displayValue: supportSearchData })
              localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

              sessionStorage.setItem(props.pageVariable, '1')
            }
          }
        }

        if (getStatusSelectForFiltering(supportSearchTypeData) === true) {
          if (!(supportSearchData === '' || supportSearchTypeData === '')) {
            let displayString:string = ''

            if (["AuthorId","UserId","SolverUserId","ResponsibleUserId","Solutions.AuthorId","Solutions.UserId"].includes(supportSearchTypeData)) displayString = rootStore.hedgehogUsers.find(hu => hu.id === supportSearchData)?.fullName!
            if (["SupportTypeId","Solutions.SupportTypeId"].includes(supportSearchTypeData)) displayString = rootDialsStore.dialsSupportTypes.find(dst => dst.id === supportSearchData)?.name!
            if (["SupportThemeIds"].includes(supportSearchTypeData)) displayString = rootDialsStore.dialsSupportThemes.find(dst => dst.id === supportSearchData)?.name!
            if (["ServiceId"].includes(supportSearchTypeData)) displayString = rootDialsStore.dialsServices.find(ds => ds.id === supportSearchData)?.name!
            if (["DistributionMethod"].includes(supportSearchTypeData)) displayString = supportSearchData === 'D' ? 'Dobírkou' : 'Elektronicky'
            if (["UpgradeScenarioId"].includes(supportSearchTypeData)) displayString = rootUpgradeStore.upgradeOffersScenarios.find(us => us.id === supportSearchData)?.program.name! + ' ' + rootUpgradeStore.upgradeOffersScenarios.find(us => us.id === supportSearchData)?.programVersion.version!


          searchDataArray.push({filterName: supportSearchTypeData, filterValue: supportSearchData, displayValue: displayString })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')
          }
        }

        if (getStatusDateForFiltering(supportSearchTypeData) === true) {
          if (!(supportSearchDateFromData === '' || supportSearchDateToData === '')) {
            searchDataArray.push({filterName: supportSearchTypeData, filterValue: '(('+supportSearchTypeData+'~gte~'+new Date(supportSearchDateFromData).toISOString()+')~and~('+supportSearchTypeData+'~lte~'+new Date(supportSearchDateToData).toISOString()+'))', displayValue: moment(supportSearchDateFromData).format('DD.MM.YYYY HH:mm')+' - '+moment(supportSearchDateToData).format('DD.MM.YYYY HH:mm') })
            localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))
  
            sessionStorage.setItem(props.pageVariable, '1')
            }
        }

        if (getStatusNumberForFiltering(supportSearchTypeData) === true) {
            //console.log(supportSearchNumberFromData.toString() === '' ? -999999999 : supportSearchNumberFromData)
            //searchDataArray.push({filterName: supportSearchTypeData, filterValue: '(('+supportSearchTypeData+'~gte~'+supportSearchNumberFromData.toString()+')~and~('+supportSearchTypeData+'~lte~'+supportSearchNumberToData.toString()+'))', displayValue: supportSearchNumberFromData.toString()+' - '+supportSearchNumberToData.toString() })
            searchDataArray.push({filterName: supportSearchTypeData, filterValue: '(('+supportSearchTypeData+'~gte~'+(supportSearchNumberFromData.toString() === '' ? -999999999 : supportSearchNumberFromData).toString()+')~and~('+supportSearchTypeData+'~lte~'+(supportSearchNumberToData.toString() === '' ? 999999999 : supportSearchNumberToData).toString()+'))', displayValue: supportSearchNumberFromData.toString()+' - '+supportSearchNumberToData.toString() })
            localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))  
            sessionStorage.setItem(props.pageVariable, '1')

        }

        if (getStatusBooleanForFiltering(supportSearchTypeData) === true) {
          
          searchDataArray.push({filterName: supportSearchTypeData, filterValue: supportSearchBooleanValueData === true ? 'true':'false', displayValue: supportSearchBooleanValueData === true ? 'Ano':'Ne' })
          localStorage.setItem(props.filterSearchArray,JSON.stringify(searchDataArray))

          sessionStorage.setItem(props.pageVariable, '1')
          
        }

        // Zavolání funkce pro načtení

        if (props.filterPage === 'contacts') await rootStore.fetchSupports(1,props.isCustomer)
        if (props.filterPage === 'customerOpen') await rootStore.fetchCustomerListNew(1)
        if (props.filterPage === 'templates') await rootStore.fetchTemplatesNew(1)

        if (props.filterPage === 'dialsDeliveryCzechPostServices') await rootDialsStore.fetchDialsDeliveryCzechPostServices(1)
        if (props.filterPage === 'dialsDeliveryCzechPostProducts') await rootDialsStore.fetchDialsDeliveryCzechPostProducts(1)
        if (props.filterPage === 'dialsDeliveryCzechPostTemplates') await rootDialsStore.fetchDialsDeliveryCzechPostTemplates(1)

        if (props.filterPage === 'dialsMeetingsCategories') await rootDialsStore.fetchDialsMeetingsCategories(1)
        if (props.filterPage === 'dialsUserGroups') await rootDialsStore.fetchDialsUserGroups(1)

        if (props.filterPage === 'submissions') await rootStore.fetchSubmissions(1)
        if (props.filterPage === 'submissionPackets') await rootStore.fetchPacketsForSubmission(props.otherId,1)

        if (props.filterPage === 'licensesTransfers') await rootStore.fetchLicensesTransfersListNew(1)
        if (props.filterPage === 'overviewsOffer') {
          if (props.overviewsNumber! < 1000) { await rootStore.fetchDatafiltersOffers(props.overviewsNumber!, 1) }
          else { await rootStore.fetchDatafiltersOffersNew(props.overviewsNumber!, 1) }
        }
        if (props.filterPage === 'overviewsCustomer') await rootStore.fetchDatafiltersCustomers(props.overviewsNumber!, 1)

        if (props.filterPage === 'emailingWhiteList') await rootEmailingStore.fetchEmailingWhiteList(1)
        if (props.filterPage === 'emailingBlackList') await rootEmailingStore.fetchEmailingBlackList(1)
        if (props.filterPage === 'datafiltersCustomersCustomList') await rootEmailingStore.fetchDatafiltersCustomersCustomList(1)
        if (props.filterPage === 'datafiltersCustomersCustomPreview') await rootEmailingStore.fetchDatafiltersCustomersDesignerPreview(1,rootEmailingStore.getEmailingConditionsToFinalString(),props.masterId)

        if (props.filterPage === 'meetings') await rootStore.fetchMeetings(1)
        if (props.filterPage === 'tasks') await rootStore.fetchTasks(1)

        if (props.filterPage === 'accounts') await rootStore.fetchAccounts(1)

        if (props.filterPage === 'appProDuelReceipts') await rootAppStore.fetchAppProduelReceipts(1,props.otherId,props.isCustomer)
        
        setShowFormItem(false)
        setLoading(false)
        props.setPageLoading(false);
        
        // Hodnoty hledání uložit do sessionStorage ***************************
        sessionStorage.setItem('supportSearchValue', '')
        sessionStorage.setItem(props.filterPage+'SearchTypeValue', supportSearchTypeData)

        setShowFormItem(true)

        // Nastavení tlačítka pro zrušení celého filtru      
        if (Object.keys(searchDataArray.filter(ssda => ssda.filterName !== "")).length === 0) { setShowCancel(false); localStorage.removeItem(props.filterSearchArray); if (props.setIsFiltered !== undefined) { props.setIsFiltered(false) }  } else { setShowCancel(true); if (props.setIsFiltered !== undefined) { props.setIsFiltered(true) } }
      })()
    }
  
  // Nastavení filtrů pro zobrazení
  let searchDataArrayToShow = [{filterName:"",filterValue:"",displayValue:""}]

  if (localStorage.getItem(props.filterSearchArray) !== null ) {
    searchDataArrayToShow = JSON.parse(localStorage.getItem(props.filterSearchArray)!)
  }
  else {
    searchDataArrayToShow = [{filterName:"",filterValue:"",displayValue:""}]
  }

  // Nastavení skupin pro zobrazení
  let filterGroups = [{filterName: ''}]
  let newGroup:string = ''

  if (localStorage.getItem(props.filterSearchArray) !== null) {
    searchDataArrayToShow.filter(ssda  => ssda.filterName !== '').sort(function(a:any, b:any) {
      var nameB = a.filterName; 
      var nameA = b.filterName; 
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
      // eslint-disable-next-line
    }).map((ssda) => {
      if (ssda.filterName !== newGroup) {
        filterGroups.push({filterName: ssda.filterName})
      }
      newGroup = ssda.filterName
    }
    )
      
  }
 

  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 

  // O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomer =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (customers[activeItem]?.accountNumber !== undefined) {
        setFieldValue('supportSearch',customers[activeItem].accountNumber)
      

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('supportSearch','')
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customers && activeItem === customers.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  

};


const handleChangeFilterType =  (e:any, nextInput:number) => {
  
    var form = e.currentTarget.form;
    form.elements[nextInput].focus()

}
   


  // Schéma validace hledání
  const schemaSearch = Yup.object().shape({
    
  })

  //console.log(props.filterItems[1])

  //const datum = new Date().toISOString()
  return (
    <>
      
      <>
      <div style={{float: 'left', marginRight: '15px'}}>
        {!(props.title === "" || props.title === undefined || props.title === null) &&<h4>{props.title}{!(props.titleCount === undefined || props.titleCount === null) && <> (<NumberFormat displayType={'text'} thousandSeparator={' '} value={props.titleCount} />)</>}</h4>}
        {props.titleDescription}
      </div>
      
      <div style={{float: 'right'}} >
      <Button size='sm' variant={(showCancel || showFilterForm) ? 'primary': 'outline-primary'} onClick={() => {localStorage.setItem('show'+props.filterPage+'Filter', showFilterForm ? '0':'1'); setShowFilterForm(!showFilterForm); }} style={{marginBottom: '15px', display: 'none'}}>
        <i className="fas fa-filter"></i>
      </Button>
      
      <Button size='sm' variant='outline-success' onClick={() => { refreshPage() }} style={{marginBottom: '15px', marginLeft: '5px'}}>
        <i className="fas fa-sync-alt"></i>
      </Button>
      </div>
      <div style={{float: 'right'}} >
        {props.secondaryRightButtons}
      </div>
      <div style={{float: 'left'}}>
        {props.secondaryLeftButtons}
      </div>
      
      <div style={{clear: 'both'}}></div>
      
      </>
      
      
      
      <div style={{marginTop: '20px'}}>
      <Row>
        <Col
          className="col-open-customer"
        >
            <Formik
              validationSchema={schemaSearch}
              initialValues={{ 
                supportSearch: sessionStorage.getItem('supportSearchValue') !== null ? sessionStorage.getItem('supportSearchValue')! : '' , 
                //supportSearchType: !(sessionStorage.getItem('supportSearchTypeValue') === null || sessionStorage.getItem('supportSearchTypeValue') === '') ? sessionStorage.getItem('supportSearchTypeValue')! : 'CustomerId' , 
                supportSearchType: 
                  !(sessionStorage.getItem(props.filterPage+'SearchTypeValue') === null || sessionStorage.getItem(props.filterPage+'SearchTypeValue') === '' || !(props.filterItems.includes(sessionStorage.getItem(props.filterPage+'SearchTypeValue')))) 
                  ? 
                    props.isCustomer && sessionStorage.getItem(props.filterPage+'SearchTypeValue')! === 'CustomerId' 
                    ? 
                      props.filterItems[1]
                    :
                      sessionStorage.getItem(props.filterPage+'SearchTypeValue')!  
                  : 
                    props.isCustomer 
                    ? 
                      props.filterItems[1] 
                      //"Email"
                    : 
                      props.filterItems[0]
                      //"Email"
                ,

                supportSearchDateFrom: moment(new Date()).format("YYYY-MM-DDT00:00"),
                supportSearchDateTo: moment(new Date()).format("YYYY-MM-DDT23:59"),
                supportSearchNumberFrom: 0,
                supportSearchNumberTo: 9999,
                supportSearchBooleanValue: false,
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSearch(values.supportSearch, values.supportSearchType, values.supportSearchDateFrom, values.supportSearchDateTo, values.supportSearchNumberFrom, values.supportSearchNumberTo, values.supportSearchBooleanValue)        
                  values.supportSearch = ''      
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                handleReset,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                  <FormGroup as={Col} lg={1} md={2} controlId="newFilter">
                    
                  <Form.Check 
                    type="switch"
                    id="newFilter-switch"
                    label="Nový filtr"
                    name="newFilter-switch"
                    
                    onClick={async () => { props.setStateNewFilter(!props.stateNewFilter) }}
                    checked={props.stateNewFilter}
                    onChange={() => {}}
                  />
                    
                  </FormGroup> 
                  <FormGroup as={Col} md={4} controlId="formSearchType">
                    
                    <Form.Control as="select" 
                      name="supportSearchType"
                      size='sm'
                      defaultValue={
                        //!(sessionStorage.getItem(props.filterPage+'SearchTypeValue') === null || sessionStorage.getItem(props.filterPage+'SearchTypeValue') === '') ? props.isCustomer && sessionStorage.getItem(props.filterPage+'SearchTypeValue')! === 'CustomerId' ? 'BusinessName':sessionStorage.getItem(props.filterPage+'SearchTypeValue')!  : props.isCustomer ? 'BusinessName':'CustomerId'
                        !(sessionStorage.getItem(props.filterPage+'SearchTypeValue') === null || sessionStorage.getItem(props.filterPage+'SearchTypeValue') === '' || !(props.filterItems.includes(sessionStorage.getItem(props.filterPage+'SearchTypeValue')))) 
                        ? 
                          props.isCustomer && sessionStorage.getItem(props.filterPage+'SearchTypeValue')! === 'CustomerId' 
                          ? 
                            props.filterItems[1]
                          :
                            sessionStorage.getItem(props.filterPage+'SearchTypeValue')!  
                        : 
                          props.isCustomer 
                          ? 
                            props.filterItems[1] 
                            //"Email"
                          : 
                            props.filterItems[0]
                            //"Email"
                      } 
                      onChange={(e) => {handleChange(e);  handleChangeFilterType(e,1)  }}>
                        {props.isCustomer !== true && props.filterItems.includes("CustomerId") && <option value="CustomerId">Zákazník</option>}
                        
                        {props.filterItems.includes("AccountNumber")        && <option value="AccountNumber">Číslo zákazníka</option>}
                        {props.filterItems.includes("CompanyRegistrationNumber") && <option value="CompanyRegistrationNumber">IČO</option>}
                        {props.filterItems.includes("BusinessName")         && <option value="BusinessName">Jméno</option>}
                        {props.filterItems.includes("BusinessName2")         && <option value="BusinessName2">Název</option>}

                        {props.filterItems.includes("Date")                 && <option value="Date">Datum</option>}

                        {props.filterItems.includes("Code")                 && <option value="Code">Kód</option>}
                        {props.filterItems.includes("PacketCode")           && <option value="PacketCode">Kód zásilky</option>}
                        {props.filterItems.includes("Packets.PacketCode")   && <option value="Packets.PacketCode">Kód zásilky</option>}
                        {props.filterItems.includes("Prefix")               && <option value="Prefix">Prefix</option>}
                        {props.filterItems.includes("Name")                 && <option value="Name">Název</option>}
                        {props.filterItems.includes("CompanyName")          && <option value="CompanyName">Název firmy</option>}
                        {props.filterItems.includes("FirstName")            && <option value="FirstName">Jméno osoby</option>}
                        {props.filterItems.includes("SurName")              && <option value="SurName">Příjmení</option>}
                        {props.filterItems.includes("ReturnDate")           && <option value="ReturnDate">Datum vrácení</option>}
                        {props.filterItems.includes("ReturnNote")           && <option value="ReturnNote">Poznámka při vrácení</option>}
                        {props.filterItems.includes("Email")                && <option value="Email">E-mail</option>}
                        {props.filterItems.includes("CustomerFulltext")     && <option value="CustomerFulltext">Fulltext (Číslo, Název, IČO)</option>}
                        {props.filterItems.includes("Subject")              && <option value="Subject">Předmět</option>}
                        {props.filterItems.includes("Request")              && <option value="Request">Požadavek</option>}
                        {props.filterItems.includes("CreateDate")           && <option value="CreateDate">Období</option>}
                        {props.filterItems.includes("LastEditDate")         && <option value="LastEditDate">Období poslední změny</option>}
                        {props.filterItems.includes("SupportTypeId")        && <option value="SupportTypeId">Typ kontaktu</option>}
                        {props.filterItems.includes("SupportThemeIds")      && <option value="SupportThemeIds">Téma</option>}
                        {props.filterItems.includes("IsActive")             && <option value="IsActive">Aktivní</option>}
                        {props.filterItems.includes("IsSystem")             && <option value="IsSystem">Systémový</option>}
                        {props.filterItems.includes("IsTrouble")            && <option value="IsTrouble">Problém</option>}
                        {props.filterItems.includes("IsFinished")           && <option value="IsFinished">Vyřešeno</option>}
                        {props.filterItems.includes("AuthorId")             && <option value="AuthorId">Autor</option>}
                        {props.filterItems.includes("UserId")               && <option value="UserId">Naposledy změnil</option>}
                        {props.filterItems.includes("SolverUserId")         && <option value="SolverUserId">Za řešení odpovídá</option>}
                        {props.filterItems.includes("ResponsibleUserId")    && <option value="ResponsibleUserId">Za řešení odpovídá</option>}
                        {props.filterItems.includes("IsDemo")               && <option value="IsDemo">Demoverze</option>}
                        {props.filterItems.includes("Solutions.Text")       && <option value="Solutions.Text">Řešení - text</option>}
                        {props.filterItems.includes("Solutions.CreateDate") && <option value="Solutions.CreateDate">Řešení - období</option>}
                        {props.filterItems.includes("Solutions.AuthorId")   && <option value="Solutions.AuthorId">Řešení - autor</option>}
                        {props.filterItems.includes("Solutions.UserId")     && <option value="Solutions.UserId">Řešení - naposledy změnil</option>}
                        {props.filterItems.includes("Solutions.SupportTypeId") && <option value="Solutions.SupportTypeId">Řešení - typ kontaktu</option>}
                        {props.filterItems.includes("Solutions.IsData")     && <option value="Solutions.IsData">Řešení - data</option>}
                        {props.filterItems.includes("Solutions.ToInvoice")  && <option value="Solutions.ToInvoice">Řešení - fakturovat</option>}
                        {props.filterItems.includes("Solutions.Duration")   && <option value="Solutions.Duration">Řešení - doba trvání</option>}
                        {props.filterItems.includes("Duration")             && <option value="Duration">Celková doba řešení (min.)</option>}
                        {props.filterItems.includes("MyNotFinish")          && <option value="MyNotFinish">Moje nevyřešené</option>}
                        {props.filterItems.includes("MyNotFinishAfterTerm") && <option value="MyNotFinishAfterTerm">Moje nevyřešené - po termínu</option>}
                        {props.filterItems.includes("AllNotFinishAfterTerm") && <option value="AllNotFinishAfterTerm">Všechny nevyřešené - po termínu</option>}
                        {props.filterItems.includes("DistributionMethod")   && <option value="DistributionMethod">Způsob distribuce</option>}
                        {props.filterItems.includes("ServiceId")            && <option value="ServiceId">Obsažená služby</option>}
                        {props.filterItems.includes("UpgradeScenarioId")    && <option value="UpgradeScenarioId">Scénář upgrade</option>}
                        {props.filterItems.includes("IsDiscount")           && <option value="IsDiscount">Sleva</option>}
                        {props.filterItems.includes("IsLicenseChanged")     && <option value="IsLicenseChanged">Změna licence v rámci upgrade</option>}
                        {props.filterItems.includes("IsLicenseChangedInProtectedPeriod") && <option value="IsLicenseChangedInProtectedPeriod">Změna licence v ochraném období</option>}
                        {props.filterItems.includes("IsNewNetworkLicense")  && <option value="IsNewNetworkLicense">Nová síťová verze</option>}
                        {props.filterItems.includes("InvoiceNumber")        && <option value="InvoiceNumber">Číslo faktury</option>}

                        {props.filterItems.includes("DistributionDate")     && <option value="DistributionDate">Datum distribuce</option>}
                        {props.filterItems.includes("Note")                 && <option value="Note">Poznámka</option>}
                        {props.filterItems.includes("Reason")               && <option value="Reason">Důvod</option>}
                        {props.filterItems.includes("Count")                && <option value="Count">Počet</option>}
                        {props.filterItems.includes("ReturnCount")          && <option value="ReturnCount">Vráceno</option>}
                        {props.filterItems.includes("Amount")               && <option value="Amount">Hodnota</option>}
                        {props.filterItems.includes("Weight")               && <option value="Weight">Hmotnost</option>}
                        {props.filterItems.includes("IsExported")           && <option value="IsExported">Exportováno</option>}
                        {props.filterItems.includes("IsSubmitted")          && <option value="IsSubmitted">Předáno dopravci</option>}

                        {props.filterItems.includes("Source")               && <option value="Source">Zdroj</option>}
                        {props.filterItems.includes("IsVerified")           && <option value="IsVerified">Ověřeno</option>}

                        {props.filterItems.includes("IsPublished")          && <option value="IsPublished">Publikováno</option>}
                        {props.filterItems.includes("IsManagement")         && <option value="IsManagement">Vedení</option>}
                        {props.filterItems.includes("Text")                 && <option value="Text">Text</option>}

                        {props.filterItems.includes("DeadLine")             && <option value="DeadLine">Termín</option>}

                        {props.filterItems.includes("IsPrivate")            && <option value="IsPrivate">Soukromý</option>}
                        {props.filterItems.includes("IsDone")               && <option value="IsDone">Splněno</option>}
                        {props.filterItems.includes("IsCompleted")          && <option value="IsCompleted">Uzavřeno</option>}

                        {props.filterItems.includes("IsDisabled")           && <option value="IsDisabled">Zablokováno</option>}
                        {props.filterItems.includes("IsEmployee")           && <option value="IsEmployee">Zaměstnanec</option>}

                        {props.filterItems.includes("MyTasks")              && <option value="MyTasks">Moje úkoly (všechny)</option>}
                        {props.filterItems.includes("MyNotFinishTasks")          && <option value="MyNotFinishTasks">Moje nesplněné úkoly</option>}
                        {props.filterItems.includes("MyNotFinishTasksPrivate")          && <option value="MyNotFinishTasksPrivate">Moje soukromé nesplněné úkoly</option>}
                        {props.filterItems.includes("MyNotFinishTasksAfterTerm") && <option value="MyNotFinishTasksAfterTerm">Moje nesplněné úkoly - po termínu</option>}
                        {props.filterItems.includes("AllNotFinishTasksAfterTerm") && <option value="AllNotFinishTasksAfterTerm">Všechny nesplněné úkoly - po termínu</option>}
                        {props.filterItems.includes("AuthorNotFinishTasks") && <option value="AuthorNotFinishTasks">Mnou zadané úkoly - nesplněné</option>}
                        {props.filterItems.includes("AuthorNotFinishTasksAfterTerm") && <option value="AuthorNotFinishTasksAfterTerm">Mnou zadané úkoly - nesplněné po termínu</option>}
                        {props.filterItems.includes("AuthorNotClosedTasks") && <option value="AuthorNotClosedTasks">Mnou zadané úkoly - splněné a nepotvrzené</option>}

                        {props.filterItems.includes("IncompletedMeetingsTasks") && <option value="IncompletedMeetingsTasks">Nevyřešené porady</option>}
                        {props.filterItems.includes("UnreadMeetings")       && <option value="UnreadMeetings">Nepřečtené porady (kterých se účastním)</option>}

                      {props.filterItems.includes("ReceiptDate")            && <option value="ReceiptDate">Datum vystavení</option>}
                        {props.filterItems.includes("Signature")            && <option value="Signature">Podpis</option>}
                        
                    </Form.Control>
                    
                  </FormGroup>  

                  {showFormItem && <>

                  {values.supportSearchType === "CustomerId" &&
                  <FormGroup as={Col} md={4} controlId="formSearch">
                    <FormControl 
                          type="text"
                          name="supportSearch"
                          size='sm'
                          value={values.supportSearch!}
                          onChange={(e) => {handleChange(e);  state.displayItems = true; state.activeItem = 0;    
                            //setCustomers(customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(e.target.value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(e.target.value!.toLowerCase())))
                             filterCustomers(e.target.value) 
                            }}
                          //tabIndex={1}
                          autoComplete="off" 
                          autoFocus={!props.isCustomer?true:false}
                          isValid={touched.supportSearch && !errors.supportSearch} 
                          isInvalid={!!errors.supportSearch}
                          onKeyDown={(e:any) => handleKeyDownCustomer(e, setFieldValue, 3)}
                          
                        />
                        {values.supportSearch! !== '' && state.displayItems ?
                            <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customers.map((dp,index) => {
                                        return (
                                            <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={async (e:any) => {await setFieldValue("supportSearch",dp.accountNumber);  await setState({activeItem: state.activeItem, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue }); }}
                                                key = {dp.id}
                                                >
                                                {dp.accountNumber} - {dp.businessName}
                                            </li>
                                        )
                                    }).slice(0, 50)
                                }
                                </ul>
                            </div>
                            : null
                        }


                  </FormGroup>
                  
                  
                  
                  }



                  {getStatusSelectForFiltering(values.supportSearchType) === true &&
                  <FormGroup as={Col} md={4} controlId="formSearch">
                        <FormControl as="select"  
                          //autoFocus={props.isCustomer === true ? false:true}
                          autoFocus={true}
                          name="supportSearch"
                          size='sm'
                          defaultValue=""
                          onChange={handleChange}
                          isInvalid={!!errors.supportSearch}>
                            <option value="">--- vyberte ---</option>
                            { (["AuthorId","UserId","SolverUserId","ResponsibleUserId","Solutions.AuthorId","Solutions.UserId"].includes(values.supportSearchType)) && <SelectOptionsHedgehogs />}
                            { (["AuthorId","UserId","SolverUserId","ResponsibleUserId","Solutions.AuthorId","Solutions.UserId"].includes(values.supportSearchType)) && <option value="" disabled>--- NEAKTIVNÍ UŽIVATELÉ ---</option>}
                            { (["AuthorId","UserId","SolverUserId","ResponsibleUserId","Solutions.AuthorId","Solutions.UserId"].includes(values.supportSearchType)) && <SelectOptionsHedgehogsInactive />}

                            { (["SupportTypeId","Solutions.SupportTypeId"].includes(values.supportSearchType)) && <SelectOptionsSupportTypes />}
                            { (["SupportThemeIds"].includes(values.supportSearchType)) && <SelectOptionsSupportThemes />}

                            { (["ServiceId"].includes(values.supportSearchType)) && <SelectOptionsServices />}
                            { (["DistributionMethod"].includes(values.supportSearchType)) && <SelectOptionsDistributionMethod />}
                            { (["UpgradeScenarioId"].includes(values.supportSearchType)) && <SelectOptionsUpgradeScenarios />}
                        
                        
                        </FormControl>
                        
                        
                  </FormGroup>
                  }
                  {getStatusStringForFiltering(values.supportSearchType) === true &&
                  <FormGroup as={Col} md={4} controlId="formSearch">
                        <FormControl 
                          //autoFocus={props.isCustomer === true ? false:true}
                          autoFocus={!props.isCustomer?true:false}
                          type="text"
                          name="supportSearch"
                          size='sm'
                          value={values.supportSearch}
                          onChange={handleChange}
                          placeholder="Hledaný výraz"
                          onFocus={function(e:any) { e.target.select()}}
                          isInvalid={!!errors.supportSearch}
                          autoComplete="off"
                        />  
                  </FormGroup>
                  }
                  {getStatusDateForFiltering(values.supportSearchType) === true &&
                  <>
                  <FormGroup as={Col} md={2} controlId="supportSearchDateFrom">
                        <FormControl 
                          autoFocus={!props.isCustomer?true:false}
                          type="datetime-local"
                          name="supportSearchDateFrom"
                          size='sm'
                          value={values.supportSearchDateFrom}
                          onChange={handleChange}
                          placeholder="Datum od"
                          onFocus={function(e:any) { e.target.select()}}
                          isInvalid={!!errors.supportSearchDateFrom}
                        />  
                  </FormGroup>
                  
                  <FormGroup as={Col} md={2} controlId="supportSearchDateTo">
                        <FormControl 
                          
                          type="datetime-local"
                          name="supportSearchDateTo"
                          size='sm'
                          value={values.supportSearchDateTo}
                          onChange={handleChange}
                          placeholder="Datum od"
                          onFocus={function(e:any) { e.target.select()}}
                          isInvalid={!!errors.supportSearchDateTo}
                        />  
                  </FormGroup>

                  </>}
                  {getStatusNumberForFiltering(values.supportSearchType) === true &&
                  <>
                  <FormGroup as={Col} md={2} controlId="supportSearchNumberFrom">
                        <FormControl 
                          autoFocus={!props.isCustomer?true:false}
                          type="number"
                          name="supportSearchNumberFrom"
                          size='sm'
                          value={values.supportSearchNumberFrom}
                          onChange={handleChange}
                          placeholder="Číslo od"
                          onFocus={function(e:any) { e.target.select()}}
                          isInvalid={!!errors.supportSearchNumberFrom}
                          min={-999999999}
                          max={999999999}
                        />  
                  </FormGroup>
                  
                  <FormGroup as={Col} md={2} controlId="supportSearchNumberTo">
                        <FormControl 
                          
                          type="number"
                          name="supportSearchNumberTo"
                          size='sm'
                          value={values.supportSearchNumberTo}
                          onChange={handleChange}
                          placeholder="Číslo do"
                          onFocus={function(e:any) { e.target.select()}}
                          isInvalid={!!errors.supportSearchNumberTo}
                          min={-999999999}
                          max={999999999}
                        />  
                  </FormGroup>

                  </>}
                  {getStatusBooleanForFiltering(values.supportSearchType) === true &&
                  <>
                  <FormGroup as={Col} md={4} controlId="supportSearchBooleanValue">
                        <FormControl 
                          autoFocus={true}
                          type="checkbox"
                          name="supportSearchBooleanValue"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.supportSearchBooleanValue}
                          isValid={touched.supportSearchBooleanValue && !errors.supportSearchBooleanValue} 
                          isInvalid={!!errors.supportSearchBooleanValue}
                        />
                        
                  </FormGroup>

                  </>}

                  </>}

                  <FormGroup as={Col} md={3} controlId="formSearch">
                      
                      <ButtonGroup  className="btn-block">
                        <Button type="submit" variant="secondary" size="sm" disabled={!(isValid ) || loading} >{loading ? 'Filtruji...' : 'Filtrovat'}</Button>  
                        {(values.supportSearch !== '' || showCancel) &&
                        <Button 
                        variant="outline-danger" 
                        size="sm" 
                        disabled={loading ? true : false} 
                        style={{maxWidth: '150px'}}
                        onClick={() => { 
                           values.supportSearch = ''; localStorage.removeItem(props.filterSearchArray); handleSearch('', '', '', '', 0, 0, false) ; setShowCancel(false); setShowFilterForm(true); 
                          }} >
                        Zrušit filtrování
                      </Button>
                        }
                      </ButtonGroup>
                    
                  </FormGroup>                      
                  </Form.Row>  
                  {error}
                  
                  
                  
                </Form>
              )}
            </Formik> 
          
        </Col>
        
      </Row>
      </div> 
      <div style={{clear: 'both'}}></div>

      <div style={{fontSize: '14px' }}>
      {Object.keys(filterGroups.filter(fg => fg.filterName !== '')).length > 0 && 
      <Alert variant='secondary'>
        <div style={{float: 'left'}} >
          <strong>Aktivní filtr</strong>
        </div>
      
      
      

      {filterGroups.filter(fg => fg.filterName !== '').slice().map((fg,index) => (
        
        <div key={index} style={{float: 'right', marginLeft: '10px'}} >
          {fg.filterName === 'CustomerId' && <>Zákazník:&nbsp;</> }
          {fg.filterName === 'Email' && <>E-mail:&nbsp;</> }
          {fg.filterName === 'AccountNumber' && <>Číslo zákazníka:&nbsp;</> }
          {fg.filterName === 'CompanyRegistrationNumber' && <>IČO:&nbsp;</> }
          {fg.filterName === 'CustomerFulltext' && <>Fulltext:&nbsp;</> }

          {fg.filterName === 'Date' && <>Datum:&nbsp;</> }

          {fg.filterName === 'Code' && <>Kód:&nbsp;</> }
          {fg.filterName === 'Prefix' && <>Prefix:&nbsp;</> }
          {fg.filterName === 'Name' && <>Název:&nbsp;</> }

          {fg.filterName === 'PacketCode' && <>Kód zásilky:&nbsp;</> }
          {fg.filterName === 'Packets.PacketCode' && <>Kód zásilky:&nbsp;</> }

          {fg.filterName === 'CompanyName' && <>Název firmy:&nbsp;</> }
          {fg.filterName === 'FirstName' && <>Jméno osoby:&nbsp;</> }
          {fg.filterName === 'Surname' && <>Příjmení:&nbsp;</> }
          {fg.filterName === 'SurName' && <>Příjmení:&nbsp;</> }
          {fg.filterName === 'ReturnDate' && <>Datum vrácení:&nbsp;</> }
          {fg.filterName === 'ReturnNote' && <>Poznámka při vrácení:&nbsp;</> }

          {fg.filterName === 'DistributionDate' && <>Datum distribuce:&nbsp;</> }
          {fg.filterName === 'Note' && <>Poznámka:&nbsp;</> }
          {fg.filterName === 'Reason' && <>Důvod:&nbsp;</> }
          {fg.filterName === 'ReturnCount' && <>Počet vrácených zásilek:&nbsp;</> }
          {fg.filterName === 'IsExported' && <>Exportováno:&nbsp;</> }
          {fg.filterName === 'IsSubmitted' && <>Předáno dopravci:&nbsp;</> }
          {fg.filterName === 'Count' && <>Počet:&nbsp;</> }
          {fg.filterName === 'Weight' && <>Hmotnost:&nbsp;</> }
          {fg.filterName === 'Amount' && <>Hodnota:&nbsp;</> }

          {fg.filterName === 'BusinessName' && <>Jméno:&nbsp;</> }
          {fg.filterName === 'BusinessName2' && <>Název:&nbsp;</> }
          {fg.filterName === 'Subject' && <>Předmět:&nbsp;</> }
          {fg.filterName === 'Request' && <>Požadavek:&nbsp;</> }
          {fg.filterName === 'CreateDate' && <>Období:&nbsp;</> }
          {fg.filterName === 'LastEditDate' && <>Období posl. změny:&nbsp;</> }
          {fg.filterName === 'IsDemo' && <>Demoverze:&nbsp;</> }
          {fg.filterName === 'Duration' && <>Doba celk.:&nbsp;</> }
          {fg.filterName === 'SupportTypeId' && <>Typ kont.:&nbsp;</> }
          {fg.filterName === 'SupportThemeIds' && <>Téma kont.:&nbsp;</> }
          
          {fg.filterName === 'IsActive' && <>Aktivní:&nbsp;</> }
          {fg.filterName === 'IsSystem' && <>Systémový:&nbsp;</> }
          
          {fg.filterName === 'IsTrouble' && <>Problém:&nbsp;</> }
          {fg.filterName === 'IsFinished' && <>Vyřešeno:&nbsp;</> }
          {fg.filterName === 'SolverUserId' && <>Odpovídá:&nbsp;</> }
          {fg.filterName === 'ResponsibleUserId' && <>Odpovídá:&nbsp;</> }
          {fg.filterName === 'UserId' && <>Naposledy změnil:&nbsp;</> }
          {fg.filterName === 'AuthorId' && <>Autor:&nbsp;</> }
          {fg.filterName === 'MyNotFinish' && <>Moje nevyřešené:&nbsp;</> }
          {fg.filterName === 'MyNotFinishAfterTerm' && <>Moje nevyřešené po termínu:&nbsp;</> }
          {fg.filterName === 'AllNotFinishAfterTerm' && <>Všechny nevyřešené po termínu:&nbsp;</> }

          {fg.filterName === 'MyTasks' && <>Moje úkoly:&nbsp;</> }
          {fg.filterName === 'MyNotFinishTasks' && <>Moje nesplněné:&nbsp;</> }
          {fg.filterName === 'MyNotFinishTasksPrivate' && <>Moje soukromé nesplněné:&nbsp;</> }
          {fg.filterName === 'MyNotFinishTasksAfterTerm' && <>Moje nesplněné po termínu:&nbsp;</> }
          {fg.filterName === 'AllNotFinishTasksAfterTerm' && <>Všechny nesplněné po termínu:&nbsp;</> }
          {fg.filterName === 'AuthorNotFinishTasks' && <>Mnou zadané a nesplněné:&nbsp;</> }
          {fg.filterName === 'AuthorNotFinishTasksAfterTerm' && <>Mnou zadané a nesplněné po termínu:&nbsp;</> }
          {fg.filterName === 'AuthorNotClosedTasks' && <>Mnou zadané - splněné a nepotvrzené:&nbsp;</> }

          {fg.filterName === 'IncompletedMeetingsTasks' && <>Nevyřešené porady:&nbsp;</> }
          {fg.filterName === 'UnreadMeetings' && <>Nepřečtené porady:&nbsp;</> }

          {fg.filterName === 'startFilter' && <>Vstupní filtr:</> }

          {fg.filterName === 'Solutions.CreateDate' && <>Období řešení:&nbsp;</> }
          {fg.filterName === 'Solutions.Text' && <>Text řešení:&nbsp;</> }
          {fg.filterName === 'Solutions.AuthorId' && <>Autor řešení:&nbsp;</> }
          {fg.filterName === 'Solutions.UserId' && <>Naposledy změnil řešení:&nbsp;</> }
          {fg.filterName === 'Solutions.SupportTypeId' && <>Typ kont. řešení:&nbsp;</> }
          {fg.filterName === 'Solutions.IsData' && <>Data:&nbsp;</> }
          {fg.filterName === 'Solutions.ToInvoice' && <>Fakturovat řešení:&nbsp;</> }
          {fg.filterName === 'Solutions.Duration' && <>Doba řešení:&nbsp;</> }

          {fg.filterName === 'ServiceId' && <>Obsažená služba:&nbsp;</> }
          {fg.filterName === 'DistributionMethod' && <>Způsob distribuce:&nbsp;</> }
          {fg.filterName === 'UpgradeScenarioId' && <>Scénář upgrade:&nbsp;</> }
          {fg.filterName === 'IsDiscount' && <>Sleva:&nbsp;</> }
          {fg.filterName === 'IsLicenseChanged' && <>Změna licence v rámci upgrade:&nbsp;</> }
          {fg.filterName === 'IsLicenseChangedInProtectedPeriod' && <>Změna licence v ochraném období:</> }
          {fg.filterName === 'IsNewNetworkLicense' && <>Nová síťová verze:</> }
          {fg.filterName === 'InvoiceNumber' && <>Číslo faktury:</> }

          {fg.filterName === 'Source' && <>Zdroj:</> }
          {fg.filterName === 'IsVerified' && <>Ověřeno:&nbsp;</> }

          {fg.filterName === 'IsDisabled' && <>Zablokováno:&nbsp;</> }
          {fg.filterName === 'IsEmployee' && <>Zaměstnanec:&nbsp;</> }

          {fg.filterName === 'IsPublished' && <>Publikováno:&nbsp;</> }
          {fg.filterName === 'IsManagement' && <>Vedení:&nbsp;</> }
          {fg.filterName === 'Text' && <>Text:&nbsp;</> }

          {fg.filterName === 'DeadLine' && <>Termín:&nbsp;</> }

          {fg.filterName === 'IsPrivate' && <>Soukromý:&nbsp;</> }
          {fg.filterName === 'IsDone' && <>Splněno:&nbsp;</> }
          {fg.filterName === 'IsCompleted' && <>Uzavřeno:&nbsp;</> }

          {fg.filterName === 'ReceiptDate' && <>Datum vystavení:&nbsp;</> }
          {fg.filterName === 'Signature' && <>Podpis:&nbsp;</> }

          {localStorage.getItem(props.filterSearchArray) !== null && searchDataArrayToShow.filter(ssda  => ssda.filterName === fg.filterName).slice().map((ssda,index) => 
              (
                <Badge key={index} variant='danger' style={{marginLeft: '5px', cursor: 'pointer'}} onClick={() => { if (!loading) {deleteFilterItem(ssda.filterName,ssda.filterValue); setShowFilterForm(true);} }}  >{ssda.displayValue !== "" ? ssda.displayValue : ssda.filterValue}&nbsp;&nbsp;<i className="fas fa-times"></i></Badge>
              )
            )
          }
        </div>
        ))
      }
        <div style={{clear: 'both'}}></div>
        </Alert>
      }
      
      </div>
      <div style={{clear: 'both'}}></div>
      




      
      <div style={{display: 'none'}}>
      
      {(loading === true || props.showLoader === true ) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <>
        
      </>}

      
      </div>
      
      
    </>
  )  
  
}

export default withRouter(observer(FilterPanel))

import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../parts/TopMenu/SideMenu.parts'
import SupportContactsAdd from './components/Support.Contacts.Add.part'
import SupportContactsList from './components/Support.Contacts.List.part'

import { Container, Row, Col } from 'react-bootstrap'

import { useMount } from '../../helpers/lifecycle-hooks'
// eslint-disable-next-line
import { useStore,useDialsStore } from '../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../Utils/Common'

function Support(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  // Nastavení práv zda jsme Ježci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  // eslint-disable-next-line
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  //const rightsBU:boolean = ((localStorage.getItem('BigUser')! === '1')) ? true : false

  

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  //const rootUpgradeStore = useUpgradeStore()

  //rootUpgradeStore.fetchUpgradeOffersScenarios()
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

 
  
  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    setShowLoader(true)
    localStorage.setItem('lastSupportTotalCount', '0')
    
    await checkVersion()

    rootStore.deleteSupport()
    

    let supportSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
    localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray))
    
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'support')
    
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      //Načtení souvisejících číselníků podle práv
      
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          //await rootStore.fetchLoggedUserPreferences()
          //await rootStore.fetchCustomerOpened()
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      } 

      if (rights === false) {
        props.history.push('/customers-dashboard')
      }
      //Konec sekce F5
      //await loadData()

      //console.log(Object.keys(rootStore.customerListAll).length)
      console.log(rootStore.customerListAll.length)

      if (rootStore.customerListAll.length < 100) await rootStore.fetchCustomerListAll('', '', 1)
      
      await rootStore.fetchLoggedUserPreferences()

      const supportDefaultFilter = rootStore.getLoggedUserPreferenceByKey('supportDefaultFilter')?.value

      let currentUser = {id:''}
      currentUser = JSON.parse(localStorage.getItem('user')!)


      if (supportDefaultFilter === 'MyNotFinish') {
        supportSearchDataArray.push({filterName: supportDefaultFilter, filterValue: '((SolverUserId~eq~'+currentUser.id+')~and~(IsFinished~eq~false))', displayValue: "Ano" })
        localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray))

        sessionStorage.setItem('supportSearchPage', '1')

      }

      if (supportDefaultFilter === 'MyNotFinishAfterTerm') {
        supportSearchDataArray.push({filterName: supportDefaultFilter, filterValue: '((SolverUserId~eq~'+currentUser.id+')~and~(IsFinished~eq~false)~and~(DeadLineDate~lt~'+ new Date().toISOString() +'))', displayValue: "Ano" })
        localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray))

        sessionStorage.setItem('supportSearchPage', '1')

      }

      if (supportDefaultFilter === 'AllNotFinishAfterTerm') {
        supportSearchDataArray.push({filterName: supportDefaultFilter, filterValue: '((IsFinished~eq~false)~and~(DeadLineDate~lt~'+ new Date().toISOString() +'))', displayValue: "Ano" })
        localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray))

        sessionStorage.setItem('supportSearchPage', '1')

      }


      //Pokud mám práva tak vždy načítám další položky 
      setLoaded(true) // Nastavení pro Loader, že je strana načtena

      rootStore.fetchHedgehogUsers()
      rootDialsStore.fetchDialsSupportThemes()
      rootDialsStore.fetchDialsSupportTypes()

      

      await rootStore.fetchSupports(1)
      setShowLoader(false)
      // !!!! Sem přidat způsoby použití programů !!!! 
      //rootDialsStore.fetchDialsPrograms()

    }

    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu loadingVersion={!loaded} />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              
              <Container fluid>
                <br />
                <h4>Podpora</h4>
                <Row>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                    
                  </Col>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                  </Col>
                </Row>
                
                <SupportContactsAdd /><br /><br />
                <SupportContactsList showLoader={showLoader} />
                
                <br /><br />
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(Support)

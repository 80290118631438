import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'
import CustomerHeader from '../../../parts/customerHeader/CustomerHeader.parts'

import CustomersLicensesActive from './components/Customers.Licenses.Active.part'
//import CustomersLicensesActiveDefault from './components/Customers.Licenses.ActiveDefault.part'
import CustomersLicensesNonactive from './components/Customers.Licenses.Nonactive.part'

import { Container } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'

function Licenses(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
  if (!localStorage.getItem('openedCustomerId')) {
    props.history.push('/customers-open')
  }

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'customers-licenses')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { await rootStore.fetchCustomerOpened() }
      } 
      //Při každém otevření stránky se načítají licence zákazníka (kvůli aktuálnosti)
      
      await (() => {
        const promise0 = rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
        const promise1 = rootDialsStore.fetchDialsPrograms()
        const promise2 = rootDialsStore.fetchDialsLicenseTypesAll()
        const promise3 = rootDialsStore.fetchDialsModulesAll()
        const promise4 = rootDialsStore.fetchDialsProgramVersionsAll()
        return Promise.all([promise0,promise1,promise2,promise3,promise4])
      })()

      
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }     

    


    setLoaded(true) // Nastavení pro Loader, že je strana načtena

    
        //console.log(rootDialsStore.dialsProgramVersions)
        //await rootDialsStore.fetchDialsPriceListsModulesAll()
    })() 
  })
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <CustomerHeader />
              <Container fluid>
                <CustomersLicensesActive />
                <br /><br />
                <CustomersLicensesNonactive />
                <br /><br />
              </Container>

            </Loader>  
            </main>
          </div>
        </div>      
      
    </div> 
  );
}

export default observer(Licenses)

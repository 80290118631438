import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'


import moment from 'moment'

interface SupportContactsSolveUpdateProps {
  supportId?:string,
  isCustomer?:boolean,
  showAs?:string    
}

const SupportContactsSolveUpdate: React.FC<SupportContactsSolveUpdateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [insertNewContact, setInsertNewContact] = useState(false)
   
  // Připojení store
  const rootStore = useStore()

  // Nastavení editivaného kontaktu
  const editedContact = rootStore.supports.find(s => s.id === props.supportId)

  // Seznam pracovníků JSW 
  const supportHedgehogs = rootStore.hedgehogUsers
   .filter(hu => hu.isEmailConfirmed === true)
   .slice()
   .sort(function(a:any, b:any) {
    var sort1A = a.surName; 
    var sort1B = b.surName; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
   .map(hu => (
     <option key={hu.id} value={hu.id}>{hu.surName}&nbsp;{hu.firstName}</option>
   ))  

  // Seznam pracovníků neaktivní 
  const supportHedgehogsInactive = rootStore.hedgehogUsers
  .filter(hu => hu.isEmailConfirmed === false)
  .slice()
  .sort(function(a:any, b:any) {
    var sort1A = a.surName; 
    var sort1B = b.surName; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(hu => (
    <option key={hu.id} value={hu.id} style={{color: 'lightgrey'}}>{hu.surName}&nbsp;{hu.firstName}</option>
  ))  

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  
  // Funkce po odeslání formuláře
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      localStorage.setItem('lastSupportType',values.supportType) // Nastavení posledního použitého typu kontaktu

      let dataToSend:any

      
        dataToSend = {
          id: props.supportId,
          solverUserId: values.solverUserId,
          deadLineDate: new Date(values.deadLineDate).toISOString(),

          createDate: new Date(editedContact?.createDate!).toISOString(),
          supportTypeId: editedContact?.supportTypeId,
          isDemo: editedContact?.isDemo,
          customerId: editedContact?.customerId,
          accountNumber: editedContact?.accountNumber,
          businessName: editedContact?.businessName,
          subject: editedContact?.subject,
          request: editedContact?.request,
          supportThemeIds: editedContact?.supportThemes,
          isTrouble: editedContact?.isTrouble,
          feeling: editedContact?.feeling,
          isFinished: editedContact?.isFinished,
          duration: editedContact?.duration,
          }
      

      // Nastavení adresy požadavku podle typu NOVÝ/EDITACE
      let urlForEditing:string = ''
      urlForEditing = '/'+props.supportId

      // Zavolání API
      await axios.post(getUrl('URL_SUPPORT_CONTACTS_ADD')+urlForEditing, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          setInsertNewContact(false)
          sessionStorage.setItem('autoCloseModal','1')

          rootStore.fetchSupportById(props.supportId)
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShowForm('')
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    /*feeling: Yup.string()
    .required('Pocit musí být vyplněn!'),
    */
    
  })  
  
// Startovací sequence při otevření formuláře
const startSequence = () => {
  if (editedContact?.customerId === rootStore.customerOpened[0]?.id!) {
    
  }
  else {
    
  }
  setInsertNewContact(true)
  setShowInfo(false) 
}

// Odchytávání kláves po stisknutí s parametrem pro korekci indexu následujícího pole ve formuláři
const handleKeyDownNextWIthCorrection = (e:any, correction:number, handleSubmit:any) => {
  
  if (e.keyCode === 13) {       //Stisknutá klávesa ENTER
    e.preventDefault();           // Vyrušit standardní funkci tlačítka
    if (e.ctrlKey) {              // CTRL + ENTER?  
      handleSubmit()              // Odeslat formulář
      return                      // Návrat z funkce zpět a nepokračovat dál
    }

    // Nastavení další pozice kurzoru ve formuláři pokud je stisknutý ENTER
    var form = e.currentTarget.form;
    var index = Array.prototype.indexOf.call(form, e.target);
    console.log(index)
    form.elements[index+1+correction].focus()
  }
  
};

// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>
          <Alert variant="success" show={showInfo}>
            <strong>Odpovědná osoba a termín byly úspěšně změněny!​</strong>
            
          </Alert>  
          {insertNewContact &&<>
          <div className={showForm} >
          <Alert variant="info" > 
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                
                
                solverUserId: editedContact?.solverUserId,
                deadLineDate: moment(editedContact?.deadLineDate).format("YYYY-MM-DDTHH:mm"), 
                
                isFinished: editedContact?.isFinished
              
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values!)              
                  setSubmitting(false);
                }, 1);
              }}
              
              
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                    <strong>Odpovědná osoba a termín</strong><br />
                    </Form.Row>
                    <Form.Row>
                    <FormGroup as={Col} md={3} sm={4} xs={12} controlId="solverUserId">
                      <FormLabel>Osoba</FormLabel>
                        <Form.Control as="select" 
                          name="solverUserId" 
                          onChange={(e) => {handleChange(e); if (e.target.value !== '') { setFieldValue('isFinished', false) } }}
                          value={values.solverUserId!}
                          isInvalid={!!errors.solverUserId}

                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            supportHedgehogs
                            }
                            <option value="">--- NEAKTIVNÍ UŽIVATELÉ ---</option>
                            {
                            supportHedgehogsInactive
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.solverUserId}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={3} sm={5}  controlId="deadLineDate">
                      <FormLabel>Termín</FormLabel>
                        <FormControl 
                          type="datetime-local"
                          name="deadLineDate"
                          value={values.deadLineDate!}
                          onChange={handleChange}
                          //tabIndex={undefined}
                          onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 0, handleSubmit)}
                          isValid={touched.deadLineDate && !errors.deadLineDate} 
                          isInvalid={!!errors.deadLineDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.deadLineDate}
                        </Form.Control.Feedback>
                    </FormGroup> 
                    </Form.Row>
                    
                    
 
                  
                     
                             
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám...' : 'Uložit změny'}</Button>
                  
                </Form>

              )}
            </Formik>  
            
            </Alert>
          </div>
          </>}
          </>



return ( 
  <>
  {props.showAs !== 'drop' ?
  <><Button variant='primary' style={{marginTop: '10px'}} size="sm" onClick={() => {startSequence()}} >Upravit</Button></>
  :
  <><Dropdown.Item onClick={() => {startSequence()}}>Odpovídá / termín</Dropdown.Item></>
  }

    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Úprava odpovědné osoby a termínu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(SupportContactsSolveUpdate))

import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Container, Button, Form, Card, Modal, Alert, CardDeck, Col, Row, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { useMount } from '../../../helpers/lifecycle-hooks'
import { useStore, useDialsStore, useUpgradeStore, useCalculatorsStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'



import { Formik } from 'formik'
import * as Yup from 'yup'

import { getUrl } from '../../../Utils/Common'
//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import NumberFormat from 'react-number-format'

//import { authFetch } from '../../../authProvider'


function UpgradeCalculator(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [showTP, setShowTP] = useState(false)

  const [errorTL, setErrorTL] = useState(null)
  const [showETL, setShowETL] = useState(false)
  const [showOKTL, setShowOKTL] = useState(false)

  const [totalPrice, setTotalPrice] = useState(0)

  const [customerDeepLoading, setCustomerDeepLoading] = useState(false)


  // AKTUALNI LICENCE
  const [aALicenseSet, setAALicenseSet] = useState(false)
  const [aALicenseType, setAALicenseType] = useState('X')
  const [aAVersion, setAAVersion] = useState('X')
  const [aATotalCount, setAATotalCount] = useState(9999)
  const [aAYCount, setAAYCount] = useState(9999)
  const [aAKCount, setAAKCount] = useState(9999)
  const [aAUCount, setAAUCount] = useState(9999)
  const [aAFCount, setAAFCount] = useState(9999)
  const [aAMCount, setAAMCount] = useState(9999)
  const [aASCount, setAASCount] = useState(9999)
  const [aAPCount, setAAPCount] = useState(9999)
  const [aAECount, setAAECount] = useState(9999)
  const [aAJCount, setAAJCount] = useState(9999)
  const [aAACount, setAAACount] = useState(9999)
  const [aARCount, setAARCount] = useState(9999)

  const [aAYCheck, setAAYCheck] = useState(false)
  const [aAKCheck, setAAKCheck] = useState(false)
  const [aAUCheck, setAAUCheck] = useState(false)
  const [aAFCheck, setAAFCheck] = useState(false)
  const [aAMCheck, setAAMCheck] = useState(false)
  const [aASCheck, setAASCheck] = useState(false)
  const [aAPCheck, setAAPCheck] = useState(false)
  const [aAECheck, setAAECheck] = useState(false)
  const [aAJCheck, setAAJCheck] = useState(false)
  const [aAACheck, setAAACheck] = useState(false)
  const [aARCheck, setAARCheck] = useState(false)

  // POZADOVANA LICENCE
  const [tTLicense, setTTLicense] = useState(false)
  const [tTLicenseSet, setTTLicenseSet] = useState(false)
  const [tTLicenseType, setTTLicenseType] = useState('X')
  const [tTTotalCount, setTTTotalCount] = useState(9999)
  const [tTYCount, setTTYCount] = useState(9999)
  const [tTKCount, setTTKCount] = useState(9999)
  const [tTUCount, setTTUCount] = useState(9999)
  const [tTFCount, setTTFCount] = useState(9999)
  const [tTMCount, setTTMCount] = useState(9999)
  const [tTSCount, setTTSCount] = useState(9999)
  const [tTPCount, setTTPCount] = useState(9999)
  const [tTECount, setTTECount] = useState(9999)
  const [tTJCount, setTTJCount] = useState(9999)
  const [tTACount, setTTACount] = useState(9999)
  const [tTRCount, setTTRCount] = useState(9999)

  const [tTYCheck, setTTYCheck] = useState(false)
  const [tTKCheck, setTTKCheck] = useState(false)
  const [tTUCheck, setTTUCheck] = useState(false)
  const [tTFCheck, setTTFCheck] = useState(false)
  const [tTMCheck, setTTMCheck] = useState(false)
  const [tTSCheck, setTTSCheck] = useState(false)
  const [tTPCheck, setTTPCheck] = useState(false)
  const [tTECheck, setTTECheck] = useState(false)
  const [tTJCheck, setTTJCheck] = useState(false)
  const [tTACheck, setTTACheck] = useState(false)
  const [tTRCheck, setTTRCheck] = useState(false)  

  // VYCHOZI LICENCE
  const [dDLicense, setDDLicense] = useState(false)
  const [dDLicenseSet, setDDLicenseSet] = useState(false)
  const [dDLicenseType, setDDLicenseType] = useState('X')
  const [dDVersion, setDDVersion] = useState('X')
  const [dDTotalCount, setDDTotalCount] = useState(9999)
  const [dDYCount, setDDYCount] = useState(9999)
  const [dDKCount, setDDKCount] = useState(9999)
  const [dDUCount, setDDUCount] = useState(9999)
  const [dDFCount, setDDFCount] = useState(9999)
  const [dDMCount, setDDMCount] = useState(9999)
  const [dDSCount, setDDSCount] = useState(9999)
  const [dDPCount, setDDPCount] = useState(9999)
  const [dDECount, setDDECount] = useState(9999)
  const [dDJCount, setDDJCount] = useState(9999)
  const [dDACount, setDDACount] = useState(9999)
  const [dDRCount, setDDRCount] = useState(9999)

  const [dDYCheck, setDDYCheck] = useState(false)
  const [dDKCheck, setDDKCheck] = useState(false)
  const [dDUCheck, setDDUCheck] = useState(false)
  const [dDFCheck, setDDFCheck] = useState(false)
  const [dDMCheck, setDDMCheck] = useState(false)
  const [dDSCheck, setDDSCheck] = useState(false)
  const [dDPCheck, setDDPCheck] = useState(false)
  const [dDECheck, setDDECheck] = useState(false)
  const [dDJCheck, setDDJCheck] = useState(false)
  const [dDACheck, setDDACheck] = useState(false)
  const [dDRCheck, setDDRCheck] = useState(false)

  


  // Nastavení práv zda jsme Ježci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false

  // Načtení Store
  const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()
  const rootDialsStore = useDialsStore()
  const rootCalculatorsStore = useCalculatorsStore()
  
  // Nastavení práv 
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'upgrade-calculator')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      if (window.performance) {
        if (window.performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()          
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
        if (rights === false) {
          props.history.push('/customers-dashboard')
        }
      } 
      if (Object.keys(rootStore.customerListAll).length < 100) await rootStore.fetchCustomerListAll('', '', 1)
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { await rootStore.fetchCustomerOpened() }

        //Načtu si licence aktuálního zákazníka
        //if (rights && rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive) await rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)

      }
      
      //Pokud mám práva tak vždy načítám další položky
      await rootUpgradeStore.fetchUpgradeOffersScenariosAll()
      //console.log(rootUpgradeStore.upgradeOffersScenarios)
      await rootDialsStore.fetchDialsPrograms()

    }
    /*if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    */ 
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })
  

  // Deklarace a nastavení stavů pro seznamy ala google
  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })

  const customerListAllF = rootStore.customerListAll

  const [customers, setCustomers] = useState(customerListAllF.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes(''))) 



  const calculatorsUpgradeScenario = Object.keys(rootCalculatorsStore.calculatorsUpgradeScenarios).length > 0 ? rootCalculatorsStore.calculatorsUpgradeScenarios[0] : undefined
  const calculatorsUpgradeCustomer = Object.keys(rootCalculatorsStore.calculatorsUpgradeCustomer).length > 0 ? rootCalculatorsStore.calculatorsUpgradeCustomer[0] : undefined

   // Seznam verzí
  const programVersions = rootDialsStore.dialsProgramVersions
  .filter(data => data.id !== calculatorsUpgradeScenario?.programVersion.id)
  .slice()
  .sort(function(a:any, b:any) {
      var nameA = a.version; 
      var nameB = b.version; 
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    })
  .map(dialsProgramVersions => (
    <option key={dialsProgramVersions.id} value={dialsProgramVersions.id}>{dialsProgramVersions.name}</option>
  ))  

   // Seznam typů licencí
   const licenseTypes = rootDialsStore.dialsLicenseTypes
   .slice()
   .sort(function(a:any, b:any) {
       var nameA = a.code; 
       var nameB = b.code; 
       if (nameA < nameB) {
         return -1;
       }
       if (nameA > nameB) {
         return 1;
       }
       return 0;
     })
   .map(dialsLicenseTypes => (
     <option key={dialsLicenseTypes.id} value={dialsLicenseTypes.id}>{dialsLicenseTypes.code} - {dialsLicenseTypes.name}</option>
   ))   

   // Seznam typů licencí A,B
   const licenseTypesAB = rootDialsStore.dialsLicenseTypes
   .filter(data => data.code === 'A' || data.code === 'B') 

  // ID licence A
  const licenseTypesIdA = rootDialsStore.dialsLicenseTypes
  .find(data => data.code === 'A' ) 

  // ID licence B
  const licenseTypesIdB = rootDialsStore.dialsLicenseTypes
  .find(data => data.code === 'B' )   

   // Seznam scénářů
   const scenarios = rootUpgradeStore.upgradeOffersScenarios
   .filter(sc => sc.isActive === true)
   .slice()
   .sort(function(a:any, b:any) {
       var nameA = a.programVersion.version; 
       var nameB = b.programVersion.version; 
       if (nameA < nameB) {
         return -1;
       }
       if (nameA > nameB) {
         return 1;
       }
       return 0;
     })
   .map(scenario => (
     <option key={scenario.id} value={scenario.id}>{scenario.program.name}&nbsp;{scenario.programVersion.name}</option>
   ))   

  // Otevírání a zavírání modálního okna
  const handleClose = () => {
    setShowOKTL(false)
    setShowETL(false)
    setErrorTL(null)
    setError(null)
    setShowTP(false)
  }

    // Otevírání a zavírání modálního okna
    const handleClose2 = (e:any) => {
      e.persist()
      setShowOKTL(false)
      setShowETL(false)
      setErrorTL(null)
      setError(null)
      setShowTP(false)
    }

  const handleDelete = () => {
    (async () => {
      setLoaded(false)  
      await rootCalculatorsStore.deleteCalculatorsUpgrade()
      await rootCalculatorsStore.deleteCalculatorsUpgradeScenarios()
      setShowTP(false)
      setLoaded(true)
    })()
  }

  const handleScenario = (scenario:string) => {
    (async () => {
      setLoaded(false)  
      //localStorage.setItem('claculatorUpgradeScenario', scenario)
      //await rootCalculatorsStore.fetchCalculatorsUpgradeScenarios(scenario)
      await rootCalculatorsStore.fetchCalculatorsUpgradeScenarios2(rootUpgradeStore.getUpgradeOffersScenarioById(scenario));
      
      await (() => {
        const promise1 = rootDialsStore.fetchDialsProgramVersions(rootCalculatorsStore.calculatorsUpgradeScenarios[0]?.program.id!)
        const promise2 = rootDialsStore.fetchDialsLicenseTypes(rootCalculatorsStore.calculatorsUpgradeScenarios[0]?.program.id!)
        const promise3 = rootDialsStore.fetchDialsModules(rootCalculatorsStore.calculatorsUpgradeScenarios[0]?.program.id!)
        //const promise4 = rootDialsStore.fetchDialsPriceListsModules(rootCalculatorsStore.calculatorsUpgradeScenarios[0]?.program.id!)
        const promise4 = rootDialsStore.fetchDialsPriceListsModulesAll()
        return Promise.all([promise1,promise2,promise3,promise4])
      })()

      setLoaded(true)
    })()
  }

  const handleCustomer = (licenseNumber:string) => {
    (async () => {
      await setLoaded(false)  
      //await handleMyReset()
      await rootCalculatorsStore.deleteCalculatorsUpgrade()
      await rootCalculatorsStore.fetchCalculatorsUpgradeCustomer(licenseNumber)
      if (rootCalculatorsStore.calculatorsUpgradeCustomer[0]?.id !== undefined) {
        await rootCalculatorsStore.fetchCalculatorsUpgradeCustomerLicenses(rootCalculatorsStore.calculatorsUpgradeCustomer[0]?.id)
        //console.log(rootCalculatorsStore.calculatorsUpgradeCustomerLicenses)
      }
      else {
        let errorMessage:any = <Alert variant='danger'>Nepodařilo se načíst zákazníka!</Alert>
        setErrorTL(errorMessage)
        setShowETL(true)
        //alert('Nenalezeno!')
      }
      await setLoaded(true)
    })()
  }






  const getModuleCount = (moduleCode:string,programCode:string,values:any) => {
    let count:number = 99
    //console.log(values)
    if (programCode === "S") {
      if (moduleCode === "K") count = values.KCheck ? values.TCount : 0
      if (moduleCode === "U") count = values.UCheck ? values.TCount : 0
      if (moduleCode === "F") count = values.FCheck ? values.TCount : 0
      if (moduleCode === "M") count = values.MCheck ? values.TCount : 0
      if (moduleCode === "S") count = values.SCheck ? values.TCount : 0
      if (moduleCode === "P") count = values.PCheck ? values.TCount : 0
      if (moduleCode === "E") count = values.ECheck ? values.TCount : 0
      if (moduleCode === "J") count = values.JCheck ? values.TCount : 0
      if (moduleCode === "A") count = values.ACheck ? values.TCount : 0
      if (moduleCode === "Y") count = values.YCheck ? values.TCount : 0
      if (moduleCode === "R") count = values.RCheck ? values.TCount : 0
    }
    else {
      if (moduleCode === "K") count = values.KCount
      if (moduleCode === "U") count = values.UCount
      if (moduleCode === "F") count = values.FCount
      if (moduleCode === "M") count = values.MCount
      if (moduleCode === "S") count = values.SCount
      if (moduleCode === "P") count = values.PCount
      if (moduleCode === "E") count = values.ECount
      if (moduleCode === "J") count = values.JCount
      if (moduleCode === "A") count = values.ACount
      if (moduleCode === "Y") count = values.YCount
      if (moduleCode === "R") count = values.RCount
    }

    return count
  }


  // Funkce pro přenos licence
  const handleCalculate = (values:any,toClipboard:boolean) => {
    //console.log('calculating');
    //console.log(values);
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   

      setLoading(true)
      const upgradeScenario = rootCalculatorsStore.calculatorsUpgradeScenarios[0]
      //const priceList = rootDialsStore.getDialsPriceListsModulesToDate(upgradeScenario.distributionDate ?? '',upgradeScenario.program.id ?? '').filter(data => data.module.programId === upgradeScenario.program.id)
      // !!! V následující funkci jsou vyloučené POLOTOVARY
      const priceList = rootDialsStore.getDialsPriceListsModulesToDate(upgradeScenario.distributionDate ?? '',upgradeScenario.program.id ?? '').filter(pl => pl.module.code !== 'R')

      const defaultLicense = {
        id: 'db8c71cf-0462-4bc4-a3df-fce5e220f5d7',
        count: values.programCode === 'D' ? values.dYCount : values.dTotalCount,
        isActive: true,
        status: 'V',
        program: upgradeScenario.program,
        programVersion: rootDialsStore.dialsProgramVersions.find(data => data.id === values.dVersion),
        licenseType: rootDialsStore.dialsLicenseTypes.find(data => data.id === values.dLicenseType),
        modules: 
          rootDialsStore.dialsModules
          .filter(data => data.programId === upgradeScenario?.program.id && data.code !== 'R')
          .map(module => ({
            count: getModuleCount(module.code!, values.programCode, {TCount: values.dTotalCount, KCount: values.dKCount, UCount: values.dUCount, FCount: values.dFCount, MCount: values.dMCount, SCount: values.dSCount, PCount: values.dPCount, ECount: values.dECount, JCount: values.dJCount, ACount: values.dACount, YCount: values.dYCount, RCount: values.dRCount, KCheck: values.dKCheck, UCheck: values.dUCheck, FCheck: values.dFCheck, MCheck: values.dMCheck, SCheck: values.dSCheck, PCheck: values.dPCheck, ECheck: values.dECheck, JCheck: values.dJCheck, ACheck: values.dACheck, YCheck: values.dYCheck, RCheck: values.dRCheck}),
            
            module

          }))
        
      }
      //console.log(defaultLicense)

      const actualLicense = {
        id: 'db8c71cf-0462-4bc4-a3df-fce5e220f5d7',
        count: values.programCode === 'D' ? values.aYCount : values.aTotalCount,
        isActive: true,
        status: 'A',
        program: upgradeScenario.program,
        programVersion: rootDialsStore.dialsProgramVersions.find(data => data.id === values.aVersion),
        licenseType: rootDialsStore.dialsLicenseTypes.find(data => data.id === values.aLicenseType),
        modules: 
          rootDialsStore.dialsModules
          .filter(data => data.programId === upgradeScenario?.program.id && data.code !== 'R')
          .map(module => ({ 
            count: getModuleCount(module.code!, values.programCode, {TCount: values.aTotalCount, KCount: values.aKCount, UCount: values.aUCount, FCount: values.aFCount, MCount: values.aMCount, SCount: values.aSCount, PCount: values.aPCount, ECount: values.aECount, JCount: values.aJCount, ACount: values.aACount, YCount: values.aYCount, RCount: values.aRCount, KCheck: values.aKCheck, UCheck: values.aUCheck, FCheck: values.aFCheck, MCheck: values.aMCheck, SCheck: values.aSCheck, PCheck: values.aPCheck, ECheck: values.aECheck, JCheck: values.aJCheck, ACheck: values.aACheck, YCheck: values.aYCheck, RCheck: values.aRCheck}),
            module
          }))
        
      }

      const targetLicense = {
        id: 'db8c71cf-0462-4bc4-a3df-fce5e220f5d7',
        count: values.programCode === 'D' ? values.tYCount : values.tTotalCount,
        isActive: true,
        status: 'A',
        program: upgradeScenario.program,
        programVersion: upgradeScenario.programVersion,
        licenseType: rootDialsStore.dialsLicenseTypes.find(data => data.id === values.tLicenseType),
        modules: 
          rootDialsStore.dialsModules
          .filter(data => data.programId === upgradeScenario?.program.id && data.code !== 'R')
          .map(module => ({ 
            count: getModuleCount(module.code!, values.programCode, {TCount: values.tTotalCount, KCount: values.tKCount, UCount: values.tUCount, FCount: values.tFCount, MCount: values.tMCount, SCount: values.tSCount, PCount: values.tPCount, ECount: values.tECount, JCount: values.tJCount, ACount: values.tACount, YCount: values.tYCount, RCount: values.tRCount, KCheck: values.tKCheck, UCheck: values.tUCheck, FCheck: values.tFCheck, MCheck: values.tMCheck, SCheck: values.tSCheck, PCheck: values.tPCheck, ECheck: values.tECheck, JCheck: values.tJCheck, ACheck: values.tACheck, YCheck: values.tYCheck, RCheck: values.tRCheck}),
            module 
          }))
        
      }

      let dataToSend={}

      if (values.dLicense === true && values.tLicense === true) {
        dataToSend = {
        priceList: priceList, 
        upgradeScenario: upgradeScenario, 
        defaultLicense: defaultLicense, 
        activeLicense: actualLicense,
        targetLicense: targetLicense
        }
      }
      if (values.dLicense === true && values.tLicense === false) {
        dataToSend = {
        priceList: priceList, 
        upgradeScenario: upgradeScenario, 
        defaultLicense: defaultLicense, 
        activeLicense: actualLicense
        }
      }
      if (values.dLicense === false && values.tLicense === true) {
        dataToSend = {
        priceList: priceList, 
        upgradeScenario: upgradeScenario, 
        targetLicense: targetLicense, 
        activeLicense: actualLicense
        }
      }
      if (values.dLicense === false && values.tLicense === false) {
        dataToSend = {
        priceList: priceList, 
        upgradeScenario: upgradeScenario, 
        activeLicense: actualLicense
        }
      }

      if (toClipboard === true) {
        /*
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = JSON.stringify(dataToSend) ;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        */
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(JSON.stringify(dataToSend));
        hiddenElement.target = '_blank';
        hiddenElement.download = 'JSON_calculator.txt';
        hiddenElement.click();
      }

      if (toClipboard === false) {
        // Zavolání API   
        await axios.post(getUrl('URL_OFFERS_CALCULATE'), dataToSend, 
        { 
          headers: { 'Authorization': 'Bearer ' + aToken}
        }
          ).then(response => {
            
            setTotalPrice(response.data.totalPrice)
            setShowTP(true)
          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setErrorTL(errorMessage)
            setShowETL(true)
          });

        }
      setLoading(false)
      })()    
  }


  

  const defaultLicense = rootCalculatorsStore.getUpgradeCustomerLicenseActiveDefaultByProgramId(calculatorsUpgradeScenario?.program.id!)
  const actualLicense = rootCalculatorsStore.getUpgradeCustomerLicenseActiveActualByProgramId(calculatorsUpgradeScenario?.program.id!)

  //const actualLicense = rootCalculatorsStore.getUpgradeCustomerLicenseActiveActualByProgramId(customerLicenseActual?.program.id!)

  const dLicense = defaultLicense?.license.licenseType.id !== undefined ? true:false
  const tLicense = actualLicense?.license.licenseType.id !== undefined ? true:false
  
  const dLicenseType = defaultLicense?.license.licenseType.id
  const aLicenseType = actualLicense?.license.licenseType.id
  const tLicenseType = actualLicense?.license.licenseType.id

  const dProgramVersion = defaultLicense?.license.programVersion.id
  const aProgramVersion = actualLicense?.license.programVersion.id

  const visibleY = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'Y')).length > 0 ? true:false  
  const visibleK = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'K')).length > 0 ? true:false  
  const visibleU = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'U')).length > 0 ? true:false  
  const visibleF = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'F')).length > 0 ? true:false  
  const visibleM = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'M')).length > 0 ? true:false  
  const visibleS = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'S')).length > 0 ? true:false  
  const visibleP = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'P')).length > 0 ? true:false  
  const visibleE = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'E')).length > 0 ? true:false  
  const visibleJ = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'J')).length > 0 ? true:false  
  const visibleA = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'A')).length > 0 ? true:false  
  const visibleR = Object.keys(rootDialsStore.dialsModules.filter(data => data.programId === calculatorsUpgradeScenario?.program.id && data.code === 'R')).length > 0 ? true:false  

  const dTotalCount = defaultLicense?.license.count
  const dYCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'Y')?.count : 0
  const dKCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'K')?.count : 0 
  const dUCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'U')?.count : 0
  const dFCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'F')?.count : 0
  const dMCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'M')?.count : 0
  const dSCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'S')?.count : 0
  const dPCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'P')?.count : 0
  const dECount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'E')?.count : 0
  const dJCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'J')?.count : 0
  const dACount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'A')?.count : 0
  const dRCount = calculatorsUpgradeScenario?.program.code === 'D' ? defaultLicense?.license.modules?.find(data => data.module.code === 'R')?.count : 0
  
  const dYCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'Y')?.count! > 0 ? true:false : false
  const dKCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'K')?.count! > 0 ? true:false : false 
  const dUCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'U')?.count! > 0 ? true:false : false
  const dFCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'F')?.count! > 0 ? true:false : false
  const dMCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'M')?.count! > 0 ? true:false : false
  const dSCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'S')?.count! > 0 ? true:false : false
  const dPCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'P')?.count! > 0 ? true:false : false
  const dECheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'E')?.count! > 0 ? true:false : false
  const dJCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'J')?.count! > 0 ? true:false : false
  const dACheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'A')?.count! > 0 ? true:false : false
  const dRCheck = calculatorsUpgradeScenario?.program.code === 'S' ? defaultLicense?.license.modules?.find(data => data.module.code === 'R')?.count! > 0 ? true:false : false

  const aTotalCount = actualLicense?.license.count
  const aYCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'Y')?.count : 0 
  const aKCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'K')?.count : 0 
  const aUCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'U')?.count : 0
  const aFCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'F')?.count : 0
  const aMCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'M')?.count : 0
  const aSCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'S')?.count : 0
  const aPCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'P')?.count : 0
  const aECount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'E')?.count : 0
  const aJCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'J')?.count : 0
  const aACount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'A')?.count : 0
  const aRCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'R')?.count : 0

  const aYCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'Y')?.count! > 0 ? true:false : false 
  const aKCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'K')?.count! > 0 ? true:false : false 
  const aUCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'U')?.count! > 0 ? true:false : false
  const aFCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'F')?.count! > 0 ? true:false : false
  const aMCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'M')?.count! > 0 ? true:false : false
  const aSCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'S')?.count! > 0 ? true:false : false
  const aPCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'P')?.count! > 0 ? true:false : false
  const aECheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'E')?.count! > 0 ? true:false : false
  const aJCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'J')?.count! > 0 ? true:false : false
  const aACheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'A')?.count! > 0 ? true:false : false
  const aRCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'R')?.count! > 0 ? true:false : false

  const tTotalCount = actualLicense?.license.count
  const tYCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'Y')?.count : 0
  const tKCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'K')?.count : 0
  const tUCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'U')?.count : 0
  const tFCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'F')?.count : 0
  const tMCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'M')?.count : 0
  const tSCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'S')?.count : 0
  const tPCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'P')?.count : 0
  const tECount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'E')?.count : 0
  const tJCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'J')?.count : 0
  const tACount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'A')?.count : 0
  const tRCount = calculatorsUpgradeScenario?.program.code === 'D' ? actualLicense?.license.modules?.find(data => data.module.code === 'R')?.count : 0

  const tYCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'Y')?.count! > 0 ? true:false : false
  const tKCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'K')?.count! > 0 ? true:false : false
  const tUCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'U')?.count! > 0 ? true:false : false
  const tFCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'F')?.count! > 0 ? true:false : false
  const tMCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'M')?.count! > 0 ? true:false : false
  const tSCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'S')?.count! > 0 ? true:false : false
  const tPCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'P')?.count! > 0 ? true:false : false
  const tECheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'E')?.count! > 0 ? true:false : false
  const tJCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'J')?.count! > 0 ? true:false : false
  const tACheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'A')?.count! > 0 ? true:false : false
  const tRCheck = calculatorsUpgradeScenario?.program.code === 'S' ? actualLicense?.license.modules?.find(data => data.module.code === 'R')?.count! > 0 ? true:false : false


  //let tTLicense:boolean|null = null
  //let tTUCount:number|null = 9999

  

  const handleMyReset = () => {
    setLoaded(false)

    setTotalPrice(0)
    setShowTP(false)

    setAALicenseSet(false)
    setAALicenseType('X')
    setAAVersion('X')

    setAATotalCount(9999)
    setAAYCount(9999)
    setAAKCount(9999)
    setAAUCount(9999)
    setAAFCount(9999)
    setAAMCount(9999)
    setAASCount(9999)
    setAAPCount(9999)
    setAAECount(9999)
    setAAJCount(9999)
    setAAACount(9999)
    setAARCount(9999)

    setAAYCheck(false)
    setAAKCheck(false)
    setAAUCheck(false)
    setAAFCheck(false)
    setAAMCheck(false)
    setAASCheck(false)
    setAAPCheck(false)
    setAAECheck(false)
    setAAJCheck(false)
    setAAACheck(false)
    setAARCheck(false)

    setTTLicenseSet(false)
    setTTLicense(false)
    setTTLicenseType('X')

    setTTTotalCount(9999)
    setTTYCount(9999)
    setTTKCount(9999)
    setTTUCount(9999)
    setTTFCount(9999)
    setTTMCount(9999)
    setTTSCount(9999)
    setTTPCount(9999)
    setTTECount(9999)
    setTTJCount(9999)
    setTTACount(9999)
    setTTRCount(9999)

    setTTYCheck(false)
    setTTKCheck(false)
    setTTUCheck(false)
    setTTFCheck(false)
    setTTMCheck(false)
    setTTSCheck(false)
    setTTPCheck(false)
    setTTECheck(false)
    setTTJCheck(false)
    setTTACheck(false)
    setTTRCheck(false)

    setDDLicenseSet(false)
    setDDLicense(false)
    setDDLicenseType('X')
    setDDVersion('X')

    setDDTotalCount(9999)
    setDDYCount(9999)
    setDDKCount(9999)
    setDDUCount(9999)
    setDDFCount(9999)
    setDDMCount(9999)
    setDDSCount(9999)
    setDDPCount(9999)
    setDDECount(9999)
    setDDJCount(9999)
    setDDACount(9999)
    setDDRCount(9999)

    setDDYCheck(false)
    setDDKCheck(false)
    setDDUCheck(false)
    setDDFCheck(false)
    setDDMCheck(false)
    setDDSCheck(false)
    setDDPCheck(false)
    setDDECheck(false)
    setDDJCheck(false)
    setDDACheck(false)
    setDDRCheck(false)

    setLoaded(true)
    //alert(values.tLicense)
  }
  
  const handleDefault = (values:any) => {
    setLoaded(false)

    setAALicenseSet(true)
    setAALicenseType(values.aLicenseType)
    setAAVersion(values.aVersion)
    setAATotalCount(values.aTotalCount)
    setAAYCount(values.aYCount)
    setAAKCount(values.aKCount)
    setAAUCount(values.aUCount)
    setAAFCount(values.aFCount)
    setAAMCount(values.aMCount)
    setAASCount(values.aSCount)
    setAAPCount(values.aPCount)
    setAAECount(values.aECount)
    setAAJCount(values.aJCount)
    setAAACount(values.aACount)
    setAARCount(values.aRCount)

    setAAYCheck(values.aYCheck)
    setAAKCheck(values.aKCheck)
    setAAUCheck(values.aUCheck)
    setAAFCheck(values.aFCheck)
    setAAMCheck(values.aMCheck)
    setAASCheck(values.aSCheck)
    setAAPCheck(values.aPCheck)
    setAAECheck(values.aECheck)
    setAAJCheck(values.aJCheck)
    setAAACheck(values.aACheck)
    setAARCheck(values.aRCheck)

    setTTLicenseType(values.tLicenseType)
    setTTTotalCount(values.tTotalCount)
    setTTYCount(values.tYCount)
    setTTKCount(values.tKCount)
    setTTUCount(values.tUCount)
    setTTFCount(values.tFCount)
    setTTMCount(values.tMCount)
    setTTSCount(values.tSCount)
    setTTPCount(values.tPCount)
    setTTECount(values.tECount)
    setTTJCount(values.tJCount)
    setTTACount(values.tACount)
    setTTRCount(values.tRCount)

    setTTYCheck(values.tYCheck)
    setTTKCheck(values.tKCheck)
    setTTUCheck(values.tUCheck)
    setTTFCheck(values.tFCheck)
    setTTMCheck(values.tMCheck)
    setTTSCheck(values.tSCheck)
    setTTPCheck(values.tPCheck)
    setTTECheck(values.tECheck)
    setTTJCheck(values.tJCheck)
    setTTACheck(values.tACheck)
    setTTRCheck(values.tRCheck)

    setDDLicenseSet(true)
    setDDLicense(values.dLicense === false ? true : false)
    setDDLicenseType(values.dLicense === false ? values.aLicenseType : 'X')
    setDDVersion(values.dLicense === false ? values.aVersion : 'X')

    setDDTotalCount(values.dLicense === false ? values.aTotalCount : 9999)
    setDDYCount(values.dLicense === false ? values.aYCount : 9999)
    setDDKCount(values.dLicense === false ? values.aKCount : 9999)
    setDDUCount(values.dLicense === false ? values.aUCount : 9999)
    setDDFCount(values.dLicense === false ? values.aFCount : 9999)
    setDDMCount(values.dLicense === false ? values.aMCount : 9999)
    setDDSCount(values.dLicense === false ? values.aSCount : 9999)
    setDDPCount(values.dLicense === false ? values.aPCount : 9999)
    setDDECount(values.dLicense === false ? values.aECount : 9999)
    setDDJCount(values.dLicense === false ? values.aJCount : 9999)
    setDDACount(values.dLicense === false ? values.aACount : 9999)
    setDDRCount(values.dLicense === false ? values.aRCount : 9999)

    setDDYCheck(values.dLicense === false ? values.aYCheck : false)
    setDDKCheck(values.dLicense === false ? values.aKCheck : false)
    setDDUCheck(values.dLicense === false ? values.aUCheck : false)
    setDDFCheck(values.dLicense === false ? values.aFCheck : false)
    setDDMCheck(values.dLicense === false ? values.aMCheck : false)
    setDDSCheck(values.dLicense === false ? values.aSCheck : false)
    setDDPCheck(values.dLicense === false ? values.aPCheck : false)
    setDDECheck(values.dLicense === false ? values.aECheck : false)
    setDDJCheck(values.dLicense === false ? values.aJCheck : false)
    setDDACheck(values.dLicense === false ? values.aACheck : false)
    setDDRCheck(values.dLicense === false ? values.aRCheck : false)

    setLoaded(true)
    //alert(values.tLicense)
  }

  const handleTarget = (values:any) => {
    setLoaded(false)

    setAALicenseSet(true)
    setAALicenseType(values.aLicenseType)
    setAAVersion(values.aVersion)
    setAATotalCount(values.aTotalCount)
    setAAYCount(values.aYCount)
    setAAKCount(values.aKCount)
    setAAUCount(values.aUCount)
    setAAFCount(values.aFCount)
    setAAMCount(values.aMCount)
    setAASCount(values.aSCount)
    setAAPCount(values.aPCount)
    setAAECount(values.aECount)
    setAAJCount(values.aJCount)
    setAAACount(values.aACount)
    setAARCount(values.aRCount)

    setAAYCheck(values.aYCheck)
    setAAKCheck(values.aKCheck)
    setAAUCheck(values.aUCheck)
    setAAFCheck(values.aFCheck)
    setAAMCheck(values.aMCheck)
    setAASCheck(values.aSCheck)
    setAAPCheck(values.aPCheck)
    setAAECheck(values.aECheck)
    setAAJCheck(values.aJCheck)
    setAAACheck(values.aACheck)
    setAARCheck(values.aRCheck)

    setDDLicenseType(values.dLicenseType)
    setDDVersion(values.dVersion)
    setDDTotalCount(values.dTotalCount)
    setDDYCount(values.dYCount)
    setDDKCount(values.dKCount)
    setDDUCount(values.dUCount)
    setDDFCount(values.dFCount)
    setDDMCount(values.dMCount)
    setDDSCount(values.dSCount)
    setDDPCount(values.dPCount)
    setDDECount(values.dECount)
    setDDJCount(values.dJCount)
    setDDACount(values.dACount)
    setDDRCount(values.dRCount)

    setDDYCheck(values.dYCheck)
    setDDKCheck(values.dKCheck)
    setDDUCheck(values.dUCheck)
    setDDFCheck(values.dFCheck)
    setDDMCheck(values.dMCheck)
    setDDSCheck(values.dSCheck)
    setDDPCheck(values.dPCheck)
    setDDECheck(values.dECheck)
    setDDJCheck(values.dJCheck)
    setDDACheck(values.dACheck)
    setDDRCheck(values.dRCheck)

    setTTLicenseSet(true)
    setTTLicense(values.tLicense === false ? true : false)
    setTTLicenseType(values.tLicense === false ? values.aLicenseType : 'X')

    setTTTotalCount(values.tLicense === false ? values.aTotalCount : 9999)
    setTTYCount(values.tLicense === false ? values.aYCount : 9999)
    setTTKCount(values.tLicense === false ? values.aKCount : 9999)
    setTTUCount(values.tLicense === false ? values.aUCount : 9999)
    setTTFCount(values.tLicense === false ? values.aFCount : 9999)
    setTTMCount(values.tLicense === false ? values.aMCount : 9999)
    setTTSCount(values.tLicense === false ? values.aSCount : 9999)
    setTTPCount(values.tLicense === false ? values.aPCount : 9999)
    setTTECount(values.tLicense === false ? values.aECount : 9999)
    setTTJCount(values.tLicense === false ? values.aJCount : 9999)
    setTTACount(values.tLicense === false ? values.aACount : 9999)
    setTTRCount(values.tLicense === false ? values.aRCount : 9999)

    setTTYCheck(values.tLicense === false ? values.aYCheck : false)
    setTTKCheck(values.tLicense === false ? values.aKCheck : false)
    setTTUCheck(values.tLicense === false ? values.aUCheck : false)
    setTTFCheck(values.tLicense === false ? values.aFCheck : false)
    setTTMCheck(values.tLicense === false ? values.aMCheck : false)
    setTTSCheck(values.tLicense === false ? values.aSCheck : false)
    setTTPCheck(values.tLicense === false ? values.aPCheck : false)
    setTTECheck(values.tLicense === false ? values.aECheck : false)
    setTTJCheck(values.tLicense === false ? values.aJCheck : false)
    setTTACheck(values.tLicense === false ? values.aACheck : false)
    setTTRCheck(values.tLicense === false ? values.aRCheck : false)

    setLoaded(true)
    //alert(values.tLicense)
  }


 

  // Filtrování zákazníků v seznamu
  const filterCustomers = (value:string) => {
    //setCustomers(rootStore.getCustomerListAllByFilter(value))

    if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

      (async () => {
        setCustomerDeepLoading(true)
        await rootStore.fetchCustomerListAllNoCache(value,'',1)
        //setCustomers(rootStore.getCustomerListAllByFilter(value))
        console.log(localStorage.getItem('customerQuickSearchValue'))
        setCustomers(rootStore.getCustomerListAllByFilter(localStorage.getItem('customerQuickSearchValue')!))
        setCustomerDeepLoading(false)
      })();
    }
    else {
      setCustomers(rootStore.getCustomerListAllByFilter(value))
    }
    //setCustomers(rootStore.getCustomerListAllByFilter(value))  
  }

  

  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 



// O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomer =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (customers[activeItem]?.accountNumber !== undefined) {
        setFieldValue('licenseNumber',customers[activeItem].accountNumber)
        handleCustomer(customers[activeItem].accountNumber!)

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('licenseNumber','')
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customers && activeItem === customers.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  

};



  // Schéma validace formuláře
  const schemaCalculator = Yup.object().shape({
    programCode: Yup.string(),

    dLicense: Yup.boolean(),
    dLicenseType: Yup.string().when('dLicense', {
      is: true,
      then: Yup.string().required('Typ licence musí být vyplněn!')
    }),
    dVersion: Yup.string().when('dLicense', {
      is: true,
      then: Yup.string().required('Verze musí být vyplněna!')
    }),

    tLicense: Yup.boolean(),
    tLicenseType: Yup.string().when('tLicense', {
      is: true,
      then: Yup.string().required('Typ licence musí být vyplněn!')
    }),

    
    aLicenseType: Yup.string()
      .required('Typ licence musí být vyplněn!'),
    aVersion: Yup.string()
      .required('Verze musí být vyplněna!'),  
    

    aTotalCount: Yup.number().when('programCode', {
      is: 'S',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .when('aLicenseType', {
        is: licenseTypesIdB?.id,
        then: Yup.number()
        .max(1, 'Maximální počet PC je 1!'),
      })
      .when('aLicenseType', {
        is: licenseTypesIdA?.id,
        then: Yup.number()
        .max(1, 'Maximální počet PC je 1!'),
      }),
    }),
    aYCount: Yup.number().when('aLicenseType', {
      is: licenseTypesIdB?.id,
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(1, 'Maximální počet PC je 1!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněnn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aKCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(Yup.ref('aYCount'), 'Počt PC modulu musí být stejný jako počet jader!')
      .max(Yup.ref('aYCount'), 'Počt PC modulu musí být stejný jako počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aUCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aFCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aMCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aSCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aPCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('aSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aECount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('aSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aJCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aACount: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .when('aUCount', {
        is: 0,
        then: Yup.number()
          .min(0, 'Musí být zadán alespoň 1 modul Účetnictví!')
          .max(0, 'Musí být zadán alespoň 1 modul Účetnictví!'),
        otherwise: Yup.number().when('programCode', {
          is: 'D',
          then: Yup.number()  
            .min(0, 'Minimální počet PC je 0!')
            .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
          otherwise: Yup.number()
            .min(0, 'Minimální počet PC je 0!'),
        }),
    }),
    aRCount: Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('aYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    aACheck: Yup.boolean()
      .when('aUCheck', {
        is: false,
        then: Yup.boolean()
          .oneOf([false], 'Musí být zadán modul Účetnictví!'),
    }),
    

    dTotalCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'S',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .when('dLicenseType', {
        is: licenseTypesIdB?.id,
        then: Yup.number()
        .max(1, 'Maximální počet PC je 1!'),
      })
      .when('dLicenseType', {
        is: licenseTypesIdA?.id,
        then: Yup.number()
        .max(1, 'Maximální počet PC je 1!'),
      }),
    }), 
    }),
    dYCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('dLicenseType', {
      is: licenseTypesIdB?.id,
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(1, 'Maximální počet PC je 1!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněnn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dKCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(Yup.ref('dYCount'), 'Počt PC modulu musí být stejný jako počet jader!')
      .max(Yup.ref('dYCount'), 'Počt PC modulu musí být stejný jako počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dUCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dFCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dMCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dSCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dPCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('dSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dECount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('dSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dJCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dACount: Yup.number().when('dLicense', { is: true, then : Yup.number()
      .required('Počet PC musí být vyplněn!')
      .when('dUCount', {
        is: 0,
        then: Yup.number()
          .min(0, 'Musí být zadán alespoň 1 modul Účetnictví!')
          .max(0, 'Musí být zadán alespoň 1 modul Účetnictví!'),
        otherwise: Yup.number().when('programCode', {
          is: 'D',
          then: Yup.number()  
            .min(0, 'Minimální počet PC je 0!')
            .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
          otherwise: Yup.number()
            .min(0, 'Minimální počet PC je 0!'),
        }),
    }),
    }),
    dRCount: Yup.number().when('dLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('dYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    dACheck: Yup.boolean().when('dLicense', { is: true, then : Yup.boolean()
    .when('dUCheck', {
      is: false,
      then: Yup.boolean()
        .oneOf([false], 'Musí být zadán modul Účetnictví!'),
    }),
    }),

    tTotalCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'S',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .when('tLicenseType', {
        is: licenseTypesIdB?.id,
        then: Yup.number()
        .max(1, 'Maximální počet PC je 1!'),
      })
      .when('tLicenseType', {
        is: licenseTypesIdA?.id,
        then: Yup.number()
        .max(1, 'Maximální počet PC je 1!'),
      }),
    }),
    }),
    tYCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('tLicenseType', {
      is: licenseTypesIdB?.id,
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(1, 'Maximální počet PC je 1!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněnn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tKCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(Yup.ref('tYCount'), 'Počt PC modulu musí být stejný jako počet jader!')
      .max(Yup.ref('tYCount'), 'Počt PC modulu musí být stejný jako počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tUCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader!'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tFCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tMCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tSCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tPCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('tSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tECount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number().when('tSCount', {
        is: 0,
        then: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Musí být zadán alespoň 1 modul Sklady!')
        .max(0, 'Musí být zadán alespoň 1 modul Sklady!'),
        otherwise: Yup.number()
        .required('Počet PC musí být vyplněn!')
        .min(0, 'Minimální počet PC je 0!')
        .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      }),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tJCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tACount: Yup.number().when('tLicense', { is: true, then : Yup.number()
      .required('Počet PC musí být vyplněn!')
      .when('tUCount', {
        is: 0,
        then: Yup.number()
          .min(0, 'Musí být zadán alespoň 1 modul Účetnictví!')
          .max(0, 'Musí být zadán alespoň 1 modul Účetnictví!'),
        otherwise: Yup.number().when('programCode', {
          is: 'D',
          then: Yup.number()  
            .min(0, 'Minimální počet PC je 0!')
            .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
          otherwise: Yup.number()
            .min(0, 'Minimální počet PC je 0!'),
        }),
    }),
    }),
    tRCount: Yup.number().when('tLicense', { is: true, then : Yup.number().when('programCode', {
      is: 'D',
      then: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!')
      .max(Yup.ref('tYCount'), 'Počt PC modulu nesmí přesáhnout počet jader.'),
      otherwise: Yup.number()
      .required('Počet PC musí být vyplněn!')
      .min(0, 'Minimální počet PC je 0!'),
    }),
    }),
    tACheck: Yup.boolean().when('tLicense', { is: true, then : Yup.boolean()
    .when('tUCheck', {
      is: false,
      then: Yup.boolean()
        .oneOf([false], 'Musí být zadán modul Účetnictví!'),
    }),
    })
     
    
     
  }) 
  const schemaScenario = Yup.object().shape({
    scenario: Yup.string()
      .required('Scénář musí být vyplněn!')
  })  

  const schemaCustomer = Yup.object().shape({
    licenseNumber: Yup.string()
      .min(5, 'Číslo zákazníka musí obsahovat 5 znaků!')
      .max(5, 'Číslo zákazníka musí obsahovat 5 znaků!')
  })   
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <Container fluid>
                <br />
                <h4>Kalkulačka upgrade</h4>
                <br />
                <CardDeck>
                <Card>
                  <Card.Header>Scénář upgrade</Card.Header>
                  <Card.Body>
                  <Formik
                    validationSchema={schemaScenario}
                    initialValues={{ scenario: calculatorsUpgradeScenario?.id !== undefined ? calculatorsUpgradeScenario.id : '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleMyReset()
                        handleScenario(values.scenario)              
                        setSubmitting(false);
                      }, 1);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting }) => (
                      <Form inline onSubmit={handleSubmit}>
                        <Form.Label className="my-1 mr-2" htmlFor="oldUser">
                          Scénář
                        </Form.Label>
                        <Form.Control as="select" 
                          name="scenario" 
                          onChange={async (e:any) => {await handleChange(e);await handleSubmit(e)}}
                          value={values.scenario!}
                          isInvalid={!!errors.scenario}
                          style={{width: '230px'}}
                          >
                            <option value="">--- Vyberte scénář ---</option>
                            {
                            scenarios
                            }
                        </Form.Control>
                        &nbsp;
                        <Button className='nodisplay' type="submit" variant="primary" disabled={loading}>{loading ? 'Načítám...' : 'Načíst'}</Button> 
                        <Form.Control.Feedback type="invalid">
                          {errors.scenario}
                        </Form.Control.Feedback>

                        {error}
                        <br />
                        
                        
                      </Form>
                    )}
                  </Formik>
                  <div className='nodisplay'>
                  <br />
                  <strong>
                  {calculatorsUpgradeScenario?.program.name}&nbsp;{calculatorsUpgradeScenario?.programVersion.version}
                  </strong>
                  </div>
                  </Card.Body>
                </Card>
                <br />
                {calculatorsUpgradeScenario?.id !== undefined &&
                <Card>
                  <Card.Header>Přednastavení licence</Card.Header>
                  <Card.Body>
                  <Formik
                    validationSchema={schemaCustomer}
                    initialValues={{ licenseNumber: calculatorsUpgradeCustomer?.accountNumber !== null ? calculatorsUpgradeCustomer?.accountNumber !== undefined ? calculatorsUpgradeCustomer?.accountNumber : '' : ''}}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleMyReset()
                        handleCustomer(values.licenseNumber!)              
                        setSubmitting(false);
                      }, 1);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup controlId="licenseNumber">
                          <Form.Row>
                        <Form.Label  column lg={2}>
                          Zákazník
                        </Form.Label>
                        <Col lg={4}>

                        <FormControl 
                          type="text"
                          name="licenseNumber"
                          
                          value={values.licenseNumber!}
                          onChange={(e) => {handleChange(e);  state.displayItems = true;  state.activeItem = 0; localStorage.setItem('customerQuickSearchValue', e.target.value);   
                            //setCustomers(customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(e.target.value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(e.target.value!.toLowerCase())))
                             filterCustomers(e.target.value) 
                            }}
                          //tabIndex={1}
                          autoComplete="off" 
                          autoFocus={true}
                          isValid={touched.licenseNumber && !errors.licenseNumber} 
                          isInvalid={!!errors.licenseNumber}
                          onKeyDown={(e:any) => handleKeyDownCustomer(e, setFieldValue, 1)}
                          onBlur={(e:any) => {setTimeout(() => {setState({activeItem: 0, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue}); },200 ) }}
                        />
                        {values.licenseNumber! !== '' && state.displayItems ?
                            <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customers.map((dp,index) => {
                                        return (
                                            <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={async (e:any) => {await setFieldValue("licenseNumber",dp.accountNumber); state.displayItems = false; handleCustomer(dp.accountNumber!)}}
                                                key = {dp.id}
                                                >
                                                {dp.accountNumber} - {dp.businessName}
                                            </li>
                                        )
                                    }).slice(0, 50)
                                }
                                </ul>
                            </div>
                            : null
                        }

                          <Form.Control.Feedback type="invalid">
                          {errors.licenseNumber}
                        </Form.Control.Feedback>
                          
                          </Col>
                          <Col>
                       
                        <Button type="submit" variant="primary" disabled={loading}>{loading ? 'Načítám...' : 'Načíst'}</Button> 
                        
                            </Col>
                        </Form.Row>
                        </FormGroup>

                        {error}
                        
                        
                        
                      </Form>
                    )}
                  </Formik>
                  <strong>
                  {calculatorsUpgradeCustomer?.accountNumber !== undefined ? '#'+calculatorsUpgradeCustomer?.accountNumber+', ' : ''}
                  {calculatorsUpgradeCustomer?.businessName}
                  </strong>
                  </Card.Body>
                </Card>}
                </CardDeck>
                
                {calculatorsUpgradeScenario?.id !== undefined &&
                <Formik
                    validationSchema={schemaCalculator}
                    initialValues={{ 
                      programCode: calculatorsUpgradeScenario?.program.code,

                      dLicense: dDLicenseSet === true ? dDLicense !== false ? true : false : dLicense !== undefined ? dLicense:false,
                      tLicense: tTLicenseSet === true ? tTLicense !== false ? true : false : tLicense !== undefined ? tLicense:false,

                      dLicenseType: dDLicenseType !== 'X' ? dDLicenseType : dLicenseType !== undefined ? dLicenseType:'', 
                      dVersion: dDVersion !== 'X' ? dDVersion : dProgramVersion !== undefined ? dProgramVersion:'', 
                      
                      aLicenseType: aALicenseType !== 'X' ? aALicenseType : aLicenseType !== undefined ? aLicenseType:'',
                      aVersion: aAVersion !== 'X' ? aAVersion : aProgramVersion !== undefined ? aProgramVersion:'',  
                      
                      
                      tLicenseType: tTLicenseType !== 'X' ? tTLicenseType : tLicenseType !== undefined ? tLicenseType:'', 

                      dTotalCount: dDTotalCount !== 9999 ? dDTotalCount : dTotalCount !== undefined ? dTotalCount:0,
                      dYCount: dDYCount !== 9999 ? dDYCount : dYCount !== undefined ? dYCount:0,
                      dKCount: dDKCount !== 9999 ? dDKCount : dKCount !== undefined ? dKCount:0, 
                      dUCount: dDUCount !== 9999 ? dDUCount : dUCount !== undefined ? dUCount:0,
                      dFCount: dDFCount !== 9999 ? dDFCount : dFCount !== undefined ? dFCount:0,
                      dMCount: dDMCount !== 9999 ? dDMCount : dMCount !== undefined ? dMCount:0,
                      dSCount: dDSCount !== 9999 ? dDSCount : dSCount !== undefined ? dSCount:0,
                      dPCount: dDPCount !== 9999 ? dDPCount : dPCount !== undefined ? dPCount:0,
                      dECount: dDECount !== 9999 ? dDECount : dECount !== undefined ? dECount:0,
                      dJCount: dDJCount !== 9999 ? dDJCount : dJCount !== undefined ? dJCount:0,
                      dACount: dDACount !== 9999 ? dDACount : dACount !== undefined ? dACount:0,
                      dRCount: dDRCount !== 9999 ? dDRCount : dRCount !== undefined ? dRCount:0,
                      
                      dYCheck: dDLicenseSet === true ? dDYCheck : dYCheck,
                      dKCheck: dDLicenseSet === true ? dDKCheck : dKCheck,
                      dUCheck: dDLicenseSet === true ? dDUCheck : dUCheck,
                      dFCheck: dDLicenseSet === true ? dDFCheck : dFCheck,
                      dMCheck: dDLicenseSet === true ? dDMCheck : dMCheck,
                      dSCheck: dDLicenseSet === true ? dDSCheck : dSCheck,
                      dPCheck: dDLicenseSet === true ? dDPCheck : dPCheck,
                      dECheck: dDLicenseSet === true ? dDECheck : dECheck,
                      dJCheck: dDLicenseSet === true ? dDJCheck : dJCheck,
                      dACheck: dDLicenseSet === true ? dDACheck : dACheck,
                      dRCheck: dDLicenseSet === true ? dDRCheck : dRCheck,

                      aTotalCount: aATotalCount !== 9999 ? aATotalCount : aTotalCount !== undefined ? aTotalCount:0,
                      aYCount: aAYCount !== 9999 ? aAYCount : aYCount !== undefined ? aYCount:0,
                      aKCount: aAKCount !== 9999 ? aAKCount : aKCount !== undefined ? aKCount:0,
                      aUCount: aAUCount !== 9999 ? aAUCount : aUCount !== undefined ? aUCount:0, 
                      aFCount: aAFCount !== 9999 ? aAFCount : aFCount !== undefined ? aFCount:0,
                      aMCount: aAMCount !== 9999 ? aAMCount : aMCount !== undefined ? aMCount:0,
                      aSCount: aASCount !== 9999 ? aASCount : aSCount !== undefined ? aSCount:0,
                      aPCount: aAPCount !== 9999 ? aAPCount : aPCount !== undefined ? aPCount:0,
                      aECount: aAECount !== 9999 ? aAECount : aECount !== undefined ? aECount:0,
                      aJCount: aAJCount !== 9999 ? aAJCount : aJCount !== undefined ? aJCount:0,
                      aACount: aAACount !== 9999 ? aAACount : aACount !== undefined ? aACount:0,
                      aRCount: aARCount !== 9999 ? aARCount : aRCount !== undefined ? aRCount:0,

                      aYCheck: aALicenseSet === true ? aAYCheck : aYCheck,
                      aKCheck: aALicenseSet === true ? aAKCheck : aKCheck,
                      aUCheck: aALicenseSet === true ? aAUCheck : aUCheck,
                      aFCheck: aALicenseSet === true ? aAFCheck : aFCheck,
                      aMCheck: aALicenseSet === true ? aAMCheck : aMCheck,
                      aSCheck: aALicenseSet === true ? aASCheck : aSCheck,
                      aPCheck: aALicenseSet === true ? aAPCheck : aPCheck,
                      aECheck: aALicenseSet === true ? aAECheck : aECheck,
                      aJCheck: aALicenseSet === true ? aAJCheck : aJCheck,
                      aACheck: aALicenseSet === true ? aAACheck : aACheck,
                      aRCheck: aALicenseSet === true ? aARCheck : aRCheck,

                      tTotalCount: tTTotalCount !== 9999 ? tTTotalCount : tTotalCount !== undefined ? tTotalCount:0,
                      tYCount: tTYCount !== 9999 ? tTYCount : tYCount !== undefined ? tYCount:0, 
                      tKCount: tTKCount !== 9999 ? tTKCount : tKCount !== undefined ? tKCount:0, 
                      tUCount: tTUCount !== 9999 ? tTUCount : tUCount !== undefined ? tUCount:0, 
                      tFCount: tTFCount !== 9999 ? tTFCount : tFCount !== undefined ? tFCount:0,
                      tMCount: tTMCount !== 9999 ? tTMCount : tMCount !== undefined ? tMCount:0,
                      tSCount: tTSCount !== 9999 ? tTSCount : tSCount !== undefined ? tSCount:0,
                      tPCount: tTPCount !== 9999 ? tTPCount : tPCount !== undefined ? tPCount:0,
                      tECount: tTECount !== 9999 ? tTECount : tECount !== undefined ? tECount:0,
                      tJCount: tTJCount !== 9999 ? tTJCount : tJCount !== undefined ? tJCount:0,
                      tACount: tTACount !== 9999 ? tTACount : tACount !== undefined ? tACount:0,
                      tRCount: tTRCount !== 9999 ? tTRCount : tRCount !== undefined ? tRCount:0,
                      
                      tYCheck: tTLicenseSet === true ? tTYCheck : tYCheck,
                      tKCheck: tTLicenseSet === true ? tTKCheck : tKCheck,
                      tUCheck: tTLicenseSet === true ? tTUCheck : tUCheck,
                      tFCheck: tTLicenseSet === true ? tTFCheck : tFCheck,
                      tMCheck: tTLicenseSet === true ? tTMCheck : tMCheck,
                      tSCheck: tTLicenseSet === true ? tTSCheck : tSCheck,
                      tPCheck: tTLicenseSet === true ? tTPCheck : tPCheck,
                      tECheck: tTLicenseSet === true ? tTECheck : tECheck,
                      tJCheck: tTLicenseSet === true ? tTJCheck : tJCheck,
                      tACheck: tTLicenseSet === true ? tTACheck : tACheck,
                      tRCheck: tTLicenseSet === true ? tTRCheck : tRCheck,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        handleCalculate(values,false)              
                        setSubmitting(false);
                      }, 1);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                      handleReset,
                      handleBlur,
                      
                      values,
                      touched,
                      isValid,
                      errors,
                      dirty,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                                                 
                        <FormControl 
                          type="hidden"
                          name="programCode"
                          value={values.programCode!}
                          onChange={(e:any) => {handleClose();handleChange(e)}}
                        />


                        <CardDeck>
                          <Card style={{minWidth: '230px', marginTop: '25px'}}>
                            <Card.Header>
                              <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Výchozí licence"
                                name="dLicense"
                                onChange={async (e:any) => {await handleClose2(e);await handleChange(e);}}
                                onClick={async () => {await handleDefault(values);await handleReset();}}
                                checked={values.dLicense}
                              />
                            </Card.Header>
                            <Card.Body>
                            
                              <FormGroup as={Col} controlId="name">
                                <Form.Row>
                                  <FormLabel column lg={3}>Verze</FormLabel>
                                  <Col>
                                    <Form.Control as="select" 
                                      name="dVersion" 
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      value={values.dVersion!}
                                      isInvalid={!!errors.dVersion}
                                      disabled={!values.dLicense}
                                      >
                                        <option value="">--- Vyberte verzi ---</option>
                                        {
                                        programVersions
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dVersion}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="name">
                                <Form.Row>
                                  <FormLabel column lg={3}>Typ</FormLabel>
                                  <Col>
                                    <Form.Control as="select" 
                                      name="dLicenseType" 
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      value={values.dLicenseType!}
                                      isInvalid={!!errors.dLicenseType}
                                      disabled={!values.dLicense}
                                      >
                                        <option value="">--- Vyberte typ licence ---</option>
                                        {
                                        licenseTypes
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dLicenseType}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>
                              </FormGroup>
                              <table>
                                <tbody>
                                <tr>
                                  <td style={{minWidth: '100px'}}><strong>Modul</strong></td>
                                  <td><strong>Počet&nbsp;PC</strong></td>
                                </tr>
                                <tr className={values.programCode === 'D' ? 'nodisplay':''}>
                                  <td>Celkem PC</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:999}
                                      min={0}
                                      name="dTotalCount"
                                      value={values.dTotalCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      //className={values.programCode === 'D' ? 'nodisplay':''}
                                      //isValid={touched.dYCount && !errors.dYCount} 
                                      isInvalid={!!errors.dTotalCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dTotalCount}
                                    </Form.Control.Feedback>
                                    
                                  </td>
                                </tr>
                                <tr className={visibleY !== true ? 'nodisplay':''}>
                                  <td>Jádro</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:999}
                                      min={0}
                                      name="dYCount"
                                      value={values.dYCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dYCount && !errors.dYCount} 
                                      isInvalid={!!errors.dYCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dYCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dY"
                                      label=""
                                      name="dYCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dYCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleK !== true ? 'nodisplay':''}>
                                  <td>Kancelář</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999}
                                      min={0}
                                      name="dKCount"
                                      value={values.dKCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dKCount && !errors.dKCount} 
                                      isInvalid={!!errors.dKCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dKCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dK"
                                      label=""
                                      name="dKCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dKCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleU !== true ? 'nodisplay':''}>
                                  <td>Účetnictví</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999}
                                      min={0}
                                      name="dUCount"
                                      value={values.dUCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dUCount && !errors.dUCount} 
                                      isInvalid={!!errors.dUCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dUCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dU"
                                      label=""
                                      name="dUCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dUCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleF !== true ? 'nodisplay':''}>
                                  <td>Finance</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999}
                                      min={0}
                                      name="dFCount"
                                      value={values.dFCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dFCount && !errors.dFCount} 
                                      isInvalid={!!errors.dFCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dFCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dF"
                                      label=""
                                      name="dFCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dFCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleM !== true ? 'nodisplay':''}>
                                  <td>Mzdy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999}
                                      min={0}
                                      name="dMCount"
                                      value={values.dMCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dMCount && !errors.dMCount} 
                                      isInvalid={!!errors.dMCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dMCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dM"
                                      label=""
                                      name="dMCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dMCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleS !== true ? 'nodisplay':''}>
                                  <td>Sklady</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999}
                                      min={0}
                                      name="dSCount"
                                      value={values.dSCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dSCount && !errors.dSCount} 
                                      isInvalid={!!errors.dSCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dSCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dS"
                                      label=""
                                      name="dSCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dSCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleP !== true ? 'nodisplay':''}>
                                  <td>Maloobchod</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.dSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999 : 0}
                                      min={0}
                                      name="dPCount"
                                      value={values.dPCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dPCount && !errors.dPCount} 
                                      isInvalid={!!errors.dPCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dPCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dP"
                                      label=""
                                      name="dPCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dPCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleE !== true ? 'nodisplay':''}>
                                  <td>E-shopy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.dSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999 : 0}
                                      min={0}
                                      name="dECount"
                                      value={values.dECount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dECount && !errors.dECount} 
                                      isInvalid={!!errors.dECount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dECount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dE"
                                      label=""
                                      name="dECheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dECheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleJ !== true ? 'nodisplay':''}>
                                  <td>Majetek</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999}
                                      min={0}
                                      name="dJCount"
                                      value={values.dJCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dJCount && !errors.dJCount} 
                                      isInvalid={!!errors.dJCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dJCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dJ"
                                      label=""
                                      name="dJCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dJCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleA !== true ? 'nodisplay':''}>
                                  <td>Analýzy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.dUCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999 : 0}
                                      min={0}
                                      name="dACount"
                                      value={values.dACount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dACount && !errors.dACount} 
                                      isInvalid={!!errors.dACount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dACount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dA"
                                      label=""
                                      name="dACheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dACheck}
                                      disabled={!values.dLicense}
                                      isInvalid={!!errors.aACheck}
                                    />
                                    {errors.dACheck}
                                  </td>
                                </tr>
                                <tr className={visibleR !== true ? 'nodisplay':''}>
                                  <td style={{width: '160px'}}>Polotovary dokladů</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.dLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.dYCount!:999}
                                      min={0}
                                      name="dRCount"
                                      value={values.dRCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.dJCount && !errors.dJCount} 
                                      isInvalid={!!errors.dJCount}
                                      disabled={!values.dLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.dRCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-dR"
                                      label=""
                                      name="dRCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.dRCheck}
                                      disabled={!values.dLicense}
                                    />
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </Card.Body>
                          </Card>   
                          <Card style={{minWidth: '230px', marginTop: '25px'}}>
                            <Card.Header>Aktuální licence</Card.Header>
                            <Card.Body>
                              <FormGroup as={Col} controlId="name">
                                <Form.Row>
                                  <FormLabel column lg={3}>Verze</FormLabel>
                                  <Col>
                                    <Form.Control as="select" 
                                      name="aVersion" 
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      value={values.aVersion!}
                                      isInvalid={!!errors.aVersion}
                                      >
                                        <option value="">--- Vyberte verzi ---</option>
                                        {
                                        programVersions
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aVersion}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="name">
                                <Form.Row>
                                  <FormLabel column lg={3}>Typ</FormLabel>
                                  <Col>
                                    <Form.Control as="select" 
                                      name="aLicenseType" 
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      value={values.aLicenseType!}
                                      isInvalid={!!errors.aLicenseType}
                                      >
                                        <option value="">--- Vyberte typ licence ---</option>
                                        {
                                        licenseTypes
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aLicenseType}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>
                              </FormGroup>
                              <table>
                                <tbody>
                                <tr>
                                  <td style={{minWidth: '100px'}}><strong>Modul</strong></td>
                                  <td><strong>Počet&nbsp;PC</strong></td>
                                </tr>
                                <tr className={values.programCode === 'D' ? 'nodisplay':''}>
                                  <td>Celkem PC</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:999}
                                      min={0}
                                      name="aTotalCount"
                                      value={values.aTotalCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      //className={values.programCode === 'D' ? 'nodisplay':''}
                                      //isValid={touched.dYCount && !errors.dYCount} 
                                      isInvalid={!!errors.aTotalCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aTotalCount}
                                    </Form.Control.Feedback>
                                    
                                  </td>
                                </tr>
                                <tr className={visibleY !== true ? 'nodisplay':''}>
                                  <td>Jádro</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:999}
                                      min={0}
                                      name="aYCount"
                                      value={values.aYCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.aYCount && !errors.aYCount} 
                                      isInvalid={!!errors.aYCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aYCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aY"
                                      label=""
                                      name="aYCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aYCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleK !== true ? 'nodisplay':''}>
                                  <td>Kancelář</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999}
                                      min={0}
                                      name="aKCount"
                                      value={values.aKCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.aKCount && !errors.aKCount} 
                                      isInvalid={!!errors.aKCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aKCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aK"
                                      label=""
                                      name="aKCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aKCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleU !== true ? 'nodisplay':''}>
                                  <td>Účetnictví</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999}
                                      min={0}
                                      name="aUCount"
                                      value={values.aUCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      
                                      //isValid={touched.aUCount && !errors.aUCount} 
                                      isInvalid={!!errors.aUCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aUCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aU"
                                      label=""
                                      name="aUCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aUCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleF !== true ? 'nodisplay':''}>
                                  <td>Finance</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999}
                                      min={0}
                                      name="aFCount"
                                      value={values.aFCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aFCount && !errors.aFCount} 
                                      isInvalid={!!errors.aFCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aFCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aF"
                                      label=""
                                      name="aFCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aFCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleM !== true ? 'nodisplay':''}>
                                  <td>Mzdy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999}
                                      min={0}
                                      name="aMCount"
                                      value={values.aMCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aMCount && !errors.aMCount} 
                                      isInvalid={!!errors.aMCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aMCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aM"
                                      label=""
                                      name="aMCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aMCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleS !== true ? 'nodisplay':''}>
                                  <td>Sklady</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999}
                                      min={0}
                                      name="aSCount"
                                      value={values.aSCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aSCount && !errors.aSCount} 
                                      isInvalid={!!errors.aSCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aSCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aS"
                                      label=""
                                      name="aSCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aSCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleP !== true ? 'nodisplay':''}>
                                  <td>Maloobchod</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.aSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999 : 0}
                                      min={0}
                                      name="aPCount"
                                      value={values.aPCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aPCount && !errors.aPCount} 
                                      isInvalid={!!errors.aPCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aPCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aP"
                                      label=""
                                      name="aPCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aPCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleE !== true ? 'nodisplay':''}>
                                  <td>E-shopy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.aSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999 : 0}
                                      min={0}
                                      name="aECount"
                                      value={values.aECount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aECount && !errors.aECount} 
                                      isInvalid={!!errors.aECount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aECount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aE"
                                      label=""
                                      name="aECheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aECheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleJ !== true ? 'nodisplay':''}>
                                  <td>Majetek</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999}
                                      min={0}
                                      name="aJCount"
                                      value={values.aJCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aJCount && !errors.aJCount} 
                                      isInvalid={!!errors.aJCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aJCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aJ"
                                      label=""
                                      name="aJCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aJCheck}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleA !== true ? 'nodisplay':''}>
                                  <td>Analýzy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.aUCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999 : 0}
                                      min={0}
                                      name="aACount"
                                      value={values.aACount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aACount && !errors.aACount} 
                                      isInvalid={!!errors.aACount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aACount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aA"
                                      label=""
                                      name="aACheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aACheck}
                                      isInvalid={!!errors.aACheck}
                                    />
                                    
                                      {errors.aACheck}
                                    
                                  </td>
                                </tr>
                                <tr className={visibleR !== true ? 'nodisplay':''}>
                                  <td style={{width: '160px'}}>Polotovary dokladů</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.aLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.aYCount!:999}
                                      min={0}
                                      name="aRCount"
                                      value={values.aRCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.aJCount && !errors.aJCount} 
                                      isInvalid={!!errors.aRCount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.aRCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-aR"
                                      label=""
                                      name="aRCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.aRCheck}
                                    />
                                  </td>
                                </tr>
                                </tbody>
                              </table>                              
                            </Card.Body>
                          </Card> 
                          <Card style={{minWidth: '230px', marginTop: '25px'}}>
                            <Card.Header>
                              <Form.Check 
                                type="switch"
                                id="custom-switch-2"
                                label="Požadovaná licence"
                                name="tLicense"
                                onChange={async (e:any) => {await handleClose2(e);await handleChange(e);}}
                                onClick={async () => {await handleTarget(values);await handleReset();}}
                                checked={values.tLicense}
                              />
                              
                            </Card.Header>
                            <Card.Body>
                              <FormGroup as={Col} controlId="name">
                                <Form.Row>
                                  <FormLabel column lg={3}>Verze</FormLabel>
                                  <FormLabel column lg={3}>{calculatorsUpgradeScenario?.programVersion.name}</FormLabel>
                                  
                                </Form.Row>
                              </FormGroup>
                              <FormGroup as={Col} controlId="name">
                                <Form.Row>
                                  <FormLabel column lg={3}>Typ</FormLabel>
                                  <Col>
                                    <Form.Control as="select" 
                                      name="tLicenseType" 
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      value={values.tLicenseType!}
                                      isInvalid={!!errors.tLicenseType}
                                      disabled={!values.tLicense}
                                      >
                                        <option value="">--- Vyberte typ licence ---</option>
                                        {
                                        licenseTypes
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tLicenseType}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>
                              </FormGroup>
                              <table>
                                <tbody>
                                <tr>
                                  <td style={{minWidth: '100px'}}><strong>Modul</strong></td>
                                  <td><strong>Počet&nbsp;PC</strong></td>
                                </tr>
                                <tr className={values.programCode === 'D' ? 'nodisplay':''}>
                                  <td>Celkem PC</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999}
                                      min={0}
                                      name="tTotalCount"
                                      value={values.tTotalCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      //className={values.programCode === 'D' ? 'nodisplay':''}
                                      //isValid={touched.dYCount && !errors.dYCount} 
                                      isInvalid={!!errors.tTotalCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tTotalCount}
                                    </Form.Control.Feedback>
                                    
                                  </td>
                                </tr>
                                <tr className={visibleY !== true ? 'nodisplay':''}>
                                  <td>Jádro</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:999}
                                      min={0}
                                      name="tYCount"
                                      value={values.tYCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tYCount && !errors.tYCount} 
                                      isInvalid={!!errors.tYCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tYCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tY"
                                      label=""
                                      name="tYCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tYCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleK !== true ? 'nodisplay':''}>
                                  <td>Kancelář</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tKCount"
                                      value={values.tKCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tKCount && !errors.tKCount} 
                                      isInvalid={!!errors.tKCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tKCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tK"
                                      label=""
                                      name="tKCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tKCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleU !== true ? 'nodisplay':''}>
                                  <td>Účetnictví</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tUCount"
                                      value={values.tUCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tUCount && !errors.tUCount} 
                                      isInvalid={!!errors.tUCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tUCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tU"
                                      label=""
                                      name="tUCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tUCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleF !== true ? 'nodisplay':''}>
                                  <td>Finance</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tFCount"
                                      value={values.tFCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tFCount && !errors.tFCount} 
                                      isInvalid={!!errors.tFCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tFCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tF"
                                      label=""
                                      name="tFCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tFCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleM !== true ? 'nodisplay':''}>
                                  <td>Mzdy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tMCount"
                                      value={values.tMCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tMCount && !errors.tMCount} 
                                      isInvalid={!!errors.tMCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tMCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tM"
                                      label=""
                                      name="tMCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tMCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleS !== true ? 'nodisplay':''}>
                                  <td>Sklady</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tSCount"
                                      value={values.tSCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tSCount && !errors.tSCount} 
                                      isInvalid={!!errors.tSCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tSCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tS"
                                      label=""
                                      name="tSCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tSCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleP !== true ? 'nodisplay':''}>
                                  <td>Maloobchod</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.tSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999 : 0}
                                      min={0}
                                      name="tPCount"
                                      value={values.tPCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tPCount && !errors.tPCount} 
                                      isInvalid={!!errors.tPCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tPCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tP"
                                      label=""
                                      name="tPCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tPCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleE !== true ? 'nodisplay':''}>
                                  <td>E-shopy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.tSCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999 : 0}
                                      min={0}
                                      name="tECount"
                                      value={values.tECount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tECount && !errors.tECount} 
                                      isInvalid={!!errors.tECount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tECount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tE"
                                      label=""
                                      name="tECheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tECheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleJ !== true ? 'nodisplay':''}>
                                  <td>Majetek</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tJCount"
                                      value={values.tJCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tJCount && !errors.tJCount} 
                                      isInvalid={!!errors.tJCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tJCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tJ"
                                      label=""
                                      name="tJCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tJCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                <tr className={visibleA !== true ? 'nodisplay':''}>
                                  <td>Analýzy</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={values.tUCount! > 0 ? Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999 : 0}
                                      min={0}
                                      name="tACount"
                                      value={values.tACount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tACount && !errors.tACount} 
                                      isInvalid={!!errors.tACount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tACount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tA"
                                      label=""
                                      name="tACheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tACheck}
                                      disabled={!values.tLicense}
                                      isInvalid={!!errors.tACheck}
                                    />
                                    {errors.tACheck}
                                  </td>
                                </tr>
                                <tr className={visibleR !== true ? 'nodisplay':''}>
                                  <td style={{width: '160px'}}>Polotovary dokladů</td>
                                  <td>
                                    <FormControl 
                                      type="number"
                                      max={Object.keys(licenseTypesAB.filter(data => data.id === values.tLicenseType) ).length > 0 ? 1:calculatorsUpgradeScenario?.program.code === 'D' ? values.tYCount!:999}
                                      min={0}
                                      name="tRCount"
                                      value={values.tRCount!}
                                      onChange={(e:any) => {handleClose();handleChange(e)}}
                                      style={{width: '80px', textAlign: 'center'}}
                                      className={values.programCode === 'D' ? '':'nodisplay'}
                                      //isValid={touched.tJCount && !errors.tJCount} 
                                      isInvalid={!!errors.tRCount}
                                      disabled={!values.tLicense}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.tRCount}
                                    </Form.Control.Feedback>
                                    <Form.Check 
                                      className={values.programCode === 'D' ? 'nodisplay':''}
                                      style={{marginTop:'10px',marginLeft:'10px'}}
                                      type="switch"
                                      id="custom-switch-tR"
                                      label=""
                                      name="tRCheck"
                                      onChange={handleChange}
                                      onClick={handleClose}
                                      checked={values.tRCheck}
                                      disabled={!values.tLicense}
                                    />
                                  </td>
                                </tr>
                                </tbody>
                              </table>                              
                            </Card.Body>
                          </Card>  
                        </CardDeck>
                        
                        <br />
                        
                        <Row>
                          <Col>
                            {showTP ? <Alert variant="success" style={{height:'100%'}}>
                              <div className="float-left">
                              <strong>Celkem</strong>
                              </div>
                              <div className="float-right">
                                <h2><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={totalPrice} />&nbsp;Kč bez&nbsp;DPH</h2>
                              </div>
                              <div className="cleaner"></div>
                            </Alert>:<Alert variant="secondary" style={{height:'100%'}}>
                              <div className="float-left">
                                <strong>Celkem</strong>
                              </div>
                              <div className="float-right">
                                <h1>nespočítáno</h1>
                              </div>
                              <div className="cleaner"></div>
                            </Alert> }
                          </Col>
                          <Col>
                            <Button block type="submit" size="lg" variant="success" disabled={loading}>{loading ? 'Počítám cenu upgrade...' : 'Spočítat cenu upgrade'}</Button> 
                            
                            <ButtonGroup className="btn-block">
                              <Button variant="outline-primary" onClick={async () => {await handleClose();await handleMyReset();await handleReset()}}>Nastavit výchozí hodnoty</Button>
                              <Button variant="outline-danger" onClick={async () => {await handleMyReset();await handleDelete()}}>Resetovat kalkulačku</Button>
                              <Button variant="outline-secondary" onClick={async () => {await handleCalculate(values,true)}}><i className="fas fa-file-download"></i></Button>
                            </ButtonGroup>
                            
                            
                            <Button variant="outline-secondary" block className='nodisplay' style={{marginTop: '10px'}} onClick={async () => {await handleCalculate(values,true)}}><i className="fas fa-file-download"></i>&nbsp;Uložit požadavek do souboru</Button>
                          </Col>

                        </Row>
                                         
                        
                        {error}
                        <br /><br />
                        
                        
                      </Form>
                    )}
                  </Formik>
                  }
                  
                <br /><br />
                <Modal 
                  show={showETL} 
                  onHide={handleClose} 
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Kalkulačka UPGRADE</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {errorTL}
                  </Modal.Body>
                </Modal>
                <Modal 
                  show={showOKTL} 
                  onHide={handleClose} 
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Kalkulačka UPGRADE</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Alert variant="success">
                      <strong>Celková cena UPGRADE</strong><br />
                      <h1><NumberFormat displayType={'text'} thousandSeparator={' '} value={totalPrice} /> Kč</h1>
                      
                    </Alert> 
                  </Modal.Body>
                </Modal>
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(UpgradeCalculator)
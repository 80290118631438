import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

import { Button, ButtonGroup, DropdownButton, Dropdown,  Form, Col, Alert, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../Utils/Confirmation"

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface FilesUpdateProps {
  id: string,
  type: string,
  masterId: string
}

const FilesUpdate: React.FC<FilesUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci


  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const file = rootStore.files.find(f => f.id === props.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteFile = () => {
    (async () => {
      if (await confirm("Opravdu chcete soubor "+file?.fileName+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání souboru'})) {
        deleteFile()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání kontaktu
  const deleteFile = () => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      // Zavolání API   
      await axios.delete(getUrl('URL_FILES')+'/'+props.id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Změna dat ve Store
          if (props.type === 'support') rootStore.fetchFilesForSupport(props.masterId)
          if (props.type === 'meeting') rootStore.fetchFilesForMeeting(props.masterId)
          if (props.type === 'task') rootStore.fetchFilesForTask(props.masterId)
          if (props.type === 'customer') rootStore.fetchFilesForCustomer(props.masterId) 

          //rootStore.fetchEducations()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          
          setShowE(true)
        });
  
      })()    
  }

  // Funkce pro změnu kontaktu
  const handleUpdate = (noteData:string) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(getUrl('URL_FILES')+'/'+props.id, { 
        id: file?.id,
        note: noteData,
        linkId: file?.linkId
      }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          // Změna dat ve Store
          
          file?.setInformations(response.data.note!)

          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    
  })   
  return (
    <>
        <ButtonGroup style={{}}>
          <Button variant='outline-primary' size="sm" onClick={handleShow}>Upravit</Button>
    
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
            <Dropdown.Item onClick={() => { handleOnClickDeleteFile()} } style={{color: 'red'}} >Smazat soubor</Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>


      <Modal 
        show={showE} 
        onHide={handleClose} 
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání souboru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        style={{backgroundColor: 'rgba(128, 128, 128, 0.5)'}}
        >
        <Modal.Header closeButton>
          <Modal.Title>Úprava informací o souboru {file?.fileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Informace o souboru byly úspěšně upraveny!​</strong>
             
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                note: file?.note
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.note!)
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
               
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Popis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>


                  
                    
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(FilesUpdate))

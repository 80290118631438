import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
//import axios from 'axios'
import { Alert, Form, Col } from 'react-bootstrap'

//import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

import { useUpgradeStore } from '../../store'
import { observer } from 'mobx-react'

//import { getToken, setUserRoles } from '../../Utils/Common'
//import { login } from '../../authProvider'

//import * as Constants from '../../Constants/Constants'

//import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'
import { Formik } from 'formik'
import * as Yup from 'yup'

function UpgradeScenarioHeader(props:any) {
  // eslint-disable-next-line
  const [error, setError] = useState(null)
  // eslint-disable-next-line
  const [actualDuelScenario, setActualDuelScenario] = useState('')
  // eslint-disable-next-line
  const [actualStereoScenario, setActualStereoScenario] = useState('')


  // Načtení Store
  const rootUpgradeStore = useUpgradeStore()
  
  const schemaUpdate = Yup.object().shape({
  
  })
   
  return( 
    <div style={{position: 'sticky', top: '56px', zIndex: 1037}}>
    <Alert variant="secondary" className="alert-no-border-radius">
      <Formik
        validationSchema={schemaUpdate}
        initialValues={{ 
          upgradeScenarioDuel: !(localStorage.getItem('upgradeScenarioDuelSelected') === undefined || localStorage.getItem('upgradeScenarioDuelSelected') === null)  ? localStorage.getItem('upgradeScenarioDuelSelected') : rootUpgradeStore.upgradeOffersScenarios.find(us => us.program.code === 'D' && us.isActive === true)!.id.toString(),
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {

            let actualDversion:number = rootUpgradeStore.upgradeOffersScenarios.find(usda => usda.isActive === true && usda.program.code === 'D')?.programVersion.version! 
            let actualSversion:number = rootUpgradeStore.upgradeOffersScenarios.find(usda => usda.isActive === true && usda.program.code === 'S')?.programVersion.version!

            let diffBetweenActualAndSelectedDversion:number = actualDversion - rootUpgradeStore.upgradeOffersScenarios.find(usd => usd.id === values.upgradeScenarioDuel)?.programVersion.version!

            let stereoSelectedVersion:number = actualSversion - diffBetweenActualAndSelectedDversion


            //console.log(actualDversion)
            //console.log(actualSversion)
            //console.log(diffBetweenActualAndSelectedDversion)
            //console.log(stereoSelectedVersion)

            localStorage.setItem('upgradeScenarioDuelSelected', values.upgradeScenarioDuel!)
            localStorage.setItem('upgradeScenarioStereoSelected', rootUpgradeStore.upgradeOffersScenarios.find(uss => uss.program.code === 'S' && uss.programVersion.version === stereoSelectedVersion)?.id!)
            setActualDuelScenario(values.upgradeScenarioDuel!)
            setActualStereoScenario(rootUpgradeStore.upgradeOffersScenarios.find(uss => uss.program.code === 'S' && uss.programVersion.version === stereoSelectedVersion)?.id!)
            setSubmitting(false);
            props.refreshPage()
          }, 100);
        }}
      >
        {({ 
          handleSubmit,
          handleChange,
        
          values,
          touched,
          isValid,
          errors,
          isSubmitting }) => (
          <Form onSubmit={handleSubmit}>

            <FormGroup controlId="upgradeScenarioDuel" style={{marginBottom: '0px'}}>
            <Form.Row>
                <FormLabel column lg={6}>Aktuálně nastavené scénáře</FormLabel>
                <Col>
                <Form.Control as="select" 
                    name="upgradeScenarioDuel" 
                    onChange={async (e:any) => { await handleChange(e); await handleSubmit(e); }}
                    value={values.upgradeScenarioDuel!}
                    >
                      <option value="" disabled >--- vyberte ---</option>
                      {rootUpgradeStore.upgradeOffersScenarios.filter(us => us.program.code === 'D').map(us => (
                        <option key={us.id} value={us.id}>{us.program.name}&nbsp;{us.programVersion.version}&nbsp;/&nbsp;STEREO&nbsp;{us.programVersion.version!+13}</option>
                      ))}
                      
                      
                  </Form.Control>
                  
                  <Form.Control.Feedback type="invalid">
                    {errors.upgradeScenarioDuel}
                  </Form.Control.Feedback>
                </Col>
                </Form.Row>  
            </FormGroup>   


            


            
          </Form>
        )}
      </Formik>  
      

      

    </Alert>
    </div>
  )
}
  
export default withRouter(observer(UpgradeScenarioHeader))

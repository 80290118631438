import { types } from 'mobx-state-tree'


export const AppProduelCustomers = types
  .model({
    id: types.optional(types.string, ''),
    portalId: types.optional(types.string, ''),
    accountNumber: types.optional(types.string, ''),
    businessName: types.optional(types.string, ''),
    name: types.maybeNull(types.string),
    companyRegistrationNumber: types.optional(types.string, ''),
    taxIdentificationNumber: types.maybeNull(types.string),
    isTaxAble: types.optional(types.boolean, false),
    firstName: types.maybeNull(types.string),
    surName: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    titleAfter: types.maybeNull(types.string),
    isActive: types.optional(types.boolean, false),

  })
  .views(self => ({
    
  }))
  .actions(self => ({
    
  }))

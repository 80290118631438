import React  from 'react'
import { RouteComponentProps,withRouter } from 'react-router-dom'
import {  Alert } from 'react-bootstrap'
import * as Constants from '../../Constants/Constants'
import { observer } from 'mobx-react'
import {getErrorByCode} from '../../helpers/globalFunctions'

export interface IErrorProps {
  errorData?: any
}

const ErrorAlert: React.FC<IErrorProps & RouteComponentProps> = ({
  children,
  ...props
}) => {

  let errorFinal:any = null

  // Výpis chyb
  const errorMessageOther:any = (
    <Alert variant="danger">
        {Constants.JEJDA}
    </Alert> 
  )
  const errorMessage401:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Neautorizovaný požadavek.</li>
    </Alert> 
  )  
  const errorMessage403:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Na tuto operaci nemáte dostatečná oprvávnění.</li>
    </Alert> 
  )
  const errorMessage404:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Server nenalezen (Kód chyby: 404)</li>
    </Alert> 
  )
  const errorMessage405:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Nepovolená metoda (Kód chyby: 405)</li>
    </Alert> 
  )
  const errorMessage409:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Záznam byl změněn jiným uživatelem. Doporučujeme si nejprve prohlédnou provedené změny a až následně uložit změny své.</li>
    </Alert> 
  )
  const errorMessage413:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Příliš velký soubor (Kód chyby: 413)</li>
    </Alert> 
  )
  const errorMessage500:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Vnitřní chyba serveru (Kód chyby: 500{props.errorData.response !== undefined ? ', Id: '+props.errorData.response.headers.telemetryid : ''})</li>
    </Alert> 
  )
  const errorMessage503:any = (
    <Alert variant="danger">
        <strong>{Constants.JEJDA}</strong><br />
        <li>Zákaznický portál momentálně není dostupný. Opakujte prosím přihlášení nebo to zkuste později.</li>
    </Alert> 
  )
  
  // Pokud 400
  if (props.errorData.response !== undefined && props.errorData.response.data.statusCode === 400) {
    const errorsDataToShow:any = props.errorData.response.data.errors
    const errorsToShow = errorsDataToShow.map((errors: { key: string | number | undefined; message: React.ReactNode | undefined; errorCode: number }) => (
      <li key={errors.key}>    
          {getErrorByCode(errors.errorCode)?.description !== undefined ? getErrorByCode(errors.errorCode)?.description : errors.message}
          {errors.errorCode === 1000 && errors.message?.toString().includes('Multipart body length limit') && ' Pravděpodobně příliš velký soubor.'}
      </li> 
    ))  

    const errorMessage:any = (
      <Alert variant="danger">
          <strong>{Constants.JEJDA}</strong><br />
          {errorsToShow}
      </Alert> 
    )

    errorFinal = errorMessage

  }

  else errorFinal = errorMessageOther 
  
  if (props.errorData.response !== undefined && props.errorData.response.status === 401) 
    {
      errorFinal = errorMessage401
      sessionStorage.setItem('automaticLogout', '2')
      props.history.push('/logout')
    }
  if (props.errorData.response !== undefined && props.errorData.response.status === 403) 
    {
      errorFinal = errorMessage403
      //sessionStorage.setItem('automaticLogout', '2')
      //props.history.push('/logout')
    }
  if (props.errorData.response !== undefined && props.errorData.response.status === 404) 
    {
      errorFinal = errorMessage404
      sessionStorage.setItem('automaticLogout', '2')
      props.history.push('/logout')
    }  
  if (props.errorData.response !== undefined && props.errorData.response.status === 500) 
    {
      errorFinal = errorMessage500
      sessionStorage.setItem('automaticLogout', '2')
      props.history.push('/logout')
    }
  if (props.errorData.response !== undefined && props.errorData.response.status === 503) 
    {
      errorFinal = errorMessage503
    }   
  if (props.errorData.response !== undefined && props.errorData.response.status === 405) 
    {
      errorFinal = errorMessage405
      //sessionStorage.setItem('automaticLogout', '2')
      //props.history.push('/logout')
    }
    
    if (props.errorData.response !== undefined && props.errorData.response.status === 409) 
    {
      errorFinal = errorMessage409
      //sessionStorage.setItem('automaticLogout', '2')
      //props.history.push('/logout')
    }

    if (props.errorData.response !== undefined && props.errorData.response.status === 413) 
    {
      errorFinal = errorMessage413
      //sessionStorage.setItem('automaticLogout', '2')
      //props.history.push('/logout')
    }
  
  return (
    <div>{errorFinal}</div>
  )  
  
}

export default withRouter(observer(ErrorAlert))

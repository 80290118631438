import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//import { getToken, getUrl } from '../../Utils/Common'

import { Button,  Col, Alert, Modal, Dropdown, Row } from 'react-bootstrap'

//import * as Constants from '../../../Constants/Constants'
//import axios from 'axios';

import { observer } from 'mobx-react'
import { useAppStore } from '../../store'

//import { confirm } from "../../Utils/Confirmation";

import moment from 'moment'

import NumberFormat from 'react-number-format'


interface AppProDuelReceiptDetailProps {
  receiptId?: string,
  tokenId: string,
  companyId: string,
  showAs: string
}

const AppProDuelReceiptDetail: React.FC<AppProDuelReceiptDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  //eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')) ? true : false

  // Nastavení stavů pro zobrazení
  //const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  //const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

 
  // Připojení store
  const rootAppStore = useAppStore()
  
  const editedReceipt = rootAppStore.appProduelReceipts.find(r => r.id === props.receiptId)
  //eslint-disable-next-line
  const company = rootAppStore.appProduelCompanies.find(c => c.id === props.companyId)
  //const token = company?.tokens?.find(t => t.id === props.tokenId)


  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleCloseE = () => { 
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleShow = async () => {

    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    //setShowInfo(false)
    //setShowForm('')
    setShow(true)
  }





  return (
    <>
      {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>
        Detail účtenky
      </Dropdown.Item>}
      {(props.showAs === 'button') &&<>
      
        <Button size="sm" style={{}} variant='outline-primary' onClick={() => { handleShow() } }>
          Detail
        </Button>
      </>
      }

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        backdrop="static"
        style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Detail účtenky
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={{span: 8, order :1}}>
              <Alert variant='secondary'>

              <Row>
                <Col sm={4} md={4} lg={4}><strong>Datum vystavení:</strong></Col>
                <Col>{moment(editedReceipt?.receiptDate).format("DD.MM.YYYY")}</Col>
              </Row>
              <Row>
                <Col sm={4} md={4} lg={4}><strong>Částka:</strong></Col>
                <Col><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={editedReceipt?.amount} />&nbsp;{editedReceipt?.currencyCode}</Col>
              </Row>
              <Row>
                <Col sm={4} md={4} lg={4}><strong>Poznámka:</strong></Col>
                <Col>{editedReceipt?.note}</Col>
              </Row>
              <Row>
                <Col sm={4} md={4} lg={4}><strong>Podpis:</strong></Col>
                <Col>{editedReceipt?.signature}</Col>
              </Row>    
              <Row>
                <Col sm={4} md={4} lg={4}><strong>Zpracováno:</strong></Col>
                <Col>{editedReceipt?.isProcessed ? <>{moment(editedReceipt?.processDate).format("DD.MM.YYYY HH:mm")}</>:'Ne'}</Col>
              </Row>
              

              <br />
              

              </Alert>
            </Col>
          </Row>
          
        </Modal.Body>

      </Modal>

      <Modal 
        show={showE} 
        onHide={handleCloseE} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Změna stavu účtenky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(AppProDuelReceiptDetail))

import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken } from '../../Utils/Common'

import { Button,  Modal, Dropdown, Card, Table, Spinner, Badge } from 'react-bootstrap'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import Pagination from 'react-js-pagination'
import moment from 'moment'

import CustomersOtherActionsHistoryDetail from './Customers.OtherActions.History.Detail.part'

interface CustomersOtherActionsHistoryProps {
  showAs: string,
  title:string,
  historyType: string,
  historyParam: string,
  block: boolean,
  label: string
}

const CustomersOtherActionsHistory: React.FC<CustomersOtherActionsHistoryProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  //const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
 
  // Připojení store
  const rootStore = useStore()

 
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    rootStore.deleteCustomerOpenedHistory()
    
    sessionStorage.removeItem('historySearchPage')
    localStorage.removeItem('customerHistoryTotalCount')
    localStorage.removeItem('customerHistoryNextSiteTotalCount')

    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    
    sessionStorage.removeItem('autoCloseModal')
    setLoaded(false);
    (async () => { 
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      
      await rootStore.fetchCustomerOpenedHistory(props.historyType, props.historyParam, Number(sessionStorage.getItem('HistorySearchPage')) === 0 ? 1:Number(sessionStorage.getItem('HistorySearchPage'))) 
      setLoaded(true)
    })()
    setError(null)
    //setShowInfo(false)
    //setShowForm('')
    setShow(true)

  }

    //Počet záznamů
    const countData:number = Object.keys(rootStore.customerOpenedHistory).length

    // Seznam e-mailů do karet
    const historyDataCard = rootStore.customerOpenedHistory.map((customerOpenedHistory,index) => (
      <div key={index}>
      <Card>
        <Card.Body>
            <div className="float-right">
            <CustomersOtherActionsHistoryDetail showAs='button' block={false} title={props.title} historyType={props.historyType} historyParam={customerOpenedHistory.id} historyDate={customerOpenedHistory.startDate!} scope={customerOpenedHistory.scopeName!} operation={customerOpenedHistory.operationName!} indexNumber={index} />
            </div>
            <strong>Datum změny:</strong>&nbsp;{moment(customerOpenedHistory.startDate).format("DD.MM.YYYY HH:mm")}<br />
            <strong>Operace:</strong>&nbsp;{customerOpenedHistory.operationName}<br />
            <strong>Oblast:</strong>&nbsp;{customerOpenedHistory.scopeName}<br />
            <strong>Změnil:</strong>&nbsp;<i>{customerOpenedHistory.userFullName}</i>
        </Card.Body>
      </Card>
      <br />
      </div>
    ))

      // Seznam e-mailů do řádků tabulky
  const historyDataTable = rootStore.customerOpenedHistory.map((customerOpenedHistory,index) => (
    <tr key={index}>
      <td>{moment(customerOpenedHistory.startDate).format("DD.MM.YYYY HH:mm")}</td>
      <td>{customerOpenedHistory.operationName}</td>
      <td>{customerOpenedHistory.scopeName}</td>
      <td>{customerOpenedHistory.userFullName}</td>
      <td><CustomersOtherActionsHistoryDetail showAs='button' block={false} title={props.title} historyType={props.historyType} historyParam={customerOpenedHistory.id} historyDate={customerOpenedHistory.startDate!} scope={customerOpenedHistory.scopeName!} operation={customerOpenedHistory.operationName!} indexNumber={index} /></td>
    </tr>
  ))

  const handlePageChange = (pageNumber:number) => {
    (async () => { 
      setLoaded(false);
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout') 
      }
      sessionStorage.setItem('historySearchPage', String(pageNumber))

      // Předat hledanou hodnotu action pro načtení zákazníků
      await rootStore.fetchCustomerOpenedHistory(props.historyType, props.historyParam, pageNumber)
      
      setLoaded(true);
      // Hodnoty hledání uložit do sessionStorage
      
    })()
  }


  return (
    <span>
      {rights &&<>
        {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Historie změn{props.label}</Dropdown.Item>}
        {props.showAs === 'button' &&<Button block={props.block} size="sm" variant="outline-primary" onClick={() => { handleShow() } }>Historie změn{props.label}</Button>}
      </>}
      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90w"
        style={{maxWidth: 'none !important'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Historie změn{props.label}<br /><Badge variant="secondary" style={{fontSize: '20px'}}>{props.title}</Badge></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '180px'}}>
      
                {loaded === false &&<div style={{textAlign: 'center', minHeight: '100px', paddingTop: '30px'}}><Spinner animation="border"  /></div>}
                
                {loaded === true &&<>
                


                  <div className="showMobile">
                  {countData === 0 ? <div>Histoie změn neobsahuje žádné záznamy.<br /><br /></div> : ''}
                      {historyDataCard}
                  </div>
                  <div className="showWeb">
                    <Table responsive hover>
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head">Datum změny</th>
                          <th className="jsw-table-head">Operace</th>
                          <th className="jsw-table-head">Oblast</th>
                          <th className="jsw-table-head">Změnil</th>
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyDataTable}
                      </tbody>
                    </Table> 
                    {countData === 0 ? <div>Histoie změn neobsahuje žádné záznamy.<br /><br /></div> : ''}
                  </div> 
                  <br />  
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={Number(sessionStorage.getItem('historySearchPage')) === 0 ? 1:Number(sessionStorage.getItem('historySearchPage')) }
                    itemsCountPerPage={10}
                    totalItemsCount={Number(localStorage.getItem('customerHistoryTotalCount'))}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(1)}
                    />
                  </>}
                
                {error}
        </Modal.Body>

      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsHistory))

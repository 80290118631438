import React, {useState}  from 'react'
import Loader from "react-loader"
import { ROUTES } from '../../../Routes'
import { NavLink } from 'react-router-dom'

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'
import CustomerHeader from '../../../parts/customerHeader/CustomerHeader.parts'
import CustomersDashboardInformation from './components/Customers.Dashboard.Information.part'
//import CustomersDashboardBillingAddress from './components/Customers.Dashboard.BillingAddress.part'
import CustomersDashboardDeliveryAddress from './components/Customers.Dashboard.DeliveryAddress.part'
import CustomersDashboardContacts from './components/Customers.Dashboard.Contacts.part'
import CustomersDashboardCategories from './components/Customers.Dashboard.Categories.part'
import CustomersDashboardLicenses from './components/Customers.Dashboard.Licenses.part'
import CustomersDashboardNotes from './components/Customers.Dashboard.Notes.part'

import SupportContactsAdd from '../../support/components/Support.Contacts.Add.part'
import SupportContactsList from '../../support/components/Support.Contacts.List.part'


import { Container, CardDeck, Alert, Row, Col } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
// eslint-disable-next-line
import { useStore,useDialsStore, useUpgradeStore, useAppStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'
import moment from 'moment'



function Dashboard(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  const [loadedForHeader, setLoadedForHeader] = useState(false);
  const [showLoaderForSupport, setShowLoaderForSupport] = useState(false);

  const [loadingAppProDuel, setLoadingAppProDuel] = useState(false);

  // Nastavení práv zda jsme Ježci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  // eslint-disable-next-line
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rightsBU:boolean = ((localStorage.getItem('BigUser')! === '1')) ? true : false







  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const rootAppStore = useAppStore()

  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  const customerCategories = rootStore.customerOpenedCategories
  const customerLicenses = rootStore.customerOpenedLicenses
  const customerServices = rootStore.customerOpenedServices
  const customerNotes = rootStore.customerOpenedNotes
  
  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    
     // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
    if (!localStorage.getItem('openedCustomerId')) {
      props.history.push('/customers-open')
    } 
    
    localStorage.setItem('lastOpenedSite', 'customers-dashboard')
    await checkVersion()
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    setShowLoaderForSupport(true)
    setLoadingAppProDuel(true)
    window.scrollTo(0, 0)
    rootStore.deleteCustomerOpenedLicenses()
    localStorage.setItem('lastSupportTotalCount', '0')
    rootStore.deleteSupport()

    let supportSearchDataArray = [{filterName:"",filterValue:"",displayValue:""}]
    localStorage.setItem('supportSearchArray',JSON.stringify(supportSearchDataArray))
    sessionStorage.setItem('supportSearchPage', '1')
    
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      //Načtení souvisejících číselníků podle práv
      
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          //await rootStore.fetchCustomerOpened()
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      } 
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { 
          await rootStore.fetchCustomerOpened()
          rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive &&  await rootStore.fetchCustomerOfferCount() 
          //rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive &&  rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
        }

      }  

      //Pokud mám práva tak vždy načítám další položky 
      setLoaded(true) // Nastavení pro Loader, že je strana načtena


      if (!(localStorage.getItem('openedCustomerId') === null || localStorage.getItem('openedCustomerId') === undefined)) {

        rootStore.fetchCustomerOpenedServices(localStorage.getItem('openedCustomerId')!)
        rights && rootStore.fetchCustomerOpenedNotes(localStorage.getItem('openedCustomerId')!)
        rootDialsStore.fetchDialsPrograms();

        await (async () => {
          // Pokud je zákazník aktivní, načítáme také licence a způsoby použití
          if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive === true) {
            await rootStore.fetchCustomerOpenedLicenses(localStorage.getItem('openedCustomerId')!)
            rootStore.fetchUsageMode(localStorage.getItem('openedCustomerId')!,rootStore.getCustomerOpenedLicensesActiveActual()[0]?.license.program.id!,  rootStore.getCustomerOpenedLicensesActiveActual()[1]?.license.program.id!)
            await rootAppStore.fetchAppProduelCustomerByPortalId(localStorage.getItem('openedCustomerId')!)
            setLoadingAppProDuel(false)
          }
        })()   

        rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)?.isActive &&  await rootStore.fetchCustomerOfferCount() 
        
        // Načtení všeho kolem kontaktů
        if (rights === true ) {
          rootStore.fetchCustomerOpenedCategories()  
          rootStore.fetchFilesCountForOpenedCustomer()
          if (!(localStorage.getItem('openedCustomerId') === null || localStorage.getItem('openedCustomerId') === undefined)) rootStore.fetchHedgehogUsers()
          if (!(localStorage.getItem('openedCustomerId') === null || localStorage.getItem('openedCustomerId') === undefined)) rootDialsStore.fetchDialsSupportThemes()
          if (!(localStorage.getItem('openedCustomerId') === null || localStorage.getItem('openedCustomerId') === undefined)) rootDialsStore.fetchDialsSupportTypes()
          if (!(localStorage.getItem('openedCustomerId') === null || localStorage.getItem('openedCustomerId') === undefined)) await rootStore.fetchSupports(1,true)
        }

      }
      setShowLoaderForSupport(false)

    }

    setLoadedForHeader(true)

    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <CustomerHeader 
                showStatuts={loadedForHeader}
                statusGDPR={customer?.gdprIsActive!} 
                statusNewCustomer={(Object.keys(customerLicenses.filter(cl => !(cl.createDate === '' || cl.createDate === null) && moment(new Date()).diff(cl.createDate.replace(' ', 'T'), 'days') < 91 )).length > 0  || (!(customer?.createDate! === "" || customer?.createDate! === null) && moment(new Date()).diff(customer?.createDate?.replace(' ', 'T'), 'days') < 91)) ? true:false }
                //statusNewCustomer={customer?.createDate! === "" ? false : moment(new Date()).diff(customer?.createDate?.replace(' ', 'T'), 'days') < 91 ? true : false} 
                statusNonActualV={Object.keys(customerLicenses.filter(cl => cl.license.programVersion.supportDateTo === '' || new Date(cl.license.programVersion.supportDateTo!.replace(' ', 'T')) >= new Date()  )).length > 0 ? false:true  } 
                statusStart={Object.keys(customerCategories.filter(c => c.code === 'S')).length > 0 ? true : false} 
                statusVS={new Date(customerServices.find(s => s.service.id === 'dec69c57-bd93-4546-8272-3f93c080f794')?.validTo?.replace(' ', 'T')!) > new Date() ? true:false} 
                statusNotes={Object.keys(customerNotes.filter(n => n.isNotify === true && n.isActive === true )).length > 0 ? true:false}
                statusFiles={customer?.filesCount}
                />
              <Container fluid>
                {rightsBU && rootStore.getCustomerOfferCountByState('P') === 0 && rootStore.getCustomerOfferCountByState('A') === 0 && <Alert variant="warning"><strong>Zkontrolujte, prosím, a popřípadě doplňte své firemní údaje.</strong></Alert>}
                {rightsBU && rootStore.getCustomerOfferCountByState('P') > 0 && <Alert variant="warning"><strong>Objednávky upgrade programů pro rok {rootStore.getCustomerOfferMaxYearByState('P')} jsou připraveny.</strong> Naleznete je v menu v sekci <NavLink to={ROUTES.upgradeOfferList.getLink()}>Objednávky upgrade</NavLink>.</Alert>}
                {rightsBU && rootStore.getCustomerOfferCountByState('P') === 0 && rootStore.getCustomerOfferCountByState('A') > 0 &&<Alert variant="success"><strong>Upgrade programu pro rok {rootStore.getCustomerOfferMaxYearByState('A')} máte objednaný.</strong> </Alert>}
                <h4>Přehled {rights === true ? 'zákazníka' : 'firmy'}</h4>
                <Row>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                    <CustomersDashboardInformation />
                    <br />
                    <CustomersDashboardDeliveryAddress />
                  </Col>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    <CustomersDashboardContacts />
                  </Col>
                </Row>
                
                
                <br /><br />
                <CustomersDashboardLicenses loadingAppProDuel={loadingAppProDuel} />
                <br /><br />
                {rights && <>
                <h4>Podpora</h4>
                <br />
                <SupportContactsAdd isCustomer={true} customerId={localStorage.getItem('openedCustomerId')!} />
                <br /><br />
                <SupportContactsList isCustomer={true} showLoader={showLoaderForSupport} />
                <br />
                
                <CustomersDashboardNotes />
                
                <br /><br />
                </>}
                <CardDeck>
                  {rights && <CustomersDashboardCategories />}
                </CardDeck>
                <br /><br />
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(Dashboard)

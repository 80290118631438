import { getToken, errorEvaluation, getUrl } from '../Utils/Common';

import { AppProduelCustomers } from './app.produel.customers.store'
import { AppProduelCompanies } from './app.produel.companies.store'
import { AppProduelTokens } from './app.produel.tokens.store'
import { AppProduelReceipts } from './app.produel.receipts.store'

import { Helpers } from './helpers.store'

import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

//eslint-disable-next-line
import { getFilterStringToBE } from '../sharedComponents/filterPanel/components/FilterPanelFunctions'

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootAppStore = types
  .model({
    helpers: types.array(Helpers),    
    appProduelCustomers: types.array(AppProduelCustomers),
    appProduelCompanies: types.array(AppProduelCompanies),
    appProduelTokens: types.array(AppProduelTokens),
    appProduelReceipts: types.array(AppProduelReceipts),
  })
  .views(self => ({
    getAppProduelCustomerByPortalId(portalId: string) {
      return self.appProduelCustomers.find(item => item.portalId === portalId)
    },
    getAppProduelCustomerById(id: string) {
      return self.appProduelCustomers.find(item => item.id === id)
    },
    
  }))
  .actions(self => ({

    fetchAppProduelReceipts: flow(function* fetchProjects(pageNumber:number,APIkey:string,masterView:boolean) {
      try {
        //self.dialsMeetingsCategoriesProducts.clear()

        let filter:string = ''
        let order:string = 'ReceiptDate~desc'

        filter = getFilterStringToBE(localStorage.getItem('appProDuelReceiptsSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('appProDuelReceiptsOrderBy') === 'ReceiptDate') order = 'ReceiptDate~asc'
        if (localStorage.getItem('appProDuelReceiptsOrderBy') === 'ReceiptDateDesc') order = 'ReceiptDate~desc'

        const page = pageNumber
        //const page = 1
        const pagesize = 10

        let urlSecondPart = ''

        if (masterView) urlSecondPart = '/sync'
        

        //const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DOKLADY_API')+'receipts'+urlSecondPart,{
          headers: {
            'X-API-KEY': '' + APIkey
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastAppProDuelReceiptsSearchTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
          //self.appProduelReceipts.clear()
        });
        
        if (localStorage.getItem('lastAppProduelReceiptsTotalCount') === '0') {
          self.appProduelReceipts.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.appProduelReceipts,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst účtenky. Může dojít k odhlášení!', error)
      self.appProduelReceipts.clear()
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    
    fetchAppProduelTokens: flow(function* fetchProjects(customerId:string) {
      try {
        const accessToken = yield getToken()

        self.appProduelCompanies
        .forEach(c => {
          axios.get(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+customerId+'/companies/'+c.id+'/tokens',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              order: 'CreateDate~asc',
            }          
          }).then(response => {
            let newData = response.data.items
            c.setTokens(newData)
            
            //return response.data
            
          }).catch(error => {
            errorEvaluation(error)
          });

       } 
       )

    } catch (error) {
      console.error('Nepodařilo se načíst tokeny. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchAppProduelCompanies: flow(function* fetchProjects(pageNumber:number,customerId:string) {
      try {
        //self.dialsMeetingsCategoriesProducts.clear()

        let filter:string = ''
        let order:string = 'Name~asc'

        filter = getFilterStringToBE(localStorage.getItem('appProduelCompaniesSearchArray'))
        //console.log(filter)

        //if (localStorage.getItem('dialsMeetingsCategoriesOrderBy') === 'Name') order = 'Name~asc'
        //if (localStorage.getItem('dialsMeetingsCategoriesOrderBy') === 'NameDesc') order = 'Name~desc'

        //const page = pageNumber
        const page = 1
        const pagesize = 9999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+customerId+'/companies',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastAppProduelCompaniesTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastAppProduelCompaniesTotalCount') === '0') {
          self.appProduelCompanies.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.appProduelCompanies,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst firmy. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchAppProduelCustomerByPortalId: flow(function* fetchProjects(portalId:string) {
      try {

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DOKLADY_CUSTOMERS')+'/portal/'+portalId,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
          }  }).then(response => {

            return response.data
          
        }).catch(error => {
          self.appProduelCustomers.clear()
          errorEvaluation(error)
        });
        
        
          
          applySnapshot(self.appProduelCustomers,[cl]) 
         
    } catch (error) {
      console.error('Nepodařilo se načíst AppProduelCustomers. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteAppProduelCustomers: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.appProduelCustomers.clear()
                
      } catch (error) {
        console.error('Failed to clear AppProduelCustomers', error)
      }
    }),

    deleteAppProduelCompanies: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.appProduelCompanies.clear()
                
      } catch (error) {
        console.error('Failed to clear AppProduelCompanies', error)
      }
    }),

    deleteAppProduelReceipts: flow(function* fetchProjects() {
      try {
        yield sleep(1)
        self.appProduelReceipts.clear()
                
      } catch (error) {
        console.error('Failed to clear AppProduelReceipts', error)
      }
    }),
    

  }))


export type RootAppStoreType = Instance<typeof RootAppStore>
export type AppProduelCustomersType = Instance<typeof AppProduelCustomers>

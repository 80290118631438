import React, {useState}  from 'react'
import Loader from "react-loader"
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'


import { Container, Card } from 'react-bootstrap'

import { useMount } from '../../../helpers/lifecycle-hooks'
// eslint-disable-next-line
import { useStore,useDialsStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../../Utils/Common'

function Dials(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);

  // Nastavení práv zda jsme Ježci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
  // eslint-disable-next-line
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  //const rightsBU:boolean = ((localStorage.getItem('BigUser')! === '1')) ? true : false

  

  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  //const rootUpgradeStore = useUpgradeStore()

  //rootUpgradeStore.fetchUpgradeOffersScenarios()
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  
  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'dials')
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      //Načtení souvisejících číselníků podle práv
      
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          //await rootStore.fetchCustomerOpened()
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      } 
      //Konec sekce F5
      //await loadData()

      

      //Pokud mám práva tak vždy načítám další položky 
      setLoaded(true) // Nastavení pro Loader, že je strana načtena

      

      // !!!! Sem přidat způsoby použití programů !!!! 
      //rootDialsStore.fetchDialsPrograms()

    }

    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              
              <Container fluid>
                <br />
                <h4>Číselníky</h4>
                <br />
                {rightsBH && 
                <>
                <Card>
                  <Card.Header>Kontakty</Card.Header>
                  <Card.Body>
                    <Link className="nav-link" to={ROUTES.dialsSupportTypes.getLink()} >
                      Typy kontaktů
                    </Link>
                    
                    <Link className="nav-link" to={ROUTES.dialsSupportThemes.getLink()} >
                      Témata kontaktů
                    </Link>
                  </Card.Body>
                </Card>
                <br />
                <Card>
                  <Card.Header>Programy</Card.Header>
                  <Card.Body>
                    <Link className="nav-link" to={ROUTES.dialsProgramVersions.getLink()} >
                      Verze programů
                    </Link>
                  </Card.Body>
                </Card>
                <br />
                <Card>
                  <Card.Header>Česká pošta</Card.Header>
                  <Card.Body>
                    <Link className="nav-link" to={ROUTES.dialsDeliveryCzechPostServices.getLink()} >
                      Služby
                    </Link>
                    <Link className="nav-link" to={ROUTES.dialsDeliveryCzechPostProducts.getLink()} >
                      Produkty
                    </Link>
                    
                    <Link className="nav-link" to={ROUTES.dialsDeliveryCzechPostTemplates.getLink()} >
                      Šablony zásilek
                    </Link>
                   
                  </Card.Body>
                </Card>
                <br />
                </>
                }

                <Card>
                  <Card.Header>Ostatní číselníky</Card.Header>
                  <Card.Body>
                    {rightsBH &&
                      <Link className="nav-link" to={ROUTES.dialsOperationsPreferences.getLink()} >
                        Preference procesů
                      </Link>}
                    <Link className="nav-link" to={ROUTES.dialsFreeText.getLink()} >
                      Volné texty
                    </Link>
                    {rightsBH &&
                    <Link className="nav-link" to={ROUTES.dialsMeetingsCategories.getLink()} >
                      Kategorie porad
                    </Link>}
                    {rightsBH &&
                    <Link className="nav-link" to={ROUTES.dialsUserGroups.getLink()} >
                      Skupiny uživatelů
                    </Link>}
                  </Card.Body>
                </Card>
                
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(Dials)
